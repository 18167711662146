import axios from "axios";
import http from "../../../../helpers/axios";
import _ from "lodash";
import moment from "moment";
import { fetchClientProgress } from "../progress/actions";

import {
  ACTIVATE_SELECTED_CLIENT,
  ADD_CLIENT_DOC,
  ADD_CLIENT_DOC_LIBRARY,
  ADD_CLIENT_VIDEO,
  ADD_NEW_TASK,
  CHANGE_ACTIVE_FILTER,
  CHANGE_CLIENTS_FILTER,
  CHANGE_SEARCH_QUERY,
  CHANGE_SORT,
  CLIENT_ACTIVE_STATUS_UPDATE,
  CLIENT_STATUS_UPDATE,
  CLIENT_TAG_FILTER,
  CLIENT_UPDATE_DURATION,
  CLIENTS_FETCH,
  DEACTIVATE_SELECTED_CLIENT,
  DELETE_CLIENT_DOC,
  DELETE_CLIENT_VIDEO,
  DELETE_SELECTED_CLIENTS,
  EXTEND_CLIENT_DURATION,
  EXTEND_CLIENT_MODAL_OPEN,
  FETCH_MORE_CLIENT_IMAGES,
  FILTER_COUNT_FETCH,
  GENERATE_MEAL_PLAN,
  GET_CLIENT_DOCS,
  GET_CLIENT_IMAGES,
  GET_CLIENT_KCALS,
  GET_CLIENT_PAYMENTS_LOG,
  GET_CLIENT_VIDEOS,
  IGNORE_CLIENT_STATUS,
  MEAL_PLAN_MODAL_OPEN,
  MEDIA_TEMPLATE_MODAL_OPEN,
  MESSAGE_COUNT_UPDATE,
  MULTI_SEND_MESSAGE,
  OPEN_SIDE_CONTENT,
  POST_ACTIVATE_CLIENT_CHAT,
  RESOLVE_REMINDER,
  SELECTED_ALL_CLIENTS,
  SELECTED_CLIENT_COUNT,
  SEND_EMAIL,
  SET_SELECTED_CLIENT,
  SUBSCRIPTION_MODAL_OPEN,
  RESEND_INVITE_MODAL_OPEN,
  SUBSCRIPTION_UPDATE,
  TASK_ADD_MODAL_OPEN,
  TOOLTIP_HANDLE,
  UNLOAD_MEAL_PLAN,
  UPDATE_CLIENT_DATA,
  UPDATE_CLIENT_PAYMENT,
  UPDATE_CLIENT_PAYMENT_LOG,
  UPDATE_UNREAD_MESSAGE,
} from "./types";
import {
  ADD_CLIENT_DOC as ADD_CLIENT_DOC_URL,
  ADD_CLIENT_VIDEO as ADD_CLIENT_VIDEO_URL,
  ADD_NEW_CLIENT as ADD_NEW_CLIENT_URL,
  ADD_NEW_TASK as ADD_NEW_TASK_URL,
  CLIENT_STATUS_ACTION,
  CLIENT_STATUS_UPDATE as CLIENT_STATUS_UPDATE_URL,
  DELETE_SELECTED_CLIENTS as DELETE_SELECTED_CLIENTS_URL,
  FETCH_CLIENT_DOCS as FETCH_CLIENTS_DOCS_URL,
  FETCH_CLIENT_IMAGES as FETCH_CLIENT_IMAGES_URL,
  FETCH_CLIENT_PAYMENTS_LOG as FETCH_CLIENT_PAYMENTS_LOG_URL,
  FETCH_CLIENT_VIDEOS as FETCH_CLIENTS_VIDEOS_URL,
  FETCH_CLIENTS,
  FETCH_DOCUMENTS,
  FETCH_VIDEOS,
  GET_CLIENT_FILTER_COUNTS,
  GET_CLIENT_KCALS_URL,
  IGNORE_STATUS,
  PAUSE_SUBSCRIPTION as PAUSE_SUBSCRIPTION_URL,
  POST_CLIENT_DOC_LIBRARY,
  REFUND_CLIENT as REFUND_CLIENT_URL,
  REMOVE_CLIENT_DOC,
  REMOVE_CLIENT_VIDEO,
  RESOLVE_REMINDER as RESOLVE_REMINDER_URL,
  UNSUBSCRIBE_CLIENT as UNSUBSCRIBE_CLIENT_URL,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO as UPDATE_CLIENT_INFO_URL,
} from "../../../../api/private/clients";

import { GENERATE_MEAL_PLAN as GENERATE_MEAL_PLAN_URL } from "../../../../api/private/meals";

import {
  SEND_EMAIL as SEND_EMAIL_URL,
  SET_CLIENT_SETTINGS,
} from "../../../../api/private";

import { MARK_MESSAGES_READ, MULTIPLE_MESSAGE_SEND } from "../../../../api/private/chat";

import { CLIENTS_LIST_FETCH_LIMIT } from "../../const";
import {
  GetCMFromFeetInches,
  GetFeetInchesFromCM,
} from "../../../../helpers/measurementHelper";
import {
  NotificationTypeEnum,
  SET_NOTIFICATION_MESSAGE,
} from "../../../../store/notification/reducerTypes";
import { ERROR_MESSAGE } from "../../../../config";
import { UPDATE_USER_TAGS } from "../../../../store/user/reducerTypes";
import { approveClientTaskAction } from "../../../../store/currentClient/action";
import { getClientsSchema, postClientsSchema } from "./schema";

let filterCountRequestCancelToken = null;
export const fetchFilterCount = () => {
  return (dispatch, getState) => {
    dispatch({ type: FILTER_COUNT_FETCH.REQUEST });

    if (filterCountRequestCancelToken !== null) {
      filterCountRequestCancelToken.cancel();
    }

    filterCountRequestCancelToken = axios.CancelToken.source();

    let tags,
      q = [];
    let params = new URLSearchParams(window.location.search);
    if (params.get("tag")) {
      tags = params.get("tag").split(",");
    }
    if (params.get("q")) {
      q = params.get("q");
    }

    const currentState = getState();
    const requestData = {
      status: currentState.clients.isActiveFilter ? "active" : "inactive",
      limit: CLIENTS_LIST_FETCH_LIMIT,
      filter: currentState.clients.filterProperty,
      q,
      tags,
    };

    axios
      .get(GET_CLIENT_FILTER_COUNTS(), {
        params: requestData,
        cancelToken: filterCountRequestCancelToken.token,
      })
      .then(response => {
        if (response?.data) {
          dispatch({ type: FILTER_COUNT_FETCH.SUCCESS, payload: response?.data });
        }
      })
      .catch(function (exception) {
        if (!axios.isCancel(exception)) {
          throw exception;
        }
      });
  };
};

export const handleTooltip = value => {
  return (dispatch, getState) => {
    dispatch({ type: TOOLTIP_HANDLE, payload: value });
  };
};

/*THIS IS FETCH CLIENTS*/
export const fetchClients = page => {
  return (dispatch, getState) => {
    dispatch({ type: CLIENTS_FETCH.REQUEST });

    let tags,
      q = [];
    let params = new URLSearchParams(window.location.search);
    if (params.get("tag")) {
      tags = params.get("tag").split(",");
    }
    if (params.get("q")) {
      q = params.get("q");
    }

    const currentState = getState();

    const offset = page > 0 ? page * CLIENTS_LIST_FETCH_LIMIT : 0;
    const requestData = {
      status: currentState.clients.isActiveFilter ? "active" : "inactive",
      offset,
      limit: CLIENTS_LIST_FETCH_LIMIT,
      filter: currentState.clients.filterProperty,
      q,
      tags,
      sortColumn: currentState.clients.sortColumn,
      sortOrder: currentState.clients.sortOrder,
    };
    return axios.get(FETCH_CLIENTS(), { params: requestData }).then(({ data }) => {
      let currentClients = offset > 0 ? currentState.clients.clients : [];
      const formattedClients = data.clients?.map(getClientsSchema);
      dispatch({
        type: CLIENTS_FETCH.SUCCESS,
        payload: {
          newClientsState: [...currentClients, ...formattedClients],
          count: data.count,
        },
      });
    });
  };
};

export const handleSelectClient = client => {
  return dispatch => {
    dispatch({ type: SET_SELECTED_CLIENT, payload: client });
    dispatch(fetchClientPaymentsLog(client.id));
    dispatch(fetchClientImages(client.id));
    dispatch(fetchClientProgress(client.id));
  };
};

export const changeFilterProperty = filterKey => {
  return { type: CHANGE_CLIENTS_FILTER, payload: filterKey };
};

export const changeSearchQuery = query => {
  return { type: CHANGE_SEARCH_QUERY, payload: query };
};

export const changeTagFilter = query => {
  return { type: CLIENT_TAG_FILTER, payload: query };
};

export const changeActiveFilter = isActive => {
  return { type: CHANGE_ACTIVE_FILTER, payload: isActive };
};

export const changeClientSort = (column, order) => {
  return { type: CHANGE_SORT, payload: { column, order } };
};

export const addClient = client => {
  return axios
    .post(ADD_NEW_CLIENT_URL(), client)
    .then(res => {
      return {
        status: "success",
        resData: res.data,
      };
    })
    .catch(err => {
      return {
        status: "error",
        resData: err.response.data
          ? err.response.data.error_list
          : "Something went wrong.",
      };
    });
};

export const clientUpdate = bodyData => {
  return () => {
    var form_data = new FormData();
    for (var key in bodyData) {
      form_data.append(key, bodyData[key]);
    }
    return axios
      .post(SET_CLIENT_SETTINGS(bodyData["client"]), form_data)
      .then(({ data }) => {
        return {
          status: "success",
          resData: data,
        };
      })
      .catch(err => {
        return {
          status: "error",
          resData: err.response.data.error,
          error_list: err.response.data?.error_list,
        };
      });
  };
};

export const addClientModalClose = () => {
  return dispatch => {
    dispatch(fetchFilterCount());
    dispatch(fetchClients());
  };
};

export const ignoreStatusAction = (id, clientId, eventName) => {
  return dispatch => {
    axios.post(IGNORE_STATUS(), { id }).then(res => {
      const payloadData = {
        id: clientId,
        status: res.data.status,
        eventName: eventName,
        statusId: id,
      };
      dispatch({ type: IGNORE_CLIENT_STATUS, payload: payloadData });
    });
  };
};

export const selectClient = client => {
  return dispatch => {
    dispatch({ type: SELECTED_CLIENT_COUNT, payload: client });
  };
};

export const selectAllClients = () => {
  return dispatch => {
    dispatch({ type: SELECTED_ALL_CLIENTS });
  };
};

export const clientStatusUpdate = responseData => {
  return dispatch => {
    dispatch({ type: CLIENT_STATUS_UPDATE, payload: responseData });
    dispatch(approveClientTaskAction(responseData.id));
  };
};

export const updateMessageCount = responseData => {
  return (dispatch, getState) => {
    const currectStateData = getState();
    dispatch({ type: MESSAGE_COUNT_UPDATE, payload: responseData });
    if (currectStateData.clients.filterCounts.unanswered !== 0) {
      dispatch(fetchFilterCount());
    }
  };
};

export const unsubscribe = (client, refetch) => {
  return dispatch => {
    dispatch({ type: SUBSCRIPTION_UPDATE.REQUEST });
    let bodyData = new FormData();
    bodyData.append("client", client);
    http
      .post(UNSUBSCRIBE_CLIENT_URL(), bodyData)
      .then(({ data }) => {
        dispatch({
          type: SUBSCRIPTION_UPDATE.SUCCESS,
          payload: Object.assign(data, { type: "unsubscribe" }),
        });
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.SUCCESS,
            message: "Everything updated correct",
          },
        });
        refetch();
      })
      .catch(error => {
        console.error(error);
        dispatch({ type: SUBSCRIPTION_UPDATE.FAILURE, payload: error });
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.ERROR,
            message: "An error occurred, please contact Zenfit support.",
          },
        });
      });
  };
};

export const pauseSubscription = (client, trialEnd, pause, t, refetch) => {
  return dispatch => {
    dispatch({ type: SUBSCRIPTION_UPDATE.REQUEST });
    let bodyData = new FormData();
    bodyData.append("client", client);
    bodyData.append("trialEnd", trialEnd);
    if (pause) {
      bodyData.append("pause", pause);
    }
    http
      .post(PAUSE_SUBSCRIPTION_URL(), bodyData)
      .then(({ data }) => {
        dispatch({
          type: SUBSCRIPTION_UPDATE.SUCCESS,
          payload: Object.assign(data, { type: pause ? "pause" : "adjust" }),
        });
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.SUCCESS,
            message: t("actions.subWasUpdated"),
          },
        });
        refetch?.();
      })
      .catch(() => {
        dispatch({ type: SUBSCRIPTION_UPDATE.FAILURE });
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.ERROR,
            message: "An error occurred, please contact Zenfit support.",
          },
        });
      });
  };
};

export const refundClient = (client, chargeId, t) => {
  return (dispatch, getState) => {
    const { clientPaymentsLog } = getState().clients;
    const currentPaymentLog = clientPaymentsLog.find(
      clientPaymentLog => clientPaymentLog.charge === chargeId,
    );
    const newRefundPaymentLog = {
      id: currentPaymentLog.id + 50,
      type: "charge.refunded",
      customer: currentPaymentLog.customer,
      charge: currentPaymentLog.charge,
      amount: currentPaymentLog.amount,
      currency: currentPaymentLog.currency,
      createdAt: {
        date: moment(),
        timezone_type: currentPaymentLog.timezone_type,
        timezone: currentPaymentLog.timezone,
      },
      arrivalDate: null,
    };

    let bodyData = new FormData();
    bodyData.append("client", client);
    bodyData.append("chargeId", chargeId);
    http
      .post(REFUND_CLIENT_URL(), bodyData)
      .then(() => {
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.SUCCESS,
            message: t("actions.refundSuccessful") + "" + t("actions.success"),
          },
        });
        dispatch({ type: UPDATE_CLIENT_PAYMENT_LOG, payload: newRefundPaymentLog });
      })
      .catch(() => {
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.ERROR,
            message: t("actions.errorOccurred") + "" + t("actions.error"),
          },
        });
      });
  };
};

export const deleteSelectedClients = clients => {
  return async dispatch => {
    dispatch({ type: DELETE_SELECTED_CLIENTS.REQUEST });
    const params = {
      clients: clients,
    };
    axios.post(DELETE_SELECTED_CLIENTS_URL(), null, { params }).then(() => {
      dispatch({ type: DELETE_SELECTED_CLIENTS.SUCCESS, payload: clients });
    });
  };
};

export const deactivateSelectedClients = clients => {
  return dispatch => {
    dispatch({ type: DEACTIVATE_SELECTED_CLIENT.REQUEST });
    const requestData = {
      clients: clients,
      status: "deactivate",
    };
    axios.post(CLIENT_STATUS_UPDATE_URL(), null, { params: requestData }).then(() => {
      dispatch({ type: DEACTIVATE_SELECTED_CLIENT.SUCCESS, payload: clients });
    });
  };
};
export const activateSelectedClients = clients => {
  return dispatch => {
    dispatch({ type: ACTIVATE_SELECTED_CLIENT.REQUEST });
    const params = {
      clients: clients,
      status: "activate",
    };
    axios.post(CLIENT_STATUS_UPDATE_URL(), null, { params }).then(() => {
      dispatch({ type: ACTIVATE_SELECTED_CLIENT.SUCCESS, payload: clients });
    });
  };
};

export const unreadMessageUpdate = responsData => {
  return dispatch => {
    axios.post(MARK_MESSAGES_READ(responsData), {}).then(() => {
      dispatch({ type: UPDATE_UNREAD_MESSAGE, payload: responsData });
    });
  };
};

export const handleTaskModal = (open, id) => {
  return dispatch => {
    dispatch({ type: TASK_ADD_MODAL_OPEN, payload: { open: open, id: id } });
  };
};

export const handleMealPlanModal = (open, id) => {
  return dispatch => {
    dispatch({ type: MEAL_PLAN_MODAL_OPEN, payload: { open: open, id: id } });
  };
};

export const addNewTask = data => {
  return dispatch => {
    dispatch({ type: ADD_NEW_TASK.REQUEST });
    axios
      .post(ADD_NEW_TASK_URL(), null, { params: data })
      .then(({ data }) => {
        dispatch({ type: ADD_NEW_TASK.SUCCESS, payload: data });
      })
      .catch(err => {
        dispatch({ type: ADD_NEW_TASK.FAILURE, payload: err });
      });
  };
};

export const resolveReminder = (id, clientId) => {
  const data = new FormData();
  data.append("id", id);
  return dispatch => {
    dispatch({ type: RESOLVE_REMINDER.REQUEST });
    axios
      .post(RESOLVE_REMINDER_URL(), data)
      .then(() => {
        dispatch({
          type: RESOLVE_REMINDER.SUCCESS,
          payload: { id: id, clientId: clientId },
        });
      })
      .catch(err => {
        dispatch({ type: RESOLVE_REMINDER.FAILURE, payload: err });
      });
  };
};

export const handleSubscriptionModal = (open, client) => {
  return dispatch => {
    dispatch({ type: SUBSCRIPTION_MODAL_OPEN, payload: { open: open, client: client } });
  };
};

export const handleResendInvitationModal = invitationData => {
  return dispatch => {
    dispatch({
      type: RESEND_INVITE_MODAL_OPEN,
      payload: { invitationData },
    });
  };
};

export const clientPaymentUpdate = bodyData => {
  return dispatch => {
    dispatch({ type: UPDATE_CLIENT_PAYMENT.REQUEST });
    var form_data = new FormData();
    for (var key in bodyData) {
      form_data.append(key, bodyData[key]);
    }
    return axios
      .post(SET_CLIENT_SETTINGS(bodyData["client"]), form_data)
      .then(({ data }) => {
        dispatch({ type: UPDATE_CLIENT_PAYMENT.SUCCESS, payload: data });
        return data;
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CLIENT_PAYMENT.FAILURE,
          payload: err.response.data.error_list,
        });
      });
  };
};

export const handleExtendModal = (value, client, id) => {
  return dispatch => {
    dispatch({
      type: EXTEND_CLIENT_MODAL_OPEN,
      payload: { open: value, client: client, statusId: id },
    });
  };
};

export const extendDuration = (bodyData, client) => {
  return dispatch => {
    axios
      .post(UPDATE_CLIENT_INFO(client), bodyData)
      .then(({ data }) => {
        const { duration, endDate, startDate } = data.client;
        const formattedClient = getClientsSchema(data.client);
        dispatch({
          type: CLIENT_UPDATE_DURATION,
          payload: {
            client: formattedClient,
            duration: duration,
            endDate: endDate,
            startDate: startDate,
          },
        });
        dispatch({ type: EXTEND_CLIENT_DURATION });
      })
      .catch(err => {
        dispatch({ type: UPDATE_CLIENT_PAYMENT.FAILURE, payload: err });
      });
  };
};

export const clientActiveStatusUpdate = id => {
  return dispatch => {
    dispatch({ type: CLIENT_ACTIVE_STATUS_UPDATE.REQUEST });
    const requestData = {
      clients: id,
      status: "activate",
    };

    http
      .post(CLIENT_STATUS_UPDATE_URL(), null, { params: requestData })
      .then(() => {
        dispatch({ type: CLIENT_ACTIVE_STATUS_UPDATE.SUCCESS });
      })
      .catch(err => {
        dispatch({ type: CLIENT_ACTIVE_STATUS_UPDATE.FAILURE, payload: err });
      });
  };
};

export const openSideContent = (open, client, messageType, templateType) => {
  return dispatch => {
    dispatch({
      type: OPEN_SIDE_CONTENT,
      payload: {
        open: open,
        messageType: messageType,
        templateType: templateType,
        client: client,
      },
    });
  };
};

export const closeSideContent = () => {
  return dispatch => {
    setTimeout(() => {
      dispatch({
        type: OPEN_SIDE_CONTENT,
        payload: { open: false, messageType: "", templateType: "", client: {} },
      });
    }, 900);
  };
};

export const sendEmail = bodyData => {
  return dispatch => {
    var form_data = new FormData();
    for (var key in bodyData) {
      form_data.append(key, bodyData[key]);
    }
    return axios.post(SEND_EMAIL_URL(), form_data).then(({ data }) => {
      setTimeout(() => {
        dispatch({ type: SEND_EMAIL.SUCCESS, payload: { sentMail: true } });
      }, 900);
      return data;
    });
  };
};

export const multiSendMessage = (userId, clients, msg) => {
  return dispatch => {
    const bodyData = {
      clients: clients,
      msg: msg,
    };
    return axios.post(MULTIPLE_MESSAGE_SEND(userId), bodyData).then(({ data }) => {
      dispatch({ type: MULTI_SEND_MESSAGE.SUCCESS });
      return data;
    });
  };
};

export const generateMealPlan = values => {
  return dispatch => {
    dispatch({ type: GENERATE_MEAL_PLAN.REQUEST });
    http
      .post(GENERATE_MEAL_PLAN_URL(), {
        ...values,
        mealTypesSplit: [
          {
            type: 1,
            percentage: 33,
          },
          {
            type: 2,
            percentage: 33,
          },
          {
            type: 3,
            percentage: 34,
          },
        ],
      })
      .then(() => {
        dispatch({ type: GENERATE_MEAL_PLAN.SUCCESS });
      })
      .catch(({ response }) => {
        dispatch({
          type: GENERATE_MEAL_PLAN.FAILURE,
          payload: response.data.error_list.msg,
        });
      });
  };
};

let updateClientInfoRequestCancelToken = null;
const debouncedUpdateClientInfo = _.debounce(updateInfo, 1000);

export const updateClientInfo = (client, bodyData, t) => {
  return (dispatch, getState) => {
    if (updateClientInfoRequestCancelToken !== null) {
      updateClientInfoRequestCancelToken.cancel();
    }

    if (bodyData["measuringSystem"] === 2) {
      bodyData["info"].height = GetCMFromFeetInches(
        bodyData["info"].feet,
        bodyData["info"].inches,
      );
    } else {
      bodyData["info"].feet = GetFeetInchesFromCM(bodyData["info"].height).feet;
      bodyData["info"].inches = GetFeetInchesFromCM(bodyData["info"].height).inches;
    }

    updateClientInfoRequestCancelToken = axios.CancelToken.source();
    return debouncedUpdateClientInfo(dispatch, getState, client, bodyData, t);
  };
};

/*THIS IS THE MAIN UPDATE INFO FUNCTION*/
function updateInfo(dispatch, getState, client, body, t) {
  const formattedBody = postClientsSchema(body);
  http
    .post(UPDATE_CLIENT_INFO_URL(client), formattedBody, {
      cancelToken: updateClientInfoRequestCancelToken.token,
    })
    .then(({ data = {} }) => {
      const formattedClient = getClientsSchema(data);
      dispatch({
        type: SET_NOTIFICATION_MESSAGE,
        payload: { type: NotificationTypeEnum.SUCCESS, message: t("actions.infoSaved") },
      });
      dispatch({ type: SET_SELECTED_CLIENT, payload: formattedClient });
      dispatch({ type: UPDATE_CLIENT_DATA, payload: formattedClient });
      dispatch({
        type: UPDATE_USER_TAGS,
        payload: {
          tags: data.tags,
        },
      });
    });
}

export const fetchClientDocs = id => {
  return dispatch => {
    dispatch({ type: GET_CLIENT_DOCS.REQUEST });
    return http.get(FETCH_CLIENTS_DOCS_URL(id)).then(({ data }) => {
      dispatch({ type: GET_CLIENT_DOCS.SUCCESS, payload: { docs: data } });
    });
  };
};

export const fetchClientVideos = id => {
  return dispatch => {
    dispatch({ type: GET_CLIENT_VIDEOS.REQUEST });
    return http.get(FETCH_CLIENTS_VIDEOS_URL(id)).then(({ data }) => {
      dispatch({ type: GET_CLIENT_VIDEOS.SUCCESS, payload: { videos: data } });
    });
  };
};

export const handleUnloadMealPlan = () => {
  return dispatch => {
    dispatch({ type: UNLOAD_MEAL_PLAN });
  };
};

export const fetchClientPaymentsLog = id => {
  return dispatch => {
    dispatch({ type: GET_CLIENT_PAYMENTS_LOG.REQUEST });
    setTimeout(() => {
      return http
        .get(FETCH_CLIENT_PAYMENTS_LOG_URL(), { params: { client: id } })
        .then(({ data }) => {
          dispatch({
            type: GET_CLIENT_PAYMENTS_LOG.SUCCESS,
            payload: { paymentsLog: data },
          });
        });
    }, 500);
  };
};

export const fetchClientImages = id => {
  return dispatch => {
    dispatch({ type: GET_CLIENT_IMAGES.REQUEST });
    setTimeout(() => {
      return http.get(FETCH_CLIENT_IMAGES_URL(id)).then(({ data }) => {
        dispatch({ type: GET_CLIENT_IMAGES.SUCCESS, payload: { images: data } });
      });
    }, 500);
  };
};

export const addClientVideo = (id, clientId) => {
  return dispatch => {
    return axios.post(ADD_CLIENT_VIDEO_URL(clientId, id), null).then(({ data }) => {
      dispatch({
        type: ADD_CLIENT_VIDEO.SUCCESS,
        payload: { clientId: clientId, videoAdded: data },
      });
    });
  };
};

export const addClientDoc = (id, clientId) => {
  return dispatch => {
    return axios.post(ADD_CLIENT_DOC_URL(clientId, id), null).then(({ data }) => {
      dispatch({
        type: ADD_CLIENT_DOC.SUCCESS,
        payload: { clientId: clientId, docAdded: data },
      });
    });
  };
};

export const deleteClientVideoAction = id => {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_CLIENT_VIDEO.REQUEST });
    return http
      .delete(REMOVE_CLIENT_VIDEO(getState().clients.selectedClient.id, id))
      .then(() => {
        dispatch({ type: DELETE_CLIENT_VIDEO.SUCCESS, payload: id });
      });
  };
};
export const deleteClientDocAction = id => {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_CLIENT_DOC.REQUEST });
    return http
      .delete(REMOVE_CLIENT_DOC(getState().clients.selectedClient.id, id))
      .then(() => {
        dispatch({ type: DELETE_CLIENT_DOC.SUCCESS, payload: id });
      });
  };
};

export const fetchMoreClientImagesAction = id => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_MORE_CLIENT_IMAGES.REQUEST });
    setTimeout(() => {
      return http
        .get(FETCH_CLIENT_IMAGES_URL(id), {
          params: { page: getState().clients.clientImagesPage },
        })
        .then(({ data }) => {
          dispatch({ type: FETCH_MORE_CLIENT_IMAGES.SUCCESS, payload: { images: data } });
        });
    }, 500);
  };
};

export const fetchGraphKcalsAction = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_CLIENT_KCALS.REQUEST });
    return http
      .get(GET_CLIENT_KCALS_URL(getState().clients.selectedClient.id))
      .then(({ data }) => {
        dispatch({ type: GET_CLIENT_KCALS.SUCCESS, payload: { kcals: data } });
      });
  };
};

export const handleMediaTemplateModal = (open, id, type, currentMedias) => {
  return dispatch => {
    dispatch({ type: MEDIA_TEMPLATE_MODAL_OPEN.REQUEST });
    return http
      .get(type === "video" ? FETCH_VIDEOS() : FETCH_DOCUMENTS())
      .then(({ data }) => {
        dispatch({
          type: MEDIA_TEMPLATE_MODAL_OPEN.SUCCESS,
          payload: {
            open: open,
            id: id,
            type: type,
            current: currentMedias,
            media: data.reverse(),
          },
        });
      });
  };
};

export const postNewClientDocLibraryAction = formData => {
  return dispatch => {
    dispatch({ type: ADD_CLIENT_DOC_LIBRARY.REQUEST });
    return http.post(POST_CLIENT_DOC_LIBRARY(), formData).then(({ data }) => {
      dispatch({ type: ADD_CLIENT_DOC_LIBRARY.SUCCESS, payload: data });
    });
  };
};

export const handleActivateClientChatAction = (data, callBack) => {
  return async dispatch => {
    return axios
      .post(CLIENT_STATUS_ACTION(), data)
      .then(res => {
        dispatch({ type: POST_ACTIVATE_CLIENT_CHAT.SUCCESS, payload: data });
        const { msg } = res.data;
        callBack({
          actionResponse: msg,
          activeClient: data.client,
        });
      })
      .catch(error => {
        console.error(error);
        dispatch({
          type: SET_NOTIFICATION_MESSAGE,
          payload: {
            type: NotificationTypeEnum.ERROR,
            message: ERROR_MESSAGE,
          },
        });
      });
  };
};
