import { ReactNode } from "react";
import { Stack, TextField } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import LenusXZenfitIcon from "./LenusXZenfitIcon";
import MenuItem from "@mui/material/MenuItem";
import { Locales } from "../../../i18n/locales";
import { LocaleEnum } from "../../../generated/graphql";
import { getFlagEmojiFromCountryCode } from "../../../components/LangSwitch/util";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import SkeletonContainer from "../../../containers/SkeletonContainer";
import { useLenusTransferContext } from "../index";
import { useLocation } from "react-router-dom";
import { Routes } from "../../../routes/routeMap";
import LenusLeaveTransfer from "./LenusLeaveTransfer";

interface Props {
  children: ReactNode[] | ReactNode;
}

const transferRoutes = [Routes.LENUS_TRANSFER, Routes.LENUS_TRANSFER_EMAIL];

export default function TransferContainer(props: Props) {
  const { children } = props;

  const { locale, loading, handleLocale } = useLenusTransferContext();
  const { t } = useTranslation(TranslatorNS.SHARED);
  let location = useLocation();
  const disableUI = location.pathname.includes(Routes.LENUS_TRANSFER_EMAIL);
  const isTransferRoute = transferRoutes.includes(location.pathname as Routes);

  return (
    <Stack
      sx={sx}
      width={"100%"}
      height={{ sm: "100vh" }}
      bgcolor={"grey.900"}
      alignItems={"center"}
      position={"relative"}>
      <Stack
        px={2.5}
        flexDirection={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
        position={{ xs: "relative", sm: "absolute" }}
        mb={{ xs: 3, sm: 0 }}
        zIndex={disableUI ? 1301 : 2}>
        <Stack width={118} display={{ xs: "none", sm: "block" }} />
        <Stack width={140} height={100}>
          {!disableUI && <LenusXZenfitIcon />}
        </Stack>
        <Stack zIndex={2}>
          {isTransferRoute ? (
            <LenusLeaveTransfer />
          ) : (
            <SkeletonContainer
              loading={loading}
              width={158}
              height={40}
              sx={{ bgcolor: "grey.800" }}>
              <TextField
                select
                size={"small"}
                focused={false}
                value={locale}
                sx={{
                  width: 168,
                  textTransform: "capitalize",
                  "& .MuiInputBase-input": { color: "white" },
                }}
                onChange={event => handleLocale(event.target.value as LocaleEnum)}>
                {Object.entries(Locales).map(([key, label]) => (
                  <MenuItem key={key} value={key} sx={{ textTransform: "capitalize" }}>
                    {getFlagEmojiFromCountryCode(key as LocaleEnum)} {t(label)}
                  </MenuItem>
                ))}
              </TextField>
            </SkeletonContainer>
          )}
        </Stack>
      </Stack>
      <Stack
        height={{ xs: "auto", sm: "100%" }}
        width={"100%"}
        zIndex={1}
        justifyContent={"center"}
        p={2}
        alignItems={"center"}>
        {children}
      </Stack>
    </Stack>
  );
}

const sx = {
  ".swiper-pagination-bullet": {
    backgroundColor: "#fff",
    opacity: 0.5,
    width: 8,
    height: 4,
    borderRadius: 2,
  },
  ".swiper-pagination-bullet-active": {
    backgroundColor: "#fff!important",
    opacity: 1,
    width: 20,
    height: 4,
    borderRadius: 2,
  },
};
