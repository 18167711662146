import styled from "styled-components";
import { Colors } from "../../styleguide";
import { DEVICE } from "../../helpers/devices";

export enum LangPlacement {
  RIGHT = "right",
  LEFT = "left",
}

interface Props {
  placement: LangPlacement;
}

export const ZFLangSwitchStyled = styled.div<Props>`
  position: absolute;
  bottom: 20px;
  ${({ placement }) =>
    placement === LangPlacement.RIGHT ? "right: 20px;" : "left: 20px"}
  font-size: 14px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
  .langBtn {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 1;
    padding: 13px 1rem;
    min-width: 180px;
    border-radius: 6px;
    border: solid 1px ${Colors.INPUT_COLORS_BORDER};
    background-color: #fff;
    color: black;
    &.active {
      border: 1px solid ${({ theme }) => theme.primaryColor || Colors.ACTIVE_COLOR};
      color: ${({ theme }) => theme.primaryColor || Colors.ACTIVE_COLOR};
      & svg {
        color: ${({ theme }) => theme.primaryColor || Colors.ACTIVE_COLOR};
      }
    }
  }
  .langList {
    position: absolute;
    z-index: 10;
    bottom: 53px;
    width: 100%;
    padding: 1rem 1rem;
    background: white;
    border: 1px solid ${({ theme }) => theme.primaryColor || Colors.ACTIVE_COLOR};
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    .langItem {
      border-radius: 2px;
      cursor: pointer;
      padding: 0.25rem 15px;
      &.active {
        font-weight: 500;
        color: black;
        font-family: Poppins, serif;
      }
      &:hover {
        background-color: #f7f7f7;
        color: black;
      }
    }
  }
  .flag {
    padding-right: 10px;
    font-size: 17px;
  }

  svg {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 18px;
    color: ${Colors.DISABLED_COLOR};
    &:hover {
      color: white;
    }
  }

  ${DEVICE.mobile} {
    z-index: 1000;
    position: absolute;
    bottom: 20px;
    .langBtn {
      min-width: 66px;
      svg {
        right: 8px;
        top: 20px;
      }
      &.active {
        min-width: 180px;
      }
    }
    .flagName {
      display: none;
      &.active {
        display: flex;
      }
    }
  }
`;
