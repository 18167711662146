/*jshint esversion: 6 */
import { SET_TOTAL_ACTIVE_CLIENTS } from "../constants";

document.getElementById('workout-day-container');

const INITIAL_STATE = {
    dayId: null,
    dayName: null,
    workoutPlanName: null,
    clientId: null,
    isTemplate: null,
    workoutId: null,
    url: null,
    muscles: [],
    equipments: [],
    btnClass: null,
    totalActiveClients: null
};

export default function Reducer(state = INITIAL_STATE, {type}) {
    switch (type) {

        case SET_TOTAL_ACTIVE_CLIENTS:
            return { ...state, totalActiveClients: 1};

        default:
            return state;
    }
}
