/* tslint:disable */
/* eslint-disable */
/**
 * Zenfit
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ClientCreateInputDTO
 */
export interface ClientCreateInputDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientCreateInputDTO
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'questionnaire': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'createWorkoutPlan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'createMealPlan': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'screeningQuestions': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'trackProgress': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'durationTime': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'payment': boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'startDurationTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'endDurationTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'dayTrackProgress'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'trackFrequency'?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'lead'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'queue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'currency'?: ClientCreateInputDTOCurrencyEnum;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'signUpFee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClientCreateInputDTO
     */
    'monthlyAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'terms'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateInputDTO
     */
    'startPaymentDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'chargeUpfrontImmediately'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateInputDTO
     */
    'hideCaloriesAndMeasurements'?: boolean | null;
}

export const ClientCreateInputDTOCurrencyEnum = {
    Usd: 'usd',
    Dkk: 'dkk',
    Nok: 'nok',
    Eur: 'eur',
    Gbp: 'gbp',
    Sek: 'sek',
    Chf: 'chf'
} as const;

export type ClientCreateInputDTOCurrencyEnum = typeof ClientCreateInputDTOCurrencyEnum[keyof typeof ClientCreateInputDTOCurrencyEnum];

/**
 * 
 * @export
 * @interface ClientCreateOutputDTO
 */
export interface ClientCreateOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof ClientCreateOutputDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTO
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTO
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTO
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {ClientCreateOutputDTOTrainer}
     * @memberof ClientCreateOutputDTO
     */
    'trainer'?: ClientCreateOutputDTOTrainer;
    /**
     * 
     * @type {ClientCreateOutputDTOPayment}
     * @memberof ClientCreateOutputDTO
     */
    'payment'?: ClientCreateOutputDTOPayment;
    /**
     * 
     * @type {ClientCreateOutputDTOQueue}
     * @memberof ClientCreateOutputDTO
     */
    'queue'?: ClientCreateOutputDTOQueue;
}
/**
 * 
 * @export
 * @interface ClientCreateOutputDTOPayment
 */
export interface ClientCreateOutputDTOPayment {
    /**
     * 
     * @type {number}
     * @memberof ClientCreateOutputDTOPayment
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTOPayment
     */
    'datakey'?: string;
}
/**
 * 
 * @export
 * @interface ClientCreateOutputDTOQueue
 */
export interface ClientCreateOutputDTOQueue {
    /**
     * 
     * @type {number}
     * @memberof ClientCreateOutputDTOQueue
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientCreateOutputDTOQueue
     */
    'payment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTOQueue
     */
    'datakey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTOQueue
     */
    'createdAt'?: string;
}
/**
 * 
 * @export
 * @interface ClientCreateOutputDTOTrainer
 */
export interface ClientCreateOutputDTOTrainer {
    /**
     * 
     * @type {number}
     * @memberof ClientCreateOutputDTOTrainer
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientCreateOutputDTOTrainer
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ClientLoginInputDTO
 */
export interface ClientLoginInputDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientLoginInputDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ClientLoginInputDTO
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface ClientStepsGoalInputDTO
 */
export interface ClientStepsGoalInputDTO {
    /**
     * 
     * @type {number}
     * @memberof ClientStepsGoalInputDTO
     */
    'goal'?: number;
}
/**
 * 
 * @export
 * @interface ClientStepsInputDTO
 */
export interface ClientStepsInputDTO {
    /**
     * 
     * @type {Array<ClientStepsOutputDTOStepsInner>}
     * @memberof ClientStepsInputDTO
     */
    'steps'?: Array<ClientStepsOutputDTOStepsInner>;
}
/**
 * 
 * @export
 * @interface ClientStepsOutputDTO
 */
export interface ClientStepsOutputDTO {
    /**
     * 
     * @type {Array<ClientStepsOutputDTOStepsInner>}
     * @memberof ClientStepsOutputDTO
     */
    'steps'?: Array<ClientStepsOutputDTOStepsInner>;
}
/**
 * 
 * @export
 * @interface ClientStepsOutputDTOStepsInner
 */
export interface ClientStepsOutputDTOStepsInner {
    /**
     * 
     * @type {string}
     * @memberof ClientStepsOutputDTOStepsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientStepsOutputDTOStepsInner
     */
    'steps'?: number;
}
/**
 * 
 * @export
 * @interface GetClientStatusOutputDTO
 */
export interface GetClientStatusOutputDTO {
    /**
     * 
     * @type {number}
     * @memberof GetClientStatusOutputDTO
     */
    'id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientStatusOutputDTO
     */
    'exists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientStatusOutputDTO
     */
    'active'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientStatusOutputDTO
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetClientStatusOutputDTO
     */
    'belongsToYou'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetClientStatusOutputDTO
     */
    'status'?: GetClientStatusOutputDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetClientStatusOutputDTO
     */
    'name'?: string | null;
}

export const GetClientStatusOutputDTOStatusEnum = {
    AlreadyExists: 'already_exists',
    Inactive: 'inactive',
    Deleted: 'deleted',
    BelongsToOtherUser: 'belongs_to_other_user',
    DoesNotExist: 'does_not_exist'
} as const;

export type GetClientStatusOutputDTOStatusEnum = typeof GetClientStatusOutputDTOStatusEnum[keyof typeof GetClientStatusOutputDTOStatusEnum];

/**
 * 
 * @export
 * @interface GetUserApiPrivateGetUserInfo200Response
 */
export interface GetUserApiPrivateGetUserInfo200Response {
    /**
     * 
     * @type {number}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'picture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'primaryColor'?: string | null;
    /**
     * 
     * @type {GetUserApiPrivateGetUserInfo200ResponseUserApp}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'userApp'?: GetUserApiPrivateGetUserInfo200ResponseUserApp | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'terms'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'currency'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'stripeConnected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'stripeConnectUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'totalActiveClients'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'locale'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'useLenusRecipes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'hasValidVat'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'interactiveToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'vatNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'isAssistant'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'configured'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'unreadMessagesCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'transferInProgress'?: number;
    /**
     * 
     * @type {GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'lenusTransferDate'?: GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'platformOrigin'?: string;
    /**
     * 
     * @type {GetUserApiPrivateGetUserInfo200ResponsePayment}
     * @memberof GetUserApiPrivateGetUserInfo200Response
     */
    'payment'?: GetUserApiPrivateGetUserInfo200ResponsePayment;
}
/**
 * 
 * @export
 * @interface GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate
 */
export interface GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate {
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate
     */
    'timezone_type'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponseLenusTransferDate
     */
    'timezone'?: string;
}
/**
 * 
 * @export
 * @interface GetUserApiPrivateGetUserInfo200ResponsePayment
 */
export interface GetUserApiPrivateGetUserInfo200ResponsePayment {
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponsePayment
     */
    'invoiceUrl'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserApiPrivateGetUserInfo200ResponsePayment
     */
    'lastPaymentFailed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponsePayment
     */
    'nextPaymentAttempt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserApiPrivateGetUserInfo200ResponsePayment
     */
    'trialLeft'?: number;
}
/**
 * 
 * @export
 * @interface GetUserApiPrivateGetUserInfo200ResponseUserApp
 */
export interface GetUserApiPrivateGetUserInfo200ResponseUserApp {
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponseUserApp
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponseUserApp
     */
    'iphone'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserApiPrivateGetUserInfo200ResponseUserApp
     */
    'android'?: string;
}
/**
 * 
 * @export
 * @interface PostReactApiClientLoginV3200Response
 */
export interface PostReactApiClientLoginV3200Response {
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'gender'?: PostReactApiClientLoginV3200ResponseGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'measuring'?: PostReactApiClientLoginV3200ResponseMeasuringEnum;
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'lastProgressActivity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'trackDayProgress'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'totalDocuments'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'totalVideos'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'initialWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'targetWeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'primaryGoal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'growth'?: number | null;
    /**
     * 
     * @type {Array<PostReactApiClientLoginV3200ResponseHabitsInner>}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'habits'?: Array<PostReactApiClientLoginV3200ResponseHabitsInner>;
    /**
     * 
     * @type {Array<PostReactApiClientLoginV3200ResponseStepsInner>}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'steps'?: Array<PostReactApiClientLoginV3200ResponseStepsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'accessApp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'activated'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostReactApiClientLoginV3200Response
     */
    'healthIntegration'?: boolean | null;
}

export const PostReactApiClientLoginV3200ResponseGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PostReactApiClientLoginV3200ResponseGenderEnum = typeof PostReactApiClientLoginV3200ResponseGenderEnum[keyof typeof PostReactApiClientLoginV3200ResponseGenderEnum];
export const PostReactApiClientLoginV3200ResponseMeasuringEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PostReactApiClientLoginV3200ResponseMeasuringEnum = typeof PostReactApiClientLoginV3200ResponseMeasuringEnum[keyof typeof PostReactApiClientLoginV3200ResponseMeasuringEnum];

/**
 * 
 * @export
 * @interface PostReactApiClientLoginV3200ResponseHabitsInner
 */
export interface PostReactApiClientLoginV3200ResponseHabitsInner {
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200ResponseHabitsInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200ResponseHabitsInner
     */
    'created'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200ResponseHabitsInner
     */
    'type'?: PostReactApiClientLoginV3200ResponseHabitsInnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200ResponseHabitsInner
     */
    'goal'?: number;
}

export const PostReactApiClientLoginV3200ResponseHabitsInnerTypeEnum = {
    NUMBER_1: 1
} as const;

export type PostReactApiClientLoginV3200ResponseHabitsInnerTypeEnum = typeof PostReactApiClientLoginV3200ResponseHabitsInnerTypeEnum[keyof typeof PostReactApiClientLoginV3200ResponseHabitsInnerTypeEnum];

/**
 * 
 * @export
 * @interface PostReactApiClientLoginV3200ResponseStepsInner
 */
export interface PostReactApiClientLoginV3200ResponseStepsInner {
    /**
     * 
     * @type {string}
     * @memberof PostReactApiClientLoginV3200ResponseStepsInner
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostReactApiClientLoginV3200ResponseStepsInner
     */
    'steps'?: number;
}
/**
 * 
 * @export
 * @interface TrainerUpdateInputDTO
 */
export interface TrainerUpdateInputDTO {
    /**
     * Trainer email
     * @type {string}
     * @memberof TrainerUpdateInputDTO
     */
    'email': string;
    /**
     * Salesforce ID
     * @type {string}
     * @memberof TrainerUpdateInputDTO
     */
    'salesforceId'?: string;
    /**
     * Commit until date in format YYYY-MM-DD
     * @type {string}
     * @memberof TrainerUpdateInputDTO
     */
    'committedUntil'?: string;
    /**
     * Trainer country
     * @type {string}
     * @memberof TrainerUpdateInputDTO
     */
    'country'?: string;
    /**
     * Start date in format YYYY-MM-DD
     * @type {string}
     * @memberof TrainerUpdateInputDTO
     */
    'startDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof TrainerUpdateInputDTO
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface TrainersListInputDTO
 */
export interface TrainersListInputDTO {
    /**
     * Start date in format YYYY-MM-DD
     * @type {string}
     * @memberof TrainersListInputDTO
     */
    'start': string;
    /**
     * End date in format YYYY-MM-DD
     * @type {string}
     * @memberof TrainersListInputDTO
     */
    'end': string;
    /**
     * 
     * @type {number}
     * @memberof TrainersListInputDTO
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrainersListInputDTO
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrainersListInputDTO
     */
    'country'?: TrainersListInputDTOCountryEnum;
    /**
     * Search query
     * @type {string}
     * @memberof TrainersListInputDTO
     */
    'q'?: string;
}

export const TrainersListInputDTOCountryEnum = {
    Eu: 'eu',
    Dk: 'dk',
    NonEu: 'non-eu'
} as const;

export type TrainersListInputDTOCountryEnum = typeof TrainersListInputDTOCountryEnum[keyof typeof TrainersListInputDTOCountryEnum];

/**
 * 
 * @export
 * @interface UserPublicOutputDTO
 */
export interface UserPublicOutputDTO {
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'picture'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'primaryColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'vat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'locale'?: string | null;
    /**
     * 
     * @type {GetUserApiPrivateGetUserInfo200ResponseUserApp}
     * @memberof UserPublicOutputDTO
     */
    'userApp'?: GetUserApiPrivateGetUserInfo200ResponseUserApp | null;
    /**
     * 
     * @type {string}
     * @memberof UserPublicOutputDTO
     */
    'terms'?: string | null;
}

/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGetClientStatus: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClientActivate: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchClientActivate', 'id', id)
            const localVarPath = `/api/client/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientCreateInputDTO} [clientCreateInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddClient: async (clientCreateInputDTO?: ClientCreateInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCreateInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSetClientSettings: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postSetClientSettings', 'id', id)
            const localVarPath = `/api/client/settings/set-client-settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGetClientStatus(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientStatusOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGetClientStatus(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchClientActivate(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetClientStatusOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchClientActivate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClientCreateInputDTO} [clientCreateInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddClient(clientCreateInputDTO?: ClientCreateInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCreateOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddClient(clientCreateInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSetClientSettings(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSetClientSettings(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGetClientStatus(email?: string, options?: any): AxiosPromise<GetClientStatusOutputDTO> {
            return localVarFp.getGetClientStatus(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchClientActivate(id: number, options?: any): AxiosPromise<GetClientStatusOutputDTO> {
            return localVarFp.patchClientActivate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientCreateInputDTO} [clientCreateInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddClient(clientCreateInputDTO?: ClientCreateInputDTO, options?: any): AxiosPromise<ClientCreateOutputDTO> {
            return localVarFp.postAddClient(clientCreateInputDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSetClientSettings(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.postSetClientSettings(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getGetClientStatus(email?: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).getGetClientStatus(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public patchClientActivate(id: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).patchClientActivate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientCreateInputDTO} [clientCreateInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public postAddClient(clientCreateInputDTO?: ClientCreateInputDTO, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).postAddClient(clientCreateInputDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public postSetClientSettings(id: string, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).postSetClientSettings(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiActivateTrainerPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/activate-trainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiClientsUpdateEmailPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/clients/updateEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiDeactivateTrainerPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/deactivate-trainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiGetRevenueGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/get-revenue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiStatsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUnsubscribeTrainerPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/unsubscribe-trainer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUserStripeInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/user-stripe-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUsersChangeInfoPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/users/changeInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUsersGetSubscriptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/admin/api/users/get-subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientReminderPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-reminder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientReminderResolvePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-reminder/resolve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientStatusUpdatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client/status/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsCountGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clients/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsDeactivatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clients/deactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsDeletePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clients/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesCreatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/exercises/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesDeleteDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/exercises/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} exercise 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesExerciseInfoExerciseGet: async (exercise: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exercise' is not null or undefined
            assertParamExists('apiExercisesExerciseInfoExerciseGet', 'exercise', exercise)
            const localVarPath = `/api/exercises/exerciseInfo/{exercise}`
                .replace(`{${"exercise"}}`, encodeURIComponent(String(exercise)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/exercises`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLenusMealGeneratePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/lenus/meal/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealClientKcalsClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiMealClientKcalsClientGet', 'client', client)
            const localVarPath = `/api/meal/client/kcals/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealMacroSplitsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/meal/macro-splits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealPlanPlanGet: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiMealPlanPlanGet', 'plan', plan)
            const localVarPath = `/api/meal/plan/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealPlansIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMealPlansIdGet', 'id', id)
            const localVarPath = `/api/meal/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealPlansIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMealPlansIdPost', 'id', id)
            const localVarPath = `/api/meal/plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealRecipesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMealRecipesIdGet', 'id', id)
            const localVarPath = `/api/meal/recipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealRecipesIdSyncPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMealRecipesIdSyncPost', 'id', id)
            const localVarPath = `/api/meal/recipes/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/meal/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMealTemplatesIdDelete', 'id', id)
            const localVarPath = `/api/meal/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMealTemplatesIdPut', 'id', id)
            const localVarPath = `/api/meal/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/meal/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiPrivateClientClientGet', 'client', client)
            const localVarPath = `/api/private/client/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientStatusIgnorePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/client-status/ignore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientStatusResendQuestionnairePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/client-status/resendQuestionnaire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientStatusTakeActionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/client-status/take-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientSubmitClientInfoClientPost: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiPrivateClientSubmitClientInfoClientPost', 'client', client)
            const localVarPath = `/api/private/client/submitClientInfo/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDashboardGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateSubscriptionTrainerCheckoutSubscriptionPost: async (subscription: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscription' is not null or undefined
            assertParamExists('apiPrivateSubscriptionTrainerCheckoutSubscriptionPost', 'subscription', subscription)
            const localVarPath = `/api/private/subscription/trainer-checkout/{subscription}`
                .replace(`{${"subscription"}}`, encodeURIComponent(String(subscription)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost: async (subscription: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscription' is not null or undefined
            assertParamExists('apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost', 'subscription', subscription)
            const localVarPath = `/api/private/subscription/trainer-checkout/update/{subscription}`
                .replace(`{${"subscription"}}`, encodeURIComponent(String(subscription)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientGetClientActivationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/client/get-client-activation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientUpdateCardSuccessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/client/update-card-success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientUploadImgClientPost: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiPublicClientUploadImgClientPost', 'client', client)
            const localVarPath = `/api/public/client/uploadImg/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientUploadProfileImgClientPost: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiPublicClientUploadProfileImgClientPost', 'client', client)
            const localVarPath = `/api/public/client/uploadProfileImg/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeCreateSessionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/stripe/create-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeGeneratePaymentPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/stripe/generate-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bundle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeGetBundleBundleGet: async (bundle: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bundle' is not null or undefined
            assertParamExists('apiPublicStripeGetBundleBundleGet', 'bundle', bundle)
            const localVarPath = `/api/public/stripe/get-bundle/{bundle}`
                .replace(`{${"bundle"}}`, encodeURIComponent(String(bundle)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} datakey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeGetPaymentDatakeyGet: async (datakey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datakey' is not null or undefined
            assertParamExists('apiPublicStripeGetPaymentDatakeyGet', 'datakey', datakey)
            const localVarPath = `/api/public/stripe/get-payment/{datakey}`
                .replace(`{${"datakey"}}`, encodeURIComponent(String(datakey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bundle 
         * @param {string} client 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet: async (bundle: string, client: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bundle' is not null or undefined
            assertParamExists('apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet', 'bundle', bundle)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet', 'client', client)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet', 'key', key)
            const localVarPath = `/api/public/stripe/handle-checkout-bundle-success/{bundle}/{client}/{key}`
                .replace(`{${"bundle"}}`, encodeURIComponent(String(bundle)))
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeHandleCheckoutSuccessKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiPublicStripeHandleCheckoutSuccessKeyGet', 'key', key)
            const localVarPath = `/api/public/stripe/handle-checkout-success/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet: async (subscription: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscription' is not null or undefined
            assertParamExists('apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet', 'subscription', subscription)
            const localVarPath = `/api/public/subscription/trainer-checkout/success/{subscription}`
                .replace(`{${"subscription"}}`, encodeURIComponent(String(subscription)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTrainerLoginInteractiveTokenPost: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiPublicTrainerLoginInteractiveTokenPost', 'token', token)
            const localVarPath = `/api/public/trainer/login/interactive/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTrainerUpdateCardSuccessGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/trainer/update-card-success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGetIngredientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recipes/get-ingredients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} recipe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGetRecipeGet: async (recipe: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('apiRecipesGetRecipeGet', 'recipe', recipe)
            const localVarPath = `/api/recipes/get/{recipe}`
                .replace(`{${"recipe"}}`, encodeURIComponent(String(recipe)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGetRecipesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recipes/get-recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRecipesIdDelete', 'id', id)
            const localVarPath = `/api/recipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRecipesIdPost', 'id', id)
            const localVarPath = `/api/recipes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesUpdateUserRecipePreferencesPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/recipes/update-user-recipe-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripePaymentsLogGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stripe/payments-log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeClientGet: async (type: string, client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientGet', 'type', type)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientGet', 'client', client)
            const localVarPath = `/api/trainer/get-default-messages/{type}/{client}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {string} locale 
         * @param {string} datakey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet: async (type: string, client: string, locale: string, datakey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet', 'type', type)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet', 'client', client)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet', 'locale', locale)
            // verify required parameter 'datakey' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet', 'datakey', datakey)
            const localVarPath = `/api/trainer/get-default-messages/{type}/{client}/{locale}/{datakey}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
                .replace(`{${"datakey"}}`, encodeURIComponent(String(datakey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeClientLocaleGet: async (type: string, client: string, locale: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleGet', 'type', type)
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleGet', 'client', client)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeClientLocaleGet', 'locale', locale)
            const localVarPath = `/api/trainer/get-default-messages/{type}/{client}/{locale}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeGet: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiTrainerGetDefaultMessagesTypeGet', 'type', type)
            const localVarPath = `/api/trainer/get-default-messages/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetLeadTagsByUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/trainer/get-lead-tags-by-user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerSendEmailToClientPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/trainer/send-email-to-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerSetDefaultMessagePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/trainer/set-default-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealClientClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiV3MealClientClientGet', 'client', client)
            const localVarPath = `/api/v3/meal/client/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/meal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanDeleteDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/meal/plan/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanPlanAddParentPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiV3MealPlanPlanAddParentPost', 'plan', plan)
            const localVarPath = `/api/v3/meal/plan/{plan}/add-parent`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanPlanPercentWeightsPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiV3MealPlanPlanPercentWeightsPost', 'plan', plan)
            const localVarPath = `/api/v3/meal/plan/{plan}/percent-weights`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanReorderPlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiV3MealPlanReorderPlanPost', 'plan', plan)
            const localVarPath = `/api/v3/meal/plan/reorder/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/meal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v3/meal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealSaveSettingsPlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiV3MealSaveSettingsPlanPost', 'plan', plan)
            const localVarPath = `/api/v3/meal/save/settings/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientAssignPlanTemplatePost: async (template: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'template' is not null or undefined
            assertParamExists('apiWorkoutClientAssignPlanTemplatePost', 'template', template)
            const localVarPath = `/api/workout/client/assign-plan/{template}`
                .replace(`{${"template"}}`, encodeURIComponent(String(template)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientPlanDayDayGet: async (day: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('apiWorkoutClientPlanDayDayGet', 'day', day)
            const localVarPath = `/api/workout/client/plan/day/{day}`
                .replace(`{${"day"}}`, encodeURIComponent(String(day)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientPlanDaysGet: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiWorkoutClientPlanDaysGet', 'plan', plan)
            const localVarPath = `/api/workout/client/{plan}/days`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientPlansClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('apiWorkoutClientPlansClientGet', 'client', client)
            const localVarPath = `/api/workout/client/plans/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientSaveWorkoutDayDayPost: async (day: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('apiWorkoutClientSaveWorkoutDayDayPost', 'day', day)
            const localVarPath = `/api/workout/client/save-workout-day/{day}`
                .replace(`{${"day"}}`, encodeURIComponent(String(day)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientSaveWorkoutPlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiWorkoutClientSaveWorkoutPlanPost', 'plan', plan)
            const localVarPath = `/api/workout/client/save-workout/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileCreateWeightLiftedWorkoutPost: async (workout: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workout' is not null or undefined
            assertParamExists('apiWorkoutMobileCreateWeightLiftedWorkoutPost', 'workout', workout)
            const localVarPath = `/api/workout/mobile/createWeightLifted/{workout}`
                .replace(`{${"workout"}}`, encodeURIComponent(String(workout)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} day 
         * @param {string} parentWorkout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost: async (day: string, parentWorkout: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'day' is not null or undefined
            assertParamExists('apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost', 'day', day)
            // verify required parameter 'parentWorkout' is not null or undefined
            assertParamExists('apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost', 'parentWorkout', parentWorkout)
            const localVarPath = `/api/workout/mobile/createWorkouts/{day}/{parentWorkout}`
                .replace(`{${"day"}}`, encodeURIComponent(String(day)))
                .replace(`{${"parentWorkout"}}`, encodeURIComponent(String(parentWorkout)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileGetWeightLiftedHistoryGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workout/mobile/getWeightLiftedHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileInsertWeightLiftedPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workout/mobile/insertWeightLifted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutPlanPlanGet: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('apiWorkoutPlanPlanGet', 'plan', plan)
            const localVarPath = `/api/workout/plan/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workout/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkoutTemplatesIdDelete', 'id', id)
            const localVarPath = `/api/workout/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiWorkoutTemplatesIdPut', 'id', id)
            const localVarPath = `/api/workout/templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/workout/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiFinishSignUpPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/api/finish-sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiForgotPasswordPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/api/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiLoginPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSavePasswordPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/api/save-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSignUpPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/api/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiClientUnreadMessagesClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('chatApiClientUnreadMessagesClientGet', 'client', client)
            const localVarPath = `/chat/api/client-unread-messages/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/api/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiDeleteConversationPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/api/delete-conversation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiDeleteMessagePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/api/delete-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiFetchConversationsUserGet: async (user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('chatApiFetchConversationsUserGet', 'user', user)
            const localVarPath = `/chat/api/fetch-conversations/{user}`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiFetchMessagesClientPost: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('chatApiFetchMessagesClientPost', 'client', client)
            const localVarPath = `/chat/api/fetch-messages/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiGetConversationClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('chatApiGetConversationClientGet', 'client', client)
            const localVarPath = `/chat/api/get-conversation/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiGetPresignedRequestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/api/get-presigned-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMarkDoneConversationConversationPost: async (conversation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversation' is not null or undefined
            assertParamExists('chatApiMarkDoneConversationConversationPost', 'conversation', conversation)
            const localVarPath = `/chat/api/mark-done-conversation/{conversation}`
                .replace(`{${"conversation"}}`, encodeURIComponent(String(conversation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMarkMessagesReadClientPost: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('chatApiMarkMessagesReadClientPost', 'client', client)
            const localVarPath = `/chat/api/mark-messages-read/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMarkUnreadConversationConversationPost: async (conversation: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'conversation' is not null or undefined
            assertParamExists('chatApiMarkUnreadConversationConversationPost', 'conversation', conversation)
            const localVarPath = `/chat/api/mark-unread-conversation/{conversation}`
                .replace(`{${"conversation"}}`, encodeURIComponent(String(conversation)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMultipleSendPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/api/multiple-send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiSendPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/api/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectPauseSubscriptionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/pause-subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectRedirectGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/redirect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectRefundClientPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/refund-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUnsubscribePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connect/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {string} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiClientDocumentDelete: async (client: string, document: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('docsApiClientDocumentDelete', 'client', client)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('docsApiClientDocumentDelete', 'document', document)
            const localVarPath = `/docs/api/{client}/{document}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"document"}}`, encodeURIComponent(String(document)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {string} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiClientDocumentPost: async (client: string, document: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('docsApiClientDocumentPost', 'client', client)
            // verify required parameter 'document' is not null or undefined
            assertParamExists('docsApiClientDocumentPost', 'document', document)
            const localVarPath = `/docs/api/{client}/{document}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"document"}}`, encodeURIComponent(String(document)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('docsApiClientGet', 'client', client)
            const localVarPath = `/docs/api/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiDeleteDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/docs/api/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/docs/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiGetImagePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/docs/api/get-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiUploadPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/docs/api/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailCallbackPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiLeadsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api/leads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiTrainerAuthLoginPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api/trainer/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiTrainerAuthSignupPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api/trainer/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiTrainerMoveClientPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gym/v1/api/trainer/move-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hookHandlePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hook/handle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hookSigmaHandlePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hook/sigma/handle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsApiCreatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leads/api/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsApiDeleteDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leads/api/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsApiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leads/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginClientInteractiveTokenGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('loginClientInteractiveTokenGet', 'token', token)
            const localVarPath = `/login/client/interactive/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealAddPlanPlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('mealAddPlanPlanPost', 'plan', plan)
            const localVarPath = `/meal/add-plan/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealClonePlanPlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('mealClonePlanPlanPost', 'plan', plan)
            const localVarPath = `/meal/clone-plan/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealPlanUpdatePost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('mealPlanUpdatePost', 'plan', plan)
            const localVarPath = `/meal/{plan}/update`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meal/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealRecipesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/meal/recipes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealSaveAsTemplatePlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('mealSaveAsTemplatePlanPost', 'plan', plan)
            const localVarPath = `/meal/save-as-template/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mfpCallbackGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mfp/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} masterMealPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfExportPlansPdfMealClientMasterMealPlanPost: async (masterMealPlan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'masterMealPlan' is not null or undefined
            assertParamExists('pdfExportPlansPdfMealClientMasterMealPlanPost', 'masterMealPlan', masterMealPlan)
            const localVarPath = `/pdf/exportPlansPdfMealClient/{masterMealPlan}`
                .replace(`{${"masterMealPlan"}}`, encodeURIComponent(String(masterMealPlan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} workoutPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfExportPlansPdfWorkoutWorkoutPlanPost: async (workoutPlan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workoutPlan' is not null or undefined
            assertParamExists('pdfExportPlansPdfWorkoutWorkoutPlanPost', 'workoutPlan', workoutPlan)
            const localVarPath = `/pdf/exportPlansPdfWorkout/{workoutPlan}`
                .replace(`{${"workoutPlan"}}`, encodeURIComponent(String(workoutPlan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} user 
         * @param {string} bundle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansApiGetBundlesUserBundleGet: async (user: string, bundle: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('plansApiGetBundlesUserBundleGet', 'user', user)
            // verify required parameter 'bundle' is not null or undefined
            assertParamExists('plansApiGetBundlesUserBundleGet', 'bundle', bundle)
            const localVarPath = `/plans/api/get-bundles/{user}/{bundle}`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)))
                .replace(`{${"bundle"}}`, encodeURIComponent(String(bundle)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansApiPlansGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/plans/api/plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansApiPlansPlanDelete: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('plansApiPlansPlanDelete', 'plan', plan)
            const localVarPath = `/plans/api/plans/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsDeleteIdDelete', 'id', id)
            const localVarPath = `/products/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsInternalApiProductsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products/internal-api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsUpdateIdPut', 'id', id)
            const localVarPath = `/products/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiClientClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('progressApiClientClientGet', 'client', client)
            const localVarPath = `/progress/api/client/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiEntriesClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('progressApiEntriesClientGet', 'client', client)
            const localVarPath = `/progress/api/entries/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiEntriesClientPost: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('progressApiEntriesClientPost', 'client', client)
            const localVarPath = `/progress/api/entries/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiEntriesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('progressApiEntriesIdDelete', 'id', id)
            const localVarPath = `/progress/api/entries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiImagesClientDelete: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('progressApiImagesClientDelete', 'client', client)
            const localVarPath = `/progress/api/images/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiImagesClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('progressApiImagesClientGet', 'client', client)
            const localVarPath = `/progress/api/images/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV2MacrosClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('reactApiV2MacrosClientGet', 'client', client)
            const localVarPath = `/react-api/v2/macros/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV2MealsClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('reactApiV2MealsClientGet', 'client', client)
            const localVarPath = `/react-api/v2/meals/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV2WorkoutStatsClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('reactApiV2WorkoutStatsClientGet', 'client', client)
            const localVarPath = `/react-api/v2/workout/stats/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ActivationSubmitPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/activation/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3AuthForgotPasswordPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3AuthMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientCreateSessionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/client/create-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientDeletePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/client/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientSettingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/client/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientSettingsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/client/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientUnsubscribePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/client/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientUpdatePasswordPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/client/updatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3DocumentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MealsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/meals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MealsSavePdfPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/meals/save-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MessagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MessagesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MessagesSeenPatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/messages/seen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MfpDisablePatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/mfp/disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3PhotosDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3PhotosGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3PhotosPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/photos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ProgressGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ProgressPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3SupportCreateLeadPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/support/create-lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3SupportFeedbackPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/support/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3VideosGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3VideosSeenPatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/videos/seen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/workout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutSavePdfPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/workout/save-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutSaveWorkoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/workout/save-workout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutStatsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/workout/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutTrackingPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/workout/tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsChangeLangPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/change-lang`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsChangePasswordPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsCompanyLogoDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsCompanyLogoPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/company-logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsCreateStripeSessionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/create-stripe-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsProfilePictureDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/profile-picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsProfilePicturePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/profile-picture`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsSavePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsUpdateVatPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/update-vat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsValidateVatPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/validate-vat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} service 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialConnectServiceGet: async (service: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'service' is not null or undefined
            assertParamExists('socialConnectServiceGet', 'service', service)
            const localVarPath = `/social/connect/{service}`
                .replace(`{${"service"}}`, encodeURIComponent(String(service)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiClientGet: async (client: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('videoApiClientGet', 'client', client)
            const localVarPath = `/video/api/{client}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiClientVideoDelete: async (client: string, video: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('videoApiClientVideoDelete', 'client', client)
            // verify required parameter 'video' is not null or undefined
            assertParamExists('videoApiClientVideoDelete', 'video', video)
            const localVarPath = `/video/api/{client}/{video}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"video"}}`, encodeURIComponent(String(video)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} client 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiClientVideoPost: async (client: string, video: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'client' is not null or undefined
            assertParamExists('videoApiClientVideoPost', 'client', client)
            // verify required parameter 'video' is not null or undefined
            assertParamExists('videoApiClientVideoPost', 'video', video)
            const localVarPath = `/video/api/{client}/{video}`
                .replace(`{${"client"}}`, encodeURIComponent(String(client)))
                .replace(`{${"video"}}`, encodeURIComponent(String(video)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/video/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiGetAssignmentTagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/video/api/get-assignment-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/video/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiVideoDelete: async (video: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'video' is not null or undefined
            assertParamExists('videoApiVideoDelete', 'video', video)
            const localVarPath = `/video/api/{video}`
                .replace(`{${"video"}}`, encodeURIComponent(String(video)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutDayPlanClonePost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('workoutDayPlanClonePost', 'plan', plan)
            const localVarPath = `/workout/day/{plan}/clone`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutDayPlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('workoutDayPlanPost', 'plan', plan)
            const localVarPath = `/workout/day/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workoutIdDelete', 'id', id)
            const localVarPath = `/workout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutIdPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workoutIdPut', 'id', id)
            const localVarPath = `/workout/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutPlanUpdatePost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('workoutPlanUpdatePost', 'plan', plan)
            const localVarPath = `/workout/{plan}/update`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/workout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutSaveAsTemplatePlanPost: async (plan: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'plan' is not null or undefined
            assertParamExists('workoutSaveAsTemplatePlanPost', 'plan', plan)
            const localVarPath = `/workout/save-as-template/{plan}`
                .replace(`{${"plan"}}`, encodeURIComponent(String(plan)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierAuthReferralGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zapier/auth/referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierAuthVerifyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zapier/auth/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierLeadPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zapier/lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierStripePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/zapier/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiActivateTrainerPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiActivateTrainerPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiClientsUpdateEmailPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiClientsUpdateEmailPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiDeactivateTrainerPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiDeactivateTrainerPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiGetRevenueGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiGetRevenueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiStatsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiStatsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiUnsubscribeTrainerPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiUnsubscribeTrainerPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiUserStripeInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiUserStripeInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiUsersChangeInfoPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiUsersChangeInfoPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminApiUsersGetSubscriptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminApiUsersGetSubscriptionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientReminderPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientReminderPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientReminderResolvePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientReminderResolvePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientStatusUpdatePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientStatusUpdatePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsCountGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsCountGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsDeactivatePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsDeactivatePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsDeletePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsDeletePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExercisesCreatePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExercisesCreatePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExercisesDeleteDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExercisesDeleteDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} exercise 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExercisesExerciseInfoExerciseGet(exercise: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExercisesExerciseInfoExerciseGet(exercise, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiExercisesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiExercisesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLenusMealGeneratePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLenusMealGeneratePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealClientKcalsClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealClientKcalsClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealMacroSplitsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealMacroSplitsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealPlanPlanGet(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealPlanPlanGet(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealPlansIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealPlansIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealPlansIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealPlansIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealRecipesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealRecipesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealRecipesIdSyncPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealRecipesIdSyncPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealTemplatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealTemplatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealTemplatesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealTemplatesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealTemplatesIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealTemplatesIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMealTemplatesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMealTemplatesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateClientClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateClientClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateClientStatusIgnorePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateClientStatusIgnorePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateClientStatusResendQuestionnairePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateClientStatusResendQuestionnairePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateClientStatusTakeActionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateClientStatusTakeActionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateClientSubmitClientInfoClientPost(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateClientSubmitClientInfoClientPost(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateDashboardGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateDashboardGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateSubscriptionTrainerCheckoutSubscriptionPost(subscription: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateSubscriptionTrainerCheckoutSubscriptionPost(subscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost(subscription: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost(subscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicClientGetClientActivationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicClientGetClientActivationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicClientUpdateCardSuccessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicClientUpdateCardSuccessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicClientUploadImgClientPost(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicClientUploadImgClientPost(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicClientUploadProfileImgClientPost(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicClientUploadProfileImgClientPost(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicStripeCreateSessionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicStripeCreateSessionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicStripeGeneratePaymentPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicStripeGeneratePaymentPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bundle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicStripeGetBundleBundleGet(bundle: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicStripeGetBundleBundleGet(bundle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} datakey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicStripeGetPaymentDatakeyGet(datakey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicStripeGetPaymentDatakeyGet(datakey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bundle 
         * @param {string} client 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet(bundle: string, client: string, key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet(bundle, client, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicStripeHandleCheckoutSuccessKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicStripeHandleCheckoutSuccessKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet(subscription: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet(subscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicTrainerLoginInteractiveTokenPost(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicTrainerLoginInteractiveTokenPost(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPublicTrainerUpdateCardSuccessGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPublicTrainerUpdateCardSuccessGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesGetIngredientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesGetIngredientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} recipe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesGetRecipeGet(recipe: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesGetRecipeGet(recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesGetRecipesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesGetRecipesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRecipesUpdateUserRecipePreferencesPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRecipesUpdateUserRecipePreferencesPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStripePaymentsLogGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStripePaymentsLogGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerGetDefaultMessagesTypeClientGet(type: string, client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerGetDefaultMessagesTypeClientGet(type, client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {string} locale 
         * @param {string} datakey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet(type: string, client: string, locale: string, datakey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet(type, client, locale, datakey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerGetDefaultMessagesTypeClientLocaleGet(type: string, client: string, locale: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerGetDefaultMessagesTypeClientLocaleGet(type, client, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerGetDefaultMessagesTypeGet(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerGetDefaultMessagesTypeGet(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerGetLeadTagsByUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerGetLeadTagsByUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerSendEmailToClientPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerSendEmailToClientPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTrainerSetDefaultMessagePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTrainerSetDefaultMessagePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealClientClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealClientClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealPlanDeleteDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealPlanDeleteDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealPlanPlanAddParentPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealPlanPlanAddParentPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealPlanPlanPercentWeightsPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealPlanPlanPercentWeightsPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealPlanReorderPlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealPlanReorderPlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV3MealSaveSettingsPlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV3MealSaveSettingsPlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutClientAssignPlanTemplatePost(template: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutClientAssignPlanTemplatePost(template, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutClientPlanDayDayGet(day: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutClientPlanDayDayGet(day, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutClientPlanDaysGet(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutClientPlanDaysGet(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutClientPlansClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutClientPlansClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutClientSaveWorkoutDayDayPost(day: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutClientSaveWorkoutDayDayPost(day, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutClientSaveWorkoutPlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutClientSaveWorkoutPlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutMobileCreateWeightLiftedWorkoutPost(workout: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutMobileCreateWeightLiftedWorkoutPost(workout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} day 
         * @param {string} parentWorkout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost(day: string, parentWorkout: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost(day, parentWorkout, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutMobileGetWeightLiftedHistoryGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutMobileGetWeightLiftedHistoryGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutMobileInsertWeightLiftedPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutMobileInsertWeightLiftedPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutPlanPlanGet(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutPlanPlanGet(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutTemplatesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutTemplatesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutTemplatesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutTemplatesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutTemplatesIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutTemplatesIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiWorkoutTemplatesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiWorkoutTemplatesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiFinishSignUpPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiFinishSignUpPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiForgotPasswordPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiForgotPasswordPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiLoginPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiLoginPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiSavePasswordPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiSavePasswordPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authApiSignUpPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authApiSignUpPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiClientUnreadMessagesClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiClientUnreadMessagesClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiDeleteConversationPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiDeleteConversationPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiDeleteMessagePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiDeleteMessagePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiFetchConversationsUserGet(user: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiFetchConversationsUserGet(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiFetchMessagesClientPost(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiFetchMessagesClientPost(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiGetConversationClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiGetConversationClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiGetPresignedRequestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiGetPresignedRequestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiMarkDoneConversationConversationPost(conversation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiMarkDoneConversationConversationPost(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiMarkMessagesReadClientPost(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiMarkMessagesReadClientPost(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiMarkUnreadConversationConversationPost(conversation: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiMarkUnreadConversationConversationPost(conversation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiMultipleSendPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiMultipleSendPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatApiSendPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatApiSendPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectPauseSubscriptionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectPauseSubscriptionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectRedirectGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectRedirectGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectRefundClientPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectRefundClientPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectUnsubscribePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectUnsubscribePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {string} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiClientDocumentDelete(client: string, document: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiClientDocumentDelete(client, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {string} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiClientDocumentPost(client: string, document: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiClientDocumentPost(client, document, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiDeleteDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiDeleteDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiGetImagePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiGetImagePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async docsApiUploadPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.docsApiUploadPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailCallbackPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailCallbackPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiLeadsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiLeadsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiTrainerAuthLoginPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiTrainerAuthLoginPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiTrainerAuthSignupPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiTrainerAuthSignupPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gymV1ApiTrainerMoveClientPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gymV1ApiTrainerMoveClientPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hookHandlePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hookHandlePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hookSigmaHandlePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hookSigmaHandlePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsApiCreatePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsApiCreatePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsApiDeleteDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsApiDeleteDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadsApiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadsApiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginClientInteractiveTokenGet(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginClientInteractiveTokenGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mealAddPlanPlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mealAddPlanPlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mealClonePlanPlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mealClonePlanPlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mealPlanUpdatePost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mealPlanUpdatePost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mealPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mealPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mealRecipesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mealRecipesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mealSaveAsTemplatePlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mealSaveAsTemplatePlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mfpCallbackGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mfpCallbackGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} masterMealPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfExportPlansPdfMealClientMasterMealPlanPost(masterMealPlan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfExportPlansPdfMealClientMasterMealPlanPost(masterMealPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} workoutPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pdfExportPlansPdfWorkoutWorkoutPlanPost(workoutPlan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pdfExportPlansPdfWorkoutWorkoutPlanPost(workoutPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} user 
         * @param {string} bundle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansApiGetBundlesUserBundleGet(user: string, bundle: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansApiGetBundlesUserBundleGet(user, bundle, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansApiPlansGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansApiPlansGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async plansApiPlansPlanDelete(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.plansApiPlansPlanDelete(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCreatePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreatePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsDeleteIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsDeleteIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsGetGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsGetGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsInternalApiProductsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsInternalApiProductsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressApiClientClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressApiClientClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressApiEntriesClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressApiEntriesClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressApiEntriesClientPost(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressApiEntriesClientPost(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressApiEntriesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressApiEntriesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressApiImagesClientDelete(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressApiImagesClientDelete(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async progressApiImagesClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.progressApiImagesClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV2MacrosClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV2MacrosClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV2MealsClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV2MealsClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV2WorkoutStatsClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV2WorkoutStatsClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ActivationSubmitPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ActivationSubmitPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3AuthForgotPasswordPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3AuthForgotPasswordPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3AuthMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3AuthMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ClientCreateSessionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ClientCreateSessionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ClientDeletePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ClientDeletePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ClientSettingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ClientSettingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ClientSettingsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ClientSettingsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ClientUnsubscribePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ClientUnsubscribePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ClientUpdatePasswordPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ClientUpdatePasswordPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3DocumentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3DocumentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3MealsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3MealsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3MealsSavePdfPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3MealsSavePdfPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3MessagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3MessagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3MessagesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3MessagesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3MessagesSeenPatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3MessagesSeenPatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3MfpDisablePatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3MfpDisablePatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3PhotosDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3PhotosDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3PhotosGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3PhotosGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3PhotosPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3PhotosPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ProgressGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ProgressGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3ProgressPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3ProgressPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3SupportCreateLeadPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3SupportCreateLeadPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3SupportFeedbackPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3SupportFeedbackPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3VideosGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3VideosGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3VideosSeenPatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3VideosSeenPatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3WorkoutGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3WorkoutGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3WorkoutSavePdfPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3WorkoutSavePdfPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3WorkoutSaveWorkoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3WorkoutSaveWorkoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3WorkoutStatsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3WorkoutStatsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactApiV3WorkoutTrackingPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reactApiV3WorkoutTrackingPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsChangeLangPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsChangeLangPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsChangePasswordPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsChangePasswordPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsCompanyLogoDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsCompanyLogoDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsCompanyLogoPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsCompanyLogoPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsCreateStripeSessionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsCreateStripeSessionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsProfilePictureDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsProfilePictureDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsProfilePicturePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsProfilePicturePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsSavePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsSavePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsUpdateVatPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsUpdateVatPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingsValidateVatPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingsValidateVatPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} service 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialConnectServiceGet(service: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialConnectServiceGet(service, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiClientGet(client: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiClientGet(client, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiClientVideoDelete(client: string, video: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiClientVideoDelete(client, video, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} client 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiClientVideoPost(client: string, video: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiClientVideoPost(client, video, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiGetAssignmentTagsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiGetAssignmentTagsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async videoApiVideoDelete(video: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.videoApiVideoDelete(video, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutDayPlanClonePost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutDayPlanClonePost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutDayPlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutDayPlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutIdPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutIdPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutPlanUpdatePost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutPlanUpdatePost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workoutSaveAsTemplatePlanPost(plan: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workoutSaveAsTemplatePlanPost(plan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapierAuthReferralGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapierAuthReferralGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapierAuthVerifyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapierAuthVerifyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapierLeadPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapierLeadPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async zapierStripePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.zapierStripePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiActivateTrainerPost(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiActivateTrainerPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiClientsGet(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiClientsUpdateEmailPost(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiClientsUpdateEmailPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiDeactivateTrainerPost(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiDeactivateTrainerPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiGetRevenueGet(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiGetRevenueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiStatsGet(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiStatsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUnsubscribeTrainerPost(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiUnsubscribeTrainerPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUserStripeInfoGet(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiUserStripeInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUsersChangeInfoPut(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiUsersChangeInfoPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUsersGet(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminApiUsersGetSubscriptionsGet(options?: any): AxiosPromise<void> {
            return localVarFp.adminApiUsersGetSubscriptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientReminderPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientReminderPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientReminderResolvePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientReminderResolvePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientStatusUpdatePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientStatusUpdatePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsCountGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsCountGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsDeactivatePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsDeactivatePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsDeletePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsDeletePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesCreatePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiExercisesCreatePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesDeleteDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiExercisesDeleteDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} exercise 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesExerciseInfoExerciseGet(exercise: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiExercisesExerciseInfoExerciseGet(exercise, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExercisesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiExercisesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLenusMealGeneratePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLenusMealGeneratePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealClientKcalsClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealClientKcalsClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealMacroSplitsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMealMacroSplitsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealPlanPlanGet(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealPlanPlanGet(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealPlansIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealPlansIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealPlansIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealPlansIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealRecipesIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealRecipesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealRecipesIdSyncPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealRecipesIdSyncPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiMealTemplatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealTemplatesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesIdPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMealTemplatesIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMealTemplatesPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiMealTemplatesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateClientClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientStatusIgnorePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateClientStatusIgnorePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientStatusResendQuestionnairePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateClientStatusResendQuestionnairePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientStatusTakeActionPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateClientStatusTakeActionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateClientSubmitClientInfoClientPost(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateClientSubmitClientInfoClientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateDashboardGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateDashboardGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateSubscriptionTrainerCheckoutSubscriptionPost(subscription: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateSubscriptionTrainerCheckoutSubscriptionPost(subscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost(subscription: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost(subscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientGetClientActivationGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicClientGetClientActivationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientUpdateCardSuccessGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicClientUpdateCardSuccessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientUploadImgClientPost(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicClientUploadImgClientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicClientUploadProfileImgClientPost(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicClientUploadProfileImgClientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeCreateSessionPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicStripeCreateSessionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeGeneratePaymentPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicStripeGeneratePaymentPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bundle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeGetBundleBundleGet(bundle: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicStripeGetBundleBundleGet(bundle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} datakey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeGetPaymentDatakeyGet(datakey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicStripeGetPaymentDatakeyGet(datakey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bundle 
         * @param {string} client 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet(bundle: string, client: string, key: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet(bundle, client, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicStripeHandleCheckoutSuccessKeyGet(key: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicStripeHandleCheckoutSuccessKeyGet(key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet(subscription: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet(subscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTrainerLoginInteractiveTokenPost(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicTrainerLoginInteractiveTokenPost(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPublicTrainerUpdateCardSuccessGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiPublicTrainerUpdateCardSuccessGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGetIngredientsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesGetIngredientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} recipe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGetRecipeGet(recipe: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesGetRecipeGet(recipe, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesGetRecipesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesGetRecipesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRecipesUpdateUserRecipePreferencesPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiRecipesUpdateUserRecipePreferencesPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStripePaymentsLogGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStripePaymentsLogGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeClientGet(type: string, client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerGetDefaultMessagesTypeClientGet(type, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {string} locale 
         * @param {string} datakey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet(type: string, client: string, locale: string, datakey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet(type, client, locale, datakey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} client 
         * @param {string} locale 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeClientLocaleGet(type: string, client: string, locale: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerGetDefaultMessagesTypeClientLocaleGet(type, client, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetDefaultMessagesTypeGet(type: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerGetDefaultMessagesTypeGet(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerGetLeadTagsByUserGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerGetLeadTagsByUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerSendEmailToClientPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerSendEmailToClientPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainerSetDefaultMessagePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiTrainerSetDefaultMessagePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealClientClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealClientClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanDeleteDelete(options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealPlanDeleteDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanPlanAddParentPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealPlanPlanAddParentPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanPlanPercentWeightsPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealPlanPlanPercentWeightsPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPlanReorderPlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealPlanReorderPlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV3MealSaveSettingsPlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV3MealSaveSettingsPlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} template 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientAssignPlanTemplatePost(template: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutClientAssignPlanTemplatePost(template, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientPlanDayDayGet(day: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutClientPlanDayDayGet(day, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientPlanDaysGet(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutClientPlanDaysGet(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientPlansClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutClientPlansClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} day 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientSaveWorkoutDayDayPost(day: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutClientSaveWorkoutDayDayPost(day, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutClientSaveWorkoutPlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutClientSaveWorkoutPlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileCreateWeightLiftedWorkoutPost(workout: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutMobileCreateWeightLiftedWorkoutPost(workout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} day 
         * @param {string} parentWorkout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost(day: string, parentWorkout: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost(day, parentWorkout, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileGetWeightLiftedHistoryGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutMobileGetWeightLiftedHistoryGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutMobileInsertWeightLiftedPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutMobileInsertWeightLiftedPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutPlanPlanGet(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutPlanPlanGet(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutTemplatesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutTemplatesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesIdPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutTemplatesIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiWorkoutTemplatesPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiWorkoutTemplatesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiFinishSignUpPost(options?: any): AxiosPromise<void> {
            return localVarFp.authApiFinishSignUpPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiForgotPasswordPost(options?: any): AxiosPromise<void> {
            return localVarFp.authApiForgotPasswordPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiLoginPost(options?: any): AxiosPromise<void> {
            return localVarFp.authApiLoginPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSavePasswordPost(options?: any): AxiosPromise<void> {
            return localVarFp.authApiSavePasswordPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authApiSignUpPost(options?: any): AxiosPromise<void> {
            return localVarFp.authApiSignUpPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiClientUnreadMessagesClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiClientUnreadMessagesClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiClientsGet(options?: any): AxiosPromise<void> {
            return localVarFp.chatApiClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiDeleteConversationPost(options?: any): AxiosPromise<void> {
            return localVarFp.chatApiDeleteConversationPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiDeleteMessagePost(options?: any): AxiosPromise<void> {
            return localVarFp.chatApiDeleteMessagePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiFetchConversationsUserGet(user: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiFetchConversationsUserGet(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiFetchMessagesClientPost(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiFetchMessagesClientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiGetConversationClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiGetConversationClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiGetPresignedRequestGet(options?: any): AxiosPromise<void> {
            return localVarFp.chatApiGetPresignedRequestGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMarkDoneConversationConversationPost(conversation: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiMarkDoneConversationConversationPost(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMarkMessagesReadClientPost(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiMarkMessagesReadClientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} conversation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMarkUnreadConversationConversationPost(conversation: string, options?: any): AxiosPromise<void> {
            return localVarFp.chatApiMarkUnreadConversationConversationPost(conversation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiMultipleSendPost(options?: any): AxiosPromise<void> {
            return localVarFp.chatApiMultipleSendPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatApiSendPost(options?: any): AxiosPromise<void> {
            return localVarFp.chatApiSendPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectPauseSubscriptionPost(options?: any): AxiosPromise<void> {
            return localVarFp.connectPauseSubscriptionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectRedirectGet(options?: any): AxiosPromise<void> {
            return localVarFp.connectRedirectGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectRefundClientPost(options?: any): AxiosPromise<void> {
            return localVarFp.connectRefundClientPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectUnsubscribePost(options?: any): AxiosPromise<void> {
            return localVarFp.connectUnsubscribePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {string} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiClientDocumentDelete(client: string, document: string, options?: any): AxiosPromise<void> {
            return localVarFp.docsApiClientDocumentDelete(client, document, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {string} document 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiClientDocumentPost(client: string, document: string, options?: any): AxiosPromise<void> {
            return localVarFp.docsApiClientDocumentPost(client, document, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.docsApiClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiDeleteDelete(options?: any): AxiosPromise<void> {
            return localVarFp.docsApiDeleteDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiGet(options?: any): AxiosPromise<void> {
            return localVarFp.docsApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiGetImagePost(options?: any): AxiosPromise<void> {
            return localVarFp.docsApiGetImagePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        docsApiUploadPost(options?: any): AxiosPromise<void> {
            return localVarFp.docsApiUploadPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailCallbackPost(options?: any): AxiosPromise<void> {
            return localVarFp.emailCallbackPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiClientsGet(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiDelete(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiGet(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiLeadsGet(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiLeadsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiTrainerAuthLoginPost(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiTrainerAuthLoginPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiTrainerAuthSignupPost(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiTrainerAuthSignupPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gymV1ApiTrainerMoveClientPost(options?: any): AxiosPromise<void> {
            return localVarFp.gymV1ApiTrainerMoveClientPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hookHandlePost(options?: any): AxiosPromise<void> {
            return localVarFp.hookHandlePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hookSigmaHandlePost(options?: any): AxiosPromise<void> {
            return localVarFp.hookSigmaHandlePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsApiCreatePost(options?: any): AxiosPromise<void> {
            return localVarFp.leadsApiCreatePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsApiDeleteDelete(options?: any): AxiosPromise<void> {
            return localVarFp.leadsApiDeleteDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadsApiGet(options?: any): AxiosPromise<void> {
            return localVarFp.leadsApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginClientInteractiveTokenGet(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.loginClientInteractiveTokenGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealAddPlanPlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.mealAddPlanPlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealClonePlanPlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.mealClonePlanPlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealPlanUpdatePost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.mealPlanUpdatePost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealPost(options?: any): AxiosPromise<void> {
            return localVarFp.mealPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealRecipesPost(options?: any): AxiosPromise<void> {
            return localVarFp.mealRecipesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mealSaveAsTemplatePlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.mealSaveAsTemplatePlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mfpCallbackGet(options?: any): AxiosPromise<void> {
            return localVarFp.mfpCallbackGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} masterMealPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfExportPlansPdfMealClientMasterMealPlanPost(masterMealPlan: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfExportPlansPdfMealClientMasterMealPlanPost(masterMealPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} workoutPlan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pdfExportPlansPdfWorkoutWorkoutPlanPost(workoutPlan: string, options?: any): AxiosPromise<void> {
            return localVarFp.pdfExportPlansPdfWorkoutWorkoutPlanPost(workoutPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} user 
         * @param {string} bundle 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansApiGetBundlesUserBundleGet(user: string, bundle: string, options?: any): AxiosPromise<void> {
            return localVarFp.plansApiGetBundlesUserBundleGet(user, bundle, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansApiPlansGet(options?: any): AxiosPromise<void> {
            return localVarFp.plansApiPlansGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        plansApiPlansPlanDelete(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.plansApiPlansPlanDelete(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreatePost(options?: any): AxiosPromise<void> {
            return localVarFp.productsCreatePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsDeleteIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsDeleteIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsGetGet(options?: any): AxiosPromise<void> {
            return localVarFp.productsGetGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsInternalApiProductsGet(options?: any): AxiosPromise<void> {
            return localVarFp.productsInternalApiProductsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateIdPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.productsUpdateIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiClientClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.progressApiClientClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiEntriesClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.progressApiEntriesClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiEntriesClientPost(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.progressApiEntriesClientPost(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiEntriesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.progressApiEntriesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiImagesClientDelete(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.progressApiImagesClientDelete(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        progressApiImagesClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.progressApiImagesClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV2MacrosClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV2MacrosClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV2MealsClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV2MealsClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV2WorkoutStatsClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV2WorkoutStatsClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ActivationSubmitPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ActivationSubmitPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3AuthForgotPasswordPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3AuthForgotPasswordPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3AuthMeGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3AuthMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientCreateSessionPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ClientCreateSessionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientDeletePost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ClientDeletePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientSettingsGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ClientSettingsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientSettingsPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ClientSettingsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientUnsubscribePost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ClientUnsubscribePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ClientUpdatePasswordPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ClientUpdatePasswordPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3DocumentsGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3DocumentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MealsGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3MealsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MealsSavePdfPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3MealsSavePdfPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MessagesGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3MessagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MessagesPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3MessagesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MessagesSeenPatch(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3MessagesSeenPatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3MfpDisablePatch(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3MfpDisablePatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3PhotosDelete(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3PhotosDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3PhotosGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3PhotosGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3PhotosPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3PhotosPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ProgressGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ProgressGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3ProgressPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3ProgressPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3SupportCreateLeadPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3SupportCreateLeadPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3SupportFeedbackPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3SupportFeedbackPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3VideosGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3VideosGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3VideosSeenPatch(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3VideosSeenPatch(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3WorkoutGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutSavePdfPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3WorkoutSavePdfPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutSaveWorkoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3WorkoutSaveWorkoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutStatsGet(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3WorkoutStatsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactApiV3WorkoutTrackingPost(options?: any): AxiosPromise<void> {
            return localVarFp.reactApiV3WorkoutTrackingPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsChangeLangPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsChangeLangPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsChangePasswordPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsChangePasswordPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsCompanyLogoDelete(options?: any): AxiosPromise<void> {
            return localVarFp.settingsCompanyLogoDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsCompanyLogoPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsCompanyLogoPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsCreateStripeSessionPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsCreateStripeSessionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsProfilePictureDelete(options?: any): AxiosPromise<void> {
            return localVarFp.settingsProfilePictureDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsProfilePicturePost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsProfilePicturePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsSavePost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsSavePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsUpdateVatPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsUpdateVatPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingsValidateVatPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingsValidateVatPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} service 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialConnectServiceGet(service: string, options?: any): AxiosPromise<void> {
            return localVarFp.socialConnectServiceGet(service, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiClientGet(client: string, options?: any): AxiosPromise<void> {
            return localVarFp.videoApiClientGet(client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiClientVideoDelete(client: string, video: string, options?: any): AxiosPromise<void> {
            return localVarFp.videoApiClientVideoDelete(client, video, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} client 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiClientVideoPost(client: string, video: string, options?: any): AxiosPromise<void> {
            return localVarFp.videoApiClientVideoPost(client, video, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiGet(options?: any): AxiosPromise<void> {
            return localVarFp.videoApiGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiGetAssignmentTagsGet(options?: any): AxiosPromise<void> {
            return localVarFp.videoApiGetAssignmentTagsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.videoApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} video 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        videoApiVideoDelete(video: string, options?: any): AxiosPromise<void> {
            return localVarFp.videoApiVideoDelete(video, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutDayPlanClonePost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.workoutDayPlanClonePost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutDayPlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.workoutDayPlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.workoutIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutIdPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.workoutIdPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutPlanUpdatePost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.workoutPlanUpdatePost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.workoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} plan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workoutSaveAsTemplatePlanPost(plan: string, options?: any): AxiosPromise<void> {
            return localVarFp.workoutSaveAsTemplatePlanPost(plan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierAuthReferralGet(options?: any): AxiosPromise<void> {
            return localVarFp.zapierAuthReferralGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierAuthVerifyGet(options?: any): AxiosPromise<void> {
            return localVarFp.zapierAuthVerifyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierLeadPost(options?: any): AxiosPromise<void> {
            return localVarFp.zapierLeadPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zapierStripePost(options?: any): AxiosPromise<void> {
            return localVarFp.zapierStripePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiActivateTrainerPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiActivateTrainerPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiClientsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiClientsUpdateEmailPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiClientsUpdateEmailPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiDeactivateTrainerPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiDeactivateTrainerPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiGetRevenueGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiGetRevenueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiStatsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiStatsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiUnsubscribeTrainerPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiUnsubscribeTrainerPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiUserStripeInfoGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiUserStripeInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiUsersChangeInfoPut(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiUsersChangeInfoPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiUsersGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public adminApiUsersGetSubscriptionsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).adminApiUsersGetSubscriptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientReminderPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientReminderPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientReminderResolvePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientReminderResolvePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientStatusUpdatePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientStatusUpdatePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientsCountGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientsCountGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientsDeactivatePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientsDeactivatePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientsDeletePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientsDeletePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiClientsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiExercisesCreatePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiExercisesCreatePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiExercisesDeleteDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiExercisesDeleteDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} exercise 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiExercisesExerciseInfoExerciseGet(exercise: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiExercisesExerciseInfoExerciseGet(exercise, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiExercisesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiExercisesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiLenusMealGeneratePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiLenusMealGeneratePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealClientKcalsClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealClientKcalsClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealMacroSplitsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealMacroSplitsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealPlanPlanGet(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealPlanPlanGet(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealPlansIdGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealPlansIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealPlansIdPost(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealPlansIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealRecipesIdGet(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealRecipesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealRecipesIdSyncPost(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealRecipesIdSyncPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealTemplatesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealTemplatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealTemplatesIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealTemplatesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealTemplatesIdPut(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealTemplatesIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiMealTemplatesPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiMealTemplatesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateClientClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateClientClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateClientStatusIgnorePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateClientStatusIgnorePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateClientStatusResendQuestionnairePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateClientStatusResendQuestionnairePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateClientStatusTakeActionPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateClientStatusTakeActionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateClientSubmitClientInfoClientPost(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateClientSubmitClientInfoClientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateDashboardGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateDashboardGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateSubscriptionTrainerCheckoutSubscriptionPost(subscription: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateSubscriptionTrainerCheckoutSubscriptionPost(subscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost(subscription: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPrivateSubscriptionTrainerCheckoutUpdateSubscriptionPost(subscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicClientGetClientActivationGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicClientGetClientActivationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicClientUpdateCardSuccessGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicClientUpdateCardSuccessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicClientUploadImgClientPost(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicClientUploadImgClientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicClientUploadProfileImgClientPost(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicClientUploadProfileImgClientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicStripeCreateSessionPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicStripeCreateSessionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicStripeGeneratePaymentPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicStripeGeneratePaymentPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bundle 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicStripeGetBundleBundleGet(bundle: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicStripeGetBundleBundleGet(bundle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} datakey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicStripeGetPaymentDatakeyGet(datakey: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicStripeGetPaymentDatakeyGet(datakey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bundle 
     * @param {string} client 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet(bundle: string, client: string, key: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicStripeHandleCheckoutBundleSuccessBundleClientKeyGet(bundle, client, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} key 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicStripeHandleCheckoutSuccessKeyGet(key: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicStripeHandleCheckoutSuccessKeyGet(key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet(subscription: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicSubscriptionTrainerCheckoutSuccessSubscriptionGet(subscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicTrainerLoginInteractiveTokenPost(token: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicTrainerLoginInteractiveTokenPost(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiPublicTrainerUpdateCardSuccessGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiPublicTrainerUpdateCardSuccessGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesGetIngredientsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesGetIngredientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} recipe 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesGetRecipeGet(recipe: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesGetRecipeGet(recipe, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesGetRecipesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesGetRecipesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesIdPost(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiRecipesUpdateUserRecipePreferencesPut(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiRecipesUpdateUserRecipePreferencesPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiStripePaymentsLogGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiStripePaymentsLogGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerGetDefaultMessagesTypeClientGet(type: string, client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerGetDefaultMessagesTypeClientGet(type, client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} client 
     * @param {string} locale 
     * @param {string} datakey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet(type: string, client: string, locale: string, datakey: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerGetDefaultMessagesTypeClientLocaleDatakeyGet(type, client, locale, datakey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} client 
     * @param {string} locale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerGetDefaultMessagesTypeClientLocaleGet(type: string, client: string, locale: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerGetDefaultMessagesTypeClientLocaleGet(type, client, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerGetDefaultMessagesTypeGet(type: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerGetDefaultMessagesTypeGet(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerGetLeadTagsByUserGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerGetLeadTagsByUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerSendEmailToClientPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerSendEmailToClientPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiTrainerSetDefaultMessagePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiTrainerSetDefaultMessagePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealClientClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealClientClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealPlanDeleteDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealPlanDeleteDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealPlanPlanAddParentPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealPlanPlanAddParentPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealPlanPlanPercentWeightsPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealPlanPlanPercentWeightsPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealPlanReorderPlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealPlanReorderPlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealPut(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV3MealSaveSettingsPlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV3MealSaveSettingsPlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} template 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutClientAssignPlanTemplatePost(template: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutClientAssignPlanTemplatePost(template, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} day 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutClientPlanDayDayGet(day: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutClientPlanDayDayGet(day, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutClientPlanDaysGet(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutClientPlanDaysGet(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutClientPlansClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutClientPlansClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} day 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutClientSaveWorkoutDayDayPost(day: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutClientSaveWorkoutDayDayPost(day, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutClientSaveWorkoutPlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutClientSaveWorkoutPlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutMobileCreateWeightLiftedWorkoutPost(workout: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutMobileCreateWeightLiftedWorkoutPost(workout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} day 
     * @param {string} parentWorkout 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost(day: string, parentWorkout: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutMobileCreateWorkoutsDayParentWorkoutPost(day, parentWorkout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutMobileGetWeightLiftedHistoryGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutMobileGetWeightLiftedHistoryGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutMobileInsertWeightLiftedPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutMobileInsertWeightLiftedPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutPlanPlanGet(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutPlanPlanGet(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutTemplatesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutTemplatesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutTemplatesIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutTemplatesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutTemplatesIdPut(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutTemplatesIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiWorkoutTemplatesPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiWorkoutTemplatesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authApiFinishSignUpPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authApiFinishSignUpPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authApiForgotPasswordPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authApiForgotPasswordPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authApiLoginPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authApiLoginPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authApiSavePasswordPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authApiSavePasswordPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authApiSignUpPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authApiSignUpPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiClientUnreadMessagesClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiClientUnreadMessagesClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiClientsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiDeleteConversationPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiDeleteConversationPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiDeleteMessagePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiDeleteMessagePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiFetchConversationsUserGet(user: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiFetchConversationsUserGet(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiFetchMessagesClientPost(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiFetchMessagesClientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiGetConversationClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiGetConversationClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiGetPresignedRequestGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiGetPresignedRequestGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiMarkDoneConversationConversationPost(conversation: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiMarkDoneConversationConversationPost(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiMarkMessagesReadClientPost(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiMarkMessagesReadClientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} conversation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiMarkUnreadConversationConversationPost(conversation: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiMarkUnreadConversationConversationPost(conversation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiMultipleSendPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiMultipleSendPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chatApiSendPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chatApiSendPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public connectPauseSubscriptionPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).connectPauseSubscriptionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public connectRedirectGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).connectRedirectGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public connectRefundClientPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).connectRefundClientPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public connectUnsubscribePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).connectUnsubscribePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {string} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiClientDocumentDelete(client: string, document: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiClientDocumentDelete(client, document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {string} document 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiClientDocumentPost(client: string, document: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiClientDocumentPost(client, document, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiDeleteDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiDeleteDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiGetImagePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiGetImagePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public docsApiUploadPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).docsApiUploadPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailCallbackPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailCallbackPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiClientsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiLeadsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiLeadsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiTrainerAuthLoginPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiTrainerAuthLoginPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiTrainerAuthSignupPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiTrainerAuthSignupPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public gymV1ApiTrainerMoveClientPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).gymV1ApiTrainerMoveClientPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hookHandlePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hookHandlePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public hookSigmaHandlePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).hookSigmaHandlePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadsApiCreatePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadsApiCreatePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadsApiDeleteDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadsApiDeleteDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadsApiGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadsApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginClientInteractiveTokenGet(token: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginClientInteractiveTokenGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mealAddPlanPlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mealAddPlanPlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mealClonePlanPlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mealClonePlanPlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mealPlanUpdatePost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mealPlanUpdatePost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mealPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mealPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mealRecipesPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mealRecipesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mealSaveAsTemplatePlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mealSaveAsTemplatePlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public mfpCallbackGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).mfpCallbackGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} masterMealPlan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pdfExportPlansPdfMealClientMasterMealPlanPost(masterMealPlan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pdfExportPlansPdfMealClientMasterMealPlanPost(masterMealPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} workoutPlan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pdfExportPlansPdfWorkoutWorkoutPlanPost(workoutPlan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pdfExportPlansPdfWorkoutWorkoutPlanPost(workoutPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} user 
     * @param {string} bundle 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plansApiGetBundlesUserBundleGet(user: string, bundle: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plansApiGetBundlesUserBundleGet(user, bundle, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plansApiPlansGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plansApiPlansGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public plansApiPlansPlanDelete(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).plansApiPlansPlanDelete(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsCreatePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsCreatePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsDeleteIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsDeleteIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsGetGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsGetGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsInternalApiProductsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsInternalApiProductsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public productsUpdateIdPut(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).productsUpdateIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressApiClientClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressApiClientClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressApiEntriesClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressApiEntriesClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressApiEntriesClientPost(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressApiEntriesClientPost(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressApiEntriesIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressApiEntriesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressApiImagesClientDelete(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressApiImagesClientDelete(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public progressApiImagesClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).progressApiImagesClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV2MacrosClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV2MacrosClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV2MealsClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV2MealsClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV2WorkoutStatsClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV2WorkoutStatsClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ActivationSubmitPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ActivationSubmitPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3AuthForgotPasswordPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3AuthForgotPasswordPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3AuthMeGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3AuthMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ClientCreateSessionPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ClientCreateSessionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ClientDeletePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ClientDeletePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ClientSettingsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ClientSettingsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ClientSettingsPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ClientSettingsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ClientUnsubscribePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ClientUnsubscribePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ClientUpdatePasswordPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ClientUpdatePasswordPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3DocumentsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3DocumentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3MealsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3MealsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3MealsSavePdfPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3MealsSavePdfPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3MessagesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3MessagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3MessagesPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3MessagesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3MessagesSeenPatch(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3MessagesSeenPatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3MfpDisablePatch(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3MfpDisablePatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3PhotosDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3PhotosDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3PhotosGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3PhotosGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3PhotosPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3PhotosPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ProgressGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ProgressGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3ProgressPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3ProgressPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3SupportCreateLeadPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3SupportCreateLeadPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3SupportFeedbackPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3SupportFeedbackPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3VideosGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3VideosGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3VideosSeenPatch(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3VideosSeenPatch(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3WorkoutGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3WorkoutGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3WorkoutSavePdfPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3WorkoutSavePdfPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3WorkoutSaveWorkoutPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3WorkoutSaveWorkoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3WorkoutStatsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3WorkoutStatsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reactApiV3WorkoutTrackingPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reactApiV3WorkoutTrackingPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsChangeLangPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsChangeLangPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsChangePasswordPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsChangePasswordPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsCompanyLogoDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsCompanyLogoDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsCompanyLogoPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsCompanyLogoPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsCreateStripeSessionPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsCreateStripeSessionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsProfilePictureDelete(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsProfilePictureDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsProfilePicturePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsProfilePicturePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsSavePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsSavePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsUpdateVatPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsUpdateVatPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public settingsValidateVatPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).settingsValidateVatPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} service 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public socialConnectServiceGet(service: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).socialConnectServiceGet(service, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiClientGet(client: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiClientGet(client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {string} video 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiClientVideoDelete(client: string, video: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiClientVideoDelete(client, video, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} client 
     * @param {string} video 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiClientVideoPost(client: string, video: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiClientVideoPost(client, video, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiGetAssignmentTagsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiGetAssignmentTagsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} video 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public videoApiVideoDelete(video: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).videoApiVideoDelete(video, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutDayPlanClonePost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutDayPlanClonePost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutDayPlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutDayPlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutIdDelete(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutIdPut(id: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutIdPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutPlanUpdatePost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutPlanUpdatePost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} plan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public workoutSaveAsTemplatePlanPost(plan: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).workoutSaveAsTemplatePlanPost(plan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zapierAuthReferralGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zapierAuthReferralGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zapierAuthVerifyGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zapierAuthVerifyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zapierLeadPost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zapierLeadPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public zapierStripePost(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).zapierStripePost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LenusApi - axios parameter creator
 * @export
 */
export const LenusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} start Start date in format YYYY-MM-DD
         * @param {string} end Start date in format YYYY-MM-DD
         * @param {string} country Country code in ISO 3166-1 alpha-2 format
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLenusV1GetGecko: async (start: string, end: string, country: string, authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getLenusV1GetGecko', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getLenusV1GetGecko', 'end', end)
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getLenusV1GetGecko', 'country', country)
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getLenusV1GetGecko', 'authorization', authorization)
            const localVarPath = `/lenus/v1/api/gecko`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLenusV1GetStats: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getLenusV1GetStats', 'authorization', authorization)
            const localVarPath = `/lenus/v1/api/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TrainersListInputDTO} [trainersListInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLenusV1GetTrainers: async (authorization: string, trainersListInputDTO?: TrainersListInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getLenusV1GetTrainers', 'authorization', authorization)
            const localVarPath = `/lenus/v1/api/trainers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainersListInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TrainerUpdateInputDTO} [trainerUpdateInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLenusV1UpdateTrainerData: async (authorization: string, trainerUpdateInputDTO?: TrainerUpdateInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('patchLenusV1UpdateTrainerData', 'authorization', authorization)
            const localVarPath = `/lenus/v1/api/trainers/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trainerUpdateInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LenusApi - functional programming interface
 * @export
 */
export const LenusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LenusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} start Start date in format YYYY-MM-DD
         * @param {string} end Start date in format YYYY-MM-DD
         * @param {string} country Country code in ISO 3166-1 alpha-2 format
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLenusV1GetGecko(start: string, end: string, country: string, authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLenusV1GetGecko(start, end, country, authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLenusV1GetStats(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLenusV1GetStats(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TrainersListInputDTO} [trainersListInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLenusV1GetTrainers(authorization: string, trainersListInputDTO?: TrainersListInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLenusV1GetTrainers(authorization, trainersListInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TrainerUpdateInputDTO} [trainerUpdateInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLenusV1UpdateTrainerData(authorization: string, trainerUpdateInputDTO?: TrainerUpdateInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLenusV1UpdateTrainerData(authorization, trainerUpdateInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LenusApi - factory interface
 * @export
 */
export const LenusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LenusApiFp(configuration)
    return {
        /**
         * 
         * @param {string} start Start date in format YYYY-MM-DD
         * @param {string} end Start date in format YYYY-MM-DD
         * @param {string} country Country code in ISO 3166-1 alpha-2 format
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLenusV1GetGecko(start: string, end: string, country: string, authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLenusV1GetGecko(start, end, country, authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLenusV1GetStats(authorization: string, options?: any): AxiosPromise<void> {
            return localVarFp.getLenusV1GetStats(authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TrainersListInputDTO} [trainersListInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLenusV1GetTrainers(authorization: string, trainersListInputDTO?: TrainersListInputDTO, options?: any): AxiosPromise<void> {
            return localVarFp.getLenusV1GetTrainers(authorization, trainersListInputDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} authorization 
         * @param {TrainerUpdateInputDTO} [trainerUpdateInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLenusV1UpdateTrainerData(authorization: string, trainerUpdateInputDTO?: TrainerUpdateInputDTO, options?: any): AxiosPromise<void> {
            return localVarFp.patchLenusV1UpdateTrainerData(authorization, trainerUpdateInputDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LenusApi - object-oriented interface
 * @export
 * @class LenusApi
 * @extends {BaseAPI}
 */
export class LenusApi extends BaseAPI {
    /**
     * 
     * @param {string} start Start date in format YYYY-MM-DD
     * @param {string} end Start date in format YYYY-MM-DD
     * @param {string} country Country code in ISO 3166-1 alpha-2 format
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LenusApi
     */
    public getLenusV1GetGecko(start: string, end: string, country: string, authorization: string, options?: AxiosRequestConfig) {
        return LenusApiFp(this.configuration).getLenusV1GetGecko(start, end, country, authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LenusApi
     */
    public getLenusV1GetStats(authorization: string, options?: AxiosRequestConfig) {
        return LenusApiFp(this.configuration).getLenusV1GetStats(authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {TrainersListInputDTO} [trainersListInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LenusApi
     */
    public getLenusV1GetTrainers(authorization: string, trainersListInputDTO?: TrainersListInputDTO, options?: AxiosRequestConfig) {
        return LenusApiFp(this.configuration).getLenusV1GetTrainers(authorization, trainersListInputDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} authorization 
     * @param {TrainerUpdateInputDTO} [trainerUpdateInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LenusApi
     */
    public patchLenusV1UpdateTrainerData(authorization: string, trainerUpdateInputDTO?: TrainerUpdateInputDTO, options?: AxiosRequestConfig) {
        return LenusApiFp(this.configuration).patchLenusV1UpdateTrainerData(authorization, trainerUpdateInputDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReactApiClientApi - axios parameter creator
 * @export
 */
export const ReactApiClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {ClientStepsInputDTO} [clientStepsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReactApiV3UpdateClientSteps: async (id: string, clientStepsInputDTO?: ClientStepsInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchReactApiV3UpdateClientSteps', 'id', id)
            const localVarPath = `/react-api/v3/client/{id}/steps`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientStepsInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientLoginInputDTO} [clientLoginInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReactApiClientLoginV3: async (clientLoginInputDTO?: ClientLoginInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/react-api/v3/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientLoginInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientStepsGoalInputDTO} [clientStepsGoalInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReactApiV3SetClientStepsGoal: async (id: string, clientStepsGoalInputDTO?: ClientStepsGoalInputDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postReactApiV3SetClientStepsGoal', 'id', id)
            const localVarPath = `/react-api/v3/client/{id}/steps/goal`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientStepsGoalInputDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReactApiClientApi - functional programming interface
 * @export
 */
export const ReactApiClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReactApiClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {ClientStepsInputDTO} [clientStepsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchReactApiV3UpdateClientSteps(id: string, clientStepsInputDTO?: ClientStepsInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientStepsOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchReactApiV3UpdateClientSteps(id, clientStepsInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ClientLoginInputDTO} [clientLoginInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReactApiClientLoginV3(clientLoginInputDTO?: ClientLoginInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostReactApiClientLoginV3200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReactApiClientLoginV3(clientLoginInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientStepsGoalInputDTO} [clientStepsGoalInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReactApiV3SetClientStepsGoal(id: string, clientStepsGoalInputDTO?: ClientStepsGoalInputDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientStepsOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReactApiV3SetClientStepsGoal(id, clientStepsGoalInputDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReactApiClientApi - factory interface
 * @export
 */
export const ReactApiClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReactApiClientApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {ClientStepsInputDTO} [clientStepsInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchReactApiV3UpdateClientSteps(id: string, clientStepsInputDTO?: ClientStepsInputDTO, options?: any): AxiosPromise<ClientStepsOutputDTO> {
            return localVarFp.patchReactApiV3UpdateClientSteps(id, clientStepsInputDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ClientLoginInputDTO} [clientLoginInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReactApiClientLoginV3(clientLoginInputDTO?: ClientLoginInputDTO, options?: any): AxiosPromise<PostReactApiClientLoginV3200Response> {
            return localVarFp.postReactApiClientLoginV3(clientLoginInputDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ClientStepsGoalInputDTO} [clientStepsGoalInputDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReactApiV3SetClientStepsGoal(id: string, clientStepsGoalInputDTO?: ClientStepsGoalInputDTO, options?: any): AxiosPromise<ClientStepsOutputDTO> {
            return localVarFp.postReactApiV3SetClientStepsGoal(id, clientStepsGoalInputDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReactApiClientApi - object-oriented interface
 * @export
 * @class ReactApiClientApi
 * @extends {BaseAPI}
 */
export class ReactApiClientApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {ClientStepsInputDTO} [clientStepsInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactApiClientApi
     */
    public patchReactApiV3UpdateClientSteps(id: string, clientStepsInputDTO?: ClientStepsInputDTO, options?: AxiosRequestConfig) {
        return ReactApiClientApiFp(this.configuration).patchReactApiV3UpdateClientSteps(id, clientStepsInputDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ClientLoginInputDTO} [clientLoginInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactApiClientApi
     */
    public postReactApiClientLoginV3(clientLoginInputDTO?: ClientLoginInputDTO, options?: AxiosRequestConfig) {
        return ReactApiClientApiFp(this.configuration).postReactApiClientLoginV3(clientLoginInputDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ClientStepsGoalInputDTO} [clientStepsGoalInputDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReactApiClientApi
     */
    public postReactApiV3SetClientStepsGoal(id: string, clientStepsGoalInputDTO?: ClientStepsGoalInputDTO, options?: AxiosRequestConfig) {
        return ReactApiClientApiFp(this.configuration).postReactApiV3SetClientStepsGoal(id, clientStepsGoalInputDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserPrivateApi - axios parameter creator
 * @export
 */
export const UserPrivateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPrivateGetMessageCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/user/message-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPrivateGetUserInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPrivateGetUserSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/user/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserApiPrivateEditPaymentMethods: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/private/user/payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPrivateApi - functional programming interface
 * @export
 */
export const UserPrivateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPrivateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiPrivateGetMessageCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiPrivateGetMessageCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiPrivateGetUserInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserApiPrivateGetUserInfo200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiPrivateGetUserInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiPrivateGetUserSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiPrivateGetUserSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserApiPrivateEditPaymentMethods(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserApiPrivateEditPaymentMethods(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserPrivateApi - factory interface
 * @export
 */
export const UserPrivateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPrivateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPrivateGetMessageCount(options?: any): AxiosPromise<void> {
            return localVarFp.getUserApiPrivateGetMessageCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPrivateGetUserInfo(options?: any): AxiosPromise<GetUserApiPrivateGetUserInfo200Response> {
            return localVarFp.getUserApiPrivateGetUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPrivateGetUserSettings(options?: any): AxiosPromise<void> {
            return localVarFp.getUserApiPrivateGetUserSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserApiPrivateEditPaymentMethods(options?: any): AxiosPromise<void> {
            return localVarFp.putUserApiPrivateEditPaymentMethods(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPrivateApi - object-oriented interface
 * @export
 * @class UserPrivateApi
 * @extends {BaseAPI}
 */
export class UserPrivateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivateApi
     */
    public getUserApiPrivateGetMessageCount(options?: AxiosRequestConfig) {
        return UserPrivateApiFp(this.configuration).getUserApiPrivateGetMessageCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivateApi
     */
    public getUserApiPrivateGetUserInfo(options?: AxiosRequestConfig) {
        return UserPrivateApiFp(this.configuration).getUserApiPrivateGetUserInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivateApi
     */
    public getUserApiPrivateGetUserSettings(options?: AxiosRequestConfig) {
        return UserPrivateApiFp(this.configuration).getUserApiPrivateGetUserSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPrivateApi
     */
    public putUserApiPrivateEditPaymentMethods(options?: AxiosRequestConfig) {
        return UserPrivateApiFp(this.configuration).putUserApiPrivateEditPaymentMethods(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserPublicApi - axios parameter creator
 * @export
 */
export const UserPublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPublicGetUserInfo: async (authorization: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('getUserApiPublicGetUserInfo', 'authorization', authorization)
            const localVarPath = `/api/public/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPublicApi - functional programming interface
 * @export
 */
export const UserPublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserApiPublicGetUserInfo(authorization: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublicOutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserApiPublicGetUserInfo(authorization, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserPublicApi - factory interface
 * @export
 */
export const UserPublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPublicApiFp(configuration)
    return {
        /**
         * 
         * @param {string} authorization 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserApiPublicGetUserInfo(authorization: string, options?: any): AxiosPromise<UserPublicOutputDTO> {
            return localVarFp.getUserApiPublicGetUserInfo(authorization, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPublicApi - object-oriented interface
 * @export
 * @class UserPublicApi
 * @extends {BaseAPI}
 */
export class UserPublicApi extends BaseAPI {
    /**
     * 
     * @param {string} authorization 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPublicApi
     */
    public getUserApiPublicGetUserInfo(authorization: string, options?: AxiosRequestConfig) {
        return UserPublicApiFp(this.configuration).getUserApiPublicGetUserInfo(authorization, options).then((request) => request(this.axios, this.basePath));
    }
}


