import Stats from "./components/Stats";
import { Stack, Typography } from "@mui/material";
import CircleChart from "../../../components/Charts/CircleChart";
import { GetClientGoalQuery } from "../../../generated/graphql";
import getGoalGraphValues from "./helpers/getGoalGraphValues";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { Fragment } from "react";
import { GoalTypeEnum } from "./helpers/getGoalType";
import Iconify from "../../../components/_MUI/Iconify";

interface Props {
  client: GetClientGoalQuery["client"];
  hideStats?: boolean;
}

export default function GoalContent(props: Props) {
  const { client, hideStats } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  const {
    startWeight,
    weightGoal,
    unit,
    currentWeight,
    goalType,
    currentProgress,
    percent,
    arrowDirection,
    goalStartDifference,
    indicatorColor,
  } = getGoalGraphValues(
    client?.startWeight,
    client?.weightGoal,
    client?.measuringSystem,
    client?.progress,
  );

  const goalTypeString = getGoalTypeString(goalType);

  return (
    <Stack height={"100%"} justifyContent={"space-between"} py={1}>
      <Fragment>
        {!hideStats && (
          <Stats
            weightGoal={weightGoal}
            startWeight={startWeight}
            currentWeight={currentWeight || 0}
            unit={unit}
          />
        )}
        <Stack
          flexGrow={1}
          flexDirection={"row"}
          justifyContent={"center"}
          pt={"15%"}
          position={"relative"}
          alignItems={"flex-start"}>
          <CircleChart
            prefixText={t(goalTypeString)}
            progressText={""}
            suffixText={
              goalType !== GoalTypeEnum.Maintain
                ? `${t("clientWeight.of")} ${goalStartDifference} ${unit}`
                : ""
            }
            progress={percent}
            width={180}
            height={180}
            outerRadius={90}
            progressTextSize={24}
            graphColor={indicatorColor}
            progressTextVerticalOffset={10}
            prefixTextVerticalOffset={-25}
            suffixTextVerticalOffset={38}
          />
          <Stack
            width={180}
            height={180}
            position={"absolute"}
            justifyContent={"center"}
            alignItems={"center"}>
            <Stack
              position={"absolute"}
              top={72}
              width={"80%"}
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              mr={2}>
              <Stack>
                <Iconify
                  width={30}
                  height={30}
                  icon={arrowDirection}
                  color={indicatorColor}
                />
              </Stack>
              <Stack>
                <Typography
                  sx={{ cursor: "default" }}
                  fontSize={24}
                  fontWeight={"600"}>{`${currentProgress} ${unit}`}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Fragment>
    </Stack>
  );
}

const getGoalTypeString = (goalType: GoalTypeEnum | undefined): string => {
  switch (goalType) {
    case GoalTypeEnum.Gain:
      return `const.goalType.gainWeight`;
    case GoalTypeEnum.Lose:
      return `const.goalType.loseWeight`;
    case GoalTypeEnum.Maintain:
      return "const.goalType.maintainWeight";
    default:
      return "modalMealPlan.goalType";
  }
};
