import moment from "moment";
import intervalToDuration from "date-fns/intervalToDuration";
import { formatDistance, differenceInDays, format, addMonths } from "date-fns";
import i18next from "i18next";
import { TranslatorNS } from "../i18n/const";
import { utcToZonedTime } from "date-fns-tz";

export function getUnixEpoch(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

export function isValidAge(age: number | null) {
  return age && !Number.isNaN(age) && age >= 10 && 100 >= age;
}

export function getCurrentAge(birthday: string): number | null {
  if (birthday === "Invalid Date") return null;

  return moment().diff(moment(birthday), "years", false);
}

export function getCurrentAgeDateFns(birthday: string | null | undefined): number | null {
  if (!birthday) return null;
  try {
    const interval = intervalToDuration({
      start: new Date(birthday),
      end: new Date(),
    });

    return interval?.years || 0;
  } catch (e) {
    return null;
  }
}
/**
 *
 * catch error in case of invalid date and log start and end "dates"
 * @param start
 * @param end
 * @returns
 */
export function timeBetweenTwoDatesInWords(start: Date, end: Date): string {
  try {
    if (differenceInDays(start, end) === 0) {
      return i18next.t("helpers.today", { ns: TranslatorNS.CLIENT_OVERVIEW });
    }
    return formatDistance(start, end, { addSuffix: true });
  } catch (error) {
    console.error(
      `start=${JSON.stringify(start)} end=${JSON.stringify(end)} - error=${JSON.stringify(
        error,
      )}`,
    );
    return "Invalid date";
  }
}
export function timeBetweenTwoDatesInWordsWithoutToday(start: Date, end: Date): string {
  try {
    const localTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    const localStartDate = utcToZonedTime(start, localTimezone);
    const localEndDate = utcToZonedTime(end, localTimezone);

    return formatDistance(localStartDate, localEndDate, { addSuffix: true });
  } catch (error) {
    console.error(
      `start=${JSON.stringify(start)} end=${JSON.stringify(end)} - error=${JSON.stringify(
        error,
      )}`,
    );
    return "Invalid date";
  }
}

export function formatDate(date: string | Date | number, dateFormat: string) {
  try {
    const localTimezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    const localDate = utcToZonedTime(new Date(date), localTimezone);
    return format(localDate, dateFormat);
  } catch (error) {
    console.error(
      `date=${JSON.stringify(date)} format=${JSON.stringify(
        dateFormat,
      )} - error=${JSON.stringify(error)}`,
    );
    return "Invalid date";
  }
}

export function addMonthsDate(date: Date, months: number) {
  try {
    return addMonths(date, months);
  } catch (error) {
    console.error(
      `date=${JSON.stringify(date)} addMonths=${JSON.stringify(
        months,
      )} - error=${JSON.stringify(error)}`,
    );
    return "Invalid date";
  }
}
