import { computeKcals } from "./mealHelper";
import {
  ClientPalEnum,
  ClientType,
  GenderEnum,
  MeasuringSystemEnum,
} from "../generated/graphql";
import { MeasureSystem } from "../interfaces/measureSystem";
import { GoalTypeEnum } from "../routes/ClientOverview/Goal/helpers/getGoalType";

export type TComputeProperties = {
  pal: ClientType["pal"] | number;
  gender: ClientType["gender"];
  height: ClientType["height"];
  age: ClientType["age"];
  measuringSystem: ClientType["measuringSystem"];
  startWeight: ClientType["startWeight"];
  weightGoal: ClientType["weightGoal"];
};

/*This function is created as a middle layer to integrate the graphql types into the current types*/
//TODO, Rewrite so all this is graphql type based
export const getEstimatedCalories = (
  info: TComputeProperties,
  gramsPerWeek?: number | undefined,
  goalTypeOverwrite?: 1 | 2,
) => {
  const gender = info.gender === GenderEnum.Male ? 2 : 1;
  const goalType =
    info?.weightGoal && info?.startWeight && info?.startWeight > info?.weightGoal ? 1 : 2;

  const string =
    gramsPerWeek && gramsPerWeek < 0
      ? "clientInfo.estimatedLoss"
      : gramsPerWeek === 0
      ? "clientInfo.estimatedMaintain"
      : "clientInfo.estimatedGain";

  const pal =
    typeof info?.pal === "number" ? info?.pal || 1.3 : getPalConverted(info?.pal);

  const measuringSystem = getMeasuringSystem(info?.measuringSystem);

  const { tdee, kcals, bmr } = computeKcals(
    gender,
    Number(info.startWeight),
    Number(info.height),
    Number(info.age),
    pal,
    goalTypeOverwrite || goalType,
    gramsPerWeek ? Math.abs(gramsPerWeek) : 0,
    measuringSystem,
  );

  return {
    tdee,
    bmr,
    kcals,
    string,
  };
};

export const getPalConverted = (pal?: TComputeProperties["pal"]) => {
  switch (pal) {
    case ClientPalEnum.None:
      return 1.3;
    case ClientPalEnum.Low:
      return 1.6;
    case ClientPalEnum.Moderate:
      return 1.8;
    case ClientPalEnum.High:
      return 2.0;
    case ClientPalEnum.Extreme:
      return 2.2;
    default:
      return 1.3;
  }
};

const getMeasuringSystem = (measuringSystem?: MeasuringSystemEnum | null) => {
  switch (measuringSystem) {
    case MeasuringSystemEnum.Imperial:
      return MeasureSystem.IMPERIAL;
    case MeasuringSystemEnum.Metric:
      return MeasureSystem.METRIC;
    default:
      return MeasureSystem.METRIC;
  }
};

export const getWeeklyGain = (goalType: GoalTypeEnum | undefined): number => {
  switch (goalType) {
    case GoalTypeEnum.Lose:
      return -500;
    case GoalTypeEnum.Gain:
      return 500;
    default:
      return 0;
  }
};
