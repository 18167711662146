import { useState } from "react";
import { MealPlan, PlanId, WorkoutPlan } from "../../../types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { EXPORT_WORKOUT_PLAN_AS_PDF } from "../../../../../api/private/workout-api";
import { EXPORT_MEAL_PLAN_AS_PDF } from "../../../../../api/private/meals";
import { createGlobalStyle } from "styled-components";
import SUCCESS_ICON from "../../../../../assets/images/Editors/successIcon.png";

interface Props {
  id: PlanId;
  plan: MealPlan | WorkoutPlan;
}

export default function SaveAsPdf({ id, plan }: Props) {
  const { t } = useTranslation("workoutPlan");
  const [input, setInput] = useState(getTitle(plan));

  const DOCUMENT_URL = "/documents";
  const IS_MEAL = id === PlanId.MEAL;

  const POST_PATH = IS_MEAL
    ? EXPORT_MEAL_PLAN_AS_PDF(plan.id)
    : EXPORT_WORKOUT_PLAN_AS_PDF(plan.id);
  const TYPE = IS_MEAL ? "meal" : "workout";

  return (
    <>
      <SuccessIcon />
      <div
        className="modal inmodal fade sm reactModal"
        id="saveAsPdf"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-documents-url={DOCUMENT_URL}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                <span aria-hidden="true">×</span>
                <span className="sr-only">{t("saveAsPDF.close")}</span>
              </button>
              <h4 className="modal-title">
                {t(IS_MEAL ? "saveAsPDF.saveMealPlan" : "saveAsPDF.saveWorkoutPlan")}
              </h4>
            </div>
            <form
              action={POST_PATH}
              method="POST"
              autoComplete="off"
              onSubmit={e => e.preventDefault()}>
              <input type="hidden" name="type" value={TYPE} />
              <div className="modal-body">
                <div className="modal-body-main">
                  <p>
                    {t(
                      IS_MEAL
                        ? "saveAsPDF.clickBelowMealPlan"
                        : "saveAsPDF.clickBelowWorkoutPlan",
                    )}
                  </p>
                  <div className="form-group">
                    <label htmlFor="template_name" className="control-label">
                      {t("saveAsPDF.name")}
                    </label>
                    <input
                      type="text"
                      id="template_name"
                      name="name"
                      className="form-control"
                      placeholder={t("saveAsPDF.nameOfPDF")}
                      value={input}
                      onChange={({ target }) => setInput(target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="template_comment" className="control-label">
                      {t("saveAsPDF.comment")}
                    </label>
                    <input
                      type="text"
                      id="template_comment"
                      name="comment"
                      className="form-control"
                      placeholder={t("saveAsPDF.enterYourComment")}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-block btn-success btn-upper"
                  data-loading-text={t("saveAsPDF.saving")}>
                  {t("saveAsPDF.saveAsPDF")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const SuccessIcon = createGlobalStyle`
  .modal-success-icon{
    background: url(${SUCCESS_ICON}) no-repeat 0 0 / 50px 50px;
    width: 50px;
    height: 50px;
    margin: 0 auto 20px;
  }
`;

const getTitle = (plan?: WorkoutPlan | MealPlan) => {
  const val = plan ? plan.name : "template";

  return `${val} ${moment().format("DD. MMMM YYYY")}`;
};
