import { PlanId, RecipePlan } from "../types";
import { InitPlans } from "../vanilla";

const initRecipe = (plan?: RecipePlan) => {

  const wrapper = document.getElementById(PlanId.RECIPE)

  if (wrapper !== null) {
    // @ts-ignore
    // this is a class Call from index
    new InitPlans(wrapper, InitPlans.RECIPE, {
      locale: plan?.locale,
      plan: plan,
      template: plan?.template,
      admin: plan?.admin
    });
  }
};

export default initRecipe;
