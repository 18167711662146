import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Nested from "./Nested";
import EditorLoader from "./Loader";
import { WorkoutPlanDay } from "../types";
import { useTheme } from "@mui/material/styles";
import Header from "./Header";
import {
  GetWorkoutPlanTemplateDocument,
  useGetWorkoutPlanTemplateQuery,
  useUpdateWorkoutDayMutation,
} from "../../../generated/graphql";
import { transformGraphqlToClientState } from "../utils";
import SeverErrorIllustration from "../../../assets/error";
import Stack from "@mui/material/Stack";
import { DialogContext, WorkoutPlanModalType } from "../Dialog/DialogProvider";
import DraggableTabs from "./DraggableTabs";

export default function PlanEditorContainer() {
  const { templateId } = useParams<{ templateId: string }>();
  const { data, loading, error } = useGetWorkoutPlanTemplateQuery({
    variables: { id: Number(templateId) },
  });

  const workoutPlanByDays = transformGraphqlToClientState(data);

  if (loading) return <EditorLoader />;
  if (error) {
    return (
      <Stack
        sx={{
          height: "100%",
          p: 16,
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}>
        <SeverErrorIllustration />
        <Box sx={{ pt: 2 }} />
        <Typography variant="subtitle2">{error?.message}</Typography>
      </Stack>
    );
  }
  const initialDay = workoutPlanByDays[0]?.id;
  return (
    <>
      <PlanEditor workoutPlanByDays={workoutPlanByDays} initialDay={initialDay} />
    </>
  );
}

function PlanEditor({
  initialDay,
  workoutPlanByDays,
}: {
  initialDay: number;
  workoutPlanByDays: WorkoutPlanDay[];
}) {
  const { templateId } = useParams<{ templateId: string }>();
  const theme = useTheme();
  const [updateWorkoutDay] = useUpdateWorkoutDayMutation();

  const [tab, setTab] = React.useState(initialDay);
  const { setModal } = React.useContext(DialogContext);
  const currentWorkoutDay = workoutPlanByDays.find(w => w.id === tab);

  const openAddDayDialog = () => setModal({ type: WorkoutPlanModalType.ADD_DAY });

  const openModifyDayModal = () => {
    if (!currentWorkoutDay) return;
    // would be nice to have this in a redux so I only have to pass the id
    setModal({
      type: WorkoutPlanModalType.MODIFY_DAY,
      context: {
        id: currentWorkoutDay.id,
        title: currentWorkoutDay.name,
        description: currentWorkoutDay.description,
        isOnlyDay: workoutPlanByDays.length <= 1,
      },
    });
  };

  const onDragEnd = async ({ id, newIndex }: { id: number; newIndex: number }) => {
    try {
      if (!id) return;
      if (!currentWorkoutDay) return;

      // some update mutation
      await updateWorkoutDay({
        variables: {
          id: Number(id),
          input: {
            order: newIndex + 1,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        borderLeft: "1px solid",
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
      }}>
      <Header />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: theme.palette.grey[300],
          pl: 2,
          pr: 2,
        }}>
        <DraggableTabs
          tabs={workoutPlanByDays}
          initialTab={initialDay}
          setActiveTab={setTab}
          activeAction={openModifyDayModal}
          onDragEnd={onDragEnd}
          openAddDayDialog={openAddDayDialog}
        />
      </Stack>

      <Box
        sx={{
          p: 2,
          height: "100vh - 190px",
          overflowY: "scroll",
          backgroundColor: "#FCFCFC",
        }}>
        {workoutPlanByDays.map(day => (
          /**
           * Switching to not unmounting the days when plan renders to avoid
           * onBlur not being fired before the component is unmounted, and
           * therefore not saved. Hidding it from view instead, solves that issues
           * And should not effect the other functionality
           */
          <Box
            key={day.id}
            sx={
              currentWorkoutDay?.id === day.id
                ? { display: "inherit" }
                : { display: "none" }
            }>
            <Nested key={day.id} dayId={day.id} workoutPlan={day.workouts} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}
