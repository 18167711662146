import { IClientVideo } from "../../../useVideosQuery/interfaces";
import { Link, Stack } from "@mui/material";
import Avatar from "../../../../../components/_MUI/Avatar";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import Typography from "@mui/material/Typography";

interface Props {
  row: IClientVideo;
}

export default function ClientVideosTitle(props: Props) {
  const { row } = props;
  return (
    <Stack width={"100%"}>
      <Link
        sx={{
          color: "black",
          flex: 1,
          height: "100%",
          width: "100%",
        }}
        target={"_blank"}
        href={row.url}>
        <Stack flexDirection={"row"} alignItems={"center"}>
          <Avatar src={row.picture} sx={{ width: 35, height: 35, borderRadius: 1 }}>
            <InsertPhotoIcon
              color={"inherit"}
              sx={{ width: "15px !important", height: "15px !important" }}
            />
          </Avatar>
          <Typography
            sx={{
              flex: 1,
              ml: 2,
              height: "100%",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              "&:hover": { textDecoration: "underline" },
            }}>
            {row.title}
          </Typography>
        </Stack>
      </Link>
    </Stack>
  );
}
