import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TranslatorNS } from "../../../i18n/const";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider } from "../../../components/_MUI/hook-form";
import RHFTextField from "../../../components/_MUI/hook-form/RHFTextField";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import {
  GetWorkoutPlanTemplateDocument,
  useDeleteWorkoutDayMutation,
  useUpdateWorkoutDayMutation,
  useDuplicateWorkoutDayMutation,
} from "../../../generated/graphql";
import {
  WorkoutPlanModalType,
  ModalContextForType,
  DialogContext,
} from "./DialogProvider";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const addDaySchema = () =>
  Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string(),
  });

interface FormValuesProps {
  title: string;
  description: string;
}

export default function ModifyDayDialog() {
  const theme = useTheme();
  const { templateId } = useParams<{ templateId: string }>();
  const { modal, setModal } = React.useContext(DialogContext);
  const { t } = useTranslation(TranslatorNS.PLANS);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [updateWorkoutDay, submitState] = useUpdateWorkoutDayMutation();
  const [deleteWorkoutDay, deleteState] = useDeleteWorkoutDayMutation();
  const [duplicateWorkoutDay, duplicateState] = useDuplicateWorkoutDayMutation();

  const isOpen = modal.type === WorkoutPlanModalType.MODIFY_DAY;
  const context = modal.context as ModalContextForType<WorkoutPlanModalType.MODIFY_DAY>;

  const handleClose = () => setModal({ type: null });

  const defaultValues = {
    title: context?.title,
    description: context?.description,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(addDaySchema()),
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;
  React.useEffect(() => {
    setValue("title", context?.title);
    setValue("description", context?.description);
    return () => {};
  }, [isOpen]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!templateId) return;
      // some update mutation
      await updateWorkoutDay({
        variables: {
          id: Number(context?.id),
          input: {
            name: data.title,
            description: data.description,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      if (!templateId || !context?.id) return;
      await deleteWorkoutDay({
        variables: {
          id: Number(context.id),
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDuplicate = async (data: FormValuesProps) => {
    try {
      if (!templateId || !context?.id) return;
      await duplicateWorkoutDay({
        variables: {
          id: Number(context.id),
          input: {
            name: data?.title,
            description: data?.description || undefined,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <IconButton onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
        <CloseOutlinedIcon />
      </IconButton>
      <DialogTitle>{t("index.editWorkoutDayModal.title")}</DialogTitle>
      <DialogContent>
        <Stack sx={{ pt: 2, pb: 2 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <RHFTextField name="title" label={t("index.titleOfWorkoutDay")} />
            <RHFTextField
              name="description"
              multiline
              minRows={3}
              label={t("index.modifyWorkoutPlanDescription.modal.placeholder")}
            />
            <Stack sx={{ pt: 2 }} direction="row" justifyContent="space-between">
              <LoadingButton
                variant="outlined"
                color="error"
                disabled={context?.isOnlyDay}
                loading={deleteState.loading}
                onClick={handleDelete}
                size="large">
                {t("index.editWorkoutDayModal.delete")}
              </LoadingButton>
              <Stack direction="row">
                <LoadingButton
                  sx={{mr: 2}}
                  variant="outlined"
                  loading={duplicateState.loading}
                  onClick={handleSubmit(handleDuplicate)}
                  size="large">
                  {t("index.copy.workoutDay")}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  loading={submitState.loading}
                  onClick={handleSubmit(onSubmit)}
                  size="large">
                  {t("index.save.workoutDay")}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
