import { Button, Dialog, DialogTitle, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { ClientPaymentType } from "../../../../generated/graphql";
import DialogContent from "@mui/material/DialogContent";
import useRefundAction from "./useRefundAction";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

interface Props {
  chargeId: ClientPaymentType["chargeId"];
  currency: ClientPaymentType["currency"];
  amount: ClientPaymentType["amount"];
  payment: ClientPaymentType;
  onClose: () => void;
}

export default function RefundModal(props: Props) {
  const { chargeId, amount, currency, onClose, payment } = props;
  const { clientId } = useParams();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { loading, refundClient } = useRefundAction({
    clientId: Number(clientId),
    chargeId,
    payment,
  });

  const handleRefund = () => {
    refundClient().then(() => onClose());
  };
  const handleCloseModal = () => {
    onClose();
  };
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        {t("clientPayment.areYouSureRefund", {
          name: `${currency?.toUpperCase()} ${amount}`,
        })}
      </DialogTitle>
      <DialogContent>
        <Stack mt={2} flexDirection={"row"} justifyContent={"flex-end"}>
          <Stack flex={0.5} flexDirection={"row"} justifyContent={"space-between"}>
            <LoadingButton
              loading={loading}
              variant="contained"
              fullWidth
              size={"large"}
              onClick={handleRefund}>
              {t("confirmNeed.yes")}
            </LoadingButton>
            <Button
              sx={{ ml: 1 }}
              variant="outlined"
              fullWidth
              size={"large"}
              onClick={handleCloseModal}>
              {t("confirmNeed.no")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
