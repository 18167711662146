import { TFunction } from "i18next";
import { ERROR_MESSAGE } from "../config";

export interface ErrorResponse extends Error {
  response: {
    data: {
      error_list: { [key: string]: string };
    };
  };
}

export function extractResponseError(error: ErrorResponse) {
  const errorList = error?.response?.data?.error_list

  return errorList
    ? Object.entries(errorList)
      .map(([_, value]) => value)
      .join("\n")
    : ERROR_MESSAGE;
}

export function extractTranslatedError(error: ErrorResponse, t: TFunction) {
  const errorList = error?.response?.data?.error_list

  return errorList
    ? Object.entries(errorList)
      .map(([_, value]) => t(value))
      .join("\n")
    : ERROR_MESSAGE;
}