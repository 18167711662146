import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import TaskDescription from "./TaskDescription";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CurrentClientState, TaskStatus } from "../../../../store/currentClient/types";
import { track } from "../../../../helpers/analytics";
import { handleTaskResolve } from "../helpers";
import { ClientTaskType } from "../../../../interfaces/clientTaskType";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { connect } from "react-redux";
import { AppState } from "../../../../store";
import { Locale } from "../../../../interfaces/locale";
import { resolveTaskReminderAction } from "../../../../store/currentClient/action";
import { handleSubscriptionModal } from "../../../Clients/store/clients/actions";
import { formatDate } from "../../../../helpers/dates";

interface Props {
  task: TaskStatus;
  clientDetails: CurrentClientState;
  locale: Locale;
  userId: number;
  resolveTaskReminderAction: (id: number) => void;
  handleSubscriptionModal: (open: boolean, client: CurrentClientState) => void;
  extendClientDurationTaskId: () => void;
  selectedTaskWithConfirmation: () => void;
  ignoreClientTaskAction: () => void;
  modalRef: any;
  endDate: string | undefined;
}

function TaskItem(props: Props) {
  const {
    task,
    locale,
    clientDetails,
    userId,
    resolveTaskReminderAction,
    handleSubscriptionModal,
    extendClientDurationTaskId,
    selectedTaskWithConfirmation,
    ignoreClientTaskAction,
    modalRef,
    endDate,
  } = props;

  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const onTaskResolveClick = async (
    eventName: string,
    taskId: number,
    eventAction: string,
  ) => {
    track("Task Resolved", "Client Page Task List", {
      eventName,
      taskId,
    });

    if (eventName === "client.payment_failed") {
      //@ts-ignore
      modalRef && modalRef?.current.portal.requestClose();
      return;
    }

    const responseAction = await handleTaskResolve(
      clientDetails,
      userId,
      eventName,
      locale,
      eventAction,
      navigate,
    );

    if (!responseAction) return;

    if (responseAction === "extend") {
      extendClientDurationTaskId();
    } else if (responseAction === "reminder") {
      resolveTaskReminderAction(taskId);
    } else if (responseAction === "resubscribe") {
      handleSubscriptionModal(true, clientDetails);
      /**
       * TODO: not a fan of passing flags arbitrarily and not using it same way other places like (MoreActions).
       */
    } else if (responseAction === ClientTaskType.RESEND_QUESTIONAIRE_SUCCESS) {
      enqueueSnackbar(t("question.questionnaireSent"), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });
      // Added error state (maybe it needs a different text?)
    } else if (responseAction === ClientTaskType.RESEND_QUESTIONAIRE_FAILED) {
      enqueueSnackbar(t("question.sendQuestionnaireError"), {
        variant: "error",
        persist: false,
        preventDuplicate: true,
      });
    }
  };

  const handleTaskIgnore = (task: TaskStatus) => {
    if (!task.event_name) return;

    track("Task Ignored", "Client Page Task List", {
      eventName: task.event_name,
      taskId: task.id,
    });
    if (task.event_name === "client.need_welcome") {
      selectedTaskWithConfirmation();
    } else {
      ignoreClientTaskAction();
    }
  };

  return (
    <FormControlLabel
      disabled
      sx={{ mb: 2.5, pl: 2 }}
      control={<Checkbox checked={task.resolved} />}
      label={
        <Stack direction="column">
          <Typography sx={{ pl: "3px" }}>
            {task.event_name ? t(`events.${task.event_name}`) : task.title}
            {task?.event_name === "client.ending_soon" &&
              endDate &&
              ` [${formatDate(endDate, "dd MMM")}]`}
            {task?.dueDate?.date && ` [${moment(task.dueDate.date).format("DD MMM")}]`}
          </Typography>
          <TaskDescription
            task={task}
            onTaskResolveClick={onTaskResolveClick}
            handleTaskIgnore={handleTaskIgnore}
          />
        </Stack>
      }
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    clientDetails: state.currentClient,
    endDate: state.currentClient?.endDate?.split(" ")?.[0],
    userId: state.user.id,
    locale: state.currentClient.locale,
  };
};

const mapDispatchToProps = {
  resolveTaskReminderAction,
  handleSubscriptionModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskItem);
