import createRequestTypes from "../../helpers/createReduxActionTypes";

// Chat Widget
export const MESSAGES_FETCH = createRequestTypes("CHAT_WIDGET.MESSAGES_FETCH");
export const MESSAGES_UPDATE = "CHAT_WIDGET.MESSAGES_UPDATE";
export const MESSAGES_READ = "CHAT_WIDGET.MESSAGES_READ";
export const MESSAGE_PENDING = "pending";
export const MESSAGE_READ = "read";

export const INIT_CHAT_WIDGET = "INIT_CHAT_WIDGET";
export const TOGGLE_CHAT_WIDGET_OPEN = "TOGGLE_CHAT_WIDGET_OPEN";
export const DEFAULT_MESSAGE_TYPES = {
  9: {
    title: "constants.defaultMessageTypes.9.title",
    subtitle: "constants.defaultMessageTypes.9.subtitle",
  },
  13: {
    title: "constants.defaultMessageTypes.13.title",
    subtitle: "constants.defaultMessageTypes.13.subtitle",
  },
};
