import {
  FormEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import Select from "react-select";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { RecipePlan } from "../../../types";
import { ROOT } from "../../../../../api/config";
import { Locales } from "../../../../../i18n/locales";
import { TranslatorNS } from "../../../../../i18n/const";
import useMacroSplit from "../../../../../hooks/useMacroSplit";
import { COOKING_TIME, MEAL_TYPE } from "../../../../Recipes/const";
import useMutationObserver from "../../../../../hooks/useMutationObserver";
import { GET_RECIPE, UPDATE_RECIPES } from "../../../../../api/private/recipes";

// @ts-ignore
const $ = global.jQuery || {};

interface Props {
  plan?: RecipePlan;
  refetchFn: () => void;
}

type TypeOption = {
  value: string;
  label: string;
};

interface RecipePlanState extends RecipePlan {
  typesState: TypeOption[];
}

const getRequestPayload = (
  statePlan: RecipePlanState,
  isRecipePage: boolean,
  sourceMealId?: string,
) => {
  const { id, name, locale, macro_split, cooking_time, admin, typesState, servings } =
    statePlan || {};

  const types = typesState.map(t => t.value) || [];
  const formattedRecipeMeta = Object.keys(statePlan?.recipeMeta || {}).filter(
    key => !!statePlan?.recipeMeta[key],
  );

  if (isRecipePage) {
    return {
      name,
      locale,
      macroSplit: macro_split,
      cookingTime: cooking_time,
      types,
      attributes: formattedRecipeMeta,
      servings: servings || 1,
    };
  } else {
    const formData = new FormData();

    const requestData: { [key: string]: number | string | string[] } = {
      recipe: id,
      admin: Number(admin) || 0,
      locale,
      macro_split,
      cooking_time,
      name,
      type: types,
      avoid: formattedRecipeMeta,
      sourceMeal: Number(sourceMealId),
      mode: "save",
    };

    for (const key in requestData) {
      if (Array.isArray(requestData[key])) {
        formData.append(key, JSON.stringify(requestData[key]));
      } else {
        formData.append(key, requestData[key] as string);
      }
    }

    return formData;
  }
};

export default function RecipeModal({ plan, refetchFn = () => {} }: Props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [statePlan, setStatePlan] = useState<RecipePlanState>();

  const { enqueueSnackbar } = useSnackbar();
  const { zenfitMacroSplitMap, lenusMacroSplitMap } = useMacroSplit();
  const { t } = useTranslation([
    TranslatorNS.MEAL,
    TranslatorNS.LISTS,
    TranslatorNS.CLIENT_OVERVIEW,
  ]);

  const macroSplits = statePlan?.isLenusRecipe ? lenusMacroSplitMap : zenfitMacroSplitMap;
  const PLAN_DESCRIPTION = statePlan ? statePlan.description : "";

  /**
   * Meal plan builder modal logic START
   */
  const modalRef = useRef<HTMLDivElement | null>(null);
  const recipeIdInputRef = useRef<HTMLInputElement | null>(null);
  const sourceMealInputRef = useRef<HTMLInputElement | null>(null);

  const getMealModalData = useCallback(async () => {
    // If we are getting the props from Plan, return;
    if (
      plan?.id ||
      modalRef.current?.ariaHidden === "true" ||
      !recipeIdInputRef.current?.value
    )
      return;

    try {
      setLoading(true);

      const { data } = await axios.get(
        GET_RECIPE(Number(recipeIdInputRef.current?.value)),
      );

      setStatePlan({
        ...data,
        typesState: getSelectedRecipeTypes(data.types),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [plan?.id]);

  // Listen for modal DOM changes
  useMutationObserver(modalRef, getMealModalData);

  /**
   * Meal plan builder modal logic END
   */

  const mealTypeOptions = useMemo(
    () =>
      Object.entries(MEAL_TYPE).map(([key, string]) => ({
        value: key,
        label: t(string, { ns: "lists" }),
      })),
    [],
  );

  const getSelectedRecipeTypes = useCallback(
    (types: { id: number; type: number }[]) => {
      return types.reduce<TypeOption[]>((array, type) => {
        const typeOption = mealTypeOptions.find(o => o.value === String(type.type));
        if (typeOption) {
          return [...array, typeOption];
        }
        return array;
      }, []);
    },
    [mealTypeOptions],
  );

  useLayoutEffect(() => {
    if (!plan) return;

    setStatePlan({
      ...plan,
      typesState: getSelectedRecipeTypes(plan.types),
    });
  }, [plan]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (!statePlan?.typesState.length) {
        setError(t("recipes.recipeModal.youNeedToSpecifyType"));
        return;
      } else if (error) {
        setError(null);
      }

      await axios.post(
        !!plan?.id ? UPDATE_RECIPES(plan.id) : ROOT + "/meal/recipes",
        getRequestPayload(statePlan, !!plan?.id, sourceMealInputRef.current?.value),
      );

      $("#recipeModal").modal("hide");
      enqueueSnackbar(t("recipes.recipeModal.recipeSuccessfullySaved"), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });

      refetchFn();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleStatePlanUpdate = (
    stateKey: keyof RecipePlanState,
    value: RecipePlanState[keyof RecipePlanState],
  ) => {
    if (!statePlan) return;

    setStatePlan({
      ...statePlan,
      [stateKey]: value,
    });
  };

  return (
    <div
      ref={modalRef}
      className="modal inmodal in sm reactModal"
      id="recipeModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-title={t("recipes.recipeModal.recipe")}
      data-description={PLAN_DESCRIPTION}>
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("recipes.recipeModal.close")}</span>
            </button>
            <h4 className="modal-title">{t("recipes.recipeModal.recipe")}</h4>
            <p className="modal-description">{PLAN_DESCRIPTION}</p>
          </div>
          <form
            method="POST"
            id="createRecipeForm"
            autoComplete="off"
            onSubmit={onSubmit}
            encType="multipart/form-data">
            <input
              ref={sourceMealInputRef}
              type="hidden"
              name="sourceMeal"
              id="recipeSourceMeal"
            />
            <input type="hidden" name="admin" value={Number(statePlan?.admin) || 0} />
            <div className="modal-body">
              {Boolean(error) && <div className="alert alert-danger">{error}</div>}
              <input
                ref={recipeIdInputRef}
                type="hidden"
                name="recipeId"
                id="recipeId"
                value={statePlan?.id || ""}
              />
              <div className="form-group">
                <select
                  className="form-control"
                  name="locale"
                  id="recipeLocale"
                  value={statePlan?.locale || ""}
                  onChange={({ target }) =>
                    handleStatePlanUpdate("locale", target.value)
                  }>
                  {Object.entries(Locales).map(([key, lang]) => (
                    <option value={key} key={key}>
                      {t(lang, { ns: TranslatorNS.SHARED })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="recipeName" className="control-label">
                  {t("recipes.recipeModal.recipeName")}
                </label>
                <input
                  type="text"
                  name="name"
                  id="recipeName"
                  className="form-control title"
                  placeholder={t("recipes.recipeModal.nameOfRecipe")}
                  required
                  onChange={({ target }) => handleStatePlanUpdate("name", target.value)}
                  value={statePlan?.name || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recipeName" className="control-label">
                  {t("ingredients.main.servings", { ns: TranslatorNS.LISTS })}
                </label>
                <input
                  type="text"
                  name="servings"
                  id="recipeName"
                  className="form-control title"
                  placeholder={t("ingredients.main.servings", { ns: TranslatorNS.LISTS })}
                  required
                  onChange={({ target }) =>
                    handleStatePlanUpdate("servings", target.value)
                  }
                  value={statePlan?.servings}
                />
              </div>
              <div className="form-group">
                <label htmlFor="recipeMacroSplit" className="control-label">
                  {t("recipes.recipeModal.chooseMacroSplit")}
                </label>
                <select
                  className="form-control"
                  name="macro_split"
                  id="recipeMacroSplit"
                  value={statePlan?.macro_split || ""}
                  onChange={({ target }) =>
                    handleStatePlanUpdate("macro_split", target.value)
                  }>
                  {Object.keys(macroSplits).map(key => (
                    <option value={key} key={key}>
                      {macroSplits[Number(key)]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="recipeType" className="control-label">
                  {t("recipes.recipeModal.chooseType")}
                </label>
                <Select
                  options={mealTypeOptions}
                  id="recipeType"
                  isMulti
                  isSearchable={false}
                  placeholder={t("recipes.recipeModal.chooseType")}
                  value={statePlan?.typesState || []}
                  onChange={newValues =>
                    handleStatePlanUpdate("typesState", [...newValues])
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="recipeMacroSplit" className="control-label">
                  {t("recipes.recipeModal.cookingTime")}
                </label>
                <select
                  className="form-control"
                  name="cooking_time"
                  id="recipeCookingTime"
                  value={statePlan?.cooking_time || ""}
                  onChange={({ target }) =>
                    handleStatePlanUpdate("cooking_time", target.value)
                  }>
                  {Object.entries(COOKING_TIME()).map(([key, string]) => (
                    <option value={key} key={key}>
                      {string}
                    </option>
                  ))}
                </select>
              </div>
              {/*Render ingredients*/}
              <div className="form-group">
                <label className="control-label">
                  {t("recipes.recipeModal.recipeIngredients", { ns: "meal" })}
                </label>
                <div className="area meal-area avoid-checkbox">
                  {Object.entries(statePlan?.recipeMeta || {}).map(([key]) => {
                    const enabled = statePlan?.recipeMeta[key];
                    const ContainsIS = key.slice(0, 2) === "is";
                    const getCapKey = key[0].toLocaleUpperCase() + key.slice(1);
                    return (
                      <div className="col meal-col" key={key}>
                        <label
                          htmlFor={
                            ContainsIS ? `is_${key.split("is")[1]}` : `avoid_${key}`
                          }
                          className={`btn ${enabled ? "current" : ""}`}
                          onClick={() =>
                            handleStatePlanUpdate("recipeMeta", {
                              ...statePlan?.recipeMeta,
                              [key]: !statePlan?.recipeMeta[key],
                            })
                          }>
                          {t(`recipes.const.recipeAttributes.${key}`, {
                            ns: TranslatorNS.LISTS,
                          })}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-block btn-success btn-upper">
                {t(loading ? "generatePlanModal.loading" : "recipes.recipeModal.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
