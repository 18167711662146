import React, { useEffect } from "react";
import { ClientType } from "../../../../generated/graphql";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { newEmailSchema } from "../newEmailSchema";
import { Button, DialogActions, DialogContent, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import Alert from "@mui/material/Alert";
import { FormProvider, RHFTextField } from "../../../_MUI/hook-form";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import RHFQuill from "../../../_MUI/hook-form/RHFQuill";
import { TDefaultEmailTemplates } from "../hooks/useGetEmailTemplates";
import RHFSelect from "../../../_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import { prepareMessageUsingTags } from "../../../SideContent/helper";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import Box from "@mui/material/Box";
import usePostEmail from "../hooks/usePostEmail";
import { DefaultMessageTypeEnum } from "../../../../api/private/default-messages";

export type TNewEmailFields = {
  clientId: ClientType["id"];
  to: ClientType["email"];
  subject: string;
  message: string;
  template: number | null;
  queueId: number;
  dataKey: string | undefined;
  type: DefaultMessageTypeEnum;
};

interface Props {
  defaultValues: TNewEmailFields;
  onClose: () => void;
  defaultTemplates: TDefaultEmailTemplates[];
}

export default function FormFields(props: Props) {
  const { defaultValues, onClose, defaultTemplates } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  const methods = useForm<TNewEmailFields>({
    resolver: yupResolver(newEmailSchema),
    defaultValues,
  });

  const { watch, setValue, handleSubmit } = methods;

  const values = watch();

  useTemplateChange(watch("template"), setValue, defaultTemplates);

  const {
    handleSaveEmailTemplate,
    handleSubmitEmail,
    sendingEmail,
    savingEmailTemplate,
  } = usePostEmail();

  return (
    <>
      <div
        style={{ display: "none" }}
        data-datakey={values?.dataKey}
        data-cy={CypressTestId.ADD_CLIENT_DATAKEY}
      />
      <DialogContent
        sx={{
          pt: { xs: 1, sm: 1 },
          pb: { xs: 1, sm: 5 },
        }}>
        <Stack pb={2}>
          <Alert
            sx={{ width: "100%!important", height: "100%!important" }}
            severity="info">
            {t("invitation.alert")}
          </Alert>
        </Stack>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitEmail)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField name="to" label={t("invitation.email")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFTextField name="subject" label={t("invitation.subject")} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHFSelect name={"template"} fullWidth label={t("invitation.template")}>
                {(defaultTemplates || []).map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Grid>
          </Grid>
          <RHFQuill name="message" label={t("invitation.email")} />
        </FormProvider>
        <Stack>
          <Box display="flex" justifyContent="flex-end" marginTop={1}>
            <LoadingButton
              variant="text"
              disabled={!Boolean(watch("message"))}
              loading={savingEmailTemplate}
              onClick={() => handleSaveEmailTemplate(values, defaultTemplates)}
              sx={{ textTransform: "none" }}>
              {t("invitation.saveEmailAsTemplate")}
            </LoadingButton>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t("addClient.closeClient")}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(handleSubmitEmail)}
          loading={sendingEmail}>
          {t("clientField.next")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}

const useTemplateChange = (
  templateId: TNewEmailFields["template"],
  setValue: UseFormSetValue<TNewEmailFields>,
  defaultTemplate: TDefaultEmailTemplates[],
) => {
  useEffect(() => {
    if (templateId) {
      const selectedTemplate =
        defaultTemplate[defaultTemplate.findIndex(t => t.id === templateId)];

      const subject =
        prepareMessageUsingTags(
          selectedTemplate?.subject,
          selectedTemplate?.placeholders,
          false,
        ) || "";

      const message =
        prepareMessageUsingTags(
          selectedTemplate.message,
          selectedTemplate.placeholders,
          false,
        ) || "";

      setValue("subject", subject);
      setValue("message", message);
    }
  }, [templateId]);
};
