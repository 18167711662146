export const getVideoIdFromYouTubeVimeo = (
  url: string,
  isException?: boolean,
  key?: string | null,
  t?: any,
) => {
  const YoutubeID = extractYouTubeVideoId(url);
  const VimeoID = extractVimeoVideoId(url);

  if (YoutubeID) {
    return {
      youtube: YoutubeID,
      vimeo: undefined,
    };
  }

  if (VimeoID) {
    return {
      youtube: undefined,
      vimeo: VimeoID,
    };
  }

  if (isException) {
    //@ts-ignore
    throw { [key]: t("videohelper.isNotValid", { url: url }) };
  }

  return null;
};

export const extractVimeoVideoId = (url: string): string | null => {
  const vimeoRegex =
    /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;

  const match = url.match(vimeoRegex);

  if (match) {
    return match[3];
  }

  return null;
};

export const getSupportedVideoUrl = (url: string | undefined) => {
  if (!url) return null;

  const YoutubeID = extractYouTubeVideoId(url);
  const VimeoID = extractVimeoVideoId(url);

  if (YoutubeID) {
    return getYoutubeVideoUrlFromId(YoutubeID);
  }

  if (VimeoID) {
    return url;
  }

  return null;
};

export const getYoutubeVideoUrlFromId = (videoId: string) => {
  return `https://www.youtube.com/watch?v=${videoId}`;
};

export const extractYouTubeVideoId = (url: string): string | null => {
  const regularVideoRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]{11})/;

  const shortsVideoRegex = /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w-]{11})/;
  const matchRegular = url.match(regularVideoRegex);
  const matchShorts = url.match(shortsVideoRegex);

  if (matchRegular) {
    return matchRegular[1];
  }

  if (matchShorts) {
    return matchShorts[1];
  }

  return null;
};

export const getYoutubeThumbnail = (videoId: string) => {
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
};
export const getVimeoThumbnail = (videoId: string) => {
  return `https://vumbnail.com/${videoId}.jpg`;
};
