import { gql, Reference, StoreObject, useApolloClient } from "@apollo/client";
import { EventNameEnum } from "../../../generated/graphql";

// This is a temporary solution to connect redux to apollo store
export default function useUpdateClientListCache() {
  const client = useApolloClient();

  const updateClientListCache = () => {
    client.cache.modify({
      fields: {
        me() {},
        clientFilterCount() {},
        clients() {},
      },
    });
  };

  const addWorkoutPlanCache = (clientId: number, templateId: number) => {
    client.writeFragment({
      id: `ClientType:${clientId}`,
      fragment: gql`
        fragment WorkoutPlan on ClientType {
          workoutPlans {
            id
          }
        }
      `,
      data: {
        workoutPlans: [
          {
            __typename: "WorkoutPlanType",
            id: templateId,
          },
        ],
      },
    });
  };

  const updateUnAnsweredMessageCount = (clientId: number) => {
    client.cache.modify({
      id: "ClientType:" + clientId,
      fields: {
        unresolvedTasks(existingRef = [], { readField }) {
          return existingRef.filter((task: StoreObject | Reference | undefined) => {
            return (
              EventNameEnum.UnansweredMessage !==
              readField("name", readField("event", task))
            );
          });
        },
      },
    });
  };

  const addMealPlanCache = (clientId: number, mealPlanId: number) => {
    client.writeFragment({
      id: `ClientType:${clientId}`,
      fragment: gql`
        fragment MealPlan on ClientType {
          mealPlans {
            id
          }
        }
      `,
      data: {
        mealPlans: [
          {
            __typename: "MealPlanType",
            id: mealPlanId,
          },
        ],
      },
    });
  };

  return {
    updateClientListCache,
    addWorkoutPlanCache,
    addMealPlanCache,
    updateUnAnsweredMessageCount,
  };
}
