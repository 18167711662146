import React, { useEffect } from "react";
import { connect } from "react-redux";
import ZFLangSwitch from "../../../components/LangSwitch";
import ZFPoweredBy from "../../../components/PoweredBy";
import ZFProgressIndicator from "../components/ProgressIndicator";
import Title from "./Title";
import { LangPlacement } from "../../../components/LangSwitch/ZFLangSwitchStyled";
import { AppState } from "../../../store";
import { changeLangAction, changeStepAction } from "../store/action";
import Stack from "@mui/material/Stack";
import { LocaleEnum } from "../../../generated/graphql";

interface Props {
  stepAttr: any;
  logo?: string;
  stepsCount: any;
  locale: LocaleEnum;
  changeLangActionDispatch: (locale: LocaleEnum) => void;
  changeStepActionDispatch: (step: number) => void;
  children: any;
  currentStep: number;
}

const SignUpWrapper = ({
  stepAttr,
  logo,
  stepsCount,
  locale,
  changeLangActionDispatch,
  changeStepActionDispatch,
  children,
  currentStep,
}: Props) => {
  useEffect(() => {
    changeLangActionDispatch(locale);
  }, []);

  return (
    <Stack>
      <ZFProgressIndicator
        currentStep={currentStep}
        changeStep={changeStepActionDispatch}
        bars={stepsCount}
      />
      <Stack
        alignItems={"center"}
        minHeight={"75vh"}
        bgcolor={"#fcfcfc"}
        p={{ xs: 2, sm: 1 }}
        justifyContent={"center"}>
        {logo && currentStep === 1 && (
          <Stack
            sx={{
              width: "12rem",
              height: "5rem",
              backgroundSize: "contain",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${logo})`,
            }}
          />
        )}
        {stepAttr && (
          <Title
            bigSubtitle={stepAttr.bigSubTitle}
            title={stepAttr.title}
            subTitle={stepAttr.subTitle}
          />
        )}
        {children}
      </Stack>
      <Stack paddingY={{ xs: 5, sm: 2 }}>
        <ZFLangSwitch
          chosenLang={locale}
          changeLang={changeLangActionDispatch}
          placement={LangPlacement.RIGHT}
        />
        <ZFPoweredBy />
      </Stack>
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    locale: clientActivation.locale,
    currentStep: clientActivation.step,
  };
}

const mapDispatchToProps = {
  changeLangActionDispatch: changeLangAction,
  changeStepActionDispatch: changeStepAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpWrapper);
