import { CircularProgress, Stack, Typography } from "@mui/material";
import { getLocalStorage, LocalStorageKeysEnum } from "../../../../helpers/localStorage";
import { formatDate } from "../../../../helpers/dates";
import { addHours, differenceInMinutes, isBefore } from "date-fns";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";

export default function TransferInProgress() {
  const { estimate, percentage } = getTransferStartedDate();
  const { t } = useTranslation(TranslatorNS.AUTH);
  return (
    <Stack maxWidth={"45%"} height={{ xs: "100vh", sm: "auto" }}>
      <Typography color={"white"} textAlign={"center"} fontSize={"32px"}>
        {t("transfer.transferring")}
      </Typography>
      <Typography color={"white"} textAlign={"center"}>
        {t("transfer.transferringHours")}
      </Typography>
      <CircularProgressWithLabel value={percentage} />
    </Stack>
  );
}

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "flex", justifyContent: "center", mt: 4 }}>
      <CircularProgress size={150} {...props} />
    </Box>
  );
}

const getTransferStartedDate = () => {
  const transferStartedDate = getLocalStorage(LocalStorageKeysEnum.LenusTransferStarted);

  if (!transferStartedDate) {
    return {
      estimate: "2-3 hours",
      percentage: 25,
    };
  }

  try {
    const date = new Date(JSON.parse(transferStartedDate));
    const currentDate = new Date();

    const addedHours = 3;
    const addedMinutes = addedHours * 60;
    const dateWithAddedHours = addHours(new Date(date), addedHours);

    if (isBefore(dateWithAddedHours, currentDate)) {
      return {
        estimate: "Any moment now",
        percentage: 100,
      };
    }

    const percentageMissing =
      ((addedMinutes - differenceInMinutes(dateWithAddedHours, currentDate)) /
        addedMinutes) *
      100;

    return {
      estimate: formatDate(dateWithAddedHours, "HH:mm | do MMM yyyy"),
      percentage: Math.round(percentageMissing),
    };
  } catch (e) {
    console.log(e);
    return {
      estimate: "Any moment now",
      percentage: 100,
    };
  }
};
