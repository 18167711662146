import * as React from "react";

const CameraIcon = () => (
  <svg height={12} width={20} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.947 1.276a.5.5 0 0 0-.67-.223L15 3.19V3a3.003 3.003 0 0 0-3-3H3a3.003 3.003 0 0 0-3 3v6a3.003 3.003 0 0 0 3 3h9a3.003 3.003 0 0 0 3-3v-.19l4.276 2.137c.07.035.146.053.224.053h.002a.5.5 0 0 0 .498-.5v-9a.5.5 0 0 0-.053-.224zM14 4v5a2.003 2.003 0 0 1-2 2H3a2.003 2.003 0 0 1-2-2V3a2.003 2.003 0 0 1 2-2h9a2.003 2.003 0 0 1 2 2zm5 5.691-4-2V4.31l4-2z"
      fill="#fff"
    />
  </svg>
)

export default CameraIcon
