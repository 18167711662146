import React, { lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { DashboardLayout } from "../layouts/dashboard";
import { PlanId } from "./Editors/types";
import { Routes } from "./routeMap";
import ClientPageWrapper from "../containers/ClientPageContainer";
import { AuthGuard } from "../containers/Routing/AuthGuard";
import { Loadable } from "../containers/Routing/Loadable";
import { GuestGuard } from "../containers/Routing/GuestGuard";
import InteractiveToken from "./InteractiveToken";
import Editors from "./Editors";
import { LoginScreen } from "./Login/const";
import WorkoutEditor from "./WorkoutEditor/index";
import ClientVideos from "./ClientVideos";
import ClientOverview from "./ClientOverview";
import { ClientListProvider } from "../providers/ClientListProvider";
import LenusSignup from "./LenusStart/Components/LenusSignup";
import { LenusTransferProvider } from "../providers/LenusTransferProvider";
import LenusSubscriptionPlans from "./LenusStart/Components/LenusSubscriptionPlans";
import LenusSuccess from "./LenusStart/Components/LenusSuccess";
import LenusTransferOutLet from "../providers/LenusTransferProvider/components/LenusTransferOutlet";
import LenusLoginStep from "./LenusTransfer/Components/LenusLoginStep";
import LenusEmailStep from "./LenusTransfer/Components/LenusEmailStep";
import LenusTransferSuccessStep from "./LenusTransfer/Components/LenusTransferSuccessStep";
import LenusTransferStep from "./LenusTransfer/Components/LenusTransferStep";
import LenusPaymentFailed from "./LenusStart/Components/LenusPaymentFailed";
import RecipeEditor from "./RecipeEditor";

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "sign-up",
          element: (
            <GuestGuard>
              <Signup />
            </GuestGuard>
          ),
        },
        {
          path: "new-password/:datakey",
          element: (
            <GuestGuard>
              <Login selectedScreen={LoginScreen.NEW_PASSWORD} />
            </GuestGuard>
          ),
        },
        {
          path: "finish-sign-up",
          element: (
            <AuthGuard>
              <FinishSignup />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: "lenus/transfer",
      element: (
        <LenusTransferProvider>
          <LenusTransferOutLet />
        </LenusTransferProvider>
      ),
      children: [
        { index: true, element: <LenusLoginStep /> },
        { path: "email", element: <LenusEmailStep /> },
        { path: "progress", element: <LenusTransferStep /> },
        { path: "success", element: <LenusTransferSuccessStep /> },
        {
          path: "start",
          children: [
            { path: "sign-up", element: <LenusSignup /> },
            { path: "subscription", element: <LenusSubscriptionPlans /> },
            { path: "success", element: <LenusSuccess /> },
            { path: "payment-failed", element: <LenusPaymentFailed /> },
          ],
        },
      ],
    },
    {
      path: "client",
      children: [
        { path: "clientActivation/:datakey", element: <ClientActivation /> },
        { path: "clientActivation", element: <ClientActivation /> }, //redirects to route above
        { path: "checkout/:datakey", element: <Checkout /> },
        { path: "checkout/bundle/:bundleId", element: <Checkout /> },
        { path: "checkout/bundle/:bundleId/:clientId", element: <Checkout /> },
        { path: "checkout/success/:datakey", element: <CheckoutSuccess /> },
        {
          path: "checkout/bundle/success/:bundleId/:clientId/:datakey",
          element: <CheckoutSuccess />,
        },
        { path: "settings", element: <ClientSettings /> },
      ],
    },
    {
      path: "checkout/:datakey", //redirects to /client/checkout/:datakey
      element: <Checkout />,
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={Routes.DEFAULT_ROUTE} replace />, index: true },
        { path: "app", element: <Dashboard /> },
        {
          path: "settings",
          element: <SettingsContainer />,
          children: [
            { path: "general", index: true, element: <SettingsGeneral /> },
            { path: "pricing", element: <SettingsPricing /> },
            { path: "integrations", element: <SettingsIntegrations /> },
            { path: "form", element: <SettingsForm /> },
          ],
        },
        { path: "plans", element: <Plans /> },
        { path: "leads", element: <Leads /> },
        { path: "chat", element: <Messages /> },
        { path: "intro", element: <Intro /> },
        {
          path: "clients",
          element: (
            <ClientListProvider>
              <Outlet />
            </ClientListProvider>
          ),
          children: [
            {
              index: true,
              element: <ClientList />,
            },
            {
              path: ":clientId",
              element: <ClientPageWrapper />,
              children: [
                { index: true, element: <ClientOverview /> },
                { path: "workouts", element: <ClientWorkout /> },
                {
                  path: "workouts/:templateId",
                  element: <WorkoutEditor />,
                },
                { path: "workouts/:templateId/day/:dayId", element: <WorkoutDay /> },
                { path: "meals", element: <MealPlan /> },
                { path: "meals/:templateId", element: <Editors type={PlanId.MEAL} /> },
                { path: "progress", element: <ClientProgress /> },
                { path: "documents", element: <Files type={"client"} /> },
                { path: "videos", element: <ClientVideos /> },
              ],
            },
          ],
        },
        {
          path: "workouts",
          children: [
            {
              element: <Navigate to={Routes.WORKOUTS_TEMPLATES} replace />,
              index: true,
            },
            { path: "exercises", element: <Exercises /> },
            { path: "templates", element: <WorkoutTemplates /> },
            // @ts-ignore
            { path: "templates/:templateId", element: <WorkoutEditor /> },
            { path: "templates/:templateId/day/:dayId", element: <WorkoutDay /> },
          ],
        },
        {
          path: "meals",
          children: [
            {
              element: <Navigate to={Routes.MEALS_TEMPLATES} replace />,
              index: true,
            },
            { path: "ingredients", element: <Ingredients /> },
            { path: "templates", element: <MealTemplates /> },
            { path: "templates/:templateId", element: <Editors type={PlanId.MEAL} /> },
            { path: "recipes", element: <Recipes /> },
            { path: "recipes/:templateId", element: <RecipeEditor /> }, // <Editors type={PlanId.RECIPE} /> },
          ],
        },
        {
          path: "content",
          children: [
            { element: <Navigate to={Routes.CONTENT_VIDEOS} replace />, index: true },
            { path: "videos", element: <Videos /> },
            { path: "files", element: <Files type={"general"} /> },
          ],
        },
      ],
    },
    { path: "login/interactive/:token", element: <InteractiveToken /> },
    { path: "*", element: <Navigate to={Routes.LOGIN_ROUTE} replace /> },
  ]);
}

// Trainer
const Dashboard = Loadable(lazy(() => import("./Dashboard")));
const Leads = Loadable(lazy(() => import("./Leads")));
const ClientList = Loadable(lazy(() => import("./ClientList/Main")));
const Messages = Loadable(lazy(() => import("./Chat")));
const WorkoutTemplates = Loadable(lazy(() => import("./WorkoutTemplates")));
const Exercises = Loadable(lazy(() => import("./Exercises")));
const MealTemplates = Loadable(lazy(() => import("./MealTemplates")));
const Recipes = Loadable(lazy(() => import("./Recipes")));
const Ingredients = Loadable(lazy(() => import("./Ingredients")));
const Videos = Loadable(lazy(() => import("./Videos")));
const Files = Loadable(lazy(() => import("./Documents")));
const SettingsContainer = Loadable(lazy(() => import("./Settings")));
const SettingsGeneral = Loadable(
  lazy(() => import("./Settings/components/GeneralTab/index")),
);
const SettingsPricing = Loadable(lazy(() => import("./Settings/components/PricingTab")));
const SettingsIntegrations = Loadable(
  lazy(() => import("./Settings/components/IntegrationsTab")),
);
const SettingsForm = Loadable(
  lazy(() => import("./Settings/components/WebsiteTab/WebsiteTab")),
);
const Plans = Loadable(lazy(() => import("./Plans")));
const WorkoutDay = Loadable(lazy(() => import("./WorkoutMobile/workout-day")));
const Intro = Loadable(lazy(() => import("./Intro")));
const ClientWorkout = Loadable(lazy(() => import("./ClientWorkout")));
const ClientProgress = Loadable(lazy(() => import("./ClientProgress")));
const MealPlan = Loadable(lazy(() => import("./MealPlan/new/src")));
const FinishSignup = Loadable(lazy(() => import("./FinishSignup")));
const Signup = Loadable(lazy(() => import("./Signup")));
const Login = Loadable(lazy(() => import("./Login")));

// Client
const Checkout = Loadable(lazy(() => import("./Checkout")));
const CheckoutSuccess = Loadable(lazy(() => import("./Checkout/components/Success")));
const ClientActivation = Loadable(lazy(() => import("./ClientActivation")));
const ClientSettings = Loadable(lazy(() => import("./ClientSettings")));
