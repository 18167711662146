import { useDropzone } from "react-dropzone";
import { FormControl, FormHelperText, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UploadProps } from "./type";
import Image from "../Image";
import Iconify from "../Iconify";
import RejectionFiles from "./RejectionFiles";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  borderRadius: "50%",
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[200]}`,
  "& .wrapper": {
    padding: 0,
  },
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.background.neutral,
  backgroundColor: theme.palette.grey[200],
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function UploadAvatar(props: UploadProps) {
  const { error, file, photoText, updatePhotoText, helperText, sx, ...other } = props;
  const { t } = useTranslation(TranslatorNS.MESSAGES);
  const updateText = updatePhotoText || t("client.activation.bodyPictures.updatePhoto");
  const addText = photoText || t("client.activation.bodyPictures.uploadPhoto");
  const fileUrl =
    typeof file === "string" ? file : file !== undefined ? URL.createObjectURL(file) : "";

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } =
    useDropzone({
      multiple: false,
      ...other,
    });

  return (
    <FormControl error={error}>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: "error.light",
          }),
          ...sx,
        }}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}>
          <input {...getInputProps()} />

          {file && <Image alt="avatar" src={fileUrl} sx={{ zIndex: 8 }} />}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: "common.white",
                bgcolor: "grey.900",
                "&:hover": { opacity: 0.72, "& span": { color: "common.white" } },
              }),
              ...((isDragReject || error) && {
                bgcolor: "error.lighter",
              }),
            }}>
            <Iconify
              icon={"ic:round-add-a-photo"}
              sx={{ width: 24, height: 24, mb: 1 }}
            />
            <Typography
              sx={{ textAlign: "center", fontSize: 12, paddingX: 1 }}
              variant="caption">
              {file ? updateText : addText}
            </Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      <Typography
        variant="caption"
        sx={{
          mt: 1,
          mb: 2,
          mx: "auto",
          display: "block",
          textAlign: "center",
          color: "text.secondary",
          fontSize: 10,
        }}>
        {t("client.activation.bodyPictures.allowed")} *.jpeg, *.jpg, *.png
      </Typography>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </FormControl>
  );
}
