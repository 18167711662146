import isNumber from "lodash/isNumber";
import { html } from "common-tags";
import { TYPE_MEAL, TYPE_RECIPE, TYPE_WORKOUT } from "../constants";
import { percent } from "../helpers";

/**
 * @param {boolean} isTemplate
 * @param {Object} client
 * @param {Object} plan
 * @param {Array<Object>} data Meal List
 * @param {Object} options
 *
 * @returns {string}
 */
function BoardHeaderMealActions(isTemplate, client, plan, data, options) {
  const applyRecipeProps = {
    plan: plan.id,
    locale: plan.locale || options.locale,
  };

  const meal = data[0];

  if (meal) {
    if (isNumber(meal.macroSplit)) {
      applyRecipeProps.macroSplit = meal.macroSplit;
    }

    if (isNumber(meal.type)) {
      applyRecipeProps.type = meal.type;
    }
  }

  return html`
    <div class="board-meal-actions">
      <div class="dropdown">
        <button
          class="btn btn-default btn-sm text-uppercase"
          data-toggle="dropdown"
          aria-expanded="false"
          type="button"
        >
          ${window.jsTrans("board.more", "plans")}
          <span class="button-caret"><strong class="caret"></strong></span>
        </button>
        <ul class="dropdown-menu">
          ${isTemplate
            ? ""
            : `<li><a href="#" class="js-save-template">${window.jsTrans(
                "board.saveAsTemplate",
                "plans",
              )}</a></li>`}
          <li>
            <a href="#" class="js-save-pdf"
              >${window.jsTrans("board.saveAsPDF", "plans")}</a
            >
          </li>
        </ul>
      </div>
    </div>
  `;
}

function BoardHeaderMealInfo(plan, client, data = [], type = TYPE_MEAL) {
  const containsAlternatives = plan.contains_alternatives;

  let totals = data.reduce(
    (totals, item) => {
      const idealTotals = containsAlternatives ? item.ideal_totals : item.totals;
      const avgTotals = containsAlternatives ? item.avg_totals : item.totals;

      totals.desiredKcals += idealTotals.kcal;
      totals.avgKcals += avgTotals.kcal;
      totals.protein += avgTotals.protein;
      totals.carbohydrate += avgTotals.carbohydrate;
      totals.fat += avgTotals.fat;

      return totals;
    },
    {
      desiredKcals: 0,
      avgKcals: 0,
      protein: 0,
      carbohydrate: 0,
      fat: 0,
    },
  );

  const progressMax = totals.protein * 4 + totals.carbohydrate * 4 + totals.fat * 9;

  return html`
    <div class="board-meal-totals">
      ${type === TYPE_RECIPE || !containsAlternatives
        ? `
          <h6>${window.jsTrans(
            "board.totalKcals",
            "plans",
          )}: <var class="js-header-total-kcal">${Math.round(
            totals.desiredKcals,
          )}</var> ${window.jsTrans("board.kcalAbbr", "plans")}</h6>
        `
        : `
          <h6>${window.jsTrans(
            "board.targetForThisPlan",
            "plans",
          )}: <var class="js-header-target-kcal">${Math.round(
            totals.desiredKcals,
          )}</var> ${window.jsTrans("board.kcalAbbr", "plans")}</h6>
          <h6>${window.jsTrans(
            "board.yourAverage",
            "plans",
          )}: <var class="js-header-avg-kcal">${Math.round(
            totals.avgKcals,
          )}</var> ${window.jsTrans("board.kcalAbbr", "plans")}</h6>
        `}
      <div class="board-meal-meta">
        <span data-color="blue"
          >${window.jsTrans("board.protein", "plans")}:
          <var class="js-header-protein"
            >${Math.round(totals.protein)}${window.jsTrans("board.gramsAbbr", "plans")}
            (${percent(totals.protein * 4, progressMax)}%)</var
          ></span
        >
        •
        <span data-color="red"
          >${window.jsTrans("board.carbs", "plans")}:
          <var class="js-header-carbohydrate"
            >${Math.round(totals.carbohydrate)}${window.jsTrans(
              "board.gramsAbbr",
              "plans",
            )}
            (${percent(totals.carbohydrate * 4, progressMax)}%)</var
          ></span
        >
        •
        <span data-color="yellow"
          >${window.jsTrans("board.fat", "plans")}:
          <var class="js-header-fat"
            >${Math.round(totals.fat)}${window.jsTrans("board.gramsAbbr", "plans")}
            (${percent(totals.fat * 9, progressMax)}%)</var
          ></span
        >
      </div>
    </div>
  `;
}

function BoardHeaderWorkoutActions(isTemplate) {
  return html`
    <button
      class="btn btn-success btn-sm js-add-plan text-uppercase m-r-xs"
      type="button"
      data-type="${TYPE_WORKOUT}"
    >
      ${window.jsTrans("board.addDay", "plans")}
    </button>
    <div class="dropdown">
      <button
        class="btn btn-default btn-sm text-uppercase"
        data-toggle="dropdown"
        aria-expanded="false"
        type="button"
      >
        ${window.jsTrans("board.more", "plans")}
        <span class="button-caret"><strong class="caret"></strong></span>
      </button>
      <ul class="dropdown-menu dropdown-menu-right">
        ${isTemplate
          ? ""
          : `<li><button class="btn btn-default js-save-template" type="button">${window.jsTrans(
              "board.saveAsTemplate",
              "plans",
            )}</button></li>`}
        <li>
          <button class="btn btn-default js-use-template" type="button">
            ${window.jsTrans("board.applyTemplate", "plans")}
          </button>
        </li>
        <li>
          <button class="btn btn-default js-save-pdf" type="button">
            ${window.jsTrans("board.saveAsPDF", "plans")}
          </button>
        </li>
      </ul>
    </div>
  `;
}

export function BoardHeader(
  { data, updatedAt, type, plan, client, options },
  isTemplate = false,
) {
  const boardDescription = window.jsTrans("board.updatedOn", "plans", {
    time: updatedAt,
  });
  let className = "grey-color";
  let workoutsPerWeek = 0;
  let duration = 0;
  let level = 0;
  let location = 0;
  let gender = 0;

  if (plan.meta) {
    workoutsPerWeek = plan.meta.workoutsPerWeek;
    duration = plan.meta.duration;
    level = plan.meta.level;
    location = plan.meta.location;
    gender = plan.meta.gender;
  }

  let editAttributes = [
    `data-name="${plan.name}"`,
    `data-workoutsPerWeek="${workoutsPerWeek}"`,
    `data-duration="${duration}"`,
    `data-level="${level}"`,
    `data-location="${location}"`,
    `data-gender="${gender}"`,
    'role="button"',
  ];

  if (type === TYPE_MEAL) {
    editAttributes = [
      ...editAttributes,
      `data-title="${plan.name}"`,
      `data-type="edit"`,
      'data-target="#mealPlanModal"',
      'data-toggle="modal"',
    ];
  } else {
    className += " js-template-title";
  }

  const boardEditTitle = html`
    <a class="${className}" ${editAttributes.join(" ")}>
      <span class="material-icons">mode_edit</span>
    </a>
  `;

  let headerLeft = `
    <h2 class="plans-board-title">
      <span class="js-plan-title">${plan.name}</span>
      ${boardEditTitle}
    </h2>
    <!--<small class="js-plan-description">${/*boardDescription*/ ""}</small>-->
  `;

  if (type === TYPE_RECIPE) {
    headerLeft = "";
  } else if (type === TYPE_MEAL) {
    headerLeft += BoardHeaderMealActions(isTemplate, client, plan, data, options);
  }

  return html`
    <header class="plans-board-header">
      <div class="plans-board-header-left">${headerLeft}</div>
      <div class="plans-board-header-right">
        ${type === TYPE_RECIPE ? BoardHeaderMealInfo(plan, client, [data], type) : ""}
        ${type === TYPE_MEAL ? BoardHeaderMealInfo(plan, client, data, type) : ""}
        ${type === TYPE_WORKOUT ? BoardHeaderWorkoutActions(isTemplate, updatedAt) : ""}
      </div>
    </header>
  `;
}
