import { Fragment, useState } from "react";
import { ClientPaymentType, PaymentTypeEnum } from "../../../../generated/graphql";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import IconButton from "@mui/material/IconButton";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { alpha } from "@mui/material/styles";
import Label from "../../../../components/_MUI/Label";
import { useGetPaymentTypeColorTitle } from "../hooks/useGetPaymentTypeColorTitle";
import RefundModal from "./RefundModal";
import { formatDate } from "../../../../helpers/dates";
import { FLEX_AMOUNT_STATUS, FLEX_DATE } from "./index";

interface Props {
  payment: ClientPaymentType;
}

export default function PaymentItem(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { payment } = props;
  const { chargeId, date, amount, type, currency } = payment;
  const [refundHoverVisible, setRefundHoverVisible] = useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const showButton =
    refundHoverVisible &&
    amount &&
    currency &&
    payment.type === PaymentTypeEnum.PaymentSucceeded;

  const { color, title } = useGetPaymentTypeColorTitle(type);

  return (
    <Fragment>
      <Stack
        sx={{
          position: "relative",
        }}
        flexDirection={"row"}
        onMouseEnter={() => setRefundHoverVisible(true)}
        onMouseLeave={() => setRefundHoverVisible(false)}
        borderRadius={1}
        p={1}>
        {date && (
          <Stack flex={FLEX_DATE}>
            {formatDate(date, "MMM dd, yyyy")}
            {payment.reason && ` (${payment.reason})`}
          </Stack>
        )}

        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          flex={FLEX_AMOUNT_STATUS}
          px={3}>
          <Stack flexDirection={"row"}>
            {currency && (
              <Typography sx={{ mx: 0.5 }}>{currency?.toUpperCase()}</Typography>
            )}
            {amount && <Typography fontWeight={500}>{amount}</Typography>}
          </Stack>

          <Stack flexDirection={"row"}>
            <Label
              variant={"filled"}
              sx={{ width: 80, background: alpha(color, 0.16), color }}>
              {title}
            </Label>
          </Stack>

          {!!showButton && (
            <Stack position={"absolute"} right={0} top={5}>
              <Tooltip
                arrow
                disableInteractive
                title={`${t(
                  "newClientOverview.refund",
                )} ${currency.toUpperCase()} ${amount}`}
                placement={"top"}>
                <IconButton size={"small"} onClick={() => setRefundModalVisible(true)}>
                  <KeyboardReturnIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      </Stack>

      {refundModalVisible && (
        <RefundModal
          chargeId={chargeId}
          amount={amount}
          currency={currency}
          onClose={() => setRefundModalVisible(false)}
          payment={payment}
        />
      )}
    </Fragment>
  );
}
