import createRequestTypes from "../../../../../helpers/createReduxActionTypes";
import { RequestState } from "../../../../../interfaces/requestState";

export const CLIENT_PROGRESS_REQUEST = "CLIENT_PROGRESS_REQUEST";
export const CLIENT_PROGRESS_SUCCESS = "CLIENT_PROGRESS_SUCCESS";
export const CLIENT_PROGRESS_FAILURE = "CLIENT_PROGRESS_FAILURE";

export const UPDATE_CLIENT_PROGRESS_REQUEST = "UPDATE_CLIENT_PROGRESS_REQUEST";
export const UPDATE_CLIENT_PROGRESS_SUCCESS = "UPDATE_CLIENT_PROGRESS_SUCCESS";
export const UPDATE_CLIENT_PROGRESS_FAILURE = "UPDATE_CLIENT_PROGRESS_FAILURE";

export const DELETE_CLIENT_PROGRESS_REQUEST = "DELETE_CLIENT_PROGRESS_REQUEST";
export const DELETE_CLIENT_PROGRESS_SUCCESS = "DELETE_CLIENT_PROGRESS_SUCCESS";
export const DELETE_CLIENT_PROGRESS_FAILURE = "DELETE_CLIENT_PROGRESS_FAILURE";

export const CLIENT_KCALS = createRequestTypes("CLIENT_KCALS");

export const CLIENT_PROGRESS_RESET = "CLIENT_PROGRESS_RESET";

export enum ClientEntryType {
  WEIGHT = "WEIGHT",
  CIRCUMFERENCE = "CIRCUMFERENCE",
}

export enum CircumferenceKey {
  CHEST = "chest",
  GLUTES = "glutes",
  HIPS = "hips",
  LEFT_ARM = "leftArm",
  LEFT_CALF = "leftCalf",
  LEFT_THIGH = "leftThigh",
  RIGHT_ARM = "rightArm",
  RIGHT_CALF = "rightCalf",
  RIGHT_THIGH = "rightThigh",
  WAIST = "waist",
}

export interface SingleCheckIn {
  id: number;
  date: string;
  content: string;
}

export interface Checkin {
  id: number;
  checkIns: SingleCheckIn[] | null;
  date: string;
  images: string | null;
  dateLabel: any;
  weight: number | null;
  fat: number | null;
  chest: number | null;
  glutes: number | null;
  hips: number | null;
  leftArm: number | null;
  leftCalf: number | null;
  leftThigh: number | null;
  rightArm: number | null;
  rightCalf: number | null;
  rightThigh: number | null;
  waist: number | null;
  muscle: number | null;
  total: React.ReactNode;
}

export interface Checkins {
  [date: string]: Checkin;
}

export interface Metrics {
  direction: "lose";
  goal: number;
  last: number;
  lastWeek: number;
  left: number;
  offText: string;
  percentage: number;
  progress: number;
  progressText: string;
  start: number;
  unit: "kg" | "lbs";
  weekly: number;
  currentWeek: number;
  weeks: number;
  loading: RequestState;
}

export interface ClientKcalsResponse {
  [date: string]: number;
}

export interface ClientTopProgressState {
  clientInfo: {
    info: {
      gender: number;
    };
  };
  checkins: Checkins;
  metrics: Metrics;
  error: any | null;
  loading: RequestState;
  updateLoading: RequestState;
  deleteLoading: RequestState;
  kcals: ClientKcalsResponse;
}

interface ClientKcalsRequest {
  type: typeof CLIENT_KCALS;
  payload: ClientKcalsResponse;
}

// LOAD
interface ClientProgressRequest {
  type: typeof CLIENT_PROGRESS_REQUEST;
  payload: any;
}
interface ClientProgressSuccess {
  type: typeof CLIENT_PROGRESS_SUCCESS;
  payload: {
    metrics: Metrics;
    checkins: Checkins;
  };
}
interface ClientProgressFailure {
  type: typeof CLIENT_PROGRESS_FAILURE;
  payload: any;
}

// ADD / EDIT
interface UpdateClientProgressRequest {
  type: typeof UPDATE_CLIENT_PROGRESS_REQUEST;
  payload: any;
}
interface UpdateClientProgressSuccess {
  type: typeof UPDATE_CLIENT_PROGRESS_SUCCESS;
  payload: { entry: Partial<Checkin> };
}
interface UpdateClientProgressFailure {
  type: typeof UPDATE_CLIENT_PROGRESS_FAILURE;
  payload: any;
}

// DELETE
interface DeleteClientProgressRequest {
  type: typeof DELETE_CLIENT_PROGRESS_REQUEST;
  payload: any;
}
interface DeleteClientProgressSuccess {
  type: typeof DELETE_CLIENT_PROGRESS_SUCCESS;
  payload: {
    entryId: number;
    type: ClientEntryType;
  };
}

interface DeleteClientProgressFailure {
  type: typeof DELETE_CLIENT_PROGRESS_FAILURE;
  payload: any;
}
interface ResetClientProgressState {
  type: typeof CLIENT_PROGRESS_RESET;
  payload: any;
}

export type ClientProgressActionTypes =
  | ClientProgressRequest
  | ClientProgressSuccess
  | ClientProgressFailure
  | UpdateClientProgressRequest
  | UpdateClientProgressSuccess
  | UpdateClientProgressFailure
  | DeleteClientProgressRequest
  | DeleteClientProgressSuccess
  | DeleteClientProgressFailure
  | ResetClientProgressState
  | ClientKcalsRequest;
