import React, { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Tooltip, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import VideoCall from "@mui/icons-material/VideoCall";
import { useSnackbar } from "notistack";
import { getVideoSize, isVideoValid } from "../../../../../../helpers/mediaValidation";
import Iconify from "../../../../../../components/_MUI/Iconify";

interface Props {
  renderActionButton: (
    toggleFunc: () => any,
    hiddenInput: any,
    handleUploadVideoDirect: () => void,
  ) => any;
  handleVideo: (blob: Blob) => void;
  handleModal: () => void;
  isMobile: boolean;
  isWidget?: boolean;
  isSending: boolean;
}

const maxMegabyte = 500;

export default function ActionButton(props: Props) {
  const { renderActionButton, handleVideo, handleModal, isWidget, isMobile, isSending } =
    props;
  const VideoFileInput = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { palette } = useTheme();
  const { t } = useTranslation("chat");

  const handleUploadVideoFile = (e: { target: { files: FileList | null } }) => {
    const video = e?.target?.files?.[0];

    if (!video) return;

    const error = isVideoValid(video, maxMegabyte);

    const { size } = getVideoSize(video?.size);

    if (error) {
      return enqueueSnackbar(t(error, { size, maxMegabyte }), {
        variant: "error",
        persist: false,
        preventDuplicate: true,
      });
    }

    handleVideo(video);
  };

  const handleUploadVideoDirect = () => {
    if (null !== VideoFileInput.current) {
      VideoFileInput?.current?.click();
    }
  };

  const handleVideoRecorder = () =>
    isMobile ? handleUploadVideoDirect() : handleModal();

  const uploadInputButton = (
    <Tooltip title={t("captureVideo.uploadVideo", { maxMegabyte })}>
      <Stack justifyContent={"center"} className={"uploadVideo"}>
        <IconButton size={"small"} disabled={isSending} onClick={handleUploadVideoDirect}>
          <Iconify
            icon={
              isMobile ? "material-symbols:videocam-outline-rounded" : "mdi:paperclip"
            }
            sx={{
              color: !isSending && isWidget ? palette.primary.main : "inherit",
              fontSize: isWidget ? "1.5rem" : "1.25rem",
            }}
          />
          <input
            onChange={handleUploadVideoFile}
            style={{ visibility: "hidden", position: "absolute", pointerEvents: "none" }}
            type="file"
            accept={isMobile ? "video/*" : ".mp4, .mov"}
            capture={true}
            ref={VideoFileInput}
          />
        </IconButton>
      </Stack>
    </Tooltip>
  );

  if (renderActionButton) {
    return renderActionButton(
      handleVideoRecorder,
      uploadInputButton,
      handleUploadVideoDirect,
    );
  }

  return (
    <div className={`triggerButton ${isWidget && "drop-options-list-item"}`}>
      <Stack flexDirection={isWidget ? "column" : "row"} alignItems={"center"}>
        {uploadInputButton}
        {!isMobile && (
          <Stack>
            <IconButton size={"small"} onClick={handleVideoRecorder}>
              {!isWidget ? (
                <VideocamOutlinedIcon />
              ) : (
                <Fragment>
                  <VideoCall /> <div>{t("captureVideo.video")}</div>
                </Fragment>
              )}
            </IconButton>
          </Stack>
        )}
      </Stack>
    </div>
  );
}
