import { Close } from "@material-ui/icons";
import { Alert, Button, Collapse, IconButton } from "@mui/material";
import { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import Iconify from "../../../../components/_MUI/Iconify";
import useResponsive, {
  BreakPointEnum,
  QueryEnum,
} from "../../../../hooks/useResponsive";
import { TranslatorNS } from "../../../../i18n/const";
import { UserState } from "../../../../store/user/reducerTypes";
import { AlertStorageKeys } from "./helper";

type Props = {
  stripeConnectUrl: UserState["stripeConnectUrl"];
  alertSx: CSSProperties;
};

function StripeConnectAlert(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [isClosed, setisClosed] = useState(false);
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);

  const handleClose = () => {
    setisClosed(true);
    localStorage.setItem(AlertStorageKeys.STRIPE_CONNECT_ALERT, "hide");
  };
  return (
    <Collapse in={!isClosed}>
      <Alert
        severity="success"
        variant="filled"
        sx={props.alertSx}
        icon={<Iconify icon="ri:money-dollar-box-fill" />}
        action={
          <>
            <Button
              color="success"
              size="small"
              sx={{ fontSize: isDesktop ? "inherit" : 12 }}
              onClick={() =>
                props.stripeConnectUrl && window.open(props.stripeConnectUrl)
              }>
              {t("stripeConnect.cta")}
            </Button>
            <IconButton
              color="inherit"
              component="span"
              size="small"
              sx={{ display: 'none' }}
              onClick={handleClose}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }>
        {isDesktop ? t("stripeConnect.title") : t("stripeConnect.titleMobile")}
      </Alert>
    </Collapse>
  );
}

export default StripeConnectAlert;
