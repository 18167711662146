import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Stack, Drawer } from "@mui/material";
import useResponsive, { BreakPointEnum, QueryEnum } from "../../../hooks/useResponsive";
import useCollapseDrawer from "../../../hooks/useCollapseDrawer";
import cssStyles from "../../../utils/cssStyles";
import { HEADER, NAVBAR } from "../../../config";
import Scrollbar from "../../../components/_MUI/Scrollbar";
import { NavSectionVertical } from "../../../components/_MUI/nav-section";
import { prepareNavBar } from "./NavConfig";
import CollapseButton from "./CollapseButton";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
  unreadMessagesCount: number;
  newLeadsCount: number;
  isAssistant: boolean;
  showPlans: boolean;
};

export default function NavbarVertical({
  isOpenSidebar,
  onCloseSidebar,
  isAssistant,
  unreadMessagesCount,
  newLeadsCount,
  showPlans,
}: Props) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": { height: 1, display: "flex", flexDirection: "column" },
      }}>
      <NavSectionVertical
        navConfig={prepareNavBar(
          unreadMessagesCount,
          newLeadsCount,
          isAssistant,
          showPlans,
        )}
        isCollapse={isCollapse}
      />
      <Stack
        direction="row"
        alignItems="center"
        position={"relative"}
        justifyContent="space-between">
        {isDesktop && !isCollapse && (
          <Box sx={{ position: "absolute", right: 7, top: 15, zIndex: 2 }}>
            <CollapseButton
              onToggleCollapse={onToggleCollapse}
              collapseClick={collapseClick}
            />
          </Box>
        )}
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          sx={{ flexShrink: 0 }}
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              top: HEADER.DASHBOARD_DESKTOP_HEIGHT,
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: "solid",
              bgcolor: "background.default",
              boxShadow: theme => theme.customShadows.z16,
              transition: theme =>
                theme.transitions.create("width", {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: theme => theme.customShadows.z24,
              }),
            },
          }}>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
