import styled from "styled-components";
import typography from "../../theme/typography";
import { HEADER } from "../../config";

// styling, der skal fikses:

// *** Meal template & recipes
// * Manglende flag ved 'ingredienser i'.

export const FixStyled = styled.div`
  ${typography};
  margin-bottom: ${-(HEADER.DASHBOARD_DESKTOP_HEIGHT + 24)}px;

  .btn-success {
    padding: 6px 12px !important;
    border: 1px;
  }

  .btn-default {
    padding: 6px 12px !important;
    border-width: 1px;
    border-style: solid;
  }

  .label-danger {
    background-color: #fff;
  }

  a:-webkit-any-link {
    text-decoration: none;
  }

  .btn . caret {
    margin-left: 0;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }

  .fa-caret-down {
    color: #7f8fa4;
    margin-left: 5px;
    font-size: 16px;
  }

  .btn-success {
    background-color: #1a91eb;
    border-color: #1a91eb;
    background: #2da1f8;
    background: -moz-linear-gradient(top, #2da1f8 0%, #1991eb 100%);
    background: -webkit-linear-gradient(top, #2da1f8 0%, #1991eb 100%);
    background: linear-gradient(to bottom, #2da1f8 0%, #1991eb 100%);
    color: #ffffff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 10%);
  }

  .open > .dropdown-menu {
    display: block;
  }

  .dropdown-menu {
  }

  .dropdown-menu > li > a {
    border-radius: 3px;
    color: inherit !important;
    line-height: 25px;
    margin: 4px;
    text-align: left;
    font-weight: normal;
  }

  .dropdown-menu > li > a {
    display: block !important;
    padding: 6px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;

    :hover {
      background-color: rgba(62, 69, 76, 0.07);
    }
  }

  .open > .dropdown-menu {
    display: block;
    background-color: white;
  }

  p {
    margin: 0 0 10px;
  }

  a.hidden {
    display: none;
  }

  .plans-box-comment a {
    display: none;
  }

  .inmodal .modal-header .close::before {
    content: "Close (or press Esc)";
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
    position: absolute;
    top: 0;
    right: 48px;
    line-height: 38px;
  }

  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  .modal.in .modal-dialog {
    margin-top: 180px;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  .plans-search-form .search-language li .fa-caret-down {
    display: inline-flex;
    width: 0 !important;
    height: 0 !important;
    border: 0 !important;
  }

  .area label {
    font-weight: 500;
    color: #000;
    padding: 6px 16px;
  }

  .current {
    background-color: rgba(40, 149, 241, 0.1);
    border: solid 1px #1a91eb;
    color: #1a91eb;
    position: relative;
    z-index: 1;
  }
  .meal-area {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .area {
    overflow: hidden;
  }

  .area .col:first-child {
    border-radius: 3px 0 0 3px;
    margin-left: 0 !important;
  }

  .meal-area .meal-col {
    float: inherit;
    margin-top: 0;
  }
  .current {
    background-color: rgba(40, 149, 241, 0.1);
    border: solid 1px #1a91eb;
    color: #1a91eb;
    position: relative;
    z-index: 1;
  }
  .area input {
  }
  .meal-col {
    label {
      border: 1px solid #dadada;
    }
  }
  .area .col {
    float: left;
    margin-bottom: 10px;
    margin-right: 4px;
  }
`;
