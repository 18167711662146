import { Stack, useTheme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactSortable, SortableEvent, Store, Sortable } from "react-sortablejs";
import "./style.css";
import { sortableOptions } from "../sortableConfig";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  RecipeIngredientType,
  useAddIngredientToRecipeMutation,
  useRemoveRecipeIngredientMutation,
  LocaleEnum,
  useReorderRecipeIngredientMutation,
  RecipeType,
} from "../../../generated/graphql";
import EmptyListImage from "./Emptystatevisual.png";
import { Box } from "@mui/system";
import { TranslatorKeyPair } from "../../../i18n/const";
import ListItem from "./ListItem";
import { GetRecipeDocument } from "../../../generated/graphql";
import InstructionsField from "./InstructionsField";

export default function DraggableList({
  ingredients,
  instructions,
  locale,
}: {
  ingredients: RecipeIngredientType[];
  instructions: RecipeType["instructions"];
  locale: LocaleEnum;
}) {
  const theme = useTheme();
  const { templateId } = useParams<{ templateId: string }>();
  const [blocks, setBlocks] = useState<RecipeIngredientType[]>([]);

  const [addRecipeIngredient] = useAddIngredientToRecipeMutation();
  const [removeRecipeIngredient] = useRemoveRecipeIngredientMutation();
  const [reorderRecipeIngredient] = useReorderRecipeIngredientMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * Consider not using state and rely fully on backend returned data
   * has to make the data with redux or so if we want to keep some state machine
   * Though the state machine gives a nicer user experience as it is more responsive
   * and gives optimistic updates
   */

  useEffect(() => {
    setBlocks(JSON.parse(JSON.stringify(ingredients)));

    return () => {};
  }, [ingredients]);

  const onEnd = (evt: SortableEvent, sortable: Sortable | null, store: Store) => {
    /**
     * Safe guards to warn developers
     */
    if (evt.newIndex === undefined) {
      console.warn("no new index");
      return;
    }
    if (evt.oldIndex === undefined) {
      console.warn("no oldIndex");
      return;
    }

    const block = ingredients[evt.oldIndex];
    if (evt.oldIndex === evt.newIndex) {
      console.warn("Skipping as it is a nested reorder");
      return;
    }
    /**
     * Pull mode prevents multiple re-orders
     * when an item is dragged from one list to another
     * (usually when the item is drag into a list from a list)
     */
    if (evt.pullMode === true) {
      return;
    }
    try {
      reorderRecipeIngredient({
        variables: {
          recipeIngredientId: block.id,
          order: evt.newIndex + 1,
        },
        refetchQueries: () => [
          {
            query: GetRecipeDocument,
            variables: { id: Number(templateId), locale },
          },
        ],
      });
    } catch (error) {
      console.error(error);
    }
  };

  const deleteItem = async (blockId: number) => {
    try {
      console.log({ blockId });

      const fBlocks = blocks.filter(b => b.id !== blockId);
      setBlocks(fBlocks);

      removeRecipeIngredient({
        variables: {
          id: blockId,
        },
        refetchQueries: () => [
          {
            query: GetRecipeDocument,
            variables: { id: Number(templateId), locale },
          },
        ],
      });

      enqueueSnackbar(t("plan.ingredientDelete", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.ingredientDeleteError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };

  const onCloneIngredient = (item: any, evt: SortableEvent) => {
    /**
     * Prevent duplicate ingredients
     */
    const ingredient = blocks.find(b => b.ingredient?.id === item.id);
    if (ingredient) {
      enqueueSnackbar(
        t("index.youCantHaveSameFoodItemTwice", { ns: TranslatorKeyPair.plans }),
        {
          variant: "error",
        },
      );
      return null;
    }
    if (evt.newIndex === undefined) {
      console.warn("clone:no newIndex");
      return item;
    }
    try {
      addRecipeIngredient({
        variables: {
          input: {
            ingredientId: Number(item.id),
            recipeId: Number(templateId),
            amount: 100,
            order: evt.newIndex + 1,
          },
        },
        refetchQueries: () => [
          {
            query: GetRecipeDocument,
            variables: { id: Number(templateId), locale },
          },
        ],
      });

      return {
        id: Math.random(),
        order: 0,
        ingredient: {
          ...item,
        },
        // to make sure the local state is in sync so we don't get glitchy updates
        amount: 100,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const isListEmpty = ingredients.length === 0;

  const emptyListStyle = {
    border: `2px dashed ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
    height: "100%",
  };

  const EmptyStateText = () => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
      }}>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ mb: -10 }}>
          <Typography variant="h5">
            {t("plan.empty.recipeTitle", { ns: TranslatorKeyPair.plans })}
          </Typography>
          <Typography variant="body1">
            {t("plan.empty.recipeDescription", { ns: TranslatorKeyPair.plans })}
          </Typography>
        </Box>
        <img
          style={{ margin: "40px auto 0" }}
          src={EmptyListImage}
          width="40%"
          alt="empty-workout-plan"
          max-width="200px"
        />
      </Box>
    </Stack>
  );

  return (
    <div
      style={{
        height: "calc(100vh - 281px)",
        width: "100%",
        position: "relative",
      }}>
      {isListEmpty && <EmptyStateText />}
      <ReactSortable
        onEnd={onEnd}
        list={blocks}
        style={
          isListEmpty
            ? emptyListStyle
            : { minHeight: "100%", paddingBottom: isListEmpty ? 0 : 40 }
        }
        setList={setBlocks}
        clone={onCloneIngredient}
        {...sortableOptions}>
        {blocks
          // ensure the local state doesn't include items that gets cloned that shouldn't
          // e.g. if the item already exists in the list
          .filter(b => b.ingredient?.id)
          .map(block => (
            <ListItem
              key={block.id}
              block={block}
              locale={locale}
              deleteItem={deleteItem}
            />
          ))}
        {!isListEmpty ? (
          <Box pt={4}>
            <InstructionsField instructions={instructions} locale={locale} />
          </Box>
        ) : (
          <></>
        )}
      </ReactSortable>
    </div>
  );
}
