import { ROOT } from "../config";

export const GET_VIDEOS = () => {
  return `${ROOT}/video/api`;
};

export const GET_CLIENT_VIDEOS = (clientId: number) => {
  return `${ROOT}/video/api/${clientId}`;
};

export const ADD_CLIENT_VIDEO = (clientId: number, videoId: number) => {
  return `${ROOT}/video/api/${clientId}/${videoId}`;
};

export const DELETE_CLIENT_VIDEO = (clientId: number, videoId: number) => {
  return `${ROOT}/video/api/${clientId}/${videoId}`;
};

export const CREATE_VIDEOS = () => {
  return `${ROOT}/video/api`;
};

export const DELETE_VIDEOS = (id: number) => {
  return `${ROOT}/video/api/${id}`;
};
