import { Tooltip } from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React, { Dispatch, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Iconify from "../../../components/_MUI/Iconify";
import {
  ExerciseTypeEnum,
  GetWorkoutPlanTemplateDocument,
  useDeleteWorkoutMutation,
} from "../../../generated/graphql";
import { TranslatorKeyPair } from "../../../i18n/const";
import {
  DialogContext,
  VideoDialogContext,
  WorkoutPlanModalType,
} from "../Dialog/DialogProvider";
import { WorkoutPlan } from "../types";
import { StyledComment } from "./Comment";
import MoveDropdown from "./MoveDropdown";
import { StyledImage } from "./WorkoutImage";

const StyledBlockWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  background: theme.palette.common.white,
  padding: 12,
  minHeight: 72,
  marginBottom: 8,
  border: `solid ${theme.palette.grey[300]}`,
  borderWidth: 1,
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",

  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
}));

export default function WorkoutItem({
  block,
  canBeSuperset,
  isSuperSet,
  handleSuperSet,
  updateComment,
  updateReps,
  updateTime,
  updateSets,
  updateRest,
  deleteItem,
  setEditingComment,
}: {
  block: WorkoutPlan;
  canBeSuperset: boolean;
  isSuperSet?: boolean;
  handleSuperSet: (blockId: number) => void;
  updateComment: (blockId: number, text: string) => void;
  updateReps: (blockId: number, reps: string) => void;
  updateTime: (blockId: number, time: string) => void;
  updateSets: (blockId: number, sets: string) => void;
  updateRest: (blockId: number, rest: string) => void;
  deleteItem: (blockId: number) => void;
  setEditingComment?: Dispatch<boolean>;
}) {
  const { templateId } = useParams<{ templateId: string }>();
  const theme = useTheme();
  const { t } = useTranslation();
  const { setModal } = React.useContext(DialogContext);
  const [deleteWorkout] = useDeleteWorkoutMutation();
  const [comment, setComment] = useState<string | null>(null);
  const [reps, setReps] = useState<string | undefined>(block.reps);
  const [sets, setSets] = useState<string | undefined>(block.sets);
  const [rest, setRest] = useState<string | undefined>(block.rest);
  const [time, setTime] = useState<string | undefined>(block.time);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openVideoModal = () =>
    setModal({
      type: WorkoutPlanModalType.VIDEO,
      context: { title: block.title, video: block.video || null } as VideoDialogContext,
    });

  const handleDeleteWorkout = async () => {
    deleteItem(block.id);
    await deleteWorkout({
      variables: {
        id: Number(block.id),
      },
      refetchQueries: () => [
        {
          query: GetWorkoutPlanTemplateDocument,
          variables: { id: Number(templateId) },
        },
      ],
    });

    handleClose();
  };

  const handleDropset = () => {
    setComment(
      `${t("index.dropSetText", { ns: TranslatorKeyPair.plans })}\n${
        block.comment || ""
      }`,
    );
    handleClose();
  };

  const handleSuperset = () => {
    handleSuperSet(block.id);
    handleClose();
  };

  const onCommentActive = () => {
    setComment(block?.comment || "");
    setEditingComment?.(true);
  };
  const onCommentSave = () => {
    if (comment !== block.comment) {
      updateComment(block.id, comment || "");
    }
    setComment(null);
    setEditingComment?.(false);
  };

  const editingComment = comment !== null;
  return (
    <StyledBlockWrapper
      // class handle controls if can be dragged
      id={"exercise-list-item"}
      className={editingComment ? "" : "handle"}
      data-id={block.id}>
      <Stack
        // class handle controls if can be dragged
        className={editingComment ? "handle" : ""}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ maxWidth: "50%", overflow: "hidden", pr: 2 }}>
          <StyledImage
            onClick={openVideoModal}
            exerciseimage={(block.image || "") as string}>
            <Iconify
              icon="material-symbols:play-arrow-rounded"
              sx={{ color: theme.palette.grey[400] }}
              className="workout-editor-play-icon"
            />
          </StyledImage>
          <Stack sx={{ width: "85%", maxWidth: 380 }}>
            <Tooltip
              enterDelay={500}
              enterNextDelay={1000}
              placement="top"
              title={block.title || ""}>
              <Typography variant="subtitle2" noWrap>
                {block.title}
              </Typography>
            </Tooltip>
            {editingComment ? (
              <Stack sx={{ display: "block" }}>
                <Button size="small" onClick={onCommentSave}>
                  {t("plan.addComment", { ns: TranslatorKeyPair.plans })}
                </Button>
              </Stack>
            ) : (
              <Tooltip title={block.comment || ""}>
                <StyledComment
                  variant="body2"
                  role="button"
                  textOverflow={"pre"}
                  sx={{ maxHeight: 36 }}
                  onClick={onCommentActive}>
                  {block.comment || t("plan.addComment", { ns: TranslatorKeyPair.plans })}
                </StyledComment>
              </Tooltip>
            )}
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ maxWidth: "45%" }}>
          <TextField
            value={sets}
            onChange={e => setSets(e.target.value)}
            onBlur={() => {
              // allow empty string to be saved
              if (block.sets != sets || !sets) {
                setSets(sets || "0");
                updateSets(block.id, sets || "0");
              }
            }}
            size="small"
            label={t("plan.inputs.sets", { ns: TranslatorKeyPair.plans })}
          />
          <Box sx={{ pr: 2 }} />
          {block.exerciseType === ExerciseTypeEnum.Time && (
            <TextField
              value={time}
              onChange={e => setTime(e.target.value)}
              onBlur={() => {
                // allow empty string to be saved
                if (block.time != time || !time) {
                  setTime(time || "0");
                  updateTime(block.id, time || "0");
                }
              }}
              size="small"
              label={t("plan.inputs.time", { ns: TranslatorKeyPair.plans })}
            />
          )}
          {block.exerciseType === ExerciseTypeEnum.Reps && (
            <TextField
              value={reps}
              onChange={e => setReps(e.target.value)}
              onBlur={() => {
                // allow empty string to be saved
                if (block.reps != reps || !reps) {
                  setReps(reps || "0");
                  updateReps(block.id, reps || "0");
                }
              }}
              size="small"
              label={t("plan.inputs.reps", { ns: TranslatorKeyPair.plans })}
            />
          )}
          <Box sx={{ pr: 2 }} />
          <TextField
            sx={{ pr: 0 }}
            value={rest}
            onChange={e => setRest(e.target.value)}
            onBlur={() => {
              // allow empty string to be saved
              if (block.rest != rest || !rest) {
                setRest(rest || "0");
                updateRest(block.id, rest || "0");
              }
            }}
            size="small"
            label={t("plan.inputs.rest", { ns: TranslatorKeyPair.plans })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {t("plan.inputs.sec", { ns: TranslatorKeyPair.plans })}
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ alignSelf: "center", ml: 1 }}>
            <IconButton size="small" onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
          </Box>
        </Stack>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}>
          <List
            disablePadding
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader">
            {canBeSuperset && (
              <ListItemButton onClick={handleSuperset}>
                <ListItemText
                  primary={t("plan.createSuperSet", { ns: TranslatorKeyPair.plans })}
                />
              </ListItemButton>
            )}

            <ListItemButton onClick={handleDropset}>
              <ListItemText
                primary={t("index.dropSetCreate", { ns: TranslatorKeyPair.plans })}
              />
            </ListItemButton>
            {!isSuperSet && (
              <MoveDropdown
                templateId={templateId as string}
                workoutId={block.id}
                dayId={block.dayId}
              />
              )}
            {isSuperSet ? (
              <Tooltip
                title={t("plan.deleteFirstExerciseInSuperset", {
                  ns: TranslatorKeyPair.plans,
                })}>
                <ListItemButton sx={{ opacity: 0.5 }}>
                  <ListItemText
                    primaryTypographyProps={{ color: theme.palette.error.main }}
                    primary={t("plan.deleteWorkout", { ns: TranslatorKeyPair.plans })}
                  />
                </ListItemButton>
              </Tooltip>
            ) : (
              <ListItemButton onClick={handleDeleteWorkout}>
                <ListItemText
                  primaryTypographyProps={{ color: theme.palette.error.main }}
                  primary={t("plan.deleteWorkout", { ns: TranslatorKeyPair.plans })}
                />
              </ListItemButton>
            )}
          </List>
        </Menu>
      </Stack>
      {editingComment && (
        <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
          <TextField
            placeholder={t("plan.writeComment", { ns: TranslatorKeyPair.plans })}
            multiline
            autoFocus
            size="small"
            value={comment}
            onChange={e => setComment(e.target.value)}
            minRows={2}
            fullWidth
            onFocus={e => {
              // Adds cursor to the end of the input on focus
              const temp_value = e.target.value;
              e.target.value = "";
              e.target.value = temp_value;
            }}
            onBlur={onCommentSave}
            sx={{ mt: 1 }}
          />
        </Stack>
      )}
    </StyledBlockWrapper>
  );
}
