import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton, MenuItem, Stack, Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TranslatorNS } from "../../../i18n/const";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider } from "../../../components/_MUI/hook-form";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import {
  LocaleEnum,
  CookingTimeEnum,
  LenusMacroSplitEnum,
  ZenfitMacroSplitEnum,
  RecipeType,
  useUpdateRecipeMutation,
  GetRecipeDocument,
  MealTypeEnum,
} from "../../../generated/graphql";
import { ModalContextForType, DialogContext, RecipeModalType } from "./DialogProvider";
import { RootState } from "../../../store";
import { connect } from "react-redux";
import RHFChipSelect from "../../../components/_MUI/hook-form/RHFChipSelect";

import RHFSelect from "../../../components/_MUI/hook-form/RHFSelect";
import {
  COOKING_TIME_ENUM_KEYS,
  MEAL_TYPE_ENUM_KEYS,
  RECIPE_ALLERGIES,
  RECIPE_PREFERENES,
  SERVINGS_SELECT_OPTIONS_MAP,
} from "../../Recipes/const";
import { gql, useQuery } from "@apollo/client";
import { Locales } from "../../../i18n/locales";
import {
  getAllergies,
  getPreferences,
  transformPreferencesToServerFormat,
} from "../utils";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RecipeSchema = () =>
  Yup.object().shape({
    tags: Yup.array().of(Yup.string()),
    cookingTime: Yup.string(),
  });

interface FormValuesProps {
  title: string;
  description: string;
  tags: string[];
  types: MealTypeEnum[];
  preferences: (keyof typeof RECIPE_PREFERENES)[];
  allergies: (keyof typeof RECIPE_ALLERGIES)[];
  cookingTime?: CookingTimeEnum;
  macro?: LenusMacroSplitEnum | ZenfitMacroSplitEnum;
  servings?: number;
}
const RECIPE_QUERY = gql`
  query getRecipe($id: Int!) {
    recipe(id: $id) {
      cookingTime
      locale
      # zenfitMacroSplit
      lenusMacroSplit
      servings
      types {
        type
      }
      meta {
        lactose
        gluten
        nuts
        eggs
        pig
        shellfish
        fish
        isVegetarian
        isPescetarian
        isVegan
      }
    }
  }
`;

function EditRecipeDialogContainer() {
  const { templateId } = useParams<{ templateId: string }>();
  const { data, loading, error } = useQuery(RECIPE_QUERY, {
    variables: { id: Number(templateId) },
  });
  if (loading || error || !data?.recipe?.locale) return null;
  return (
    <EditRecipeDialog
      locale={data.recipe.locale}
      recipe={data.recipe}
      recipeId={Number(templateId)}
    />
  );
}

function EditRecipeDialog({
  locale,
  recipe,
  recipeId,
}: {
  locale: LocaleEnum;
  recipe: RecipeType;
  recipeId: number;
}) {
  const theme = useTheme();

  const { modal, setModal } = React.useContext(DialogContext);
  const { t } = useTranslation(TranslatorNS.PLANS);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const macroSplitMap = recipe?.lenusMacroSplit
    ? Object.values(LenusMacroSplitEnum)
    : Object.values(ZenfitMacroSplitEnum);

  const [updateRecipe, submitState] = useUpdateRecipeMutation();

  const isOpen = modal.type === RecipeModalType.EDIT_RECIPE;

  const handleClose = () => setModal({ type: null });

  const defaultValues = {
    language: locale,
    types: (recipe?.types?.map(({ type }) => type) || []) as MealTypeEnum[],
    allergies: getAllergies(recipe.meta),
    cookingTime: recipe.cookingTime as CookingTimeEnum,
    preferences: getPreferences(recipe.meta),
    macro: (recipe?.lenusMacroSplit || recipe?.zenfitMacroSplit) as
      | LenusMacroSplitEnum
      | ZenfitMacroSplitEnum,
    servings: recipe?.servings ? Number(recipe.servings) : undefined,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RecipeSchema()),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!recipeId) return;

      // getting all keys to set boolean on.
      // transforming the data back to the backend format
      const preferences = transformPreferencesToServerFormat({
        preferences: data.preferences,
        allergies: data.allergies,
      });

      await updateRecipe({
        variables: {
          id: recipeId,
          input: {
            servings: Number(data.servings),
            types: data.types,
            cookingTime: data.cookingTime,
            preferences: preferences,
            macroSplit: data.macro as LenusMacroSplitEnum,
            locale,
          },
        },
        refetchQueries: () => [
          {
            query: GetRecipeDocument,
            variables: { id: recipeId, locale },
          },
        ],
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <IconButton onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
        <CloseOutlinedIcon />
      </IconButton>
      <DialogTitle>{t("index.editRecipeModal.title")}</DialogTitle>
      <DialogContent>
        <Stack sx={{ pt: 2, pb: 2 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Tooltip title={t("index.editRecipeModal.tooltip")}>
              <Stack>
                <RHFSelect
                  key="language"
                  label={t("index.editRecipeModal.language")}
                  name="language"
                  fullWidth
                  disabled>
                  {Object.entries(Locales).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {t(label, { ns: TranslatorNS.SHARED })}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
            </Tooltip>
            <RHFSelect
              key="macro"
              label={t("index.editRecipeModal.macroSplit")}
              name="macro"
              fullWidth>
              {Object.entries(macroSplitMap).map(([index, value]) => (
                <MenuItem key={value} value={value}>
                  {value.split("_").join("/")}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFChipSelect
              key="types"
              label={t("index.editRecipeModal.type")}
              name="types"
              fullWidth
              labelMap={MEAL_TYPE_ENUM_KEYS}>
              {Object.entries(MEAL_TYPE_ENUM_KEYS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {t(label, { ns: TranslatorNS.LISTS })}
                </MenuItem>
              ))}
            </RHFChipSelect>
            <RHFSelect
              label={t("index.editRecipeModal.cookingTime")}
              name="cookingTime"
              fullWidth>
              {Object.entries(COOKING_TIME_ENUM_KEYS).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {t(label, { ns: TranslatorNS.MEAL_PLAN })}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFChipSelect
              key="preferences"
              label={t("index.editRecipeModal.preferences")}
              name="preferences"
              fullWidth
              labelMap={RECIPE_PREFERENES}
              chipColor="success">
              {Object.entries(RECIPE_PREFERENES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {t(label, { ns: TranslatorNS.LISTS })}
                </MenuItem>
              ))}
            </RHFChipSelect>
            <RHFChipSelect
              key="allergies"
              label={t("index.editRecipeModal.allergies")}
              name="allergies"
              labelMap={RECIPE_ALLERGIES}
              chipColor="info"
              fullWidth>
              {Object.entries(RECIPE_ALLERGIES).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {t(label, { ns: TranslatorNS.LISTS })}
                </MenuItem>
              ))}
            </RHFChipSelect>
            <RHFSelect
              key="servings"
              label={t("index.editRecipeModal.servings")}
              name="servings"
              fullWidth>
              {Object.entries(SERVINGS_SELECT_OPTIONS_MAP).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </RHFSelect>
            <Stack sx={{ pt: 2 }} direction="row" justifyContent="flex-end" spacing={2}>
              <LoadingButton variant="outlined" onClick={handleClose} size="large">
                {t("index.editRecipeModal.cancel")}
              </LoadingButton>
              <Stack direction="row">
                <LoadingButton
                  variant="contained"
                  loading={submitState.loading}
                  type="submit"
                  size="large">
                  {t("index.editRecipeModal.save")}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default EditRecipeDialogContainer;
