import CardContainer from "../../../containers/CardContainer/CardContainer";
import { useParams } from "react-router-dom";
import { useGetClientGeneralQuery } from "../../../generated/graphql";
import GeneralContent from "./GeneralContent";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { Fragment } from "react";
import ClientSettingsButton from "./components/ClientSettingsButton";

export default function GeneralContainer() {
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { data, loading, error } = useGetClientGeneralQuery({
    variables: {
      id: Number(clientId),
    },
  });

  return (
    <Fragment>
      <CardContainer
        title={t("newClientOverview.generalCard.title")}
        icon={<ClientSettingsButton />}
        error={error}
        loading={loading}
        sx={{ height: 534 }}
        skeletonHeight={650}>
        {data && <GeneralContent data={data} />}
      </CardContainer>
    </Fragment>
  );
}
