import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

export default function ListColumns({
  totalGrams,
  totalKcal,
}: {
  totalGrams: number;
  totalKcal: number;
}) {
  const { t } = useTranslation([TranslatorNS.PLANS]);
  return (
    <Stack
      // class handle controls if can be dragged
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "100%" }}>
      <Stack
        direction="row"
        width={"50%"}
        alignItems="center"
        fontSize={12}
        sx={{ overflow: "hidden", pr: 2, pl: 1 }}>
        <Stack>{t("layout.foodItems")}</Stack>
      </Stack>

      <Stack direction="row" sx={{ maxWidth: "45%" }}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
          <Box minWidth={80}>
            <Typography
              display={"inline-flex"}
              variant="body2"
              noWrap
              color="grey.500"
              fontSize={12}
              sx={{
                py: 0.5,
                px: 1,
                borderRadius: 1,
                "&:hover": {
                  cursor: "pointer",
                },
              }}>
              {t("layout.amount")}
            </Typography>
          </Box>
          <Typography variant="body2" color="grey.500" noWrap minWidth={80} fontSize={12}>
            {totalGrams}g
          </Typography>
          <Typography
            variant="body2"
            noWrap
            minWidth={135}
            color="grey.500"
            fontSize={12}>
            {Math.round(totalKcal)} {t("board.kcalAbbr")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
