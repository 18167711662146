import {
  TextField,
  Box,
  Stack,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { EQUIPMENT, MUSCLE_GROUPS } from "../../Exercises/const";
import { TranslatorKeyPair, TranslatorNS } from "../../../i18n/const";
import { debounce } from "lodash";

export interface ExerciseQuery {
  searchText?: string;
  muscleGroupId?: number;
  equipmentId?: number;
  useOwnExercisesOnly?: boolean;
}

export default function SearchExercises({
  setQueryParams,
}: {
  setQueryParams: (query: ExerciseQuery) => void;
}) {
  const { t } = useTranslation([TranslatorKeyPair.lists, TranslatorKeyPair.plans]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [muscleGroupId, setMuscleGroupId] = useState<string>("");
  const [equipmentId, setEquipmentId] = useState<string>("");
  const [useOwnExercisesOnly, setUseOwnExercisesOnly] = useState<boolean>(false);

  const getDebouncedParams = useMemo(
    () =>
      debounce(params => {
        setQueryParams(params);
        setLoading(false);
      }, 800),
    [setQueryParams],
  );
  useEffect(() => {
    setLoading(true);
    getDebouncedParams({ searchText });
  }, [searchText]);

  useEffect(() => {
    setQueryParams({
      muscleGroupId: Number(muscleGroupId),
      equipmentId: Number(equipmentId),
      useOwnExercisesOnly,
    });
  }, [muscleGroupId, equipmentId, useOwnExercisesOnly]);

  /**
   * sorting the options and inserting a "deselect" option
   */
  const sortedMuscleGroupsWithDeselect = [
    { id: "", label: t("search.filterMuscleGroup", { ns: TranslatorNS.PLANS }) },
    ...Object.entries(MUSCLE_GROUPS)
      .map(([id, label]) => ({
        id,
        label: t(label),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
  const sortedEquipmentWithDeselect = [
    { id: "", label: t("search.filterByEquipment", { ns: TranslatorNS.PLANS }) },
    ...Object.entries(EQUIPMENT)
      .map(([id, label]) => ({
        id,
        label: t(label),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];

  return (
    <Box id={"exercise-search-filters"}>
      <Stack>
        <Tooltip
          placement="top"
          title={t("search.tooltip.example", { ns: TranslatorNS.PLANS })}>
          <TextField
            size="small"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder={t("search.searchExercises", { ns: TranslatorNS.PLANS })}
            type="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {loading ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    <SearchOutlined />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Stack>
      <Box sx={{ mt: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <TextField
          fullWidth
          label={t("search.muscleGroup", { ns: TranslatorNS.PLANS })}
          size="small"
          select
          value={muscleGroupId}
          onChange={e => setMuscleGroupId(e.target.value)}>
          {sortedMuscleGroupsWithDeselect.map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Box sx={{ mr: 1 }} />
        <TextField
          fullWidth
          label={t("search.equipment", { ns: TranslatorNS.PLANS })}
          size="small"
          select
          value={equipmentId}
          onChange={e => setEquipmentId(e.target.value)}>
          {sortedEquipmentWithDeselect.map(({ id, label }) => (
            <MenuItem key={id} value={id}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Box sx={{ mb: 1 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">
          {t("layout.exercises", { ns: TranslatorNS.PLANS })}
        </Typography>
        <FormControlLabel
          sx={{ mb: 0 }}
          label={t("ownExerciseOption.showOnlyOwn", { ns: TranslatorNS.PLANS })}
          control={
            <Switch
              checked={useOwnExercisesOnly}
              onChange={e => setUseOwnExercisesOnly(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </Stack>
      <Box sx={{ mb: 1 }} />
    </Box>
  );
}
