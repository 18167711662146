import {
  TextField,
  Box,
  Stack,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { SearchOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { TranslatorKeyPair, TranslatorNS } from "../../../i18n/const";
import { debounce } from "lodash";

export interface IngredientQuery {
  searchText?: string;
  onlyMine?: boolean;
}

export default function SearchExercises({
  setQueryParams,
}: {
  setQueryParams: (query: IngredientQuery) => void;
}) {
  const { t } = useTranslation([TranslatorKeyPair.lists, TranslatorKeyPair.plans]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [onlyMine, setOnlyMine] = useState<boolean>(false);

  const getDebouncedParams = useMemo(
    () =>
      debounce(params => {
        setQueryParams(params);
        setLoading(false);
      }, 800),
    [setQueryParams],
  );
  useEffect(() => {
    setLoading(true);
    getDebouncedParams({ searchText });
  }, [searchText]);

  useEffect(() => {
    setQueryParams({ onlyMine });
  }, [onlyMine]);

  return (
    <Box id={"exercise-search-filters"}>
      <Stack>
        <Tooltip
          placement="top"
          title={t("search.tooltip.exampleRecipes", { ns: TranslatorNS.PLANS })}>
          <TextField
            size="small"
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            placeholder={t("search.searchExercises", { ns: TranslatorNS.PLANS })}
            type="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {loading ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    <SearchOutlined />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Tooltip>
      </Stack>
      <Box sx={{ mt: 2 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">
          {t("layout.foodItems", { ns: TranslatorNS.PLANS })}
        </Typography>
        <FormControlLabel
          sx={{ mb: 0 }}
          label={t("layout.onlyMyIngredients", { ns: TranslatorNS.PLANS })}
          control={
            <Switch
              size="small"
              checked={onlyMine}
              onChange={e => setOnlyMine(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </Stack>
      <Box sx={{ mb: 1 }} />
    </Box>
  );
}
