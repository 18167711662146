import {
  isModalOpen,
  ModalTypeEnum,
  TrackedWorkoutsModalContext,
} from "../../../store/modals/reducerTypes";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { closeGlobalModalAction } from "../../../store/modals/action";
import TrackedWorkoutsContainer from "./components/TrackedWorkoutsContainer";

interface Props {
  context?: TrackedWorkoutsModalContext;
  closeGlobalModalAction: (payload: ModalTypeEnum.TrackedWorkouts) => void;
  isModalOpen: boolean;
}

function TrackedWorkoutsModal(props: Props) {
  const { isModalOpen, context, closeGlobalModalAction } = props;

  if (!isModalOpen || !context) {
    return null;
  }

  const { savedWorkoutDate, savedWorkoutId } = context;

  return (
    <TrackedWorkoutsContainer
      savedWorkoutId={savedWorkoutId}
      savedWorkoutDate={savedWorkoutDate}
      onClose={() => closeGlobalModalAction(ModalTypeEnum.TrackedWorkouts)}
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    context: state?.globalModal[
      ModalTypeEnum.TrackedWorkouts
    ] as TrackedWorkoutsModalContext,
    isModalOpen: isModalOpen(ModalTypeEnum.TrackedWorkouts, state.globalModal),
  };
};

export default connect(mapStateToProps, { closeGlobalModalAction })(TrackedWorkoutsModal);
