import { Dispatch } from "redux";
import {
  CLIENT_IMAGES_ADD_IMAGE_FAILURE,
  CLIENT_IMAGES_ADD_IMAGE_REQUEST,
  CLIENT_IMAGES_ADD_IMAGE_SUCCESS,
  CLIENT_IMAGES_DELETE_IMAGE_FAILURE,
  CLIENT_IMAGES_DELETE_IMAGE_REQUEST,
  CLIENT_IMAGES_DELETE_IMAGE_SUCCESS,
  CLIENT_IMAGES_FAILURE,
  CLIENT_IMAGES_REQUEST,
  CLIENT_IMAGES_SUCCESS,
  CLIENT_IMAGES_LOAD_MORE_SUCCESS,
  CLIENT_IMAGES_RESET,
  ClientImagesActionTypes,
  Image,
} from "./types";
import * as api from "../../api";
import moment from "moment";
import { extractResponseError } from "../../../../../helpers/error";
import { PROGRESS_IMAGE_LIMIT } from "../../const";

const formatImagesWithShortDate = (imageArray: Image[]) =>
  imageArray.map((d: Image) => ({
    ...d,
    shortDate: d.date?.split("T")?.[0],
  }));

export const getClientImages = (clientId: number) => {
  return async (dispatch: Dispatch<ClientImagesActionTypes>, getState: any) => {
    dispatch({ type: CLIENT_IMAGES_REQUEST });
    try {
      const { data } = await api.getClientImages(clientId, 1, PROGRESS_IMAGE_LIMIT);

      dispatch({
        type: CLIENT_IMAGES_SUCCESS,
        payload: { clientId, imageList: formatImagesWithShortDate(data) },
      });
    } catch (error: any) {
      dispatch({
        type: CLIENT_IMAGES_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};

export const loadMoreClientImages = () => {
  return async (dispatch: Dispatch<ClientImagesActionTypes>, getState: any) => {
    dispatch({ type: CLIENT_IMAGES_REQUEST });
    try {
      const { clientImages } = getState().clientProgress.top;
      const page = clientImages.page;
      const clientId = clientImages.clientId;
      const { data } = await api.getClientImages(clientId, page + 1, PROGRESS_IMAGE_LIMIT);

      dispatch({
        type: CLIENT_IMAGES_LOAD_MORE_SUCCESS,
        payload: { imageList: formatImagesWithShortDate(data) },
      });
    } catch (error: any) {
      dispatch({
        type: CLIENT_IMAGES_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};

export const resetClientImages = () => {
  return async (dispatch: any) => {
    dispatch({
      type: CLIENT_IMAGES_RESET,
    });
  };
};

export const addClientImage = ({
  frontImage,
  backImage,
  sideImage,
  date,
  clientId,
}: {
  frontImage: File | null;
  backImage: File | null;
  sideImage: File | null;
  date: string;
  clientId: number;
}) => {
  return async (dispatch: Dispatch<ClientImagesActionTypes>) => {
    dispatch({ type: CLIENT_IMAGES_ADD_IMAGE_REQUEST });
    try {
      const bodyFormData = new FormData();

      if (frontImage) {
        bodyFormData.append("front-img", frontImage);
      }
      if (backImage) {
        bodyFormData.append("back-img", backImage);
      }
      if (sideImage) {
        bodyFormData.append("side-img", sideImage);
      }

      bodyFormData.append("date", moment(new Date(date)).format("YYYY-MM-DD"));

      const response = await api.uploadClientImages(clientId, bodyFormData);
      const formatted = formatImagesWithShortDate(response.data);

      formatted.forEach((image: Image) => {
        dispatch({
          type: CLIENT_IMAGES_ADD_IMAGE_SUCCESS,
          payload: image,
        });
      });
    } catch (error: any) {
      dispatch({
        type: CLIENT_IMAGES_ADD_IMAGE_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};
export const deleteClientImages = ({
  imageIds,
  clientId,
}: {
  imageIds: number[];
  clientId: number;
}) => {
  return async (dispatch: Dispatch<ClientImagesActionTypes>) => {
    dispatch({ type: CLIENT_IMAGES_DELETE_IMAGE_REQUEST });
    try {
      await api.deleteClientImages(clientId, imageIds);

      imageIds.forEach((id: number) => {
        dispatch({
          type: CLIENT_IMAGES_DELETE_IMAGE_SUCCESS,
          payload: { id },
        });
      });
    } catch (error: any) {
      dispatch({
        type: CLIENT_IMAGES_DELETE_IMAGE_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};
