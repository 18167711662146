import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/_MUI/Iconify";
import ZFLottie from "../../../components/Lottie";
import { Confetti } from "../assets/confetti";
import { Link } from "react-router-dom";
import { Routes } from "../../routeMap";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import NavigationSteps from "./NavigationSteps";

export default function LenusSuccess() {
  const { t } = useTranslation(TranslatorNS.AUTH);

  return (
    <>
      <Stack
        width={"100%"}
        height={{ xs: "82vh", sm: "100%" }}
        alignItems={"center"}
        justifyContent={"center"}
        zIndex={2}>
        <Stack
          border={"solid 2px"}
          borderColor={"success.dark"}
          boxShadow={"0 0 6px 0 #229A16"}
          borderRadius={10}
          padding={5}
          mb={5}>
          <Iconify color={"white"} icon={"uil:check"} height={32} width={32} />
        </Stack>
        <Stack sx={{ textAlign: "center", mb: 5, color: "white" }}>
          <Typography color={"white"} fontSize={"32px"} mb={0.5}>
            {t("transfer.welcomeToZenfit")}
          </Typography>
          <Typography color={"white"}>{t("transfer.yourDataWillBeMoved")}</Typography>
        </Stack>
        <LoadingButton
          to={Routes.DEFAULT_ROUTE}
          component={Link}
          variant={"contained"}
          sx={{ bgcolor: "primary.dark" }}
          size={"large"}>
          {t("transfer.continue")}
        </LoadingButton>
      </Stack>
      <Stack
        position={"absolute"}
        zIndex={0}
        width={"100%"}
        height={{ xs: "82vh", sm: "100%" }}>
        <ZFLottie
          lottie={Confetti}
          autoplay
          speed={0.5}
          delay={0}
          height={"100%"}
          width={"100%"}
          loop={false}
        />
      </Stack>
      <NavigationSteps />
    </>
  );
}
