import { InputType } from "../../components/InputField/enum";
import { CypressTestId } from "../../helpers/cypressTestIds";
import { IDiet } from "./type";

export const CREATE_ACCOUNT_FIELDS = {
  name: {
    label: "client.survey.fullName",
    name: "name",
    type: InputType.TEXT,
  },
  email: {
    label: "client.survey.email",
    name: "email",
    type: "email",
  },
  phone: {
    label: "client.survey.phone",
    name: "phone",
    type: "phone",
  },
  password: {
    label: "client.activation.password",
    name: "password",
    type: "password",
    cyId: CypressTestId.CLIENT_PAGE_PASSWORD,
  },
  passwordConfirm: {
    label: "client.activation.confirmPassword",
    name: "passwordConfirm",
    type: "password",
    cyId: CypressTestId.CLIENT_PAGE_CONFIRM_PASSWORD,
  },
};

export const ACTIVITY_LEVEL = {
  1: "client.survey.activityLevels.1",
  2: "client.survey.activityLevels.2",
  3: "client.survey.activityLevels.3",
  4: "client.survey.activityLevels.4",
  5: "client.survey.activityLevels.5",
};

export const WORKOUT_EXPERIENCE_LEVEL: { [key: number]: string } = {
  1: "client.survey.workoutExperienceLevels.1",
  2: "client.survey.workoutExperienceLevels.2",
  3: "client.survey.workoutExperienceLevels.3",
};

export const WORKOUT_LOCATIONS: { [key: number]: string } = {
  1: "client.survey.workoutLocations.1",
  2: "client.survey.workoutLocations.2",
  3: "client.survey.workoutLocations.3",
};

export const WORKOUT_PER_WEEK: { [key: number]: number } = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
};

export const WORKOUT_PREFERENCES_FIELDS = {
  injuries: {
    label: "client.survey.injuries",
    name: "injuries",
    type: InputType.TEXT,
  },
  workoutExperienceLevel: {
    label: "client.survey.workoutExperienceLevel",
    name: "experienceLevel",
    type: "select",
    options: WORKOUT_EXPERIENCE_LEVEL,
  },
  workoutElaboration: {
    label: "client.survey.workoutExperiencePlaceholder",
    name: "experience",
    type: InputType.TEXT,
  },
  workoutPreferences: {
    label: "client.survey.workoutPreferences",
    name: "exercisePreferences",
    type: InputType.TEXT,
  },
  workoutLocations: {
    label: "client.survey.selectWorkoutLocation",
    name: "workoutLocation",
    type: "select",
    options: WORKOUT_LOCATIONS,
  },
  workoutsPerWeek: {
    label: "client.survey.workoutsPerWeek",
    name: "workoutsPerWeek",
    type: "select",
    options: WORKOUT_PER_WEEK,
  },
};

export const NUMBER_OF_MEALS_PER_DAY = [3, 4, 5, 6];

export const DIET_PREFERENCES = {
  none: "client.survey.none",
  isPescetarian: "client.survey.pescetarianType",
  isVegetarian: "client.survey.vegetarianType",
  isVegan: "client.survey.veganType",
};

export const FOOD_PREFERENCES = {
  lactose: "client.survey.lactose",
  gluten: "client.survey.gluten",
  nuts: "client.survey.nuts",
  eggs: "client.survey.eggs",
  pig: "client.survey.pig",
  shellfish: "client.survey.shellfish",
  fish: "client.survey.fish",
};

export const DISORDERS_ARR: Partial<keyof IDiet>[] = [
  "significantLoss",
  "feelsFat",
  "foodDominates",
  "uncomfortablyFull",
  "lostControl",
];
