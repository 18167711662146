import { AppState } from "../../../store";
import {
  ClientInfoModalContext,
  isModalOpen,
  ModalTypeEnum,
} from "../../../store/modals/reducerTypes";
import { connect } from "react-redux";
import { closeGlobalModalAction } from "../../../store/modals/action";
import ClientInfoContainer from "./Components/ClientInfoContainer";

interface Props {
  context?: ClientInfoModalContext;
  closeGlobalModalAction: (payload: ModalTypeEnum.ClientInfo) => void;
  isModalOpen: boolean;
}

function ClientInfoModal(props: Props) {
  const { isModalOpen, context, closeGlobalModalAction } = props;

  if (!isModalOpen || !context) {
    return null;
  }

  const { clientId } = context;

  return (
    <ClientInfoContainer
      clientId={clientId}
      onClose={() => closeGlobalModalAction(ModalTypeEnum.ClientInfo)}
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    context: state.globalModal[ModalTypeEnum.ClientInfo] as ClientInfoModalContext,
    isModalOpen: isModalOpen(ModalTypeEnum.ClientInfo, state.globalModal),
  };
};

export default connect(mapStateToProps, { closeGlobalModalAction })(ClientInfoModal);
