import { ReactNode, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../i18n/const";
import axios from "../helpers/axios";
import { Button } from "@mui/material";

type Props = {
  children: ReactNode;
};

const isLocal = () => ["localhost", "127.0.0.1"].includes(window.location.hostname);
const currentVersion = () => (document.querySelector("[name=version]") as any)?.content;

const VERSION_CHECK_INTERVAL = 300000;
const VERSION_CHECK_FILE_PATH = "/version.txt";

export default function VersionProvider({ children }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.APP);

  useEffect(() => {
    if (!isLocal()) {
      setInterval(
        () =>
          axios.get(VERSION_CHECK_FILE_PATH).then(result => {
            const newestVersion: string = result.data;

            if (newestVersion.trim() !== currentVersion().trim()) {
              enqueueSnackbar(t("versionUpdate.text"), {
                variant: "info",
                action: () => (
                  <Button onClick={() => window.location.reload()}>
                    {t("versionUpdate.buttonText")}
                  </Button>
                ),
                persist: true,
              });
            }
          }),
        VERSION_CHECK_INTERVAL,
      );
    }
  }, []);

  return <>{children}</>;
}
