import { Dispatch } from "redux";
import { WorkoutplanActionTypes } from "../types";
import * as api from "../../api";
import { extractResponseError } from "../../../../helpers/error";
import { WorkoutPlan } from "../../../../interfaces/meal";
import { formatWorkoutplan } from "../helpers/formatData";

export const DUPLICATE_WORKOUTPLANS_REQUEST = "workoutplan/DUPLICATE_REQUEST";
export const DUPLICATE_WORKOUTPLANS_SUCCESS = "workoutplan/DUPLICATE_SUCCESS";
export const DUPLICATE_WORKOUTPLANS_FAILURE = "workoutplan/DUPLICATE_FAILURE";

// DUPLICATE
export interface DuplicateWorkoutplansRequest {
  type: typeof DUPLICATE_WORKOUTPLANS_REQUEST;
  payload: Partial<WorkoutPlan>;
}
export interface DuplicateWorkoutplansSuccess {
  type: typeof DUPLICATE_WORKOUTPLANS_SUCCESS;
  payload: WorkoutPlan;
}
export interface DuplicateWorkoutplansFailure {
  type: typeof DUPLICATE_WORKOUTPLANS_FAILURE;
  payload: any;
}

export interface DuplicateWorkoutplan {
  id: number;
  name: string;
  explanation: string;
  clientId: number;
}

export const duplicateWorkoutplans = ({
  id,
  name,
  explanation,
  clientId,
}: DuplicateWorkoutplan) => {
  return async (dispatch: Dispatch<WorkoutplanActionTypes>) => {
    dispatch({ type: DUPLICATE_WORKOUTPLANS_REQUEST });
    try {
      const data = await api.duplicateWorkoutPlan({
        id,
        name,
        explanation,
        clientId,
      });
      dispatch({
        type: DUPLICATE_WORKOUTPLANS_SUCCESS,
        payload: formatWorkoutplan(data, clientId),
      });
    } catch (error: any) {
      console.log({ error });

      dispatch({
        type: DUPLICATE_WORKOUTPLANS_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};
