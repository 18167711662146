export default function LenusXZenfitIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 147 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>Group</title>
      <g
        id="Lenus-Transfer-Flow"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="Transfer-Flow---1" transform="translate(-646.000000, -44.000000)">
          <g id="Group" transform="translate(646.000000, 45.000000)">
            <g
              id="Zenfit-Logo"
              transform="translate(73.000000, 22.000000)"
              fill="#FCFCFC"
              fillRule="nonzero">
              <path
                d="M0.136632821,7.43749973 L6.70896536,7.43749973 L5.73710933,12.6827059 L15.2352941,12.6827059 C13.8382455,15.3385175 11.0828075,17.0011685 8.08043566,17 C3.61783141,17 0,13.3852479 0,8.9248038 C0,8.41680063 0.0470134437,7.91907497 0.136632821,7.43749973 Z"
                id="Path"></path>
              <path
                d="M0.708085334,5.43292961 C2.08467491,2.75007439 4.85779781,1.06166764 7.88629503,1.06249965 C12.3355985,1.06249965 15.9426471,4.64989433 15.9426471,9.07495401 C15.9426471,9.60523276 15.8913794,10.1238571 15.7917736,10.6249996 L9.70483338,10.6249996 L10.7997649,5.43292961 L0.707352941,5.43292961 L0.708085334,5.43292961 Z"
                id="Path"></path>
              <polygon
                id="Path"
                points="32.2661765 15.2468744 32.2661765 12.5435171 27.402246 12.5435171 32.1831921 7.57326624 32.1831921 4.99374982 23.6858921 4.99374982 23.6858921 7.67520849 28.1326579 7.67520849 23.5602941 12.4823518 23.5602941 15.2461193 32.2661765 15.2461193"></polygon>
              <path
                d="M47.4246216,9.14578828 C47.4246216,8.14063771 48.0959865,7.39670855 49.1443371,7.39670855 C50.2978231,7.39670855 50.8430255,8.13990188 50.8430255,9.12592076 L50.8430255,14.8749995 L54.0308824,14.8749995 L54.0308824,8.60274206 C54.0308824,6.41142551 52.8563693,4.62187483 50.2347418,4.62187483 C49.1653642,4.62187483 47.8864666,5.08545087 47.3202371,6.02952716 L47.3202371,4.88383208 L44.2367647,4.88383208 L44.2367647,14.8749995 L47.4238706,14.8749995 L47.4238706,9.14578828 L47.4246216,9.14578828 Z"
                id="Path"></path>
              <path
                d="M63.5483075,7.37687502 L63.5483075,14.8749995 L66.7632353,14.8749995 L66.7632353,4.77633503 L59.6782767,4.77633503 L59.6782767,3.90155384 C59.6782767,2.88543896 60.5030252,2.64145237 61.0528572,2.64145237 C61.5178666,2.64145237 61.8139885,2.70244902 61.9616707,2.74336141 L61.9616707,0.223158465 C61.6663063,0.0818247705 61.0104459,0 60.4818193,0 C57.921995,0 56.4633494,1.58442511 56.4633494,3.90155384 L56.4633494,4.77559116 L54.7926471,4.77559116 L54.7926471,7.37687502 L56.4633494,7.37687502 L56.4633494,14.8749995 L59.6782767,14.8749995 L59.6782767,7.37687502 L63.5483075,7.37687502 L63.5483075,7.37687502 Z"
                id="Path"></path>
              <path
                d="M63.1183805,1.77932147 C63.1183805,2.76010747 63.9373913,3.55937487 64.9199111,3.55937487 C65.9442245,3.55937487 66.7632353,2.75937554 66.7632353,1.77932147 C66.7632353,0.779505291 65.9442245,0 64.9191776,0 C63.9366578,0 63.1176471,0.779505291 63.1176471,1.77932147 L63.1183805,1.77932147 Z"
                id="Path"></path>
              <path
                d="M71.9989323,2.12499992 L69.1934492,2.12499992 L69.1934492,3.41409146 C69.1934492,4.34161754 68.6778547,5.04703912 67.6053003,5.04703912 L67.0897059,5.04703912 L67.0897059,7.72778874 L68.9046277,7.72778874 L68.9046277,12.0223009 C68.9046277,14.0175519 70.2046934,15.2468744 72.3291197,15.2468744 C73.3189426,15.2468744 73.8352755,15.0255078 73.958634,14.965001 L73.958634,12.4251881 C73.773227,12.4650341 73.4637225,12.5255409 73.1335356,12.5255409 C72.4532166,12.5255409 71.9989323,12.3041743 71.9989323,11.477739 L71.9989323,7.72778874 L74,7.72778874 L74,5.04777701 L71.9989323,5.04777701 L71.9989323,2.12499992 Z"
                id="Path"></path>
              <path
                d="M37.8868227,4.62187483 C41.1411513,4.62187483 43.1485294,6.53685427 43.1485294,9.7631703 C43.1485294,9.78434949 43.1484235,9.80562823 43.1482196,9.82695133 L43.1476834,9.86964008 L43.1476834,9.86964008 L43.1469002,9.91234848 C43.138931,10.2894035 43.1036856,10.6572455 43.0849712,10.7106977 L43.0849712,10.7106977 L35.774271,10.7106977 C35.8378292,11.8390228 36.9152911,12.6456018 38.1614847,12.6456018 C39.3236909,12.6456018 39.9789451,12.1216575 40.2747932,11.3350032 L40.2747932,11.3350032 L42.9586116,12.0611456 C42.4509031,13.7945895 40.7817451,15.2468744 38.1198693,15.2468744 C35.224947,15.2468744 32.6470588,13.2706452 32.6470588,9.90411868 C32.6470588,6.6785406 35.1613889,4.62187483 37.8868227,4.62187483 Z M37.9291949,7.04087391 C36.577071,7.04087391 35.9006306,7.98913924 35.8378292,8.73446846 L35.8378292,8.73446846 L40.0425033,8.73446846 C40.0001312,7.96847665 39.4500505,7.04087391 37.9291949,7.04087391 Z"
                id="Combined-Shape"></path>
            </g>
            <line
              x1="19.2152778"
              y1="61.7832168"
              x2="80.7847222"
              y2="0.216783217"
              id="Line"
              stroke="#FFFFFF"
              strokeWidth="0.685314685"
              opacity="0.401622954"
              strokeLinecap="square"></line>
            <g
              id="lenuslogo"
              transform="translate(0.000000, 14.000000)"
              fillRule="nonzero">
              <path
                d="M0,33.9184391 C0.065465961,33.9510463 0.130931922,33.9673929 0.196397883,34 C2.79867144,31.3733184 5.40094714,28.7466367 8.03595689,26.1363017 C8.44511111,25.7447149 8.59241005,25.3368675 8.59241005,24.7821589 C8.57603874,16.8531603 8.57603874,8.90785813 8.57603874,0.97888532 C8.57603874,0.701535339 8.54329612,0.407869744 8.52692481,0 C8.21599846,0.293666026 7.98684295,0.48944266 7.79047293,0.685218863 C5.90828637,2.5777365 4.00979279,4.45392906 2.1276448,6.33013023 C0.720127713,7.74953029 0,9.44626312 0,11.4529789 C0,18.6804078 0,25.9078796 0,33.1353085 C0,33.3963807 0,33.6574099 0,33.9184391 Z M29.9997857,17.212097 C29.9670431,17.1468397 29.917972,17.0815824 29.8852294,17.0163251 C25.7936015,17.0163251 21.7019736,17.0000215 17.626717,17.0326287 C17.3484904,17.0326287 17.0375212,17.2610507 16.824737,17.4731262 C14.3533975,19.9040359 11.9148006,22.3349456 9.45983243,24.7821589 C9.26341955,24.9779308 9.08342083,25.2063529 8.80519425,25.4999892 C9.0343069,25.5326394 9.13249191,25.548943 9.23071978,25.548943 C12.4712538,25.548943 15.7282019,25.5978967 18.9687788,25.5326394 C20.8018086,25.4999892 22.4057257,24.7658554 23.7150449,23.4606663 C25.2862194,21.8781445 26.8737652,20.3119263 28.4613538,18.7293615 C28.9523218,18.2073031 29.4924036,17.7178518 29.9997857,17.212097 Z"
                id="Shape"
                fill="#CCFFC8"></path>
              <path
                d="M0,33.9184391 C0,33.6574099 0,33.3963807 0,33.1353085 C0,25.9078796 0,18.6804078 0,11.4529789 C0,9.44626312 0.720127713,7.74953029 2.1276448,6.33013023 C4.00979279,4.45392906 5.90828637,2.5777365 7.79043007,0.685218863 C8.00321426,0.48944266 8.21599846,0.293666026 8.52692481,0 C8.54329612,0.407869744 8.57603874,0.701535339 8.57603874,0.97888532 C8.57603874,8.90785813 8.57603874,16.8368567 8.59241005,24.7821589 C8.59241005,25.3368675 8.44511111,25.7284113 8.03595689,26.1363017 C5.40094714,28.7466367 2.79867144,31.3733184 0.196397883,34 C0.130931922,33.9673929 0.065465961,33.9347427 0,33.9184391 Z"
                id="Path"
                fill="#FCFCFC"></path>
              <path
                d="M30,17.212097 C29.4762466,17.7178518 28.9525361,18.2073031 28.445154,18.7293615 C26.8576082,20.2955797 25.2864337,21.8781445 23.6988879,23.4606663 C22.3895686,24.7821589 20.7856515,25.5000323 18.9525789,25.5326394 C15.7120449,25.5978967 12.4550967,25.548943 9.2145199,25.548943 C9.11633488,25.548943 9.01814987,25.5326394 8.78899436,25.5000323 C9.06722095,25.2063529 9.24726252,24.9779308 9.4436754,24.7821589 C11.8986436,22.3512492 14.3536118,19.9040359 16.8085799,17.4731262 C17.0213641,17.2610507 17.3486618,17.0326287 17.6105599,17.0326287 C21.702145,17.0000215 25.7937729,17.0163251 29.8690295,17.0163251 C29.9181434,17.0815824 29.9672574,17.1468397 30,17.212097 Z"
                id="Path"
                fill="#FCFCFC"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
