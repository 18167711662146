export type MealPlan = {
  id: number;
  name: string;
  template: boolean;
  contains_alternatives: boolean;
  desired_kcals: {};
  avg_totals: number;
  locale: string;
  lastUpdated: string;
  description?: string;
  templates?: MealTemplate[];
};

export type WorkoutPlan = {
  id: number;
  name: string;
  comment: string;
  template: boolean;
  lastUpdated: string;
  dayId: string;
  dayName: string;
  meta: {
    workoutsPerWeek: number;
    duration: number;
    gender: number;
    level: number;
    location: number;
  };
  settings: {
    reps: boolean;
    rest: boolean;
    rm: boolean;
    sets: boolean;
    tempo: boolean;
    time: boolean;
    weight: boolean;
  };
  templates?: WorkoutTemplate[];
};

export type RecipePlan = {
  id: number;
  name: string;
  macro_split: number;
  locale: string;
  template: boolean;
  admin: boolean;
  recipeMeta: { [key: string]: boolean };
  types: { id: number; type: number }[];
  description?: string;
  cooking_time: number;
  isLenusRecipe: boolean;
  servings: number;
};

export type Client = {
  workoutsPerWeek?: number;
  id?: number;
  name: string;
  firstname: string;
};

type WorkoutTemplate = {
  name: string;
  id: number;
  explaination: string;
  days: number;
};

type MealTemplate = {
  name: string;
  id: number;
  explaination: string;
  mealsSize: number;
};

export enum PlanId {
  NONE = "",
  MEAL = "meal-planner",
  WORKOUT = "workout-plans",
  RECIPE = "recipe-planner",
}
