import React from "react";
import { useTranslation } from "react-i18next";
import { Client, MealPlan } from "../../../types";
import { UPDATE_MEAL } from "../../../../../api/private/meals";

interface Props {
  plan: MealPlan;
  client?: Client;
}

export default function MealApplyTemplate({ plan, client }: Props) {
  const { t } = useTranslation("meal");
  const PATH = UPDATE_MEAL(plan.id);

  return (
    <div
      className="modal inmodal in reactModal"
      id="addMealTemplate"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("applyTemplate.close")}</span>
            </button>
            <h4 className="modal-title">{t("editor.applyMealTemplate")}</h4>
            <p>{t("applyTemplate.youCanAddOneOrSeveral")}</p>
          </div>
          <form
            id="applyTemplateForm"
            action={PATH}
            method="POST"
            onSubmit={e => e.preventDefault()}>
            {client?.id !== null && (
              <input type="hidden" name="client" value={client?.id} />
            )}
            <div className="modal-body">
              <div style={{ textAlign: "center" }}>
                {/*<img id="spinnerApplyTemplate"
                     style={{"display": "none"}}
                     src={'/images/ajax-loader.gif'} alt={'loading'}
                />*/}
              </div>
              <div id="applyTemplateCheckboxes">
                {plan.templates?.length === 0 && (
                  <div className="alert alert-info">
                    {t("applyTemplate.itDoesntSeemLike")}
                    <strong>
                      <a href="#" style={{ color: "black" }}>
                        {t("applyTemplate.clickHere")}
                      </a>
                    </strong>
                    {t("applyTemplate.toCreateYourFirst")}
                  </div>
                )}
                {plan.templates?.length !== 0 &&
                  plan.templates?.map(template => (
                    <div className="checkbox" key={template.id}>
                      <label htmlFor={`template_${template.id}`}>
                        <input
                          type="checkbox"
                          id={`template_${template.id}`}
                          name="templates[]"
                          value={template.id}
                        />
                        <strong>
                          {template.name} - {template.explaination}
                        </strong>
                        <span
                          className={`label label-${
                            template.mealsSize == 0 ? "warning" : "inverse"
                          }`}>
                          {template.mealsSize === 0
                            ? t("applyTemplate.empty")
                            : `${template.mealsSize} ${t("applyTemplate.meals")}`}
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            {plan.templates?.length !== 0 && (
              <div className="modal-footer text-left">
                <button
                  type="submit"
                  id="saveTemplate"
                  className="btn btn-block btn-success btn-upper">
                  {t("applyTemplate.addMealTemlpates")}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
