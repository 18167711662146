import Page from "../../components/_MUI/Page";
import { RoutesTitle } from "../routeMap";
import { Stack, Typography } from "@mui/material";
import ClientVideosTableContainer from "./components/ClientVideosTableContainer";
import { connect } from "react-redux";
import { AppState } from "../../store";
import SkeletonContainer from "../../containers/SkeletonContainer";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";

function ClientVideos({ clientName }: { clientName: string }) {
  const { t } = useTranslation(TranslatorNS.LISTS);
  return (
    <Page title={RoutesTitle.CLIENT_VIDEOS}>
      <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"}>
        <Stack width={{ xs: "100%", sm: "70%" }} maxWidth={{ xs: "100%", sm: "70%" }}>
          <Typography variant={"h4"}>
            <SkeletonContainer height={40} width={"50%"} loading={!Boolean(clientName)}>
              {t("videos.clientVideos.title", { clientName })}
            </SkeletonContainer>
          </Typography>
          <Typography variant={"inherit"} mt={1}>
            <SkeletonContainer height={25} loading={!Boolean(clientName)}>
              {t("videos.clientVideos.description", { clientName })}
            </SkeletonContainer>
          </Typography>
        </Stack>
        <Stack>
          {/*
          Will be re-added when backend is ready for add videos
          <Button
            size={"large"}
            variant={"contained"}
            onClick={() => {
              console.log("hejsa");
            }}>
            Add video
          </Button>
          */}
        </Stack>
      </Stack>
      <Stack mt={3} mb={5}>
        <ClientVideosTableContainer />
      </Stack>
    </Page>
  );
}

const mapStateToProps = (appState: AppState) => {
  return {
    clientName: appState.currentClient.name,
  };
};

export default connect(mapStateToProps)(ClientVideos);
