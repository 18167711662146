import { keyBy, omit } from "lodash";

import * as createAction from "./actions/create";
import * as deleteAction from "./actions/delete";
import * as fetchAction from "./actions/fetch";
import * as duplicateAction from "./actions/duplicate";
import * as updateAction from "./actions/update";
import { WorkoutplanActionTypes, WorkoutplanState } from "./types";

const INITIAL_STATE: WorkoutplanState = {
  workoutPlansInitialLoading: true,
  workoutplansIds: [],
  workoutplansMap: {},
  workoutplansLoading: true,
  workoutplansError: null,
  workoutplansCreateError: null,
  workoutplansUpdateLoading: false,
  workoutplansDeleteLoading: false,
  workoutplansCreateLoading: false,
};

export default function Reducer(
  state = INITIAL_STATE,
  action: WorkoutplanActionTypes,
): WorkoutplanState {
  switch (action.type) {
    case fetchAction.FETCH_WORKOUTPLANS_REQUEST: {
      return {
        ...state,
        workoutplansLoading: true,
      };
    }
    case fetchAction.FETCH_WORKOUTPLANS_SUCCESS: {
      return {
        ...state,
        workoutplansIds: action.payload.map(plan => plan.id),
        workoutplansMap: keyBy(action.payload, "id"),
        workoutplansLoading: false,
        workoutPlansInitialLoading: false,
      };
    }
    case fetchAction.FETCH_WORKOUTPLANS_FAILURE: {
      return {
        ...state,
        workoutplansError: action.payload,
        workoutplansLoading: false,
      };
    }
    // Update
    case updateAction.UPDATE_WORKOUTPLANS_REQUEST: {
      return {
        ...state,
        workoutplansUpdateLoading: true,
      };
    }
    case updateAction.UPDATE_WORKOUTPLANS_SUCCESS: {
      return {
        ...state,
        // workoutplansIds: [...state.workoutplansIds, action.payload.id],
        workoutplansMap: {
          ...state.workoutplansMap,
          [action.payload.id]: {
            ...state.workoutplansMap[action.payload.id],
            ...action.payload.workoutplan,
          },
        },
        workoutplansUpdateLoading: false,
      };
    }
    case updateAction.UPDATE_WORKOUTPLANS_FAILURE: {
      return {
        ...state,
        workoutplansError: action.payload,
        workoutplansUpdateLoading: false,
      };
    }
    // Delete
    case deleteAction.DELETE_WORKOUTPLANS_REQUEST: {
      return {
        ...state,
        workoutplansDeleteLoading: true,
      };
    }
    case deleteAction.DELETE_WORKOUTPLANS_SUCCESS: {
      return {
        ...state,
        workoutplansIds: state.workoutplansIds.filter(id => id !== action.payload.id),
        workoutplansMap: omit(state.workoutplansMap, action.payload.id),
        workoutplansDeleteLoading: false,
      };
    }
    case deleteAction.DELETE_WORKOUTPLANS_FAILURE: {
      return {
        ...state,
        workoutplansError: action.payload,
        workoutplansDeleteLoading: false,
      };
    }
    // CREATE
    case createAction.CREATE_WORKOUTPLANS_REQUEST:
    case duplicateAction.DUPLICATE_WORKOUTPLANS_REQUEST:
      return {
        ...state,
        workoutplansCreateLoading: true,
        workoutplansCreateError: null,
      };

    case createAction.CREATE_WORKOUTPLANS_SUCCESS:
    case duplicateAction.DUPLICATE_WORKOUTPLANS_SUCCESS:
      return {
        ...state,
        workoutplansIds: [...state.workoutplansIds, action.payload.id],
        workoutplansMap: {
          ...state.workoutplansMap,
          [action.payload.id]: action.payload,
        },
        workoutplansCreateLoading: false,
      };

    case createAction.CREATE_WORKOUTPLANS_FAILURE:
    case duplicateAction.DUPLICATE_WORKOUTPLANS_FAILURE:
      return {
        ...state,
        workoutplansCreateError: action.payload,
        workoutplansCreateLoading: false,
      };

    default:
      return state;
  }
}
