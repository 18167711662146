export const DIET_PREFERENCES = {
  none: "client.survey.none",
  isPescetarian: "client.survey.pescetarianType",
  isVegetarian: "client.survey.vegetarianType",
  isVegan: "client.survey.veganType",
};

export const EXCLUDED_INGREDIENTS = {
  lactose: "client.survey.lactose",
  gluten: "client.survey.gluten",
  nuts: "client.survey.nuts",
  eggs: "client.survey.eggs",
  pig: "client.survey.pig",
  shellfish: "client.survey.shellfish",
  fish: "client.survey.fish",
};
