import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { Button, Link, Stack, Typography } from "@mui/material";
import CardContainer from "../../../../containers/CardContainer/CardContainer";
import { gql, useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import { Routes } from "../../../routeMap";

const GET_INVOICE_URL = gql`
  {
    me {
      subscription {
        invoiceUrl
        lastPaymentFailed
      }
    }
  }
`;

export default function LenusPaymentFailed() {
  const { t } = useTranslation(TranslatorNS.SETTINGS);
  const { data, loading } = useQuery(GET_INVOICE_URL);

  const invoiceUrl = data?.me?.subscription?.invoiceUrl;
  const lastPaymentFailed = !!data?.me?.subscription?.lastPaymentFailed;

  if (!loading && !lastPaymentFailed) {
    return <Navigate to={Routes.LENUS_TRANSFER} />;
  }

  return (
    <CardContainer
      title={t("userIntro.dontWorry")}
      loading={loading}
      sx={{ maxWidth: 400 }}
      skeletonHeight={225}>
      <Stack gap={4} mb={2} justifyContent={"space-between"}>
        <Typography variant={"subtitle2"}>
          {t("userIntro.yourAccountTempInactive")}
        </Typography>
        <Button component={Link} variant={"contained"} href={invoiceUrl}>
          {t("userIntro.payInvoice")}
        </Button>
      </Stack>
    </CardContainer>
  );
}
