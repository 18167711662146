export enum RecorderStatusEnum {
  NOT_READY = "NOT_READY",
  NO_PERMISSIONS = "NO_PERMISSIONS",
  READY = "READY",
  RECORDING = "RECORDING",
  READY_TO_SEND = "READY_TO_SEND",
  ERROR = "ERROR",
}

export type ZFRecorderType = {
  status: RecorderStatusEnum;
  recordedChunks: ArrayBuffer[];
  timeCounter: number;
  previewUrl: string;
  blob: object;
  error: string;
};
