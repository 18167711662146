import { PlatformOriginEnum } from "../store/user/reducerTypes";
import { AppStatus } from "../store/user/reducer";
import { Routes } from "../routes/routeMap";
import { TransferProgressEnum } from "../generated/graphql";

export function prepareRoute(url: string, params: { [key: string]: any }) {
  //@ts-ignore
  Object.keys(params).map(param => {
    const newKey = `:${param}`;
    delete Object.assign(params, { [newKey]: params[param] })[param];
  });
  let re = new RegExp(Object.keys(params).join("|"), "gi");
  return url.replace(re, function (matched: any) {
    return params[matched];
  });
}

export function getNextAuthRoute(
  platformOrigin: PlatformOriginEnum,
  appStatus: AppStatus,
  activated: boolean,
  pathname: string,
  token: string | null | undefined,
  configured: boolean,
  transferInProgress?: TransferProgressEnum,
): Routes | null {
  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return Routes.LOGIN_ROUTE;
  }

  if (
    // if lenus coach is not paying
    platformOrigin === PlatformOriginEnum.Lenus &&
    appStatus === AppStatus.READY &&
    !activated &&
    !pathname.includes("lenus/transfer")
  ) {
    return Routes.LENUS_SIGNUP;
  }

  // Transfer in progress
  if (
    platformOrigin === PlatformOriginEnum.Lenus &&
    appStatus === AppStatus.READY &&
    activated &&
    !pathname.includes(Routes.LENUS_TRANSFER_PROGRESS) &&
    !pathname.includes(Routes.LENUS_TRANSFER_SUCCESS) &&
    transferInProgress === TransferProgressEnum.InProgress
  ) {
    return Routes.LENUS_TRANSFER_PROGRESS;
  }

  if (
    // Coach is either missing payment or doesn't have active sub/ trial end
    platformOrigin === PlatformOriginEnum.Zenfit &&
    appStatus === AppStatus.READY &&
    !activated &&
    configured &&
    !pathname.includes("intro")
  ) {
    return Routes.INTRO_ROUTE;
  }

  if (
    // Missing final info from signup ( phone and client count )
    platformOrigin === PlatformOriginEnum.Zenfit &&
    appStatus === AppStatus.READY &&
    !configured &&
    !pathname.includes(Routes.FINISH_SIGN_UP_ROUTE)
  ) {
    return Routes.FINISH_SIGN_UP_ROUTE;
  }

  return null;
}

export function getNextLenusRoute(
  activated: boolean,
  loggedIn: boolean,
  pathname: string,
  loading: boolean,
  lenusToken: string | undefined,
  transferInProgress: TransferProgressEnum,
  lastPaymentFailed: boolean,
  emailSubject?: string,
  emailContent?: string,
): Routes | null {
  // latest payment have failed
  if (
    activated &&
    lastPaymentFailed &&
    !loading &&
    !pathname.includes("payment-failed")
  ) {
    return Routes.LENUS_PAYMENT_FAILED;
  }

  // Does not have a subscription or payment has failed
  if (!activated && !loading && loggedIn && !pathname.includes("subscription")) {
    return Routes.LENUS_SUBSCRIPTION;
  }

  // Does not have an account
  if (!activated && !loading && !loggedIn && !pathname.includes("sign-up")) {
    return Routes.LENUS_SIGNUP;
  }

  // Transfer in progress
  if (
    activated &&
    !loading &&
    loggedIn &&
    !pathname.includes(Routes.LENUS_TRANSFER_PROGRESS) &&
    !pathname.includes(Routes.LENUS_TRANSFER_SUCCESS) &&
    transferInProgress === TransferProgressEnum.InProgress
  ) {
    return Routes.LENUS_TRANSFER_PROGRESS;
  }

  // Transfer success
  if (
    lenusToken &&
    activated &&
    !loading &&
    loggedIn &&
    !pathname.includes(Routes.LENUS_TRANSFER_SUCCESS) &&
    transferInProgress === TransferProgressEnum.Completed
  ) {
    return Routes.LENUS_TRANSFER_SUCCESS;
  }
  // Logged in and has not transferred
  if (
    lenusToken &&
    activated &&
    loggedIn &&
    !loading &&
    !pathname.includes(Routes.LENUS_TRANSFER_EMAIL) &&
    transferInProgress === TransferProgressEnum.NotStarted &&
    !emailSubject &&
    !emailContent
  ) {
    return Routes.LENUS_TRANSFER_EMAIL;
  }

  // Has not logged into Lenus Account
  if (
    transferInProgress !== TransferProgressEnum.InProgress &&
    !lenusToken &&
    activated &&
    loggedIn &&
    !loading &&
    pathname !== Routes.LENUS_TRANSFER &&
    !pathname.includes("start")
  ) {
    return Routes.LENUS_TRANSFER;
  }

  return null;
}
