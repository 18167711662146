import React, { Fragment, useRef, useState } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import store, { AppState } from "../../../store";
import { TranslatorNS } from "../../../i18n/const";
import { Locale } from "../../../interfaces/locale";
import ModalExtend from "../../Clients/components/modal-duration-extend";
import ModalConfirmNeed from "../../Clients/components/modal-confirm-need";
import { CurrentClientState, TaskStatus } from "../../../store/currentClient/types";
import { AddClientStep } from "../../../components/Modal/AddClientModal/types";
import Questionnaire from "../../../components/Modal/AddClientModal/Questionnaire";
import {
  approveClientTaskAction,
  extendCurrentClientDuration,
  ignoreClientTaskAction,
} from "../../../store/currentClient/action";
import { handleResendInvitationModal } from "../../Clients/store/clients/actions";
import { DefaultMessageTypeEnum } from "../../../api/private/default-messages";
import AddTaskModal from "./components/AddTaskModal";
import ModalChatTemplates from "../../Modals/modal-chat-templates";
import CardContainer from "../../../containers/CardContainer/CardContainer";
import { RequestState } from "../../../interfaces/requestState";
import TaskItem from "./components/TaskItem";
import AllDone from "./components/AllDone";

interface Props {
  clientDetails: CurrentClientState;
  userId: number;
  locale: Locale;
  resendInvitationData: {
    messageType: number;
    client: CurrentClientState;
  };
  handleResendInvitationModal: (data: null) => void;
  ignoreClientTaskAction: (task: TaskStatus) => void;
  approveClientTaskAction: (taskId: number) => void;
  extendCurrentClientDuration: (clientId: number, endDate: string) => Promise<void>;
  loading: boolean;
}

function Tasks({
  clientDetails,
  userId,
  resendInvitationData,
  handleResendInvitationModal,
  ignoreClientTaskAction,
  approveClientTaskAction,
  extendCurrentClientDuration,
  loading,
}: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  let modalRef = useRef<any>();
  const allTasks = [...clientDetails.status, ...clientDetails.reminders];
  const unresolvedTasks = allTasks.filter(status => !status.resolved);
  const resolvedTasks = allTasks.filter(status => status.resolved);
  const [extendClientDurationTaskId, setExtendClientDurationTaskId] = useState<
    number | null
  >(null);
  const [selectedTaskWithConfirmation, setSelectedTaskWithConfirmation] =
    useState<TaskStatus | null>(null);
  const [completedTasksActive, setCompletedTasksActive] = useState<boolean>(false);

  const displayedTasks = completedTasksActive ? resolvedTasks : unresolvedTasks;
  const getButtonString = completedTasksActive
    ? t("clientTask.showCompletedTasks", {
        completedTasksNumber: resolvedTasks.length,
      })
    : t("clientTask.showUnresolvedTasks", {
        unresolvedTasksNumber: unresolvedTasks.length,
      });

  const chatTemplateClient = {
    id: clientDetails.id,
    name: clientDetails.name,
    photo: clientDetails.photo,
    messages: 8,
  };

  const onTaskIgnoreWithConfirmationConfirm = () => {
    if (!selectedTaskWithConfirmation) return;

    ignoreClientTaskAction(selectedTaskWithConfirmation);
    setSelectedTaskWithConfirmation(null);
  };

  const handleClientDurationExtend = async (endDate: string) => {
    await extendCurrentClientDuration(clientDetails.id, endDate);
    extendClientDurationTaskId && approveClientTaskAction(extendClientDurationTaskId);
    setExtendClientDurationTaskId(null);
  };

  return (
    <>
      <CardContainer
        title={t("clientTask.title")}
        error={undefined}
        icon={<AddTaskModal clientId={clientDetails.id} />}
        loading={loading}
        sx={{ height: 533 }}
        skeletonHeight={650}>
        <Stack direction="column" sx={styles.tasksWrapper}>
          <Box sx={styles.tasksHeader}>
            <Typography color={"text.secondary"}>
              {t(
                unresolvedTasks.length === 1
                  ? "clientTask.pendingTasksSingular"
                  : "clientTask.pendingTasks",
                {
                  pendingTasksNumber: unresolvedTasks.length,
                },
              )}
            </Typography>
          </Box>
          <Stack direction="column" sx={{ height: 347, overflow: "auto", mb: 1 }}>
            {displayedTasks.map(task => (
              <Fragment key={task.id}>
                <TaskItem
                  modalRef={modalRef}
                  task={task}
                  extendClientDurationTaskId={() =>
                    setExtendClientDurationTaskId(task.id)
                  }
                  selectedTaskWithConfirmation={() =>
                    setSelectedTaskWithConfirmation(task)
                  }
                  ignoreClientTaskAction={() => ignoreClientTaskAction(task)}
                />
              </Fragment>
            ))}
            {displayedTasks.length === 0 && !completedTasksActive && <AllDone />}
            {!allTasks.length && (
              <Typography textAlign="center" color="GrayText">
                {t("clientTask.noData")}
              </Typography>
            )}
          </Stack>
          <Button
            variant="outlined"
            size="large"
            onClick={() => setCompletedTasksActive(!completedTasksActive)}
            color={"inherit"}>
            {getButtonString}
          </Button>
        </Stack>
      </CardContainer>
      {selectedTaskWithConfirmation && (
        <ModalConfirmNeed
          show={true}
          onClose={() => setSelectedTaskWithConfirmation(null)}
          onSubmit={onTaskIgnoreWithConfirmationConfirm}
        />
      )}
      {!!resendInvitationData && (
        <Dialog
          open={true}
          onClose={() =>
            store.dispatch(
              handleResendInvitationModal(null) as unknown as Dispatch<AppState>,
            )
          }
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "720px!important",
            },
          }}>
          <Questionnaire
            hiddenSteps={Object.keys(AddClientStep) as AddClientStep[]}
            invitationData={{
              messageType: resendInvitationData.messageType,
              locale: resendInvitationData.client.locale,
              clientName: resendInvitationData.client.name,
              clientEmail: resendInvitationData.client.email,
              clientId: resendInvitationData.client.id,
              dataKey:
                resendInvitationData.messageType ===
                DefaultMessageTypeEnum.TYPE_PAYMENT_MESSAGE_EMAIL
                  ? resendInvitationData.client?.paymentInfo.datakey
                  : resendInvitationData.client?.queue.datakey,
              queueId: resendInvitationData.client.queue.id,
            }}
            closeClientModal={() =>
              store.dispatch(
                handleResendInvitationModal(null) as unknown as Dispatch<AppState>,
              )
            }
            // Override prop from redux
            changeFilterProperty={() => {}}
          />
        </Dialog>
      )}
      {!!extendClientDurationTaskId && (
        <ModalExtend
          show={true}
          endDate={clientDetails?.endDate}
          onClose={() => setExtendClientDurationTaskId(null)}
          onSubmit={handleClientDurationExtend}
        />
      )}
      <ModalChatTemplates
        modalRef={(reference: any) => {
          if (modalRef) {
            modalRef.current = reference;
          }
        }}
        client={chatTemplateClient}
        clientId={clientDetails.id}
        defaultMessageType={8}
        userId={userId}
      />
    </>
  );
}

const styles = {
  tasksWrapper: {
    height: "100%",
    mt: -2,
  },
  tasksHeader: {
    mb: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pb: 1,
  },
};

const mapStateToProps = (state: AppState) => {
  return {
    clientDetails: state.currentClient,
    userId: state.user.id,
    locale: state.currentClient.locale,
    resendInvitationData: state.clients.resendInvitationData,
    loading: state.currentClient.loading === RequestState.PENDING,
  };
};

export default connect(mapStateToProps, {
  ignoreClientTaskAction,
  handleResendInvitationModal,
  approveClientTaskAction,
  extendCurrentClientDuration,
})(Tasks);
