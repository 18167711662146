import { combineReducers } from "redux";
import stats from "./stats/reducer";
import macros from "./macros/reducer";
import chart from "./chart/reducer";
import progress from "./progress/reducer";

export const bottom = combineReducers({
  stats,
  macros,
  chart,
  progress,
});
