import {
  CHANGE_USER_LANGUAGE,
  GET_USER,
  IUser,
  PlatformOriginEnum,
  UPDATE_MESSAGE_COUNT,
  UPDATE_USER_TAGS,
  userActionTypes,
  UserState,
} from "./reducerTypes";
import { Colors } from "../../styleguide";
import { CurrencyEnum } from "../../interfaces/currency";
import { Locale } from "../../interfaces/locale";
import { LocaleEnum, TransferProgressEnum } from "../../generated/graphql";

export enum AppStatus {
  IDLE = "idle",
  LOADING = "loading",
  READY = "ready",
}

const USER_INITIAL_STATE: UserState = {
  id: 0,
  name: "",
  email: "",
  company: "",
  logo: "",
  activated: false,
  primaryColor: Colors.CTA_COLORS_BG,
  currency: CurrencyEnum.EUR,
  interactiveToken: "",
  tags: [],
  totalActiveClients: 0,
  unreadMessagesCount: 0,
  newLeadsCount: 0,
  configured: false,
  loading: true,
  appStatus: AppStatus.IDLE,
  locale: LocaleEnum.DaDk,
  isAssistant: false,
  version: 0, // VersionEnum.Stable,
  hasValidVat: false,
  userApp: undefined,
  subscription: {
    id: 0,
    title: "",
    clients: 0,
    price: 0,
    currency: CurrencyEnum.EUR,
    isTrial: false,
  },
  settings: {
    defaultCheckInDay: null,
    defaultCurrency: null,
    defaultUpfront: null,
    defaultRecurring: null,
  },
  loadedAt: null,
  payment: {
    //timestamp of next payment attempt
    nextPaymentAttempt: null,
    lastPaymentFailed: false,
  },
  stripeConnected: false,
  stripeConnectUrl: null,
  userApplicationFee: null,
  useLenusRecipes: false,
  lenusTransferDate: null,
  transferInProgress: TransferProgressEnum.NotStarted,
  platformOrigin: PlatformOriginEnum.Zenfit,
};

export default function user(state = USER_INITIAL_STATE, action: userActionTypes) {
  const { type, payload } = action;
  switch (type) {
    case GET_USER.SUCCESS:
      const { platformOrigin, transferInProgress, ...rest } = payload as IUser;
      return {
        ...state,
        loading: false,
        appStatus: AppStatus.READY,
        loadedAt: new Date(),
        platformOrigin: platformOrigin || PlatformOriginEnum.Zenfit,
        transferInProgress: getMappedTransferProgress(
          transferInProgress as unknown as 0 | 1 | 2,
        ),
        ...rest,
      };
    case CHANGE_USER_LANGUAGE:
      return { ...state, locale: payload };
    case UPDATE_MESSAGE_COUNT:
      return { ...state, unreadMessagesCount: payload.unreadMessagesCount };
    case UPDATE_USER_TAGS:
      const currentTags = state.tags;
      const filteredAndLowerCase = (payload?.tags || [])
        ?.map((tag: string) => tag.toLowerCase())
        .filter((tag: string) => !currentTags.includes(tag));

      const hasNewTag = filteredAndLowerCase.some(
        (tag: string) => !currentTags.includes(tag),
      );

      return {
        ...state,
        tags: hasNewTag ? currentTags.concat(filteredAndLowerCase) : currentTags,
      };
    default:
      return state;
  }
}

const getMappedTransferProgress = (transferProgress: 0 | 1 | 2): TransferProgressEnum => {
  switch (transferProgress) {
    case 1:
      return TransferProgressEnum.InProgress;
    case 2:
      return TransferProgressEnum.Completed;
    default:
      return TransferProgressEnum.NotStarted;
  }
};
