import { IClientVideo } from "../../../useVideosQuery/interfaces";
import { Box, Stack, tooltipClasses, Typography } from "@mui/material";
import { HtmlTooltip } from "../../../../../components/HtmlTooltip";

interface Props {
  row: IClientVideo;
}

export default function ClientVideosComment(props: Props) {
  const { row } = props;

  const Content = () => {
    return (
      <Stack p={1.5}>
        <Typography fontWeight={"bold"}>Description</Typography>
        <Typography>{row.comment}</Typography>
      </Stack>
    );
  };
  return (
    <HtmlTooltip
      disableInteractive
      placement={"top"}
      sx={Styles.tooltip}
      arrow
      title={row.comment ? <Content /> : ""}>
      <Box component="div" sx={Styles.box}>
        {row.comment}
      </Box>
    </HtmlTooltip>
  );
}

const Styles = {
  box: {
    textOverflow: "ellipsis",
    alignItems: "center",
    cursor: "default",
    overflow: "hidden",
  },
  tooltip: {
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 240,
      minWidth: 240,
    },
  },
};
