import { IClientVideo } from "../../../useVideosQuery/interfaces";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import useClientVideosMutations from "../../../useVideosQuery/useClientVideosMutations";

interface Props {
  row: IClientVideo;
}

export default function ClientVideosVisibility(props: Props) {
  const { t } = useTranslation(TranslatorNS.LISTS);
  const { clientId, id, visibility } = props.row;

  const { showClientVideoMutation, hideClientVideoMutation } = useClientVideosMutations();
  const isLoading =
    showClientVideoMutation.isLoading || hideClientVideoMutation.isLoading;

  const handleVisibility = () => {
    const params = {
      clientId,
      videoId: id,
    };

    visibility
      ? hideClientVideoMutation.mutate(params)
      : showClientVideoMutation.mutate(params);
  };

  return (
    <FormGroup>
      <FormControlLabel
        onChange={handleVisibility}
        checked={visibility}
        disabled={isLoading}
        control={<Switch inputProps={{ "aria-label": "controlled" }} />}
        label={t(visibility ? "documents.main.visible" : "documents.main.hidden")}
      />
    </FormGroup>
  );
}
