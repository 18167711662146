import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Header from "./Header";
import { LocaleEnum, RecipeType, useGetRecipeQuery } from "../../../generated/graphql";
import SeverErrorIllustration from "../../../assets/error";
import Stack from "@mui/material/Stack";
import EditorLoader from "../../WorkoutEditor/PlanEditor/Loader";
import DraggableList from "./DraggableList";
import ListHeader from "./ListHeader";
import ListColumns from "./ListColumns";

function EditorContainer() {
  const { templateId } = useParams<{ templateId: string }>();

  const { data, loading, error } = useGetRecipeQuery({
    variables: { id: Number(templateId) },
  });

  if (loading) return <EditorLoader />;
  if (error || !data?.recipe || !data.recipe.locale) {
    return (
      <Stack
        sx={{
          height: "100%",
          p: 16,
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}>
        <SeverErrorIllustration />
        <Box sx={{ pt: 2 }} />
        <Typography variant="subtitle2">{error?.message}</Typography>
      </Stack>
    );
  }
  return <PlanEditor recipe={data.recipe as RecipeType} locale={data.recipe.locale} />;
}

function PlanEditor({ recipe, locale }: { recipe: RecipeType; locale: LocaleEnum }) {
  const theme = useTheme();

  /**
   * Calculate stats for the recipe
   * @type {Object}
   * @property {number} grams - total grams of the recipe
   * @property {number} kcal - total kcal of the recipe
   * @property {number} fat - total fat of the recipe
   * @property {number} carbs - total carbs of the recipe
   * @property {number} protein - total protein of the recipe
   * @property {number} ingredientCount - total number of ingredients
   * @property {number} spiceCount - total number of spices
   */
  const stats = React.useMemo(
    () => ({
      grams: recipe.ingredients.reduce((acc, i) => acc + Number(i.amount), 0),
      kcal: recipe.ingredients.reduce(
        (acc, i) => (acc += Number(i.amount) * (Number(i.ingredient?.kcal) / 100)),
        0,
      ),
      fat: recipe.ingredients.reduce(
        (acc, i) => (acc += Number(i.amount) * (Number(i.ingredient?.fat) / 100)),
        0,
      ),
      carbs: recipe.ingredients.reduce(
        (acc, i) =>
          (acc += Number(i.amount) * (Number(i.ingredient?.carbohydrates) / 100)),
        0,
      ),
      protein: recipe.ingredients.reduce(
        (acc, i) => (acc += Number(i.amount) * (Number(i.ingredient?.protein) / 100)),
        0,
      ),
      ingredientCount: recipe.ingredients.filter(i => Number(i.ingredient?.kcal) > 0)
        .length,
      spiceCount: recipe.ingredients.filter(i => Number(i.ingredient?.kcal) <= 0).length,
    }),
    [recipe.ingredients],
  );

  // Sort ingredients by order
  const sortedIngredients = [...recipe.ingredients].sort(
    (a, b) => Number(a?.order) - Number(b?.order),
  );

  const hasIngredients = sortedIngredients?.length > 0;

  return (
    <Box
      sx={{
        height: "100%",
        borderLeft: "1px solid",
        borderColor: theme.palette.grey[300],
        backgroundColor: theme.palette.common.white,
      }}>
      <Header
        id={recipe.id}
        name={recipe.name}
        image={recipe.image}
        macroSplit={(recipe.lenusMacroSplit || recipe.zenfitMacroSplit || "")
          ?.split("_")
          .join("/")}
        locale={locale}
        meta={recipe.meta}
      />
      {hasIngredients && (
        <ListHeader
          carbs={stats.carbs}
          fat={stats.fat}
          protein={stats.protein}
          totalKcal={stats.kcal}
          ingredientCount={stats.ingredientCount}
          spiceCount={stats.spiceCount}
        />
      )}
      <Box
        sx={{
          p: 2,
          overflowY: "scroll",
          backgroundColor: "#FCFCFC",
        }}>
        {hasIngredients && (
          <ListColumns totalGrams={stats.grams} totalKcal={stats.kcal} />
        )}

        <DraggableList
          ingredients={sortedIngredients}
          instructions={recipe.instructions}
          locale={locale}
        />
      </Box>
    </Box>
  );
}

export default EditorContainer;
