import axios from "../../helpers/axios";
import { WorkoutPlan } from "../../interfaces/meal";
import { WorkoutplanStatus } from "./enum";
import { DuplicateWorkoutplan } from "./store/actions/duplicate";
import {
  CREATE_PLAN,
  GET_WORKOUT_PLANS,
  GET_WORKOUT_TEMPLATES,
  UPDATE_WORKOUT_PLAN,
} from "../../api/private/workout-api";

interface WorkoutTemplatesQueryParams {
  q?: string;
  workoutsPerWeek?: number[];
  levels?: number[];
  locations?: number[];
  gender?: number;
  limit?: number;
  offset?: number;
}

export async function getWorkoutTemplates(queryParams?: WorkoutTemplatesQueryParams) {
  const { data }: { data: WorkoutPlan[] } = await axios.get(GET_WORKOUT_TEMPLATES(), {
    params: queryParams || {},
  });
  return data;
}

interface WorkoutPlanBase {
  clientId: number;
  explanation: string;
  name: string;
}
export interface CreateWorkPlanBase extends WorkoutPlanBase {
  templates: number[];
}

/**
   templates = fyld med template ids (hvis plan skal laves vha. templates)
 */

export async function createWorkoutPlan(parameters: CreateWorkPlanBase) {
  const result = await axios.post(CREATE_PLAN(), parameters);
  return result?.data;
}

export async function getWorkoutplans(id: number) {
  const { data } = await axios.get(GET_WORKOUT_PLANS(id));
  return data;
}

export interface UpdateWorkoutPlan extends WorkoutPlanBase {
  status: WorkoutplanStatus;
}

export async function updateWorkoutPlan(
  id: number,
  parameters: Partial<UpdateWorkoutPlan>,
) {
  const result = await axios.put(UPDATE_WORKOUT_PLAN(id), parameters);
  return { id: result?.data?.plan };
}

export async function deleteWorkoutPlan(id: number) {
  // TODO: RENAME UPDATE_WORKOUT_PLAN TO SOMETHING LIKE WORKOUTPLAN
  const result = await axios.delete(UPDATE_WORKOUT_PLAN(id));
  return { id: result?.data?.plan };
}

export async function duplicateWorkoutPlan({
  id,
  name,
  explanation,
  clientId,
}: DuplicateWorkoutplan) {
  const result = await axios.post(CREATE_PLAN(), {
    planId: id,
    clientId,
    name,
    explanation,
  });
  return result?.data;
}
