import CardContainer from "../../../containers/CardContainer/CardContainer";
import {
  SavedWorkoutType,
  useGetClientSavedWorkoutsQuery,
} from "../../../generated/graphql";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { endOfISOWeek, format, startOfISOWeek } from "date-fns";
import SavedWorkoutTable from "./Components/SavedWorkoutTable";
import WeekPicker from "./Components/WeekPicker";
import PendingWorkoutPlan from "./Components/PendingWorkoutPlan";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { useLocation } from "react-router";

export default function Workouts() {
  const { clientId } = useParams();
  const { t } = useTranslation(TranslatorNS.WORKOUT_PLAN);
  const location = useLocation();
  const isProgress = location.pathname.includes("progress");
  const [disabledNavigation, setDisabledNavigation] = useState(false);
  const [filters, setFilters] = useState({
    to: endOfISOWeek(new Date()),
    from: startOfISOWeek(new Date()),
  });

  const { data, loading, error } = useGetClientSavedWorkoutsQuery({
    variables: {
      id: Number(clientId),
      from: format(filters.from, "yyyy-MM-dd"),
      to: format(filters.to, "yyyy-MM-dd"),
    },
  });

  useEffect(() => {
    if (data?.client.workoutPlans?.length === 0) {
      setDisabledNavigation(true);
    }
  }, [data?.client?.workoutPlans]);

  const flattenedSavedWorkouts: TFlattenedWorkout[] = (data?.savedWorkouts || [])
    .map(savedWorkout => ({
      id: savedWorkout.id,
      date: savedWorkout.date,
      dayTitle: savedWorkout?.day.name || "-",
      dayId: savedWorkout?.day?.id,
      comment: savedWorkout?.comment || undefined,
      time: savedWorkout?.time || undefined,
      planTitle: savedWorkout?.day?.workoutPlan?.name || "-",
    }))
    .sort(
      (a, b) => new Date(String(a.date)).getTime() - new Date(String(b.date)).getTime(),
    );

  const renderContent = () => {
    if (disabledNavigation) {
      return <PendingWorkoutPlan name={data?.client?.name} />;
    }

    return (
      <SavedWorkoutTable
        loading={loading}
        savedWorkouts={flattenedSavedWorkouts}
        workoutsPerWeek={data?.client?.workoutsPerWeek}
      />
    );
  };

  const height = isProgress ? 560 : 460;

  return (
    <CardContainer
      title={t("createModal.workouts")}
      error={error}
      icon={
        <WeekPicker
          loading={loading}
          endDate={data?.client?.endDate}
          startDate={data?.client?.startDate}
          disabledNavigation={disabledNavigation}
          filters={filters}
          setFilters={setFilters}
        />
      }
      loading={false}
      sx={{ height }}>
      {renderContent()}
    </CardContainer>
  );
}

export type TFlattenedWorkout = {
  id: SavedWorkoutType["id"];
  date: SavedWorkoutType["date"];
  dayTitle: string | undefined;
  dayId: number;
  comment: string | undefined;
  time: string | undefined;
  planTitle: string;
};
