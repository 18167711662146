import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import Alert from "@mui/material/Alert";
import { AlertTitle, Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React from "react";
import ActivateClientWrapper from "../../../../../components/ActivateClientWrapper";
import { EventNameEnum } from "../../../../../generated/graphql";

interface Props {
  loading: boolean;
  activateClient: () => void;
  cancel: () => void;
  pendingTasks: EventNameEnum[];
}

export default function WarnBeforeActivate(props: Props) {
  const { activateClient, cancel, loading, pendingTasks } = props;

  const { t } = useTranslation(TranslatorNS.CHAT_WIDGET);

  return (
    <Alert severity={"warning"} icon={false}>
      <AlertTitle>{t("pendingWarning", { count: pendingTasks?.length })}</AlertTitle>
      {t("pendingWarningSubtitle")}
      <br />
      <Stack mt={2}>
        {t("pendingTasks")}:
        {pendingTasks?.map((task, index) => (
          <Typography color={"warning.darker"} key={index}>
            - {t(`missingTasks.${task}`)}
          </Typography>
        ))}
      </Stack>
      <Stack flexDirection={"row"} mt={2}>
        <ActivateClientWrapper>
          {({ disabled }) => (
            <LoadingButton
              loading={loading}
              color={"warning"}
              disabled={disabled}
              onClick={activateClient}
              variant={"contained"}>
              {t("ignoreTask")}
            </LoadingButton>
          )}
        </ActivateClientWrapper>
        <Button onClick={cancel} color={"warning"} variant={"text"} sx={{ ml: 2 }}>
          {t("messages.notYet")}
        </Button>
      </Stack>
    </Alert>
  );
}
