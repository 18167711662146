import moment from "moment";
import { RecorderStatusEnum } from "../type";
import { MAX_RECORDING_SEC } from "../config";

export const GetFormattedMM2SEC = (sec: number) =>
  `${moment().startOf("day").seconds(sec).format("mm:ss")}`;

export const RecorderTimer = (
  status: RecorderStatusEnum,
  setZFRecorder: (prevState: any) => void,
  handleStopCaptureClick: { (): void; (): void },
) => {
  let intervalId: number | NodeJS.Timer | undefined;
  if (status === RecorderStatusEnum.RECORDING) {
    intervalId = setInterval(() => {
      setZFRecorder((prevState: { timeCounter: number }) => {
        if (prevState.timeCounter === MAX_RECORDING_SEC) {
          handleStopCaptureClick();
          clearInterval(intervalId as NodeJS.Timeout);
        }
        return {
          ...prevState,
          timeCounter: prevState.timeCounter + 1,
        };
      });
    }, 1000);
  } else {
    if (intervalId) {
      clearInterval(intervalId as NodeJS.Timeout);
    }
  }

  return () => clearInterval(intervalId as NodeJS.Timeout);
};
