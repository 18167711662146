import { styled, Button } from "@mui/material";

export default styled(Button)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.grey[900],

  "&:hover": {
    backgroundColor: theme.palette.grey[200],
    fieldset: {
      borderColor: "transparent",
    },
  },
}));
