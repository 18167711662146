import { Fragment, ReactNode } from "react";
import { WorkoutType } from "../../../../generated/graphql";
import { Stack, Typography } from "@mui/material";
import Avatar from "../../../_MUI/Avatar";
import Iconify from "../../../_MUI/Iconify";
import Label from "../../../_MUI/Label";
import TrackedSets from "./TrackedSets";
import { TrackedWorkoutsModalContext } from "../../../../store/modals/reducerTypes";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  tracked: WorkoutType | undefined;
  readonly savedWorkoutDate: TrackedWorkoutsModalContext["savedWorkoutDate"];
  exerciseChildren?: ReactNode;
}

export default function Exercise(props: Props) {
  const { tracked, savedWorkoutDate, exerciseChildren } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);
  const title = tracked?.exercise?.title;
  const image = tracked?.exercise?.image || undefined;
  const isSuperSetChild = !!exerciseChildren;

  if (!tracked) {
    return null;
  }

  return (
    <Fragment>
      <Stack borderTop={isSuperSetChild ? 0 : 1} borderColor={"grey.300"}>
        <Stack p={2} flexDirection={"row"} bgcolor={"grey.200"}>
          <Avatar sx={{ borderRadius: 1, height: 64, width: 64, mr: 2 }} src={image}>
            <Iconify icon="uil:dumbbell" color={"inherit.main"} width={50} height={50} />
          </Avatar>
          <Stack>
            <Typography variant={"subtitle2"}>{title}</Typography>
            <Typography fontSize={12} color={"text.secondary"} variant={"caption"}>
              {t("trackedWorkout.plannedVolume")}
            </Typography>
            <Stack flexDirection={"row"} flexWrap={"wrap"} mt={0.5} alignItems={"center"}>
              <Label sx={{ my: { xs: 0.5, sm: 0 } }}>
                {t("trackedWorkout.sets", { sets: tracked?.sets })}
              </Label>
              <Iconify sx={{ mx: 0.5 }} icon={"ion:close"} />
              {!tracked?.time && (
                <Label>{t("trackedWorkout.reps", { reps: tracked?.reps })}</Label>
              )}
              {tracked?.time && (
                <Label>{t("trackedWorkout.time", { time: tracked?.time })}</Label>
              )}
              <Stack sx={{ mx: 0.5 }} flexDirection={"row"}>
                <Typography>{t("trackedWorkout.restTitle")}</Typography>:
              </Stack>
              <Label>{t("trackedWorkout.restTime", { rest: tracked?.rest })}</Label>
            </Stack>
          </Stack>
        </Stack>
        <TrackedSets
          trackedWorkouts={tracked.tracking}
          savedWorkoutDate={savedWorkoutDate}
        />
      </Stack>
      {exerciseChildren}
    </Fragment>
  );
}
