import {
  CHANGE_LANGUAGE,
  CHANGE_STEP,
  IClientActivationState,
  SAVE_STEP,
  clientActivationActionTypes,
  GET_CLIENT_ACTIVATION_REQUEST,
  GET_CLIENT_ACTIVATION_SUCCESS,
  GET_CLIENT_ACTIVATION_FAILURE,
  POST_CLIENT_REQUEST,
  POST_CLIENT_SUCCESS,
  POST_CLIENT_FAILURE,
} from "./types";
import produce from "immer";
import i18n from "i18next";
import { ActivationConfigEnum, ActivationStepsEnum } from "../type";
import {
  INITIAL_ACCOUNT,
  INITIAL_DIET,
  INITIAL_GENERAL,
  INITIAL_GOAL,
  INITIAL_OTHER,
  INITIAL_PHOTOS,
  INITIAL_WORKOUT,
} from "./initialStates";
import { LocaleEnum } from "../../../generated/graphql";

const CLIENT_ACTIVATION_INITIAL_STATE: IClientActivationState = {
  step: 1,
  clientId: -1,
  answeredQuestionnaire: false,
  showEatingDisorderQuestions: true,
  clientSubmitting: false,
  clientSubmitError: "",
  account: INITIAL_ACCOUNT,
  general: INITIAL_GENERAL,
  photos: INITIAL_PHOTOS,
  workout: INITIAL_WORKOUT,
  goal: INITIAL_GOAL,
  diet: INITIAL_DIET,
  other: INITIAL_OTHER,
  initialLoading: true,
  userToken: "",
  locale: LocaleEnum.EnUs,
  initialError: "",
  config: ActivationConfigEnum.NONE,
  customQuestions: undefined,
};

export default function Reducer(
  state = CLIENT_ACTIVATION_INITIAL_STATE,
  action: clientActivationActionTypes,
) {
  const { type, payload } = action;

  switch (type) {
    case GET_CLIENT_ACTIVATION_REQUEST: {
      return produce(state, draftState => {
        draftState.initialLoading = true;
      });
    }
    case GET_CLIENT_ACTIVATION_SUCCESS: {
      return produce(state, draftState => {
        return {
          ...draftState,
          ...payload.mappedToState,
          initialLoading: false,
          config: payload.config,
          userToken: payload.userToken,
          datakey: payload.datakey,
          showEatingDisorderQuestions: payload.showEatingDisorderQuestions,
          customQuestions: payload.customQuestions,
        };
      });
    }
    case GET_CLIENT_ACTIVATION_FAILURE: {
      return produce(state, draftState => {
        draftState.initialError = payload;
        draftState.initialLoading = false;
      });
    }

    case CHANGE_LANGUAGE: {
      return produce(state, draftState => {
        try {
          i18n.changeLanguage(payload);
          draftState.locale = payload;
        } catch (e) {
          console.log("lang issue", e);
        }
      });
    }
    case CHANGE_STEP: {
      return produce(state, draftState => {
        draftState.step = payload;
        draftState.clientSubmitError = "";
      });
    }
    case SAVE_STEP: {
      return produce(state, draftState => {
        const { stepContent, stepName } = payload;
        draftState[stepName as ActivationStepsEnum] = stepContent;
      });
    }

    case POST_CLIENT_REQUEST: {
      return produce(state, draftState => {
        draftState.clientSubmitting = true;
      });
    }

    case POST_CLIENT_SUCCESS: {
      return produce(state, draftState => {
        draftState.clientSubmitting = false;
        payload !== "on_leave_save" &&
          (draftState.step = Math.round(draftState.step) + 1);
      });
    }
    case POST_CLIENT_FAILURE: {
      return produce(state, draftState => {
        draftState.clientSubmitting = false;
        draftState.clientSubmitError = payload;
      });
    }

    default:
      return state;
  }
}
