import { workoutTemplateSchema, updateWorkoutTemplateSchema } from "./schema";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { createWorkoutTemplate, deleteWorkoutTemplate, editWorkoutTemplate } from "./api";
import { useWorkoutTemplateSearchParams } from "./useWorkoutTemplateSearchParams";

interface WorkoutTemplatesShape {
  pages: WorkoutTemplatesPageShape[];
  pageParam?: number;
}

export interface WorkoutTemplatesPageShape {
  data: ReturnType<typeof workoutTemplateSchema>[];
  nextCursor: boolean;
}

export default function useWorkoutTemplatesMutations() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.LISTS);
  const { queryKey, q, levels, workoutsPerWeek, location, gender, setSearchParams } =
    useWorkoutTemplateSearchParams();

  const createWorkoutTemplateMutation = useMutation(createWorkoutTemplate, {
    onMutate: () => {},
    onSuccess: result => {
      queryClient.setQueryData<WorkoutTemplatesShape>(
        [queryKey, q, levels, workoutsPerWeek, location, gender],
        previousState => {
          if (!previousState) return;
          const [firstPage, ...remainingPages] = previousState.pages;
          setSearchParams({});
          return {
            ...previousState,
            pages: [
              { data: [result, ...firstPage.data], nextCursor: firstPage.nextCursor },
              ...remainingPages,
            ],
          };
        },
      );
      enqueueSnackbar(t("workouts.notify.added.success"), { variant: "success" });
    },
    onError: (error, variables, context) => {
      console.error({ error, variables, context });
      enqueueSnackbar(t("workouts.notify.added.error"), {
        variant: "error",
      });
    },
  });

  const updateWorkoutTemplateMutation = useMutation(editWorkoutTemplate, {
    onMutate: () => {},
    onSuccess: (data, variables) => {
      queryClient.setQueryData<WorkoutTemplatesShape>(
        [queryKey, q, levels, workoutsPerWeek, location, gender],
        previousState => {
          if (!previousState) return;
          return {
            ...previousState,
            pages: previousState.pages.map(page => {
              return {
                data: page.data.map(workoutTemplate =>
                  workoutTemplate.id === variables.id
                    ? { ...workoutTemplate, ...updateWorkoutTemplateSchema(variables) }
                    : workoutTemplate,
                ),
                nextCursor: page.nextCursor,
              };
            }),
          };
        },
      );
      enqueueSnackbar(t("workouts.notify.updated.success"), { variant: "success" });
    },
    onError: (error, variables, context) => {
      console.error({ error, variables, context });
      enqueueSnackbar(t("workouts.notify.updated.error"), {
        variant: "error",
      });
    },
  });

  const deleteExerciseMutation = useMutation(deleteWorkoutTemplate, {
    onMutate: () => {},
    onSuccess: (data, variables) => {
      queryClient.setQueryData<WorkoutTemplatesShape>(
        [queryKey, q, levels, workoutsPerWeek, location, gender],
        previousState => {
          if (!previousState) return;
          return {
            ...previousState,
            pages: previousState.pages.map(({ data, nextCursor }) => ({
              data: data.filter(
                (workoutTemplate: any) => workoutTemplate.id !== variables,
              ),
              nextCursor,
            })),
          };
        },
      );
      enqueueSnackbar(t("workouts.notify.deleted.success"), { variant: "success" });
    },
    onError: () => {
      enqueueSnackbar(t("workouts.notify.deleted.error"), {
        variant: "error",
      });
    },
  });

  return {
    createWorkoutTemplateMutation,
    updateWorkoutTemplateMutation,
    deleteExerciseMutation,
  };
}
