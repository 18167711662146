import React, { useCallback } from "react";
import { MEDIA_RECORDER_OPTIONS } from "../config";
import { RecorderStatusEnum, ZFRecorderType } from "../type";

export const CheckPermissions = (
  cameraIsready: () => void,
  noPermissions: (data: string) => void,
) => {
  if (window.location.protocol !== "https:" && window.location.hostname !== "localhost") {
    noPermissions(
      "getUserMedia() must be run from a secure origin: https or localhost.\nPlease change the protocol to https.",
    );
  }
  navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then(function (stream) {
      let result = stream.getVideoTracks().some(function (track) {
        return track.enabled && track.readyState === "live";
      });
      if (result) {
        cameraIsready();
      } else {
        noPermissions("");
      }
    })
    .catch(function (err) {
      console.log(err.name + ": " + err.message);
      noPermissions(err.message);
    });
};

const GetMediaRecorderOptions = () => {
  const MediaRecorder = window.MediaRecorder;
  const MediaRecorderOptions = MEDIA_RECORDER_OPTIONS;

  const possibleTypes = [
    "video/webm;codecs=vp9,opus",
    "video/webm;codecs=vp8,opus",
    "video/webm;codecs=h264,opus",
    "video/webm",
  ];

  MediaRecorderOptions.mimeType = possibleTypes.filter(mimeType =>
    MediaRecorder.isTypeSupported(mimeType),
  )[0];

  return MediaRecorderOptions;
};

export const GetHandleStartCaptureClick = (
  HandleRecorderState: (status: string, RECORDING: RecorderStatusEnum.RECORDING) => void,
  mediaRecorderRef: React.MutableRefObject<null>,
  webcamRef: React.MutableRefObject<null>,
  handleDataAvailable: ({ data }: { data: any }) => void,
  handleError: (error: string) => void,
) => {
  return useCallback(() => {
    try {
      HandleRecorderState("status", RecorderStatusEnum.RECORDING);
      //@ts-ignore
      mediaRecorderRef.current = new MediaRecorder(
        // @ts-ignore
        webcamRef?.current?.stream,
        GetMediaRecorderOptions(),
      );

      //@ts-ignore
      mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
      //@ts-ignore
      mediaRecorderRef.current.start();
    } catch (error) {
      console.error(error, "recorder issue!");
      handleError(String(error));
    }
  }, [webcamRef, HandleRecorderState, mediaRecorderRef]);
};

export const GetHandleStopCaptureClick = (
  mediaRecorderRef: React.MutableRefObject<null>,
  HandleRecorderState: {
    (name: string, val: any): void;
    (arg0: string, arg1: RecorderStatusEnum): void;
  },
  webcamRef: React.MutableRefObject<null>,
  handleError: (error: string) => void,
) => {
  return useCallback(() => {
    try {
      //@ts-ignore
      mediaRecorderRef.current.stop();
      HandleRecorderState("status", RecorderStatusEnum.READY_TO_SEND);
    } catch (error) {
      console.error(error, "recorder issue!");
      handleError(String(error));
    }
  }, [mediaRecorderRef, HandleRecorderState, webcamRef]);
};

export function GetPreviewUrlAndBlob(
  ZFRecorder: ZFRecorderType,
  HandleMultipleRecorderState: (obj: object) => void,
  handleError: (error: string) => void,
) {
  try {
    if (ZFRecorder.status === RecorderStatusEnum.READY_TO_SEND) {
      // @ts-ignore
      const blob = new Blob(ZFRecorder.recordedChunks, {
        type: GetMediaRecorderOptions().mimeType,
      });
      const url = URL.createObjectURL(blob);
      HandleMultipleRecorderState({
        previewUrl: url,
        blob: blob,
      });
    }
  } catch (error) {
    console.error(error, "recorder issue!");
    handleError(String(error));
  }
}

export function GetHandleDataAvailable(
  setZFRecorder: (
    value: ((prevState: ZFRecorderType) => ZFRecorderType) | ZFRecorderType,
  ) => void,
  handleError: (error: string) => void,
) {
  return useCallback(
    ({ data }: any) => {
      try {
        if (data.size > 0) {
          setZFRecorder(prevState => ({
            ...prevState,
            recordedChunks: prevState.recordedChunks.concat(data),
          }));
        }
      } catch (error) {
        console.error(error, "recorder issue!");
        handleError(String(error));
      }
    },
    [setZFRecorder],
  );
}
