/**
 * For more examples of how to use this component check out this link https://github.com/danbovey/react-infinite-scroller
 */

import { createElement, ReactNode, useCallback, useEffect, useRef } from "react";
import ZFLoader from "../Loader";

interface Props {
  /**
   * This is the component where we need to add infinite scroll
   */
  children: ReactNode;
  /**
   * Name of the element that the component should render as.
   * @default 'div'
   */
  element?: string;
  /**
   * Whether there are more items to be loaded.
   * Event listeners are removed if false.
   * @default false
   */
  hasMore?: boolean;
  /**
   * Whether the component should load the first set of items.
   * @default true
   */
  initialLoad?: boolean;
  /**
   * A React component to render while more items are loading.
   */
  loader?: JSX.Element;
  /**
   * A callback when more items are requested by the user.
   * Receives a single parameter specifying the page to load.
   */
  loadMore: () => void;
  /**
   * Override method to return a different scroll listener if it's not the immediate parent of InfiniteScroll.
   */
  getScrollParent?: () => HTMLElement | undefined;
  /**
   * The distance in pixels before the end of the items that will trigger a call to loadMore.
   * @default 50
   */
  threshold?: number;
  /**
   * Proxy to the useCapture option of the added event listeners.
   * @default false
   */
  useCapture?: boolean;
  /**
   * Add scroll listeners to the window, or else, the component's parentNode.
   * @default true
   */
  useWindow?: boolean;
  /** Scroll ref */
  ref?: (node: Element) => void;
  /**
   * Whether the Infinite Scroll component default loader should show or not
   */
  shouldShowDefaultLoader?: boolean;
}

const DefaultLoader = () => <ZFLoader size={30} thickness={2} centerAlign={false} />;

const isPassiveSupported = () => {
  let supportsPassive = false;

  // Test via a getter in the options object to see if the passive property is accessed
  const options = Object.defineProperty({}, "passive", {
    get: function () {
      supportsPassive = true;
      return "";
    },
  });

  try {
    document.addEventListener("testPassive", () => {}, options);
    document.removeEventListener("testPassive", () => {}, options);
  } catch (e) {
    // ignore
  }

  return supportsPassive;
};

const eventListenerOptions = (useCapture: boolean): AddEventListenerOptions => {
  if (isPassiveSupported()) {
    return {
      capture: useCapture,
      passive: true,
    };
  }

  return {
    passive: false,
  };
};

const getParentElement = (
  el: Element | undefined,
  getScrollParent: (() => HTMLElement | undefined) | undefined,
): any => {
  if (getScrollParent) {
    return getScrollParent();
  }
  return el && el.parentNode;
};

const calculateOffset = (el: HTMLElement | undefined, scrollTop: number) => {
  if (!el) {
    return 0;
  }

  return calculateTopPosition(el) + (el.offsetHeight - scrollTop - window.innerHeight);
};

const calculateTopPosition = (el: HTMLElement): number => {
  if (!el) {
    return 0;
  }
  return el.offsetTop + calculateTopPosition(el.offsetParent as HTMLElement);
};

const mousewheelListener = (e: WheelEvent) => {
  // Prevents Chrome hangups
  // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
  if (e.deltaY === 1 && !isPassiveSupported()) {
    e.preventDefault();
  }
};

function InfiniteScroll(props: Props) {
  const {
    children,
    element = "div",
    hasMore = false,
    initialLoad = true,
    threshold = 50,
    useWindow = true,
    useCapture = false,
    loader = null,
    shouldShowDefaultLoader = false,
    getScrollParent = undefined,
    ref,
    loadMore,
  } = props;

  const scrollComponentRef = useRef<HTMLElement>();
  const beforeScrollHeightRef = useRef(0);
  const beforeScrollTopRef = useRef(0);
  const loadMoreRef = useRef(false);

  const detachScrollListener = () => {
    let scrollEl = window;
    if (useWindow === false) {
      scrollEl = getParentElement(scrollComponentRef.current, getScrollParent);
    }

    const eventOptions = eventListenerOptions(useCapture)
      ? eventListenerOptions(useCapture)
      : useCapture;

    scrollEl.removeEventListener("scroll", scrollListener, eventOptions);
    scrollEl.removeEventListener("resize", scrollListener, eventOptions);
  };

  const scrollListener = useCallback(() => {
    const el = scrollComponentRef.current;
    const scrollEl = window;
    const parentNode = getParentElement(el, getScrollParent);

    let offset;
    if (useWindow) {
      const doc = document.documentElement || document.body.parentNode || document.body;
      const scrollTop =
        scrollEl.pageYOffset !== undefined ? scrollEl.pageYOffset : doc.scrollTop;

      offset = calculateOffset(el, scrollTop);
    } else {
      offset = (el?.scrollHeight || 0) - parentNode.scrollTop - parentNode.clientHeight;
    }

    // Here we make sure the element is visible as well as checking the offset
    if (offset < Number(threshold) && el && el.offsetParent !== null) {
      // Stop scrolling listener
      detachScrollListener();

      beforeScrollHeightRef.current = parentNode.scrollHeight;
      beforeScrollTopRef.current = parentNode.scrollTop;
      if (typeof loadMore === "function") {
        loadMore();
        loadMoreRef.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore]);

  useEffect(() => {
    if (!hasMore) return;

    const parentElement = getParentElement(scrollComponentRef.current, getScrollParent);

    if (!parentElement) return;

    let scrollEl = window;
    if (useWindow === false) {
      scrollEl = parentElement;
    }

    const eventOptions = eventListenerOptions(useCapture)
      ? eventListenerOptions(useCapture)
      : useCapture;

    scrollEl.addEventListener("wheel", mousewheelListener, eventOptions);
    scrollEl.addEventListener("scroll", scrollListener, eventOptions);
    scrollEl.addEventListener("resize", scrollListener, eventOptions);

    if (initialLoad) {
      scrollListener();
    }

    return () => {
      scrollEl.removeEventListener("wheel", mousewheelListener, eventOptions);
      scrollEl.removeEventListener("scroll", scrollListener, eventOptions);
      scrollEl.removeEventListener("resize", scrollListener, eventOptions);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore, scrollComponentRef.current, getScrollParent, scrollListener]);

  const elementProps: any = {};
  elementProps.ref = (node: HTMLElement) => {
    scrollComponentRef.current = node;
    if (ref) {
      ref(node);
    }
  };

  const childrenArray = [children];
  if (hasMore && shouldShowDefaultLoader) {
    if (loader) {
      const LoaderComponent = () => loader;
      childrenArray.push(<LoaderComponent key={Date.now()} />);
    } else {
      childrenArray.push(<DefaultLoader key={Date.now()} />);
    }
  }

  return createElement(element, elementProps, childrenArray);
}

export default InfiniteScroll;
