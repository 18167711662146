import React, { useState } from "react";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import RHFDesktopDatePicker from "../../../../_MUI/hook-form/RHFDesktopDatePicker";
import { getDurationInMonthsDays } from "../../../../../routes/ClientOverview/util";
import RHFSelect from "../../../../_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import { WEEK_DAYS } from "../../../../../helpers/week";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import { checkInFrequency } from "../../../AddClientModal/help-const";
import { ClientPlansScheduleEnum } from "../../../../../generated/graphql";
import { useFormContext } from "react-hook-form";
import Button from "@mui/material/Button";
import addMonths from "date-fns/addMonths";

export default function PlanFields() {
  const { watch, setValue } = useFormContext();
  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [openEndPicker, setOpenEndPicker] = useState(false);

  const watchStartDate = watch("startDate");
  const watchEndDate = watch("endDate");
  const watchCheckinDay = watch("checkInDay");

  const durationInMonths =
    watchStartDate &&
    watchEndDate &&
    getDurationInMonthsDays(watchStartDate, watchEndDate);

  return (
    <Stack mb={3}>
      <Typography variant={"subtitle2"} mb={2}>
        {t("info.clientFields.planInfo")}
      </Typography>
      <Stack flexDirection={"row"} justifyContent={"space-between"}>
        <RHFDesktopDatePicker
          name={"startDate"}
          sx={{ width: "35%" }}
          label={t("const.clientNotesFields.startDate")}
        />
        <RHFDesktopDatePicker
          name={"endDate"}
          open={openEndPicker}
          sx={{ width: "35%" }}
          label={t("const.clientNotesFields.endDate")}
          showToolbar
          onClose={() => setOpenEndPicker(false)}
          onOpen={() => setOpenEndPicker(true)}
          ToolbarComponent={() => (
            <Stack spacing={2} marginTop={2} direction="row" justifyContent="center">
              <Button
                variant="contained"
                color="inherit"
                disabled={!watchStartDate}
                onClick={() => {
                  setOpenEndPicker(false);
                  setValue(
                    "endDate",
                    watchStartDate ? addMonths(new Date(watchStartDate), 3) : null,
                    { shouldDirty: true },
                  );
                }}>
                {t("clientPayment.monthShort", { monthNumber: 3 })}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                disabled={!watchStartDate}
                onClick={() => {
                  setOpenEndPicker(false);
                  setValue(
                    "endDate",
                    watchStartDate ? addMonths(new Date(watchStartDate), 6) : null,
                    { shouldDirty: true },
                  );
                }}>
                {t("clientPayment.monthShort", { monthNumber: 6 })}
              </Button>
              <Button
                variant="contained"
                color="inherit"
                disabled={!watchStartDate}
                onClick={() => {
                  setOpenEndPicker(false);
                  setValue("endDate", null, { shouldDirty: true });
                }}>
                {t("clientPayment.noEndDate")}
              </Button>
            </Stack>
          )}
        />
        <Stack width={"25%"} justifyContent={"center"} mb={2}>
          <Tooltip title={t("clientPayment.durationExplanation")}>
            <Typography fontSize={12} color={palette.grey[500]}>
              {durationInMonths || ""}
            </Typography>
          </Tooltip>
        </Stack>
      </Stack>
      <RHFSelect label={t("checkIn.checkInDay")} name={"checkInDay"}>
        {WEEK_DAYS.map(w => {
          return (
            <MenuItem key={w.value} value={w.value}>
              {t(w.label)}
            </MenuItem>
          );
        })}
      </RHFSelect>
      {watchCheckinDay && (
        <RHFSelect label={t("checkIn.frequency")} name={"checkInFrequency"}>
          {checkInFrequency.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {t(label)}
            </MenuItem>
          ))}
        </RHFSelect>
      )}
      <RHFSelect
        label={t("info.clientFields.updatePlans.updateMealSchedule")}
        name={"mealPlanSchedule"}>
        {Object.values(ClientPlansScheduleEnum).map(value => (
          <MenuItem key={value} value={value}>
            {t(`info.clientFields.updatePlans.${value.toLowerCase()}`)}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFSelect
        label={t("info.clientFields.updatePlans.updateWorkoutSchedule")}
        name={"workoutPlanSchedule"}>
        {Object.values(ClientPlansScheduleEnum).map(value => (
          <MenuItem key={value} value={value}>
            {t(`info.clientFields.updatePlans.${value.toLowerCase()}`)}
          </MenuItem>
        ))}
      </RHFSelect>
    </Stack>
  );
}
