/*jshint esversion: 6 */
import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const Spinner = (props) => {
    const {t} = useTranslation('messages');
    if (!props.show) {
        return null;
    }
    return (
        <div className="loader-container">
            <div className="loader">{t('spinner.loading')}</div>
        </div>
    );
};

export default Spinner;
