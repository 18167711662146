import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FieldValidators } from "../../../../helpers/validators";
import { WORKOUT_PREFERENCES_FIELDS } from "../../const";
import { useTranslation } from "react-i18next";
import { InputType } from "../../../../components/InputField/enum";
import { AppState } from "../../../../store";
import { Button, Stack, TextField } from "@mui/material";
import { WorkoutLocation } from "../../../../interfaces/workout";
import { saveFieldsAction } from "../../store/action";
import { ActivationStepsEnum, IWorkout } from "../../type";
import { IClientActivationState } from "../../store/types";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { scrollToElementId } from "../../../../helpers/utils";
import { track } from "../../../../helpers/analytics";

interface Props {
  currentStep: IClientActivationState["step"];
  saveFieldsActionDispatch: (
    workout: IWorkout,
    nextStep: IClientActivationState["step"],
    currentStepName: ActivationStepsEnum,
  ) => void;
  workoutState: any;
}

const Workout = ({ currentStep, saveFieldsActionDispatch, workoutState }: Props) => {
  const { t } = useTranslation("messages");
  const [validator, setValidator] = useState({
    injuries: "",
    experienceLevel: "",
    experience: "",
    exercisePreferences: "",
    workoutLocation: "",
    workoutsPerWeek: "",
  });
  const [workout, setWorkout] = useState<IWorkout>({
    injuries: "",
    experienceLevel: undefined,
    experience: "",
    exercisePreferences: "",
    workoutLocation: undefined,
    workoutsPerWeek: undefined,
  });

  useEffect(() => {
    Object.keys(workoutState).length > 0 &&
      setWorkout(Object.assign({ ...workout }, workoutState));
  }, [workoutState]);

  const handleChange = (name: string, value: string | number) => {
    setValidator({ ...validator, [name]: "" });
    setWorkout({ ...workout, [name]: value });
  };

  const handleCheckbox = (name: string, value: boolean) => {
    setWorkout({ ...workout, [name]: value });
    setValidator({ ...validator, [name]: "" });
  };

  const handleSave = () => {
    try {
      FieldValidators(workout, t, ["injuries"]);
      track("Workout Preferences Added", "Client Activation");
      saveFieldsActionDispatch(workout, currentStep + 1, ActivationStepsEnum.WORKOUT);
    } catch (e: any) {
      setValidator(e);
      const id = e && Object.keys(e)[0];
      id && scrollToElementId(id);
    }
  };

  return (
    <Stack width={{ xs: "100%", sm: 450 }}>
      {Object.values(WORKOUT_PREFERENCES_FIELDS).map(
        (
          field: {
            type: string;
            label: string;
            name: string;
            options?: { [key: number]: string | number };
          },
          i,
        ) => {
          return (
            <Fragment key={i}>
              {field.type === InputType.TEXT ? (
                <TextField
                  id={`zf_${field.name}`}
                  type={field.type}
                  label={t(field.label)}
                  name={field.name}
                  sx={{ mb: 2 }}
                  multiline
                  error={Boolean(validator[field.name as keyof IWorkout])}
                  rows={3}
                  onChange={e => handleChange(e.target.name, e.target.value)}
                  value={workout[field.name as keyof IWorkout]}
                  helperText={validator[field.name as keyof IWorkout]}
                />
              ) : (
                <TextField
                  id={`zf_${field.name}`}
                  name={field.name}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  value={workout[field.name as keyof IWorkout]}
                  onChange={(val: any) => handleCheckbox(field.name, val.target.value)}
                  error={Boolean(validator[field.name as keyof IWorkout])}
                  sx={{ mb: 2 }}
                  label={t(field.label)}>
                  <option
                    disabled={Boolean(workout[field.name as keyof IWorkout])}
                    value={undefined}>
                    {t("chooseOption")}
                  </option>
                  {field.options &&
                    Object.entries(field.options).map(([key, val]) => {
                      return (
                        <option key={key} value={key}>
                          {t(val as string)}
                        </option>
                      );
                    })}
                </TextField>
              )}
            </Fragment>
          );
        },
      )}

      <Button
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_5}
        variant="contained"
        size="large"
        onClick={() => handleSave()}>
        {t("client.activation.next")}
      </Button>
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    currentStep: clientActivation.step,
    workoutState: clientActivation.workout,
  };
}

const mapDispatchToProps = {
  saveFieldsActionDispatch: saveFieldsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workout);
