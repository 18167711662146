import React, { useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

import Iconify from "../../../components/_MUI/Iconify";

import Timer from "./timer";

import { clearRecord, getFile, getURL, startRecording } from "../recorder-util";

let audio = null;

const VoiceRecorder = props => {
  const { onSelectVoice, onClose } = props;
  const [startRecord, setStartRecord] = useState(false);
  const [stopRecord, setStopRecord] = useState(false);
  const [playing, setPlaying] = useState(false);

  const onRecord = () => {
    setStartRecord(true);
    startRecording();
  };
  const onRemoveVoice = () => {
    onClose();
    clearRecord();
    if (audio) {
      audio.pause();
    }
  };
  const sendMessage = async () => {
    const file = await getFile();
    onSelectVoice(file);
    clearRecord();
    audio?.pause();
  };
  const onStopRecord = () => {
    setStopRecord(true);
    // playAudio()
  };
  const onPlayAudio = async () => {
    const url = await getURL();
    audio = new Audio(url);
    audio.addEventListener("ended", () => setPlaying(false));
    audio.play();
    setPlaying(true);
  };
  const onPauseAudio = () => {
    audio.pause();
    audio.currentTime = 0;
    audio.removeEventListener("ended", () => setPlaying(false));
    setPlaying(false);
  };
  return (
    <RootStyle>
      {startRecord ? (
        <Timer
          onStopRecord={onStopRecord}
          stopRecord={stopRecord}
          playing={playing}
          onPlayAudio={onPlayAudio}
          onPauseAudio={onPauseAudio}
        />
      ) : (
        <StyledIconButton variant="contained" onClick={onRecord}>
          <Iconify icon="uil:microphone" width={18} height={18} color="#0062ff" />
        </StyledIconButton>
      )}
      <div style={{ flex: 1 }} />

      <Stack direction="row" gap="4px">
        {startRecord ? (
          <StyledIconButton aria-label="recorder" onClick={onRemoveVoice}>
            <Iconify icon="uil:trash-alt" width={18} height={18} color="#fd5b5b" />
          </StyledIconButton>
        ) : (
          <StyledIconButton aria-label="recorder" onClick={onClose}>
            <Iconify icon="uil:times" width={18} height={18} color="#0062ff" />
          </StyledIconButton>
        )}
        <StyledIconButton
          aria-label="recorder"
          disabled={!startRecord}
          onClick={sendMessage}>
          <Iconify
            icon="uil:message"
            width={18}
            height={18}
            color={!startRecord ? "rgba(145, 158, 171, 0.8)" : "#0062ff"}
          />
        </StyledIconButton>
      </Stack>
    </RootStyle>
  );
};

const RootStyle = styled("div")(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  margin: "-1px 0px",
  padding: "4px",
  borderRadius: "20px",
  boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.07)",
  backgroundColor: "#0062ff",
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",

  "&:disabled": {
    backgroundColor: "white",
  },

  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

export default VoiceRecorder;
