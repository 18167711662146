/*jshint esversion: 6 */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import "react-datetime/css/react-datetime.css";
import "./styles.scss";
import { useTranslation } from "react-i18next";

export default function ModalConfirmNeed(props) {
    const {t} = useTranslation('clientOverview');
    const {
        show,
        message,
        onSubmit,
        onClose
    } = props;


    return (
        <Dialog aria-labelledby="simple-dialog-title" open={show} style={{zIndex: 2002}}>
            <div className="modal inmodal in sm2" style={{display: (show ? 'block' : 'none')}}>
                <div className="modal-dialog" style={{marginTop: 160}}>
                    <div className="modal-content modal-content-light-grey">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={() => onClose(false)}>
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">{t('confirmNeed.close')}</span>
                            </button>
                            <h4 className="modal-title">
                                {message ? message : t('confirmNeed.areYouSure.activate')}
                            </h4>
                        </div>
                        {!message && (
                            <div className="modal-body">
                                <div>{t('confirmNeed.clientWillBeMoved')}</div>
                            </div>
                        )}
                        <div className="modal-footer footer-button">
                            <button className="btn btn-default btn-upper" onClick={() => onClose(false)}>{t('confirmNeed.no')}</button>
                            <button className="btn btn-success btn-upper" onClick={onSubmit}>{t('confirmNeed.yes')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
