// @ts-ignore
import styled from "styled-components";

export const ZFLoaderStyled = styled.div`
  display: flex;
  justify-content: center;
  &.centerAlign{
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;
