import React from "react";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { DialogActions, DialogContent, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { FormProvider, RHFTextField } from "../../../../components/_MUI/hook-form";
import RHFQuill from "../../../../components/_MUI/hook-form/RHFQuill";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import i18next from "i18next";
import Iconify from "../../../../components/_MUI/Iconify";

export type TLenusClientEmail = {
  subject: string;
  content: string;
};

export const newLenusEmailSchema = Yup.object().shape({
  subject: Yup.string().required(),
  content: Yup.string()
    .required()
    .test(
      "Test if message have [url]",
      i18next.t("transfer.emailUrlRequired", { ns: TranslatorNS.AUTH }),
      value => Boolean(value?.includes("[url]")),
    ),
});

interface Props {
  defaultValues: TLenusClientEmail;
  handleNext: () => void;
}

export default function LenusEmailForm(props: Props) {
  const { defaultValues, handleNext } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { setEmailSubject, setEmailContent } = useLenusTransferContext();

  const methods = useForm<TLenusClientEmail>({
    resolver: yupResolver(newLenusEmailSchema),
    defaultValues,
  });

  const handleSubmitEmail = (data: TLenusClientEmail) => {
    setEmailSubject(data.subject);
    setEmailContent(data.content);
    handleNext();
  };

  const { handleSubmit } = methods;

  return (
    <>
      <DialogContent>
        <Stack mb={3}>
          <Stack direction={"row"}>
            <Typography fontWeight={"bold"} fontSize={18}>
              {t("transfer.clientTransferEmail", { ns: TranslatorNS.AUTH })}
            </Typography>
            {/* TODO: Make tooltip translation work  */}
            <Tooltip title="transfer.clientTransferEmailTooltip">
              <Stack>
                <Iconify icon="uil:info-circle" width={20} height={20} sx={{ m: 0.5 }} color="grey.700" />
              </Stack>
            </Tooltip>
          </Stack>
          <Typography>
            {t("transfer.clientTransferEmailBody", { ns: TranslatorNS.AUTH })}
          </Typography>
        </Stack>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitEmail)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField name="subject" label={t("invitation.subject")} />
            </Grid>
          </Grid>
          <RHFQuill name="content" label={t("invitation.email")} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(handleSubmitEmail)}>
          {t("transfer.save", { ns: TranslatorNS.AUTH })}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
