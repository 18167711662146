import { connect } from "react-redux";
import { AppState } from "../../../store";
import {
  CreateSubscriptionContext,
  ModalTypeEnum,
} from "../../../store/modals/reducerTypes";
import CreateSubscriptionContainer from "./components/CreateSubscriptionContainer";
import Snackbar from "@mui/material/Snackbar";
import { closeGlobalModalAction } from "../../../store/modals/action";

interface Props {
  context?: CreateSubscriptionContext;
  closeGlobalModalAction: (payload: ModalTypeEnum.ClientSubscription) => void;
}

function CreateSubscriptionModal(props: Props) {
  const { context, closeGlobalModalAction } = props;

  if (!context) {
    return null;
  }

  const { clientId } = context;

  if (!clientId) {
    return (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="To the developer: No Client Id was provided to Subscription modal"
      />
    );
  }

  return (
    <CreateSubscriptionContainer
      clientId={clientId}
      onClose={() => closeGlobalModalAction(ModalTypeEnum.ClientSubscription)}
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    context: state?.globalModal[
      ModalTypeEnum.ClientSubscription
    ] as CreateSubscriptionContext,
  };
};

export default connect(mapStateToProps, { closeGlobalModalAction })(
  CreateSubscriptionModal,
);
