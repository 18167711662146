import React, { Fragment, useEffect, useRef, useState } from "react";
import { ZFPictureStyled } from "./ZFPictureStyled";
import { FileDrop } from "react-file-drop";
import { useTranslation } from "react-i18next";
import { CloseRounded } from "@material-ui/icons";
import MaleFrontSVG from "../SVG/MaleFront.svg";
import FemaleFrontSVG from "../SVG/FemaleFront.svg";
import MaleBackSVG from "../SVG/MaleBack.svg";
import FemaleBackSVG from "../SVG/FemaleBack.svg";
import MaleSideSVG from "../SVG/MaleSide.svg";
import FemaleSideSVG from "../SVG/FemaleSide.svg";
import { Gender } from "../../../../interfaces/gender";
import { IGeneral } from "../../type";

interface Props {
  title: string;
  type: any;
  onChange: (file: any) => void;
  value: any;
  gender: IGeneral["gender"];
  onRemove: (string: string) => void;
}

export default function PictureComponent({
  title,
  type,
  onChange,
  value,
  gender,
  onRemove,
}: Props) {
  const { t } = useTranslation("messages");
  const isPictureNoteActive = value === "" || value == null;
  const ref = useRef(null);
  const [image, setImage] = useState<string>();

  const handleReset = () => {
    if (ref && ref.current) {
      (ref.current as any).value = "";
    }
    onRemove("");
  };

  useEffect(() => {
    switch (gender) {
      case Gender.MALE: {
        switch (type) {
          case "front": {
            setImage(MaleFrontSVG);
            break;
          }
          case "back": {
            setImage(MaleBackSVG);
            break;
          }
          default: {
            setImage(MaleSideSVG);
            break;
          }
        }
        break;
      }
      default: {
        switch (type) {
          case "front": {
            setImage(FemaleFrontSVG);
            break;
          }
          case "back": {
            setImage(FemaleBackSVG);
            break;
          }
          default: {
            setImage(FemaleSideSVG);
            break;
          }
        }
        break;
      }
    }
  }, [gender]);

  return (
    <ZFPictureStyled className={!isPictureNoteActive ? "active" : ""}>
      {isPictureNoteActive ? (
        <Fragment>
          <label htmlFor={`input_${type}`} className={"default"}>
            {/* @ts-ignore*/}
            <FileDrop onDrop={files => files !== null && onChange(files[0])}>
              <img src={image} alt={""} />
            </FileDrop>
          </label>
          <div className="description">
            <h2>{title}</h2>
            <h3>{t("client.activation.bodyPictures.spec")}</h3>
            <label htmlFor={`input_${type}`}>
              <span>{t("client.activation.bodyPictures.uploadPicture")}</span>
            </label>
          </div>
        </Fragment>
      ) : (
        <div className="image">
          <span onClick={() => handleReset()}>
            <CloseRounded />
          </span>
          <img
            className={"active"}
            src={typeof value == "string" ? value : URL.createObjectURL(value)}
            alt=""
          />
        </div>
      )}
      <input
        //@ts-ignore
        ref={ref}
        accept="image/*"
        id={`input_${type}`}
        type="file"
        onChange={e => e.target.files !== null && onChange(e.target.files[0])}
      />
    </ZFPictureStyled>
  );
}
