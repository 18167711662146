import React, { Fragment } from "react";

interface Props {
  clientId: number;
}

/*TODO: fix after merged into frontend 16. june*/
export default function DemoClient({ clientId }: Props) {
  return <Fragment>Template fore {clientId}</Fragment>;
}
