import { InputAdornment, TextField } from "@mui/material";
import Iconify from "../../../../components/_MUI/Iconify";
import { useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";

export default function Search({
  search,
  setSearch,
}: {
  search: string;
  setSearch: (q: string) => void;
}) {
  const [searchState, setSearchState] = useState<string>(search);
  useDebounce(() => setSearch(searchState), [searchState], 800);
  const { t } = useTranslation(TranslatorNS.LISTS);

  return (
    <TextField
      size="small"
      label={t("common.search")}
      type="text"
      variant="outlined"
      value={searchState}
      onChange={e => setSearchState(e.target.value)}
      sx={{ mr: 2, width: { xs: "100%", sm: 320 }, background: "white" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon="uil:search" />
          </InputAdornment>
        ),
      }}
    />
  );
}
