import { ReactNode, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { fetchUserUnreadMessages } from "../store/user/action";

type Props = {
  userId: number;
  children: ReactNode;
  fetchUserUnreadMessagesDispatch: () => void;
};

// 2 minutes
const CHECK_INTERVAL = 2 * 60 * 1000;

function UnreadMessagesProvider({
  userId,
  children,
  fetchUserUnreadMessagesDispatch,
}: Props) {
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (userId) {
      fetchUserUnreadMessagesDispatch();
      intervalRef.current = setInterval(
        () => fetchUserUnreadMessagesDispatch(),
        CHECK_INTERVAL,
      );
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [fetchUserUnreadMessagesDispatch, userId]);

  return <>{children}</>;
}

const mapStateToProps = (state: any) => {
  return {
    userId: state.user.id,
  };
};

const mapDispatchToProps = {
  fetchUserUnreadMessagesDispatch: fetchUserUnreadMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnreadMessagesProvider);
