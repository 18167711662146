import React, { useEffect, useRef, useState } from "react";
import { INITIAL_RECORDER_STATE, RESET_RECORDER_STATE } from "../const";
import { RecorderTimer } from "../util/time";
import {
  CheckPermissions,
  GetHandleDataAvailable,
  GetHandleStartCaptureClick,
  GetHandleStopCaptureClick,
  GetPreviewUrlAndBlob,
} from "../util/recorder";
import VideoHeader from "./modules/VideoHeader";
import VideoPreview from "./modules/VideoPreview";
import VideoFooter from "./modules/VideoFooter";
import { IconButton } from "@mui/material";
import Iconify from "../../../../../components/_MUI/Iconify";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import { RecorderStatusEnum, ZFRecorderType } from "../type";
import { useChatPrompt } from "../useChatPrompt";
import { useLocation } from "react-router";

interface Props {
  handleVideoRecorded: (blob: any) => void;
  isMobile: boolean;
  onClose: () => void;
}

//TODO: Fix issue with video isn't paused when confirm close on playing
const VideoRecorder = (props: Props) => {
  const { handleVideoRecorded, onClose } = props;
  const { t } = useTranslation(TranslatorNS.CHAT);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [ZFRecorder, setZFRecorder] = useState<ZFRecorderType>(INITIAL_RECORDER_STATE);
  let location = useLocation();

  useChatPrompt(
    t("chat.leavePageWhileRecording"),
    Boolean(
      ZFRecorder.status === RecorderStatusEnum.RECORDING ||
        ZFRecorder.status === RecorderStatusEnum.READY_TO_SEND,
    ),
    location.pathname,
  );

  const HandleSingleRecorderState = (name: string, val: any) =>
    setZFRecorder(prevState => ({
      ...prevState,
      [name]: val,
    }));

  const HandleMultipleRecorderState = (obj: object) =>
    setZFRecorder(prevState => ({
      ...prevState,
      ...obj,
    }));

  useEffect(() => () => setZFRecorder(RESET_RECORDER_STATE), []);
  useEffect(
    () => RecorderTimer(ZFRecorder.status, setZFRecorder, handleStopCaptureClick),
    [ZFRecorder.status],
  );
  useEffect(
    () =>
      GetPreviewUrlAndBlob(ZFRecorder, HandleMultipleRecorderState, error =>
        HandleMultipleRecorderState({
          status: RecorderStatusEnum.ERROR,
          error,
        }),
      ),
    [ZFRecorder.recordedChunks],
  );

  const handleDataAvailable = GetHandleDataAvailable(setZFRecorder, error =>
    HandleMultipleRecorderState({
      status: RecorderStatusEnum.ERROR,
      error,
    }),
  );
  const handleStartCaptureClick = GetHandleStartCaptureClick(
    HandleSingleRecorderState,
    mediaRecorderRef,
    webcamRef,
    handleDataAvailable,
    error =>
      HandleMultipleRecorderState({
        status: RecorderStatusEnum.ERROR,
        error,
      }),
  );
  const handleStopCaptureClick = GetHandleStopCaptureClick(
    mediaRecorderRef,
    HandleSingleRecorderState,
    webcamRef,
    error =>
      HandleMultipleRecorderState({
        status: RecorderStatusEnum.ERROR,
        error,
      }),
  );
  const handleRetry = () => setZFRecorder(RESET_RECORDER_STATE);

  const handlePermission = () =>
    CheckPermissions(
      () => HandleSingleRecorderState("status", RecorderStatusEnum.READY),
      error =>
        HandleMultipleRecorderState({
          status: RecorderStatusEnum.NO_PERMISSIONS,
          error: error,
        }),
    );

  return (
    <>
      <Box style={{ position: "absolute", zIndex: 21, right: 0 }}>
        <IconButton
          onClick={() =>
            ZFRecorder.status === RecorderStatusEnum.RECORDING ||
            ZFRecorder.status === RecorderStatusEnum.READY_TO_SEND
              ? window.confirm(t("chat.areYouSureDelete")) && onClose()
              : onClose()
          }>
          <Iconify icon={"eva:close-fill"} width={40} height={40} color="#fff" />
        </IconButton>
      </Box>
      <div className={"VideoRecorderStyled"}>
        <VideoHeader
          status={ZFRecorder.status}
          handleClearVideo={handleRetry}
          error={ZFRecorder.error}
        />
        <VideoPreview
          status={ZFRecorder.status}
          webcamRef={webcamRef}
          previewUrl={ZFRecorder.previewUrl}
          handlePermission={handlePermission}
        />
        <VideoFooter
          status={ZFRecorder.status}
          timeCounter={ZFRecorder.timeCounter}
          handleDownload={() => handleVideoRecorded(ZFRecorder.blob)}
          handleStartCaptureClick={handleStartCaptureClick}
          handleStopCaptureClick={handleStopCaptureClick}
        />
      </div>
    </>
  );
};

export default VideoRecorder;
