import * as React from "react";
import { Stack, Typography } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { StyledImage } from "../Items/WorkoutImage";
import Iconify from "../../../components/_MUI/Iconify";
import {
  DialogContext,
  VideoDialogContext,
  WorkoutPlanModalType,
} from "../Dialog/DialogProvider";

interface Props {
  exerciseImage?: string;
  exerciseVideo?: string;
  title: string;
  subTitle: string;
}

export default function ListItem({
  exerciseImage,
  exerciseVideo,
  title,
  subTitle,
}: Props) {
  const theme = useTheme();
  const { setModal } = React.useContext(DialogContext);

  const openVideoModal = () =>
    setModal({
      type: WorkoutPlanModalType.VIDEO,
      context: { title, video: exerciseVideo || null } as VideoDialogContext,
    });

  return (
    <Stack direction="row">
      <StyledImage
        exerciseimage={(exerciseImage || "") as string}
        onClick={openVideoModal}>
        <Iconify
          icon="material-symbols:play-arrow-rounded"
          sx={{ color: theme.palette.grey[400] }}
          className="workout-editor-play-icon"
        />
      </StyledImage>
      <Stack alignSelf={"center"}>
        <Typography variant="subtitle2">{title}</Typography>
        <Tooltip title={subTitle}>
          <Typography
            variant="body2"
            sx={{
              fontSize: 12,
              color: theme.palette.grey[500],
              width: 220,
              textOverflow: "ellipsis",
              whiteSpace: "noWrap",
              overflow: "hidden",
              display: "inline-block",
            }}>
            {subTitle}
          </Typography>
        </Tooltip>
      </Stack>
    </Stack>
  );
}
