export enum LoginScreen {
  EMAIL_FORGOT_PASSWORD = "forgotPassword",
  LOGIN = "login",
  NEW_PASSWORD = "newPassword",
}

export const SLIDES = {
  1: {
    video: "https://www.youtube.com/watch?v=IAL4kGo1osM",
    title: "login.slider.howToSucceed.title",
    subtitle: "login.slider.howToSucceed.desc",
  },
  2: {
    video: "https://www.youtube.com/watch?v=vJf4MNKx4n8",
    title: "login.slider.powerfulTricks.title",
    subtitle: "login.slider.powerfulTricks.desc",
  },
  3: {
    video: "https://www.youtube.com/watch?v=9ooBgIhFt90",
    title: "login.slider.howToStructure.title",
    subtitle: "login.slider.howToStructure.desc",
  },
};
