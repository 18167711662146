import {
  ClientStatusType,
  ClientType,
  useResolveTaskMutation,
} from "../../../generated/graphql";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/client";

export default function useResolveTask() {
  const [resolveTask] = useResolveTaskMutation();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const removeUnresolvedTaskFromCache = (
    clientId: ClientType["id"],
    statusId: ClientStatusType["id"],
  ) => {
    client.cache.modify({
      id: "ClientType:" + clientId,
      fields: {
        unresolvedTasks(existingTasks = [], { readField }) {
          return existingTasks.filter(
            (task: ClientStatusType) => statusId !== readField("id", task),
          );
        },
      },
    });
  };

  const handleResolveTask = async (
    statusId: ClientStatusType["id"],
    clientId: ClientType["id"],
  ) => {
    try {
      await resolveTask({
        variables: {
          taskId: statusId,
        },
        update: cache => {
          cache.modify({
            fields: {
              clientFilterCount() {},
            },
          });
        },
      });
      removeUnresolvedTaskFromCache(clientId, statusId);

      enqueueSnackbar("Task resolved", {
        variant: "success",
      });
    } catch (error) {
      console.log({ error });
      enqueueSnackbar("Task was not resolved", {
        variant: "error",
      });
    }
  };

  const handleResolveTaskWithoutNotification = async (
    statusId: ClientStatusType["id"],
    clientId: ClientType["id"],
  ) => {
    try {
      await resolveTask({
        variables: {
          taskId: statusId,
        },
        update: cache => {
          cache.modify({
            fields: {
              clientFilterCount() {},
            },
          });
        },
      });
      removeUnresolvedTaskFromCache(clientId, statusId);
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    handleResolveTask,
    handleResolveTaskWithoutNotification,
    removeUnresolvedTaskFromCache,
  };
}
