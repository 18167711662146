import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getUnixEpoch } from "../../../../../helpers/dates";
import DateConfirmModal from "../../../../../components/Modal/DateConfirmModal";
import { AppState } from "../../../../../store";
import { pauseSubscription } from "../../../../Clients/store/clients/actions";
import { RequestState } from "../../../../../interfaces/requestState";

interface Props {
  open: boolean;
  clientId: number;
  handleClose: VoidFunction;
  // date: unix
  // pause: should always ebe false for this one as it is a flag
  dispatchAdjustPaymentDate: (
    clientId: number,
    unix: number,
    pause: false,
    t: any,
  ) => void;
  isSubmitting: boolean;
}
function AdjustPaymemtDateContainer({
  open,
  clientId,
  handleClose,
  dispatchAdjustPaymentDate,
  isSubmitting,
}: Props) {
  const { t } = useTranslation("clientOverview");

  const handleAdjustPaymentDate = (date: Date) => {
    const unixTime = getUnixEpoch(date);
    if (!clientId || !unixTime) return alert("Something went wrong");
    dispatchAdjustPaymentDate(clientId, unixTime, false, t);
    handleClose();
  };
  return (
    <DateConfirmModal
      open={open}
      title={t("clientPayment.adjustPaymentDate")}
      description={t("clientPayment.theDateWillBeChangedTo")}
      handleClose={handleClose}
      onConfirm={handleAdjustPaymentDate}
      isSubmitting={isSubmitting}
    />
  );
}

function mapStateToProps(state: AppState) {
  return {
    clientId: state.currentClient.id,
    isSubmitting: state.currentClient.subscriptionRequestState === RequestState.PENDING,
  };
}

const mapDispatchToProps = {
  dispatchAdjustPaymentDate: pauseSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustPaymemtDateContainer);
