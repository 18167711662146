import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import RHFSelect from "../../../../_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import { RHFCheckbox, RHFTextField } from "../../../../_MUI/hook-form";
import { MEALS_PER_DAY } from "../../../../../routes/Clients/constants/Meal";
import Label from "../../../../_MUI/Label";
import {
  DIET_PREFERENCES,
  EXCLUDED_INGREDIENTS,
} from "../../../../../routes/ClientOverview/_const";

export default function DietFields() {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  return (
    <Stack mb={3}>
      <Typography variant={"subtitle2"} mb={2}>
        {t("const.clientInfoFields.dietaryPreference")}
      </Typography>
      <RHFCheckbox name="hideCalories" label={t("info.hideKcalsTitle")} />
      <RHFSelect
        label={t("info.optionalQuestions.numberOfMealsTitle")}
        name={"numberOfMeals"}>
        {Object.values(MEALS_PER_DAY).map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        label={t("const.clientInfoFields.foodPreferences")}
        name={"foodPreferences"}>
        {Object.entries(DIET_PREFERENCES).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {t(label, { ns: TranslatorNS.MESSAGES })}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        label={t("const.clientInfoFields.excludedIngredients")}
        multiple
        name={"allergies"}
        renderValue={selected => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {(selected as any).map((value: string) => (
              <Label key={value} color={"error"}>
                {t(`client.survey.${value}`, { ns: TranslatorNS.MESSAGES })}
              </Label>
            ))}
          </Box>
        )}>
        {Object.entries(EXCLUDED_INGREDIENTS).map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {t(label, { ns: TranslatorNS.MESSAGES })}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFTextField
        name="dietStyle"
        label={t("const.clientInfoFields.dietStyle")}
        multiline
        minRows={3}
      />
    </Stack>
  );
}
