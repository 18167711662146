export enum CurrencyEnum {
  EUR = "eur",
  USD = "usd",
  DKK = "dkk",
  SEK = "sek",
  NOK = "nok",
  GBP = "gbp",
  CHF = "chf",
}

const currencyValues = ['eur', 'usd', 'dkk', 'sek', 'nok', 'gbp', 'chf'] as const
export type CurrencyEnumValue = typeof currencyValues[number]

export enum CurrencySymbol {
  DOLLAR = "$",
  EURO = "€",
  POUND = "£",
}
