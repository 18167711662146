import { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";
import {
  CoachType,
  CurrencyEnum,
  LocaleEnum,
  TransferProgressEnum,
} from "../../../generated/graphql";
import { Country } from "../../../components/InputField/enum";
import { CountryEnum } from "../../../interfaces/locale";

export enum AmountOfClientsEnum {
  Zero = "0 clients",
  OneToFive = "1-5 clients",
  SixToTwenty = "6-20 clients",
  TwentyOneToFifty = "21-50 clients",
  FiftyPlus = "+50 clients",
}

export const GetStartedDataInitialState = {
  name: undefined,
  email: undefined,
  locale: LocaleEnum.EnUs,
  commitment: false,
  startDate: new Date(),
  currency: CurrencyEnum.Usd,
  isActive: false,
  amountOfClients: AmountOfClientsEnum.Zero,
  country: CountryEnum.US,
  salesforceId: undefined,
  transferInProgress: TransferProgressEnum.NotStarted,
  lastPaymentFailed: false,
};

export type GetStartedData = {
  name: CoachType["name"] | undefined;
  email: CoachType["email"] | undefined;
  locale: LocaleEnum;
  commitment: boolean;
  startDate: Date;
  currency: CurrencyEnum;
  isActive: boolean;
  amountOfClients: AmountOfClientsEnum;
  country: CountryEnum;
  salesforceId: string | undefined;
  transferInProgress: TransferProgressEnum;
  lastPaymentFailed: boolean;
};

const QUERY_ME = gql`
  {
    me {
      id
      name
      email
      isActive
      language {
        id
        locale
      }
      country
      settings {
        id
        transferInProgress
        startDate(format: "c")
      }
      subscription {
        id
        committedUntil(format: "c")
        lastPaymentFailed
      }
    }
  }
`;

export default function useGetStartedData() {
  const [prefilled, setPrefilled] = useState<GetStartedData>(GetStartedDataInitialState);
  const [loading, setLoading] = useState(true);
  const [getMeQuery] = useLazyQuery(QUERY_ME);

  let [searchParams, _] = useSearchParams();

  const handlePrefilled = (me: CoachType) => {
    const country = getCountryCodeFromParamsMappedToEnum(
      searchParams.get("country") as Country,
      me?.country,
    );
    setPrefilled({
      name: searchParams.get("name") || me?.name,
      commitment: Boolean(
        Number(searchParams.get("commitment")) || !!me?.subscription?.committedUntil,
      ),
      email: searchParams.get("email") || me?.email,
      locale: getLocaleFromParamsMappedToEnum(
        searchParams.get("locale"),
        me?.language?.locale,
      ),
      startDate: getStartDate(searchParams.get("startDate"), me?.settings?.startDate),
      currency: getCurrencyFromParamsMappedToEnum(searchParams.get("currency"), country),
      isActive: Boolean(me?.isActive),
      country,
      amountOfClients: getAmountOfClientsFromParamsMappedToEnum(
        searchParams.get("amountOfClients"),
      ),
      salesforceId: searchParams.get("salesforceId") || undefined,
      transferInProgress:
        me?.settings?.transferInProgress || TransferProgressEnum.NotStarted,
      lastPaymentFailed: !!me.subscription?.lastPaymentFailed,
    });
  };

  const getPrefilledData = async () => {
    try {
      setLoading(true);
      const { data } = await getMeQuery();
      const me: CoachType = data?.me;
      await handlePrefilled(me);
    } catch (e) {
      console.error({ e });
    } finally {
      setLoading(false);
    }
  };

  const refetch = async () => {
    try {
      const { data } = await getMeQuery();
      const me: CoachType = data?.me;
      handlePrefilled(me);
    } catch (e) {
      console.error({ e });
    }
  };

  useEffect(() => {
    getPrefilledData();

    return () => {
      setPrefilled(GetStartedDataInitialState);
    };
  }, []);

  return {
    refetch,
    getStartedData: {
      ...prefilled,
      loading,
    },
  };
}

const getCountryCodeFromParamsMappedToEnum = (
  country: string | null,
  meCountry: string | undefined | null,
): CountryEnum => {
  if (meCountry) {
    return meCountry as CountryEnum;
  }

  const upperCaseCountry = country?.toUpperCase();

  switch (upperCaseCountry) {
    case CountryEnum.DK:
      return CountryEnum.DK;
    case CountryEnum.NO:
      return CountryEnum.NO;
    case CountryEnum.SE:
      return CountryEnum.SE;
    case CountryEnum.GB:
      return CountryEnum.GB;
    case CountryEnum.DE:
      return CountryEnum.DE;
    case CountryEnum.FI:
      return CountryEnum.FI;
    default:
      return CountryEnum.US;
  }
};

const getLocaleFromParamsMappedToEnum = (
  locale: string | null,
  meLocale: string | null | undefined,
): LocaleEnum => {
  if (meLocale) {
    return meLocale as LocaleEnum;
  }

  switch (locale) {
    case LocaleEnum.DaDk:
      return LocaleEnum.DaDk;
    case LocaleEnum.NbNo:
      return LocaleEnum.NbNo;
    case LocaleEnum.SvSe:
      return LocaleEnum.SvSe;
    case LocaleEnum.EnGb:
      return LocaleEnum.EnGb;
    case LocaleEnum.DeDe:
      return LocaleEnum.DeDe;
    case LocaleEnum.FiFi:
      return LocaleEnum.FiFi;
    default:
      return LocaleEnum.EnUs;
  }
};

const getCurrencyFromParamsMappedToEnum = (
  currency: string | null,
  country: CountryEnum,
): CurrencyEnum => {
  const lowerCaseCurrency = currency?.toLowerCase();

  const getCurrencyFromCountry = () => {
    switch (country) {
      case CountryEnum.DK:
        return CurrencyEnum.Dkk;
      case CountryEnum.NO:
        return CurrencyEnum.Nok;
      case CountryEnum.SE:
        return CurrencyEnum.Sek;
      case CountryEnum.GB:
        return CurrencyEnum.Gbp;
      case CountryEnum.DE:
        return CurrencyEnum.Eur;
      case CountryEnum.FI:
        return CurrencyEnum.Eur;
      case CountryEnum.NL:
        return CurrencyEnum.Eur;
      case CountryEnum.CH:
        return CurrencyEnum.Chf;
      default:
        return CurrencyEnum.Usd;
    }
  };

  switch (lowerCaseCurrency) {
    case CurrencyEnum.Eur:
      return CurrencyEnum.Eur;
    case CurrencyEnum.Gbp:
      return CurrencyEnum.Gbp;
    case CurrencyEnum.Dkk:
      return CurrencyEnum.Dkk;
    case CurrencyEnum.Sek:
      return CurrencyEnum.Sek;
    case CurrencyEnum.Nok:
      return CurrencyEnum.Nok;
    case CurrencyEnum.Chf:
      return CurrencyEnum.Chf;
    default:
      return getCurrencyFromCountry();
  }
};

const getStartDate = (
  startDate: string | null,
  meStartDate: string | undefined | null,
): Date => {
  try {
    if (meStartDate) {
      return new Date(meStartDate);
    }

    if (startDate) {
      return new Date(startDate);
    }

    return new Date();
  } catch (e) {
    return new Date();
  }
};

const getAmountOfClientsFromParamsMappedToEnum = (
  amountOfClients: string | null,
): AmountOfClientsEnum => {
  if (!amountOfClients) return AmountOfClientsEnum.Zero;

  const numbAmountOfClients = Number(amountOfClients);

  if (numbAmountOfClients === 0) return AmountOfClientsEnum.Zero;

  if (numbAmountOfClients >= 1 && numbAmountOfClients <= 5)
    return AmountOfClientsEnum.OneToFive;

  if (numbAmountOfClients >= 6 && numbAmountOfClients <= 20)
    return AmountOfClientsEnum.SixToTwenty;

  if (numbAmountOfClients >= 21 && numbAmountOfClients <= 50)
    return AmountOfClientsEnum.TwentyOneToFifty;

  if (numbAmountOfClients >= 51) return AmountOfClientsEnum.FiftyPlus;

  return AmountOfClientsEnum.Zero;
};
