import React, { ChangeEvent, useEffect, useState } from "react";
import axios from "../../../../../helpers/axios";
import { useTranslation } from "react-i18next";

import { Client, WorkoutPlan } from "../../../types";
import {
  GET_WORKOUT_TEMPLATES,
  UPDATE_WORKOUT,
} from "../../../../../api/private/workout-api";

// @ts-ignore
const $ = global.jQuery || {};

interface Props {
  plan: WorkoutPlan;
  client?: Client;
  refetchFn: () => void;
}

type Template = {
  id: number;
  active: number;
  comment?: string;
  created: string;
  days: number;
  explaination: string;
  explanation: string;
  last_updated: string;
  meta: {
    duration: number;
    gender: number;
    level: number;
    location: number;
    type: number;
    workoutsPerWeek: number;
  };
  name: string;
  status: string;
  settings: {
    reps: boolean;
    rest: boolean;
    time: boolean;
    weight: boolean;
    tempo: boolean;
    rm: boolean;
  };
};

export default function ApplyTemplate({ plan, refetchFn }: Props) {
  const { t } = useTranslation(["workoutPlan", "lists"]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<number[]>([]);
  let PATH = UPDATE_WORKOUT(plan.id);

  useEffect(() => {
    const fetch = async () => {
      const templates = await axios.get(GET_WORKOUT_TEMPLATES());
      setTemplates(templates.data);
    };

    fetch();
  }, []);

  const selectTemplate = (e: ChangeEvent<HTMLInputElement>, templateId: number) => {
    if (e.target.checked) {
      setSelectedTemplates([...selectedTemplates, templateId]);
    } else {
      setSelectedTemplates(selectedTemplates.filter(id => id !== templateId));
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const $form = $(e.target);
    const $submit = $form.find("[type=submit]").button("loading");

    const formData = new FormData();
    selectedTemplates.forEach(templateId => {
      formData.append("templates[]", String(templateId));
    });

    const promise = axios.post(PATH, formData);

    promise
      .then(() => {
        $form.closest(".modal").modal("hide");

        setSelectedTemplates([]);
        $form[0].reset();
        refetchFn();
      })
      .catch((...args) => {
        console.log("#addWorkoutTemplate::fail", args);
      })
      .then(() => {
        $submit.button("reset");
      });
  };

  return (
    <div
      className="modal modal-workout inmodal reactModal"
      id="addWorkoutTemplate"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey ">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("applyTemplate.close")}</span>
            </button>
            <h4 className="modal-title">{t("editor.applyTemplateToWorkout")}</h4>
            <p>{t("applyTemplate.youCanAdd")}</p>
          </div>
          <form id="applyTemplateForm" action={PATH} method="POST" onSubmit={onSubmit}>
            <div className="modal-body">
              <div id="applyTemplateCheckboxes">
                {Object.values(templates).map(template => {
                  return (
                    <div className="checkbox" key={template.id}>
                      <label htmlFor={`template_${template.id}`}>
                        <input
                          style={{ userSelect: "none" }}
                          id={`template_${template.id}`}
                          type="checkbox"
                          onChange={e => selectTemplate(e, template.id)}
                        />
                        <strong>{template.name} - </strong>
                        <span
                          className={`label label-${
                            template.days == 0 ? "warning" : "inverse"
                          }`}>
                          {template.days == 0
                            ? t("applyTemplate.empty")
                            : `${template.days} ${t("applyTemplate.workoutDays")}`}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                disabled={selectedTemplates.length <= 0}
                type="submit"
                id="saveTemplate"
                className="btn btn-block btn-success btn-upper">
                {t("applyTemplate.addWorkoutTemplates")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
