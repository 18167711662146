import React from "react";
import { Stack, Typography } from "@mui/material";
import RHFSelect from "../../../../_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import {
  WorkoutExperienceLevelEnum,
  WorkoutLocationEnum,
} from "../../../../../generated/graphql";
import { RHFTextField } from "../../../../_MUI/hook-form";
import { WORKOUT_PER_WEEK } from "../../../../../routes/ClientActivation/const";

export default function WorkoutFields() {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  return (
    <Stack mb={3}>
      <Typography variant={"subtitle2"} mb={2}>
        {t("const.clientInfoFields.workoutPreference")}
      </Typography>
      <RHFSelect
        label={t("modalWorkoutTemplates.workoutPerWeek")}
        name={"workoutsPerWeek"}>
        {Object.values(WORKOUT_PER_WEEK).map(value => (
          <MenuItem key={value} value={value}>
            {value}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFSelect label={t("modalWorkoutTemplates.location")} name={"workoutLocation"}>
        {Object.values(WorkoutLocationEnum).map(value => (
          <MenuItem key={value} value={value}>
            {t(`const.clientWorkoutMetas.location.${value.toLowerCase()}`)}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFSelect
        label={t("const.clientInfoFields.workoutExperienceLevel")}
        name={"workoutExperienceLevel"}>
        {Object.values(WorkoutExperienceLevelEnum).map(value => (
          <MenuItem key={value} value={value}>
            {t(
              `newClientOverview.generalCard.workoutExperienceLevel.${value.toLowerCase()}`,
            )}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFTextField
        name="workoutExperience"
        label={t("const.clientInfoFields.experience")}
        multiline
        minRows={3}
      />
      <RHFTextField
        name="workoutPreference"
        label={t("const.clientInfoFields.workoutPreference")}
        multiline
        minRows={3}
      />
      <RHFTextField
        name="injuries"
        label={t("const.clientInfoFields.injuries")}
        multiline
        minRows={3}
      />
    </Stack>
  );
}
