import * as Yup from "yup";

export const newEmailSchema = Yup.object().shape({
  clientId: Yup.number().required(),
  to: Yup.string().required(),
  subject: Yup.string().required(),
  message: Yup.string().required(),
  queueId: Yup.number().required(),
  template: Yup.string().required(),
});
