import { ClientType } from "../../../../generated/graphql";
import { getISOWeek, getISOWeekYear } from "date-fns";
import { roundToDecimals } from "../../../ClientProgress/bottom/helpers/utils";

type TStepsGroupedByWeekNumber = {
  [key: string]: { [key: string]: { id: string; steps: number; date: string } };
};

export const getPreviousWeeksAverageSteps = (steps?: ClientType["steps"]) => {
  const sortedSteps = [...(steps || [])].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  const stepsGroupedByWeek = getStepsGroupedByWeekNumber(sortedSteps);
  const stepsGroupedByWeekKeys = Object.keys(stepsGroupedByWeek);

  const weekNumber = getWeekNumber(stepsGroupedByWeek, stepsGroupedByWeekKeys, 2);
  const averageWeekSteps = getAverageWeekWeight(weekNumber, stepsGroupedByWeek);

  const removeYearFromWeekNumber = weekNumber?.split("-")[1];

  return {
    stepsWeekNumber: removeYearFromWeekNumber || undefined,
    stepsAverageWeek: averageWeekSteps || undefined,
  };
};

function getAverageWeekWeight(
  weekNumber: string | undefined,
  progressGroupedByWeekNumber: TStepsGroupedByWeekNumber,
) {
  if (!weekNumber) return undefined;

  const valuesArr = Object.values(progressGroupedByWeekNumber[weekNumber]);
  const sumSteps = valuesArr.reduce((previousValue, c) => previousValue + c.steps, 0);

  return roundToDecimals(sumSteps / valuesArr.length, 0);
}

function getWeekNumber(
  stepsGroupedByWeek: TStepsGroupedByWeekNumber,
  stepsGroupedByWeekKeys: string[],
  indexFromLast: number,
) {
  return stepsGroupedByWeek
    ? stepsGroupedByWeekKeys[stepsGroupedByWeekKeys?.length - indexFromLast]
    : undefined;
}

function getStepsGroupedByWeekNumber(
  steps: ClientType["steps"] | undefined,
): TStepsGroupedByWeekNumber {
  return (steps || []).reduce((previousValue, currentValue) => {
    const weekNumber = getISOWeek(new Date(currentValue.date));
    const year = getISOWeekYear(new Date(currentValue.date));
    const weekNumberAndYear = `${year}-${weekNumber}`;

    if (!Boolean(currentValue.steps)) {
      return previousValue;
    }

    return {
      ...previousValue,
      [weekNumberAndYear]: {
        ...previousValue[weekNumberAndYear],
        [currentValue.id]: {
          id: currentValue.id,
          date: currentValue.date,
          steps: currentValue.steps,
        },
      },
    };
  }, {} as TStepsGroupedByWeekNumber);
}
