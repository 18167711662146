// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import styled from "styled-components";
import { GLOBAL_FONT_STYLE } from "../../../styleguide/Fonts";
import { quillFormats, quillModules } from "../../SideContent/reactQuillConfig";
import ReactQuill from "react-quill";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  label: string;
};

type Props = IProps;

export default function RHFQuill({ name, label }: Props) {
  const { control } = useFormContext();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <Stack>
            <Typography color="GrayText" fontWeight={600} fontSize={14} sx={{ mb: 0.5 }}>
              {label}
            </Typography>
            <ReactQuillStyled>
              {/* @ts-ignore */}
              <ReactQuill
                value={value}
                placeholder={t("invitation.emailPlaceholder")}
                tabIndex={10}
                modules={quillModules}
                formats={quillFormats}
                theme="snow"
                onKeyUp={({ target }) => onChange(target.innerHTML)}
              />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </ReactQuillStyled>
          </Stack>
        );
      }}
    />
  );
}

const ReactQuillStyled = styled.div`
  .ql-editor {
    min-height: 150px;
    padding: 12px 0;
  }
  .ql-container {
    padding: 10px 21px !important;
    border: 1px solid #dadada !important;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid #dadada;
    border-radius: 8px;
    border-bottom: 0;
    box-sizing: border-box;
    ${GLOBAL_FONT_STYLE};
    padding: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
