import React from "react";
import {
  IconButton,
  styled,
  Typography,
  Stack,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { TranslatorKeyPair } from "../../i18n/const";
import MoveDropdown from "./Items/MoveDropdown";
import { useParams } from "react-router-dom";

const StyledBlockWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  background: theme.palette.common.white,
  padding: 8,
  minHeight: 64,
  marginBottom: 10,
  border: `solid ${theme.palette.grey[300]}`,
  borderWidth: 1,
  borderRadius: 8,
  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
}));

export default function SuperSetWrapper({
  id,
  dayId,
  editingComment,
  children,
  deleteItem,
}: {
  id: number;
  dayId: number;
  editingComment: boolean;
  children: React.ReactElement;
  deleteItem: VoidFunction;
}) {
  const { templateId } = useParams<{ templateId: string }>();
  const theme = useTheme();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteItem();
    handleClose();
  };
  return (
    <StyledBlockWrapper
      data-id={id}
      // class handle controls if can be dragged
      className={editingComment ? "" : "handle"}
      sx={{
        backgroundColor: "#ecf7ff",
        borderColor: theme.palette.primary.lighter,
        p: 2,
        pt: 1,
      }}>
      <Stack
        // class handle controls if can be dragged
        className={editingComment ? "handle" : ""}
        direction="row"
        alignItems="center"
        justifyContent="space-between">
        {/* // set to ml: -1 if using TextEditField */}
        <Box sx={{ ml: 0 }}>
          <Typography variant="subtitle2">
            {t("plan.superSet", { ns: TranslatorKeyPair.plans })}
          </Typography>
        </Box>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}>
          <List
            disablePadding
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader">
            <MoveDropdown
              templateId={templateId as string}
              workoutId={id}
              dayId={dayId}
            />
            <ListItemButton onClick={handleDelete}>
              <ListItemText
                primaryTypographyProps={{ color: theme.palette.error.main }}
                primary={t("plan.deleteSuperSet", { ns: TranslatorKeyPair.plans })}
              />
            </ListItemButton>
          </List>
        </Menu>
      </Stack>
      <Box sx={{ pb: 1 }} />
      {children}
    </StyledBlockWrapper>
  );
}
