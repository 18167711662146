import React, { useRef } from "react";
import DOMPurify from "dompurify";
import moment from "moment";
import Voice from "../voice";
import { MESSAGE_PENDING } from "../../constants";
import MessageStatus from "./message-status";
import { useTranslation } from "react-i18next";
import { getChatContent } from "../../../Chat/components/messages/MessageReplacement";
import { styled } from "@mui/material/styles";
import MediaBubble from "../../../Chat/components/MediaBubble";

const Message = ({ message, groupWithNext }) => {
  const { t } = useTranslation("chatWidget");
  const isPending = message.status === MESSAGE_PENDING;
  const messageElement = useRef(null);

  let alignment = "right";
  let content = message.content ? DOMPurify.sanitize(message.content) : null;

  if (message.client) {
    alignment = "left";
  }

  let video = null;
  let statusPrefix = null;

  if (message.video) {
    if (isPending) {
      content = null;
      statusPrefix = t("message.mediaIsUploading");
    } else {
      const isVideo = message.video.includes(".mp4");

      if (isVideo) {
        video = <MediaBubble url={message.video} />;
      } else {
        video = (
          <ChatMessageBubble
            alignment={alignment}
            style={{ marginBottom: message.content ? 4 : 0, width: "100%" }}>
            <Voice url={message.video} />
          </ChatMessageBubble>
        );
      }
    }
  }

  if (message.isUpdate) {
    alignment = "center";

    if (message.content) {
      content = (
        <ChatMessageUpdateWrapper>
          {getChatContent(content, undefined, messageElement)}
        </ChatMessageUpdateWrapper>
      );
    }
  } else {
    if (message.content) {
      content = (
        <ChatMessageBubble alignment={alignment}>
          <ChatMessageText
            ref={messageElement}
            dangerouslySetInnerHTML={{ __html: urlify(content) }}
            alignment={alignment}
          />
        </ChatMessageBubble>
      );
    }
  }

  const now = moment(new Date().setHours(23, 59, 59, 999));
  const date = moment(message.date);
  const { days, months, years } = moment.preciseDiff(now, date, true);
  let time = null;
  /**/
  if (days > 1 || months > 0 || years > 0) {
    time = date.format("llll");
  } else if (days === 1) {
    time = `${t("message.yesterday")}, ${date.format("LT")}`;
  } else {
    time = `${date.format("LT")}`;
  }
  function urlify(text) {
    var urlRegex = /([^"]https?:\/\/[^\s]+)/g;
    return (text || "").replace(urlRegex, function (url) {
      return (
        '<a href="' + url + '" class="chat-message-url" target="_blank">' + url + "</a>"
      );
    });
  }
  return (
    <ChatMessageWrapper
      id={`chat_widget_message_${message.id}`}
      alignment={alignment}
      groupWithNext={groupWithNext}>
      {statusPrefix !== null ? (
        <ChatMessageStatus style={{ marginBottom: groupWithNext ? "8px" : 0 }}>
          {statusPrefix}
        </ChatMessageStatus>
      ) : (
        <>
          {video}
          {content}
        </>
      )}
      {!groupWithNext && !message.isUpdate && (
        <ChatMessageStatus>
          {!message.client && <MessageStatus status={message.status} />}
          <ChatMessageStatusTime>{time}</ChatMessageStatusTime>
        </ChatMessageStatus>
      )}
    </ChatMessageWrapper>
  );
};

const ChatMessageBubbleAlignmentMapping = {
  left: {
    backgroundColor: "#f4f6f8",
    color: "#212b36",
  },
  right: {
    backgroundColor: "#0062ff",
    color: "#fff",
  },
};

const ChatMessageBubble = styled("div")(({ alignment }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "75%",
  borderRadius: "8px",
  padding: "16px 18px",

  ...(ChatMessageBubbleAlignmentMapping[alignment] || {}),
}));

const ChatMessageText = styled("div")(({ alignment }) => ({
  wordBreak: "break-word",
  fontSize: "14px",
  lineHeight: 1.57,
}));

const ChatMessageWrapperAlignmentMapping = {
  center: "center",
  left: "flex-start",
  right: "flex-end",
};

const ChatMessageWrapper = styled(({ groupWithNext, ...props }) => <div {...props} />)(
  ({ alignment, groupWithNext }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: groupWithNext ? "2px" : "8px",
    lineHeight: 1.25,
    alignItems: ChatMessageWrapperAlignmentMapping[alignment],
  }),
);

const ChatMessageUpdateWrapper = styled("div")(() => ({
  wordBreak: "break-word",
  margin: "12px 0px",
}));

const ChatMessageStatus = styled("div")(() => ({
  fontSize: "12px",
  lineHeight: "18px",
  color: "#919eab",
  margin: "8px 0px 16px 0",
}));

const ChatMessageStatusTime = styled("span")(() => ({
  display: "inline-block",
  verticalAlign: "middle",
}));

export default Message;
