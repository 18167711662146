export const WEEK_DAYS = [
  { value: "0", label: "help.week.none" },
  { value: "1", label: "help.week.Monday" },
  { value: "2", label: "help.week.Tuesday" },
  { value: "3", label: "help.week.Wednesday" },
  { value: "4", label: "help.week.Thursday" },
  { value: "5", label: "help.week.Friday" },
  { value: "6", label: "help.week.Saturday" },
  { value: "7", label: "help.week.Sunday" },
];
