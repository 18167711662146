import {
  isModalOpen,
  ModalTypeEnum,
  MealTemplateModalContext,
} from "../../../store/modals/reducerTypes";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { closeGlobalModalAction } from "../../../store/modals/action";
import MealTemplateContainer from "./components/MealTemplateContainer";

interface Props {
  context?: MealTemplateModalContext;
  closeGlobalModalAction: (payload: ModalTypeEnum.MealTemplate) => void;
  isModalOpen: boolean;
}

function MealTemplateModal(props: Props) {
  const { isModalOpen, context, closeGlobalModalAction } = props;

  if (!isModalOpen || !context) {
    return null;
  }

  const { mealPlanId } = context;

  return (
    <MealTemplateContainer
      type={context.type}
      mealPlanId={mealPlanId}
      onClose={() => closeGlobalModalAction(ModalTypeEnum.MealTemplate)}
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    context: state.globalModal[ModalTypeEnum.MealTemplate] as MealTemplateModalContext,
    isModalOpen: isModalOpen(ModalTypeEnum.MealTemplate, state.globalModal),
  };
};

export default connect(mapStateToProps, { closeGlobalModalAction })(MealTemplateModal);
