import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SimpleConfirmModal from "../../../../../components/Modal/SimpleConfirmModal";
import { AppState } from "../../../../../store";
import { unsubscribe } from "../../../../Clients/store/clients/actions";
import { RequestState } from "../../../../../interfaces/requestState";
import { useGetClientSubscriptionLazyQuery } from "../../../../../generated/graphql";

interface Props {
  open: boolean;
  clientId: number;
  handleClose: VoidFunction;
  dispatchUnsubscribe: (clientId: number, refetch: () => void) => void;
  isSubmitting: boolean;
}

function UnsubscribeClientContainer({
  open,
  clientId,
  handleClose,
  dispatchUnsubscribe,
  isSubmitting,
}: Props) {
  const { t } = useTranslation("clientOverview");

  const [_, { refetch }] = useGetClientSubscriptionLazyQuery({
    variables: {
      id: Number(clientId),
    },
  });

  const handleUnsubscribeClient = () => {
    if (!clientId) return alert("Something went wrong");
    dispatchUnsubscribe(clientId, () => refetch());
    handleClose();
  };

  return (
    <SimpleConfirmModal
      open={open}
      title={t("newClientOverview.areYouSureTitle")}
      description={t("newClientOverview.areYouSureDesc")}
      actionTxt={t("newClientOverview.endSubscription")}
      handleClose={handleClose}
      onConfirm={handleUnsubscribeClient}
      isSubmitting={isSubmitting}
    />
  );
}

function mapStateToProps(state: AppState) {
  return {
    clientId: state.currentClient.id,
    isSubmitting: state.currentClient.subscriptionRequestState === RequestState.PENDING,
  };
}

const mapDispatchToProps = {
  dispatchUnsubscribe: unsubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeClientContainer);
