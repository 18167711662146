// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ThemeSettings from "./components/_MUI/settings";
import { ProgressBarStyle } from "./components/_MUI/ProgressBar";
import MotionLazyContainer from "./components/_MUI/animate/MotionLazyContainer";
import { Provider } from "react-redux";
import store from "./store";
import { initializeHttp } from "./helpers/axios";
import { initTranslator } from "./i18n/helpers/translator";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import NotificationBar from "./components/Notification";
import VersionProvider from "./providers/VersionProvider";
import UnreadMessagesProvider from "./providers/UnreadMessagesProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Tracking from "./Tracking";
import ApolloClientProvider from "./providers/ApolloClientProvider";

// TODO: hack to add AUTH headers to axios later make a nicer wrapper
initializeHttp();
initTranslator();

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
      networkMode: "always",
    },
  },
});

export default function App() {
  return (
    <VersionProvider>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <ProgressBarStyle />
            <Provider
              // @ts-ignore
              store={store}>
              <ApolloClientProvider>
                <QueryClientProvider client={queryClient}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <UnreadMessagesProvider>
                      <Router />
                      <Tracking />
                    </UnreadMessagesProvider>
                  </LocalizationProvider>
                  <NotificationBar />
                  <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
              </ApolloClientProvider>
            </Provider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
    </VersionProvider>
  );
}
