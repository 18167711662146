import {
  CLEAR_NOTIFICATION_MESSAGE,
  NotificationTypeEnum,
  notificationActionTypes,
  NotificationState,
  SET_NOTIFICATION_MESSAGE,
} from "./reducerTypes";

const INITIAL_NOTIFICATION_STATE: NotificationState = {
  open: false,
  message: "",
  type: NotificationTypeEnum.INFO,
};

export default function notificationReducer(
  state = INITIAL_NOTIFICATION_STATE,
  action: notificationActionTypes,
) {
  switch (action.type) {
    case SET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        open: true,
        type: action.payload.type,
        message: action.payload.message,
      };
    case CLEAR_NOTIFICATION_MESSAGE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
