import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { LocaleEnum, TransferProgressEnum } from "../../generated/graphql";
import i18next from "i18next";
import { getToken, Tokens } from "../../api/tokens";
import useGetStartedData, {
  GetStartedData,
  GetStartedDataInitialState,
} from "./hooks/useGetStartedData";
import {
  getLocalStorage,
  LocalStorageKeysEnum,
  removeLocalStorage,
  setLocalStorage,
} from "../../helpers/localStorage";

export type LenusTransferContextType = {
  locale: LocaleEnum;
  loggedIn: boolean;
  getStartedData: GetStartedData;
  loading: boolean;
  transferInProgress: TransferProgressEnum;
  lenusToken: string | undefined;
  emailSubject: string | undefined;
  emailContent: string | undefined;
  handleLocale: (locale: LocaleEnum) => void;
  setLoggedIn: (loggedIn: boolean) => void;
  setLenusToken: (token: string | undefined) => void;
  setEmailSubject: (subject: string | undefined) => void;
  setEmailContent: (content: string | undefined) => void;
  refetchMe: () => void;
};

const initialState: LenusTransferContextType = {
  locale: LocaleEnum.EnUs,
  loggedIn: false,
  loading: false,
  transferInProgress: TransferProgressEnum.NotStarted,
  getStartedData: GetStartedDataInitialState,
  lenusToken: undefined,
  emailSubject: undefined,
  emailContent: undefined,
  handleLocale: () => {},
  setLoggedIn: () => {},
  setLenusToken: () => {},
  setEmailSubject: () => {},
  setEmailContent: () => {},
  refetchMe: () => {},
};

const LenusTransferContext = createContext(initialState);

interface Props {
  children: ReactNode[] | ReactNode;
}

function LenusTransferProvider(props: Props) {
  const { children } = props;
  const [locale, setLocale] = useState(initialState.locale);
  const [loggedIn, setLoggedIn] = useState(false);
  const [lenusToken, setLenusToken] = useState<string | undefined>(
    getLocalStorage(LocalStorageKeysEnum.LenusCoachToken),
  );
  const [emailSubject, setEmailSubject] = useState<string | undefined>(undefined);
  const [emailContent, setEmailContent] = useState<string | undefined>(undefined);

  const { getStartedData, refetch } = useGetStartedData();
  const token = getToken(Tokens.BEARER_TOKEN);
  const {
    locale: userLocal,
    loading,
    transferInProgress,
    lastPaymentFailed,
  } = getStartedData;

  useEffect(() => setLoggedIn(Boolean(token)), [token]);
  useEffect(() => handleLocale(userLocal), [userLocal]);

  useEffect(() => {
    if (lastPaymentFailed) {
      setLenusToken(undefined);
      removeLocalStorage(LocalStorageKeysEnum.LenusCoachToken);
    }
  }, [lastPaymentFailed]);

  const handleLocale = (locale: LocaleEnum) => {
    setLocale(locale);
    i18next.changeLanguage(locale);
  };

  const handleLenusToken = (token: string | undefined) => {
    setLenusToken(token);
    token && setLocalStorage(LocalStorageKeysEnum.LenusCoachToken, token);
  };

  return (
    <LenusTransferContext.Provider
      value={{
        locale,
        lenusToken,
        loggedIn,
        transferInProgress,
        getStartedData,
        loading,
        emailSubject,
        emailContent,
        handleLocale,
        setLenusToken: handleLenusToken,
        setLoggedIn,
        setEmailSubject,
        setEmailContent,
        refetchMe: refetch,
      }}>
      {children}
    </LenusTransferContext.Provider>
  );
}

const useLenusTransferContext = () => useContext(LenusTransferContext);

export { useLenusTransferContext, LenusTransferProvider, LenusTransferContext };
