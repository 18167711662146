import React, { useCallback } from "react";
import { Stack, Button, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { DialogContext, RecipeModalType } from "../Dialog/DialogProvider";
import { TranslatorNS } from "../../../i18n/const";
import { Box } from "@mui/system";
import {
  GetRecipeDocument,
  LocaleEnum,
  RecipeType,
  usePresignedRequestMutation,
  useUpdateRecipeImageMutation,
  useUpdateRecipeMutation,
} from "../../../generated/graphql";
import TitleField from "../TitleField";
import UploadSimpleImage from "../../../components/_MUI/upload/UploadSimpleImage";
import axios from "axios";
import { getAllergyTranslationKeyList, getPreferenceTranslationKeyList } from "../utils";
import Label from "../../../components/_MUI/Label";
interface Props {
  id: number;
  name?: RecipeType["name"];
  image: RecipeType["image"];
  macroSplit: string;
  locale: LocaleEnum;
  meta?: RecipeType["meta"];
}
export default function HeaderContainer({
  id,
  name,
  image,
  macroSplit,
  locale,
  meta,
}: Props) {
  return (
    <Header
      id={id}
      name={name as string}
      image={image}
      macroSplit={macroSplit}
      locale={locale}
      meta={meta}
    />
  );
}

function Header(props: Props) {
  const { t } = useTranslation([TranslatorNS.PLANS]);
  const { setModal } = React.useContext(DialogContext);
  const [name, setName] = React.useState<string>(props.name || "");
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [updateRecipe] = useUpdateRecipeMutation();
  const [updateRecipeImage] = useUpdateRecipeImageMutation();
  const [presignRequest] = usePresignedRequestMutation();
  const { enqueueSnackbar } = useSnackbar();

  const openEditDialog = () =>
    setModal({
      type: RecipeModalType.EDIT_RECIPE,
      context: { id: props.id },
    });

  const handleDropAvatar = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      try {
        setUploadingImage(true);
        const response = await presignRequest({
          variables: {
            contentType: file.type,
          },
        });
        if (!response.data?.presignedRequest?.s3Url) {
          throw new Error("No presigned url");
        }

        const res = await axios.put(response.data.presignedRequest.url, file, {
          transformRequest: (data, headers) => {
            // @ts-ignore
            delete headers.common["Authorization"];
            return data;
          },
        });

        if (res?.status !== 200) {
          throw new Error("Failed to upload image");
        }

        await updateRecipeImage({
          variables: {
            recipeId: Number(props.id),
            input: {
              image: response.data.presignedRequest.s3Url,
            },
          },
          refetchQueries: () => [
            {
              query: GetRecipeDocument,
              variables: { id: Number(props.id), locale: props.locale },
            },
          ],
        });

        enqueueSnackbar(t("Recipe image updated"), { variant: "success" });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(t("Recipe image failed to update"), { variant: "error" });
      } finally {
        setUploadingImage(false);
      }
    }
  }, []);

  const saveName = () => {
    try {
      if (!props.id) {
        throw new Error("Recipe id is not defined");
      }
      console.log({ name, initNam: props.name });

      // if no change in name, don't update
      if (name === props.name) return;
      updateRecipe({
        variables: {
          id: Number(props.id),
          input: {
            name,
            locale: props.locale,
          },
        },
        refetchQueries: () => [
          {
            query: GetRecipeDocument,
            variables: { id: Number(props.id), locale: props.locale },
          },
        ],
      });
      enqueueSnackbar(t("Recipe name updated"), { variant: "success" });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("Recipe name failed to update"), { variant: "success" });
    }
  };

  const allergies = getAllergyTranslationKeyList(props.meta);
  const preferences = getPreferenceTranslationKeyList(props.meta);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 2,
        borderBottom: 1,
        borderColor: "divider",
      }}>
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }} spacing={2}>
        <UploadSimpleImage
          photoText={t("client.activation.bodyPictures.uploadProfile")}
          updatePhotoText={t("client.activation.bodyPictures.updatePhoto")}
          accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
          file={props.image || undefined}
          onDrop={handleDropAvatar}
          loading={uploadingImage}
        />
        <Stack id={"recipe-title"} width="100%">
          <TitleField
            sx={{ ml: -1, mt: -1, maxWidth: "50%" }}
            value={name}
            size="small"
            placeholder={t("index.nameOfRecipe")}
            onChange={e => setName(e.target.value)}
            onBlur={saveName}>
            {name || t("index.nameOfRecipe")}
          </TitleField>

          <Stack pl={0.9} direction="row" spacing={1} alignItems="center">
            <Typography variant={"body2"} color={"text.secondary"} sx={{ mr: -0.5 }}>
              {t("plan.macroTarget")}:
            </Typography>
            <Typography variant={"body2"}>{props.macroSplit}</Typography>

            {preferences.map(p => (
              <Label color={"success"} key={p}>
                {t(p, { ns: TranslatorNS.LISTS })}
              </Label>
            ))}
            {allergies.map(p => (
              <Label color={"info"} key={p}>
                {t(p, { ns: TranslatorNS.LISTS })}
              </Label>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box>
          <Button variant="contained" size="large" fullWidth onClick={openEditDialog}>
            {t("index.edit")}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
