import { useEffect, useState } from "react";
import axios from "axios";
import { getExercisesUrl } from "../../../api/private/workout-api";
import { ExerciseListState } from "./ExercisesList";
import { WorkoutPlan } from "../types";

function formatExerciseData(data: any[]) {
  return data.map((e: any) => ({
    ...e,
    title: e.name,
    subTitle: [e?.muscleGroup?.name, e?.equipment?.name, e?.exerciseType?.name]
      .filter(Boolean)
      .join(" - "),
    image: e.picture_url,
    video: e.video_url,
  }));
}

export default function useExercisesList({
  searchText,
  page,
  limit,
  muscleGroupId,
  equipmentId,
  useOwnExercisesOnly,
  updateExerciseList,
}: Omit<ExerciseListState, "exercises" | "moreToLoad" | "loading"> & {
  updateExerciseList: (exercises: WorkoutPlan[]) => void;
}) {
  useEffect(() => {
    const getExercises = async () => {
      try {
        const response = await axios.get(
          getExercisesUrl({
            searchText,
            page,
            limit,
            muscleGroupId,
            equipmentId,
            useOwnExercisesOnly,
          }),
        );
        updateExerciseList(formatExerciseData(response.data));
      } catch (error) {
        console.log({ error });
      }
    };

    getExercises();
  }, [page, searchText, limit, muscleGroupId, equipmentId, useOwnExercisesOnly]);
}
