import axios from "axios";
import {
  ADD_CLIENT_VIDEO,
  DELETE_CLIENT_VIDEO,
  DELETE_VIDEOS,
  GET_CLIENT_VIDEOS,
  GET_VIDEOS,
} from "../../../api/private/videos";
import { GET_CLIENT_VIDEOS_LIMIT } from "./const";
import {
  IClientVideosQueryParams,
  IClientVideo,
  IVideo,
  IClientVideoMutationsParams,
} from "./interfaces";

export async function getClientVideos(params: IClientVideosQueryParams) {
  const [generalVideos, clientVideos] = await Promise.all([
    axios.get(GET_VIDEOS()),
    axios.get(GET_CLIENT_VIDEOS(params.clientId)),
  ]);

  const clientVideosArr = clientVideos?.data;
  const generalVideosArr = generalVideos?.data;

  const data = generalVideosArr?.map((video: IVideo): IClientVideo => {
    return {
      ...video,
      clientId: params.clientId,
      visibility: clientVideosArr.some((cVideo: IVideo) => cVideo.id === video.id),
    };
  });

  return {
    data,
    nextCursor: data.length >= GET_CLIENT_VIDEOS_LIMIT,
  };
}

export async function hideClientVideo(params: IClientVideoMutationsParams) {
  const { clientId, videoId } = params;
  const { data } = await axios.delete(DELETE_CLIENT_VIDEO(clientId, videoId));
  return data; // backend only returns "OK"
}

export async function showClientVideo(params: IClientVideoMutationsParams) {
  const { clientId, videoId } = params;
  const { data } = await axios.post(ADD_CLIENT_VIDEO(clientId, videoId));
  return data; // backend returns IVideo
}

export async function deleteClientVideo(params: IClientVideoMutationsParams) {
  const { videoId } = params;
  const { data } = await axios.delete(DELETE_VIDEOS(videoId));
  return data; // backend only returns "OK"
}
