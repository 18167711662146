import { useTranslation } from "react-i18next";
import { PaymentTypeEnum } from "../../../../generated/graphql";
import { useTheme } from "@mui/material/styles";
import { TranslatorNS } from "../../../../i18n/const";

export const useGetPaymentTypeColorTitle = (
  type?: PaymentTypeEnum,
): { color: string; title: string } => {
  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  switch (type) {
    case PaymentTypeEnum.ChargeSucceeded:
    case PaymentTypeEnum.PaymentSucceeded:
      return {
        color: palette.success.dark,
        title: t("clientPayment.paid"),
      };
    case PaymentTypeEnum.PaymentFailed:
      return {
        color: palette.error.dark,
        title: t("clientPayment.failed"),
      };
    case PaymentTypeEnum.SubscriptionCanceled:
      return {
        color: palette.error.dark,
        title: t("clientPayment.cancelled"),
      };
    case PaymentTypeEnum.ChargeRefunded:
      return {
        color: palette.primary.dark,
        title: t("clientPayment.refunded"),
      };
    case PaymentTypeEnum.SubscriptionPaymentWaiting:
      return {
        color: palette.primary.dark,
        title: t("clientPayment.pending"),
      };
    case PaymentTypeEnum.ChargeDispute:
      return {
        color: palette.error.darker,
        title: t("clientPayment.disputed"),
      };
    default:
      return {
        color: palette.grey[600],
        title: "-",
      };
  }
};
