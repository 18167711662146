import CardContainer from "../../../containers/CardContainer/CardContainer";
import { useParams } from "react-router-dom";
import { useGetClientGoalQuery } from "../../../generated/graphql";
import { useState } from "react";
import EditGoalModalContainer from "./components/EditGoalModal";
import GoalTitle from "./components/GoalTitle";
import GoalContent from "./GoalContent";
import { Button, Stack, Typography } from "@mui/material";
import WeeklyAverages, { getStepHabitGoal } from "./components/WeeklyAverages";
import MissingHealth from "./components/MissingHealth";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import MissingGoal from "./components/MissingGoal";
import Iconify from "../../../components/_MUI/Iconify";

export default function Goal({ hideStats }: { hideStats?: boolean }) {
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [openModal, setOpenModal] = useState(false);

  const { data, loading, error } = useGetClientGoalQuery({
    variables: {
      id: Number(clientId),
    },
  });
  const client = data?.client;

  const handleModal = (toggle: boolean) => setOpenModal(toggle);

  const habitString = client?.integrations?.healthConnected
    ? "newClientOverview.weeklyAvg"
    : "newClientOverview.mainGoal";

  const { stepGoal } = getStepHabitGoal(client?.habits);

  return (
    <CardContainer
      title={<GoalTitle client={client} />}
      error={error}
      loading={loading}
      icon={
        <EditGoalModalContainer
          client={client}
          openModal={openModal}
          handleModal={handleModal}
        />
      }
      sx={{ height: 534 }}
      skeletonHeight={650}>
      {client && client?.weightGoal ? (
        <GoalContent hideStats={hideStats} client={client} />
      ) : (
        <MissingGoal openModal={() => handleModal(true)} />
      )}

      <Stack pb={1}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Typography fontSize={12} sx={{ color: "grey.600" }}>
            {t(habitString)}
          </Typography>
          {!stepGoal && (
            <Button
              onClick={() => setOpenModal(true)}
              variant={"text"}
              size={"small"}
              endIcon={
                <Iconify
                  width={12}
                  height={12}
                  icon="material-symbols:arrow-forward-ios-rounded"
                />
              }
              sx={{ fontSize: 12, fontWeight: 600 }}>
              {t("newClientOverview.addMissingStepGoal")}
            </Button>
          )}
        </Stack>
        {client?.integrations?.healthConnected ? (
          <WeeklyAverages steps={client?.steps} habits={client.habits} />
        ) : (
          <MissingHealth />
        )}
      </Stack>
    </CardContainer>
  );
}
