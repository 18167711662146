import { ReactNode } from "react";
import { Skeleton, Theme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { SkeletonPropsVariantOverrides } from "@mui/material/Skeleton/Skeleton";
import { SxProps } from "@mui/system";

interface Props {
  sx?: SxProps<Theme>;
  children: ReactNode;
  loading: boolean;
  width?: number | string;
  height?: number | string;
  variant?: OverridableStringUnion<
    "text" | "rectangular" | "circular",
    SkeletonPropsVariantOverrides
  >;
}

export default function SkeletonContainer(props: Props) {
  const {
    sx = {},
    children,
    loading = false,
    width,
    height,
    variant = "rectangular",
  } = props;

  if (!loading) {
    return <>{children}</>;
  }

  return <Skeleton variant={variant} width={width} height={height} sx={sx} />;
}
