import {
  add,
  differenceInWeeks,
  endOfISOWeek,
  format,
  isFuture,
  sub,
  isBefore,
  startOfDay,
  endOfDay,
  startOfISOWeek,
} from "date-fns";
import { Stack, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../../../components/_MUI/Iconify";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { ClientType } from "../../../../generated/graphql";
import SkeletonContainer from "../../../../containers/SkeletonContainer";

interface WeekProps {
  loading: boolean;
  endDate: ClientType["endDate"] | undefined | null;
  startDate: ClientType["startDate"] | undefined | null;
  filters: { to: Date; from: Date };
  setFilters: Dispatch<SetStateAction<{ to: Date; from: Date }>>;
  disabledNavigation: boolean;
}

export default function WeekPicker(props: WeekProps) {
  const { filters, setFilters, disabledNavigation, endDate, startDate, loading } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const totalWeeks =
    startDate && endDate && differenceInWeeks(new Date(endDate), new Date(startDate));

  const currentWeek =
    filters.from &&
    startDate &&
    differenceInWeeks(new Date(filters.from), new Date(startDate), {
      roundingMethod: "ceil",
    });

  const previousWeekStartOfDate = startOfDay(sub(filters.from, { weeks: 1 }));
  const nextWeekStartOfDate = endOfDay(add(filters.from, { weeks: 1 }));

  const prevTooltipWeek =
    startDate &&
    differenceInWeeks(new Date(previousWeekStartOfDate), new Date(startDate), {
      roundingMethod: "ceil",
    });

  const nextTooltipWeek =
    startDate &&
    differenceInWeeks(new Date(nextWeekStartOfDate), new Date(startDate), {
      roundingMethod: "ceil",
    });

  const nextDisabled = isFuture(nextWeekStartOfDate);
  // Only disable previous week if the start date is before the previous week
  // We allow a week 0 to be selected, since it's the first week
  const prevDisabled =
    startDate &&
    isBefore(previousWeekStartOfDate, startOfISOWeek(startOfDay(new Date(startDate))));

  const handleNextWeek = () => {
    setFilters({
      from: nextWeekStartOfDate,
      to: endOfISOWeek(nextWeekStartOfDate),
    });
  };

  const handlePreviousWeek = () => {
    setFilters({
      from: previousWeekStartOfDate,
      to: endOfISOWeek(previousWeekStartOfDate),
    });
  };

  return (
    <Stack flexDirection={"row"} alignItems={"center"}>
      <Tooltip
        title={
          !Boolean(prevDisabled) && prevTooltipWeek
            ? t("helpers.weekDiff", { diff: prevTooltipWeek })
            : ""
        }>
        <Stack>
          <IconButton
            disabled={disabledNavigation || Boolean(prevDisabled)}
            onClick={handlePreviousWeek}>
            <Iconify
              width={20}
              height={20}
              color={disabledNavigation || Boolean(prevDisabled) ? "inherit" : "black"}
              icon={"material-symbols:arrow-back-ios-new-rounded"}
            />
          </IconButton>
        </Stack>
      </Tooltip>
      <Tooltip
        title={`${format(filters.from, "dd/MM/yyyy")} - ${format(
          filters.to,
          "dd/MM/yyyy",
        )}`}>
        <Stack
          justifyContent={"center"}
          flexDirection={"row"}
          width={130}
          alignItems={"center"}>
          <SkeletonContainer loading={loading} height={27} width={"100%"}>
            <Typography
              sx={{
                color: "black",
                fontSize: 16,
                cursor: "default",
                textAlign: "center",
              }}>
              {t("newClientOverview.generalCard.week", {
                currentWeekCount: currentWeek,
                totalWeeks: totalWeeks || "",
              })}
              {!totalWeeks && (
                <Iconify sx={{ fontSize: 16, mb: "-3px" }} icon={"mdi:infinity"} />
              )}
            </Typography>
          </SkeletonContainer>
        </Stack>
      </Tooltip>
      <Tooltip
        title={nextTooltipWeek ? t("helpers.weekDiff", { diff: nextTooltipWeek }) : ""}>
        <Stack>
          <IconButton
            disabled={disabledNavigation || Boolean(nextDisabled)}
            onClick={handleNextWeek}>
            <Iconify
              width={20}
              height={20}
              color={disabledNavigation || nextDisabled ? "inherit" : "black"}
              icon={"material-symbols:arrow-forward-ios-rounded"}
            />
          </IconButton>
        </Stack>
      </Tooltip>
    </Stack>
  );
}
