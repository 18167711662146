import { Button, DialogContent, Stack } from "@mui/material";
import React, { Fragment } from "react";
import DialogActions from "@mui/material/DialogActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { FormProvider, RHFTextField } from "../../../_MUI/hook-form";
import { TMealTemplateForm } from "./MealTemplateContainer";
import { ModalCrudTypeEnum } from "../../../../store/modals/reducerTypes";

interface Props {
  defaultValues: TMealTemplateForm<ModalCrudTypeEnum>;
  onClose: () => void;
  submit: (data: TMealTemplateForm<ModalCrudTypeEnum>) => void;
  loading: boolean;
  type: ModalCrudTypeEnum;
}

const newMealTemplate = Yup.object().shape({
  clientId: Yup.number().nullable(),
  title: Yup.string().required(),
  description: Yup.string().nullable(),
});

export default function MealTemplateForm(props: Props) {
  const { defaultValues, onClose, submit, loading, type } = props;
  const { t } = useTranslation(TranslatorNS.LISTS);

  const methods = useForm<TMealTemplateForm<ModalCrudTypeEnum>>({
    resolver: yupResolver(newMealTemplate),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const buttonTitle = getButtonString(type);

  return (
    <Fragment>
      <DialogContent>
        <Stack my={2}>
          <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <RHFTextField name={"title"} label={t("meals.main.title")} />
            <RHFTextField
              name="description"
              label={t("meals.const.tableTitle.comment")}
              multiline
              rows={4}
            />
          </FormProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t("common.close")}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(submit)}
          loading={loading}>
          {t(buttonTitle)}
        </LoadingButton>
      </DialogActions>
    </Fragment>
  );
}

const getButtonString = (type: ModalCrudTypeEnum) => {
  switch (type) {
    case ModalCrudTypeEnum.Update:
      return "shared.helpers.update";
    case ModalCrudTypeEnum.Duplicate:
      return "shared.helpers.duplicate";
    default:
      return "shared.helpers.create";
  }
};
