import * as Yup from "yup";

export default function useYupClientSettings() {
  return Yup.object().shape({
    name: Yup.string().required(),
    locale: Yup.string().nullable(),
    email: Yup.string().email().required(),
    birthday: Yup.date().nullable(),
    startDate: Yup.date().nullable(),
    endDate: Yup.date().nullable(),
    allergies: Yup.array().nullable(),
    foodPreferences: Yup.string().nullable(),
    startWeight: Yup.number().nullable(),
    height: Yup.number().nullable(),
    checkInDay: Yup.number().nullable(),
  });
}
