import { Grid } from "@mui/material";
import Goal from "./Goal";
import Tasks from "./Tasks";
import SubscriptionContainer from "./Subscription";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import GeneralContainer from "./General";
import DemoPreviewApp from "./DemoPreviewApp";
import Workouts from "./Workouts";

export default function ClientOverview() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        maxWidth={1400}
        sx={{ mb: 8 }}
        paddingBottom={8}
        columnSpacing={{ xs: 2, sm: 4 }}
        rowSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sx={{ p: "0px !important" }}>
          <DemoPreviewApp />
        </Grid>
        <Grid item xs={12} id="subscription-grid">
          <SubscriptionContainer />
        </Grid>
        <Grid item xs={12} sm={4} id="general-grid">
          <GeneralContainer />
        </Grid>
        <Grid item xs={12} sm={4} id="goal-grid">
          <Goal />
        </Grid>
        <Grid item xs={12} sm={4} id="tasks-grid">
          <Tasks />
        </Grid>
        <Grid item xs={12} sm={6} id="workouts-grid">
          <Workouts />
        </Grid>
        {/*
        <Grid item xs={12} sm={6} id="meals-grid">
          <Meals />
        </Grid>
        */}
      </Grid>
    </LocalizationProvider>
  );
}
