import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import RHFDesktopDatePicker from "../../../../_MUI/hook-form/RHFDesktopDatePicker";
import RHFSelect from "../../../../_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import {
  ClientPalEnum,
  GenderEnum,
  MeasuringSystemEnum,
} from "../../../../../generated/graphql";
import { useFormContext } from "react-hook-form";
import { RHFTextField } from "../../../../_MUI/hook-form";
import { getCurrentAgeDateFns } from "../../../../../helpers/dates";
import {
  GetCMFromFeetInches,
  GetFeetInchesFromCM,
  lbsToKgFactor,
  roundToTwo,
} from "../../../../../helpers/measurementHelper";
import isValid from "date-fns/isValid";

export default function BasicFields() {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  const watchAge = watch("age");
  const watchBirthDay = watch("birthday");
  const watchMeasuringSystem = watch("measuringSystem");
  const watchStartWeight = watch("startWeight");
  const watchFeet = watch("feet");
  const watchInches = watch("inches");
  const watchHeight = watch("height");

  const [age, setAge] = useState<number | null>(null);

  useEffect(() => {
    if (watchBirthDay && isValid(watchBirthDay)) {
      const ageYears = getCurrentAgeDateFns(watchBirthDay);
      setAge(ageYears);
    }
  }, [watchBirthDay]);

  const handleMeasureSystem = (value: MeasuringSystemEnum) => {
    setValue("measuringSystem", value);
    if (value == MeasuringSystemEnum.Imperial) {
      setValue(
        "startWeight",
        Number(roundToTwo(Number(watchStartWeight) * lbsToKgFactor)),
      );
      setValue("feet", GetFeetInchesFromCM(Number(watchHeight)).feet);
      setValue("inches", GetFeetInchesFromCM(Number(watchHeight)).inches);
    } else {
      setValue(
        "startWeight",
        Number(roundToTwo(Number(watchStartWeight) / lbsToKgFactor)),
      );
      setValue("height", GetCMFromFeetInches(Number(watchFeet), Number(watchInches)));
    }
  };

  return (
    <Stack mb={3}>
      <Typography variant={"subtitle2"} mb={2}>
        {t("info.clientFields.basicInfo")}
      </Typography>
      <RHFSelect label={t("const.clientInfoFields.gender")} name={"gender"}>
        {Object.values(GenderEnum).map(value => (
          <MenuItem key={value} value={value}>
            {t(`newClientOverview.gender.${value}`)}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFDesktopDatePicker
        name={"birthday"}
        label={t("const.clientInfoFields.birthday")}
      />
      <RHFTextField name="age" value={age || watchAge || ""} disabled label="Age" />
      <RHFSelect
        label={t("const.clientInfoFields.measuringSystem")}
        onChange={({ target }) =>
          handleMeasureSystem(target.value as MeasuringSystemEnum)
        }
        name={"measuringSystem"}>
        {Object.values(MeasuringSystemEnum).map(value => (
          <MenuItem key={value} value={value}>
            {t(`const.measuringSystem.${value.toLowerCase()}`)}
          </MenuItem>
        ))}
      </RHFSelect>
      <RHFTextField
        name="startWeight"
        label={t("const.clientInfoFields.startWeight")}
        type={"number"}
        InputProps={{
          endAdornment: (
            <Typography sx={{ opacity: 0.5 }}>
              {watchMeasuringSystem === MeasuringSystemEnum.Metric ? "kg" : "lbs"}
            </Typography>
          ),
        }}
      />
      {watchMeasuringSystem === MeasuringSystemEnum.Metric ? (
        <RHFTextField
          name="height"
          label={t("const.clientInfoFields.height")}
          type={"number"}
          InputProps={{
            endAdornment: <Typography sx={{ opacity: 0.5 }}>cm</Typography>,
          }}
        />
      ) : (
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <RHFTextField
            name="feet"
            sx={{ width: "48%" }}
            label={t("const.clientInfoFields.feet")}
            type={"number"}
          />
          <RHFTextField
            name="inches"
            sx={{ width: "48%" }}
            label={t("const.clientInfoFields.inches")}
            type={"number"}
          />
        </Stack>
      )}
      <RHFSelect label={t("const.clientInfoFields.activityLevel")} name={"pal"}>
        {Object.values(ClientPalEnum)
          .reverse()
          .map(value => (
            <MenuItem key={value} value={value}>
              {t(`newClientOverview.generalCard.pal.${value.toLowerCase()}`)}
            </MenuItem>
          ))}
      </RHFSelect>
    </Stack>
  );
}
