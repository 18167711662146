import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PictureComponent from "../PictureComponent/PictureComponent";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
import { AppState } from "../../../../store";
import { ActivationStepsEnum, IGeneral, IPhotos } from "../../type";
import { saveFieldsAction } from "../../store/action";
import { INITIAL_PHOTOS } from "../../store/initialStates";
import { IClientActivationState } from "../../store/types";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { track } from "../../../../helpers/analytics";

interface Props {
  currentStep: IClientActivationState["step"];
  saveFieldsActionDispatch: (
    photos: IPhotos,
    nextStep: IClientActivationState["step"],
    currentStepName: ActivationStepsEnum,
  ) => void;
  picturesState: IPhotos;
  gender: IGeneral["gender"];
}

const Photos = ({
  currentStep,
  saveFieldsActionDispatch,
  picturesState,
  gender,
}: Props) => {
  const { t } = useTranslation("messages");
  const [photos, setPhotos] = useState(INITIAL_PHOTOS);

  useEffect(() => {
    setPhotos({
      ...photos,
      front: picturesState.front,
      back: picturesState.back,
      side: picturesState.side,
    });
  }, [picturesState]);

  const handleChange = (name: keyof typeof photos, value: string | number | boolean) => {
    if (!!value) {
      track("Photo Added", "Client Activation");
    } else {
      track("Photo Removed", "Client Activation");
    }
    setPhotos({ ...photos, [name]: value });
  };

  const handleSave = () => {
    saveFieldsActionDispatch(photos, currentStep + 1, ActivationStepsEnum.PHOTOS);
  };

  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <Stack flexDirection={{ xs: "column", sm: "row" }}>
        <PictureComponent
          title={t("client.activation.bodyPictures.front")}
          value={photos.front}
          type={"front"}
          gender={gender}
          onChange={(val: any) => handleChange("front", val)}
          onRemove={(val: any) => handleChange("front", val)}
        />
        <PictureComponent
          title={t("client.activation.bodyPictures.back")}
          value={photos.back}
          type={"back"}
          gender={gender}
          onChange={(val: any) => handleChange("back", val)}
          onRemove={(val: any) => handleChange("back", val)}
        />
        <PictureComponent
          title={t("client.activation.bodyPictures.side")}
          value={photos.side}
          type={"side"}
          gender={gender}
          onChange={(val: any) => handleChange("side", val)}
          onRemove={(val: any) => handleChange("side", val)}
        />
      </Stack>

      <Button
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_3}
        sx={{ width: { xs: "100%", sm: 450 } }}
        variant="contained"
        size="large"
        onClick={() => handleSave()}>
        {t("client.activation.next")}
      </Button>
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    picturesState: clientActivation.photos,
    currentStep: clientActivation.step,
    gender: clientActivation.general.gender,
  };
}

const mapDispatchToProps = {
  saveFieldsActionDispatch: saveFieldsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Photos);
