/**
 * TODO: when splitting project make this reducer shared across the app
 */

import {
  ModalActionTypes,
  ModalState,
  SET_EDIT_WORKOUT_PLAN_MODAL,
  SET_MANUAL_PLAN_MODAL,
} from "./types";

export const INITIAL_STATE: ModalState = {
  manualPlanModalVisible: false,
  generatedPlanModalVisible: false,
  editWorkoutPlan: null,
};

export default function Reducer(
  state = INITIAL_STATE,
  action: ModalActionTypes,
): ModalState {
  switch (action.type) {
    case SET_MANUAL_PLAN_MODAL: {
      return {
        ...state,
        manualPlanModalVisible: action.payload,
      };
    }
    case SET_EDIT_WORKOUT_PLAN_MODAL: {
      return {
        ...state,
        editWorkoutPlan: action.payload,
      };
    }
    default:
      return state;
  }
}
