import {
  ClientPaymentConfigurationStatusEnum,
  GetClientSubscriptionQuery,
} from "../../../../generated/graphql";
import { Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import moment from "moment";
import { useGetPaymentStatusColorTitle } from "../hooks/useGetPaymentStatusColorTitle";
import { alpha } from "@mui/material/styles";
import Label from "../../../../components/_MUI/Label";
import { format } from "date-fns";
import Iconify from "../../../../components/_MUI/Iconify";
import { formatDate } from "../../../../helpers/dates";
import { TFunction } from "i18next";

interface Props {
  payment?: GetClientSubscriptionQuery["client"]["paymentConfiguration"];
}

export default function Subscription(props: Props) {
  const { payment } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const currency = (payment?.currency || "").toUpperCase();
  const recurringFee = payment?.recurringFee;
  const upfrontFee = payment?.upfrontFee;
  const status = payment?.status;
  const title = `${currency} ${recurringFee}${t("clientPayment.perMonth")}`;
  const months = payment?.months;
  const createdAt = payment?.createdAt
    ? moment(payment.createdAt).format("DD/MM/YY")
    : "-";

  const pausedUntil = payment?.subscription?.pausedUntil;

  const pausedUntilDate = pausedUntil
    ? formatDate(new Date(pausedUntil), "dd/MM/yyyy")
    : "-";

  const unsubscribedDate = payment?.subscription?.unsubscribedDate;

  const unsubscribedDateFormatted = unsubscribedDate
    ? formatDate(new Date(unsubscribedDate), "dd/MM/yyyy")
    : "-";

  const { color, statusTitle, tooltipString } = useGetPaymentStatusColorTitle(status);

  const isPendingTerms =
    statusTitle !== "newClientOverview.generalCard.paidUpfrontTitle" &&
    status === ClientPaymentConfigurationStatusEnum.Pending;

  const subTitleString = getSubscriptionSubTitle(
    currency,
    months,
    upfrontFee,
    recurringFee,
    t,
  );

  const endDate = payment?.endDate ? format(new Date(payment?.endDate), "dd/MM/yy") : "-";

  const getUpfrontTitle = upfrontFee
    ? t("newClientOverview.generalCard.paidUpfrontTitle", {
        currency,
        upfrontFee,
      })
    : "-";

  return (
    <Stack
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent={"space-between"}
      alignItems={"center"}>
      <Stack flexDirection={"row"} alignItems={"center"} flex={1}>
        <Stack>
          <Iconify icon="uil:usd-circle" color={color} fontSize={24} />
        </Stack>
        <Stack ml={2}>
          <Typography variant={"subtitle2"}>
            {Boolean(recurringFee) ? title : getUpfrontTitle}
          </Typography>
          <Typography fontSize={"12"} color={"GrayText"}>
            {subTitleString}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        flexDirection={"row"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        justifyContent={{ xs: "flex-start", sm: "space-around" }}
        flex={1}>
        <Stack minWidth={60} mr={{ xs: 1, sm: 0 }}>
          <Typography sx={Styles.title}>{t("clientPayment.status")}</Typography>
          <Tooltip
            title={t(tooltipString || "", {
              date: pausedUntilDate,
              unsubDate: unsubscribedDateFormatted,
            })}>
            <Stack>
              <Label variant={"filled"} sx={{ background: alpha(color, 0.16), color }}>
                {t(statusTitle)}
              </Label>
            </Stack>
          </Tooltip>
        </Stack>
        <Stack minWidth={60} mr={{ xs: 1, sm: 0 }}>
          <Typography sx={Styles.title}>{t("clientPayment.created")}</Typography>
          <Typography sx={Styles.subtitle}>{createdAt}</Typography>
        </Stack>
        <Stack minWidth={60} mr={{ xs: 1, sm: 0 }}>
          <Typography sx={Styles.title}>{t("clientPayment.endDate")}</Typography>
          <Tooltip
            title={
              endDate !== "-" && Boolean(recurringFee)
                ? t("newClientOverview.endDateTooltip", { endDate })
                : ""
            }>
            <Typography sx={Styles.subtitle}>{endDate}</Typography>
          </Tooltip>
        </Stack>
        <Tooltip title={payment?.terms || ""} arrow placement={"top"}>
          <Stack>
            <Typography sx={Styles.title}>{t("clientPayment.terms")}</Typography>
            {isPendingTerms ? (
              <Label variant={"filled"} sx={{ background: alpha(color, 0.16), color }}>
                {t(statusTitle)}
              </Label>
            ) : (
              <Stack>
                {payment?.terms ? (
                  <Iconify
                    icon="material-symbols:check-circle-outline-rounded"
                    sx={Styles.iconTerms}
                  />
                ) : (
                  "-"
                )}
              </Stack>
            )}
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

const getSubscriptionSubTitle = (
  currency: string,
  months: number | undefined,
  upfrontFee: number | undefined,
  recurringFee: number | undefined,
  t: TFunction,
) => {
  const untilUnsubscribes = months === 13;
  const onlyUpfrontFee = !!upfrontFee && !recurringFee;
  const onlyRecurringFee = !upfrontFee && !!recurringFee;

  if (untilUnsubscribes && onlyRecurringFee) {
    return t("clientPayment.untilClientUnsubsRecurringFee", {
      recurringFee,
      currency,
    });
  }

  if (untilUnsubscribes && onlyUpfrontFee) {
    return t("clientPayment.untilClientUnsubsUpfront", {
      upfrontFee,
      currency,
    });
  }

  if (untilUnsubscribes) {
    return t("clientPayment.untilClientUnsubsUpfrontFeeRecurringFee", {
      recurringFee,
      upfrontFee,
      months,
      currency,
    });
  }

  if (onlyRecurringFee) {
    return t("clientPayment.subscriptionRecurringFee", {
      recurringFee,
      currency,
      months,
    });
  }

  if (onlyUpfrontFee) {
    return t("clientPayment.subscriptionUpfrontFee", {
      upfrontFee,
      currency,
    });
  }

  return t("clientPayment.subscriptionUpfrontFeeRecurringFee", {
    recurringFee,
    upfrontFee,
    months,
    currency,
  });
};

const Styles = {
  title: {
    fontSize: 12,
    color: "text.secondary",
  },
  subtitle: {
    fontSize: 12,
    color: "text.secondary",
    fontWeight: "bold",
    mt: 0.5,
  },
  iconTerms: {
    color: "success.dark",
    fontSize: 20,
  },
};
