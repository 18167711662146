import styled from "styled-components";
import { Colors, Fonts } from "../../styleguide";

export const ZFCheckboxStyled = styled.div<{ iconSize: number }>`
  display: flex;
  align-items: normal;

  & > label {
    margin-left: 12px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    ${Fonts.REMOVE_SELECTION};
  }

  .subtitle {
    font-weight: 400;
    font-size: 13px;
    ${Fonts.REMOVE_SELECTION};
  }

  .MuiCheckbox-root {
    align-self: center;
    padding: 0 !important;
    width: ${p => p.iconSize}px;
    height: ${p => p.iconSize}px;
  }

  svg {
    width: ${p => p.iconSize}px;
    height: ${p => p.iconSize}px;
  }

  .MuiSvgIcon-root {
    font-size: 17px;
    border-radius: 4px;
    color: ${({ theme }) => theme.primaryColor || Colors.ACTIVE_COLOR};
  }
`;
