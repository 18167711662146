import {
  useCallback,
  useEffect,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useRef,
} from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import { UilSearch } from "@iconscout/react-unicons";

import { getWorkoutTemplates } from "../../api";
import ZFLoader from "../../../../components/Loader";
import { TranslatorNS } from "../../../../i18n/const";
import TemplateFiltersMenu from "./TemplateFiltersMenu";
import { WorkoutPlan } from "../../../../interfaces/meal";
import TemplateCheckboxItem from "./TemplateCheckboxItem";
import { extractResponseError } from "../../../../helpers/error";
import InfiniteScroll from "../../../../components/InfiniteScroll";

export interface FiltersState {
  q: string;
  workoutsPerWeek: number[];
  levels: number[];
  locations: number[];
  gender: number | undefined;
}

interface ChooseTemplateProps {
  selectedTemplateIds: number[];
  setSelectedTemplatesIds: Dispatch<SetStateAction<number[]>>;
  modalBodyRef?: HTMLDivElement;
}

const INITIAL_OFFSET = 0;
const PAGE_LIMIT = 20;

function ChooseTemplate({
  selectedTemplateIds,
  setSelectedTemplatesIds,
  modalBodyRef,
}: ChooseTemplateProps) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [workoutTemplates, setWorkoutTemplates] = useState<WorkoutPlan[]>([]);
  const [pageOffset, setPageOffset] = useState<number>(INITIAL_OFFSET);
  const [filters, setFilters] = useState<FiltersState>({
    q: "",
    workoutsPerWeek: [],
    levels: [],
    locations: [],
    gender: undefined,
  });

  const theme = useTheme();
  const { t } = useTranslation(TranslatorNS.WORKOUT_PLAN);
  const isFirstRenderRef = useRef<boolean>(true);
  const containerScrollRef = useRef<HTMLDivElement>();

  const getWorkouts = useCallback(async (queryParams: FiltersState) => {
    try {
      setLoading(true);

      const data = await getWorkoutTemplates({
        ...queryParams,
        offset: INITIAL_OFFSET,
        limit: PAGE_LIMIT,
      });

      setWorkoutTemplates(data);
      setHasNext(data.length >= PAGE_LIMIT);
      setPageOffset(INITIAL_OFFSET);
    } catch (error: any) {
      const errorMessages = extractResponseError(error);
      setError(errorMessages);
    } finally {
      setLoading(false);
    }
  }, []);

  const getWorkoutsDebounced = useMemo(
    () => debounce(params => getWorkouts(params), 800),
    [getWorkouts],
  );

  useEffect(() => {
    // Initially get without debounce
    isFirstRenderRef.current ? getWorkouts(filters) : getWorkoutsDebounced(filters);

    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
    }
  }, [filters, getWorkoutsDebounced, getWorkouts]);

  const loadMore = async () => {
    if (loading || workoutTemplates.length < PAGE_LIMIT) {
      return;
    }

    try {
      const newOffset = pageOffset + PAGE_LIMIT;

      const newWorkoutTemplates = await getWorkoutTemplates({
        ...filters,
        offset: newOffset,
        limit: PAGE_LIMIT,
      });

      setWorkoutTemplates([...workoutTemplates, ...newWorkoutTemplates]);
      setPageOffset(newOffset);
      setHasNext(newWorkoutTemplates.length >= PAGE_LIMIT);
    } catch (error) {
      console.error(error, "error");
    }
  };

  const handleFilterChange = (
    filterName: keyof FiltersState,
    filterValue: string | number | number[] | null,
  ) => {
    setFilters({
      ...filters,
      [filterName]: filterValue,
    });
  };

  const onTemplateCheck = (isChecked: boolean, templateId: number) => {
    setSelectedTemplatesIds(
      isChecked
        ? [...selectedTemplateIds, templateId]
        : [...selectedTemplateIds].filter(id => id !== templateId),
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      {loading && <ZFLoader size={60} thickness={2} style={{ marginTop: 20 }} />}
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError("")}>
          {error}
        </Alert>
      )}
      <OutlinedInput
        size="small"
        value={filters.q}
        fullWidth
        onChange={e => {
          handleFilterChange("q", e.target.value);
        }}
        startAdornment={
          <InputAdornment position="start">
            <UilSearch />
          </InputAdornment>
        }
        placeholder={t("createModal.search")}
      />
      <TemplateFiltersMenu filters={filters} handleFilterChange={handleFilterChange} />
      <Typography variant="subtitle1" mb={1} color={theme.palette.grey[600]}>
        {t("createModal.templatesTitle")}
      </Typography>
      <Box
        ref={containerScrollRef}
        sx={{
          py: 2,
          height: { sm: 330 },
          overflow: { sm: "auto" },
        }}>
        {!workoutTemplates.length && !loading && (
          <Typography variant="caption" display="block">
            {t("createModal.noWorkoutTemplates")}
          </Typography>
        )}
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasNext}
          useWindow={false}
          shouldShowDefaultLoader={!loading}
          getScrollParent={() =>
            modalBodyRef ? modalBodyRef : containerScrollRef.current
          }>
          <Stack spacing={2}>
            {workoutTemplates.map(({ id, name, meta }) => (
              <TemplateCheckboxItem
                key={id}
                title={name}
                subtitle={t(
                  meta?.workoutsPerWeek === 1
                    ? "createModal.templatesWorkoutDay"
                    : "createModal.templatesWorkoutDays",
                  {
                    days: meta?.workoutsPerWeek || 0,
                  },
                )}
                isChecked={selectedTemplateIds.includes(id)}
                onCheck={isChecked => onTemplateCheck(isChecked, id)}
              />
            ))}
          </Stack>
        </InfiniteScroll>
      </Box>
    </Box>
  );
}

export default ChooseTemplate;
