import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

type ActionType =
  | "confirm"
  | "error"
  | "info"
  | "danger"
  | "success"
  | "warning"
  | "delete"
  | "ok"
  | "accept";

interface Props {
  title: string;
  body: string | React.ReactElement;
  cancel?: string;
  children?: React.ReactNode;
  action: () => void;
  type: ActionType;
  disableModal?: boolean;
  actionTxt?: string;
}

const ConfirmModal = (props: Props) => {
  const { t } = useTranslation("shared");
  const {
    title,
    body,
    cancel,
    children,
    disableModal = false,
    action,
    type,
    actionTxt,
  } = props;

  const [state, setState] = useState(false);
  const handleDeleteAction = () => {
    disableModal ? action() : setState(true);
  };
  const handleAction = () => {
    setState(false);
    action();
  };

  const FindTypeOfActionColor = (type: ActionType) => {
    switch (type) {
      case "delete":
      case "error":
      case "danger":
        return "error";
      case "warning":
        return "warning";
      case "success":
      case "ok":
      case "accept":
      case "confirm":
        return "primary";
      default:
        return "primary";
    }
  };
  const FindTypeOfActionText = (type: ActionType) => {
    switch (type) {
      case "delete":
        return t("confirmModal.delete");
      case "danger":
        return t("confirmModal.danger");
      case "warning":
        return t("confirmModal.warning");
      case "success":
        return t("confirmModal.success");
      case "ok":
        return t("confirmModal.ok");
      case "accept":
        return t("confirmModal.accept");
      case "confirm":
        return t("confirmModal.confirm");
      default:
        return type;
    }
  };

  return (
    <Fragment>
      <div onClick={handleDeleteAction}>{children}</div>
      <Dialog
        disableScrollLock
        open={state}
        onClose={() => setState(false)}
        //TODO: cleanup so zIndex is not that high usually the range would be [0-10] or [0-100
        style={{ zIndex: 2002 }}>
        <DialogTitle>
          <Stack flexDirection="row">
            <Typography variant={"h5"}>{title}</Typography>
            {type === "delete" && (
              <Typography ml={0.5} variant="h5">
                ?
              </Typography>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ py: 2 }} variant={"body1"}>
            {body}
          </Typography>
          <Stack flexDirection={"row"} justifyContent={"flex-end"} pt={4}>
            <Button
              onClick={handleAction}
              size={"medium"}
              variant={"contained"}
              color={FindTypeOfActionColor(type)}>
              {actionTxt ?? FindTypeOfActionText(type)}
            </Button>
            <Button
              size={"medium"}
              variant={"contained"}
              sx={{ ml: 2 }}
              color={"inherit"}
              onClick={() => setState(false)}>
              {cancel ? cancel : t("confirmModal.cancel")}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmModal;
