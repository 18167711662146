import React, { useEffect, useState } from "react";
import { Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../../../components/_MUI/Iconify";

const Timer = props => {
  const { stopRecord, onStopRecord, onPauseAudio, onPlayAudio, playing } = props;
  const [time, setTime] = useState(0);

  const min = parseInt(time / 60) < 10 ? "0" + parseInt(time / 60) : parseInt(time / 60);
  const sec = time % 60 < 10 ? "0" + (time % 60) : time % 60;
  const actionButton = !stopRecord ? (
    <Iconify icon="uil:microphone" width={18} height={18} />
  ) : playing ? (
    <Iconify icon="uil:pause" width={18} height={18} />
  ) : (
    <Iconify icon="uil:play" width={18} height={18} />
  );

  const action = () => {
    if (!stopRecord) {
      onStopRecord();
    } else {
      if (playing) {
        onPauseAudio();
      } else {
        onPlayAudio();
      }
    }
  };
  useEffect(() => {
    let timer = null;
    if (!stopRecord) {
      timer = window.setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    }
    if (stopRecord && timer) {
      clearTimeout(timer);
    }
    if (time === 300) {
      onStopRecord();
    }
    return () => {
      window.clearTimeout(timer);
    };
  }, [time, stopRecord]);
  return (
    <StyledButton onClick={action}>
      <Stack direction="row" gap="4px" alignItems="center">
        {actionButton}
        <div>
          {min}:{sec}
        </div>
      </Stack>
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "20px",
  padding: "4px 8px",
  minWidth: 0,
  minHeight: 0,
  height: "100%",
  fontSize: "12px",
  lineHeight: "18px",

  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
}));

export default Timer;
