import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export default function MuiInternalUrl({ name, url }: { name: string; url: string }) {
  return (
    <Link style={Styles.link} to={url}>
      <Box component="div" sx={Styles.box}>
        {name}
      </Box>
    </Link>
  );
}

const Styles = {
  box: {
    textOverflow: "ellipsis",
    alignItems: "center",
    overflow: "hidden",
    alignSelf: "center",
    width: "100%",
    "&:hover": { textDecoration: "underline" },
  },
  link: {
    color: "black",
    width: "100%",
    height: "100%",
    display: "flex",
    fontSize: 14,
    fontWeight: "bold",
  },
};
