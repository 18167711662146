import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { Stack, Typography } from "@mui/material";
import NavigationButtons from "../../../../providers/LenusTransferProvider/components/NavigationButtons";

interface Props {
  handleNext: () => void;
}

export const rectangleSx = {
  width: { xs: "100%", sm: "49.5%" },
  height: 54,
  padding: 1,
  borderRadius: 1,
  bgcolor: "#252525",
};

export const circleSx = {
  width: 38,
  height: 38,
  borderRadius: "50%",
  bgcolor: "primary.dark",
  justifyContent: "center",
  alignItems: "center",
  mr: 1,
};

export const rectangleText = {
  color: "white",
  textAlign: "left",
};

export default function LoginIntro(props: Props) {
  const { handleNext } = props;
  const { getStartedData } = useLenusTransferContext();
  const { t } = useTranslation(TranslatorNS.AUTH);

  return (
    <>
      <Stack maxWidth={{ xs: 660 }}>
        <Stack sx={{ textAlign: "center", mb: 5, color: "white" }}>
          <Typography color={"white"} fontSize={"32px"}>
            {t("transfer.theDayHasComeTitle2", {
              name: getStartedData.name || "Mr/Mrs trainer",
            })}
          </Typography>
          <Typography color={"white"} fontSize={"32px"} sx={{ mb: 2 }}>
            {t("transfer.theDayHasComeTitle")}
          </Typography>
          <Typography color={"white"}>{t("transfer.insure")}</Typography>
          <Stack alignItems={"center"} my={3}>
            <Stack
              sx={rectangleSx}
              flexDirection={"row"}
              alignItems={"center"}
              my={0.5}
              justifyContent={"flex-start"}>
              <Stack sx={circleSx}>
                <Typography color={"white"}>1</Typography>
              </Stack>
              <Typography sx={rectangleText}>{t("transfer.loginToLenus")}</Typography>
            </Stack>
            <Stack
              sx={rectangleSx}
              flexDirection={"row"}
              alignItems={"center"}
              my={1}
              justifyContent={"flex-start"}>
              <Stack sx={circleSx}>
                <Typography color={"white"}>2</Typography>
              </Stack>
              <Typography sx={rectangleText}>{t("transfer.createEmail")}</Typography>
            </Stack>
            <Stack
              sx={rectangleSx}
              flexDirection={"row"}
              alignItems={"center"}
              my={1}
              justifyContent={"flex-start"}>
              <Stack sx={circleSx}>
                <Typography color={"white"}>3</Typography>
              </Stack>
              <Typography sx={rectangleText}>{t("transfer.startTransfer")}</Typography>
            </Stack>
          </Stack>
          <Typography color={"grey.500"}>{t("transfer.note")}</Typography>
        </Stack>
      </Stack>
      <NavigationButtons
        buttonTxt={t("transfer.next")}
        goNextTxt={`${t("transfer.next")}: ${t("transfer.loginLenus")}`}
        goNext={handleNext}
      />
    </>
  );
}
