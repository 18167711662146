export const isVideoValid = (file: Blob, maxSizeInMb = 200): string | undefined => {
  const { size } = getVideoSize(file.size);

  if (
    file.type !== "video/mp4" &&
    file.type !== "video/mov" &&
    file.type !== "video/quicktime"
  )
    return "captureVideo.wrongFormat";

  if (size > maxSizeInMb) return `captureVideo.tooBig`;

  return undefined;
};

export const getVideoSize = (bytes: number) => {
  const kb = 1024;
  const ndx = Math.floor(Math.log(bytes) / Math.log(kb));
  const fileSizeTypes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  return {
    size: +(bytes / kb / kb).toFixed(2),
    type: fileSizeTypes[ndx],
  };
};
