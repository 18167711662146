import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { FormProvider, RHFTextField } from "../../../../components/_MUI/hook-form";
import { Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { LenusLoginFormProps } from "./index";
import * as Yup from "yup";
import { useState } from "react";
import axios from "axios";
import { LENUS_LOGIN } from "../../../../api/private/users";
import LenusIcon from "../../../../providers/LenusTransferProvider/components/LenusIcon";
import NavigationButtons from "../../../../providers/LenusTransferProvider/components/NavigationButtons";
import RHFSelect from "../../../../components/_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import { LocalStorageKeysEnum, setLocalStorage } from "../../../../helpers/localStorage";
import { LenusPlatformTypeEnum } from "../../../../generated/graphql";

interface Props {
  defaultValues: LenusLoginFormProps;
  handleBack: () => void;
  setTempLenusToken: (token: string | undefined) => void;
}

export const LenusLoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  platform: Yup.string().required(),
});

type validateLenusLoginFormProps = Yup.InferType<typeof LenusLoginSchema>;

const TEXTFIELD_SX = {
  input: {
    color: "black",
  },
};

export default function LoginForm(props: Props) {
  const { defaultValues, setTempLenusToken, handleBack } = props;
  const { t } = useTranslation(TranslatorNS.AUTH);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (data: LenusLoginFormProps) => {
    try {
      const validatedData = data as validateLenusLoginFormProps;

      setLoading(true);
      setError(null);
      const payload = {
        email: validatedData.email,
        password: validatedData.password,
        platform: validatedData.platform,
      };

      const { data: loginData } = await axios.post(LENUS_LOGIN(), payload);

      if (loginData.token) {
        setLocalStorage(LocalStorageKeysEnum.LenusPlatformType, validatedData.platform);
        setTempLenusToken(loginData.token);
      } else {
        setError(
          "The login credentials are invalid. Please try again without two-factor authentication enabled. (No token)",
        );
      }
    } catch (e) {
      setError(
        "The login credentials are invalid. Please try again without two-factor authentication enabled. (Catch)",
      );
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm<LenusLoginFormProps>({
    resolver: yupResolver(LenusLoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  return (
    <>
      <Stack
        bgcolor={"white"}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={{ xs: 492 }}
        padding={5}
        borderRadius={1}
        border={"solid 1px #979797"}
        width={{ xs: "100%", sm: 686 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleLogin)}>
          <Stack
            sx={{ textAlign: "center", mb: 5 }}
            alignItems={"center"}
            justifyContent={"center"}>
            <Stack alignItems={"center"} justifyContent={"center"}>
              <LenusIcon />
            </Stack>
            <Typography color={"black"} fontSize={"32px"}>
              {t("transfer.pleaseLogin")}
            </Typography>
            <Typography color={"black"} width={{ xs: "100%", sm: "70%" }}>
              {t("transfer.inOrderToTransfer")}
            </Typography>
          </Stack>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Stack width={{ xs: "90%", sm: 480 }}>
              <RHFTextField
                sx={TEXTFIELD_SX}
                name={"email"}
                label={t("signup.inputs.email")}
              />
              <RHFTextField
                sx={TEXTFIELD_SX}
                name={"password"}
                type={"password"}
                label={t("signup.inputs.password")}
              />
              <RHFSelect sx={TEXTFIELD_SX} name={"platform"} label={"platform"}>
                {Object.values(LenusPlatformTypeEnum).map(value => (
                  <MenuItem key={value} value={value}>
                    {`${value}.lenus.io`}
                  </MenuItem>
                ))}
              </RHFSelect>
              {error && (
                <Alert sx={{ width: "100%", my: 2 }} severity="error">
                  {error}
                </Alert>
              )}
            </Stack>
            <LoadingButton
              type={"submit"}
              size={"large"}
              loading={loading}
              disabled={!isDirty}
              variant={"contained"}>
              {t("login.login")}
            </LoadingButton>
          </Stack>
        </FormProvider>
      </Stack>
      <NavigationButtons
        goBackTxt={t("transfer.back")}
        goBack={handleBack}
        goNextTxt={`${t("transfer.next")}: ${t("transfer.nextCreateEmail")}`}
      />
    </>
  );
}
