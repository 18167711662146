import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";
import { SnackbarKey, useSnackbar } from "notistack";

export default function UploadVideoLoaderSnackbar(props: {
  isOpen: boolean;
  progress: number;
}) {
  const { isOpen, progress } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.APP);
  const [active, setActive] = useState(false);
  const [key, setKey] = useState<SnackbarKey>("");

  useEffect(() => setActive(Boolean(isOpen && progress)), [isOpen, progress]);

  useEffect(() => {
    if (active) {
      const key = enqueueSnackbar(t("videoUpload.uploading"), {
        variant: "info",
        persist: true,
      });
      setKey(key);
    } else {
      closeSnackbar(key);
    }
    return () => {};
  }, [active]);

  return <></>;
}
