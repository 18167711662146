import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Stack } from "@mui/material";
import IPHONE_IMG from "../../assets/images/clientActivation/AppleAppStore.png";
import GOOGLE_IMG from "../../assets/images/clientActivation/GoogleStore.png";
import Typography from "@mui/material/Typography";
import { Alert } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";
import { AppState } from "../../store";
import { track } from "../../helpers/analytics";

interface Props {
  demoClient: boolean;
  email: string;
}

function DemoPreviewApp(props: Props) {
  const { demoClient, email } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const IOS_URL = "https://apps.apple.com/dk/app/zenfit-for-clients/id1155897823";
  const Android_URL = "https://play.google.com/store/apps/details?id=com.zenfit_app";
  const clientAppPassword = "12345";

  useEffect(() => {
    demoClient && track("Visit Demo Client", "Demo Client");
  }, []);

  if (!demoClient) {
    return null;
  }

  return (
    <Stack pt={4} pl={4}>
      <Alert severity={"info"} sx={{ position: "relative" }}>
        <Stack
          width={"100%"}
          flexDirection={{ sm: "row", xs: "column" }}
          justifyContent={"space-between"}>
          <Stack id="vist-client-app">
            <Typography maxWidth={"75%"} variant={"subtitle1"}>
              {t("info.visitClientApp")}
            </Typography>
            <Typography>
              {t("const.clientInfoFields.email")}: {email}
            </Typography>
            <Typography>
              {t("login.inputs.password", { ns: TranslatorNS.CLIENT_SETTINGS })}:{" "}
              {clientAppPassword}
            </Typography>
          </Stack>
          <Stack
            position={{ sm: "absolute", xs: "relative" }}
            top={"25%"}
            right={10}
            flexDirection={"row"}
            justifyContent={"space-between"}
            id="client-app-download"
            sx={{ mt: 1, ml: 1 }}>
            <a
              target={"_blank"}
              onClick={() => track("Clicked Download App", "Demo Client")}
              href={IOS_URL}>
              <img
                style={{ width: "120px", marginRight: 16 }}
                src={IPHONE_IMG}
                alt="DownLoadAppToIphone"
              />
            </a>
            <a
              target={"_blank"}
              onClick={() => track("Clicked Download App", "Demo Client")}
              href={Android_URL}>
              <img
                style={{ width: "120px", marginRight: 16 }}
                src={GOOGLE_IMG}
                alt="DownLoadAppToAndroid"
              />
            </a>
          </Stack>
        </Stack>
      </Alert>
    </Stack>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    demoClient: state.currentClient.isDemoClient,
    email: state.currentClient.email,
  };
};

export default connect(mapStateToProps)(DemoPreviewApp);
