import { AddClientStep } from "./types";
import { CurrencyEnum } from "../../../interfaces/currency";

export const question = {
  title: "help.questionnaire.title",
  content: ["help.questionnaire.content1", ""],
};

export const duration = {
  title: "help.durationTime.title",
  content: ["help.durationTime.content1", "help.durationTime.content2"],
};

export const checkIn = {
  title: "help.checkIn.title",
  content: ["help.checkIn.content1", "help.checkIn.content2"],
};

export const payment = {
  title: "help.payment.title",
  content: ["help.payment.content1", "help.payment.content2"],
};

export const durationMonth = [
  { label: "help.durationMonth.chargeUpfront", value: 0 },
  { label: "help.durationMonth.1 Month", value: 1 },
  { label: "help.durationMonth.2 Months", value: 2 },
  { label: "help.durationMonth.3 Months", value: 3 },
  { label: "help.durationMonth.4 Months", value: 4 },
  { label: "help.durationMonth.5 Months", value: 5 },
  { label: "help.durationMonth.6 Months", value: 6 },
  { label: "help.durationMonth.7 Months", value: 7 },
  { label: "help.durationMonth.8 Months", value: 8 },
  { label: "help.durationMonth.9 Months", value: 9 },
  { label: "help.durationMonth.10 Months", value: 10 },
  { label: "help.durationMonth.11 Months", value: 11 },
  { label: "help.durationMonth.12 Months", value: 12 },
  { label: "help.durationMonth.untilUnsubscribe", value: 13 },
];

export const week = [
  { label: "help.week.Monday", value: 1 },
  { label: "help.week.Tuesday", value: 2 },
  { label: "help.week.Wednesday", value: 3 },
  { label: "help.week.Thursday", value: 4 },
  { label: "help.week.Friday", value: 5 },
  { label: "help.week.Saturday", value: 6 },
  { label: "help.week.Sunday", value: 7 },
];

export const checkInFrequency = [
  { label: "help.frequency.everyWeek", value: 1 },
  { label: "help.frequency.everyTwoWeeks", value: 2 },
  { label: "help.frequency.everyThreeWeeks", value: 3 },
  { label: "help.frequency.everyFourWeeks", value: 4 },
];

export const currency = Object.keys(CurrencyEnum).map(currencyKey => {
  return {
    label: currencyKey,
    value: CurrencyEnum[currencyKey as keyof typeof CurrencyEnum],
  };
});

export const AddClientStepNumber = {
  [AddClientStep.CLIENT]: 1,
  [AddClientStep.ADDITIONAL_OPTIONS]: 2,
  [AddClientStep.SUBSCRIPTION]: 3,
  [AddClientStep.QUESTIONNAIRE]: 4,
};
