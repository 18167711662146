import { AppStatus } from "../../store/user/reducer";
import { Navigate, useLocation } from "react-router-dom";
import { getToken, Tokens } from "../../api/tokens";
import React, { useEffect } from "react";
import { fetchUserPrivate } from "../../store/user/action";
import { connect } from "react-redux";
import { AppState } from "../../store";
import LoadingScreen from "../../components/_MUI/LoadingScreen";
import { PlatformOriginEnum } from "../../store/user/reducerTypes";
import { getNextAuthRoute } from "../../helpers/routing";
import { TransferProgressEnum } from "../../generated/graphql";

interface AuthGuardProps {
  fetchUserPrivate: () => void;
  children: JSX.Element;
  activated: boolean;
  appStatus: AppStatus;
  configured: boolean;
  platformOrigin: PlatformOriginEnum;
  transferInProgress: TransferProgressEnum;
}

function AuthGuardComponent(props: AuthGuardProps) {
  const {
    children,
    platformOrigin,
    configured,
    appStatus,
    activated,
    fetchUserPrivate,
    transferInProgress,
  } = props;

  let location = useLocation();
  const token = getToken(Tokens.BEARER_TOKEN);

  useEffect(() => {
    if (token) {
      fetchUserPrivate();
    }
  }, [token]);

  const nextAuthRoute = getNextAuthRoute(
    platformOrigin,
    appStatus,
    activated,
    location.pathname,
    token,
    configured,
    transferInProgress,
  );

  if (nextAuthRoute) {
    return <Navigate to={nextAuthRoute} state={{ from: location }} replace />;
  }

  if (appStatus !== AppStatus.READY) {
    return <LoadingScreen />;
  }

  return children;
}

const mapDispatchToProps = {
  fetchUserPrivate: fetchUserPrivate,
};

function mapStateToProps(state: AppState) {
  return {
    activated: state.user.activated,
    appStatus: state.user.appStatus,
    configured: state.user.configured,
    platformOrigin: state.user.platformOrigin,
    transferInProgress: state.user.transferInProgress,
  };
}

export const AuthGuard = connect(mapStateToProps, mapDispatchToProps)(AuthGuardComponent);
