import { useTheme } from "@mui/material";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

interface Props {
  prefixText: string;
  progressText: string;
  suffixText: string;
  prefixTextSize?: number;
  progressTextSize?: number;
  suffixTextSize?: number;
  progress: number;
  height?: number;
  width?: number;
  strokeWidth?: number;
  outerRadius?: number;
  progressTextVerticalOffset?: number;
  prefixTextVerticalOffset?: number;
  suffixTextVerticalOffset?: number;
  graphColor?: CircleChartColorEnum;
}

export default function CircleChart(props: Props) {
  const {
    prefixText,
    progressText,
    suffixText,
    progress,
    height = 250,
    width = 400,
    strokeWidth = 8,
    outerRadius = 100,
    prefixTextSize = 14,
    progressTextSize = 40,
    suffixTextSize = 14,
    progressTextVerticalOffset = 16,
    prefixTextVerticalOffset = -30,
    suffixTextVerticalOffset = 44,
  } = props;
  const totalPercentage = 100;
  const { palette } = useTheme();

  const calcualtedStrokeWidth = outerRadius - strokeWidth;

  const difference = totalPercentage - progress;

  /**
   * Data formatted to display circle chart
   */
  const data = [
    { name: "progress", value: progress },
    { name: "goal", value: difference },
  ];

  const color = () => {
    switch (props.graphColor) {
      case CircleChartColorEnum.Error:
        return palette.error.dark;
      default:
        return palette.primary.main;
    }
  };

  return (
    <div style={{ height, width }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            startAngle={90}
            endAngle={-270}
            innerRadius={calcualtedStrokeWidth}
            outerRadius={outerRadius}>
            <Cell fill={color()} />
            <Cell fill={"rgba(145,158,171,0.16)"} />
          </Pie>
          <text
            x={"50%"}
            y={"50%"}
            dy={prefixTextVerticalOffset}
            textAnchor="middle"
            fontFamily={"Inter"}
            fontSize={prefixTextSize}
            fontWeight={600}
            fill={palette.grey[600]}>
            {prefixText}
          </text>
          <text
            style={{ lineHeight: 1, letterSpacing: "0.1px" }}
            x={"50%"}
            y={"50%"}
            dy={progressTextVerticalOffset}
            fontFamily={"Inter"}
            fontSize={progressTextSize}
            fontWeight={600}
            textAnchor="middle"
            fill={"#171725"}>
            {progressText}
          </text>
          <text
            x={"50%"}
            y={"50%"}
            dy={suffixTextVerticalOffset}
            textAnchor="middle"
            fontFamily={"Inter"}
            fontSize={suffixTextSize}
            fill={"#333333"}>
            {suffixText}
          </text>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export enum CircleChartColorEnum {
  Primary = "primary.main",
  Success = "success.dark",
  Error = "error.dark",
  Default = "secondary.dark",
}
