import { ROOT } from "../config";

export const CLIENT_LOGIN = () => {
  return `${ROOT}/react-api/v3/auth/login`;
};
export const GET_CLIENT_SETTINGS = () => {
  return `${ROOT}/react-api/v3/client/settings`;
};
export const SAVE_CLIENT_SETTINGS = () => {
  return `${ROOT}/react-api/v3/client/settings`;
};
export const REQUEST_UNSUBSCRIBE = () => {
  return `${ROOT}/react-api/v3/client/unsubscribe`;
};
export const UPDATE_PASSWORD = () => {
  return `${ROOT}/react-api/v3/client/updatePassword`;
};
export const CREATE_SESSION = () => {
  return `${ROOT}/react-api/v3/client/create-session`;
};
export const GET_CLIENT_FILTER_COUNTS = () => {
  return `${ROOT}/api/clients/count`;
};
export const FETCH_CLIENTS = () => {
  return `${ROOT}/api/clients`;
};
export const FETCH_CLIENT = (id: number) => {
  return `${ROOT}/api/private/client/${id}`;
};
export const DELETE_SELECTED_CLIENTS = () => {
  return `${ROOT}/api/clients/delete`;
};
export const ADD_NEW_TASK = () => {
  return `${ROOT}/api/client-reminder`;
};
export const RESOLVE_REMINDER = () => {
  return `${ROOT}/api/client-reminder/resolve`;
};
export const CLIENT_STATUS_UPDATE = () => {
  return `${ROOT}/api/client/status/update`;
};
export const ADD_NEW_CLIENT = () => {
  return `${ROOT}/api/client/add`;
};
export const PAUSE_SUBSCRIPTION = () => {
  return `${ROOT}/connect/pause-subscription`;
};
export const UNSUBSCRIBE_CLIENT = () => {
  return `${ROOT}/connect/unsubscribe`;
};
export const REFUND_CLIENT = () => {
  return `${ROOT}/connect/refund-client`;
};
export const FETCH_VIDEOS = () => {
  return `${ROOT}/video/api`;
};
export const FETCH_DOCUMENTS = () => {
  return `${ROOT}/docs/api`;
};
export const FETCH_CLIENT_VIDEOS = (clientId: number) => {
  return `${ROOT}/video/api/${clientId}`;
};
export const ADD_CLIENT_VIDEO = (clientId: number, videoId: number) => {
  return `${ROOT}/video/api/${clientId}/${videoId}`;
};
export const REMOVE_CLIENT_VIDEO = (clientId: number, videoId: number) => {
  return `${ROOT}/video/api/${clientId}/${videoId}`;
};
export const REMOVE_CLIENT_DOC = (clientId: number, docId: number) => {
  return `${ROOT}/docs/api/${clientId}/${docId}`;
};
export const POST_CLIENT_DOC_LIBRARY = () => {
  return `${ROOT}/docs/api/upload`;
};
export const FETCH_CLIENT_DOCS = (clientId: number) => {
  return `${ROOT}/docs/api/${clientId}`;
};
export const ADD_CLIENT_DOC = (clientId: number, docId: number) => {
  return `${ROOT}/docs/api/${clientId}/${docId}`;
};
export const UPDATE_CLIENT_INFO = (clientId: number) => {
  return `${ROOT}/api/private/client/submitClientInfo/${clientId}`;
};
export const FETCH_CLIENT_PAYMENTS_LOG = () => {
  return `${ROOT}/api/stripe/payments-log`;
};
export const FETCH_CLIENT_IMAGES = (clientId: number) => {
  return `${ROOT}/progress/api/images/${clientId}`;
};
export const FETCH_CLIENT_PROGRESS = (clientId: number) => {
  return `${ROOT}/progress/api/client/${clientId}`;
};
export const GET_CLIENT_KCALS_URL = (clientId: number) => {
  return `${ROOT}/api/meal/client/kcals/${clientId}`;
};
export const UPLOAD_CLIENT_IMAGES = (clientId: number) => {
  return `${ROOT}/api/public/client/uploadImg/${clientId}`;
};
export const DELETE_CLIENT_IMAGES = (clientId: number) => {
  return `${ROOT}/progress/api/images/${clientId}`;
};
export const UPDATE_CLIENT_PROGRESS = (clientId: number) => {
  return `${ROOT}/progress/api/entries/${clientId}`;
};
export const RESEND_QUESTIONNAIRE = () => {
  return `${ROOT}/api/private/client-status/resendQuestionnaire`;
};
export const CLIENT_STATUS_ACTION = () => {
  return `${ROOT}/api/private/client-status/take-action`;
};
export const IGNORE_STATUS = () => {
  return `${ROOT}/api/private/client-status/ignore`;
};
export const DISCONNECT_MFP = () => {
  return `${ROOT}/react-api/v3/mfp/disable`;
};

//LEGACY ENDPOINTS
export const GET_CLIENT_MACROS = (clientId: number) => {
  return `${ROOT}/react-api/v2/macros/${clientId}`;
};
export const GET_CLIENT_WORKOUT_STATS = (clientId: number) => {
  return `${ROOT}/react-api/v2/workout/stats/${clientId}`;
};
export const GET_CLIENT_MEAL_STATS = (clientId: number) => {
  return `${ROOT}/react-api/v2/meals/${clientId}`;
};
