import React from "react";
import ZFLogo from "../../assets/logo/ZFLogo";
import { ZFPoweredByStyled } from "./ZFPoweredByStyled";
import { useTranslation } from "react-i18next";

interface Props {
  color?: string;
}

const ZFPoweredBy = (props: Props) => {
  const { color } = props;
  const { t } = useTranslation("messages");
  return (
    <ZFPoweredByStyled>
      <span style={{ color: color }}>{t("poweredBy")}</span>
      <ZFLogo color={color} height={20} width={60} />
    </ZFPoweredByStyled>
  );
};
export default ZFPoweredBy;
