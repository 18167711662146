import { InitialModalShow } from "../../../helpers/listHelper";
import { MODAL_STATE } from "../../../helpers/const";

export const INITIAL_STATE = {
  modal: InitialModalShow,
};

export default function Reducer(state = INITIAL_STATE, { type, payload }: any) {
  switch (type) {
    case MODAL_STATE: {
      return { ...state, modal: payload };
    }
    default:
      return state;
  }
}
