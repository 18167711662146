import { Stack, Typography } from "@mui/material";
import Label, { LabelColor } from "../../../../components/_MUI/Label";
import { getProgressWeekChange } from "../helpers/getProgressWeekChange";
import { GetClientGoalQuery } from "../../../../generated/graphql";
import { getUnit, MeasuringSystemUnitEnum } from "../../../../helpers/progressHelper";
import { getGoalType, GoalTypeEnum } from "../helpers/getGoalType";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { HtmlTooltip } from "../../../../components/HtmlTooltip";

interface Props {
  client?: GetClientGoalQuery["client"];
}

export default function GoalTitle(props: Props) {
  const { client } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  const startWeight = client?.startWeight;
  const weightGoal = client?.weightGoal;

  const {
    weekChange,
    isPositiveNumber,
    secondToLastWeekNumber,
    lastWeekNumber,
    lastWeekAverageWeight,
    secondToLastWeekAverageWeight,
    differenceInWeeksNumber,
  } = getProgressWeekChange(client?.progress);

  const unit = getUnit(client?.measuringSystem);
  const goalType = getGoalType(startWeight, weightGoal);
  const color = getColorStatus(goalType, isPositiveNumber, weekChange);

  const showTooltip = secondToLastWeekNumber && lastWeekNumber;

  const labelString = getLabelString(
    isPositiveNumber,
    weekChange,
    differenceInWeeksNumber,
  );
  const tooltipString = getTooltipTitle(weekChange, unit, isPositiveNumber);

  const TooltipComponent = () => (
    <Stack p={1} minWidth={200}>
      <Typography variant={"subtitle2"}>
        {t(tooltipString, { weekChange: Math.abs(weekChange || 0), unit })}
      </Typography>
      <Stack pt={1}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography>
            {t("newClientOverview.weekNumber", {
              weekNumber: secondToLastWeekNumber || 0,
            })}
            :
          </Typography>
          <Typography>
            {t("newClientOverview.weekNumberAvg", {
              avg: secondToLastWeekAverageWeight?.toFixed(2),
              unit,
            })}
          </Typography>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography>
            {t("newClientOverview.weekNumber", {
              weekNumber: lastWeekNumber || 0,
            })}
            :
          </Typography>
          <Typography>
            {t("newClientOverview.weekNumberAvg", {
              avg: lastWeekAverageWeight?.toFixed(2),
              unit,
            })}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
      {t("clientWeight.stats.goal")}
      <HtmlTooltip title={showTooltip ? <TooltipComponent /> : ""}>
        <Stack ml={2}>
          <Label sx={{ cursor: "default" }} color={color}>
            {t(labelString, {
              weekChange,
              unit,
              differenceInWeeks: differenceInWeeksNumber,
            })}
          </Label>
        </Stack>
      </HtmlTooltip>
    </Stack>
  );
}

const getLabelString = (
  isPositive: boolean,
  weekChange: number | undefined,
  differenceInWeeks: number | undefined,
) => {
  if (!differenceInWeeks) {
    return `newClientOverview.noChangeInWeight`;
  }

  if (isPositive) {
    if (differenceInWeeks === 1) {
      return `newClientOverview.lastWeekDifferencePositive`;
    }
    return `newClientOverview.weekDifferencePositive`;
  }

  if (differenceInWeeks === 1) {
    return `newClientOverview.lastWeekDifferenceNegative`;
  }

  return `newClientOverview.weekDifferenceNegative`;
};

const getTooltipTitle = (
  weekChange: number | undefined,
  unit: MeasuringSystemUnitEnum,
  isPositive: boolean,
) => {
  if (!weekChange) {
    return `newClientOverview.noChangeInWeight`;
  }

  if (isPositive) {
    return `newClientOverview.clientGained`;
  }

  return `newClientOverview.clientLost`;
};

const getColorStatus = (
  goalType: GoalTypeEnum | undefined,
  isPositive: boolean,
  weekChange: number | undefined,
): LabelColor => {
  if (!weekChange) return "default";

  switch (goalType) {
    case GoalTypeEnum.Gain:
      return isPositive ? "success" : "error";
    case GoalTypeEnum.Lose:
      return isPositive ? "error" : "success";
    default:
      return "default";
  }
};
