import { useSearchParams } from "react-router-dom";
import { ReactQueryKeys } from "../../../interfaces/ReactQueryKeys";

export function useWorkoutTemplateSearchParams() {
  let [searchParams, setSearchParams] = useSearchParams();
  const queryKey = ReactQueryKeys.WORKOUT_TEMPLATES;
  const q = searchParams.get("q") ?? undefined;
  const levels = searchParams.get("levels")?.split(",") as any;
  const workoutsPerWeek = searchParams.get("workoutsPerWeek")?.split(",") as any;
  const location = searchParams.get("locations")?.split(",") as any;
  const gender = searchParams.get("gender") as any;
  return { queryKey, q, levels, workoutsPerWeek, location, gender, setSearchParams };
}
