import createRequestTypes from "../../../helpers/createReduxActionTypes";

export const TAGS_BY_USER = "TAGS_BY_USER";
export const RESET_DOCUMENTS = "RESET_DOCUMENTS";
export const ERROR_STATE = "ERROR_STATE";
export const GET_DOCUMENTS = createRequestTypes("GET_DOCUMENTS");
export const CREATE_DOCUMENTS = createRequestTypes("CREATE_DOCUMENTS");
export const DELETE_DOCUMENTS = createRequestTypes("DELETE_DOCUMENTS");
export const CREATE_DOCUMENT_PIC_URL = createRequestTypes("CREATE_DOCUMENT_PIC_URL");
export const PUT_DOCUMENT_TO_CLIENT = createRequestTypes("POST_DOCUMENT_TO_CLIENT");
