import { useFormContext, Controller } from "react-hook-form";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "@mui/material";

type IProps = {
  name: string;
  sx?: TextFieldProps["sx"];
};

type Props = IProps &
  Omit<DesktopDatePickerProps<Date, Date>, "renderInput" | "onChange" | "value">;

export default function RHFDesktopDatePicker({
  name,
  disabled,
  label,
  sx,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DesktopDatePicker
          {...field}
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
          label={label}
          {...other}
          renderInput={params => (
            <TextField
              name={name}
              sx={{ ...sx, mb: 2 }}
              fullWidth
              helperText={error?.message}
              {...params}
              error={Boolean(error)}
            />
          )}
        />
      )}
    />
  );
}
