import { Locale } from "../../../interfaces/locale";
import { CurrencyEnum } from "../../../interfaces/currency";

export enum AddClientStep {
  CLIENT = "CLIENT",
  ADDITIONAL_OPTIONS = "ADDITIONAL_OPTIONS",
  SUBSCRIPTION = "SUBSCRIPTION",
  QUESTIONNAIRE = "QUESTIONNAIRE",
}

export interface InvitationData {
  locale?: Locale | string | null;
  clientName?: string;
  clientEmail?: string;
  messageType?: number;
  clientId?: number;
  dataKey?: string | null;
  queueId?: number;
}

export interface LeadData {
  leadId: number;
  clientName: string;
  clientEmail: string;
  clientTags: string[];
}

export interface EmailTemplate {
  id: number;
  locale: Locale;
  message: string;
  placeholders: { client: string; trainer: string; checkout: string };
  subject: string;
  title: string;
  type: number;
}

export type ClientFormValues = {
  name: string;
  email: string;
  tags: string[];
};

export type AdditionalOptionsFormValues = {
  durationTime: boolean;
  trackProgress: boolean;
  screeningQuestions: boolean;
  createWorkoutPlan: boolean;
  createMealPlan: boolean;
  hideCaloriesAndMeasurements: boolean;
  questionnaire: boolean;
  startDurationTime: Date | null;
  endDurationTime: Date | null;
  dayTrackProgress: number;
  trackFrequency: number;
};

export type SubscriptionFormValues = {
  currency?: CurrencyEnum;
  duration?: number;
  signUpFee?: number;
  monthlyAmount?: number;
  specifyTaxRate?: boolean;
  taxRate?: number;
  startPaymentDate?: Date | null;
  chargeUpfrontImmediately?: boolean;
  addSubscriptionTerms?: boolean;
  terms?: string;
};

export type QuestionnaireFormValues = {
  to: string;
  subject: string;
  template: number | null;
  message: string;
};

export type AddClientFormValues = {
  [AddClientStep.CLIENT]: ClientFormValues;
  [AddClientStep.ADDITIONAL_OPTIONS]: AdditionalOptionsFormValues;
  [AddClientStep.SUBSCRIPTION]: SubscriptionFormValues;
};
