import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteClientVideo, hideClientVideo, showClientVideo } from "./api";
import { IClientVideo } from "./interfaces";
import { ReactQueryKeys } from "../../../interfaces/ReactQueryKeys";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { SnackbarVariantEnum } from "../../../components/Snackbar/SnackbarVariantEnum";

interface IClientVideosShape {
  pages: IClientVideosPageShape[];
  pageParam?: number;
}

export interface IClientVideosPageShape {
  data: IClientVideo[];
  nextCursor: boolean;
}

export default function useClientVideosMutations() {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.LISTS);

  const showClientVideoMutation = useMutation(showClientVideo, {
    onMutate: () => {},
    onSuccess: (data, variables) => {
      queryClient.setQueryData<IClientVideosShape>(
        [ReactQueryKeys.CLIENT_VIDEOS, variables.clientId],
        previousState => {
          if (!previousState) return;

          return {
            ...previousState,
            pages: previousState.pages.map(page => {
              return {
                data: page.data.map(clientVideo =>
                  clientVideo.id === variables.videoId
                    ? { ...clientVideo, visibility: true }
                    : clientVideo,
                ),
                nextCursor: page.nextCursor,
              };
            }),
          };
        },
      );
      enqueueSnackbar(t("videos.clientVideos.showMutationSuccess"), {
        variant: SnackbarVariantEnum.SUCCESS,
      });
    },
    onError: (error, variables, context) => {
      console.error({ error, variables, context });
      enqueueSnackbar(t("videos.clientVideos.showMutationError"), {
        variant: SnackbarVariantEnum.ERROR,
      });
    },
  });

  const hideClientVideoMutation = useMutation(hideClientVideo, {
    onMutate: () => {},
    onSuccess: (data, variables) => {
      queryClient.setQueryData<IClientVideosShape>(
        [ReactQueryKeys.CLIENT_VIDEOS, variables.clientId],
        previousState => {
          if (!previousState) return;

          return {
            ...previousState,
            pages: previousState.pages.map(page => {
              return {
                data: page.data.map(clientVideo =>
                  clientVideo.id === variables.videoId
                    ? { ...clientVideo, visibility: false }
                    : clientVideo,
                ),
                nextCursor: page.nextCursor,
              };
            }),
          };
        },
      );
      enqueueSnackbar(t("videos.clientVideos.hideMutationSuccess"), {
        variant: SnackbarVariantEnum.SUCCESS,
      });
    },
    onError: (error, variables, context) => {
      console.error({ error, variables, context });
      enqueueSnackbar(t("videos.clientVideos.hideMutationError"), {
        variant: SnackbarVariantEnum.ERROR,
      });
    },
  });

  const deleteClientVideoMutation = useMutation(deleteClientVideo, {
    onMutate: () => {},
    onSuccess: (data, variables) => {
      queryClient.setQueryData<IClientVideosShape>(
        [ReactQueryKeys.CLIENT_VIDEOS, variables.clientId],
        previousState => {
          if (!previousState) return;

          return {
            ...previousState,
            pages: previousState.pages.map(page => {
              return {
                data: page.data.filter(
                  clientVideo => clientVideo.id !== variables.videoId,
                ),
                nextCursor: page.nextCursor,
              };
            }),
          };
        },
      );
      enqueueSnackbar(t("videos.clientVideos.deleteMutationSuccess"), {
        variant: SnackbarVariantEnum.SUCCESS,
      });
    },
    onError: (error, variables, context) => {
      console.error({ error, variables, context });
      enqueueSnackbar(t("videos.clientVideos.deleteMutationError"), {
        variant: SnackbarVariantEnum.ERROR,
      });
    },
  });

  return {
    hideClientVideoMutation,
    showClientVideoMutation,
    deleteClientVideoMutation,
  };
}
