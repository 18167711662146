import React from "react";
import { useDeleteMealTemplateMutation } from "../../../../generated/graphql";
import MuiActions from "../../../../components/Table/MuiActions";
import { connect } from "react-redux";
import { openGlobalModalAction } from "../../../../store/modals/action";
import {
  ModalCrudTypeEnum,
  ModalPayload,
  ModalTypeEnum,
} from "../../../../store/modals/reducerTypes";
import { Reference, StoreObject } from "@apollo/client";

interface Props {
  templateId: number;
  name: string;
  openGlobalModalAction: (payload: ModalPayload<ModalTypeEnum.MealTemplate>) => void;
}

function Actions({ templateId, name, openGlobalModalAction }: Props) {
  const [deleteTemplate] = useDeleteMealTemplateMutation();

  const deleteAction = async () => {
    try {
      await deleteTemplate({
        variables: {
          id: templateId,
        },
        update: cache => {
          cache.modify({
            fields: {
              mealPlanTemplates(existingMealTemplates, { readField }) {
                const arr = existingMealTemplates?.data.filter(
                  (taskRef: Reference | StoreObject | undefined) =>
                    templateId !== readField("id", taskRef),
                );
                return {
                  ...existingMealTemplates,
                  total: existingMealTemplates?.total - 1,
                  data: arr,
                };
              },
            },
          });
        },
      });
    } catch (e) {}
  };

  const editAction = () => {
    openGlobalModalAction({
      type: ModalTypeEnum.MealTemplate,
      context: {
        type: ModalCrudTypeEnum.Update,
        mealPlanId: templateId,
      },
    });
  };

  const duplicateAction = () => {
    openGlobalModalAction({
      type: ModalTypeEnum.MealTemplate,
      context: {
        type: ModalCrudTypeEnum.Duplicate,
        mealPlanId: templateId,
      },
    });
  };

  return (
    <MuiActions
      itemName={name}
      onDelete={deleteAction}
      onDuplicate={duplicateAction}
      onEdit={editAction}
    />
  );
}

export default connect(undefined, { openGlobalModalAction })(Actions);
