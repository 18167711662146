import createRequestTypes from "../../helpers/createReduxActionTypes";
import { Locale } from "../../interfaces/locale";
import { RequestState } from "../../interfaces/requestState";
import { IPaymentInfo } from "../../routes/ClientOverview/type";
import clientSchema from "./schema";
import { RELOAD_MEAL_PLAN } from "../../routes/Clients/store/clients/types";

export const SET_CURRENT_CLIENT_REQUEST = "SET_CURRENT_CLIENT_REQUEST";
export const SET_CURRENT_CLIENT_FAILED = "SET_CURRENT_CLIENT_FAILED";
export const SET_CURRENT_CLIENT_SUCCESS = "SET_CURRENT_CLIENT_SUCCESS";
export const APPROVE_CLIENT_TASK = "APPROVE_CLIENT_TASK";
export const IGNORE_CLIENT_TASK = "IGNORE_CLIENT_TASK";
export const RESOLVE_TASK_REMINDER = createRequestTypes("RESOLVE_TASK_REMINDER");
export const EXTEND_CLIENT_DURATION = createRequestTypes("EXTEND_CLIENT_DURATION");
export const ADD_NEW_CLIENT_TASK = createRequestTypes("ADD_NEW_CLIENT_TASK");

export const RESET_CURRENT_CLIENT = "RESET_CURRENT_CLIENT";

interface SetCurrentClientRequest {
  type: typeof SET_CURRENT_CLIENT_REQUEST;
  payload: {
    clientId: number;
  };
}
interface SetCurrentClientSuccess {
  type: typeof SET_CURRENT_CLIENT_SUCCESS;
  payload: ReturnType<typeof clientSchema>;
}
interface SetCurrentClientFailed {
  type: typeof SET_CURRENT_CLIENT_FAILED;
  payload: {
    error: any;
  };
}

interface ResetCurrentClient {
  type: typeof RESET_CURRENT_CLIENT;
  payload: any;
}

interface ApproveClientTask {
  type: typeof APPROVE_CLIENT_TASK;
  payload: {
    id: number;
  };
}

interface IgnoreClientTask {
  type: typeof IGNORE_CLIENT_TASK;
  payload: {
    status: TaskStatus[];
  };
}

interface ReloadMealPlan {
  type: typeof RELOAD_MEAL_PLAN;
  payload: boolean;
}

type DateResponseType = {
  date: string;
  timezone_type: number;
  timezone: string;
};

export interface AddNewTaskRequest {
  client: number;
  title: string;
  dueDate: string | null;
}

export interface TaskStatus {
  event_id: number;
  event_name: string;
  title: string;
  priority: number;
  id: number;
  resolved: boolean;
  date: DateResponseType;
  dueDate?: {
    date: string;
  };
  resolved_by: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

export interface CurrentClientState {
  loading: RequestState;
  subscriptionRequestState: RequestState;
  loadedAt: Date | null;
  id: number;
  name: string;
  firstname: string;
  email: string;
  isActive: boolean;
  isDemoClient: boolean;
  age: number;
  birthday: string;
  locale: Locale;
  photo: string;
  tags: { label: string; value: string }[];
  startDate: string;
  endDate: string;
  dayTrackProgress: number;
  trackProgressFrequency: number;
  phone: string;
  updateMealSchedule: any;
  updateWorkoutSchedule: any;
  notes: { dialogMessage: string | null; note: string | null; salesNotes: string | null };
  startWeight: number;
  goalWeight: number;
  goalType: number;
  pal: number;
  height: number;
  inches: number;
  gender: number;
  measuringSystem: number;
  feet: number;
  activityLevel: number;
  numberOfMeals: number;
  injuries: number;
  experienceLevel: number;
  experience: string;
  exercisePreferences: any;
  workoutLocation: number;
  workoutsPerWeek: number;
  motivation: string;
  dietaryPreference: string;
  excludedIngredients: { label: string; value: string }[];
  eatingDisorder: { [key: string]: boolean };
  lifestyle: string;
  trainerId: number;
  isMfpActivated: boolean;
  paymentInfo: IPaymentInfo;
  paymentLogs: any[];
  customQuestions: any[];
  clientFoodPreferences: {
    allergies: { [key: string]: boolean };
    eatingDisorder: { [key: string]: boolean };
  };
  hideKcals: boolean;
  status: TaskStatus[];
  reminders: TaskStatus[];
  queue: {
    id: number;
    payment: boolean;
    datakey: string;
    createdAt: DateResponseType;
  };
  loadingMealPlan: boolean;
}

export type currentClientActionTypes =
  | SetCurrentClientRequest
  | SetCurrentClientSuccess
  | SetCurrentClientFailed
  | ApproveClientTask
  | IgnoreClientTask
  | ResetCurrentClient
  | ReloadMealPlan;
