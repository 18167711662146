import styled from "styled-components";
import { RecipeType } from "../../generated/graphql";
import { RECIPE_ALLERGIES, RECIPE_PREFERENES } from "../Recipes/const";

export const Item = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  background-color: #fff;
  padding: 8px 12px;
  cursor: grab;
  border-radius: 8px;
  border: 1px solid #DFE3E8;
  background-color: #F9FAFB;
  background-color: theme.palette.grey[100];
  &.sortable-ghost {
    background-color: #c8ebfb;
  }
`;
// transform to pass test
// using this to transform the data for the front to be easier consumed
export const getPreferences = (meta?: RecipeType["meta"]) => {
  let preferences: (keyof typeof RECIPE_PREFERENES)[] = [];
  if (meta?.isVegetarian) {
    preferences.push("isVegetarian");
  }
  if (meta?.isPescetarian) {
    preferences.push("isPescetarian");
  }
  if (meta?.isVegan) {
    preferences.push("isVegan");
  }

  return preferences;
};
// using this to transform the data for the front to be easier consumed
export const getAllergies = (meta?: RecipeType["meta"]) => {
  let allergies: (keyof typeof RECIPE_ALLERGIES)[] = [];
  if (meta?.lactose) {
    allergies.push("lactose");
  }
  if (meta?.eggs) {
    allergies.push("eggs");
  }
  if (meta?.fish) {
    allergies.push("fish");
  }
  if (meta?.gluten) {
    allergies.push("gluten");
  }
  if (meta?.nuts) {
    allergies.push("nuts");
  }
  if (meta?.pig) {
    allergies.push("pig");
  }
  if (meta?.shellfish) {
    allergies.push("shellfish");
  }

  return allergies;
};

export const getPreferenceTranslationKeyList = (meta?: RecipeType["meta"]) => {
  let selected = [];

  if (meta?.isVegetarian) {
    selected.push(RECIPE_PREFERENES["isVegetarian"]);
  }
  if (meta?.isVegan) {
    selected.push(RECIPE_PREFERENES["isVegan"]);
  }
  if (meta?.isPescetarian) {
    selected.push(RECIPE_PREFERENES["isPescetarian"]);
  }
  return selected;
};

// using this to transform the data for the front to be easier consumed
export const getAllergyTranslationKeyList = (meta?: RecipeType["meta"]) => {
  let selected = [];

  if (meta?.lactose) {
    selected.push(RECIPE_ALLERGIES["lactose"]);
  }
  if (meta?.eggs) {
    selected.push(RECIPE_ALLERGIES["eggs"]);
  }
  if (meta?.fish) {
    selected.push(RECIPE_ALLERGIES["fish"]);
  }
  if (meta?.gluten) {
    selected.push(RECIPE_ALLERGIES["gluten"]);
  }
  if (meta?.nuts) {
    selected.push(RECIPE_ALLERGIES["nuts"]);
  }
  if (meta?.pig) {
    selected.push(RECIPE_ALLERGIES["pig"]);
  }
  if (meta?.shellfish) {
    selected.push(RECIPE_ALLERGIES["shellfish"]);
  }

  return selected;
};

export function transformPreferencesToServerFormat({
  preferences,
  allergies,
}: {
  preferences: (keyof typeof RECIPE_PREFERENES)[];
  allergies: (keyof typeof RECIPE_ALLERGIES)[];
}) {
  return Object.keys({
    ...RECIPE_ALLERGIES,
    ...RECIPE_PREFERENES,
  }).reduce(
    (acc, key) => ({
      ...acc,
      // setting the boolean value to true if the key is in the selection
      [key]: [...preferences, ...allergies].includes(
        key as keyof typeof RECIPE_PREFERENES & keyof typeof RECIPE_ALLERGIES,
      ),
    }),
    {},
  );
}
