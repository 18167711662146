// @mui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, Stack, Toolbar } from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive, { BreakPointEnum, QueryEnum } from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER } from "../../../config";
// components
import Logo from "../../../components/_MUI/Logo";
import Iconify from "../../../components/_MUI/Iconify";
import { IconButtonAnimate } from "../../../components/_MUI/animate";
//
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import { connect } from "react-redux";
import { changeUserLangAction } from "../../../store/user/action";
import UserAlerts from "./UserAlerts";
import ZenfitDark from "../../../assets/logo/ZenfitDark";
import { AppStatus } from "../../../store/user/reducer";
import { Link } from "react-router-dom";
import { Routes } from "../../../routes/routeMap";
import SleekPlanPopover from "./SleekPlanPopover";
import { Locale } from "../../../interfaces/locale";
import HelpPopover from "./HelpPopover";
// ----------------------------------------------------------------------

type RootStyleProps = {
  isCollapse: boolean;
  isOffset: boolean;
  verticalLayout: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: prop =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})<RootStyleProps>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: "100%",
    ...(isCollapse && {
      width: `100%`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  onOpenSidebar: VoidFunction;
  isCollapse?: boolean;
  verticalLayout?: boolean;
  name: string;
  image?: string;
  locale: Locale;
  changeUserLangAction: (locale: Locale) => void;
  appStatus: AppStatus;
};

function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  name,
  image,
  locale,
  changeUserLangAction,
  appStatus,
}: Props) {
  const theme = useTheme();
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);
  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
          backgroundColor: theme.palette.primary.dark,
        }}>
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}
        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}>
            <Iconify icon="eva:menu-fill" sx={{ color: "common.white" }} />
          </IconButtonAnimate>
        )}
        {isDesktop && (
          <Stack sx={{ mb: "-4px" }}>
            <Link to={Routes.DEFAULT_ROUTE}>
              <ZenfitDark color="#fff" />
            </Link>
          </Stack>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            width: isDesktop ? "75%" : "85%",
            justifyContent: isDesktop ? "flex-end" : "space-between",
          }}
          spacing={{ xs: 0.5 }}>
          <Stack sx={{ width: "100%" }}>
            {appStatus === AppStatus.READY && <UserAlerts />}
          </Stack>
          <HelpPopover/>
          <SleekPlanPopover />
          {isDesktop && (
            <LanguagePopover locale={locale} changeLang={changeUserLangAction} />
          )}
          <AccountPopover name={name} image={image} />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}

function mapStateToProps(state: any) {
  return {
    name: state.user.name,
    image: state.user.picture,
    locale: state.user.locale,
    appStatus: state.user.appStatus,
  };
}

export default connect(mapStateToProps, { changeUserLangAction })(DashboardHeader);
