import {
  currentClientActionTypes,
  CurrentClientState,
  SET_CURRENT_CLIENT_REQUEST,
  SET_CURRENT_CLIENT_SUCCESS,
  RESET_CURRENT_CLIENT,
  SET_CURRENT_CLIENT_FAILED,
  APPROVE_CLIENT_TASK,
  IGNORE_CLIENT_TASK,
  RESOLVE_TASK_REMINDER,
  EXTEND_CLIENT_DURATION,
  ADD_NEW_CLIENT_TASK,
} from "./types";
import { RequestState } from "../../interfaces/requestState";
import { Locale } from "../../interfaces/locale";
import {
  RELOAD_MEAL_PLAN,
  SUBSCRIPTION_UPDATE,
} from "../../routes/Clients/store/clients/types";
import { SubscriptionStatus, SubscriptionType } from "../../interfaces/subscription";

const CURRENT_CLIENT_INITIAL_STATE: CurrentClientState = {
  loading: RequestState.INITIAL,
  subscriptionRequestState: RequestState.INITIAL,
  loadedAt: null,
  id: -1,
  fullname: "",
  firstname: "",
  email: "",
  isActive: false,
  isDemoClient: false,
  age: -1,
  locale: Locale.da_DK,
  photo: "",
  trainerId: 0,
  isMfpActivated: false,
  status: [],
  reminders: [],
  // @ts-ignore
  paymentInfo: {},
  loadingMealPlan: false,
};

export default function currentClient(
  state = CURRENT_CLIENT_INITIAL_STATE,
  action: currentClientActionTypes,
) {
  switch (action.type) {
    case SET_CURRENT_CLIENT_REQUEST:
      return { ...state, loading: RequestState.PENDING };

    case SET_CURRENT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: RequestState.RESOLVED,
        loadedAt: new Date(),
        ...action.payload,
      };
    case SET_CURRENT_CLIENT_FAILED:
      return { ...state, loading: RequestState.REJECTED };

    case SUBSCRIPTION_UPDATE.REQUEST:
      return {
        ...state,
        subscriptionRequestState: RequestState.PENDING,
      };
    case SUBSCRIPTION_UPDATE.SUCCESS:
      if (action.payload.type === SubscriptionType.UNSUBSCRIBE) {
        return {
          ...state,
          subscriptionRequestState: RequestState.RESOLVED,
          paymentInfo: {
            ...state.paymentInfo,
            status: SubscriptionStatus.CANCELED,
            canceled: true,
          },
        };
      }
      if (action.payload.type === SubscriptionType.PAUSE) {
        return {
          ...state,
          subscriptionRequestState: RequestState.RESOLVED,
          paymentInfo: {
            ...state.paymentInfo,
            status: SubscriptionStatus.PAUSED,
            paused_until: action.payload.trialEnd,
          },
        };
      }

      return { ...state, subscriptionRequestState: RequestState.RESOLVED };
    case SUBSCRIPTION_UPDATE.FAILURE:
      return {
        ...state,
        subscriptionRequestState: RequestState.REJECTED,
      };
    case RESET_CURRENT_CLIENT: {
      return CURRENT_CLIENT_INITIAL_STATE;
    }
    case APPROVE_CLIENT_TASK: {
      return {
        ...state,
        status: state.status.map(el =>
          el.id === action.payload.id ? { ...el, resolved: true } : el,
        ),
      };
    }
    case IGNORE_CLIENT_TASK: {
      return {
        ...state,
        status: action.payload.status,
      };
    }
    case RESOLVE_TASK_REMINDER.SUCCESS: {
      return {
        ...state,
        reminders: state.reminders.map(el =>
          el.id === action.payload.id ? { ...el, resolved: true } : el,
        ),
      };
    }
    case EXTEND_CLIENT_DURATION.SUCCESS: {
      return {
        ...state,
        endDate: action.payload.endDate,
      };
    }
    case ADD_NEW_CLIENT_TASK.SUCCESS: {
      return {
        ...state,
        reminders: [...state.reminders, action.payload],
      };
    }

    case RELOAD_MEAL_PLAN: {
      return {
        ...state,
        loadingMealPlan: action.payload,
      };
    }
    default:
      return state;
  }
}
