import { TMealTemplateForm } from "./components/MealTemplateContainer";
import { ModalCrudTypeEnum } from "../../../store/modals/reducerTypes";
import axios from "../../../helpers/axios";
import {
  CREATE_MEALS as CREATE_MEALS_URL,
  EDIT_MEALS_ENDPOINT,
} from "../../../api/private/meals";
import { getErrorMessage } from "../../../helpers/postHelper";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useApolloClient } from "@apollo/client";
import {
  GetMealTemplatesDocument,
  MealPlanTemplateFragmentFragmentDoc,
} from "../../../generated/graphql";
import { TEMPLATES_LIMIT } from "../../../routes/MealTemplates/const";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { defaultOrderByObject } from "../../../routes/MealTemplates/components/TableContainer";

type Entry = {
  title: string;
  description: string | undefined;
  id: number;
  numberOfMeals: number;
};

export default function useMealTemplateAction({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { t } = useTranslation(TranslatorNS.MODALS);

  const AddEntryToCache = async (entry: Entry) => {
    const { title, description, id, numberOfMeals } = entry;
    // write to cache
    await client.cache.writeFragment({
      data: {
        id,
        title,
        description,
        totals: {
          numberOfMeals,
        },
        __typename: "MealPlanType",
      },
      fragment: MealPlanTemplateFragmentFragmentDoc,
    });

    // read from cache
    const newMealTemplateRef = await client.cache.readFragment({
      id: `MealPlanType:${id}`,
      fragment: MealPlanTemplateFragmentFragmentDoc,
      variables: {
        id,
      },
    });

    const currentMealTemplateObject = await client.readQuery({
      query: GetMealTemplatesDocument,
      variables: {
        limit: TEMPLATES_LIMIT,
        filters: {},
        orderBy: defaultOrderByObject,
      },
    });

    const previous = currentMealTemplateObject?.mealPlanTemplates?.data || [];
    const merged = [newMealTemplateRef, ...previous];

    // write to default cached list without any filters
    client.writeQuery({
      query: GetMealTemplatesDocument,
      variables: {
        limit: TEMPLATES_LIMIT,
        filters: {},
        orderBy: defaultOrderByObject,
      },
      data: {
        mealPlanTemplates: {
          ...currentMealTemplateObject.mealPlanTemplates,
          total: currentMealTemplateObject.mealPlanTemplates.total + 1,
          data: merged,
        },
      },
    });
  };

  const EditEntryCache = async ({ title, description, id, numberOfMeals }: Entry) => {
    await client.writeFragment({
      id: "MealPlanType:" + id,
      fragment: MealPlanTemplateFragmentFragmentDoc,
      data: {
        __typename: "MealPlanType",
        id,
        title,
        description,
        totals: {
          numberOfMeals,
        },
      },
    });
  };

  const createMealPlanTemplate = async (
    data: TMealTemplateForm<ModalCrudTypeEnum.Create>,
  ) => {
    try {
      setLoading(true);

      const { data: responseData } = await axios.post(CREATE_MEALS_URL(), {
        name: data.title,
        comment: data.description,
      });

      const { id } = responseData?.[0];

      enqueueSnackbar(t("mealTemplate.created"), {
        variant: "success",
        autoHideDuration: 2000,
      });

      await AddEntryToCache({
        id,
        title: data.title,
        description: data.description,
        numberOfMeals: 1,
      });

      onClose();
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
      console.log({ e });
    } finally {
      setLoading(false);
    }
  };

  const updateMealPlanTemplate = async (
    data: TMealTemplateForm<ModalCrudTypeEnum.Update>,
  ) => {
    try {
      setLoading(true);

      await axios.put(EDIT_MEALS_ENDPOINT(data.id), {
        name: data.title,
        comment: data.description,
      });

      enqueueSnackbar(t("mealTemplate.updated"), {
        variant: "success",
      });

      await EditEntryCache({
        title: data?.title,
        description: data?.description,
        id: data.id,
        numberOfMeals: data.numberOfMeals,
      });

      onClose();
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
    } finally {
      setLoading(false);
    }
  };

  const duplicateMealPlanTemplate = async (
    data: TMealTemplateForm<ModalCrudTypeEnum.Duplicate>,
  ) => {
    try {
      setLoading(true);

      const { data: responseData } = await axios.post(CREATE_MEALS_URL(), {
        id: data.id,
        name: data.title,
        comment: data.description,
      });

      const { id } = responseData?.[0];

      await AddEntryToCache({
        id,
        title: data.title,
        description: data.description,
        numberOfMeals: data?.numberOfMeals,
      });

      enqueueSnackbar(t("mealTemplate.duplicated"), {
        variant: "success",
      });
      onClose();
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    createMealPlanTemplate,
    updateMealPlanTemplate,
    duplicateMealPlanTemplate,
  };
}
