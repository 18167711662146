import { styled, Typography } from "@mui/material";

export const StyledComment = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  paddingLeft: 4,
  marginLeft: -4,
  paddingRight: 4,
  borderRadius: 8,
  color: theme.palette.grey[500],
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.grey[100],
  },
}));
