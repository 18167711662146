import React from "react";

export enum WorkoutPlanModalType {
  ADD_DAY = "ADD_DAY",
  MODIFY_DAY = "MODIFY_DAY",
  VIDEO = "VIDEO",
  TEMPLATE = "TEMPLATE",
  MODIFY_WORKOUT_PLAN_INFO = "MODIFY_WORKOUT_PLAN_INFO",
  SAVE_AS_TEMPLATE = "SAVE_AS_TEMPLATE",
}

type If<T, Type, Then, Otherwise> = T extends Type ? Then : Otherwise;

export interface VideoDialogContext {
  title: string;
  video: string | null;
}
export interface ModifyDayDialogContext {
  id: number;
  title: string;
  description: string;
  isOnlyDay: boolean;
}
export interface ModifyWorkoutPlanInfoDialogContext {
  id: number;
  name: string;
  description: string | null;
}
export interface SaveAsTemplateContext {
  id: number;
}

const initialValue = {
  type: null,
};

export type ModalContextForType<Type extends WorkoutPlanModalType> = If<
  Type,
  WorkoutPlanModalType.VIDEO,
  VideoDialogContext,
  If<
    Type,
    WorkoutPlanModalType.MODIFY_DAY,
    ModifyDayDialogContext,
    If<
      Type,
      WorkoutPlanModalType.MODIFY_WORKOUT_PLAN_INFO,
      ModifyWorkoutPlanInfoDialogContext,
      If<Type, WorkoutPlanModalType.SAVE_AS_TEMPLATE, SaveAsTemplateContext, never>
    >
  >
>;

interface ContextState {
  type: WorkoutPlanModalType | null;
  context?: ModalContextForType<WorkoutPlanModalType>;
}

export const DialogContext = React.createContext<{
  modal: ContextState;
  setModal: React.Dispatch<ContextState>;
}>({ modal: initialValue, setModal: () => {} });

export default function DialogProvider({ children }: { children: React.ReactElement }) {
  const [modal, setModal] = React.useState<ContextState>(initialValue);
  const value = { modal, setModal };

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
}
