// not sure if these will be used else where, if so we might move these to it.

export enum InputType {
  FILE = "file",
  PROFILE_IMG = "profileImg",
  PHONE = "phone",
  NUMBER = "number",
  TEXT = "text",
  SELECT = "select",
}

export enum Country {
  DK = "dk",
  SE = "se",
  NO = "no",
  FI = "fi",
  GB = "gb",
  NL = "nl",
  DE = "de",
  US = "us",
}
