import React, { Component, createRef, Fragment } from "react";
import moment from "moment";
import Spinner from "../../../../components/Spinner";
import axios from "../../../../helpers/axios";
import { MESSAGES_RECEIVE } from "../../../../api/private/chat";
import { Scrollbars } from "react-custom-scrollbars";
import * as _ from "lodash";
import Message from "./message";
import { styled } from "@mui/material/styles";

require("moment-precise-range-plugin");

export default class MessageList extends Component {
  static defaultProps = {
    threshold: 5,
  };

  constructor(props) {
    super(props);

    this.chatRef = createRef();
    this.floatingDate = createRef();

    this.state = {
      isInfiniteLoading: false,
    };

    this.handleFetchMessages = this.handleFetchMessages.bind(this);
    this.throttledOnScroll = _.throttle(this.onScroll.bind(this), 100, {
      leading: true,
      trailing: true,
    });
  }

  stickyTimeout = null;

  setStickyHeader = header => {
    this.floatingDate.current.innerText = header.innerText;
    if (Boolean(this.floatingDate.current)) {
      this.floatingDate?.current?.parentNode?.classList?.add("visible");
      clearTimeout(this.stickyTimeout);
      this.stickyTimeout = setTimeout(() => {
        this.floatingDate?.current?.parentNode?.classList?.remove("visible");
      }, 1500);
    }
  };

  clearStickyHeader = () => {
    this.floatingDate.current.innerText = "";
    this.floatingDate.current.parentNode.classList.remove("visible");
    clearTimeout(this.stickyTimeout);
  };

  $groupMap = {};
  $firstItem;
  stickyIndex = 0;
  lastWrapScrollTop = 0;

  componentDidMount() {
    this.addScrollListener();
    this.addClickListener();
  }

  componentWillUnmount() {
    this.removeScrollListener();
    this.removeClickListener();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { messages, isMessageLoading } = this.props;
    const { isInfiniteLoading } = this.state;

    return (
      messages !== nextProps.messages ||
      isMessageLoading !== nextProps.isMessageLoading ||
      isInfiniteLoading !== nextState.isInfiniteLoading
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { messages } = this.props;
    if (messages !== nextProps.messages) {
      this.$groupMap = {};
      this.stickyIndex = 0;
      this.$firstItem = null;
    }
  }

  componentDidUpdate(prevProps) {
    // handle floating date reset when switching between chats
    if (prevProps.messages !== this.props.messages) {
      this.clearStickyHeader();
      this.handleUpdateHeader();
    }

    if (
      prevProps.messages !== this.props.messages &&
      prevProps.startFrom !== 0 &&
      this.props.startFrom !== 0 &&
      prevProps?.messages?.[0]?.id !== this.props.messages?.[0]?.id &&
      this.props.startFrom > prevProps.startFrom
    ) {
      const previousFirstMessage = prevProps.messages[0];
      const newFirstMessage = this.props.messages[0];

      if (previousFirstMessage && newFirstMessage) {
        const previousFirstMessageElement = document.getElementById(
          `chat_widget_message_${previousFirstMessage.id}`,
        );
        const previousFirstMessagePos = previousFirstMessageElement
          ? previousFirstMessageElement.getBoundingClientRect().top
          : null;

        const newFirstMessageElement = document.getElementById(
          `chat_widget_message_${newFirstMessage.id}`,
        );
        const newFirstMessagePos = newFirstMessageElement
          ? newFirstMessageElement.getBoundingClientRect().top
          : null;

        this.chatRef.current.view.scrollTop =
          previousFirstMessagePos - newFirstMessagePos;
      }
    }
  }

  fillGroups = messages => {
    let messageGroups = [];

    const messagesLastIndex = messages.length - 1;
    if (messages[messagesLastIndex]) {
      messages[messagesLastIndex]["isLast"] = true;
    }

    messages.map(item => {
      const date = moment(item.date);
      const formattedDate = _.toUpper(date.format("ddd, MMM D, YYYY"));

      if (messageGroups[formattedDate]) {
        const lastIndexInGroup = messageGroups[formattedDate].items.length - 1;

        messageGroups[formattedDate].items[lastIndexInGroup].isLast = false;
        messageGroups[formattedDate].items.push(item);
      } else {
        messageGroups[formattedDate] = {
          header: formattedDate,
          items: [item],
        };
      }
    });

    return messageGroups;
  };

  addScrollListener = () => {
    this.chatRef.current.view.addEventListener("scroll", this.throttledOnScroll);
  };

  removeScrollListener = () => {
    this.chatRef.current.view.removeEventListener("scroll", this.throttledOnScroll);
  };

  addClickListener = () => {
    this.chatRef.current.view.addEventListener("click", this.props.markMessagesAsRead);
  };

  removeClickListener = () => {
    this.chatRef.current.view.removeEventListener("click", this.props.markMessagesAsRead);
  };

  onScroll() {
    const { hasMore } = this.props;
    const { isInfiniteLoading } = this.state;

    const topScrollPos = this.chatRef.current.view.scrollTop;
    const containerFixedHeight = this.chatRef.current.view.offsetHeight;
    const bottomScrollPos = topScrollPos + containerFixedHeight;

    if (bottomScrollPos - containerFixedHeight < this.props.threshold) {
      if (!isInfiniteLoading && hasMore) {
        this.handleFetchMessages();
      }
    }

    this.handleUpdateHeader();
  }

  handleUpdateHeader() {
    const { $groupMap, stickyIndex, lastWrapScrollTop } = this;

    const wrapScrollTop = this.chatRef.current.view.scrollTop;
    const goDown = wrapScrollTop - lastWrapScrollTop > 0;
    this.lastWrapScrollTop = wrapScrollTop;

    const $stickyGroup = $groupMap[stickyIndex];
    if (!$stickyGroup) {
      return;
    }
    const $stickyHeader = $stickyGroup.firstChild;
    const nextIndex = goDown ? stickyIndex + 1 : stickyIndex - 1;
    const $nextGroup = $groupMap[nextIndex];

    if ($nextGroup) {
      const $nextHeader = $nextGroup.firstChild;

      const updateToNextSticky = () => {
        this.setStickyHeader($nextHeader);
        this.stickyIndex = nextIndex;
      };

      const { offsetTop: groupOffsetTop, offsetHeight: groupHeight } = $nextGroup;
      if (goDown) {
        if (wrapScrollTop >= groupOffsetTop) {
          updateToNextSticky();
          return;
        }
      } else {
        if (wrapScrollTop <= groupOffsetTop + groupHeight) {
          updateToNextSticky();
          return;
        }
      }
    }

    this.setStickyHeader($stickyHeader);
  }

  handleFetchMessages() {
    const that = this;
    const { updateMessages, messages } = this.props;

    this.setState(
      {
        isInfiniteLoading: true,
      },
      () => {
        const obj = { startFrom: this.props.startFrom, isViewed: false };
        axios.post(MESSAGES_RECEIVE(this.props.clientId), obj).then(res => {
          const { messages: receivedMessages, hasMore } = res.data;
          that.setState(
            {
              isInfiniteLoading: false,
            },
            () => {
              updateMessages(
                [...receivedMessages, ...messages],
                hasMore,
                this.props.startFrom + receivedMessages.length,
              );
            },
          );
        });
      },
    );
  }

  getShouldGroupWithNext(message, nextMessage) {
    if (
      !nextMessage ||
      nextMessage.client !== message.client ||
      message.isUpdate ||
      nextMessage.isUpdate
    ) {
      return false;
    }
    const { minutes, hours, days, months, years } = moment.preciseDiff(
      moment(message.date),
      moment(nextMessage.date),
      true,
    );

    return minutes < 10 && hours === 0 && days === 0 && months === 0 && years === 0;
  }

  render() {
    const { messages, isMessageLoading, messagesEnd } = this.props;
    const { isInfiniteLoading } = this.state;
    const { $groupMap } = this;
    const messageGroups = this.fillGroups(messages);

    return (
      <Fragment>
        <FloatingDateContainer>
          <FloatingDate ref={this.floatingDate}></FloatingDate>
        </FloatingDateContainer>
        <Scrollbars
          ref={this.chatRef}
          onScroll={this.handleChatScroll}
          renderView={props => (
            <RootStyle {...props} style={{ ...props.style, overflowX: "hidden" }} />
          )}
          autoHide={true}
          autoHideTimeout={1500}>
          {isMessageLoading ? null : (
            <LazyLoader>
              <Spinner show={isInfiniteLoading} />
            </LazyLoader>
          )}
          {isMessageLoading ? (
            <Spinner show={isMessageLoading} />
          ) : (
            <Fragment>
              {Object.values(messageGroups).map(({ header, items, key }, index) => (
                <div
                  key={key !== undefined ? key : index}
                  ref={$group => {
                    $groupMap[index] = $group;
                  }}>
                  <MessageGroupHeader>
                    <MessageGroupHeaderInner>{header}</MessageGroupHeaderInner>
                  </MessageGroupHeader>
                  <MessageGroupItems>
                    {items.map((message, i) => (
                      <Message
                        message={message}
                        groupWithNext={this.getShouldGroupWithNext(message, items[i + 1])}
                        key={`message_${message.id}`}
                      />
                    ))}
                  </MessageGroupItems>
                </div>
              ))}
            </Fragment>
          )}
          {messagesEnd}
        </Scrollbars>
      </Fragment>
    );
  }
}

const RootStyle = styled("div")(() => ({
  flex: 1,
  overflowY: "auto",
  width: "100%",
  backgroundColor: "#fff",
}));

const ChatDateTextStyle = {
  display: "inline-block",
  fontSize: "12px",
  lineHeight: "18px",
  color: "#919eab",
};

const FloatingDateContainer = styled("div")(() => ({
  display: "none",
  width: "100%",
  position: "absolute",
  top: "8px",
  textAlign: "center",
  zIndex: 100,
  transition: "top 0.35s ease-out",

  "&.visible": {
    display: "block",
  },
}));

const FloatingDate = styled("div")(() => ({
  padding: "2px 10px",
  backgroundColor: "#fff",
  borderRadius: "5px",
  boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.07)",
  ...ChatDateTextStyle,
}));

const MessageGroupHeader = styled("div")(() => ({
  margin: "8px 0px",
  textAlign: "center",
}));

const MessageGroupHeaderInner = styled("div")(() => ({
  ...ChatDateTextStyle,
}));

const MessageGroupItems = styled("div")(() => ({
  padding: "0 16px",
}));

const LazyLoader = styled("div")(() => ({
  ">.loader-container": {
    height: "auto",
    padding: "10px 0",

    ">.loader": {
      margin: 0,
      width: "25px",
      height: "25px",
    },
  },
}));
