import { ClientType } from "../../../../generated/graphql";
import { getUnit } from "../../../../helpers/progressHelper";
import { getGoalType, GoalTypeEnum } from "./getGoalType";
import { roundToDecimals } from "../../../ClientProgress/bottom/helpers/utils";
import { CircleChartColorEnum } from "../../../../components/Charts/CircleChart";
import { GraphArrowDirectionEnum } from "./graphDirection";

export default function getGoalGraphValues(
  clientStartWeight: ClientType["startWeight"] | undefined,
  clientWeightGoal: ClientType["weightGoal"] | undefined,
  clientMeasuringSystem: ClientType["measuringSystem"] | undefined,
  clientProgress: ClientType["progress"] | undefined,
) {
  const startWeight = roundToDecimals(clientStartWeight || 0);
  const weightGoal = roundToDecimals(clientWeightGoal || 0);
  const unit = getUnit(clientMeasuringSystem);

  const currentWeight = getCurrentWeightFromProgress(clientProgress);
  const goalType = getGoalType(startWeight, weightGoal);

  const goalStartDifference = roundToDecimals(
    weightGoal && startWeight ? Math.abs(weightGoal - startWeight) : 0,
  );

  const currentProgress = roundToDecimals(
    currentWeight && startWeight ? currentWeight - startWeight : 0,
  );

  const { indicatorColor, arrowDirection, percentageMultiplier } = getGraphIndicators(
    goalType,
    currentWeight,
    startWeight,
  );

  const percentage =
    goalStartDifference && currentProgress
      ? (currentProgress / goalStartDifference) * 100 * percentageMultiplier
      : 0;

  const percent = Math.abs(percentage) > 100 ? 100 * percentageMultiplier : percentage;

  return {
    startWeight,
    weightGoal,
    unit,
    currentWeight,
    goalType,
    indicatorColor,
    currentProgress: Math.abs(currentProgress),
    arrowDirection,
    percent,
    goalStartDifference,
  };
}

const getGraphIndicators = (
  goalType: GoalTypeEnum | undefined,
  currentWeight: number | undefined,
  startWeight: number | undefined,
) => {
  const hasWeightIncreased = startWeight && currentWeight && currentWeight > startWeight;

  if (goalType === GoalTypeEnum.Gain) {
    if (hasWeightIncreased) {
      return {
        indicatorColor: CircleChartColorEnum.Success,
        arrowDirection: GraphArrowDirectionEnum.Up,
        percentageMultiplier: 1,
      };
    }
    return {
      indicatorColor: CircleChartColorEnum.Error,
      arrowDirection: GraphArrowDirectionEnum.Down,
      percentageMultiplier: -1,
    };
  }

  if (goalType === GoalTypeEnum.Lose) {
    if (hasWeightIncreased) {
      return {
        indicatorColor: CircleChartColorEnum.Error,
        arrowDirection: GraphArrowDirectionEnum.Up,
        percentageMultiplier: -1,
      };
    }
    return {
      indicatorColor: CircleChartColorEnum.Success,
      arrowDirection: GraphArrowDirectionEnum.Down,
      percentageMultiplier: -1,
    };
  }

  if (currentWeight && currentWeight !== 0) {
    return {
      indicatorColor: CircleChartColorEnum.Error,
      arrowDirection: hasWeightIncreased
        ? GraphArrowDirectionEnum.Up
        : GraphArrowDirectionEnum.Down,
      percentageMultiplier: -1,
    };
  }

  return {
    indicatorColor: CircleChartColorEnum.Default,
    arrowDirection: GraphArrowDirectionEnum.Maintain,
    percentageMultiplier: 1,
  };
};

const getCurrentWeightFromProgress = (
  progress: ClientType["progress"] | undefined,
): number | undefined => {
  if (!Boolean(progress?.length)) return undefined;

  /** For some reason we can get a null entry from backend & entries are not in date order
   * HouseCleaning is needed
   */
  const cleanedProgress = progress?.filter(p => Boolean(p?.weight));

  if (!cleanedProgress?.length) return undefined;

  const latestProgress = cleanedProgress.reduce((a, b) => {
    return new Date(String(a.date)) > new Date(String(b.date)) ? a : b;
  });

  return roundToDecimals(latestProgress?.weight || 0);
};
