import i18next from "i18next";
import { TranslatorNS } from "../../../i18n/const";

export const PROGRESS_IMAGE_LIMIT = 15;
export const TABLE_MIN_LIMIT = 8;
export const DECIMAL_COUNT = 1;

export const CIRCUM_TABLE_STRUCTURE = () => [
  {
    label: i18next.t("progress.date", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "dateLabel",
  },
  {
    label: i18next.t("progress.chest", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "chest",
  },
  {
    label: i18next.t("progress.waist", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "waist",
  },
  {
    label: i18next.t("progress.hips", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "hips",
  },
  {
    label: i18next.t("progress.glutes", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "glutes",
  },
  {
    label: i18next.t("progress.lArm", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "leftArm",
  },
  {
    label: i18next.t("progress.rArm", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "rightArm",
  },
  {
    label: i18next.t("progress.rThigh", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "rightThigh",
  },
  {
    label: i18next.t("progress.lThigh", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "leftThigh",
  },
  {
    label: i18next.t("progress.rCalf", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "rightCalf",
  },
  {
    label: i18next.t("progress.lCalf", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "leftCalf",
  },
  {
    label: i18next.t("progress.total", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "total",
  },
];

export const WEIGHT_TABLE_STRUCTURE = () => [
  {
    label: i18next.t("progress.date", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "dateLabel",
  },
  {
    label: i18next.t("progress.weight", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "weight",
  },
  {
    label: i18next.t("progress.fat%", { ns: TranslatorNS.CLIENT_PROGRESS }),
    value: "fat",
  },
];
