import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { FieldValidators } from "../../../../helpers/validators";
import { useTranslation } from "react-i18next";
import EatingDisorder from "../EatingDisorder";
import {
  DIET_PREFERENCES,
  DISORDERS_ARR,
  FOOD_PREFERENCES,
  NUMBER_OF_MEALS_PER_DAY,
} from "../../const";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { AppState } from "../../../../store";
import { ActivationStepsEnum, IDiet, IDietValidator } from "../../type";
import { TranslatorNS } from "../../../../i18n/const";
import { saveFieldsAction } from "../../store/action";
import { INITIAL_DIET } from "../../store/initialStates";
import { IClientActivationState } from "../../store/types";
import Typography from "@mui/material/Typography";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { scrollToElementId } from "../../../../helpers/utils";
import { track } from "../../../../helpers/analytics";

interface Props {
  currentStep: IClientActivationState["step"];
  saveFieldsActionDispatch: (
    diet: IDiet,
    nextStep: IClientActivationState["step"],
    currentStepName: ActivationStepsEnum,
  ) => void;
  dietState: IDiet;
  answeredQuestionnaire: IClientActivationState["answeredQuestionnaire"];
  showEatingDisorderQuestions: boolean;
}

const Diet = (props: Props) => {
  const {
    currentStep,
    saveFieldsActionDispatch,
    dietState,
    answeredQuestionnaire,
    showEatingDisorderQuestions,
  } = props;
  const { t } = useTranslation(TranslatorNS.MESSAGES);
  const [validator, setValidator] = useState<IDietValidator>({
    dietStyle: "",
    numberOfMeals: "",
    allergies: "",
    dietPreference: "",
    uncomfortablyFull: "",
    lostControl: "",
    significantLoss: "",
    feelsFat: "",
    foodDominates: "",
  });
  const [diet, setDiet] = useState<IDiet>(INITIAL_DIET);
  const { palette } = useTheme();

  useEffect(() => {
    setDiet({
      ...diet,
      dietStyle: dietState.dietStyle,
      numberOfMeals: dietState?.numberOfMeals,
      allergies: dietState.allergies,
      dietPreference: dietState.dietPreference,
      uncomfortablyFull: dietState.uncomfortablyFull,
      lostControl: dietState.lostControl,
      significantLoss: dietState.significantLoss,
      feelsFat: dietState.feelsFat,
      foodDominates: dietState.foodDominates,
    });
  }, [dietState]);

  const handleChange = (name: string, value: number | string | boolean) => {
    setValidator({ ...validator, [name]: "" });
    setDiet({ ...diet, [name]: value });
  };

  const handleCheckbox = (name: string, value: string) => {
    const allergiesCheckbox = diet.allergies.includes(value)
      ? diet.allergies.filter(allergi => allergi !== value)
      : [...diet.allergies, value];
    setDiet({ ...diet, [name]: allergiesCheckbox });
  };

  const handleSave = () => {
    try {
      FieldValidators(diet, t, ["allergies", "dietPreference"]);
      track("Diet Preferences Added", "Client Activation");
      saveFieldsActionDispatch(diet, currentStep + 1, ActivationStepsEnum.DIET);
    } catch (e: any) {
      setValidator(e);
      const id = e && Object.keys(e)[0];
      id && scrollToElementId(id);
    }
  };

  return (
    <Stack width={{ xs: "100%", sm: 450 }}>
      <Stack>
        <Typography color={Boolean(validator["dietStyle"]) ? "error" : "#6f7274"}>
          {t("client.survey.dietStylePlaceholder")}
        </Typography>
        <TextField
          name={"dietStyle"}
          id={`zf_dietStyle`}
          multiline
          rows={3}
          value={diet.dietStyle}
          sx={{ mb: 2 }}
          onChange={e => handleChange("dietStyle", e.target.value)}
          error={Boolean(validator["dietStyle"])}
          helperText={validator["dietStyle"]}
        />
      </Stack>
      <TextField
        value={diet.numberOfMeals}
        select
        SelectProps={{
          native: true,
        }}
        sx={{ mb: 2 }}
        id={`zf_numberOfMeals`}
        label={t("client.survey.numberOfMeals")}
        error={Boolean(validator["numberOfMeals"])}
        onChange={(val: any) => handleChange("numberOfMeals", val.target.value)}>
        <option disabled={Boolean(diet.numberOfMeals)} value={undefined}>
          {t("chooseOption")}
        </option>
        {NUMBER_OF_MEALS_PER_DAY.map((key: number) => {
          return (
            <option key={key} value={key}>
              {key}
            </option>
          );
        })}
      </TextField>
      <FormControl sx={{ my: 3 }}>
        <RadioGroup
          name="dietPreference"
          id={`zf_dietPreference`}
          value={diet.dietPreference}
          onChange={({ target }: any) => handleChange("dietPreference", target.value)}>
          <FormLabel sx={{ fontWeight: "600" }}>
            {t("client.survey.dietStyleTitle")}
          </FormLabel>
          <Stack flexDirection={"column"} justifyContent={"center"} mb={1}>
            {Object.entries(DIET_PREFERENCES).map(([key, val]) => {
              return (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={
                    <Typography
                      color={Boolean(validator["dietPreference"]) ? "error" : "inherit"}>
                      {t(val)}
                    </Typography>
                  }
                />
              );
            })}
          </Stack>
        </RadioGroup>
      </FormControl>
      <FormControl sx={{ my: 3 }}>
        <FormGroup id={`zf_excludeIngredients`}>
          <FormLabel sx={{ fontWeight: "600" }}>
            {t("client.survey.excludeIngredients")}
          </FormLabel>
          <Stack flexDirection={"column"} justifyContent={"center"} mb={1}>
            {Object.entries(FOOD_PREFERENCES).map(([key, val]) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      sx={{
                        "&.MuiCheckbox-indeterminate": {
                          color: palette.error.main,
                        },
                      }}
                      checked={false}
                      indeterminate={diet.allergies.includes(key)}
                    />
                  }
                  onChange={() => handleCheckbox("allergies", key)}
                  label={
                    <Typography
                      color={Boolean(validator["dietPreference"]) ? "error" : "inherit"}>
                      {t(val)}
                    </Typography>
                  }
                />
              );
            })}
          </Stack>
        </FormGroup>
      </FormControl>

      {showEatingDisorderQuestions && (
        <Stack>
          {DISORDERS_ARR.map(name => (
            <Fragment key={name}>
              <EatingDisorder
                answeredQuestionnaire={answeredQuestionnaire}
                label={`client.survey.eatingDisorder.${name}`}
                handleChange={(name, value) => handleChange(name, value)}
                value={diet[name]}
                name={name}
                helperText={validator[name]}
              />
            </Fragment>
          ))}
        </Stack>
      )}

      <Button
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_6}
        variant="contained"
        size="large"
        onClick={() => handleSave()}>
        {t("client.activation.next")}
      </Button>
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    currentStep: clientActivation.step,
    dietState: clientActivation.diet,
    answeredQuestionnaire: clientActivation.answeredQuestionnaire,
    showEatingDisorderQuestions: clientActivation.showEatingDisorderQuestions,
  };
}

const mapDispatchToProps = {
  saveFieldsActionDispatch: saveFieldsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Diet);
