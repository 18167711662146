import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";

export default function Activated() {
  const { t } = useTranslation(TranslatorNS.CHAT_WIDGET);
  return (
    <Alert severity={"info"} icon={false}>
      <AlertTitle sx={{ color: "info.darker" }}>{t("successActivated")}</AlertTitle>
    </Alert>
  );
}
