import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Client, WorkoutPlan } from "../../../types";
import { SAVE_WORKOUT_AS_TEMPLATE } from "../../../../../api/private/workout-api";
import { useSnackbar } from "notistack";

// @ts-ignore
const $ = global.jQuery || {};

interface Props {
  plan: WorkoutPlan;
  client?: Client;
}

export default function SaveAsTemplate({ plan }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["workoutPlan", "lists"]);
  const PATH = SAVE_WORKOUT_AS_TEMPLATE(plan.id);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const $form = $(e.target);
    const $submit = $form.find("[type=submit]").button("loading");
    // ændre PATH for at teste snackbar
    const promise = axios.post(PATH, $form.serialize());

    promise
      .then(response => {
        $form.closest(".modal").modal("hide");
        enqueueSnackbar(t("saveAsTemplate.workoutPlanWas"), {
          variant: "success",
          persist: false,
          preventDuplicate: true,
        });

        navigate(`/dashboard/workouts/templates/${response.data.id}`, { replace: true });
        $form[0].reset();
      })
      .catch(() => {
       //skal sættes øverst på z-index
        enqueueSnackbar(t( "saveAsTemplate.cannotSaveWorkoutPlan" ), {
          variant: "error",
          persist: false,
          preventDuplicate: true,
        });
      })
      .then(() => {
        $submit.button("reset");
      });
  };

  return (
    <div
      className="modal inmodal fade sm reactModal"
      id="saveAsTemplate"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("saveAsTemplate.close")}</span>
            </button>
            <h4 className="modal-title">{t("saveAsTemplate.saveWorkoutPlan")}</h4>
          </div>
          <form action={PATH} method="POST" onSubmit={onSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="template_name" className="control-label">
                  {t("saveAsTemplate.workoutPlanName")}
                </label>
                <input
                  type="text"
                  id="template_name"
                  name="name"
                  className="form-control"
                  placeholder={t("saveAsTemplate.nameOfWorkoutPlan")}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="template_comment" className="control-label">
                  {t("saveAsTemplate.comment")}
                </label>
                <input
                  type="text"
                  id="template_comment"
                  name="comment"
                  className="form-control"
                  placeholder={t("saveAsTemplate.enterYourComment")}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-block btn-success btn-upper"
                data-loading-text={t("saveAsTemplate.saving")}>
                {t("saveAsTemplate.save")} {t("saveAsTemplate.workout")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
