import { WorkoutType } from "../../../../generated/graphql";
import { Stack, Typography } from "@mui/material";
import { TrackedWorkoutsModalContext } from "../../../../store/modals/reducerTypes";
import Exercise from "./Exercise";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  trackedWorkouts: WorkoutType[] | any;
  readonly savedWorkoutDate: TrackedWorkoutsModalContext["savedWorkoutDate"];
}

export default function TrackedWorkouts(props: Props) {
  const { trackedWorkouts, savedWorkoutDate } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);

  return (
    <Stack>
      {trackedWorkouts.map((tracked: WorkoutType) => (
        <Stack key={tracked.id}>
          {!!tracked?.children?.length && (
            <Typography
              sx={{ textTransform: "uppercase" }}
              fontWeight={"bold"}
              fontSize={12}
              mb={1}
              color={"grey.600"}>
              {t("trackedWorkout.superset")}
            </Typography>
          )}
          <Stack
            key={tracked?.id}
            mb={2}
            border={1}
            borderColor={"grey.300"}
            borderRadius={2}
            sx={{ overflowX: "hidden" }}>
            <Exercise
              tracked={tracked}
              savedWorkoutDate={savedWorkoutDate}
              exerciseChildren={
                <>
                  {tracked?.children?.map((child: any) => (
                    <Exercise
                      key={child?.id}
                      tracked={child || undefined}
                      savedWorkoutDate={savedWorkoutDate}
                    />
                  ))}
                </>
              }
            />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
