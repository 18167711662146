import { Link } from "react-router-dom";
import { prepareRoute } from "../../../../../helpers/routing";
import { Routes } from "../../../../routeMap";
import React from "react";
import i18next from "i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import DemoClient from "./DemoClient";

export const getChatContent = (
  content: string,
  feedbackGiven?: React.ReactNode,
  messageElement?: any,
) => {
  const nameKey = " has updated his/her";
  const demoKey = " I'm Thor, your demo client 😎 ";
  const bodyProgressKey = " has uploaded a new progress";
  /**
   * Match last number in string (should be the id in the url aka. client id)
   */
  const clientId = content.match(/(\d+)(?!.*\d)/g)?.[0];

  /*
    content from backend is in html, so key above is from the checkin message
    Make React router link to progress page
  */

  if (content.includes(nameKey)) {
    const name = content.split(nameKey)[0];
    return (
      <div>
        {feedbackGiven}
        <div className="chat-text chat-update">
          <div style={{ padding: "0 10px" }}>
            {`${i18next.t("messages.feedBackCheckin", {
              ns: TranslatorNS.CHAT,
              name,
            })}: `}
            <Link to={prepareRoute(Routes.CLIENT_PROGRESS, { clientId })}>
              {`${i18next.t("messages.clickReview", { ns: TranslatorNS.CHAT, name })}`}
            </Link>
          </div>
        </div>
      </div>
    );
  } else if (content.includes(demoKey)) {
    return <DemoClient clientId={Number(clientId)} />;
  } else if (content.includes(bodyProgressKey)) {
    const name = content.split(bodyProgressKey)[0];
    return (
      <div className="chat-text chat-update">
        <div style={{ padding: "0 10px" }}>
          {`${i18next.t("messages.newBodyProgress", {
            ns: TranslatorNS.CHAT,
            name,
          })}: `}
          <Link to={prepareRoute(Routes.CLIENT_PROGRESS, { clientId })}>
            {`${i18next.t("messages.clickReview", { ns: TranslatorNS.CHAT, name })}`}
          </Link>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        {feedbackGiven}
        <div className="chat-text chat-update">
          <div
            ref={messageElement}
            className="update-text"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    );
  }
};
