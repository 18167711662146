import { html } from "common-tags";
import { ROOT } from "../../../../api/config";
import { TYPE_WORKOUT } from "../constants";

export function EmptyState({ type, client, template, plan }, isTemplate) {
  let title;




  if (isTemplate) {
    title = type === TYPE_WORKOUT ? window.jsTrans('emptyState.workoutTemplate', 'plans') : window.jsTrans('emptyState.mealTemplate', 'plans');
  }

  const btn = type === TYPE_WORKOUT ? window.jsTrans('emptyState.useWorkoutTemplate', 'plans') : window.jsTrans('emptyState.useMealTemplate', 'plans');
  const scratchUrl = type === TYPE_WORKOUT ? `${ROOT}/workout/from-scratch/${plan.id}` : `${ROOT}/meal/from-scratch/${plan.id}`;

  const description = type === TYPE_WORKOUT ?
      window.jsTrans('emptyState.welcomeWorkoutBuilder', 'plans') :
      window.jsTrans('emptyState.welcomeMealPlanBuilder', 'plans');

  return html`
    <div class="empty-state">
      <h2 class="empty-state-title">${window.jsTrans('emptyState.create', 'plans')} ${title}</h2>
      <p class="empty-state-description">${description}</p>
      <div class="empty-state-actions">
        <button class="btn btn-primary js-use-template">${btn}</button>
        <span class="or-selector">${window.jsTrans('emptyState.or', 'plans')}</span>
        <a
          role="button"
          href="${scratchUrl}"
          class="btn btn-default btnStart"
        >${window.jsTrans('emptyState.startFromScratch', 'plans')}</a>
      </div>
    </div>
  `;
}
