import { ClientFoodPreferencesType } from "../../../../generated/graphql";
import { Stack, tooltipClasses, Typography } from "@mui/material";
import Label from "../../../../components/_MUI/Label";
import { HtmlTooltip } from "../../../../components/HtmlTooltip";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

type TScreeningQuestion = Pick<
  ClientFoodPreferencesType,
  | "__typename"
  | "uncomfortablyFull"
  | "significantLoss"
  | "lostControl"
  | "foodDominates"
  | "feelsFat"
>;

interface Props {
  screeningQuestions: TScreeningQuestion | null | undefined;
  shortLabel?: boolean;
}

export default function Screening(props: Props) {
  const { screeningQuestions, shortLabel } = props;
  const { t } = useTranslation([TranslatorNS.MESSAGES, TranslatorNS.CLIENT_OVERVIEW]);
  if (!screeningQuestions) return null;
  const { __typename, ...rest } = screeningQuestions;

  const count = Object.values(rest || []).filter(c => c).length;
  const show = Boolean(count);
  const totalCount = Object.keys(rest).length;
  const style = {
    ...(show && { cursor: "help!important" }),
  };

  return (
    <HtmlTooltip
      enterDelay={500}
      title={<TooltipContent clientFoodPreferences={rest} t={t} />}
      sx={{
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 400,
          minWidth: "20rem",
        },
      }}
      placement={"right"}>
      <Stack flexDirection={"row"} sx={{ ...style, width: "100%" }}>
        <Label sx={style} color={show ? "error" : "success"}>
          {shortLabel
            ? t(`newClientOverview.generalCard.shortScreening`, {
                count,
                totalCount,
                ns: TranslatorNS.CLIENT_OVERVIEW,
              })
            : t("newClientOverview.screening", { ns: TranslatorNS.CLIENT_OVERVIEW })}
        </Label>
        {!shortLabel && (
          <Typography sx={{ ...style, ml: 1 }}>
            {show
              ? t("newClientOverview.generalCard.screening", {
                  count,
                  totalCount,
                  ns: TranslatorNS.CLIENT_OVERVIEW,
                })
              : t("newClientOverview.generalCard.noScreening", {
                  ns: TranslatorNS.CLIENT_OVERVIEW,
                })}
          </Typography>
        )}
      </Stack>
    </HtmlTooltip>
  );
}

const TooltipContent = ({
  clientFoodPreferences,
  t,
}: {
  clientFoodPreferences: Omit<TScreeningQuestion, "__typename">;
  t: any;
}) => {
  return (
    <Stack p={1}>
      <Typography variant={"subtitle2"} sx={{ mb: 2 }}>
        {t("newClientOverview.generalCard.screeningTitle", {
          ns: TranslatorNS.CLIENT_OVERVIEW,
        })}
      </Typography>
      {Object.entries(clientFoodPreferences).map(([question, val]) => {
        return (
          <Stack
            key={question}
            mb={1}
            flexDirection={"row"}
            justifyContent={"space-between"}>
            <Typography fontSize={12} color={"#637381"}>
              {t(`client.survey.eatingDisorder.${question}`)}
            </Typography>
            <Label
              fontSize={12}
              sx={{ minWidth: 50, ml: 1 }}
              color={val ? "error" : "success"}>
              {t(val ? "general.yes" : "general.no")}
            </Label>
          </Stack>
        );
      })}
    </Stack>
  );
};
