import { Dispatch } from "redux";
import { WorkoutplanActionTypes } from "../types";
import * as api from "../../api";
import { extractResponseError } from "../../../../helpers/error";
import { formatWorkoutplan } from "../helpers/formatData";
import { WorkoutPlan } from "../../../../interfaces/meal";

export const FETCH_WORKOUTPLANS_REQUEST = "workoutplan/FETCH_REQUEST";
export const FETCH_WORKOUTPLANS_SUCCESS = "workoutplan/FETCH_SUCCESS";
export const FETCH_WORKOUTPLANS_FAILURE = "workoutplan/FETCH_FAILURE";

// Types
export interface FetchWorkoutplansRequest {
  type: typeof FETCH_WORKOUTPLANS_REQUEST;
}
export interface FetchWorkoutplansSuccess {
  type: typeof FETCH_WORKOUTPLANS_SUCCESS;
  payload: any[];
}
export interface FetchWorkoutplansFailure {
  type: typeof FETCH_WORKOUTPLANS_FAILURE;
  payload: any;
}

function fetchWorkoutPlanRequest(): FetchWorkoutplansRequest {
  return {
    type: FETCH_WORKOUTPLANS_REQUEST,
  };
}

export function fetchWorkoutPlanSuccess(data: any): FetchWorkoutplansSuccess {
  return { type: FETCH_WORKOUTPLANS_SUCCESS, payload: data };
}

export function fetchWorkoutPlanFail(error: any): FetchWorkoutplansFailure {
  return {
    type: FETCH_WORKOUTPLANS_FAILURE,
    payload: extractResponseError(error),
  };
}

export const fetchWorkoutplans = (clientId: number) => {
  return async (dispatch: Dispatch<WorkoutplanActionTypes>) => {
    if (clientId < 0) return;

    dispatch(fetchWorkoutPlanRequest());
    try {
      const data = await api.getWorkoutplans(clientId);
      const formattedData = data.map((workoutplan: WorkoutPlan) =>
        formatWorkoutplan(workoutplan, clientId),
      );
      dispatch(fetchWorkoutPlanSuccess(formattedData));
      // HACK: reload page (later connect to redux for optimistic updates)
      // window.location.reload();
    } catch (error: any) {
      dispatch(fetchWorkoutPlanFail(error));
    }
  };
};
