import { ReactNode } from "react";
import { CircularProgress, Stack, Theme } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { SkeletonPropsVariantOverrides } from "@mui/material/Skeleton/Skeleton";
import { SxProps } from "@mui/system";
import { CircularProgressPropsColorOverrides } from "@mui/material/CircularProgress/CircularProgress";
import { ApolloError } from "@apollo/client";
import RHFGraphqlError from "../../components/_MUI/hook-form/RHFGraphqlError";

interface Props {
  sx?: SxProps<Theme>;
  children: ReactNode;
  loading: boolean;
  width?: number | string;
  height?: number | string;
  variant?: OverridableStringUnion<
    "text" | "rectangular" | "circular",
    SkeletonPropsVariantOverrides
  >;
  color?: OverridableStringUnion<
    "primary" | "secondary" | "error" | "info" | "success" | "warning" | "inherit",
    CircularProgressPropsColorOverrides
  >;
  size?: number | string;
  error?: ApolloError;
}

export default function LoadingContainer(props: Props) {
  const {
    sx = {},
    children,
    loading = false,
    width = "100%",
    height = "100%",
    error,
  } = props;

  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Stack
      sx={sx}
      width={width}
      height={height}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}>
      {error ? <RHFGraphqlError error={error} /> : <CircularProgress />}
    </Stack>
  );
}
