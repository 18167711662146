import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import ReactPlayer from "react-player/lazy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton, CircularProgress, Stack, styled, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";
import {
  DialogContext,
  ModalContextForType,
  WorkoutPlanModalType,
} from "./DialogProvider";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoDialog() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { modal, setModal } = React.useContext(DialogContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [loadingVideo, setloadingVideo] = React.useState(false);

  const isOpen = modal.type === WorkoutPlanModalType.VIDEO;
  const context = modal?.context as ModalContextForType<WorkoutPlanModalType.VIDEO>;

  const handleClose = () => setModal({ type: null });

  React.useEffect(() => {
    setloadingVideo(true);

    return () => {
      setloadingVideo(false);
    };
  }, [context?.video]);

  console.log({ video: context?.video });

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        // maxWidth={"md"}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
          <CloseOutlinedIcon />
        </IconButton>
        <DialogTitle>{context?.title}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description"> */}
          {context?.video && loadingVideo && (
            <LoaderWrapper alignItems="center" justifyContent="center">
              <CircularProgress />
              <Typography>{t("Loading video...")}</Typography>
            </LoaderWrapper>
          )}
          {context?.video && (
            <ReactPlayer
              onReady={() => setloadingVideo(false)}
              url={context?.video}
              controls
              width="100%"
            />
          )}
          {!context?.video && loadingVideo && t("No video available")}
          {/* </DialogContentText> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const LoaderWrapper = styled(Stack)({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
});
