export const S3_CLIENT_PHOTO =
  "https://zenfit-images.s3.eu-central-1.amazonaws.com/before-after-images/client/photo/";

export const ERROR_MESSAGE =
  "Something went wrong. Please try again later or contact support";

export const MODAL_STATE = "MODAL_STATE";
export enum ContentState {
  NONE = "none",
  FULL = "full",
  EMPTY = "empty",
}

export const KEY_TO_FIND_CLIENT_NAME = "_client_name_%";
