/**
 * TODO: when splitting project make this reducer shared across the app
 */
export const SET_MANUAL_PLAN_MODAL = "SET_MANUAL_PLAN_MODAL";
export const SET_EDIT_WORKOUT_PLAN_MODAL = "SET_EDIT_WORKOUT_PLAN_MODAL";

export interface ModalState {
  manualPlanModalVisible: boolean;
  generatedPlanModalVisible: boolean;
  editWorkoutPlan: number | null;
}

interface SetManualPlanModal {
  type: typeof SET_MANUAL_PLAN_MODAL;
  payload: boolean;
}
interface SetEditWorkoutPlanModal {
  type: typeof SET_EDIT_WORKOUT_PLAN_MODAL;
  payload: number | null;
}

export type ModalActionTypes = SetManualPlanModal | SetEditWorkoutPlanModal;
