import { ROOT } from "../config";

export const GET_LEADS = () => {
  return `${ROOT}/leads/api`;
};

export const ADD_UPDATE_LEAD = () => {
  return `${ROOT}/leads/api/create`;
};

export const DELETE_LEAD = () => {
  return `${ROOT}/leads/api/delete`;
};

export const GET_LEAD_TAGS = () => {
  return `${ROOT}/api/trainer/get-lead-tags-by-user`;
};
