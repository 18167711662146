import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import TransferForm from "./TransferForm";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import { Stack } from "@mui/material";
import TransferInProgress from "./TransferInProgress";
import { TransferProgressEnum } from "../../../../generated/graphql";

export default function LenusTransferStep() {
  const { transferInProgress, loading } = useLenusTransferContext();

  const renderStep = () => {
    if (transferInProgress === TransferProgressEnum.NotStarted) {
      return <TransferForm />;
    }

    return <TransferInProgress />;
  };

  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      width={"100vw"}>
      <SkeletonContainer
        loading={loading}
        sx={{
          mt: 4,
          width: { xs: "60vw", sm: "60%" },
          height: { xs: "65vh", sm: "80%" },
          bgcolor: "grey.900",
        }}>
        {renderStep()}
      </SkeletonContainer>
    </Stack>
  );
}
