import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { GridSelectionModel, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { ClientOrderByEnum, SortingDirectionEnum } from "../../generated/graphql";

export enum ClientFilterTabEnum {
  Active = "Active",
  Pending = "Pending",
  Ended = "Ended",
}

export enum FiltersEnum {
  activated = "activated",
  paymentFailed = "paymentFailed",
  requestedUnsubscribe = "requestedUnsubscribe",
  ending = "ending",
  checkIn = "checkIn",
  missingCheckIn = "missingCheckIn",
  updatePlans = "updatePlans",
  noContact = "noContact",
  unreadMessage = "unreadMessage",
  dueReminders = "dueReminders",
  futureReminders = "futureReminders",
  //pending
  pending = "pending",
  paymentPending = "paymentPending",
  questionnairePending = "questionnairePending",
  invitePending = "invitePending",
  createPlans = "createPlans",
  readyForActivation = "readyForActivation",
}

export type ClientListContextProps = {
  setFilter: (key: FiltersEnum) => void;
  setActiveTab: (key: ClientFilterTabEnum) => void;
  setSearchQuery: (q: string | undefined) => void;
  setUserTags: (q: string[]) => void;
  setTags: any;
  setSelectedClients: (q: GridSelectionModel) => void;
  setPage: (q: number) => void;
  setOrderBy: (m: GridSortModel) => void;
  setDatagridCoordinates: (coordinates: { rowIndex: number; colIndex: number }) => void;
  searchQuery: string | undefined;
  filter: FiltersEnum;
  activeTab: ClientFilterTabEnum;
  tags: string[];
  userTags: string[];
  page: number;
  selectedClients: GridSelectionModel;
  orderBy: GridSortModel;
  datagridCoordinates: { rowIndex: number; colIndex: number };
};

const initialState: ClientListContextProps = {
  setSelectedClients: () => {},
  setFilter: () => {},
  setSearchQuery: () => {},
  setActiveTab: () => {},
  setTags: () => {},
  setUserTags: () => {},
  setPage: () => {},
  setOrderBy: () => {},
  setDatagridCoordinates: () => {},
  searchQuery: undefined,
  datagridCoordinates: { rowIndex: 0, colIndex: 0 },
  tags: [],
  userTags: [],
  filter: FiltersEnum.activated,
  activeTab: ClientFilterTabEnum.Active,
  selectedClients: [],
  page: 1,
  orderBy: [
    {
      field: ClientOrderByEnum.Name,
      sort: SortingDirectionEnum.Asc.toLowerCase() as unknown as GridSortDirection,
    },
  ],
};

const ClientListContext = createContext(initialState);

function ClientListProvider({ children }: { children: ReactNode }) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    initialState.searchQuery,
  );
  const [tags, setTags] = useState<string[]>(initialState.tags);
  const [activeTab, setActiveTab] = useState<ClientFilterTabEnum>(initialState.activeTab);
  const [filter, setFilter] = useState(initialState.filter);
  const [userTags, setUserTags] = useState<string[]>(initialState.userTags);
  const [selectedClients, setSelectedClients] = useState<GridSelectionModel>(
    initialState.selectedClients,
  );
  const [page, setPage] = useState(initialState.page);
  const [orderBy, setOrderBy] = useState(initialState.orderBy);
  const [datagridCoordinates, setDatagridCoordinates] = useState(
    initialState.datagridCoordinates,
  );

  useEffect(() => {
    if (!!selectedClients.length) {
      setSelectedClients(initialState.selectedClients);
    }
    setPage(initialState.page);
  }, [activeTab, filter, searchQuery, tags]);

  useEffect(() => {
    if (activeTab === ClientFilterTabEnum.Pending) {
      setOrderBy([{ field: "status", sort: "asc" }]);
    } else {
      setOrderBy([{ field: ClientOrderByEnum.Name, sort: "asc" }]);
    }
  }, [activeTab]);

  return (
    <ClientListContext.Provider
      value={{
        setDatagridCoordinates,
        setActiveTab,
        setFilter,
        setSearchQuery,
        setUserTags,
        setTags,
        setSelectedClients,
        setPage,
        setOrderBy,
        orderBy,
        tags,
        userTags,
        activeTab,
        filter,
        searchQuery,
        selectedClients,
        page,
        datagridCoordinates,
      }}>
      {children}
    </ClientListContext.Provider>
  );
}

const useClientListContext = () => useContext(ClientListContext);

export { ClientListProvider, useClientListContext, ClientListContext };
