import React from "react";
import {
  IconButton,
  styled,
  Typography,
  Stack,
  TextField,
  Popover,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-ui/core";
import {
  GetRecipeDocument,
  LocaleEnum,
  RecipeIngredientType,
  useUpdateRecipeIngredientMutation,
} from "../../../generated/graphql";
import { useParams } from "react-router-dom";
import { TranslatorNS } from "../../../i18n/const";
import { capitalCase, sentenceCase } from "change-case";
import Iconify from "../../../components/_MUI/Iconify";

const StyledBlockWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  background: theme.palette.grey[100],
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 12,
  paddingRight: 12,
  marginBottom: 8,
  border: `solid ${theme.palette.grey[300]}`,
  borderWidth: 1,
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",

  cursor: "grab",
  "&:active": {
    cursor: "grabbing",
  },
}));

export default function IngredientItem({
  block,
  locale,
  deleteItem,
}: {
  block: RecipeIngredientType;
  locale: LocaleEnum;
  deleteItem: (blockId: number) => void;
}) {
  const { templateId } = useParams<{ templateId: string }>();
  const theme = useTheme();
  const { t } = useTranslation();
  const [updateRecipeIngredient] = useUpdateRecipeIngredientMutation();
  const [selectedUnit, setSelectedUnit] = React.useState<number | null>(
    block.unit?.id || null,
  );
  const unitAmountsMap = block.ingredient?.units?.reduce(
    (acc, unit) => ({
      ...acc,
      [unit.id]: block.unit?.id === unit.id ? block.unitAmount : 1,
    }),
    {} as { [id: string]: number },
  );

  const [unitAmounts, setUnitAmounts] = React.useState<{ [id: string]: number }>({
    ...unitAmountsMap,
    null: Number(block.amount),
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [amountAnchorEl, setAmountAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleAmount = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAmountAnchorEl(event.currentTarget);
  };

  const handleCloseAmount = () => {
    // Prevent from updating if nothing changed
    if (
      selectedUnit == null &&
      block.unit == null &&
      unitAmounts["" + null] == Number(block.amount)
    ) {
      return setAmountAnchorEl(null);
    }
    // Prevent from updating if nothing changed
    if (
      selectedUnit === block.unit?.id &&
      unitAmounts[selectedUnit] == Number(block.unitAmount)
    ) {
      return setAmountAnchorEl(null);
    }
    if (selectedUnit) {
      const unitSelected = block.ingredient?.units?.find(u => u.id === selectedUnit);
      if (!unitSelected) {
        return setAmountAnchorEl(null);
      }
      const amountInGrams = unitAmounts[selectedUnit] * Number(unitSelected.weight);
      console.log({ amountInGrams });
      updateRecipeIngredient({
        variables: {
          id: block.id,
          input: {
            amount: Math.round(amountInGrams),
            unitAmount: Number(unitAmounts[selectedUnit]),
            unitId: selectedUnit,
          },
        },
        refetchQueries: () => [
          {
            query: GetRecipeDocument,
            variables: { id: Number(templateId), locale },
          },
        ],
      });
    } else {
      // if in grams the selected unit is null
      const amountInGrams = unitAmounts["" + null];
      if (amountInGrams) {
        updateRecipeIngredient({
          variables: {
            id: block.id,
            input: {
              amount: amountInGrams,
              unitId: null,
            },
          },
          refetchQueries: () => [
            {
              query: GetRecipeDocument,
              variables: { id: Number(templateId), locale },
            },
          ],
        });
      }
    }

    setAmountAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    deleteItem(block.id as number);
    handleClose();
  };

  console.log({ unitAmounts });

  const totalKcal = (Number(block.ingredient?.kcal) / 100) * Number(block.amount);

  return (
    <StyledBlockWrapper id={"recipe-list-item"} className={"handle"} data-id={block.id}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%" }}>
        <Stack direction="row" alignItems="center" sx={{ overflow: "hidden", pr: 2 }}>
          <Stack>
            <Tooltip
              enterDelay={500}
              enterNextDelay={1000}
              placement="top"
              title={block.ingredient?.name || ""}>
              <Typography variant="subtitle2" fontSize={12} noWrap>
                {block.ingredient?.name}
              </Typography>
            </Tooltip>
            <Tooltip
              enterDelay={500}
              enterNextDelay={1000}
              placement="top"
              title={block.ingredient?.name || ""}>
              {block.ingredient?.protein ||
              block.ingredient?.carbohydrates ||
              block.ingredient?.fat ? (
                <Typography
                  variant="body2"
                  noWrap
                  fontSize={10}
                  color={theme.palette.text.secondary}>
                  {t("generateMealPlan.sharedPCFGram", {
                    ns: TranslatorNS.MEAL_PLAN,
                    protein: block.ingredient?.protein,
                    carbs: block.ingredient?.carbohydrates,
                    fat: block.ingredient?.fat,
                  })}
                </Typography>
              ) : (
                <></>
              )}
            </Tooltip>
          </Stack>
        </Stack>

        <Stack direction="row" sx={{ maxWidth: "55%", height: 3 }}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={4}>
            <Box minWidth={80}>
              <Typography
                variant="body2"
                noWrap
                display={"inline-flex"}
                fontSize={12}
                fontWeight={700}
                role="button"
                onClick={handleAmount}
                sx={{
                  py: 0.5,
                  px: 1,
                  borderRadius: 1,
                  backgroundColor: Boolean(amountAnchorEl)
                    ? theme.palette.grey[400]
                    : "inherit",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[400],
                    cursor: "pointer",
                  },
                }}>
                {block?.unit?.id
                  ? `${block.unitAmount} ${block.unit.name}`
                  : `${block.amount}g`}
              </Typography>
              <Popover
                id={`${block.id}`}
                open={Boolean(amountAnchorEl)}
                anchorEl={amountAnchorEl}
                onClose={handleCloseAmount}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}>
                <Stack p={2}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group">
                    <FormControlLabel
                      key={"grams"}
                      value={null}
                      control={<Radio checked={selectedUnit === null} />}
                      onChange={() => setSelectedUnit(null)}
                      sx={{ marginRight: 0 }}
                      label={
                        <Stack
                          width={340}
                          direction={"row"}
                          alignItems="center"
                          spacing={1}
                          justifyContent="space-between">
                          <Typography width="100%">
                            {sentenceCase("grams" || "")}
                          </Typography>

                          <Stack
                            direction="row"
                            width="100%"
                            justifyContent="flex-end"
                            alignItems={"center"}
                            spacing={1}>
                            <TextField
                              onKeyDown={e => {
                                if (e.key === "Enter") {
                                  handleCloseAmount();
                                }
                              }}
                              value={unitAmounts["" + null]}
                              onChange={e => {
                                setUnitAmounts({
                                  ...unitAmounts,
                                  null: Number(e.target.value),
                                });
                              }}
                              sx={{ width: 100 }}
                              size="small"
                            />
                            <Typography sx={{ textAlign: "right", width: 80 }}>
                              (
                              {Math.round(
                                (unitAmounts["" + null] / 100) *
                                  Number(block.ingredient?.kcal),
                              )}{" "}
                              {t("kcal")})
                            </Typography>
                          </Stack>
                        </Stack>
                      }
                    />
                    {block.ingredient?.units.map(unit => (
                      <FormControlLabel
                        key={unit.id}
                        value={unit.id}
                        control={<Radio checked={selectedUnit === unit.id} />}
                        onChange={() => setSelectedUnit(unit.id)}
                        sx={{ mt: 1, marginRight: 0 }}
                        label={
                          <Stack
                            minWidth={340}
                            direction={"row"}
                            alignItems="center"
                            spacing={1}
                            justifyContent="space-between">
                            <Typography width="100%">
                              {sentenceCase(unit.name || "")}
                            </Typography>
                            <Stack
                              direction="row"
                              width="100%"
                              justifyContent="flex-end"
                              alignItems={"center"}
                              spacing={1}>
                              <TextField
                                onKeyDown={e => {
                                  if (e.key === "Enter") {
                                    handleCloseAmount();
                                  }
                                }}
                                type="number"
                                label={capitalCase(unit?.name || "")}
                                value={unitAmounts[unit.id]}
                                onChange={e => {
                                  setUnitAmounts({
                                    ...unitAmounts,
                                    [unit.id]: e.target.value.replace(",", "."),
                                  });
                                }}
                                sx={{ width: 100 }}
                                size="small"
                              />
                              <Typography sx={{ textAlign: "right", width: 80 }}>
                                (
                                {Math.round(
                                  ((unitAmounts[unit.id] * Number(unit.weight)) / 100) *
                                    Number(block.ingredient?.kcal),
                                )}{" "}
                                {t("kcal")})
                              </Typography>
                            </Stack>
                          </Stack>
                        }
                      />
                    ))}
                  </RadioGroup>
                </Stack>
              </Popover>
            </Box>
            <Typography
              variant="body2"
              color="grey.500"
              noWrap
              minWidth={80}
              fontSize={12}>
              {block.amount}g
            </Typography>
            <Typography
              variant="body2"
              noWrap
              minWidth={80}
              color="grey.500"
              fontSize={12}>
              {block.ingredient?.kcal ? (
                `${Math.round(totalKcal)} ${t("kcal")}`
              ) : (
                <Tooltip title={t("Spices don't have calories")}>
                  <Typography>-</Typography>
                </Tooltip>
              )}
            </Typography>
          </Stack>
          <Box sx={{ alignSelf: "center", ml: 1 }}>
            <IconButton sx={{ fontSize: 16 }} onClick={handleDelete}>
              <Iconify icon={"uis:multiply"} />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </StyledBlockWrapper>
  );
}
