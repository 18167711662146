import numeral from "numeral";

// ----------------------------------------------------------------------

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fData(number: string | number) {
  return numeral(number).format("0.0 b");
}
