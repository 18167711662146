import { html } from "common-tags";
import { BoardHeader } from "./Board";
import { SearchForm } from "./Search";
import { OwnExercise } from "./OwnExerciseOption";
import { EmptyState } from "./EmptyState";
import { Intro } from "./Intro";
import { PlanComment } from "./Plan";
import { TYPE_MEAL, TYPE_WORKOUT } from "../constants";

export function Layout({ data, updatedAt, type, equipments, muscles, locale, empty, client, plan, q, showIntro, template, options, isWorkout }, isTemplate) {
  if (empty) {
    return html`
      ${EmptyState({ type, client, template, plan }, isTemplate)}
    `;
  }

  let displayIntro = type === TYPE_MEAL
    ? `<div class="row intro-box">${showIntro ? Intro() : ''}</div>`
    : '';

  let planComment = '';

  if (type === TYPE_WORKOUT) {
    planComment = PlanComment(plan, type, false, window.jsTrans('layout.writeOverallComment', 'plans'), null, {
      containerClass: 'plans-board-comment',
    });
  }

  const title = type === TYPE_WORKOUT ? window.jsTrans('layout.exercises', 'plans') : window.jsTrans('layout.foodItems', 'plans');
  type === TYPE_WORKOUT ? window.jsTrans('layout.createYourExercise', 'plans') : window.jsTrans('layout.createYourFoodItem', 'plans');

  const searchBar = `
    <aside class="plans-search">
      ${SearchForm({ type, equipments, muscles, locale, q })}
      <div class="plans-search-title">
        <p>${title}</p>
        <div style="flex: 1"></div>
        ${isWorkout ? OwnExercise() : ''}
      </div>
      <div class="plans-search-result has-touch-scrolling"></div>
    </aside>
  `;

  return `
    ${searchBar}
    <section class="plans-board">
        ${BoardHeader({ updatedAt, type, plan, client, options, data }, isTemplate)}
        <div class="plans-board-list"></div>
        ${displayIntro}
        ${planComment}
    </div>
    <div class="plans-temp"></div>
    </section>
  `;


}
