import i18next from "i18next";
import format from "date-fns/format";

import { ClientTiers } from "../config";
import { TranslatorNS } from "../i18n/const";
import { LocaleEnum } from "../generated/graphql";
import { startOfDay } from "date-fns";

export const isHexTooBright = (color: string) => {
  const hex = color.replace("#", "");
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 230;
};

export const handleSingleState = (
  setState: (arg: any) => void,
  name: string,
  val: any,
) => {
  setState((prevState: object) => ({
    ...prevState,
    [name]: val,
  }));
};
export const handleMultipleState = (setState: (arg: any) => void, object: object) => {
  setState((prevState: object) => ({
    ...prevState,
    ...object,
  }));
};

export const getNextClientTier = (number: number | string): ClientTiers | 0 => {
  if (number > 0 && number <= ClientTiers.STARTER) {
    return ClientTiers.GROWTH;
  } else {
    return ClientTiers.PROFESSIONEL;
  }
};

export const getZenfitHomeUrl = (locale: LocaleEnum) => {
  const homeUrl = "https://zenfit.io/";
  switch (locale) {
    case LocaleEnum.DaDk:
      return homeUrl + "da";
    case LocaleEnum.SvSe:
      return homeUrl + "se";
    case LocaleEnum.DeDe:
      return homeUrl + "de";
    case LocaleEnum.NbNo:
      return homeUrl + "no";
    case LocaleEnum.NlNl:
      return homeUrl + "nl";
    case LocaleEnum.FiFi:
      return homeUrl + "fi";
    case LocaleEnum.EsEs:
      return homeUrl + "es";
    default:
      return homeUrl;
  }
};

export const getClientInitials = (name: string) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName?.[0]?.toUpperCase() ?? ""}${lastName?.[0]?.toUpperCase() ?? ""}`;
};

/*
 * Native structure of react-select options and value
 */
export const getUserTagsArrayToReactSelect = (
  arr: (string | undefined)[] | undefined,
): { value: string; label: string }[] => {
  return arr
    ? arr?.reduce(
        (p: { value: string; label: string }[], c) =>
          c ? p.concat({ value: c, label: c.toUpperCase() }) : p,
        [],
      )
    : [];
};

/*
 * Stupid design from ZFSelect: It needs to have an obj of { [value: string]: string }
 * instead of native structure of react select { value: string; label: string }[]
 * So ZF select fields need to have the options parsed, so it can parse it again
 * To Native :(
 */
export const getUserTagsArrayToSelectOptionsContent = (
  arr: string[],
): { [value: string]: string } => {
  const options = arr?.reduce((p, c) => ({ ...p, [c]: c.toUpperCase() }), {});
  const t = (key: string) => i18next.t(key, { ns: TranslatorNS.LISTS });
  return { ...{ "#all": t("videos.main.all") }, ...options };
};

export const formatDate = (date: Date | null | undefined) => {
  if (!date) return undefined;

  try {
    return format(startOfDay(date), "yyyy-MM-dd");
  } catch (e) {
    return undefined;
  }
};

export const scrollToElementId = (id: string) => {
  document
    .getElementById(`zf_${id}`)
    ?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
};
