import { CurrencyEnum } from "./currency";

export interface Subscription {
  title: string;
  price: number;
  clients: number;
  currency: CurrencyEnum;
  id: number;
  isTrial: boolean;
}

export enum SubscriptionType {
  UNSUBSCRIBE = "unsubscribe",
  PAUSE = "pause",
}

export enum SubscriptionStatus {
  CANCELED = "canceled",
  PAUSED = "paused",
}
