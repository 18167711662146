import RHFGraphqlError from "../../components/_MUI/hook-form/RHFGraphqlError";
import { Card, Skeleton, Stack, Typography } from "@mui/material";
import { ApolloError } from "@apollo/client";
import { ReactNode } from "react";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles/createTheme";

interface Props {
  title?: string | ReactNode;
  icon?: ReactNode;
  error?: ApolloError | undefined;
  skeletonHeight?: number;
  sx?: SxProps<Theme>;
  loading?: boolean;
  children: ReactNode;
}

export default function CardContainer(props: Props) {
  const { title, loading, error, children, skeletonHeight, icon, sx } = props;

  const renderContent = () => {
    if (loading) {
      return (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={((skeletonHeight || 1) / 100) * 70}
        />
      );
    }

    if (error) {
      return <RHFGraphqlError error={error} />;
    }
    return children;
  };

  return (
    <Card sx={{ ...sx, px: 3, py: 2 }}>
      <Stack height={"100%"}>
        {(title || icon) && (
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            mt={1}
            mb={2}
            alignItems={"center"}>
            {title && <Typography variant={"h6"}>{title}</Typography>}
            {icon && <Stack>{icon}</Stack>}
          </Stack>
        )}
        {renderContent()}
      </Stack>
    </Card>
  );
}
