import React from "react";
import { Stack, Typography } from "@mui/material";
import Chart, { useChart } from "../../../components/_MUI/chartv4.2";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

export default function ListHeader({
  carbs,
  fat,
  protein,
  totalKcal,
  ingredientCount,
  spiceCount,
}: {
  carbs: number;
  fat: number;
  protein: number;
  totalKcal: number;
  ingredientCount: number;
  spiceCount: number;
}) {
  const theme = useTheme();
  const { t } = useTranslation([TranslatorNS.PLANS]);

  const percentageOfProteinOfTotalKcal = Math.round(((protein * 4) / totalKcal) * 100);
  const percentageOfCarbsOfTotalKcal = Math.round(((carbs * 4) / totalKcal) * 100);
  const percentageOfFatOfTotalKcal = Math.round(((fat * 9) / totalKcal) * 100);

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" px={3} pt={2} sx={{backgroundColor: "#FCFCFC"}}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="subtitle1" sx={{fontWeight: 600}}>
          {ingredientCount} {t("layout.ingredients")}
        </Typography>
        <Typography color={"text.secondary"}>•</Typography>
        <Typography variant="body1" color={"text.secondary"}>
          {spiceCount} {t("layout.spices")}
        </Typography>
      </Stack>
      <Stack direction="row" mt={1}>
        <Stack direction="row" alignItems="center">
          <ChartDonut
            labels={[t("plan.protein"), ""]}
            series={[
              percentageOfProteinOfTotalKcal,
              100 - percentageOfProteinOfTotalKcal,
            ]}
            colors={["#0038b7", theme.palette.grey[400]]}
          />
          <Typography sx={{ ml: -1, mt: -0.75 }} fontSize={12}>
            {t("plan.protein")}: {Math.round(protein)}g ({percentageOfProteinOfTotalKcal || 0}
            %)
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <ChartDonut
            labels={[t("plan.carbs"), ""]}
            series={[percentageOfCarbsOfTotalKcal, 100 - percentageOfCarbsOfTotalKcal]}
            colors={["#ffc107", theme.palette.grey[400]]}
          />
          <Typography sx={{ ml: -1, mt: -0.75 }} fontSize={12}>
            {t("plan.carbs")}: {Math.round(carbs)}g ({percentageOfCarbsOfTotalKcal || 0}%)
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <ChartDonut
            labels={[t("plan.fat"), ""]}
            series={[percentageOfFatOfTotalKcal, 100 - percentageOfFatOfTotalKcal]}
            colors={["#ff4842", theme.palette.grey[400]]}
          />
          <Typography sx={{ ml: -1, mt: -0.75 }} fontSize={12}>
            {t("plan.fat")}: {Math.round(fat)}g ({percentageOfFatOfTotalKcal || 0}%)
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <ChartDonut
            labels={[t("plan.protein"), t("plan.carbs"), t("plan.fat")]}
            series={[
              percentageOfProteinOfTotalKcal,
              percentageOfCarbsOfTotalKcal,
              percentageOfFatOfTotalKcal,
            ]}
          />
          <Typography sx={{ ml: -1, mt: -0.75 }} fontSize={12}>
            {t("board.total")}: {Math.round(totalKcal)} {t("board.kcalAbbr")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

// LITTLE DONUT CHART can be moved and reused if needed

export function ChartDonut({
  series,
  labels = [],
  colors = ["#0038b7", "#ffc107", "#ff4842"],
}: {
  series: number[];
  labels?: string[];
  colors?: string[];
}) {
  const chartOptions = useChart({
    labels,
    colors,
    stroke: {
      width: 0,
      curve: "smooth",
      lineCap: "round",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: colors,
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },

    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
          size: "60%",
        },
      },
    },
  });

  return (
    <Chart type="donut" series={series} options={chartOptions} height={50} width={50} />
  );
}
