import { TFunction, useTranslation } from "react-i18next";
import { getUnixEpoch } from "../../../../../helpers/dates";
import DateConfirmModal from "../../../../../components/Modal/DateConfirmModal";
import { AppState } from "../../../../../store";
import { RequestState } from "../../../../../interfaces/requestState";
import { connect } from "react-redux";
import { pauseSubscription } from "../../../../Clients/store/clients/actions";
import {
  GetClientSubscriptionQuery,
  useGetClientSubscriptionLazyQuery,
} from "../../../../../generated/graphql";

interface Props {
  open: boolean;
  clientId: number;
  handleClose: VoidFunction;
  // date: unix
  // pause: should always be true for this one as it is a flag
  dispatchPauseSubscription: (
    clientId: number,
    unix: number,
    pause: true,
    t: TFunction<"clientOverview", undefined>,
    refetch: () => void,
  ) => void;
  isSubmitting: boolean;
  payment?: GetClientSubscriptionQuery["client"]["paymentConfiguration"];
}
function PauseSubscriptionContainer({
  open,
  clientId,
  handleClose,
  dispatchPauseSubscription,
  payment,
  isSubmitting,
}: Props) {
  const { t } = useTranslation("clientOverview");
  const [_, { refetch }] = useGetClientSubscriptionLazyQuery({
    variables: {
      id: Number(clientId),
    },
  });

  const handlePauseSubscription = (date: Date) => {
    const unixTime = getUnixEpoch(date);
    if (!clientId || !unixTime) return alert("Something went wrong");
    dispatchPauseSubscription(clientId, unixTime, true, t, () => refetch());
    handleClose();
  };

  return (
    <DateConfirmModal
      open={open}
      title={t("clientPayment.pauseSub")}
      description={t("clientPayment.subWillBePausedUntil")}
      handleClose={handleClose}
      onConfirm={handlePauseSubscription}
      isSubmitting={isSubmitting}
    />
  );
}

function mapStateToProps(state: AppState) {
  return {
    clientId: state.currentClient.id,
    isSubmitting: state.currentClient.subscriptionRequestState === RequestState.PENDING,
  };
}

const mapDispatchToProps = {
  dispatchPauseSubscription: pauseSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(PauseSubscriptionContainer);
