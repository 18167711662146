import _ from "lodash";
import i18n from "../i18n/helpers/translator";
import { TranslatorNS } from "../i18n/const";
import { ROOT } from "../api/config";

function validatePasswordConfirm(
  fields: any,
  fieldKey: string,
  fieldValue: any,
): { [key: string]: string } | null {
  if (fieldKey === "passwordConfirm") {
    if (fieldValue !== fields["password"]) {
      return {
        [fieldKey]: i18n.t("client.activation.passwordDontMatch", {
          ns: TranslatorNS.MESSAGES,
        }),
      };
    }
  }
  return null;
}

function validatePassword(fieldKey: string, fieldValue: any) {
  if (fieldKey === "password") {
    // @ts-ignore
    if (!(fieldValue.length >= 6)) {
      return {
        [fieldKey]: i18n.t("client.activation.passwordLength", {
          ns: TranslatorNS.MESSAGES,
        }),
      };
    }
  }
  return null;
}

function validateAssignWhen(fieldKey: string, fieldValue: any) {
  if (
    fieldKey === "assignWhen" &&
    // @ts-ignore
    fieldKey !== 0 &&
    !Number.isInteger(parseFloat(fieldValue))
  ) {
    return {
      [fieldKey]: i18n.t("client.activation.onlyWholeNumbers", {
        ns: TranslatorNS.MESSAGES,
      }),
    };
  }
  return null;
}

function validateEmail(fieldKey: string, fieldValue: any) {
  if (fieldKey === "email") {
    const isItEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!isItEmail.test(fieldValue)) {
      return {
        [fieldKey]: i18n.t("client.activation.emailInvalid", {
          ns: TranslatorNS.MESSAGES,
        }),
      };
    }
  }
  return null;
}

// TODO: this does not really validate phone number just checks that it is longer than 3
// google has a library for this if that is interesting to fix
function validatePhone(fieldKey: string, fieldValue: any) {
  if (fieldKey === "phone") {
    // @ts-ignore
    if (fieldValue?.length <= 5) {
      return {
        [fieldKey]: i18n.t("client.activation.fieldEmpty", { ns: TranslatorNS.MESSAGES }),
      };
    }
  }
  return null;
}

// TODO: write tests for this part, wasn'i18n.t sure what fields this entails
function validateMisc(fieldKey: string, fieldValue: any) {
  if (fieldValue?.length <= 0) {
    return {
      [fieldKey]: i18n.t("client.activation.fieldEmpty", { ns: TranslatorNS.MESSAGES }),
    };
  }
  if (fieldValue === null) {
    return {
      [fieldKey]: i18n.t("client.activation.fieldEmpty", { ns: TranslatorNS.MESSAGES }),
    };
  }
  return null;
}

// TODO: function camelCase
export const FieldValidators = (
  fields: any,
  translator: any = null,
  fieldsIgnored: any = [],
) => {
  let errors: any = {};

  /*
   * 0 val = options
   * '' val = field
   * [] = multi select
   *
   * */

  Object?.entries(fields)?.map(([fieldKey, fieldValue]) => {
    const passwordConfirmErrors = validatePasswordConfirm(fields, fieldKey, fieldValue);
    const passwordErrors = validatePassword(fieldKey, fieldValue);
    const assignWhenErrors = validateAssignWhen(fieldKey, fieldValue);
    const emailErrors = validateEmail(fieldKey, fieldValue);
    const phoneErrors = !fieldsIgnored.includes(fieldKey)
      ? validatePhone(fieldKey, fieldValue)
      : null;
    const miscErrors = !fieldsIgnored.includes(fieldKey)
      ? validateMisc(fieldKey, fieldValue)
      : null;

    // spreading null omits it
    errors = {
      ...errors,
      ...passwordConfirmErrors,
      ...passwordErrors,
      ...assignWhenErrors,
      ...emailErrors,
      ...phoneErrors,
      ...miscErrors,
    };
  });

  if (!_.isEmpty(errors)) {
    throw errors;
  }
};

interface Option {
  value: string;
  label: string;
}

// this one is a bit of black box to use
// would consider a different solution to using this function
// else write test for this
export const prepareOptions = (
  options: any[] | { [key: string]: string },
  translator?: any,
): Option[] => {
  if (Array.isArray(options)) {
    return translator ? options.map(key => translator(key)) : options;
  }
  return Object?.keys(options)?.map(key => {
    return {
      value: key,
      label: translator ? translator(options[key]) : options[key],
    };
  });
};

export const arrayToObj = (options: { [key: string]: string }) => {
  return Object.keys(options).map(key => {
    return {
      value: key,
      label: _.upperFirst(options[key]),
    };
  });
};

export const getImageAsset = (relativePath: string) => {
  try {
    ImageStringValidator(relativePath);
    return `${ROOT}/bundles/app/images/${relativePath}`;
  } catch (e) {
    console.error(e);
  }
};

//TODO: camelCase function
export const ImageStringValidator = (image: string) => {
  if (typeof image !== "string") {
    throw new Error("Image is not a string");
  }
  if (!image.match(/\w+\.(jpg|jpeg|gif|json|png|tiff|bmp)$/gi)) {
    throw new Error(`Image: ${image} does not have a valid image extension`);
  }
};
