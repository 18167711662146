import { AppState } from "../../../store";
import {
  EmailInvitationModalContext,
  isModalOpen,
  ModalTypeEnum,
} from "../../../store/modals/reducerTypes";
import { connect } from "react-redux";
import { closeGlobalModalAction } from "../../../store/modals/action";
import Snackbar from "@mui/material/Snackbar";
import EmailInvitationContainer from "./components/EmailInvitationContainer";

// This modal only supports payment sending for now
interface Props {
  context?: EmailInvitationModalContext;
  closeGlobalModalAction: (payload: ModalTypeEnum.EmailInvitation) => void;
  isModalOpen: boolean;
}

function EmailInvitationModal(props: Props) {
  const { context, closeGlobalModalAction, isModalOpen } = props;

  if (!isModalOpen || !context) {
    return null;
  }

  const { clientId } = context;

  if (!clientId) {
    return (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        message="To the developer: No Client Id was provided to Invitation modal"
      />
    );
  }

  return (
    <EmailInvitationContainer
      context={context}
      onClose={() => closeGlobalModalAction(ModalTypeEnum.EmailInvitation)}
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    context: state?.globalModal[
      ModalTypeEnum.EmailInvitation
    ] as EmailInvitationModalContext,
    isModalOpen: isModalOpen(ModalTypeEnum.EmailInvitation, state.globalModal),
  };
};

export default connect(mapStateToProps, { closeGlobalModalAction })(EmailInvitationModal);
