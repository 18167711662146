import { RecorderStatusEnum } from "./type";

export const INITIAL_RECORDER_STATE = {
  status: RecorderStatusEnum.NOT_READY,
  recordedChunks: [],
  timeCounter: 0,
  previewUrl: "",
  blob: {},
  error: "",
};

export const RESET_RECORDER_STATE = {
  status: RecorderStatusEnum.READY,
  timeCounter: 0,
  error: "",
  blob: {},
  recordedChunks: [],
  previewUrl: "",
};
