import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";
import { SnackbarKey, useSnackbar } from "notistack";

const RETRY_UPLOAD_SNACKBAR_KEY = "RetryUploadSnackbar";

export default function RetryUploadSnackbar(props: {
  isOpen: boolean;
  retryAction: () => void;
}) {
  const { isOpen, retryAction } = props;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [key, setKey] = useState<number>(0);

  const { t } = useTranslation(TranslatorNS.APP);

  const actionFn = (snackbarKey: SnackbarKey) => {
    return (
      <Button
        onClick={() => {
          retryAction();
          closeSnackbar(snackbarKey);
        }}
        size={"small"}
        variant={"contained"}
        color={"error"}>
        {t("videoUpload.tryAgain")}
      </Button>
    );
  };

  useEffect(() => {
    if (isOpen) {
      const snackbarKey = `${RETRY_UPLOAD_SNACKBAR_KEY}-${key}`;
      enqueueSnackbar(t("videoUpload.somethingWentWrong"), {
        variant: "error",
        persist: true,
        action: () => actionFn(snackbarKey),
        key: snackbarKey,
      });      
      setKey(key + 1);
    }

    return () => {};
  }, [isOpen]);

  return <></>;
}
