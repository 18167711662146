import { useEffect, useState } from "react";
import { MealPlanType, useGetMealTemplateLazyQuery } from "../../../../generated/graphql";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import MealTemplateForm from "./MealTemplateForm";
import DialogTitle from "@mui/material/DialogTitle";
import { If, ModalCrudTypeEnum } from "../../../../store/modals/reducerTypes";
import { Typography } from "@mui/material";
import useMealTemplateAction from "../useMealTemplateAction";
import LoadingContainer from "../../../../containers/LoadingContainer";

export type TMealTemplateFormUpdate = {
  id: MealPlanType["id"];
  title: string;
  description: string | undefined;
  numberOfMeals: number;
  type: ModalCrudTypeEnum;
};

export type TMealTemplateFormDuplicate = {
  id: MealPlanType["id"];
  title: string;
  description: string | undefined;
  numberOfMeals: number;
  type: ModalCrudTypeEnum;
};

export type TMealTemplateFormCreate = {
  title: string;
  description: string | undefined;
  type: ModalCrudTypeEnum;
};

// prettier-ignore
export type TMealTemplateForm<Type extends ModalCrudTypeEnum> =
  If<Type, ModalCrudTypeEnum.Create, TMealTemplateFormCreate,
  If<Type, ModalCrudTypeEnum.Update, TMealTemplateFormUpdate,
  If<Type, ModalCrudTypeEnum.Duplicate, TMealTemplateFormDuplicate,
  never
  >>>;

interface Props {
  onClose: () => void;
  mealPlanId: MealPlanType["id"] | undefined;
  type: ModalCrudTypeEnum;
}

export default function MealTemplateContainer(props: Props) {
  const { onClose, mealPlanId, type } = props;
  const { t } = useTranslation(TranslatorNS.LISTS);
  const [getTemplate, { data }] = useGetMealTemplateLazyQuery();
  const {
    loading: loadingSubmit,
    createMealPlanTemplate,
    updateMealPlanTemplate,
    duplicateMealPlanTemplate,
  } = useMealTemplateAction({ onClose });

  // Loading state is needed because of bug in Apollo Client, where loading is always false
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getMe = async () => {
      if (mealPlanId) {
        await getTemplate({
          variables: {
            id: mealPlanId,
          },
        });
      }
      setLoading(false);
    };
    getMe();
  }, [mealPlanId]);

  const { content, title } = getTitleString(type);

  const defaultValues = {
    id: mealPlanId,
    title: data?.mealPlanTemplate.title || "",
    description: data?.mealPlanTemplate.description || "",
    type,
    numberOfMeals: data?.mealPlanTemplate?.totals?.numberOfMeals || 1,
  };

  const handleSubmitMealTemplate = async (data: TMealTemplateForm<ModalCrudTypeEnum>) => {
    if (data.type === ModalCrudTypeEnum.Update) {
      return await updateMealPlanTemplate(
        data as TMealTemplateForm<ModalCrudTypeEnum.Update>,
      );
    }

    if (data.type === ModalCrudTypeEnum.Duplicate) {
      return await duplicateMealPlanTemplate(
        data as TMealTemplateForm<ModalCrudTypeEnum.Duplicate>,
      );
    }

    return await createMealPlanTemplate(
      data as TMealTemplateForm<ModalCrudTypeEnum.Create>,
    );
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ ".MuiPaper-root": { width: { xs: 400, sm: 900 } } }}>
      <DialogTitle>
        {t(title)}
        <Typography color={"text.secondary"}>{t(content)}</Typography>
      </DialogTitle>
      <LoadingContainer loading={loading} height={285} width={"100%"}>
        <MealTemplateForm
          defaultValues={defaultValues}
          onClose={onClose}
          loading={loadingSubmit}
          submit={handleSubmitMealTemplate}
          type={type}
        />
      </LoadingContainer>
    </Dialog>
  );
}

const getTitleString = (type: ModalCrudTypeEnum) => {
  switch (type) {
    case ModalCrudTypeEnum.Update:
      return {
        title: "meals.const.editMeal.headTitle",
        content: "meals.const.editMeal.content",
      };
    case ModalCrudTypeEnum.Duplicate:
      return {
        title: "meals.const.duplicateMeal.headTitle",
        content: "meals.const.duplicateMeal.content",
      };
    default:
      return {
        title: "meals.const.newMeal.headTitle",
        content: "meals.const.newMeal.content",
      };
  }
};
