import React, { useLayoutEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Client, WorkoutPlan } from "../../../types";
import { GENDER, LEVELS, LOCATION, PERWEEK } from "../../../../WorkoutTemplates/const";
import { EDIT_WORKOUT_DAY } from "../../../../../api/private/workout-api";
import { useSnackbar } from "notistack";

// @ts-ignore
const $ = global.jQuery || {};

interface Props {
  plan: WorkoutPlan;
  client?: Client;
  refetchFn: () => void;
}

export default function EditWorkout({ plan, client, refetchFn }: Props) {
  const { t } = useTranslation(["workoutPlan", "lists", "plans"]);
  const PATH = EDIT_WORKOUT_DAY(plan.id);

  const [workoutsPerWeek, setWorkoutsPerWeek] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    setWorkoutsPerWeek(
      plan?.meta?.workoutsPerWeek ? String(plan.meta.workoutsPerWeek) : "",
    );
    setLevel(plan?.meta.level ? String(plan.meta.level) : "");
    setLocation(plan?.meta?.location ? String(plan.meta.location) : "");
    setGender(plan?.meta?.gender ? String(plan.meta.gender) : "");
  }, [plan]);

  const onSubmit = (e: any) => {
    e.preventDefault();

    const $form = $(e.target);
    const $submit = $form.find("[type=submit]").button("loading");

    const promise = axios.post($form.attr("action"), $form.serialize());

    promise
      .then(() => {
        $form.closest(".modal").modal("hide");
        enqueueSnackbar(
          t("index.renamed.workoutPlan", { ns: "plans" }) +
            " " +
            t("index.updated.workoutPlan", { ns: "plans" }),
          {
            variant: "success",
            persist: false,
            preventDuplicate: true,
          },
        );

        refetchFn();
      })
      .catch((...args) => {
        console.log("#editTemplateText::fail", args);
      })
      .then(() => {
        $submit.button("reset");
      });
  };

  return (
    <div
      className="modal modal-workout inmodal in sm reactModal"
      id="editTemplateText"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-title={t("editor.editWorkoutPlan")}
      data-description={plan.comment}>
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("plan.close")}</span>
            </button>
            <h4 className="modal-title">{t("editor.editWorkoutPlan")}</h4>
            <p className="modal-description">{plan.comment}</p>
          </div>
          <form action={PATH} data-action={PATH} method="POST" onSubmit={onSubmit}>
            <input type="hidden" name="client" defaultValue={client ? client.id : ""} />
            <input type="hidden" name="plan" defaultValue={plan ? plan.id : ""} />
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="plan_name" className="control-label">
                  {t("plan.title")}
                </label>
                <input
                  type="text"
                  name="name"
                  defaultValue={plan ? plan.name : ""}
                  className="form-control"
                  id="plan_name"
                  placeholder={t("plan.titleOfPlan")}
                />
              </div>
              <div className="form-group">
                <label className="control-label">{t("plan.workoutsPerWeek")}</label>
                <select
                  defaultValue={client?.workoutsPerWeek}
                  className="form-control"
                  name="workoutsPerWeek"
                  value={workoutsPerWeek}
                  onChange={e => setWorkoutsPerWeek(e.target.value)}>
                  <option value="0">{t("plan.undefined")}</option>
                  {Object.keys(PERWEEK).map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">{t("plan.level")}</label>
                <select
                  className="form-control"
                  name="level"
                  value={level}
                  onChange={e => setLevel(e.target.value)}>
                  <option value="0">{t("plan.undefined")}</option>
                  {Object.entries(LEVELS).map(([key, title]) => (
                    <option value={key} key={key}>
                      {t(title, { ns: "lists" })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">{t("plan.location")}</label>
                <select
                  className="form-control"
                  name="location"
                  value={location}
                  onChange={e => setLocation(e.target.value)}>
                  <option value="0">{t("plan.undefined")}</option>
                  {Object.entries(LOCATION).map(([key, title]) => (
                    <option value={key} key={key}>
                      {t(title, { ns: "lists" })}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label className="control-label">{t("plan.gender")}</label>
                <select
                  className="form-control"
                  name="gender"
                  value={gender}
                  onChange={e => setGender(e.target.value)}>
                  <option value="0">{t("plan.undefined")}</option>
                  {Object.entries(GENDER).map(([key, title]) => (
                    <option value={key} key={key}>
                      {t(title, { ns: "lists" })}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-block btn-success btn-upper">
                {t("plan.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
