import React from "react";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { ZFProgressStyled } from "./ZFProgressStyled";
import { IconButton, useTheme } from "@mui/material";
import { CypressTestId } from "../../../../helpers/cypressTestIds";

interface Props {
  currentStep: number;
  bars: number[];
  changeStep: (step: number) => void;
}

const ZFProgressIndicator = (props: Props) => {
  const { currentStep, bars, changeStep } = props;
  const handleBars = (step: number) => changeStep(Number(step));
  const { palette } = useTheme();

  const ProgressItems = () => {
    return (
      <div className="zf-progress">
        {bars.map((el, i) => {
          return (
            <span
              className={`span_${currentStep >= el ? "active" : "inactive"}`}
              key={i}
              style={{ width: `${100 / bars.length}%` }}
              onClick={() => currentStep >= el && handleBars(el)}>
              <div
                style={{
                  width: "100%",
                  borderRadius:
                    i === 0 ? "5px 0 0 5px" : i + 1 === bars.length ? "0 5px 5px 0" : "",
                }}
                className={`zf-progressItem ${currentStep >= el ? "active" : "inactive"}`}
              />
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <ZFProgressStyled
      primaryColor={palette.primary.main}
      className="removeHighLight"
      data-cy={CypressTestId.CLIENT_PAGE_PROGRESS_BAR}>
      <IconButton
        disabled={!(currentStep > 1)}
        onClick={() => currentStep !== 1 && handleBars(currentStep - 1)}>
        <ArrowBack className="backArrow" />
      </IconButton>
      <ProgressItems />
      <span className="counts">{`${currentStep}/${bars.length}`}</span>
    </ZFProgressStyled>
  );
};

export default ZFProgressIndicator;
