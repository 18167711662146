import { TClientSettingsForm, TEatingDisorders } from "../Components/ClientSettingsForm";
import {
  ClientFoodPreferencesInputType,
  LocaleEnum,
  MeasuringSystemEnum,
  useUpdateClientSettingsMutation,
} from "../../../../generated/graphql";
import {
  DefaultValues,
  FieldValues,
  KeepStateOptions,
  UnpackNestedValue,
} from "react-hook-form";
import { format } from "date-fns";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import {
  GetCMFromFeetInches,
  GetFeetInchesFromCM,
} from "../../../../helpers/measurementHelper";

interface Props {
  reset: (
    values?: DefaultValues<FieldValues> | UnpackNestedValue<FieldValues>,
    keepStateOptions?: KeepStateOptions,
  ) => void;
  /*TODO: Remove this dispatch when all pages are migrated to GRAPHQL*/
  setCurrentClientDispatch: ({ clientId }: { clientId: number }) => void;
  onClose: () => void;
  clientId: number;
}

export default function useUpdateClientSettings(props: Props) {
  const { reset, setCurrentClientDispatch, onClose, clientId } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [updateClient, { loading, error }] = useUpdateClientSettingsMutation();
  const { enqueueSnackbar } = useSnackbar();

  const submitSettings = async (data: TClientSettingsForm) => {
    try {
      /*Extract data values so we can modify them to proper data to mutation*/
      const {
        id,
        customQuestions,
        allergies,
        foodPreferences,
        eatingDisorders,
        __typename,
        ...fields
      } = data;

      const formattedCustomQuestions = Object.entries(customQuestions)
        .filter(([_, a]) => Boolean(a))
        .map(([id, answer]) => {
          return {
            id,
            answer,
          };
        });

      const clientFoodPreferences = mergeAllergiesFoodPreferences(
        allergies,
        foodPreferences,
        eatingDisorders,
      );

      const checkInDay =
        fields.checkInDay !== "0" && fields?.checkInDay
          ? parseInt(fields?.checkInDay)
          : null;

      const { feetImperial, inchesImperial, heightMetric } = getFeetInchesCm(
        fields?.feet,
        fields.inches,
        fields.height,
        fields.measuringSystem,
      );

      await updateClient({
        variables: {
          id: clientId,
          input: {
            isQuestionnaire: false,
            // Contact information
            // photo: fields?.photo,
            name: fields?.name,
            email: fields?.email,
            tags: fields?.tags || undefined,
            phone: fields?.phone ? String(fields?.phone) : undefined,
            locale: fields?.locale ? (fields?.locale as LocaleEnum) : undefined,
            //Plan information
            startDate: fields.startDate ? formatDateFnsDate(fields.startDate) : undefined,
            endDate: fields?.endDate ? formatDateFnsDate(fields?.endDate) : null,
            checkInDay: checkInDay || null,
            checkInFrequency: fields?.checkInFrequency || undefined,
            mealPlanSchedule: fields?.mealPlanSchedule || undefined,
            workoutPlanSchedule: fields?.workoutPlanSchedule || undefined,
            note: fields?.note || undefined,
            //Basic Information
            gender: fields?.gender || undefined,
            birthday: fields?.birthday ? formatDateFnsDate(fields.birthday) : undefined,
            measuringSystem: fields?.measuringSystem || undefined,
            startWeight: fields?.startWeight ? Number(fields?.startWeight) : undefined,
            height: heightMetric || undefined,
            feet: feetImperial || undefined,
            inches: inchesImperial || undefined,
            pal: fields?.pal || undefined,
            //Workout preferences
            workoutsPerWeek: fields?.workoutsPerWeek || undefined,
            workoutLocation: fields?.workoutLocation || undefined,
            workoutExperience: fields?.workoutExperience || undefined,
            workoutExperienceLevel: fields?.workoutExperienceLevel || undefined,
            workoutPreference: fields?.workoutPreference || undefined,
            injuries: fields?.injuries || undefined,
            //Diet Preferences
            hideCalories: Boolean(fields?.hideCalories),
            lifestyle: fields?.lifestyle || undefined,
            numberOfMeals: fields?.numberOfMeals || undefined,
            ...(clientFoodPreferences && { clientFoodPreferences }),
            dietStyle: fields?.dietStyle || undefined,
            motivation: fields?.motivation || undefined,
            other: fields?.other || undefined,
            ...(formattedCustomQuestions?.length && {
              customQuestions: JSON.stringify(formattedCustomQuestions),
            }),
          },
        },
      });
      reset(data);
      onClose();
      setCurrentClientDispatch({ clientId: id });
      enqueueSnackbar(_.capitalize(t("info.saveStatus.saved")), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(_.capitalize(t("info.saveStatus.error")), {
        variant: "error",
        persist: false,
        preventDuplicate: true,
      });
    }
  };

  return {
    submitSettings,
    error,
    loading,
  };
}

export const mergeAllergiesFoodPreferences = (
  allergies: string[],
  foodPreferences: string,
  eatingDisorders: TEatingDisorders | null,
): ClientFoodPreferencesInputType | undefined => {
  if (!eatingDisorders) return undefined;

  return {
    ...eatingDisorders,
    isPescetarian: foodPreferences === "isPescetarian",
    isVegan: foodPreferences === "isVegan",
    isVegetarian: foodPreferences === "isVegetarian",
    eggs: allergies.includes("eggs"),
    fish: allergies.includes("fish"),
    gluten: allergies.includes("gluten"),
    lactose: allergies.includes("lactose"),
    nuts: allergies.includes("nuts"),
    pig: allergies.includes("pig"),
    shellfish: allergies.includes("shellfish"),
  };
};

export const formatDateFnsDate = (date: any | undefined) =>
  date ? format(new Date(date), "yyyy-MM-dd") : undefined;

export const getFeetInchesCm = (
  feet: number | undefined | null,
  inches: number | undefined | null,
  height: number | undefined | null,
  measuringSystem: MeasuringSystemEnum | undefined | null,
) => {
  if (measuringSystem === MeasuringSystemEnum.Imperial) {
    const heightMetric = GetCMFromFeetInches(Number(feet), Number(inches));
    return {
      heightMetric: Number(heightMetric),
      feetImperial: Number(feet),
      inchesImperial: Number(inches),
    };
  }

  const { feet: feetImperial, inches: inchesImperial } = GetFeetInchesFromCM(
    Number(height),
  );

  return {
    heightMetric: Number(height),
    feetImperial: Number(feetImperial),
    inchesImperial: Number(inchesImperial),
  };
};
