import axios from "axios";
import {
  CREATE_WORKOUT_TEMPLATES,
  DELETE_WORKOUT_TEMPLATES,
  EDIT_WORKOUT_TEMPLATES,
  GET_WORKOUT_TEMPLATES,
} from "../../../api/private/workout-api";
import { GET_WORKOUT_LIMIT } from "../const";
import { IWorkoutTemplateParams } from "../types";
import { workoutTemplateSchema, updateWorkoutTemplateSchema } from "./schema";

export async function getWorkoutTemplates(params: IWorkoutTemplateParams) {
  const { data } = await axios.get(GET_WORKOUT_TEMPLATES(), {
    params,
  });

  const formattedData = data.map(workoutTemplateSchema) as ReturnType<
    typeof workoutTemplateSchema
  >[];

  return { data: formattedData, nextCursor: formattedData.length >= GET_WORKOUT_LIMIT };
}

export async function deleteWorkoutTemplate(id: number) {
  return axios.delete(DELETE_WORKOUT_TEMPLATES(id));
}

export async function createWorkoutTemplate(payload: any) {
  const { data } = await axios.post(CREATE_WORKOUT_TEMPLATES(), payload);
  return workoutTemplateSchema(data);
}

export async function editWorkoutTemplate(payload: any) {
  const { id, ...workoutTemplate } = payload;
  const { data } = await axios.put(EDIT_WORKOUT_TEMPLATES(id), workoutTemplate);
  return updateWorkoutTemplateSchema(payload);
}
