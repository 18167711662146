import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  ClientType,
  EventNameEnum,
  useActivateClientMutation,
} from "../../../../../generated/graphql";
import { Stack } from "@mui/material";
import ReadyToActivate from "./ReadyToActivate";
import WarnBeforeActivate from "./WarnBeforeActivate";
import { connect } from "react-redux";
import { track } from "../../../../../helpers/analytics";
import { handleActivateClientChatAction } from "../../../../Clients/store/clients/actions";
import Activated from "./Activated";
import { useSnackbar } from "notistack";
import useUpdateClientListCache from "../../../../ClientList/hooks/useUpdateClientListCache";
import RHFGraphqlError from "../../../../../components/_MUI/hook-form/RHFGraphqlError";

interface Props {
  clientId: ClientType["id"];
}

const eventPendingNames = [
  EventNameEnum.InvitePending,
  EventNameEnum.QuestionnairePending,
  EventNameEnum.PaymentPending,
  EventNameEnum.CreateWorkoutPlan,
  EventNameEnum.CreateMealPlan,
];

const CLIENT_QUERY = gql`
  query GetClientStatuses($id: Int!) {
    client(id: $id) {
      id
      activated
      unresolvedTasks {
        id
        event {
          id
          name
          title
        }
      }
    }
  }
`;

function ActivateClientContainer(props: Props) {
  const { clientId } = props;
  const [activateClient, { loading: activateLoading }] = useActivateClientMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [hideAction, setHideAction] = useState(true);
  const [activated, setActivated] = useState(false);

  const { updateClientListCache } = useUpdateClientListCache();
  const { data, loading, error } = useQuery(CLIENT_QUERY, {
    variables: { id: clientId },
  });

  const client = data?.client as ClientType;

  useEffect(() => {
    if (!loading && client) {
      setHideAction(client?.activated);
    }
    return () => {
      setHideAction(false);
      setActivated(false);
    };
  }, [loading, client?.activated]);

  const pendingTasks = (client?.unresolvedTasks || [])
    .filter(task => eventPendingNames.includes(task.event.name))
    .map(task => task.event.name);

  const handleActivateClient = async () => {
    try {
      await activateClient({
        variables: {
          id: clientId,
        },
      });
      setActivated(true);
      track("Client Activated", "Chat Widget", { clientId });
      updateClientListCache();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error activating client", { variant: "error" });
    }
  };

  if (error) {
    return <RHFGraphqlError error={error} />;
  }

  if (loading || hideAction) {
    return null;
  }

  if (activated) {
    return (
      <Stack p={2} bgcolor={"white"}>
        <Activated />
      </Stack>
    );
  }

  return (
    <Stack p={2} bgcolor={"white"}>
      {!pendingTasks.length ? (
        <ReadyToActivate
          loading={activateLoading}
          activateClient={handleActivateClient}
          cancel={() => setHideAction(true)}
        />
      ) : (
        <WarnBeforeActivate
          loading={loading}
          pendingTasks={pendingTasks}
          activateClient={handleActivateClient}
          cancel={() => setHideAction(true)}
        />
      )}
    </Stack>
  );
}

export default connect(undefined, { handleActivateClientChatAction })(
  ActivateClientContainer,
);
