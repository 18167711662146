import { getToken, Tokens } from "../api/tokens";
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { concat, uniqBy } from "lodash";

export default function ApolloClientProvider(props: { children: JSX.Element }) {
  const isProduction = process.env.NODE_ENV === "production";
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_SCHEMA_URL });

  //Will always get the latest token on each request
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken(Tokens.BEARER_TOKEN);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    connectToDevTools: !isProduction,
    cache: createApolloInMemoryCache(),
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
}

export const createApolloInMemoryCache = () => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          //This is a custom field policy that will merge the results of the query with the previous results
          //This is only needed for the clients query because it is paginated
          clients: {
            // Read the existing data from the cache
            read(existing) {
              return existing;
            },
            // Create cache keys without page
            keyArgs: ["filters", "orderBy"],
            merge(existing, incoming) {
              return mergeCache(existing, incoming);
            },
          },
          mealPlanTemplates: {
            keyArgs: ["filters", "orderBy"],
            // Read the existing data from the cache
            read(existing) {
              return existing;
            },
            merge(existing, incoming) {
              return mergeCache(existing, incoming);
            },
          },
        },
      },
    },
  });
};

export const mergeCache = (existing: any, incoming: any) => {
  const { data, ...rest } = incoming;
  const newClients = data || [];
  const existingClients = existing?.data || [];
  const merged = uniqBy(concat(existingClients, newClients), "__ref");
  return {
    ...rest,
    data: merged,
  };
};
