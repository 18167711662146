import { keyBy } from "lodash";
import {
  CLIENT_IMAGES_ADD_IMAGE_FAILURE,
  CLIENT_IMAGES_ADD_IMAGE_REQUEST,
  CLIENT_IMAGES_ADD_IMAGE_SUCCESS,
  CLIENT_IMAGES_DELETE_IMAGE_FAILURE,
  CLIENT_IMAGES_DELETE_IMAGE_REQUEST,
  CLIENT_IMAGES_DELETE_IMAGE_SUCCESS,
  CLIENT_IMAGES_FAILURE,
  CLIENT_IMAGES_REQUEST,
  CLIENT_IMAGES_SUCCESS,
  CLIENT_IMAGES_LOAD_MORE_SUCCESS,
  CLIENT_IMAGES_RESET,
  ClientImagesActionTypes,
  ClientTopImagesState,
} from "./types";
import { RequestState } from "../../../../../interfaces/requestState";

const INITIAL_STATE: ClientTopImagesState = {
  clientId: null,
  list: [],
  map: {},
  error: null,
  fetchStatus: RequestState.INITIAL,
  addStatus: RequestState.INITIAL,
  deleteStatus: RequestState.INITIAL,
  page: 0,
  allLoaded: false,
  initialLoading: true,
};

export default function Reducer(
  state = INITIAL_STATE,
  action: ClientImagesActionTypes,
): ClientTopImagesState {
  switch (action.type) {
    case CLIENT_IMAGES_REQUEST: {
      return {
        ...state,
        error: "banana",
        fetchStatus: RequestState.PENDING,
      };
    }
    case CLIENT_IMAGES_SUCCESS: {
      return {
        ...state,
        clientId: action.payload.clientId,
        list: action.payload.imageList,
        map: keyBy(action.payload.imageList, "id"),
        fetchStatus: RequestState.RESOLVED,
        page: 1,
        allLoaded: action.payload.imageList?.length === 0,
        initialLoading: false,
      };
    }
    case CLIENT_IMAGES_FAILURE: {
      return {
        ...state,
        error: action.payload,
        fetchStatus: RequestState.REJECTED,
      };
    }
    case CLIENT_IMAGES_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        list: [...state.list, ...action.payload.imageList],
        map: { ...keyBy(action.payload.imageList, "id"), ...state.map },
        fetchStatus: RequestState.RESOLVED,
        page: state.page + 1,
        allLoaded: action.payload.imageList?.length === 0,
        initialLoading: false,
      };
    }
    case CLIENT_IMAGES_ADD_IMAGE_REQUEST: {
      return {
        ...state,
        error: null,
        addStatus: RequestState.PENDING,
      };
    }
    case CLIENT_IMAGES_ADD_IMAGE_SUCCESS: {
      return {
        ...state,
        list: [...state.list, action.payload],
        map: {
          ...state.map,
          [action.payload.id]: {
            ...action.payload,
          },
        },
        addStatus: RequestState.RESOLVED,
      };
    }
    case CLIENT_IMAGES_ADD_IMAGE_FAILURE: {
      return {
        ...state,
        error: action.payload,
        addStatus: RequestState.REJECTED,
      };
    }
    case CLIENT_IMAGES_DELETE_IMAGE_REQUEST: {
      return {
        ...state,
        error: null,
        deleteStatus: RequestState.PENDING,
      };
    }
    case CLIENT_IMAGES_DELETE_IMAGE_SUCCESS: {
      const filtered = state.list.filter(({ id }) => action.payload.id !== id);
      return {
        ...state,
        list: filtered,
        deleteStatus: RequestState.RESOLVED,
      };
    }
    case CLIENT_IMAGES_DELETE_IMAGE_FAILURE: {
      return {
        ...state,
        error: action.payload,
        deleteStatus: RequestState.REJECTED,
      };
    }
    case CLIENT_IMAGES_RESET: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
}
