import React from "react";
import { List, ListItem, Stack, Tooltip, Typography } from "@mui/material";
import { UilInfoCircle } from "@iconscout/react-unicons";
import { TranslatorNS } from "../../../../../i18n/const";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function VideoError() {
  const { t } = useTranslation(TranslatorNS.CHAT);

  const listSx = {
    paddingX: 0,
    paddingY: 0.5,
  };

  return (
    <Stack bgcolor={"black"} width={400} height={300} p={"8rem 20px 1rem"}>
      <Stack sx={{ overflowY: Object.keys(listContent).length > 4 ? "scroll" : "none" }}>
        <Typography variant={"subtitle1"} color={"white"}>
          {t("captureVideo.errorSubtitle")}
        </Typography>
        <List>
          {Object.values(listContent()).map(({ title, subtitle }, index) => {
            return (
              <ListItem key={title} sx={listSx}>
                <Typography color={"white"}>{`${index + 1}. ${title}`}</Typography>
                <Tooltip
                  disableInteractive
                  arrow
                  placement={"top"}
                  PopperProps={{ style: { zIndex: 10001 } }}
                  title={subtitle}>
                  <Stack paddingX={1} sx={{ cursor: "help" }}>
                    <UilInfoCircle size={18} color={"white"} />
                  </Stack>
                </Tooltip>
              </ListItem>
            );
          })}
        </List>
      </Stack>
    </Stack>
  );
}

const listContent = () => {
  const t = (key: string) => i18next.t(key, { ns: TranslatorNS.CHAT });
  return {
    1: {
      title: t("captureVideo.errorList.title1"),
      subtitle: t("captureVideo.errorList.subtitle1"),
    },
    2: {
      title: t("captureVideo.errorList.title2"),
      subtitle: t("captureVideo.errorList.subtitle2"),
    },
    3: {
      title: t("captureVideo.errorList.title3"),
      subtitle: t("captureVideo.errorList.subtitle3"),
    },
  };
};
