import { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";
import Actions from "./Actions";
import Title from "./Title";
import i18next from "i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { MealPlanType } from "../../../../generated/graphql";

const t = (key: string) => i18next.t(key, { ns: TranslatorNS.LISTS });

export const columns = (): GridColDef<MealPlanType | any>[] => [
  {
    field: "id",
    headerName: "id",
  },
  {
    field: "name",
    headerName: t("meals.const.tableTitle.name"),
    sortable: false,
    flex: 0.25,
    renderCell: ({ row }) => {
      return <Title templateId={row.id} title={row.title} />;
    },
  },
  {
    field: "description",
    headerName: t("meals.const.tableTitle.comment"),
    sortable: false,
    flex: 0.3,
  },
  {
    field: "numberOfMeals",
    headerName: t("meals.const.tableTitle.amountMeals"),
    sortable: false,
    valueGetter: ({ row }) => row?.totals?.numberOfMeals,
    flex: 0.25,
  },
  {
    field: "actions",
    headerName: t("common.actions"),
    sortable: false,
    flex: 0.2,
    renderCell: ({ row }) => {
      return <Actions templateId={row.id} name={row.title} />;
    },
  },
];
