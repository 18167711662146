import { ROOT } from "../config";

export const GET_GENERAL_SETTINGS = () => {
  return `${ROOT}/api/private/user/settings`;
};

export const SAVE_GENERAL_SETTINGS = () => {
  return `${ROOT}/settings/save`;
};

export const EDIT_STRIPE_SETTINGS = () => {
  return `${ROOT}/settings/create-stripe-session`;
};

export const UPLOAD_PHOTO = () => {
  return `${ROOT}/settings/profile-picture`;
};

export const UPLOAD_LOGO = () => {
  return `${ROOT}/settings/company-logo`;
};

export const CHANGE_PASSWORD = () => {
  return `${ROOT}/settings/change-password`;
};

export const VALIDATE_VAT_API = () => {
  return `${ROOT}/settings/validate-vat`;
};

export const TRAINER_CHECKOUT = (id: number) => {
  return `${ROOT}/api/private/subscription/trainer-checkout/${id}`;
};

export const UPDATE_TRAINER_SUBSCRIPTION = (id: number) => {
  return `${ROOT}/api/private/subscription/trainer-checkout/update/${id}`;
};

export const UPDATE_USER_LANG = () => {
  return `${ROOT}/settings/change-lang`;
};

export const UPDATE_USER_VAT = () => {
  return `${ROOT}/settings/update-vat`;
};
