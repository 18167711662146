import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../../helpers/postHelper";
import axios from "../../../../helpers/axios";
import {
  DefaultMessageTypeEnum,
  GET_DEFAULT_MESSAGES_LOCAL_QUEUE,
} from "../../../../api/private/default-messages";
import {
  ClientPaymentConfigurationType,
  ClientType,
  LocaleEnum,
} from "../../../../generated/graphql";
import { prepareMessageUsingTags } from "../../../SideContent/helper";

export type TDefaultEmailTemplates = {
  id: number;
  title: string;
  subject: string;
  message: string;
  locale: LocaleEnum;
  type: number;
  placeholders: {
    client: string;
    trainer: string;
    checkout: string;
  };
};

interface GetEmailTemplatesProps {
  clientId: ClientType["id"];
  locale: ClientType["locale"];
  dataKey?: ClientPaymentConfigurationType["datakey"];
  messageType: DefaultMessageTypeEnum;
}

interface IEmailTemplateProps {
  subject: string;
  message: string;
  template: number | null;
  defaultTemplates: TDefaultEmailTemplates[];
}

export default function useGetEmailTemplates(props: GetEmailTemplatesProps) {
  const [loading, setLoading] = useState(false);
  const { clientId, locale, dataKey, messageType } = props;

  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplateProps>({
    subject: "",
    message: "",
    template: null,
    defaultTemplates: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getEmailTemplates();
  }, []);

  const getEmailTemplates = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        GET_DEFAULT_MESSAGES_LOCAL_QUEUE(messageType!, clientId!, locale!, dataKey!),
      );

      const defaultTemplates = data?.defaultMessages || [];

      const {
        id: defaultTemplateId,
        subject,
        placeholders,
        message,
      } = defaultTemplates[defaultTemplates.length - 1] || {};

      setEmailTemplate({
        subject: prepareMessageUsingTags(subject, placeholders, false) || "",
        message: prepareMessageUsingTags(message, placeholders, false) || "",
        template: defaultTemplateId || null,
        defaultTemplates,
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
      console.log({ e }, "devDebug");
    } finally {
      setLoading(false);
    }
  };

  return {
    emailTemplate,
    loading,
  };
}
