import React from "react";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import IPHONE_IMG from "../../../../assets/images/clientActivation/AppleAppStore.png";
import GOOGLE_IMG from "../../../../assets/images/clientActivation/GoogleStore.png";
import { Link, Stack } from "@mui/material";
import { AppState } from "../../../../store";
import { UserApp } from "../../../../interfaces/userApp";
import { track } from "../../../../helpers/analytics";

interface Props {
  userApp?: UserApp;
}

const Done = ({ userApp }: Props) => {
  const iPhoneLink = userApp ? userApp.iphone : process.env.REACT_APP_CLIENT_IOS_APP;
  const googleLink = userApp ? userApp.android : process.env.REACT_APP_CLIENT_ANDROID_APP;

  if (iPhoneLink === undefined || googleLink === undefined) {
    throw new Error("Client app links are not defined");
  }

  return (
    <Stack sx={{ flexDirection: { xs: "column", sm: "row" } }} gap={8}>
      <Stack alignItems={"center"}>
        <Link
          sx={{ m: 1 }}
          target={"_blank"}
          href={iPhoneLink}
          onClick={() =>
            track("Mobile App link Clicked", "Client Activation", { Store: "Apple" })
          }>
          <img style={{ width: 200 }} src={IPHONE_IMG} alt="DownLoadAppToIphone" />
        </Link>
        <QRCode value={iPhoneLink} />
      </Stack>
      <Stack alignItems={"center"}>
        <Link
          sx={{ m: 1 }}
          href={googleLink}
          target={"_blank"}
          onClick={() =>
            track("Mobile App link Clicked", "Client Activation", { Store: "Google" })
          }>
          <img style={{ width: 200 }} src={GOOGLE_IMG} alt="DownLoadAppToIphone" />
        </Link>
        <QRCode value={googleLink} />
      </Stack>
    </Stack>
  );
};

function mapStateToProps({ user }: AppState) {
  return {
    userApp: user.userApp,
  };
}

export default connect(mapStateToProps)(Done);
