import { ROOT } from "../config";

export const GET_MEALS = () => {
  return `${ROOT}/api/meal/templates`;
};

export const CREATE_MEALS = () => {
  return `${ROOT}/api/meal/templates`;
};

export const EDIT_MEALS_ENDPOINT = (id: number) => {
  return `${ROOT}/api/meal/templates/${id}`;
};

export const DELETE_MEALS = (id: number) => {
  return `${ROOT}/api/meal/templates/${id}`;
};

export const GENERATE_MEAL_PLAN = () => {
  return `${ROOT}/api/lenus/meal/generate`;
};

// maybe should have its own
export const GET_MACRO_SPLITS = (planId?: number) => {
  if (planId) {
    return `${ROOT}/api/meal/macro-splits/${planId}`;
  }
  return `${ROOT}/api/meal/macro-splits`;
};

export const CLONE_MEAL = (id: number) => {
  return `${ROOT}/meal/clone-plan/${id}`;
};

export const UPDATE_MEAL = (id: number) => {
  return `${ROOT}/meal/${id}/update`;
};

export const ADD_PLAN = (id: number) => {
  return `${ROOT}/meal/add-plan/${id}`;
};

export const SAVE_AS_TEMPLATE = (id: number) => {
  return `${ROOT}/meal/save-as-template/${id}`;
}

export const EXPORT_MEAL_PLAN_AS_PDF = (id: number) => {
  return `${ROOT}/pdf/exportPlansPdfMealClient/${id}`;
}