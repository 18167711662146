import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldValidators } from "../../../../helpers/validators";
import ZFError from "../../../../components/Error";
import { AppState } from "../../../../store";
import { Stack, TextField, Typography } from "@mui/material";
import { InputType } from "../../../../components/InputField/enum";
import { ActivationStepsEnum, IOther, TSubmitError } from "../../type";
import { submitClientAction } from "../../store/action";
import { INITIAL_OTHER } from "../../store/initialStates";
import LoadingButton from "@mui/lab/LoadingButton";
import { IClientActivationState } from "../../store/types";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { scrollToElementId } from "../../../../helpers/utils";
import { track } from "../../../../helpers/analytics";

interface Props {
  submitClientActionDispatch: (data: IOther, stepName: ActivationStepsEnum) => void;
  customQuestions: IClientActivationState["customQuestions"];
  otherState: IOther;
  submitError: TSubmitError;
  clientSubmitting: IClientActivationState["clientSubmitting"];
}

const Other = (props: Props) => {
  const {
    submitClientActionDispatch,
    customQuestions,
    otherState,
    submitError,
    clientSubmitting,
  } = props;

  const [validator, setValidator] = useState({
    lifestyle: "",
    motivation: "",
  });
  const { t } = useTranslation("messages");

  const [other, setOther] = useState<IOther>(INITIAL_OTHER);

  useEffect(() => {
    setOther({
      ...other,
      lifestyle: otherState.lifestyle,
      motivation: otherState.motivation,
      customQuestions: Boolean(otherState.customQuestions)
        ? otherState.customQuestions
        : (customQuestions || []).reduce(
            (p: any, c: any) => ({ ...p, [c.id]: c.answer || "" }),
            {},
          ) || undefined,
    });
  }, [otherState]);

  const handleChange = (name: keyof typeof other, value: number | string) => {
    setValidator({ ...validator, [name]: "" });
    setOther({ ...other, [name]: value });
  };

  const handleSave = () => {
    try {
      FieldValidators(other, t);
      track("Questionnaire Submitted", "Client Activation");
      submitClientActionDispatch(other, ActivationStepsEnum.OTHER);
    } catch (e: any) {
      setValidator(e);
      const id = e && Object.keys(e)[0];
      id && scrollToElementId(id);
    }
  };

  return (
    <Stack width={{ xs: "100%", sm: 750 }}>
      <Stack>
        <Typography color={Boolean(validator["lifestyle"]) ? "error" : "#6f7274"}>
          {t("client.survey.lifestyleNew")}
        </Typography>
        <TextField
          type={InputType.TEXT}
          name={"lifestyle"}
          id={`zf_lifestyle`}
          sx={{ mb: 2 }}
          multiline
          rows={3}
          onChange={e => handleChange("lifestyle", e.target.value)}
          value={other.lifestyle}
          error={Boolean(validator["lifestyle"])}
          helperText={validator["lifestyle"]}
        />
      </Stack>
      <Stack>
        <Typography color={Boolean(validator["lifestyle"]) ? "error" : "#6f7274"}>
          {t("client.survey.howMotivated")}
        </Typography>
        <TextField
          type={InputType.TEXT}
          name={"motivation"}
          id={`zf_motivation`}
          sx={{ mb: 2 }}
          multiline
          rows={3}
          onChange={e => handleChange("motivation", e.target.value)}
          value={other.motivation}
          error={Boolean(validator["motivation"])}
          helperText={validator["motivation"]}
        />
      </Stack>

      {Boolean(customQuestions) &&
        other.customQuestions !== undefined &&
        customQuestions?.map((question: any, index: number) => {
          const questionID = question.id.toString();
          return (
            <Stack key={index}>
              <Typography color={"#6f7274"}>{question.text}</Typography>
              <TextField
                name={questionID}
                multiline
                sx={{ mb: 2 }}
                rows={3}
                onChange={e => {
                  setOther({
                    ...other,
                    // @ts-ignore
                    customQuestions: {
                      ...other.customQuestions,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                //@ts-ignore
                value={other.customQuestions[questionID] || ""}
                //@ts-ignore
                helperText={validator[questionID]}
              />
            </Stack>
          );
        })}

      <LoadingButton
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_7}
        variant="contained"
        size="large"
        loading={clientSubmitting}
        disabled={clientSubmitting}
        onClick={() => handleSave()}>
        {t("client.survey.submit")}
      </LoadingButton>
      {submitError && (
        <ZFError
          msg={typeof submitError !== "string" ? submitError?.message : submitError}
          status={typeof submitError !== "string" ? submitError?.status : ""}
        />
      )}
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    step: clientActivation.step,
    customQuestions: clientActivation.customQuestions,
    clientSubmitting: clientActivation.clientSubmitting,
    otherState: clientActivation.other,
    submitError: clientActivation.clientSubmitError,
    clientSubmittingState: clientActivation.clientSubmitting,
  };
}

const mapDispatchToProp = {
  submitClientActionDispatch: submitClientAction,
};

export default connect(mapStateToProps, mapDispatchToProp)(Other);
