import React from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { throttle } from "lodash";
/**
 * Usage:
 * Pass the ref of the scroll element to the hook
 * You can choose to track scroll bounds or opt out
 * @param ref
 * @param trackScroll
 * @returns {atStart: boolean, atEnd: boolean, isOverflowingHorizontal: boolean}
 */
export function useHasHorizontalOverflow(ref: HTMLDivElement | null, trackScroll = true) {
  const [isOverflowingHorizontal, setIsOverflowingHorizontal] = React.useState(false);
  // index = 0 is start, index = 1 is end of the scroll element
  const [bounds, setBounds] = React.useState([true, false]);

  /**
   * Currently throttling as there is a state race conditions
   */
  const throttledOnScroll = throttle(() => {
    const currentScrollPosition = Number(ref?.scrollLeft) + Number(ref?.clientWidth);
    const scrollWith = ref?.scrollWidth;

    const isAtEnd = currentScrollPosition === scrollWith;
    const isAtStart = ref?.scrollLeft === 0;

    if (isAtEnd) return setBounds([false, true]);

    if (isAtStart) return setBounds([true, false]);

    setBounds([false, false]);
  }, 500);

  React.useEffect(() => {
    if (trackScroll) {
      ref?.addEventListener("scroll", throttledOnScroll);
    }
    return () => ref?.removeEventListener("scroll", throttledOnScroll);
  }, [ref]);

  /**
   * Initially check if the element has overflow
   */
  React.useLayoutEffect(() => {
    if (ref) {
      const hasOverflow = ref.scrollWidth > ref.clientWidth;
      setIsOverflowingHorizontal(hasOverflow);
    }
  }, [ref]);

  /**
   * Observe element size changes
   */
  useResizeObserver(ref, entry => {
    const hasOverflow = entry.target.scrollWidth > entry.target.clientWidth;
    setIsOverflowingHorizontal(hasOverflow);
  });

  return { atStart: bounds[0], atEnd: bounds[1], isOverflowingHorizontal };
}

// TODO: make vertical scroll too?
