import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavigationButtons from "../../../../providers/LenusTransferProvider/components/NavigationButtons";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  handleNext: () => void;
}

export default function EmailSuccess(props: Props) {
  const { handleNext } = props;
  const { t } = useTranslation(TranslatorNS.AUTH);
  return (
    <>
      <Stack maxWidth={"45%"}>
        <Typography color={"white"} textAlign={"center"} fontSize={"32px"} mb={2}>
          {t("transfer.emailHasBeenCreated")}
        </Typography>
        <Typography color={"white"} textAlign={"center"}>
          {t("transfer.onceCommitted")}
        </Typography>
      </Stack>
      <NavigationButtons
        buttonTxt={t("transfer.next")}
        goNextTxt={`${t("transfer.next")}: ${t("transfer.transferOverview")}`}
        goNext={handleNext}
      />
    </>
  );
}
