import "./styles.scss";
import React from "react";
import ChatWidgetContainer from "./containers/chat";
import useUpdateClientListCache from "../ClientList/hooks/useUpdateClientListCache";
import useUnreadClientMessages from "./useUnreadClientMessages";

const ChatWidgetWrapper = () => {
  const { updateUnAnsweredMessageCount } = useUpdateClientListCache();
  const { markTaskRead } = useUnreadClientMessages();
  return (
    <ChatWidgetContainer
      updateUnAnsweredMessageCount={updateUnAnsweredMessageCount}
      resolveMarkAsReadTask={markTaskRead}
    />
  );
};

export default ChatWidgetWrapper;
