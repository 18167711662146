import { useTranslation } from "react-i18next";
import React, { useRef } from "react";

export default function ExerciseModal() {
  const { t } = useTranslation("workoutPlan");
  useRef(null);

  return (
    <div
      className="modal inmodal reactModal"
      id="exerciseModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content animated bounceInRight">
          <div className="modal-header">
            <button type="button" className="close exerciseClose" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">{t("info.createModal.close")}</span>
            </button>
            <h4 className="modal-title exerciseLoader">
              {t("info.createModal.fetchingData")}...
            </h4>
            <h4 className="modal-title exerciseTitle"></h4>
          </div>
          <div className="modal-body">
            <iframe
              className={"exerciseIframe"}
              style={{ display: "none" }}
              src={""}
              frameBorder="0"
              allowFullScreen
            />
            <div className="sk-spinner sk-spinner-fading-circle">
              <div className="sk-circle1 sk-circle"></div>
              <div className="sk-circle2 sk-circle"></div>
              <div className="sk-circle3 sk-circle"></div>
              <div className="sk-circle4 sk-circle"></div>
              <div className="sk-circle5 sk-circle"></div>
              <div className="sk-circle6 sk-circle"></div>
              <div className="sk-circle7 sk-circle"></div>
              <div className="sk-circle8 sk-circle"></div>
              <div className="sk-circle9 sk-circle"></div>
              <div className="sk-circle10 sk-circle"></div>
              <div className="sk-circle11 sk-circle"></div>
              <div className="sk-circle12 sk-circle"></div>
            </div>
          </div>
          <div className="modal-footer">
            {/*<button type="button" className="btn btn-white" data-dismiss="modal">
              {t("info.createModal.close")}
            </button>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
