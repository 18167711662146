import React, { Fragment } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import { getTaskEvents } from "../helpers";
import Stack from "@mui/material/Stack";
import { TaskStatus } from "../../../../store/currentClient/types";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  task: TaskStatus;
  onTaskResolveClick: (eventName: string, taskId: number, eventAction: string) => void;
  handleTaskIgnore: (task: TaskStatus) => void;
}

export default function TaskDescription(props: Props) {
  const { task, handleTaskIgnore, onTaskResolveClick } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  if (task.resolved) {
    return (
      <Typography fontSize={12} color="GrayText" marginLeft={0.5}>
        {`${t("clientTask.done")}${
          task.resolved_by?.date
            ? `, ${moment(task.resolved_by.date).format("MMM DD")}`
            : ""
        }`}
      </Typography>
    );
  }

  const taskEvents = getTaskEvents(task.event_name);

  return (
    <Stack direction="row">
      {taskEvents.map((eventAction, i) => (
        <Fragment key={eventAction}>
          <ActionButton
            title={t(eventAction)}
            onClick={() => onTaskResolveClick(task.event_name, task.id, eventAction)}
          />
          {i < taskEvents.length - 1 && <TaskEventSeparator />}
        </Fragment>
      ))}
      {task.event_name && (
        <>
          <TaskEventSeparator />
          <ActionButton
            title={t("clientTask.ignore")}
            onClick={() => handleTaskIgnore(task)}
          />
        </>
      )}
    </Stack>
  );
}

const TaskEventSeparator = () => (
  <Typography sx={{ color: theme => theme.palette.primary.main }}> / </Typography>
);

const ActionButton = ({ title, onClick }: { title: string; onClick: () => void }) => (
  <Button
    variant="text"
    size="small"
    sx={{ py: 0, px: "5px", minWidth: "auto", fontWeight: 500 }}
    onClick={onClick}>
    {title}
  </Button>
);
