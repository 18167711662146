export enum LocalStorageKeysEnum {
  LenusCoachToken = "lenusCoachToken",
  LenusTransferStarted = "lenusTransferStarted", // timestamp'
  LenusPlatformType = "lenusPlatformType",
}

export const setLocalStorage = (key: LocalStorageKeysEnum, value: string) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key: LocalStorageKeysEnum) => {
  return localStorage.getItem(key) || undefined;
};

export const removeLocalStorage = (key: LocalStorageKeysEnum) => {
  localStorage.removeItem(key);
};
