import { Fragment } from "react";
import { Routes } from "../../routeMap";
import { Stack } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function NavigationSteps() {
  const steps = [
    Routes.LENUS_TRANSFER,
    Routes.LENUS_TRANSFER_EMAIL,
    Routes.LENUS_TRANSFER_PROGRESS,
    Routes.LENUS_TRANSFER_SUCCESS,
  ];
  let location = useLocation();
  return (
    <Stack
      width={"100%"}
      justifyContent={"center"}
      flexDirection={"row"}
      position={"absolute"}
      bottom={20}
      alignItems={"center"}>
      {steps.map((step, index) => {
        const isCurrentRoute = location.pathname === step;
        return (
          <Fragment key={index}>
            <Stack
              height={4}
              borderRadius={2}
              mx={0.5}
              width={isCurrentRoute ? 20 : 8}
              bgcolor={"white"}
              sx={{ opacity: isCurrentRoute ? 1 : 0.5 }}
            />
          </Fragment>
        );
      })}
    </Stack>
  );
}
