export const transferContentArr = [
  {
    icon: "uil:users-alt",
    title: "transfer.data.clients", // Clients (General data, such as contact info, weight, etc.)
    info: "transfer.data.clientsTooltip",
    enabled: true,
  },
  {
    icon: "uil:comment",
    title: "transfer.data.messages", // Messages (text)
    info: "transfer.data.messagesTooltip",
    enabled: true,
  },
  {
    icon: "uil:utensils",
    title: "transfer.data.clientMealPlans", // Client Meal Plans
    enabled: true,
  },
  {
    icon: "uil:window-grid",
    title: "transfer.data.workoutTemplates", // Workout Templates
    enabled: true,
  },
  {
    icon: "uil:clipboard-notes",
    title: "transfer.data.clientWorkoutPlans", // Client workout plans
    enabled: true,
  },
{
    icon: "uil:pizza-slice",
    title: "transfer.data.recipes", // Recipes (your own)
    info: "transfer.data.recipesTooltip",
    enabled: true,
  },
  {
    icon: "uil:chart-line",
    title: "transfer.data.clientProgress", // Client progress (Weight progress and check-ins)
    info: "transfer.data.clientProgressTooltip",
    enabled: true,
  },
  {
    icon: "uil:dumbbell",
    title: "transfer.data.exercises", // Exercises (your own)
    info: "transfer.data.exercisesTooltip",
    enabled: true,
  },
];
