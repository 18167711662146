import Dialog from "@mui/material/Dialog";
import {
  LocaleEnum,
  useGetClientInvitationModalQuery,
} from "../../../../generated/graphql";
import DialogTitle from "@mui/material/DialogTitle";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import useGetEmailTemplates from "../hooks/useGetEmailTemplates";
import { EmailInvitationModalContext } from "../../../../store/modals/reducerTypes";
import FormFields from "./FormFields";

interface Props {
  context: EmailInvitationModalContext;
  onClose: () => void;
}

export default function EmailInvitationContainer(props: Props) {
  const { context, onClose } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);
  const { data, loading } = useGetClientInvitationModalQuery({
    variables: {
      clientId: context.clientId,
    },
  });

  const client = data?.client;

  const { emailTemplate, loading: emailLoading } = useGetEmailTemplates({
    clientId: context.clientId,
    locale: client?.locale || LocaleEnum.EnUs,
    dataKey: context.dataKey,
    messageType: context.messageType,
  });

  const isLoading = emailLoading || loading;

  const defaultValues = {
    clientId: context.clientId,
    to: client?.email || "",
    subject: emailTemplate.subject,
    message: emailTemplate.message,
    template: emailTemplate.template,
    queueId: context.queueId,
    dataKey: context?.dataKey,
    type: context.messageType,
  };

  return (
    <Dialog
      maxWidth={"xl"}
      sx={{
        ".MuiPaper-root": {
          width: { xs: "90vw", sm: "50vw" },
          maxWidth: "720px!important",
        },
      }}
      open={Boolean(context?.clientId)}
      onClose={onClose}>
      <DialogTitle>{t("Invitation")}</DialogTitle>
      <SkeletonContainer
        loading={isLoading}
        sx={{
          mb: 0.5,
          height: { xs: "90vh", sm: "78.5vh" },
          width: "100%",
        }}>
        <FormFields
          defaultTemplates={emailTemplate.defaultTemplates}
          defaultValues={defaultValues}
          onClose={onClose}
        />
      </SkeletonContainer>
    </Dialog>
  );
}
