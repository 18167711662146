import Iconify from "../../../components/_MUI/Iconify";
import { useNavigate } from "react-router";
import ConfirmModal from "../../../components/Modal/ConfirmModal";
import { Routes } from "../../../routes/routeMap";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

export default function LenusLeaveTransfer() {
  let navigate = useNavigate();
  const { t } = useTranslation(TranslatorNS.AUTH);
  return (
    <Stack width={158} alignItems={"self-end"}>
      <ConfirmModal
        title={t("transfer.leaveTransfer")}
        body={t("transfer.leaveTransferBody")}
        action={() => navigate(Routes.DEFAULT_ROUTE)}
        actionTxt={t("transfer.leave")}
        type={"danger"}>
        <Iconify
          sx={{ cursor: "pointer" }}
          width={24}
          height={24}
          icon={"uil:times"}
          color={"white"}
        />
      </ConfirmModal>
    </Stack>
  );
}
