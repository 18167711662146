import * as React from "react";

const RetryIconSVG = () => (
  <svg height={11} width={11} xmlns="http://www.w3.org/2000/svg" >
    <path
      d="M.55 4.95a.55.55 0 0 1 .55.55 4.427 4.427 0 1 0 1.22-3.024h1.32a.55.55 0 1 1 0 1.1H1.15a.55.55 0 0 1-.55-.55V.55a.55.55 0 0 1 1.1 0v.973A5.5 5.5 0 1 1 0 5.501a.55.55 0 0 1 .55-.55z"
      fill="#fff"
    />
    <text> Retry </text>
  </svg>
)

interface Props{
  text:string
}

const RetryIcon = (props:Props) => (
  <div className={'retryIcon'}>
    <RetryIconSVG/>
    {props.text}
  </div>
)

export default RetryIcon
