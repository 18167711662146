import React from "react";
import { useTranslation } from "react-i18next";

/*
 * TODO: find out if this is deprecated, is orginal from base html as baseModals
 */
export default function ConfirmDelete() {
  const { t } = useTranslation("workoutPlan");
  return (
    <div
      className="modal fade reactModal"
      id="confirm-delete"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h3>{t("info.oldModal.areYouSure")}</h3>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal">
              {t("info.oldModal.cancel")}
            </button>
            <a href={"#"} id="deleteBtnConfirmation" className="btn btn-danger btn-ok">
              {t("info.table.deletePlan")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
