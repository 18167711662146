import { WorkoutPlan } from "../../../../interfaces/meal";
import moment from "moment";
import { Routes } from "../../../routeMap";
import { prepareRoute } from "../../../../helpers/routing";

export function formatWorkoutplan(plan: WorkoutPlan, clientId: number) {
  const templateId = plan.id;

  const MomentObj = ({ compareKey }: { compareKey: number }) => {
    return <>{moment.unix(compareKey).format("DD/MM/YYYY")}</>;
  };

  return {
    ...plan,
    id: plan.id,
    url: prepareRoute(Routes.CLIENT_WORKOUT_PLAN_EDITOR, { clientId, templateId }),
    name: plan.name,
    explanation: plan.explanation || "",
    workoutsPerWeek: plan.days,
    lastUpdated: <MomentObj compareKey={moment(plan.last_updated).unix()} />,
    created: <MomentObj compareKey={moment(plan.created).unix()} />,
    status: plan.status,
  };
}
