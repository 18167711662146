import React from "react";
import { useTranslation } from "react-i18next";
import { ADD_DAY } from "../../../../../api/private/workout-api";

interface Props {
  dayId?: string;
  workoutId: number;
  name?: string;
  onSubmit?: (e: any) => any;
}

export default function AddDay({ workoutId, dayId, name, onSubmit }: Props) {
  const { t } = useTranslation("workoutPlan");
  const PATH = ADD_DAY(workoutId);

  return (
    <div
      className="modal inmodal fade sm"
      id="addPlanModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("day.close")}</span>
            </button>
            <h4 className="modal-title">{t("day.addNewWorkoutDay")}</h4>
          </div>
          <form
            action={PATH}
            method="POST"
            onSubmit={e => {
              e.preventDefault();
              if (onSubmit) {
                onSubmit(e);
              }
            }}>
            <input type="hidden" name="id" defaultValue={dayId || ""} />
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputPassword" className="control-label">
                  {t("day.title")}
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  defaultValue={name || ""}
                  placeholder={t("day.titleOfWorkoutDay")}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-block btn-success btn-upper">
                {t("day.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
