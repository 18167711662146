/**
 * @param {Element} el
 * @param {Object} options
 */
import "./polyfill";
import "./index.scss";
import "./modal.scss";
import "custom-event-polyfill";

import _ from "underscore";
import fastdom from "fastdom";
import Drop from "tether-drop";
import Cookies from "js-cookie";
import Sortable from "sortablejs";
import { html } from "common-tags";
import * as delegate from "delegated-events";

import api from "./api";
import Queue from "./libs/Queue";
import { percent } from "./helpers";
import * as Templates from "./templates";
import axios from "../../../helpers/axios";
import { ROOT } from "../../../api/config";
import { TranslatorNS } from "../../../i18n/const";
import { prepareOptions } from "../../../helpers/validators";
import { getMealHeaderImageHtmlString } from "./templates/Plan";
import { EQUIPMENT, MUSCLE_GROUPS } from "../../Exercises/const";
import SnackbarUtils from "../../../components/Snackbar/SnackbarUtils";
import { getVideoIdFromYouTubeVimeo } from "../../../helpers/videohelper";
import {
  DROP_SET_RE,
  DROP_SET_TEXT,
  IS_FIREFOX,
  IS_TOUCH,
  LOCALE_KEY,
  TYPE_MEAL,
  TYPE_RECIPE,
  TYPE_WORKOUT,
} from "./constants";

global.Drop = Drop;

const $ = global.jQuery || {};
const sortables = new Map();
const charts = new Map();
const drops = new Map();

const SORTABLE_SCROLL_SENSITIVITY = 120;

function getSuccessToastKey({ isWorkout, isMeal, isRecipe }) {
  if (isWorkout) return "index.updated!";
  if (isMeal) return "index.updated!";
  if (isRecipe) return "index.updated!";
  console.warn("Unknown plan type");
  return "";
}

$.if = function (expression, truthy) {
  if (expression) {
    return {
      else() {
        return truthy();
      },
    };
  } else {
    return {
      else(falsey) {
        return falsey();
      },
    };
  }
};

function randId() {
  return Math.random().toString(36).substr(2, 10);
}

function addSortable(el, options) {
  if (el && !sortables.has(el)) {
    sortables.set(el, Sortable.create(el, options));
  }
}

/**
 * @param {Element} el
 */
function removeSortable(el) {
  if (sortables.has(el)) {
    sortables.get(el).destroy();
    sortables.delete(el);
  }
}

/**
 * @param {Element} el
 * @param {Object} options
 */
function addDrop(el, options = {}) {
  if (!drops.has(el)) {
    drops.set(el, new Drop(options));
  }
  return drops.get(el);
}

/**
 * @param {Element} el
 */
function removeDrop(el) {
  if (charts.has(el)) {
    charts.get(el).destroy();
    charts.delete(el);
  }
}

function closeAllDrops() {
  Drop.drops.forEach(x => x.close());
}

/**
 * @param {string} html
 * @return {Node}
 */
function htmlToElement(html) {
  const template = document.createElement("template");
  template.innerHTML = html.trim();

  return template.content || template.firstChild;
}

/**
 * @param {Event} e
 */
function stopEvent(e) {
  e.stopPropagation();
  e.preventDefault();
}

function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

/**
 * @param {Element} target
 * @param {String} selector
 * @return {string|*}
 */
function secureNodeValue(target, selector) {
  return (target.querySelector(selector) || {}).value;
}

/**
 * @param {Element} el
 * @param {Number?} order
 * @return {{id, order: number, workout_id: *, comment: (string|*), reps: (string|*), time: (string|*), sets: (string|*), rest: (string|*)}}
 */
function serializeExerciseItem(el, order = 0) {
  return {
    id: parseInt(el.dataset.exerciseId, 10) || 0,
    order,
    workout_id: parseInt(el.dataset.id, 10) || 0,
    comment: secureNodeValue(el, ".js-plan-item-comment"),
    reps: secureNodeValue(el, ".plan-item-input-reps"),
    time: secureNodeValue(el, ".plan-item-input-time"),
    sets: secureNodeValue(el, ".plan-item-input-sets"),
    rest: secureNodeValue(el, ".plan-item-input-rest"),
    start_weight: secureNodeValue(el, ".plan-item-input-weight"),
    tempo: secureNodeValue(el, ".plan-item-input-tempo"),
    rm: secureNodeValue(el, ".plan-item-input-rm"),
    superset: [],
  };
}

/**
 *
 * @param {Element} el
 * @param {Number?} order
 * @return {{id, order: number, totalWeight: (Number|number), weightId: (Number|number), weightUnits: Number, entity_id: (*|null)}}
 */
function serializeProductItem(el, order = 0) {
  return {
    id: parseInt(el.dataset.productId, 10) || 0,
    order,
    totalWeight: parseFloat(el.dataset.totalWeight) || 0,
    weightId: parseInt(el.dataset.weightId, 10) || 0,
    weightUnits: parseFloat(el.dataset.weightUnits),
    entity_id: parseInt(el.dataset.id, 10) || 0,
  };
}

var mealPlanTitle = null;
var mealPlanComment = null;
var duration = null;
var level = null;
var gender = null;
var location = null;
var workoutsPerWeek = null;
var eventHandlers = {};

/**
 * Make singleton instead multiple instance Class
 */
const Plans = (() => {
  let instance = null;
  /**
   * Class Plans
   */
  return class Plans {
    static WORKOUT = TYPE_WORKOUT;
    static MEAL = TYPE_MEAL;
    static RECIPE = TYPE_RECIPE;

    /**
     * @type {Element}
     */
    container = null;
    /**
     * @type {TYPE_WORKOUT|TYPE_MEAL|TYPE_RECIPE}
     */
    type = TYPE_WORKOUT;
    /**
     * @type {boolean}
     */
    options = {
      client: undefined,
      updatedAt: undefined,
      locale: Cookies.get(LOCALE_KEY),
      template: null,
      empty: undefined,
      plan: undefined,
      settings: {},
      tour: undefined,
      youtubeApiKey: "",
    };
    data = {
      equipments: [],
      muscles: [],
    };

    searchParams = {
      q: "",
      page: 1,
      limit: 10,
    };

    youtubeParams = {
      nextPageToken: null,
      prevPageToken: null,
      pageInfo: {},
    };

    /**
     * @param {Element} container
     * @param {TYPE_WORKOUT|TYPE_MEAL|TYPE_RECIPE} type
     * @param {Object?} options
     * @throws TypeError
     */
    constructor(container, type, options = {}) {
      if (!container) {
        throw new TypeError("You must define plans container element");
      }

      if (!type) {
        throw new TypeError("You must define plans type");
      }

      const init = instance => {
        instance.save = _.debounce(instance.save, 200);
        instance.updatePlan = _.debounce(instance.updatePlan, 200);
        instance.onSearchScroll = _.debounce(instance.onSearchScroll, 200);
        instance.onProductAmountUpdate = _.debounce(instance.onProductAmountUpdate, 50);
        instance.onCopySets = _.debounce(instance.onCopySets, 200);

        instance.container = container;
        instance.type = type;
        instance.saveQueue = new Queue(true);

        if (instance.isWorkout) {
          instance.searchParams.equipmentId = "";
          instance.searchParams.muscleId = "";
        }

        if (window.location.hash) {
          instance.searchParams.q = decodeURI(window.location.hash.substring(1)).replace(
            /\+/g,
            " ",
          );
        }
        instance.searchParams.q = "";

        instance.container.classList.add("plans");
        instance.container.classList.add(IS_TOUCH ? "plans-touch" : "plans-no-touch");
        instance.container.dataset.type = instance.type;

        instance.setOptions(options);
        //instance.setOptions({ locale: Cookies.get('plans_locale')})
        instance.initiatePlan();
      };

      /**
       * Use existing instance
       */
      if (instance) {
        init(instance);
        return instance;
      }
      /**
       * Set instance first time
       */
      instance = this;
      init(this);
    }

    initiatePlan() {
      this.fetchFilters().then(() => {
        this.fetchPlans().then(res => {
          const data = this.isRecipe ? res.data : _.values(res.data);

          this.empty = _.isEmpty(data);
          this.createLayout(data);
          this.addListeners();

          if (this.isRecipe) {
            this.addPlan(res.data);
          } else {
            Object.keys(data).map(plan => this.addPlan(data[plan]));
          }

          this.search();

          fastdom.mutate(() => {
            $('[data-toggle="tooltip"]').tooltip({
              container: "body",
            });
          });
        });
      });
    }

    /**
     * @param {Object} options
     */
    setOptions(options = {}) {
      if (typeof options !== "object" || options === null) {
        throw new TypeError("Plans options is not a Object");
      }

      for (let prop in options) {
        if (options.hasOwnProperty(prop)) {
          this.options[prop] = options[prop];
        }
      }
    }

    /**
     * @param {Array<Object>} data
     */
    createLayout(data = []) {
      this.container.innerHTML = Templates.Layout(
        {
          data,
          type: this.type,
          locale: this.locale,
          equipments: this.data.equipments,
          muscles: this.data.muscles,
          updatedAt: this.options.updatedAt,
          empty: this.empty,
          client: this.client,
          q: this.searchParams.q,
          plan: this.plan,
          options: this.options,
          isWorkout: this.isWorkout,
        },
        this.isTemplate,
      );
    }

    addListeners() {
      this.board = this.container.querySelector(".plans-board");
      const boardComment = (this.boardComment = this.container.querySelector(
        ".plans-board-comment textarea",
      ));
      const boardList = (this.boardList =
        this.container.querySelector(".plans-board-list"));
      const searchResults = (this.searchResults =
        this.container.querySelector(".plans-search-result"));

      addSortable(searchResults, this.sortableSearchOptions);
      if (this.isWorkout) {
        addSortable(boardList, this.sortablePlansOptions);
      }

      if (boardComment && window?.window.autosize) {
        window?.window.autosize(boardComment);
      }

      if (searchResults) {
        searchResults.addEventListener("scroll", this.onSearchScroll);
      }

      const searchForm = this.container.querySelector(".plans-search-form");

      if (searchForm) {
        searchForm.addEventListener("submit", this.onSearchFormSubmit);

        searchForm
          .querySelectorAll("select")
          .forEach(el => el.addEventListener("change", this.onSearchFilterChange));
      }

      const delegateCtx = { context: this };

      this.on(
        "change",
        "#own-exercise",
        function (e) {
          this.search(e.target.checked);
        },
        delegateCtx,
      );

      this.on(
        "click",
        ".js-plan-close",
        function (e, el) {
          stopEvent(e);
          this.togglePlanNew(el, "close");
        },
        delegateCtx,
      );
      this.on(
        "click",
        ".js-plan-open",
        function (e, el) {
          stopEvent(e);
          this.togglePlanNew(el, "open");
        },
        delegateCtx,
      );

      this.on(
        "click",
        ".js-plan-delete",
        function (e, el) {
          let message;

          if (this.isWorkout) {
            message = window.jsTrans("index.areYouSure.remove.workoutDay", "plans");
          } else {
            message = el.closest(".plans-meal-plan")
              ? window.jsTrans("index.areYouSure.remove.workoutMeal", "plans")
              : window.jsTrans("index.areYouSure.remove.workoutMealPlan", "plans");
          }

          this.confirmDelete(message, confirmed => {
            if (confirmed) {
              this.removePlan(el);
            }
          });
        },
        delegateCtx,
      );

      this.on(
        "click",
        ".js-plan-item-remove",
        function (e, el) {
          stopEvent(e);

          if (this.isWorkout) {
            if (el.classList.contains("delete-superset")) {
              this.confirmDelete(
                window.jsTrans("index.areYouSure.cancel.superset", "plans"),
                confirmed => {
                  if (confirmed) {
                    this.removePlanExercise(el);
                  }
                },
              );
            } else {
              this.removePlanExercise(el);
            }
          } else {
            this.removePlanProduct(el);
          }
        },
        delegateCtx,
      );

      this.on(
        "change",
        ".plans-box-comment textarea",
        function () {
          this.saveQueue.add(this.save);
        },
        delegateCtx,
      );

      this.on("change", ".plans-board-comment textarea", this.updatePlan, delegateCtx);

      this.on(
        "click",
        ".plans-box-comment a",
        function (e, el) {
          stopEvent(e);

          el.classList.add("hidden");
          el.nextElementSibling.classList.remove("hidden");
        },
        delegateCtx,
      );

      this.on("submit", "#addPlanModal form", this.onAddEditPlan, delegateCtx);

      if (this.isMeal || this.isRecipe) {
        this.on("click", ".js-plans-locale", this.onSearchLocaleChange, delegateCtx);
        this.on(
          "click",
          ".product-weights-cancel",
          this.onProductAmountCancel,
          delegateCtx,
        );
        this.on(
          "change",
          ".plan-item-amount-radio",
          this.onProductAmountSwitch,
          delegateCtx,
        );
        this.on(
          "keyup",
          ".plan-item-amount-value",
          this.onProductAmountUpdate,
          delegateCtx,
        );
        this.on(
          "submit",
          ".plan-item-amount-form",
          this.onProductAmountSubmit,
          delegateCtx,
        );
        this.on("plan:calc", ".plans-box", this.onPlanCalculate, delegateCtx);
        this.on("product:calc", ".plan-item", this.onProductCalculate, delegateCtx);
      }

      if (this.isMeal) {
        this.on("meal:calc", ".plans-meal-plan", this.onMealCalculate, delegateCtx);
      }

      if (this.isRecipe) {
        this.on("meal:calc", ".plans-box-item-list", this.onMealCalculate, delegateCtx);
      }

      if (this.isWorkout) {
        this.on("plan:calc", ".plans-box", this.onPlanWorkoutCalculate, delegateCtx);
        this.on(
          "click",
          ".js-plan-item-set-comment",
          function (e, el) {
            stopEvent(e);
            this.toggleItemComment(el);
          },
          delegateCtx,
        );

        this.on(
          "click",
          ".js-plan-item-set-dropset",
          function (e, el) {
            stopEvent(e);

            this.toggleItemComment(el, undefined, true);
            this.saveQueue.add(this.save);
          },
          delegateCtx,
        );

        this.on(
          "change",
          ".js-plan-item-comment",
          function (e, el) {
            if (!el.value) {
              this.toggleItemComment(el, true);
            }

            this.saveQueue.add(this.save);
          },
          delegateCtx,
        );

        this.on(
          "click",
          ".js-plan-item-set-superset",
          function (e, el) {
            stopEvent(e);
            this.switchItemSuperSet(el);
          },
          delegateCtx,
        );

        const itemInputsSelector = [
          "reps",
          "rest",
          "sets",
          "time",
          "weight",
          "tempo",
          "rm",
        ]
          .map(input => `.plan-item-input-${input}`)
          .join(", ");

        this.on(
          "change",
          itemInputsSelector,
          function () {
            this.saveQueue.add(this.save);
          },
          delegateCtx,
        );

        this.on(
          "click",
          ".js-more-youtube-results",
          function (e) {
            stopEvent(e);
            this.search();
          },
          delegateCtx,
        );
      }
    }
    on(eventName, selector, fn, options = {}) {
      function eventHandler(e) {
        return fn.apply(options.context || this, [e, this]);
      }
      const eventKey = eventName + selector;

      // if event listener with `eventKey` has already been registered
      // unregister the previous listener before adding the new one
      if (eventHandlers[eventKey]) {
        delegate.off(eventName, selector, eventHandlers[eventKey], options);
      }

      eventHandlers[eventKey] = eventHandler;
      delegate.on(eventName, selector, eventHandler, options);
    }

    // @autobind
    save = (reload = false) => {
      let promise;

      if (this.isWorkout) {
        promise = api.saveWorkout(this.plan.id, this.workoutFormData);
      } else if (this.isMeal) {
        promise = api.saveMeals(this.plan.id, this.mealFormData, { locale: this.locale });
      } else if (this.isRecipe) {
        const data = Object.assign(this.recipeFormData, { locale: this.locale });
        promise = api.syncRecipe(this.plan.id, data);
      }

      if (!promise) {
        return;
      }

      promise
        .then(response => {
          let titlePrefix;

          if (this.isTemplate) {
            titlePrefix = window.jsTrans("index.templateChanges", "plans");
          } else {
            if (this.isRecipe) {
              titlePrefix = window.jsTrans("index.recipe", "plans");
            } else {
              titlePrefix = this.isWorkout
                ? window.jsTrans("index.workoutPlan", "plans")
                : window.jsTrans("index.mealPlan", "plans");
            }
          }

          let description = "";

          if (this.isWorkout) {
            description = this.isTemplate
              ? window.jsTrans("index.workoutTemplateReadyAssigned", "plans")
              : window.jsTrans("index.changesWillAppear", "plans");
          }

          if (this.isMeal) {
            this.updateMealIds(response.data);
          } else if (this.isRecipe) {
            this.updateRecipeIds(response.data);
          } else if (this.isWorkout) {
            try {
              this.updateWorkoutIds(response.data);
              this.boardList.querySelector(".plans-box-item-list");
            } catch (e) {
              console.log("Plans::save(error):".e);
            }
          }

          const successToastKey = getSuccessToastKey({
            isWorkout: this.isWorkout,
            isMeal: this.isMeal,
            isRecipe: this.isRecipe,
          });

          SnackbarUtils.success(
            `${titlePrefix} ${window.jsTrans(successToastKey, "plans")}\n${description}`,
          );

          if (reload) {
            window.location.reload();
          }
        })
        .catch(error => {
          let titlePrefix;

          if (this.isTemplate) {
            titlePrefix = window.jsTrans("index.templateChanges", "plans");
          } else {
            if (this.isRecipe) {
              titlePrefix = window.jsTrans("index.recipe", "plans");
            } else {
              titlePrefix = this.isWorkout
                ? window.jsTrans("index.workoutPlan", "plans")
                : window.jsTrans("index.mealPlan", "plans");
            }
          }

          SnackbarUtils.error(
            `${titlePrefix} ${window.jsTrans("index.planSaveFailed", "plans")}`,
          );
          console.error(error);
        });

      return promise;
    };

    // @autobind
    updatePlan = dispatch => {
      let promise = null;
      let data = {};

      if (this.client) {
        data.client = this.client.id;
      }

      if (this.isWorkout) {
        data.comment = this.boardComment.value;

        promise = api.updateWorkoutPlan(this.plan.id, data);
        promise
          .then(() => {
            SnackbarUtils.success(
              `${window.jsTrans("index.workoutPlan", "plans")}\n${window.jsTrans(
                "index.workoutPlanUpdated",
                "plans",
              )}`,
            );
          })
          .catch(() => {
            SnackbarUtils.error(
              `${window.jsTrans("index.workoutPlan", "plans")}\n${window.jsTrans(
                "index.workoutPlanFailed",
                "plans",
              )}`,
            );
          });
      }

      return promise;
    };

    search(showOnlyOwn) {
      if (this.searchLoading) {
        return;
      }

      this.searchParams.limit = this.searchParams.page > 1 ? 10 : 25;
      if (showOnlyOwn !== undefined) {
        this.searchParams.showOnlyOwn = showOnlyOwn;
      }

      const params = { ...this.searchParams, ...{ locale: this.locale } };

      const method = this.type === TYPE_WORKOUT ? api.getExercises : api.getMealProducts;
      const plansSearch = this.container.querySelector(".plans-search");
      const searchResults = {
        local: [],
        youtube: [],
      };

      if (plansSearch) {
        plansSearch.classList.add("is-loading");
      }

      this.searchLoading = true;
      method(params)
        .then(response => {
          searchResults.local = response.data;

          /*
          temp fix for fixing youtube query exceed limit issue
          if (
            this.isWorkout &&
            this.searchParams.q &&
            response.data.length < this.searchParams.limit
          ) {
            return api.searchYoutube(
              this.searchParams.q,
              this.options.youtubeApiKey,
              20,
              "snippet",
              this.youtubeParams,
            );
          }*/

          return response;
        })
        .then(response => {
          let nextYoutubeParams = {
            ...this.youtubeParams,
          };

          if (this.isWorkout && Array.isArray(response.data.items)) {
            if (
              this.searchParams.page === 1 &&
              !searchResults.local.length &&
              !this.youtubeParams.nextPageToken
            ) {
              this.searchResults.innerHTML = "";
            }

            nextYoutubeParams = {
              ...nextYoutubeParams,
              nextPageToken: response.data.nextPageToken,
              prevPageToken: response.data.prevPageToken,
              pageInfo: response.data.prevPageToken,
            };

            searchResults.youtube = response.data.items
              .filter(item => item.id.kind === "youtube#video")
              .slice(0, 10);
          }

          this.searchLoading = false;
          this.renderSearchResults(searchResults, params.q);

          if (plansSearch) {
            plansSearch.classList.remove("is-loading");
          }

          this.youtubeParams = nextYoutubeParams;
        })
        .catch(() => {
          this.searchLoading = false;
          this.renderSearchResults({ local: [], youtube: [] }, params.q);
          if (plansSearch) {
            plansSearch.classList.remove("is-loading");
          }
        });
    }

    renderSearchResults(items, q) {
      let content = "";
      let hasItems = items.local.length;

      if (hasItems && this.isWorkout) {
        hasItems = !this.youtubeParams.nextPageToken;
      }

      if (hasItems) {
        let template = this.isWorkout
          ? Templates.SearchExerciseItem
          : Templates.SearchProductItem;

        content = items.local
          .map(item => template(item, this.locale, this.options, this.searchParams))
          .join(`\n`);
      }

      if (this.isWorkout && items.youtube.length) {
        if (!this.searchResults.querySelector(".plans-search-youtube-header")) {
          content += `${Templates.SearchYoutubeHeader()}\n`;
        }

        content += items.youtube
          .map(item => Templates.SearchExerciseYoutubeItem(item))
          .join(`\n`);

        const youtubeFooter = this.searchResults.querySelector(
          ".plans-search-youtube-footer",
        );
        const emptyState = this.searchResults.querySelector(".search-result-empty-state");

        if (youtubeFooter) {
          youtubeFooter.parentNode.removeChild(youtubeFooter);
        }

        if (emptyState) {
          emptyState.parentNode.removeChild(emptyState);
        }

        content += `${Templates.SearchYoutubeFooter()}\n`;
      }

      if (this.searchParams.page > 1 || this.youtubeParams.nextPageToken) {
        this.searchResults.appendChild(htmlToElement(content));
      } else {
        if (this.searchResults) {
          this.searchResults.innerHTML = content;
          this.searchResults.scrollTop = 0;
        }
      }

      if (!content || items.local.length < this.searchParams.limit) {
        if (this.searchResults.querySelector(".search-result-empty-state")) {
          return;
        }

        const itemCount = this.searchResults.querySelectorAll(".plan-item").length;

        content = Templates.EmptyStateSearch(this.type, q, itemCount);

        if (itemCount) {
          this.searchResults.appendChild(htmlToElement(content));
        } else {
          this.searchResults.innerHTML = content;
        }
      }
    }

    /**
     * @param {Object} plan
     */
    addPlan(plan) {
      this.boardList.appendChild(
        htmlToElement(Templates.Plan(plan, this.type, this.plan, this.options)),
      );

      const el = this.boardList.querySelector(`.plans-box[data-id="${plan.id}"]`);
      const list = el.querySelector(".plans-box-item-list");
      const job = {
        sortable: null,
        entities: [],
      };

      if (this.isWorkout) {
        job.sortable = this.sortableItemsOptions;
        job.entities = ["workouts", "addPlanExercise"];
      } else if (this.isMeal) {
        job.sortable = this.sortableMealsOptions;
        job.entities = ["meals", "addPlanMeal"];
      } else if (this.isRecipe) {
        job.sortable = this.sortableItemsOptions;
        job.entities = ["products", "addPlanProduct"];
      }

      if (job.sortable) {
        addSortable(list, job.sortable);
      }

      fastdom.mutate(() => {
        const [entities, action] = job.entities;

        if (Array.isArray(plan[entities])) {
          plan[entities].forEach(item => this[action](list, item));
        }

        fastdom.mutate(() => {
          el.querySelectorAll("textarea").forEach(
            node => window.autosize && window.autosize(node),
          );
        });
      });
    }

    confirmDelete(message, callback) {
      if (!$(".bootbox-confirm").length) {
        window.bootbox.confirm({
          message: message,
          callback: callback,
          buttons: {
            confirm: {
              label: window.jsTrans("bootbox.confirm", "messages"),
            },
            cancel: {
              label: window.jsTrans("bootbox.cancel", "messages"),
            },
          },
        });
      }
    }

    /**
     * @param {Element} el
     */
    removePlan(el) {
      let plan;
      let planBox;
      let selectors = {
        workout: ".plans-box",
        meal: ".plans-meal-plan",
      };

      if (this.isMeal) {
        plan = el.closest(selectors.meal);

        if (plan) {
          planBox = plan.closest(".plans-box");
        }
      }

      if (!plan) {
        plan = el.closest(selectors.workout);
      }

      if (plan) {
        plan
          .querySelectorAll(".plans-box-item-list")
          .forEach(list => removeSortable(list));

        plan
          .querySelectorAll("textarea")
          .forEach(node => window.autosize && window.autosize.destroy(node));

        if (this.isMeal) {
          removeDrop(plan);
        }

        if (plan.parentNode) {
          plan.remove();
        }

        if (planBox) {
          delegate.fire(planBox, "plan:calc");
        }

        const checkCount = () => {
          const totalPlans = document.querySelectorAll(
            this.isMeal ? selectors.meal : selectors.workout,
          ).length;

          if (!totalPlans) {
            window.location.reload();
          }
        };

        this.saveQueue.add(() => {
          const promise = this.save();

          if (promise instanceof Promise) {
            promise.then(checkCount);
            return promise;
          } else {
            checkCount();
          }
        });
      }
    }

    addPlanMeal(target, meal) {
      try {
        // const content = Templates.PlanMeal(meal, this.type, this.locale);
        // const el = htmlToElement(content);
        target.appendChild(htmlToElement(Templates.PlanMeal(meal, this.type)));

        const el = target.querySelector(`.plans-meal-plan[data-id="${meal.id}"]`);
        const list = el.querySelector(".plans-box-item-list--products");

        addSortable(list, this.sortableItemsOptions);

        fastdom.mutate(() => {
          meal.products.forEach(product => this.addPlanProduct(list, product));
        });

        fastdom.mutate(() => {
          const headerTitle = el.querySelector(".plans-box-header-main > h5 > span");
          addDrop(el, {
            target: headerTitle,
            classes: "drop-theme-arrows plan-meal-totals-drop",
            content(e) {
              const plan = e.target.closest(".plans-meal-plan");
              const weight = parseFloat(plan.dataset.weight);

              const getBaseValue = value => (parseFloat(value) / weight) * 100;

              const totals = (e.mealTotals = {
                fat: getBaseValue(plan.dataset.fat),
                protein: getBaseValue(plan.dataset.protein),
                carbohydrate: getBaseValue(plan.dataset.carbohydrate),
                kcal: getBaseValue(plan.dataset.kcal),
              });

              return Templates.PlanTotalsDrop(
                {
                  title: meal.name,
                  ...totals,
                },
                weight,
                true,
              );
            },
            position: "right center",
            openOn: "hover",
            constrainToWindow: false,
            constrainToScrollParent: true,
            remove: true,
          });
        });

        fastdom.mutate(() => {
          const serviceLabel = el.querySelector("#titleServing");
          if (serviceLabel) {
            addDrop(serviceLabel, {
              target: serviceLabel,
              classes: "drop-theme-arrows servingTooltip",
              content(e) {
                const plan = e.target.closest(".plans-meal-plan");
                const servings = plan.dataset.servings;
                const totalGramPerServing = plan.dataset.weight * servings;
                const totalKcalsPerServing = plan.dataset.kcal * servings;
                const dishName = meal.name;

                return window.jsTrans("dish.servingsSubtitle", TranslatorNS.MEAL_PLAN, {
                  dishName,
                  servings,
                  totalGramPerServing,
                  totalKcalsPerServing,
                });
              },
              position: "top center",
              openOn: "hover",
              constrainToWindow: false,
              constrainToScrollParent: true,
              remove: true,
            });
          }
        });

        fastdom.mutate(() => {
          const totalPerServing = el.querySelectorAll(".plan-totals-col");
          [...totalPerServing]?.map((planTotal, i) => {
            if (i === 4) return;
            addDrop(planTotal, {
              target: planTotal,
              classes: "drop-theme-arrows servingTooltip",
              content: window.jsTrans("dish.indicator", TranslatorNS.MEAL_PLAN),
              position: "top left",
              openOn: "hover",
              constrainToWindow: false,
              constrainToScrollParent: true,
              remove: true,
            });
          });
        });
      } catch (e) {
        console.error("Plans::addPlanMeal(error):", e);
      }
    }

    /**
     * @param {Element} target
     * @param {Object} workout
     * @param {Boolean=} replace
     */
    addPlanExercise(target, workout, replace = false) {
      const isSuperSet = Array.isArray(workout.supers) && Boolean(workout.supers.length);
      let content = Templates.PlanExercise(workout, this.options);

      if (isSuperSet) {
        content = Templates.PlanSuperSet(
          {
            id: workout.id,
            children: content,
          },
          this.type,
        );
      }

      const el = htmlToElement(content);
      let refreshSuperSet = null;

      if (isSuperSet) {
        const childrenItemList = el.querySelector(".plans-box-item-list");

        if (childrenItemList) {
          childrenItemList.dataset.count = workout.supers.length;
          workout.supers.forEach(childrenWorkout =>
            this.addPlanExercise(childrenItemList, childrenWorkout),
          );
        }

        this.enableSuperSet(el);
      } else {
        const superSetLink = el.querySelector(".js-plan-item-set-superset");
        const isChildren = Boolean(target.closest("[data-children]"));

        if (superSetLink) {
          superSetLink.classList.toggle("is-hidden", isChildren);
        }

        if (isChildren) {
          let lastRest;
          let lastSets;

          if (target.previousElementSibling && !target.nextElementSibling) {
            lastRest = lastSets = target.previousElementSibling;
          } else if (target.nextElementSibling) {
            lastSets = target.nextElementSibling;
          } else {
            lastRest = lastSets = target
              .closest(".plan-superset")
              .querySelector(".plan-item");
          }

          try {
            if (lastRest) {
              lastRest = lastRest.querySelector(".plan-item-input-rest").value;
            }

            if (lastSets) {
              lastSets = lastSets.querySelector(".plan-item-input-sets").value;
            }

            refreshSuperSet = [target.closest(".plan-superset"), lastRest, lastSets];
          } catch (e) {}
        }
      }

      if (replace) {
        target.parentNode.replaceChild(el, target);
      } else {
        target.appendChild(el);
      }

      if (refreshSuperSet) {
        this.enableSuperSet(...refreshSuperSet);
      }
    }

    removePlanExercise(el) {
      const item = el.classList.contains("plan-item") ? el : el.closest(".plan-item");

      if (!item) {
        return;
      }

      const planBox = item.closest(".plans-box");
      const parent = item.parentNode;
      const itemList = item.closest(".plans-box-item-list");

      if (parent?.classList?.contains("plan-superset")) {
        const list = parent.querySelector(".plans-box-item-list");

        removeSortable(list);

        parent
          .querySelectorAll(".plan-item-input-sets")
          .forEach(node => node.removeEventListener("change", this.onCopySets));

        parent
          .querySelectorAll("textarea")
          .forEach(node => window.autosize && window.autosize.destroy(node));

        if (parent.parentNode) {
          parent.remove();
        }

        itemList.dataset.count = itemList.children.length;
      } else {
        const superSet = item.closest(".plan-superset");
        const restValue = superSet
          ? item.querySelector(".plan-item-input-rest").value
          : null;

        item
          .querySelectorAll("textarea")
          .forEach(node => window.autosize && window.autosize.destroy(node));

        if (item.parentNode) {
          item.remove();
        }

        if (itemList && itemList.dataset) {
          itemList.dataset.count = itemList.children.length;
        }

        if (superSet) {
          this.enableSuperSet(superSet, restValue);
        }
      }

      if (planBox) {
        delegate.fire(planBox, "plan:calc");
      }

      this.saveQueue.add(this.save);
    }

    /**
     * ]
     * @param {Element} target
     * @param {Object} product
     * @param {Boolean=} replace
     * @param {Boolean=} openAmountChooser
     */
    addPlanProduct(target, product, replace = false, openAmountChooser = false) {
      let content = Templates.PlanProduct(product, this.locale, this.options);
      const el = htmlToElement(content);
      const title = el.querySelector(".plan-item-title");
      const amountHandler = el.querySelector(".js-plans-choose-amount");

      if (replace) {
        target.parentNode.replaceChild(el, target);
      } else {
        target.appendChild(el);
      }

      fastdom.mutate(() => {
        const amountDrop = addDrop(amountHandler, {
          target: amountHandler,
          classes: "drop-theme-arrows plan-item-amount-drop",
          content: Templates.PlanMealAmountDrop(product, this.locale),
          position: "bottom center",
          openOn: "click",
          constrainToWindow: true,
          constrainToScrollParent: true,
          remove: true,
        });

        const that = this;

        amountDrop.on("open", function onDropOpen() {
          that.amountEntity = this;

          const form = this.content.querySelector(".plan-item-amount-form");

          if (form) {
            const radioInput = form.querySelector("input:checked");

            if (radioInput) {
              delegate.fire(radioInput, "change");
            }
          }
        });

        // amountDrop.on('close', function onDropClose() {
        //   that.amountEntity = null;
        // });

        addDrop(title, {
          target: title,
          classes: "drop-theme-arrows plan-meal-totals-drop",
          content(e) {
            const plan = e.target.closest(".plan-item");
            return Templates.PlanTotalsDrop(
              {
                title: product.product.name,
                fat: parseFloat(plan.dataset.fat),
                protein: parseFloat(plan.dataset.protein),
                carbohydrate: parseFloat(plan.dataset.carbohydrates),
                kcal: parseFloat(plan.dataset.kcal),
              },
              parseFloat(plan.dataset.totalWeight),
            );
          },
          position: "right center",
          openOn: "hover",
          constrainToWindow: false,
          constrainToScrollParent: true,
          remove: true,
        });

        if (openAmountChooser) {
          amountDrop.open();
        }
      });
    }

    removePlanProduct = el => {
      const product = el.closest(".plan-item");

      if (product) {
        const itemList = product.closest(".plans-box-item-list");
        const mealPlan = product.closest(".plans-meal-plan");
        const box = product.closest(".plans-box");

        removeDrop(el.querySelector(".plan-item-title"));
        removeDrop(el.querySelector(".js-plans-choose-amount"));

        if (product.parentNode) {
          product.remove();
        }
        if (itemList && itemList.dataset) {
          itemList.dataset.count = itemList.children.length;
        }
        // added mealPlan safeguard
        if (this.isMeal && mealPlan) {
          delegate.fire(mealPlan, "meal:calc");
          // added itemList safeguard
        } else if (this.isRecipe && itemList) {
          delegate.fire(itemList, "meal:calc");
        }
        // added box safeguard
        if (box) {
          delegate.fire(box, "plan:calc");
        }
      }

      this.saveQueue.add(this.save);
    };

    /**
     *
     * @param {Element|Node} el
     * @param {String?} restValue
     */
    enableSuperSet(el, restValue, setsValue) {
      const list = el.querySelector(".plans-box-item-list");

      if (list) {
        addSortable(list, this.sortableItemsOptions);
      }

      const setsInputs = el.querySelectorAll(".plan-item-input-sets");
      const totalSets = setsInputs.length;
      const lastSetsIndex = totalSets - 1;

      setsInputs.forEach((node, index) => {
        node.disabled = index !== lastSetsIndex;

        if (totalSets <= 1 || index !== lastSetsIndex) {
          node.removeEventListener("change", this.onCopySets);
        } else if (index === lastSetsIndex) {
          node.addEventListener("change", this.onCopySets);

          if (setsValue) {
            node.value = setsValue;
          }
        }
      });

      const restInputs = el.querySelectorAll(".plan-item-input-rest");
      const lastRestIndex = restInputs.length - 1;

      restInputs.forEach((node, index) => {
        if (index === lastRestIndex) {
          node.disabled = false;

          if (restValue) {
            node.value = restValue;
          }
        } else {
          node.value = 0;
          node.disabled = true;
        }
      });
    }

    disableSuperSet(el) {
      const list = el.querySelector(".plans-box-item-list");
      removeSortable(list);
    }

    /**
     * @param {*} el
     * @param {*} collapsed
     */
    togglePlan = (el, collapsed) => {
      const plan = el.classList.contains("plans-box") ? el : el.closest(".plans-box");

      if (plan) {
        if (typeof collapsed !== "boolean") {
          collapsed = !plan.classList.contains("is-collapsed");
        }

        const icon = $.if(/button/i.test(el.tagName), () =>
          el.querySelector("span"),
        ).else(() => plan.querySelector("button.js-plan-toggle > span"));

        plan.classList.toggle("is-collapsed", collapsed);

        this.updateCollapsedDescription(plan, this.type);

        if (icon) {
          icon.textContent = collapsed ? "expand_more" : "expand_less";
        }

        if (!collapsed) {
          plan
            .querySelectorAll("textarea")
            .forEach(node => window.autosize && window.autosize.update(node));
        }
      }
    };

    togglePlanNew = (el, type) => {
      const plan = el.classList.contains("plans-box") ? el : el.closest(".plans-box");
      const isOpen = type === "open";
      if (plan) {
        const icon = $.if(/button/i.test(el.tagName), () =>
          el.querySelector("span"),
        ).else(() => plan.querySelector("button.js-plan-toggle > span"));
        if (isOpen) {
          plan.classList.toggle("is-collapsed", false);
          Array.from(plan.querySelectorAll(".js-plan-open")).forEach(function (el) {
            el.style.display = "none";
          });
          Array.from(plan.querySelectorAll(".js-plan-close")).forEach(function (el) {
            el.style.display = "initial";
          });
        } else {
          plan.classList.toggle("is-collapsed", true);
          Array.from(plan.querySelectorAll(".js-plan-open")).forEach(function (el) {
            el.style.display = "initial";
          });
          Array.from(plan.querySelectorAll(".js-plan-close")).forEach(function (el) {
            el.style.display = "none";
          });
        }

        this.updateCollapsedDescription(plan, this.type);

        if (icon) {
          icon.textContent = isOpen ? "expand_more" : "expand_less";
        }

        if (!isOpen) {
          plan
            .querySelectorAll("textarea")
            .forEach(node => window.autosize && window.autosize.update(node));
        }
      }
    };

    updateCollapsedDescription(el, type) {
      const collapsedDescription = el.querySelector(".plans-box-collapsed-description");

      if (type === TYPE_WORKOUT) {
        const supersets = el.querySelectorAll(
          ".plans-box-item-list > div.plan-superset",
        ).length;
        const items = el.querySelectorAll(".plans-box-item-list div.plan-item").length;

        collapsedDescription.innerHTML = `
       <span>${window.jsTrans("index.countExercisesInTotal", "plans", {
         count: items,
       })}</span>
       <span>${window.jsTrans("index.countSupersets", "plans", {
         count: supersets,
       })}</span>`;
      } else {
        const meals = [
          ...el.querySelectorAll(".plans-box-item-list div.plans-meal-plan"),
        ];
        let kcal = meals.reduce(
          (total, node) => total + (parseInt(node.getAttribute("data-kcal"), 10) || 0),
          0,
        );

        if (el.dataset.containsAlternatives === true) {
          kcal = kcal / el.dataset.meals;
        }

        collapsedDescription.innerHTML =
          el.dataset.containsAlternatives === true
            ? window.jsTrans("index.alternativesWith", "plans", {
                meals: meals.length,
                kcals: Math.round(kcal),
              })
            : window.jsTrans("index.mealsWith", "plans", {
                meals: meals.length,
                kcals: Math.round(kcal),
              });
      }
    }

    toggleItemComment(el, isHidden, isDropSet = false) {
      const item = el.classList.contains("plan-item") ? el : el.closest(".plan-item");

      if (item) {
        const commentHandle = item.querySelector(".js-plan-item-set-comment");
        const dropSetHandle = item.querySelector(".js-plan-item-set-dropset");
        const comment = item.querySelector(".plan-item-comment");
        const input = comment.querySelector("textarea");
        let value = input.value.trim();

        if (isDropSet) {
          value = (
            new RegExp("^" + window.jsTrans(DROP_SET_RE, "plans"), "i").test(value)
              ? value.replace(window.jsTrans(DROP_SET_TEXT, "plans"), "")
              : `${window.jsTrans(DROP_SET_TEXT, "plans")}\n${value}`
          ).trim();
        }

        if (typeof isHidden !== "boolean") {
          isHidden = isDropSet
            ? !Boolean(value)
            : !comment.classList.contains("is-hidden");
        }

        const switchHandle = (handle, isActive) => {
          handle.classList.toggle("is-active", isActive);
          handle.textContent = handle.dataset[isActive ? "on" : "off"];
        };

        comment.classList.toggle("is-hidden", isHidden);
        input.value = isHidden ? "" : value;

        switchHandle(commentHandle, !isHidden);
        switchHandle(
          dropSetHandle,
          new RegExp("^" + window.jsTrans(DROP_SET_RE, "plans"), "i").test(input.value),
        );
      }
    }

    switchItemSuperSet(el) {
      let item = el.closest(".plan-item");

      if (item) {
        let parent = item.parentNode;
        let isSuperSet = parent.classList.contains("plan-superset");
        let handle = item.querySelector(".js-plan-item-set-superset");

        const handleSave = isActive => {
          handle = item.querySelector(".js-plan-item-set-superset");
          handle.classList.toggle("is-active", isActive);
          handle.textContent = isActive ? handle.dataset.on : handle.dataset.off;

          this.saveQueue.add(this.save);
        };

        if (isSuperSet) {
          this.confirmDelete(
            window.jsTrans("index.areYouSure.cancel.superset", "plans"),
            confirmed => {
              if (confirmed) {
                this.disableSuperSet(parent);

                parent.parentNode.replaceChild(item, parent);

                item.querySelectorAll('input[type="text"]').forEach(node => {
                  node.removeEventListener("change", this.onCopySets);
                  node.disabled = false;
                });

                handle.classList.remove("is-active");
                handle.textContent = handle.dataset.off;

                handleSave(false);
              }
            },
          );
        } else {
          const id = item.dataset.id || randId();

          parent = htmlToElement(Templates.PlanSuperSet({ id }, this.type));
          insertAfter(
            item.cloneNode(true),
            parent.querySelector(".plan-superset-divider"),
          );

          item.parentNode.replaceChild(parent, item);
          item = this.boardList.querySelector(`.plan-superset[data-id="${id}"]`);

          this.enableSuperSet(item);

          handleSave(true);
        }
      }
    }

    updateMealIds(data) {
      data.meals.forEach(meal => {
        const mealPlan = this.board.querySelector(
          `.plans-meal-plan[data-id="${meal.id}"]`,
        );

        if (mealPlan) {
          const planProducts = mealPlan.querySelectorAll(".plan-item");

          meal.products.forEach((id, index) => {
            const planProduct = planProducts[index];

            if (planProduct) {
              planProduct.dataset.id = id;
            }
          });
        }
      });
    }

    updateRecipeIds(data) {
      const recipeProducts = this.board.querySelectorAll(".plan-item");
      /**
       * Somehow products is sometimes an object which breaks the forEach
       */
      if (!Array.isArray(data.products)) {
        return;
      }
      data.products.forEach((id, index) => {
        const recipeProduct = recipeProducts[index];

        if (recipeProduct) {
          recipeProduct.dataset.id = id;
        }
      });
    }

    updateWorkoutIds(data) {
      if (!Array.isArray(data)) {
        return;
      }

      data.forEach(day => {
        const workoutDay = this.board.querySelector(
          `.plans-box[data-id="${day.workout_day_id}"] .plans-box-item-list`,
        );

        if (workoutDay) {
          const dayExercises = workoutDay.children;

          day.workouts.forEach((exercise, index) => {
            let dayExercise = dayExercises[index];
            let daySuperExercises = [];

            if (dayExercise) {
              if (dayExercise.classList.contains("plan-superset")) {
                daySuperExercises = dayExercise.querySelectorAll(
                  ".plans-box-item-list > .plan-item",
                );
                dayExercise = dayExercise.querySelector(".plan-item");
              }

              dayExercise.dataset.id = exercise.workout_id;

              exercise.sub_workouts.forEach((superExercise, superIndex) => {
                const daySuperExercise = daySuperExercises[superIndex];

                if (daySuperExercise) {
                  daySuperExercise.dataset.id = superExercise.workout_id;
                }
              });
            }
          });
        }
      });
    }

    // @autobind
    onSearchScroll = e => {
      const target = e.target;

      if (target.scrollTop + target.clientHeight + 5 > target.scrollHeight) {
        if (this.searchResults.querySelector(".search-result-empty-state")) {
          return;
        }

        this.searchParams.page += 1;
        this.search();
      }
    };

    // @autobind
    onSearchFormSubmit = e => {
      e.preventDefault();

      const form = e.target;
      const input = form.querySelector('input[name="q"]');

      if (!input.value || input.value !== this.searchParams.q) {
        this.searchParams.equipmentId = "";
        this.searchParams.muscleId = "";

        form.querySelectorAll("select").forEach(el => (el.selectedIndex = 0));
      }

      this.searchParams.q = input.value;
      this.searchParams.page = 1;
      this.youtubeParams = {
        nextPageToken: null,
        prevPageToken: null,
        pageInfo: {},
      };

      this.search();
    };

    // @autobind
    onSearchFilterChange = e => {
      const target = e.target;
      const key = target.getAttribute("data-key");

      this.searchParams[key] = target.options[target.selectedIndex].value;
      this.searchParams.page = 1;
      this.search();
    };

    // @autobind
    onSearchLocaleChange = (e, el) => {
      stopEvent(e);
      this.locale = el.dataset.lang;
      Cookies.set(LOCALE_KEY, this.locale);
      this.saveQueue.add(this.save.bind(this, true));
    };

    // @autobind
    onProductAmountSubmit = (e, el) => {
      stopEvent(e);

      // TODO where the F does amountEntity come from? it is sometimes undefined so this is a safeguard
      if (!this.amountEntity) return;
      // const drop = el.relatedTarget;
      const radioInput = el.querySelector("input:checked");
      const row = radioInput.closest(".plan-item-amount-row");
      const weight = parseFloat(el.querySelector(".plan-item-amount-value").value);
      const id = parseInt(el.elements.weight_type.value, 10) || 0;
      const units = id
        ? parseFloat(row.querySelector(".plan-item-amount-value").value) || 0
        : 0;

      const target = this.amountEntity.target; // drop.target;
      const product = target.closest(".plan-item");

      product.dataset.totalWeight = weight;
      product.dataset.weightId = id;
      product.dataset.weightUnits = units;

      $.if(id, () => {
        const label = row.querySelector("label");
        target.textContent = `${units} x ${label.textContent}`;
      }).else(() => {
        target.textContent = `${weight}${window.jsTrans("index.gramsAbbr", "plans")}`;
      });

      //drop.close();
      this.amountEntity.close();
      delegate.fire(product, "product:calc");

      this.saveQueue.add(this.save);
    };

    // @autobind
    onProductAmountCancel = e => {
      stopEvent(e);

      if (this.amountEntity) {
        this.amountEntity.close();
      }
    };

    // @autobind
    onProductAmountSwitch = (e, el) => {
      const row = el.closest(".plan-item-amount-row");
      const total = row.querySelector(".plan-item-amount-total");
      const input = row.querySelector(".plan-item-amount-value");

      if (total) {
        total.classList.add("is-visible");
      }

      if (input) {
        delegate.fire(input, "keyup");
      }

      row.parentNode.querySelectorAll(".plan-item-amount-total").forEach(node => {
        if (node !== total) {
          node.classList.remove("is-visible");
        }
      });
    };

    // @autobind
    onProductCalculate = (e, el) => {
      const target = el.querySelector(".js-plans-product-kcal");

      if (target) {
        target.textContent =
          Math.round((el.dataset.kcal / 100) * el.dataset.totalWeight) || 0;
      }

      if (this.isMeal) {
        delegate.fire(el.closest(".plans-meal-plan"), "meal:calc");
      } else if (this.isRecipe) {
        delegate.fire(el.closest(".plans-box-item-list"), "meal:calc");
      }
    };

    // @autobind
    onProductAmountUpdate = (e, el) => {
      const form = el.closest("form");
      // const product = form.relatedTarget.target.closest('.plan-item');
      // TODO where the F does amountEntity come from? it is sometimes undefined so this is a safeguard
      if (!this.amountEntity) return;
      const product = this.amountEntity.target.closest(".plan-item");
      const weightInput = form.querySelector(".plan-item-amount-value");
      const isWeightInput = el === weightInput;
      const totalWeight = isWeightInput
        ? parseFloat(el.value)
        : parseFloat(el.value) * parseFloat(el.dataset.weight);

      const totalEnergy = (parseFloat(product.dataset.kcal) / 100) * totalWeight;

      if (!isWeightInput) {
        weightInput.value = totalWeight || 0;
      }

      const totalNode = el.parentNode.querySelector("var");

      if (totalNode) {
        totalNode.textContent = Math.round(totalEnergy);
      }

      product.dataset.totalKcal = totalEnergy;
    };

    // @autobind
    onMealCalculate = (e, el) => {
      const items = el.querySelectorAll(".plan-item");
      const totalsNodeList = el.querySelectorAll(".plans-meal-totals var");
      const totals = {
        carbohydrate: 0,
        protein: 0,
        fat: 0,
        weight: 0,
        kcal: 0,
      };

      items.forEach(item => {
        const weight = parseFloat(item.dataset.totalWeight) || 0;
        const kcal = parseFloat(item.dataset.kcal) || 0;
        const energy = Math.round((kcal / 100) * weight) || 0;

        totals.carbohydrate += (weight / 100) * parseFloat(item.dataset.carbohydrates);
        totals.protein += (weight / 100) * parseFloat(item.dataset.protein);
        totals.fat += (weight / 100) * parseFloat(item.dataset.fat);
        totals.weight += weight;
        totals.kcal += energy;
      });

      Object.keys(totals).forEach((key, index) => {
        const target = totalsNodeList[index];

        if (target) {
          target.textContent = Math.round(totals[key]);
        }

        el.dataset[key] = totals[key];
      });

      const box = el.closest(".plans-box");

      if (box) {
        delegate.fire(box, "plan:calc");
      }
    };

    // @autobind
    onPlanWorkoutCalculate = (e, el) => {
      const collapsedDescription = el.querySelector(".plans-box-collapsed-description");
      const exercises =
        el.querySelectorAll(".plans-box-plan > .plans-box-item-list > .plan-item")
          .length + el.querySelectorAll(".plan-superset > .plan-item").length;
      const supersets = el.querySelectorAll(".plan-superset").length;

      if (collapsedDescription) {
        collapsedDescription.innerHTML = html`
          <span
            >${window.jsTrans("index.countSingleExercises", "plans", {
              count: exercises,
            })}</span
          >
          <span
            >${window.jsTrans("index.countSuperSets", "plans", {
              count: supersets,
            })}</span
          >
        `;
      }
    };

    // @autobind
    onPlanCalculate = (e, el) => {
      let totals = {
        kcal: 0,
        protein: 0,
        carbohydrate: 0,
        fat: 0,
      };

      let keys = Object.keys(totals);

      const updateHeaderTotals = () => {
        const containsAlternatives = this.plan.contains_alternatives;
        const max = totals.protein * 4 + totals.carbohydrate * 4 + totals.fat * 9;

        if (this.isRecipe || !containsAlternatives) {
          const totalKcalNode = this.board.querySelector(".js-header-total-kcal");

          if (totalKcalNode) {
            totalKcalNode.textContent = totals.kcal;
          }
        } else {
          const avgKcalNode = this.board.querySelector(".js-header-avg-kcal");

          if (avgKcalNode) {
            avgKcalNode.textContent = totals.kcal;
          }
        }

        let macros = {
          carbohydrate: 4,
          protein: 4,
          fat: 9,
        };

        for (var macro in macros) {
          const node = this.board.querySelector(`.js-header-${macro}`);

          if (node) {
            node.textContent = `${Math.round(totals[macro])}${window.jsTrans(
              "index.gramsAbbr",
              "plans",
            )} (${percent(totals[macro] * macros[macro], max)}%)`;
          }
        }
      };

      const updatePlanDiff = (planNode, diff) => {
        if (+this.plan.desired_kcals > 0 && +this.plan.contains_alternatives === 1) {
          const diffNode = planNode.querySelector(".offLabel");
          if (diffNode) {
            diffNode.textContent =
              diff > 30 ? window.jsTrans("index.kcalsOff", "plans", { count: diff }) : "";
          }
        }
      };

      if (this.isMeal) {
        const containsAlternatives = this.plan.contains_alternatives;

        document.querySelectorAll(".plans-box").forEach(plan => {
          let mealTotals = {
            kcal: 0,
            carbohydrate: 0,
            protein: 0,
            fat: 0,
          };
          plan.querySelectorAll(".plans-meal-plan").forEach(meal => {
            keys.forEach(key => {
              mealTotals[key] += parseFloat(meal.dataset[key]);
            });
            const idealKCals = meal.dataset.idealKcals;
            const totalKCals = meal.dataset.kcal;
            const diff = Math.abs(idealKCals - totalKCals);
            updatePlanDiff(meal, diff);
          });

          let alternatives = plan.querySelectorAll(".plans-meal-plan").length;
          keys.forEach(key => {
            totals[key] = containsAlternatives
              ? Math.round(totals[key] + mealTotals[key] / alternatives)
              : Math.round(totals[key] + mealTotals[key]);
          });
        });

        updateHeaderTotals();
      } else if (this.isRecipe) {
        let itemList = el.querySelector(".plans-box-item-list");
        let recipeTotalsNodeList = el.querySelectorAll(".plans-meal-totals var");

        totals.weight = 0;

        if (itemList) {
          [...keys, "weight"].forEach(key => {
            totals[key] += parseFloat(itemList.dataset[key]);
          });
        }

        updateHeaderTotals();

        ["carbohydrate", "protein", "fat", "weight", "kcal"].forEach((key, index) => {
          const target = recipeTotalsNodeList[index];

          if (target) {
            target.textContent = Math.round(totals[key]);
          }
        });
      }

      // let totalsNodeList = el.querySelectorAll('.plans-box-side .plans-box-totals-list var');
      //
      // keys.forEach((key, index) => {
      //   const target = totalsNodeList[index];
      //   if (target) {
      //     if(el.dataset.containsAlternatives === 'true') {
      //       target.textContent = Math.round(totals[key] / el.dataset.meals);
      //     } else {
      //       target.textContent = Math.round(totals[key]);
      //     }
      //   }
      // });

      // const chart = charts.get(el.querySelector('.plans-box-side-chart'));
      //
      // if (chart) {
      //   chart.series[0].setData([
      //     ['Protein', totals.protein * 4],
      //     ['Carbohydrates', totals.carbohydrate * 4],
      //     ['Fat', totals.fat * 9]
      //   ]);
      // }
    };

    // @autobind
    onCopySets = e => {
      const target = e.target;
      const inputs = target
        .closest(".plan-superset")
        .querySelectorAll(".plan-item-input-sets");

      inputs
        .toArray()
        .slice(0, inputs.length - 1)
        .forEach(node => (node.value = target.value));

      this.saveQueue.add(this.save);
    };

    // @autobind
    onAddEditPlan = e => {
      stopEvent(e);

      const $form = $(e.target);
      const $submit = $form.find("[type=submit]").button("loading");

      const promise = axios.post($form.attr("action"), new FormData(e.target), "json");
      const notification = {
        title: "",
        description: "",
      };

      promise
        .then(response => {
          const plan = response.data;
          let target = plan.parent_id
            ? this.boardList.querySelector(`.plans-meal-plan[data-id="${plan.id}"]`)
            : this.boardList.querySelector(`.plans-box[data-id="${plan.id}"]`);

          if (this.isWorkout) {
            notification.title = window.jsTrans("index.updated.workoutPlan", "plans");
          } else {
            notification.title = plan.parent_id
              ? window.jsTrans("index.updated.mealPlan", "plans")
              : window.jsTrans("index.updated.meal", "plans");
          }

          if (target) {
            target.querySelector("#plan-name").textContent = plan.name;
            target.querySelector("#plan-link").dataset.title = plan.name;

            if (this.isWorkout) {
              notification.description = window.jsTrans(
                "index.renamed.workoutPlanDay",
                "plans",
              );
            } else {
              // Meal plan meal
              if (plan.parent_id && plan.image) {
                const mealHeaderContent = target.querySelector("#meal-details-header");
                const parentNode = mealHeaderContent.parentNode;

                const existingImageHeader = parentNode.querySelector(
                  "#meal-details-header-image",
                );

                // If we already have an image, replace the old one
                if (existingImageHeader) {
                  existingImageHeader.src = plan.image;
                } else {
                  const newMealImageElement = htmlToElement(
                    getMealHeaderImageHtmlString(plan),
                  );

                  parentNode.insertBefore(newMealImageElement, mealHeaderContent);
                }
              }
            }
          } else {
            if (plan.parent_id) {
              target = document.querySelector(
                `.plans-box[data-id="${plan.parent_id}"] .plans-box-item-list`,
              );

              if (target) {
                this.addPlanMeal(target, plan);

                notification.description = window.jsTrans("index.created.meal", "plans");
              }
            } else {
              const planData = {};

              if (this.isWorkout) {
                planData.workouts = [];
              }

              this.boardList
                .querySelectorAll(".plans-box")
                .forEach(el => el.classList.add("is-collapsed"));

              this.addPlan({ ...planData, ...plan });

              if (this.isWorkout) {
                notification.description = window.jsTrans(
                  "index.created.workoutDay",
                  "plans",
                );
              } else {
                notification.description = window.jsTrans(
                  "index.created.mealPlan",
                  "plans",
                );
              }
            }
          }

          $form.closest(".modal").modal("hide");

          SnackbarUtils.success(`${notification.title}\n${notification.description}`);
        })
        .catch((...args) => {
          console.log("#addPlanModal::fail", args);
        })
        .then(() => {
          $submit.button("reset");
        });
    };

    async fetchPlans() {
      if (this.isWorkout) {
        return api.getWorkout(this.plan.id);
      } else if (this.isRecipe) {
        return api.getRecipe(this.plan.id);
      }
      return api.getMeals(this.plan.id, this.locale, this.plan.meal);
    }

    async fetchFilters() {
      if (!this.isWorkout) return;

      try {
        this.data.equipments = prepareOptions(EQUIPMENT);
        this.data.muscles = prepareOptions(MUSCLE_GROUPS);
      } catch (e) {}
    }

    tempElements = [];

    // @autobind
    sortableDragImage = (dataTransfer, dragEl) => {
      const crt = dragEl.cloneNode(true);

      crt.classList.add("is-collapsed");
      this.tempElements.push(crt);

      document.querySelector(".plans-temp").appendChild(crt);
      dataTransfer.setDragImage(crt, 0, 0);
    };

    clearTempElements() {
      this.tempElements.forEach(node => node.remove());
      this.tempElements.length = 0;
    }

    refreshExercises(item) {
      const isChildren = Boolean(item.closest("[data-children]"));
      const superSetLink = item.querySelector(".js-plan-item-set-superset");
      let refreshSuperSet = null;

      if (superSetLink) {
        superSetLink.classList.toggle("is-hidden", isChildren);
      }

      if (isChildren) {
        let lastRest;
        let lastSets;

        if (item.previousElementSibling && !item.nextElementSibling) {
          lastRest = lastSets = item.previousElementSibling;
        } else if (item.nextElementSibling) {
          lastSets = item.nextElementSibling;
        } else {
          lastRest = lastSets = item
            .closest(".plan-superset")
            .querySelector(".plan-item");
        }

        try {
          if (lastRest) {
            lastRest = lastRest.querySelector(".plan-item-input-rest").value;
          }

          if (lastSets) {
            lastSets = lastSets.querySelector(".plan-item-input-sets").value;
          }

          refreshSuperSet = [item.closest(".plan-superset"), lastRest, lastSets];
        } catch (e) {}

        if (refreshSuperSet) {
          this.enableSuperSet(...refreshSuperSet);
        }
      } else {
        item.querySelectorAll('input[type="text"]').forEach(el => {
          el.disabled = false;
          el.removeEventListener("change", this.onCopySets);
        });
      }
    }

    get locale() {
      return this.options.locale;
    }

    set locale(value) {
      this.options.locale = value;
    }

    get plan() {
      return this.options.plan;
    }

    get client() {
      return this.options.client;
    }

    get template() {
      return this.options.template;
    }

    get tour() {
      return this.options.tour;
    }

    get isWorkout() {
      return this.type === TYPE_WORKOUT;
    }

    get isMeal() {
      return this.type === TYPE_MEAL;
    }

    get isRecipe() {
      return this.type === TYPE_RECIPE;
    }

    get isTemplate() {
      if (this.plan.hasOwnProperty("template")) {
        return !!this.plan.template;
      }

      return this.template !== null;
    }

    get sortablePlansOptions() {
      const options = {
        sort: true,
        scroll: this.board,
        scrollSensitivity: SORTABLE_SCROLL_SENSITIVITY,
        group: {
          name: "plans",
          pull: false,
          put: false,
        },
        animation: 150,
        onStart: e => {
          [e.item, e.clone].forEach(node => node && node.classList.add("is-collapsed"));

          e.from.children.forEach(plan => {
            plan.dataset.wasCollapsed = String(plan.classList.contains("is-collapsed"));
            // prevent the event for triggering twice
            _.debounce(() => this.togglePlan(plan, true), 200);
          });

          if (this.isMeal) {
            closeAllDrops();
          }
        },
        onEnd: e => {
          [e.item, e.clone].forEach(
            node => node && node.classList.remove("is-collapsed"),
          );

          e.from.children.forEach(plan => {
            // prevent the event for triggering twice
            _.debounce(
              () => this.togglePlan(plan, plan.dataset.wasCollapsed === "true"),
              200,
            );
            plan.dataset.wasCollapsed = undefined;
          });

          this.clearTempElements();
        },
        onUpdate: () => {
          this.saveQueue.add(this.save);
        },
      };

      if (!IS_FIREFOX) {
        options.setData = this.sortableDragImage;
      }

      // if (IS_TOUCH) {
      options.handle = ".plans-box-handle";
      // }

      return options;
    }

    get sortableMealsOptions() {
      let collapsedMeals = [];

      const options = {
        sort: true,
        dataIdAttr: "data-id",
        scroll: this.board,
        scrollSensitivity: SORTABLE_SCROLL_SENSITIVITY,
        // group: {
        //   name: 'meals',
        //   pull: true,
        //   put: true
        // },
        group: "meals",
        animation: 150,
        onStart: e => {
          [e.item, e.clone].forEach(node => node && node.classList.add("is-collapsed"));

          closeAllDrops();

          collapsedMeals = this.board
            .querySelectorAll(".plans-meal-plan")
            .toArray()
            .filter(el => el !== e.item);

          collapsedMeals.forEach(el => el.classList.add("plans-meal-plan--collapsed"));
        },
        onEnd: e => {
          [e.item, e.clone].forEach(
            node => node && node.classList.remove("is-collapsed"),
          );

          collapsedMeals.forEach(el => el.classList.remove("plans-meal-plan--collapsed"));

          collapsedMeals.length = 0;

          this.clearTempElements();
        },
        onUpdate: () => {
          this.saveQueue.add(this.save);
        },
        onAdd: e => {
          this.saveQueue.add(this.save);

          const fromPlanBox = e.from.closest(".plans-box");
          const toPlanBox = e.to.closest(".plans-box");

          delegate.fire(toPlanBox, "plan:calc");

          if (fromPlanBox !== toPlanBox) {
            delegate.fire(fromPlanBox, "plan:calc");
          }
        },
      };

      if (!IS_FIREFOX) {
        options.setData = this.sortableDragImage;
      }

      // if (IS_TOUCH) {
      options.handle = ".plans-handle";
      // }

      return options;
    }

    get sortableItemsOptions() {
      const options = {
        sort: true,
        dataIdAttr: "data-id",
        scroll: this.board,
        scrollSensitivity: SORTABLE_SCROLL_SENSITIVITY,
        // group: {
        //   name: 'advanced',
        //   pull: true,
        //   put: true
        // },
        group: "items",
        animation: 150,
        onStart: () => {
          this.container.classList.add("plans-dragging");
        },
        onEnd: () => {
          this.container.classList.remove("plans-dragging");
        },
        onUpdate: e => {
          if (e.from.classList.contains("plans-box-item-list") && this.isWorkout) {
            this.refreshExercises(e.item);
          }

          this.saveQueue.add(this.save);
        },
        onRemove: e => {
          //e.target.dataset.count = e.target.children.length;

          if (this.isWorkout) {
            delegate.fire(e.target.closest(".plans-box"), "plan:calc");
          }

          if (this.isMeal || this.isRecipe) {
            closeAllDrops();
          }
        },
        onAdd: e => {
          if (this.isMeal || this.isRecipe) {
            closeAllDrops();
          }

          if (e.from.classList.contains("plans-search-result")) {
            const data = JSON.parse(decodeURI(e.item.dataset.item));

            if (this.isWorkout) {
              data.sets = 3;
              data[data.type.name.toLowerCase()] = 12;
              data.rest = 60;

              this.addPlanExercise(e.item, data, true);
            } else if (this.isMeal || this.isRecipe) {
              if (e.target.querySelector(`[data-product-id="${data.product.id}"]`)) {
                const description = window.jsTrans(
                  "index.youCantHaveSameFoodItemTwice",
                  "plans",
                );

                SnackbarUtils.error(
                  `${window.jsTrans(
                    "recipes.recipeModal.anErrorOccured",
                    TranslatorNS.MEAL,
                  )}\n${description}`,
                );

                if (e.item.parentNode) {
                  e.item.remove();
                }
                return;
              }

              this.addPlanProduct(e.item, data, true, true);
            }

            if (e.item.parentNode) {
              e.item.remove();
            }
          } else if (e.from.classList.contains("plans-box-item-list")) {
            if (this.isWorkout) {
              this.refreshExercises(e.item);
            }

            if (this.isMeal) {
              delegate.fire(e.from.closest(".plans-meal-plan"), "meal:calc");
              delegate.fire(e.to.closest(".plans-meal-plan"), "meal:calc");
            }

            delegate.fire(e.from.closest(".plans-box"), "plan:calc");
            e.from.dataset.count = e.from.children.length;
          }

          delegate.fire(e.target.closest(".plans-box"), "plan:calc");
          e.target.dataset.count = e.target.children.length;

          this.saveQueue.add(this.save);
        },
      };

      // if (IS_TOUCH) {
      options.handle = ".plans-handle";
      // }

      return options;
    }

    get sortableSearchOptions() {
      let dropZones = [];

      const options = {
        sort: false,
        scroll: this.board,
        scrollSensitivity: SORTABLE_SCROLL_SENSITIVITY,
        filter: ".no-drag",
        group: {
          // name: 'advanced',
          name: "items",
          pull: "clone",
          put: false,
        },
        animation: 150,
        onStart: e => {
          this.container.classList.add("plans-dragging");

          (dropZones = document
            .querySelectorAll(".plan-box-drop-zone")
            .toArray()).forEach(el => el.classList.add("is-highlighted"));

          if (IS_TOUCH) {
            const target = e.item.closest(".plans-search-result");

            if (target) {
              target.classList.remove("has-touch-scrolling");
            }
          }
        },
        onEnd: e => {
          this.container.classList.remove("plans-dragging");

          dropZones.forEach(el => el.classList.remove("is-highlighted"));
          dropZones.length = 0;

          if (IS_TOUCH) {
            const target = e.item.closest(".plans-search-result");

            if (target) {
              target.classList.add("has-touch-scrolling");
            }
          }
        },
      };

      if (IS_TOUCH) {
        options.handle = ".plans-handle";
      }

      return options;
    }

    get workoutFormData() {
      const results = [];

      this.boardList
        .querySelectorAll(".plans-box")
        .forEach(function (planBox, planIndex) {
          const plan = {
            day_id: planBox.dataset.id,
            workoutDayComment: planBox.querySelector(".plans-box-comment textarea").value,
            order: planIndex + 1,
            exercises: [],
          };

          const entityList = planBox.querySelector(".plans-box-item-list").children;

          entityList.forEach((node, index) => {
            const isSuperSet = node.classList.contains("plan-superset");
            const item = isSuperSet ? node.querySelector(".plan-item") : node;

            const exercise = serializeExerciseItem(item, index + 1);

            if (isSuperSet) {
              item.parentNode
                .querySelector(".plans-box-item-list")
                .children.forEach((childrenNode, childrenIndex) => {
                  exercise.superset.push(
                    serializeExerciseItem(childrenNode, childrenIndex + 1),
                  );
                });
            }

            plan.exercises.push(exercise);
          });

          results.push(plan);
        });

      return { results };
    }

    get mealFormData() {
      const results = [];

      this.boardList
        .querySelectorAll(".plans-box")
        .forEach(function (planBox, planIndex) {
          const plan = {
            id: planBox.dataset.id,
            meals: [],
            order: planIndex + 1,
            comment: planBox.querySelector(
              ".plans-box-plan > .plans-box-comment textarea",
            ).value,
          };

          planBox.querySelectorAll(".plans-meal-plan").forEach((mealNode, mealIndex) => {
            const meal = {
              id: mealNode.dataset.id,
              parent: plan.id,
              order: mealIndex + 1,
              comment: mealNode.querySelector(".plans-box-comment textarea").value,
              products: [],
            };

            mealNode
              .querySelectorAll(".plan-item")
              .forEach((node, productIndex) =>
                meal.products.push(serializeProductItem(node, productIndex + 1)),
              );

            plan.meals.push(meal);
          });

          results.push(plan);
        });

      return { results, locale: this.locale };
    }

    get recipeFormData() {
      const planBox = this.boardList.querySelector(".plans-box");
      const recipe = {
        id: planBox.dataset.id,
        comment: planBox.querySelector(".plans-box-plan > .plans-box-comment textarea")
          .value,
        products: [],
        order: 1,
      };

      planBox
        .querySelectorAll(".plan-item")
        .forEach((node, productIndex) =>
          recipe.products.push(serializeProductItem(node, productIndex + 1)),
        );

      return recipe;
    }
  };
})();

global.Plans = Plans;

export const InitPlans = Plans;

// Global jQuery Listeners
const $body = $("body");

$body.on("hidden.bs.modal", "#saveAsPdf", function () {
  $(this)
    .find(".modal-success, a[role=button]")
    .remove()
    .end()
    .find(
      ".modal-title, .modal-body-main, button[type=submit], .description, .sub-description",
    )
    .show();
});

$body.on("click", ".js-save-pdf", function () {
  $("#saveAsPdf").modal("show");
});

$body.on("submit", "#saveAsPdf form", function (event) {
  event.preventDefault();
  const $form = $(this);
  const $modal = $("#saveAsPdf");
  const $submit = $form.find("button[type=submit]").button("loading");

  const xhr = axios.post($form.attr("action"), $form.serialize(), "json");

  xhr
    .then(response => {
      $modal.data("documentsUrl");

      $modal.find(".modal-title, .modal-body-main").hide();

      $modal
        .find(".modal-body")
        .append(
          Templates.ModalSuccessMessage(
            window.jsTrans("index.yourPdfIsBeingGenerated", "plans"),
            window.jsTrans("index.onceFinishedItWillBeSent", "plans"),
          ),
        );

      $submit
        .button("reset")
        .hide()
        .after(
          `<a data-dismiss="modal" class="btn btn-block btn-default" role="button">${window.jsTrans(
            "index.okIGotIt",
            "plans",
          )}</a>`,
        );

      if (response.url) {
        setTimeout(() => {
          window.location = response.url;
        }, 100);
      }
    })
    .catch(err => {
      $submit.button("reset");

      var json = JSON.parse(err.responseText);

      if (json.subscription) {
        SnackbarUtils.info(
          `${window.jsTrans(
            `index.upgradeYourPlan1', 'plans`,
          )}<a href="/dashboard/subscription">${window.jsTrans(
            `index.upgradeYourPlan2', 'plans`,
          )}</a>${window.jsTrans(`index.upgradeYourPlan3', 'plans`)}`,
        );
        return;
      }
    });
});

$body.on("click", ".js-save-template", function () {
  $("#saveAsTemplate").modal("show");
});

$body.on("click", ".js-use-template", function (event) {
  event.preventDefault();
  let $modal = $("#addMealTemplate");

  if (!$modal.length) {
    $modal = $("#addWorkoutTemplate");
  }

  $modal.modal("show");
});

/*Add day modal trigger*/
$body.on("click", ".js-add-plan", function (event) {
  stopEvent(event);

  const title = this.dataset.title;
  const id = this.dataset.id;
  const parentId = this.dataset.parent;
  const isMeal = this.dataset.type === TYPE_MEAL;
  const isRecipe = this.dataset.type === TYPE_RECIPE;
  const $modal = $(isRecipe ? "#recipeModal" : "#addPlanModal");
  const shouldHideUploadImageInput = !!this.dataset.hideUploadImage;

  let type = window.jsTrans("index.typeWorkoutDay", "plans");

  if (isMeal) {
    type = window.jsTrans("index.typeMealPlan", "plans");
  } else if (isRecipe) {
    type = window.jsTrans("index.typeRecipe", "plans");
  }

  let placeholder = window.jsTrans("index.titleOfWorkoutDay", "plans");

  if (isMeal) {
    placeholder = parentId
      ? window.jsTrans("index.titleOfMeal", "plans")
      : window.jsTrans("index.titleOfMealPlan", "plans");
    $modal
      .find("label[for=mealTitle]")
      .text(
        parentId
          ? window.jsTrans("index.titleOfMeal", "plans")
          : window.jsTrans("index.titleOfMealPlan", "plans"),
      );
  } else if (isRecipe) {
    placeholder = window.jsTrans("index.nameOfRecipe", "plans");
  }

  $modal.modal("show");
  $modal.find("input[name=id]").val(id || null);
  $modal.find("input[name=parent_id]").val(parentId || null);
  $modal.find("input[name=image]").val(null);
  $modal
    .find("input[name=name]")
    .val(title || "")
    .attr("placeholder", placeholder);

  if (isMeal) {
    $modal.find("input[name=parent_id]").val(parentId || null);
    const uploadMealInput = $modal.find("#upload-meal-image-input");
    shouldHideUploadImageInput ? uploadMealInput.hide() : uploadMealInput.show();
  }

  let addTitle;

  if (isMeal) {
    addTitle = parentId
      ? window.jsTrans("index.createNew.meal", "plans")
      : window.jsTrans("index.createNew.mealPlan", "plans");
  } else if (isRecipe) {
    addTitle = window.jsTrans("index.createNew.recipe", "plans");
  } else {
    addTitle = window.jsTrans("index.createNew.workoutDay", "plans");
  }

  $modal
    .find(".modal-title")
    .text(id ? window.jsTrans("index.edit", "plans") + " " + type : addTitle);
});

// Save recipe modal
$body.on("click", ".js-recipe-save", function (event) {
  stopEvent(event);

  const $modal = $("#recipeModal");

  const { id = "", sourceMeal = "" } = $(this).data();

  $modal.modal("show");

  $modal.find("#recipeId").val(id);
  $modal.find("#recipeSourceMeal").val(sourceMeal);
});

$body.on("click", ".js-plan-clone", function (event) {
  stopEvent(event);

  const $modal = $("#clonePlanModal");
  const data = $(this).data();
  const plans = this.closest(".plans");

  let title;
  let submit;

  if (plans && plans.dataset.type === TYPE_WORKOUT) {
    title = window.jsTrans("index.copy.thisWorkoutPlan", "plans");
    submit = window.jsTrans("index.copy.workoutPlan", "plans");
  } else {
    const isMeal = Boolean(this.closest(".plans-meal-plan"));

    title = isMeal
      ? window.jsTrans("index.copy.thisMeal", "plans")
      : window.jsTrans("index.copy.thisMealPlan", "plans");
    submit = isMeal
      ? window.jsTrans("index.copy.meal", "plans")
      : window.jsTrans("index.copy.mealPlan", "plans");
  }

  $modal.modal("show");
  $modal
    .find("input[name=name]")
    .val(window.jsTrans("index.copyOf", "plans") + " " + data.title);
  $modal.find("input[name=id]").val(data.id);
  $modal.find(".modal-title").text(title);
  $modal.find('button[type="submit"]').text(submit);

  $modal.on("click", ".btn-success", function (e) {
    stopEvent(e);

    let $form = $modal.find("form");
    const $submit = $form.find("[type=submit]").button("loading");

    let data = {
      id: $form.find("input[name=id]").val(),
      name: $form.find("input[name=name]").val(),
    };

    axios
      .post($form.attr("action"), data)
      .then(() => {
        SnackbarUtils.success(
          `${window.jsTrans("index.cloning", "plans")}\n${window.jsTrans(
            "index.successfully",
            "plans",
          )}`,
        );

        window.location.reload();
      })
      .catch(function () {
        SnackbarUtils.error(window.jsTrans("index.cloningFailed", "plans"));
      })
      .finally(function () {
        $submit.button("reset");
        $modal.modal("hide");
      });
  });
});

$body.on("click", ".js-plan-settings", function (event) {
  stopEvent(event);
  $("#planSettingsModal").modal("show");
});

$body.on("click", ".js-assign-template", function (event) {
  stopEvent(event);
  $("#assignTemplate").modal("show");
});

$body.on("click", ".js-template-title", function (event) {
  stopEvent(event);
  let data = $(this).data();

  if (!mealPlanTitle) {
    mealPlanTitle = data.name;
  }

  if (!mealPlanComment) {
    mealPlanComment = data.comment || data.explaination;
  }

  if (!workoutsPerWeek) {
    workoutsPerWeek = data.workoutsperweek;
  }

  if (!location) {
    location = data.location;
  }

  if (!gender) {
    gender = data.gender;
  }

  if (!level) {
    level = data.level;
  }

  if (!duration) {
    duration = data.duration;
  }

  //meal stuff
  $("#mealPlanTitle").val(mealPlanTitle);
  $("#mealPlanComment").val(mealPlanComment);

  $("#editTemplateText").modal("show");
});

$body.on("click", "#planSettingsModal input[type=checkbox]", function () {
  const $target = $(this);
  const name = $target.attr("name");

  if (name === "reps" || name === "time") {
    const mirror = {
      reps: "time",
      time: "reps",
    };

    $target
      .closest("form")
      .find(`input[name="${mirror[name]}"]`)
      .prop("checked", $target.prop("checked"));
  }
});

$body.on("click", ".js-exercise-preview", async function (event) {
  stopEvent(event);
  const $exerciseModal = $("#exerciseModal");
  const YOUTUBE_URL = "https://www.youtube.com/embed/";
  const VIMEO_URL = "https://player.vimeo.com/video/";
  try {
    const url = this.getAttribute("href");
    $exerciseModal.modal("show", this);
    const { data } = await axios.get(url);
    const video = getVideoIdFromYouTubeVimeo(data.video);
    $exerciseModal.find(".exerciseLoader").hide();
    $exerciseModal.find(".exerciseLoader").hide();
    const width = $exerciseModal.find(".modal-body").width();
    const height = $exerciseModal.find(".modal-body").height();
    $exerciseModal.find(".exerciseTitle").text(data?.name);
    if (video.youtube) {
      $exerciseModal.find(".exerciseIframe").attr("src", YOUTUBE_URL + video.youtube);
    } else if (video.vimeo) {
      $exerciseModal.find(".exerciseIframe").attr("src", VIMEO_URL + video.vimeo);
    }
    $exerciseModal.find(".sk-spinner").hide();
    $exerciseModal.find(".exerciseIframe").width(width).height(height);
    $exerciseModal.find(".exerciseIframe").show();
  } catch (e) {
    console.error(e);
  }
});

$body.on("click", ".exerciseClose, #exerciseModal", function (event) {
  stopEvent(event);
  const $exerciseModal = $("#exerciseModal");
  $exerciseModal.find(".exerciseIframe").attr("src", "");
  $exerciseModal.find(".exerciseTitle").text("");
  $exerciseModal.find(".exerciseIframe").hide();
  $exerciseModal.find(".sk-spinner").show();
  $exerciseModal.find(".exerciseLoader").show();
});

$body.on("change", ".js-plan-macro-split-select", function () {
  let value = this.value;

  axios
    .post(`/meal/recipes/${this.dataset.recipeId}/update`, {
      macro_split: value,
    })
    .then(() => {
      $("[data-macro-split]").attr("data-macro-split", value);
      SnackbarUtils.success(
        `${window.jsTrans("index.recipeUpdate", "plans")}\n${window.jsTrans(
          "index.macroSplitUpdated",
          "plans",
        )}`,
      );
    })
    .catch(() => {
      SnackbarUtils.error(
        `${window.jsTrans("index.recipeUpdate", "plans")}\n${window.jsTrans(
          "index.macroSplitNotUpdated",
          "plans",
        )}`,
      );
    });
});

$body.on("click", ".js-recipe-change-image", function (event) {
  event.preventDefault();
  $("#recipeModalImage").modal("show", this);
});

$body.on("click", ".js-apply-recipe", function (event) {
  event.preventDefault();
  $("#applyMealRecipe").modal("show", this);
});

/*Edit workout template*/
$(document).ready(function () {
  var $modal = $("#editTemplateText");
  var $modalTitle = $modal.find(".modal-title");
  var $modalBody = $modal.find(".modal-body");
  var $modalDescription = $modal.find(".modal-description");
  var $modalForm = $modal.find("form");
  var $submit = $modalForm.find('button[type="submit"]');
  var currentPlan = null;

  $modal
    .on("show.bs.modal", function (event) {
      var data = $(event.relatedTarget).data() || {};

      if (data.title) {
        $modalTitle.text(data.title);
      }

      if (data.action) {
        $modalForm.attr("action", data.action);
      }

      $modalDescription.text(data.description || "");
      $modalForm.find('[name="plan"]').val(data.plan || "");

      currentPlan = data.name || data.explaination;

      if (currentPlan) {
        var name = data.name;

        if (data.plan) {
          name = `${window.jsTrans("plan.copyOf", "plans")} ${name}`;
        }

        $modalForm.find('[name="name"]').val(name);
      }

      $submit.text(
        currentPlan && !data.plan
          ? window.jsTrans("plan.saveChanges", "plans")
          : window.jsTrans("plan.createWorkoutPlan", "plans"),
      );
    })
    .on("hidden.bs.modal", function () {
      var data = $modal.data();

      $modalTitle.text(data.title);
      $modalDescription.text(data.description);
      $modalForm.attr("action", $modalForm.data("action"));
      $modalBody.find(".alert-danger").remove();
      $modalForm.find('[name="plan"]').val("");

      if (currentPlan) {
        $modalForm.find('[name="name"]').val("");
      }

      currentPlan = null;
    });

  $modalForm.on("submit", function (event) {
    var title = $modalForm.find('[name="name"]').val();
    var $alert = $modalBody.find(".alert-danger");

    if (title === "") {
      event.preventDefault();

      if (!$alert.length) {
        $modalBody.prepend(
          '<div class="alert alert-danger"> ' +
            window.jsTrans("plan.youNeedToGive", "plans") +
            "</div>",
        );
      }
    } else {
      $alert.remove();
    }
  });
});

/*General modals*/
$(document).ready(function () {
  window.autosize($("textarea"));

  $("#saveTemplate").click(function (e) {
    e.preventDefault();
    if ($("#applyTemplateForm input:checkbox:checked").length > 0) {
      $("#applyTemplateCheckboxes").hide();
      $("#spinnerApplyTemplate").show();
      $("#applyTemplateForm").submit();
    }
  });
});

/*Apply recipe to meal plan*/
$(document).ready(function () {
  var $modal = $("#applyMealRecipe");

  var $ownFilter = $modal.find("#recipes_filter_mine");

  var widget = {
    $modal: $modal,
    $ownFilter: $ownFilter,
    $list: $modal.find(".js-modal-recipe-list"),
    $spinner: $modal.find(".modal-recipes-spinner"),
    $empty: $modal.find(".modal-recipes-empty"),
    $more: $modal.find(".js-modal-recipes-more"),
    endpoint: `${ROOT}/api/recipes/get-recipes`,
    imagePlaceholder: "/bundles/app/images/recipe-placeholder.png?",
    params: {},
    data: [],
    limit: 20,
    page: 0,
  };

  var cookingTimes = {
    1: "0-10",
    2: "10-20",
    3: "20-30",
    4: "+30",
  };

  var macroSplits = {
    1: "50/30/20",
    2: "40/40/20",
    3: "30/30/40",
    4: "10/30/60",
    5: "20/40/40",
  };

  var types = {
    1: window.jsTrans("applyRecipes.types.breakfast", "plans"),
    2: window.jsTrans("applyRecipes.types.lunch", "plans"),
    3: window.jsTrans("applyRecipes.types.dinner", "plans"),
    4: window.jsTrans("applyRecipes.types.morning_snack", "plans"),
    5: window.jsTrans("applyRecipes.types.afternoon_snack", "plans"),
    6: window.jsTrans("applyRecipes.types.evening_snack", "plans"),
  };

  var fuse = new window.Fuse(widget.data, {
    shouldSort: true,
    findAllMatches: true,
    matchAllTokens: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ["name"],
  });

  function render(data) {
    //Array.isArray(data) ? data.length : 0;

    /*
     if (!hasData && !count) {
       return widget.$empty.removeAttr('hidden');
     }

     widget.$empty.attr('hidden', 'hidden');

     if (count < widget.limit) {
       widget.$more.attr('hidden', 'hidden');
     } else {
       widget.$more.removeAttr('hidden');
     }
     */

    var listItems = data.map(function (recipe) {
      var description = [];
      var timeBadge = "";

      if (macroSplits[recipe.macroSplit]) {
        description.push(macroSplits[recipe.macroSplit]);
      }

      if (types[recipe.type]) {
        description.push(types[recipe.type]);
      }

      if (description.length) {
        description =
          '<span class="recipe-list-text">' + description.join(", ") + "</span>";
      } else {
        description = "";
      }

      if (cookingTimes[recipe.cookingTime]) {
        timeBadge =
          '<div class="recipe-list-badge">' +
          cookingTimes[recipe.cookingTime] +
          window.jsTrans("applyRecipes.min", "plans") +
          "</div>";
      }

      var applyUrl = "/meal/apply-recipe/" + widget.params.plan + "/" + recipe.id;

      if (widget.params.meal) {
        applyUrl += "/" + widget.params.meal;
      }

      return (
        '<div class="recipe-list-item">' +
        '<div class="recipe-list-thumbnail">' +
        '<img src="' +
        (recipe.image || widget.imagePlaceholder) +
        '" alt="' +
        recipe.name +
        '">' +
        "</div>" +
        '<h4 class="recipe-list-heading">' +
        recipe.name +
        description +
        "</h4>" +
        timeBadge +
        '<a class="recipe-list-btn" href="' +
        applyUrl +
        '">' +
        window.jsTrans("applyRecipes.apply", "plans") +
        "</a>" +
        "</div>"
      );
    });

    // widget.$list.html(listItems);
    widget.$list.append(listItems);
  }

  function fetch(page) {
    var queryParams = Object.keys(widget.params).map(function (key) {
      return key + "=" + widget.params[key];
    });

    if (page) {
      queryParams.push("offset=" + page * widget.limit);
    }

    var url = widget.endpoint + "?" + queryParams.join("&");

    widget.$empty.attr("hidden", true);
    widget.$spinner.removeAttr("hidden");
    widget.$more.attr("hidden", true);
    widget.page = page || 0;

    axios
      .get(decodeURI(url))
      .then(function (data) {
        widget.data = page ? widget.data.concat(data) : data;
        fuse.setCollection(widget.data);
        render(data);
      })
      .catch(function () {
        // @TODO show error
      })
      .then(function () {
        widget.$spinner.show();
        widget.$spinner.attr("hidden", true);
        widget.$more.removeAttr("hidden");
      });
  }

  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  function reset() {
    widget.$list.empty();
    widget.data.length = 0;
    widget.page = 0;
    fuse.setCollection(widget.data);
  }

  widget.$modal.on("show.bs.modal", function (e) {
    var $relatedTarget = $(e.relatedTarget);

    try {
      widget.params = JSON.parse(window.atob($relatedTarget.data("params")));
      widget.params.type = "";
    } catch (e) {
      widget.params = {};
    }

    widget.params.user = widget.$ownFilter.prop("checked") ? 1 : 0;
    fetch();
  });

  widget.$modal.on("hidden.bs.modal", function () {
    widget.params = {};
    reset();
  });

  widget.$more.on("click", "button", function (event) {
    event.preventDefault();
    fetch(widget.page + 1);
  });

  widget.$ownFilter.on("change", function () {
    widget.params.user = this.checked ? 1 : 0;
    reset();
    fetch();
  });

  widget.$modal.on(
    "keyup",
    '[name="q"]',
    debounce(function (e) {
      e.preventDefault();

      var keyword = $(this).val();
      var data = keyword ? fuse.search(keyword) : widget.data;

      widget.$list.empty();
      render(data);
    }, 250),
  );
});
