import React, { Dispatch, useState } from "react";
import { WorkoutItemType, WorkoutPlan } from "./types";
import Container from "./BlockContainer";
import { SetStateAction } from "react";
import WorkoutItem from "./Items/WorkoutItem";
import SuperSetWrapper from "./SuperSetWrapper";
import { Stack } from "@mui/material";
import { SortableEvent } from "react-sortablejs";

interface Props {
  block: WorkoutPlan;
  blockIndex: number[];
  setBlocks: Dispatch<SetStateAction<WorkoutPlan[]>>;
  handleSuperSet: (blockId: number) => void;
  canBeSuperset: boolean;
  updateComment: (blockId: number, text: string) => void;
  updateReps: (blockId: number, reps: string) => void;
  updateTime: (blockId: number, time: string) => void;
  updateSets: (blockId: number, sets: string) => void;
  updateRest: (blockId: number, rest: string) => void;
  handleDiscardSuperset: (blockId: number) => void;
  deleteItem: (blockId: number) => void;
  disableSectionDrop: boolean;
  disableSuperSuperDrop: boolean;
  setDisableSuperSuperDrop: Dispatch<boolean>;
  setEditingComment: Dispatch<boolean>;
  editingComment?: boolean;
  reorderNestedList: ({
    blockId,
    order,
    parentId,
  }: {
    blockId: number;
    order: number;
    parentId: number;
  }) => void;
  onCloneNestedWorkout: (item: any, evt: SortableEvent, parentId: number) => void;
}

function BlockWrapper({
  block,
  blockIndex,
  setBlocks,
  handleSuperSet,
  canBeSuperset,
  handleDiscardSuperset,
  updateComment,
  updateReps,
  updateTime,
  updateSets,
  updateRest,
  deleteItem,
  disableSectionDrop,
  disableSuperSuperDrop,
  setDisableSuperSuperDrop,
  reorderNestedList,
  onCloneNestedWorkout,
  setEditingComment,
  editingComment,
}: Props) {
  if (!block) return null;
  if (block.type === WorkoutItemType.SUPER_SET) {
    return (
      <SuperSetWrapper
        id={block.id}
        dayId={block.dayId}
        editingComment={Boolean(editingComment)}
        deleteItem={() => deleteItem(block.id)}>
        <>
          <WorkoutItem
            block={block}
            isSuperSet
            canBeSuperset={false}
            handleSuperSet={handleSuperSet}
            updateComment={updateComment}
            updateReps={updateReps}
            updateTime={updateTime}
            updateSets={updateSets}
            updateRest={updateRest}
            deleteItem={deleteItem}
            setEditingComment={setEditingComment}
          />

          <Container
            block={block}
            setBlocks={setBlocks}
            blockIndex={blockIndex}
            handleSuperSet={handleSuperSet}
            handleDiscardSuperset={handleDiscardSuperset}
            updateComment={updateComment}
            updateReps={updateReps}
            updateTime={updateTime}
            updateSets={updateSets}
            updateRest={updateRest}
            deleteItem={deleteItem}
            disableSectionDrop={disableSectionDrop}
            disableSuperSuperDrop={disableSuperSuperDrop}
            setDisableSuperSuperDrop={setDisableSuperSuperDrop}
            reorderNestedList={reorderNestedList}
            onCloneNestedWorkout={onCloneNestedWorkout}
            setEditingComment={setEditingComment}
          />
        </>
      </SuperSetWrapper>
    );
  } else {
    return (
      <WorkoutItem
        block={block}
        canBeSuperset={canBeSuperset}
        handleSuperSet={handleSuperSet}
        updateComment={updateComment}
        updateReps={updateReps}
        updateTime={updateTime}
        updateSets={updateSets}
        updateRest={updateRest}
        deleteItem={deleteItem}
        setEditingComment={setEditingComment}
      />
    );
  }
}

export default BlockWrapper;
