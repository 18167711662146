import { Locale } from "../interfaces/locale";
import { LocaleEnum } from "../generated/graphql";

export enum Locales {
  da_DK = "helper.const.localeSelect.da_DK",
  de_DE = "helper.const.localeSelect.de_DE",
  en_GB = "helper.const.localeSelect.en_GB",
  en_US = "helper.const.localeSelect.en_US",
  fi_FI = "helper.const.localeSelect.fi_FI",
  nb_NO = "helper.const.localeSelect.nb_NO",
  nl_NL = "helper.const.localeSelect.nl_NL",
  sv_SE = "helper.const.localeSelect.sv_SE",
  es_ES = "helper.const.localeSelect.es_ES",
}

export type LOCALE_TYPE =
  | "en_US"
  | "en_GB"
  | "da_DK"
  | "nb_NO"
  | "sv_SE"
  | "fi_FI"
  | "de_DE"
  | "nl_NL"
  | "es_ES";
export const DEFAULT_LOCALE = "en_US";

export const getLocaleFromKey = (key: string) =>
  key &&
  Object.keys(Locales).filter(lang =>
    lang?.toLowerCase()?.includes(key?.toLowerCase()),
  )[0];

export const LocaleObj: {
  [key: string]: { label: string; value: Locale; icon: string };
} = {
  da_DK: {
    label: "Danish",
    value: Locale.da_DK,
    icon: "/assets/icons/flags/dk.svg",
  },
  de_DE: {
    label: "German",
    value: Locale.de_DE,
    icon: "/assets/icons/flags/de.svg",
  },
  en_GB: {
    label: "English (GB)",
    value: Locale.en_GB,
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },
  en_US: {
    label: "English (US)",
    value: Locale.en_US,
    icon: "/assets/icons/flags/us.svg",
  },
  fi_FI: {
    label: "Finnish",
    value: Locale.fi_FI,
    icon: "/assets/icons/flags/fi.svg",
  },
  nb_NO: {
    label: "Norwegian",
    value: Locale.nb_NO,
    icon: "/assets/icons/flags/no.svg",
  },
  nl_NL: {
    label: "Dutch",
    value: Locale.nl_NL,
    icon: "/assets/icons/flags/nl.svg",
  },
  sv_SE: {
    label: "Swedish",
    value: Locale.sv_SE,
    icon: "/assets/icons/flags/se.svg",
  },
  es_ES: {
    label: "Spanish",
    value: Locale.es_ES,
    icon: "/assets/icons/flags/es.svg",
  },
};

export const LocaleFlagAndName = {
  [LocaleEnum.EnGb]: "🇬🇧 English (GB)",
  [LocaleEnum.EnUs]: "🇺🇸 English (US)",
  [LocaleEnum.DeDe]: "🇩🇪 German",
  [LocaleEnum.DaDk]: "🇩🇰 Danish",
  [LocaleEnum.SvSe]: "🇸🇪 Swedish",
  [LocaleEnum.NlNl]: "🇳🇱 Dutch",
  [LocaleEnum.FiFi]: "🇫🇮 Finnish",
  [LocaleEnum.NbNo]: "🇳🇴 Norwegian",
  [LocaleEnum.EsEs]: "🇪🇸 Spanish",
};
