import { GetStartedData } from "../../../../providers/LenusTransferProvider/hooks/useGetStartedData";
import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import SignupForm from "./SignupForm";
import { Stack } from "@mui/material";
import { LocaleEnum } from "../../../../generated/graphql";
import NavigationSteps from "../NavigationSteps";
import { CountryEnum } from "../../../../interfaces/locale";

export type TLenusSignupFormProps = Pick<GetStartedData, "email" | "name"> & {
  password: string | undefined;
  acceptedTerms: boolean;
  platformOrigin: string;
  amountOfClients: string;
  country: CountryEnum;
  locale: LocaleEnum;
  salesforceId: string | undefined;
  startDate: Date;
  commitment: boolean;
};

export default function LenusSignup() {
  const { getStartedData, loading } = useLenusTransferContext();

  const defaultValues = {
    name: getStartedData.name,
    email: getStartedData.email,
    password: undefined,
    acceptedTerms: false,
    platformOrigin: "lenus",
    amountOfClients: getStartedData.amountOfClients,
    country: getStartedData.country,
    locale: getStartedData.locale,
    salesforceId: getStartedData.salesforceId,
    startDate: getStartedData.startDate,
    commitment: getStartedData.commitment,
  };

  return (
    <>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        width={"100vw"}>
        <SkeletonContainer
          loading={loading}
          sx={{
            width: { xs: "60vw", sm: 400 },
            height: { xs: "65vh", sm: 475 },
            bgcolor: "grey.900",
          }}>
          <SignupForm defaultValues={defaultValues} />
        </SkeletonContainer>
      </Stack>
      <NavigationSteps />
    </>
  );
}
