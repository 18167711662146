import {
  Checkins,
  CLIENT_PROGRESS_FAILURE,
  CLIENT_PROGRESS_REQUEST,
  CLIENT_PROGRESS_RESET,
  CLIENT_PROGRESS_SUCCESS,
  ClientEntryType,
  ClientProgressActionTypes,
  ClientTopProgressState,
  DELETE_CLIENT_PROGRESS_FAILURE,
  DELETE_CLIENT_PROGRESS_REQUEST,
  DELETE_CLIENT_PROGRESS_SUCCESS,
  Metrics,
  UPDATE_CLIENT_PROGRESS_FAILURE,
  UPDATE_CLIENT_PROGRESS_REQUEST,
  UPDATE_CLIENT_PROGRESS_SUCCESS,
  CLIENT_KCALS,
} from "./types";
import { getEntry } from "./selectors";
import { RequestState } from "../../../../../interfaces/requestState";

function getCheckInEntryDeleteType(
  checkins: Checkins,
  entryId: number,
  entryType: ClientEntryType,
) {
  const entry = getEntry(checkins, entryId);
  if (!entry?.date) return checkins;

  switch (entryType) {
    case ClientEntryType.WEIGHT:
      return {
        ...checkins,
        [entry.date]: {
          ...checkins[entry.date],
          weight: null,
          fat: null,
          muscle: null,
        },
      };
    case ClientEntryType.CIRCUMFERENCE:
      return {
        ...checkins,
        [entry.date]: {
          ...checkins[entry.date],
          chest: null,
          waist: null,
          hips: null,
          glutes: null,
          leftArm: null,
          rightArm: null,
          leftThigh: null,
          rightThigh: null,
          leftCalf: null,
          rightCalf: null,
        },
      };

    default:
      return checkins;
  }
}

const INITIAL_STATE: ClientTopProgressState = {
  clientInfo: {
    info: {
      gender: 0,
    },
  },
  metrics: {} as Metrics,
  checkins: {},
  error: null,
  loading: RequestState.PENDING,
  updateLoading: RequestState.INITIAL,
  deleteLoading: RequestState.INITIAL,
  kcals: {},
};

export default function clientProgressReducer(
  state = INITIAL_STATE,
  action: ClientProgressActionTypes,
): ClientTopProgressState {
  switch (action.type) {
    // LOAD
    case CLIENT_PROGRESS_REQUEST: {
      return {
        ...state,
        error: null,
        loading: RequestState.PENDING,
      };
    }

    case CLIENT_PROGRESS_SUCCESS: {
      return {
        ...state,
        metrics: action.payload.metrics,
        checkins: action.payload.checkins,
        loading: RequestState.RESOLVED,
      };
    }
    case CLIENT_PROGRESS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        loading: RequestState.REJECTED,
      };
    }

    // UPDATE
    case UPDATE_CLIENT_PROGRESS_REQUEST: {
      return {
        ...state,
        error: null,
        updateLoading: RequestState.PENDING,
      };
    }
    case UPDATE_CLIENT_PROGRESS_SUCCESS: {
      const date = action.payload.entry.date as string;
      return {
        ...state,
        error: null,
        updateLoading: RequestState.RESOLVED,
        checkins: {
          ...state.checkins,
          [date]: {
            ...state.checkins[date],
            ...action.payload.entry,
          },
        },
      };
    }
    case UPDATE_CLIENT_PROGRESS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        updateLoading: RequestState.REJECTED,
      };
    }
    // DELETE
    case DELETE_CLIENT_PROGRESS_REQUEST: {
      return {
        ...state,
        error: null,
        deleteLoading: RequestState.PENDING,
      };
    }
    case DELETE_CLIENT_PROGRESS_SUCCESS: {
      const entryId = action.payload.entryId;
      const entryType = action.payload.type;

      const updatedCheckins = getCheckInEntryDeleteType(
        state.checkins,
        entryId,
        entryType,
      );
      return {
        ...state,
        error: null,
        deleteLoading: RequestState.RESOLVED,
        checkins: updatedCheckins,
      };
    }
    case DELETE_CLIENT_PROGRESS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        deleteLoading: RequestState.REJECTED,
      };
    }
    case CLIENT_PROGRESS_RESET: {
      return INITIAL_STATE;
    }
    case CLIENT_KCALS.SUCCESS: {
      return {
        ...state,
        kcals: action.payload,
      };
    }
    default:
      return state;
  }
}
