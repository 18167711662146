import { useDropzone } from "react-dropzone";
import {
  FormControl,
  FormHelperText,
  Typography,
  Stack,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { UploadProps } from "./type";
import Image from "../Image";
import Iconify from "../Iconify";
import RejectionFiles from "./RejectionFiles";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

const RootStyle = styled("div")(({ theme }) => ({
  width: 64,
  height: 64,
  margin: "auto",
  "& .wrapper": {
    padding: 0,
  },
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: 8,
  backgroundColor: "#f1f1f1",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.background.neutral,
  backgroundColor: theme.palette.grey[200],
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

export default function UploadSimpleImage(props: UploadProps & { loading?: boolean }) {
  const { error, file, photoText, updatePhotoText, helperText, sx, loading, ...other } =
    props;
  
  const { t } = useTranslation([TranslatorNS.PLANS]);

  const fileUrl =
    typeof file === "string" ? file : file !== undefined ? URL.createObjectURL(file) : "";

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <FormControl error={error}>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: "error.light",
          }),
          ...sx,
        }}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}>
          <input {...getInputProps()} />

          {file && <Image alt="avatar" src={fileUrl} sx={{ zIndex: 8 }} />}
          {loading && (
            <Stack
              position="absolute"
              zIndex={10}
              alignItems="center"
              justifyContent="center"
              sx={{ backgroundColor: "rgba(255,255,255,0.5)" }}>
              <CircularProgress size={24} />
            </Stack>
          )}
          <Tooltip title={t("plan.changeImage")}>
            <PlaceholderStyle
              className="placeholder"
              sx={{
                ...(file && {
                  opacity: 0,
                  color: "common.white",
                  bgcolor: "grey.900",
                  "&:hover": { opacity: 0.7, "& span": { color: "common.white" } },
                }),
                ...((isDragReject || error) && {
                  bgcolor: "error.lighter",
                }),
              }}>
              <Iconify
                icon={"uil:image-upload"}
                sx={{ width: 24, height: 24 }}
                color={"common.white"}
              />
            </PlaceholderStyle>
          </Tooltip>
        </DropZoneStyle>
      </RootStyle>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
