import { LocaleEnum } from "../../generated/graphql";

/*Rest*/
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const FINISH_SIGNING_UP_REQUEST = "FINISH_SIGNING_UP_REQUEST";
export const FINISH_SIGNING_UP_SUCCESS = "FINISH_SIGNING_UP_SUCCESS";
export const FINISH_SIGNING_UP_FAILURE = "FINISH_SIGNING_UP_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_LENUS_SUCCESS = "LOGIN_LENUS_SUCCESS";

/*Function*/
export const CHANGE_MESSAGE = "CHANGE_MESSAGE";
export const CHANGE_LANG = "CHANGE_LANG";
export const CHANGE_ERROR = "CHANGE_ERROR";
export const SET_VIEW = "SET_VIEW";

interface loginAction {
  type: typeof LOGIN_REQUEST | typeof LOGIN_SUCCESS | typeof LOGIN_FAILURE | typeof LOGIN_LENUS_SUCCESS
  payload: string;
}

interface changeMessage {
  type: typeof CHANGE_MESSAGE;
  payload: string;
}
interface changeError {
  type: typeof CHANGE_ERROR;
  payload: string;
}
interface setView {
  type: typeof SET_VIEW;
  payload: string;
}
interface createUserRequest {
  type: typeof CREATE_USER_REQUEST;
}
interface createUserSuccess {
  type: typeof CREATE_USER_SUCCESS;
  payload: string;
}
interface createUserFailure {
  type: typeof CREATE_USER_FAILURE;
  payload: string;
}
interface finishSigninUpRequest {
  type: typeof FINISH_SIGNING_UP_REQUEST;
}
interface finishSigninUpSuccess {
  type: typeof FINISH_SIGNING_UP_SUCCESS;
}
interface finishSigninUpFailure {
  type: typeof FINISH_SIGNING_UP_FAILURE;
  payload: string;
}
interface changeLang {
  type: typeof CHANGE_LANG;
  payload: LocaleEnum;
}

export type AuthActionTypes =
  | changeMessage
  | changeError
  | setView
  | createUserRequest
  | createUserSuccess
  | createUserFailure
  | finishSigninUpRequest
  | finishSigninUpSuccess
  | finishSigninUpFailure
  | loginAction
  | changeLang;
