import React from "react";
import { Colors } from "../../styleguide";
import { UilArrowCircleUp } from "@iconscout/react-unicons";
import { Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";

interface Props {
  title: string;
  subTitle?: string | boolean;
  action: () => void;
}

export default function PromptBox(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  return (
    <Stack>
      <Stack flexDirection={"row"} p={2} pb={3} borderBottom={"1px solid #f3f3f3"}>
        <Stack pr={1}>
          <UilArrowCircleUp size={30} color={Colors.CTA_COLORS_BG} />
        </Stack>
        <Stack>
          <Typography fontSize={12} mb={1} fontWeight={"bold"}>
            {props.title}
          </Typography>
          <Typography fontSize={11}>{props.subTitle}</Typography>
        </Stack>
      </Stack>
      <Stack paddingY={2} flexDirection={"row"} justifyContent={"center"}>
        <Button size={"medium"} variant={"contained"} onClick={props.action}>
          {t("upgrade.upgradePlan")}
        </Button>
      </Stack>
    </Stack>
  );
}
