import {
  SET_CURRENT_CLIENT_REQUEST,
  SET_CURRENT_CLIENT_SUCCESS,
  SET_CURRENT_CLIENT_FAILED,
  currentClientActionTypes,
  CurrentClientState,
  IGNORE_CLIENT_TASK,
  TaskStatus,
  RESOLVE_TASK_REMINDER,
  APPROVE_CLIENT_TASK,
  EXTEND_CLIENT_DURATION,
  ADD_NEW_CLIENT_TASK,
  AddNewTaskRequest,
} from "./types";
import { Dispatch } from "redux";
import axios from "../../helpers/axios";
import {
  ADD_NEW_TASK,
  FETCH_CLIENT,
  FETCH_CLIENT_PAYMENTS_LOG,
  IGNORE_STATUS,
  RESOLVE_REMINDER,
  UPDATE_CLIENT_INFO,
  UPLOAD_CLIENT_IMAGES,
} from "../../api/private/clients";
import clientSchema, { updateClientSchema } from "./schema";
import { AppState } from "..";
import { UPDATE_USER_TAGS } from "../user/reducerTypes";
import { getCurrentAge } from "../../helpers/dates";
import { RELOAD_MEAL_PLAN } from "../../routes/Clients/store/clients/types";

export const setCurrentClient = ({ clientId }: { clientId: number }) => {
  return async (dispatch: Dispatch<currentClientActionTypes>) => {
    try {
      dispatch({ type: SET_CURRENT_CLIENT_REQUEST });
      const { data } = await axios.get(FETCH_CLIENT(clientId));
      const logs = await axios.get(FETCH_CLIENT_PAYMENTS_LOG(), {
        params: { client: clientId },
      });

      dispatch({
        type: SET_CURRENT_CLIENT_SUCCESS,
        payload: clientSchema({ ...data, paymentLogs: logs.data }),
      });
    } catch (error) {
      dispatch({ type: SET_CURRENT_CLIENT_FAILED, payload: error });
      console.error(error);
    }
  };
};

export const updateCurrentClient = (
  updatedValues: Partial<CurrentClientState>,
  clientId: number,
) => {
  return async (dispatch: any, getState: () => AppState) => {
    try {
      if (updatedValues.photo && typeof updatedValues.photo !== "string") {
        let imgs = new FormData();
        imgs.append("profile-img", updatedValues.photo);

        await axios.post(UPLOAD_CLIENT_IMAGES(clientId), imgs);
      }

      const updatedClient = updateClientSchema({
        ...getState().currentClient,
        ...updatedValues,
      });

      await axios.post(UPDATE_CLIENT_INFO(clientId), updatedClient);

      const withAgeAndPal = {
        ...updatedClient,
        age: getCurrentAge(updatedClient.birthday),
        pal: updatedClient.activityLevel,
      };

      dispatch({
        type: SET_CURRENT_CLIENT_SUCCESS,
        payload: withAgeAndPal,
      });

      dispatch({
        type: UPDATE_USER_TAGS,
        payload: {
          tags: updatedClient.tags,
        },
      });

      return 1;
    } catch (e) {
      console.error(e);
      dispatch({ type: SET_CURRENT_CLIENT_FAILED, payload: {} });
      return 0;
    }
  };
};

export const approveClientTaskAction = (id: number) => {
  return (dispatch: Dispatch<AppState>) => {
    dispatch({ type: APPROVE_CLIENT_TASK, payload: { id } });
  };
};

export const ignoreClientTaskAction = (task: TaskStatus) => {
  return (dispatch: Dispatch<AppState>) => {
    axios.post(IGNORE_STATUS(), { id: task.id }).then(res => {
      dispatch({
        type: IGNORE_CLIENT_TASK,
        payload: {
          status: res.data.status,
        },
      });
    });
  };
};

export const ignoreClientTaskActionGraphqlCallback = (
  id: TaskStatus["id"],
  refetch: () => void,
) => {
  return async (dispatch: Dispatch<AppState>) => {
    await axios.post(IGNORE_STATUS(), { id }).then(res => {
      dispatch({
        type: IGNORE_CLIENT_TASK,
        payload: {
          status: res.data.status,
        },
      });
    });
    await refetch();
  };
};

export const resolveTaskReminderAction = (id: number) => {
  const data = new FormData();
  data.append("id", String(id));

  return (dispatch: Dispatch<AppState>) => {
    dispatch({ type: RESOLVE_TASK_REMINDER.REQUEST });
    axios
      .post(RESOLVE_REMINDER(), data)
      .then(() => {
        dispatch({
          type: RESOLVE_TASK_REMINDER.SUCCESS,
          payload: { id },
        });
      })
      .catch(err => {
        dispatch({ type: RESOLVE_TASK_REMINDER.FAILURE, payload: err });
      });
  };
};

export const extendCurrentClientDuration = (clientId: number, endDate: string) => {
  return async (dispatch: Dispatch<AppState>, getState: () => AppState) => {
    try {
      dispatch({ type: EXTEND_CLIENT_DURATION.REQUEST });

      const updatedClient = updateClientSchema({
        ...getState().currentClient,
        endDate,
      });

      const response = await axios.post(UPDATE_CLIENT_INFO(clientId), updatedClient);

      dispatch({
        type: EXTEND_CLIENT_DURATION.SUCCESS,
        payload: response.data.client,
      });
    } catch (error) {
      dispatch({ type: EXTEND_CLIENT_DURATION.FAILURE, payload: error });
    }
  };
};

export const addNewClientTask = (requestPayload: AddNewTaskRequest) => {
  return async (dispatch: Dispatch<AppState>) => {
    try {
      dispatch({ type: ADD_NEW_CLIENT_TASK.REQUEST });

      const response = await axios.post(ADD_NEW_TASK(), requestPayload);

      dispatch({ type: ADD_NEW_CLIENT_TASK.SUCCESS, payload: response.data });
    } catch (err) {
      dispatch({ type: ADD_NEW_CLIENT_TASK.FAILURE, payload: err });
    }
  };
};

export const reloadMealPlan = () => {
  return (dispatch: Dispatch<AppState>) => {
    dispatch({ type: RELOAD_MEAL_PLAN, payload: true });

    setTimeout(() => {
      dispatch({ type: RELOAD_MEAL_PLAN, payload: false });
    }, 500);
  };
};
