import { Client, PlanId, WorkoutPlan } from "../types";
import { InitPlans } from "../vanilla";
import { S3_BEFORE_AFTER_IMAGES, YOUTUBE_API_KEY } from "../../../config";

const initWorkout = (plan: WorkoutPlan, client?: Client) => {
  const initClient = client
    ? {
        id: client.id,
        name: client.name,
        firstName: client.firstname,
      }
    : null;

  const wrapper = document.getElementById(PlanId.WORKOUT);

  if (wrapper !== null) {
    // @ts-ignore
    // this is a class Call from index
    new InitPlans(wrapper, InitPlans.WORKOUT, {
      client: initClient,
      settings: plan.settings,
      updatedAt: plan.lastUpdated,
      plan: plan,
      s3: S3_BEFORE_AFTER_IMAGES,
      youtubeApiKey: YOUTUBE_API_KEY,
      tour: false,
    });
  }
};

export default initWorkout;
