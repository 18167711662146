import React, { useEffect, useState } from "react";
import ZFCheckbox from "../Checkbox";
import TermsAccepted from "./TermsAccepted";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { CTA_COLORS_BG } from "../../styleguide/Colors";
import { GLOBAL_FONT_STYLE } from "../../styleguide/Fonts";
import { DEVICE } from "../../helpers/devices";

interface Props {
  checked: boolean;
  onChange: () => void;
  bounce?: boolean;
  primaryColor?: string;
}

enum AnimationStatus {
  IDLE = "idle",
  READY = "ready",
}

export default function ZFTermsBox(props: Props) {
  const { checked, onChange, bounce = false, primaryColor = CTA_COLORS_BG } = props;

  const [state, setState] = useState(AnimationStatus.IDLE);
  const [changeState, setChangeState] = useState(false);

  const { x } = useSpring({
    from: { x: 0 },
    x: changeState ? 1 : 0,
    config: { duration: 1000 },
  });

  // TODO: useEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useEffect(() => {
    if (state === AnimationStatus.IDLE) {
      setState(AnimationStatus.READY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: useEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useEffect(() => {
    if (state === AnimationStatus.READY) {
      setChangeState(!changeState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounce]);

  return (
    <Styled primaryColor={primaryColor}>
      <animated.div
        className="terms"
        style={{
          transform: x
            .to({
              range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 0.97, 1],
            })
            .to(x => `scale(${x})`),
        }}>
        <ZFCheckbox
          iconSize={20}
          component={TermsAccepted}
          name={"termsAccepted"}
          checked={checked}
          onChange={onChange}
        />
      </animated.div>
    </Styled>
  );
}

const Styled = styled.div<{ primaryColor: string }>`
  .terms {
    display: flex;
    justify-content: center;
    border-radius: 6px;
    padding: 11px 20px 8px;
    background: ${({ primaryColor }) => primaryColor}0d;
    border: 1px solid ${({ primaryColor }) => primaryColor}4d;
    margin: 15px 0 30px;
    .termsTxt {
      font-size: 12px;
      ${GLOBAL_FONT_STYLE};
      a {
        padding: 0 4px;
        color: ${({ primaryColor }) => primaryColor};
      }
    }
  }
  ${DEVICE.mobile} {
    .terms .termsTxt {
      font-size: 11px;
    }
  }
`;
