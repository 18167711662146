import { connect } from "react-redux";
import { Alert, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import moment from "moment";
import useResponsive, {
  BreakPointEnum,
  QueryEnum,
} from "../../../../hooks/useResponsive";
import { CSSProperties } from "react";
import { AlertTitle } from "@mui/material";

interface Props {
  invoiceUrl: string;
  nextPaymentAttempt: string;
  alertSx: CSSProperties;
}

function FailedPaymentAlert(props: Props) {
  const { t } = useTranslation(TranslatorNS.SETTINGS);
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);
  return (
    <Alert
      severity="error"
      variant="outlined"
      sx={props.alertSx}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={() => window.open(props.invoiceUrl)}>
          {t("main.payInvoice")}
        </Button>
      }>
      {isDesktop ? (
        `${t("main.failedPaymentBar")} ${props.nextPaymentAttempt}`
      ) : (
        <AlertTitle sx={{ pt: 0, pb: 0, mt: "3px", mb: 0, fontSize: 10, color: 'white' }}>
          {t("main.actionNeeded")}
        </AlertTitle>
      )}
    </Alert>
  );
}

function mapStateToProps(state: any) {
  return {
    invoiceUrl: state.user.payment?.invoiceUrl,
    nextPaymentAttempt: moment(state.user.payment?.nextPaymentAttempt * 1000).format(
      "DD MMM, YYYY",
    ),
  };
}

export default connect(mapStateToProps)(FailedPaymentAlert);
