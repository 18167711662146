import { useState } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { Avatar, Box, Divider, MenuItem, Stack, Typography } from "@mui/material";
// components
import MenuPopover from "../../../components/_MUI/MenuPopover";
import { IconButtonAnimate } from "../../../components/_MUI/animate";
import { logout } from "../../../api/tokens";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routeMap";
import { CypressTestId } from "../../../helpers/cypressTestIds";
import { useTranslation } from "react-i18next";
// ----------------------------------------------------------------------

export default function AccountPopover({
  name,
  image,
}: {
  name: string;
  image?: string;
}) {
  const {t} = useTranslation("settings");
  let navigate = useNavigate();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const navigateToSettings = () => {
    navigate(Routes.SETTINGS);
    handleClose();
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ml: 2,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: theme => alpha(theme.palette.grey[700], 0.8),
            },
          }),
        }}>
        <Avatar src={image} alt={name} id={"profile-avatar"} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        keepMounted
        sx={{
          p: 0,
          mt: 0.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" data-cy={CypressTestId.USER_HEADER_NAME} noWrap>
            {name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={() => navigateToSettings()}>{t("main.settings")}</MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={() => logout()} sx={{ m: 1 }}>
          {t("userIntro.logout")}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
