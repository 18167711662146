import { ROOT } from "../config";

export const GET_CLIENTS = (q = "", tags = "") => {
  return `${ROOT}/chat/api/clients?q=${q}&tags=${tags}`;
};

export const GET_SIGNED_UPLOAD_URL = (extension: string, contentType: string) => {
  return `${ROOT}/chat/api/get-presigned-request?extension=${extension}&contentType=${contentType}`;
};

export const MESSAGES_RECEIVE = (clientId: number) => {
  return `${ROOT}/chat/api/fetch-messages/${clientId}`;
};

export const MESSAGE_SEND = () => {
  return `${ROOT}/chat/api/send`;
};

export const MULTIPLE_MESSAGE_SEND = () => {
  return `${ROOT}/chat/api/multiple-send`;
};

export const MARK_MESSAGES_READ = (userId: string) => {
  return `${ROOT}/chat/api/mark-messages-read/${userId}`;
};

export const CONVERSATIONS_RECEIVE = (userId: number, q: string, tags: string) => {
  return `${ROOT}/chat/api/fetch-conversations/${userId}?q=${q}&tags=${tags}`;
};

export const CONVERSATIONS_GET = (id: number, q: string) => {
  return `${ROOT}/chat/api/get-conversation/${id}?q=${q}`;
};

export const CONVERSATIONS_MARK_UNREAD = (id: number) => {
  return `${ROOT}/chat/api/mark-unread-conversation/${id}`;
};

export const CONVERSATIONS_MARK_DONE = (id: number) => {
  return `${ROOT}/chat/api/mark-done-conversation/${id}`;
};

export const CONVERSATION_DELETE = () => {
  return `${ROOT}/chat/api/delete-conversation`;
};

export const MESSAGE_DELETE = () => {
  return `${ROOT}/chat/api/delete-message`;
};

export const UNREAD_CLIENT_MESSAGES = (clientId: number) => {
  return `${ROOT}/chat/api/client-unread-messages/${clientId}`;
};
