import React from "react";
import RetryIcon from "../icons/Retry";
import RecordingIcon from "../icons/Recording";
import { animated, useSpring } from "react-spring";
import ZFError from "../../../../../../components/Error";
import { useTranslation } from "react-i18next";
import { MAX_MINUTS, RECORDER_ANIMATION_SPEED } from "../../config";
import { RecorderStatusEnum } from "../../type";

interface Props {
  status: RecorderStatusEnum;
  handleClearVideo: () => void;
  error: string;
}

const VideoHeader = (props: Props) => {
  const { status, handleClearVideo, error } = props;
  const { t } = useTranslation("chat");

  const styles = useSpring({
    loop: true,
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: RECORDER_ANIMATION_SPEED },
  });

  const Title = () => {
    switch (status) {
      case RecorderStatusEnum.NO_PERMISSIONS:
        return t("captureVideo.newPermissions");
      case RecorderStatusEnum.ERROR:
        return t("captureVideo.errorTitle");
      case RecorderStatusEnum.READY_TO_SEND:
        return t("captureVideo.done");
      case RecorderStatusEnum.RECORDING:
        return t("captureVideo.recording");
      default:
        return t("captureVideo.videoMessage");
    }
  };

  const SubTitle = () => {
    switch (status) {
      case RecorderStatusEnum.NO_PERMISSIONS:
        return t("captureVideo.newSubPermissions");
      case RecorderStatusEnum.READY_TO_SEND:
        return (
          <span onClick={handleClearVideo}>
            <RetryIcon text={t("captureVideo.retry")} />
          </span>
        );
      case RecorderStatusEnum.ERROR:
        return "";
      case RecorderStatusEnum.RECORDING:
        return (
          <span
            className={"recording"}
            onClick={() =>
              window.confirm(t("chat.areYouSureDelete")) && handleClearVideo()
            }>
            <animated.div style={styles} className={"recordingIcon"}>
              <RecordingIcon />
            </animated.div>
            {t("captureVideo.cancel")}
          </span>
        );
      default:
        return t("captureVideo.maxMinuts", { min: MAX_MINUTS });
    }
  };

  return (
    <div className="videoHeader">
      <h2>{Title()}</h2>
      <h3>{SubTitle()}</h3>
      <span style={{ maxWidth: "30rem" }}>
        {error.length > 0 && <ZFError msg={error} />}
      </span>
    </div>
  );
};

export default VideoHeader;
