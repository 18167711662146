import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useLocation } from "react-router";
import * as rudderanalytics from "rudder-sdk-js";
import { identify, page, setAnonymousId } from "../helpers/analytics";

declare global {
  interface Window {
    dataLayer: any;
  }
}
const isProduction =
  process.env.NODE_ENV === "production" &&
  window.location.hostname === "app.zenfitapp.com";

const isClientPage = () => window.location.pathname.includes("/client/");
const initHotjar = isProduction && !isClientPage();

export default function useTracking(
  userId?: number,
  userEmail?: string,
  userName?: string,
  anonymousId?: string | null,
) {
  const location = useLocation();

  const initBugPilot = (id: number, email: string) => {
    if ((window as any)?.AdoptoConfig) {
      (window as any).AdoptoConfig = {
        user: {
          id,
          email,
        },
      };
    }
  };

  const initIntercom = (user_id: number, name: string, email: string) => {
    if ((window as any)?.intercomSettings) {
      (window as any).Intercom("boot", {
        app_id: "ugr32o49",
        name,
        user_id,
        email,
        zenfitVersion: "Zenfit 1.0",
      });
    }
  };

  const initRudderStack = () => {
    if (isProduction && rudderanalytics) {
      rudderanalytics?.load(
        String(process.env.REACT_APP_RUDDER_KEY),
        String(process.env.REACT_APP_RUDDER_PLANE),
      );
    } else {
      console.log("I will only track in production");
    }
  };

  useEffect(() => {
    initRudderStack();
    if (initHotjar) {
      hotjar?.initialize(
        Number(process.env.REACT_APP_HOTJAR_ID),
        Number(process.env.REACT_APP_HOTJAR_VERSION),
      );
    }
  }, []);

  useEffect(() => {
    if (isProduction) {
      if (userId && userName && userEmail) {
        identify(`${userId}`, {
          name: userName,
          email: userEmail,
        });
        if (initHotjar) {
          hotjar.identify(`${userId}`, { userProperty: "value" });
        }

        initIntercom(userId, userName, userEmail);
        initBugPilot(userId, userEmail);
      } else if (anonymousId) {
        setAnonymousId(anonymousId);
      }
    }
  }, [userId, userName, userEmail]);

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "pageview1",
        page: {
          path: window.location.pathname,
          title: document.title,
        },
      });
    }
    if (initHotjar) {
      hotjar.stateChange(window.location.pathname);
    }
    page();
  }, [location]);
}
