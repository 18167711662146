import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  ClientPaymentConfigurationType,
  ClientType,
  useGetClientGeneralLazyQuery,
  useGetClientPaymentConfigurationLazyQuery,
} from "../../../generated/graphql";
import axios from "../../../helpers/axios";
import { SET_CLIENT_SETTINGS } from "../../../api/private";
import { format } from "date-fns";
import { getFormDataFromObject } from "../../../helpers/postHelper";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import {
  closeGlobalModalAction,
  openGlobalModalAction,
} from "../../../store/modals/action";
import reduxStore from "../../../store";
import { ModalTypeEnum } from "../../../store/modals/reducerTypes";
import { DefaultMessageTypeEnum } from "../../../api/private/default-messages";
import { extractTranslatedError } from "../../../helpers/error";

export type TNewSubscriptionFormValues = {
  clientId: ClientType["id"];
  locale: ClientType["locale"];
  currency: ClientPaymentConfigurationType["currency"];
  months: ClientPaymentConfigurationType["months"] | undefined;
  upfrontFee: ClientPaymentConfigurationType["upfrontFee"] | undefined;
  recurringFee: ClientPaymentConfigurationType["recurringFee"] | undefined;
  startPaymentDate: Date;
  terms: ClientPaymentConfigurationType["terms"];
  includeTerms: boolean;
  customStartPaymentDate: boolean;
  chargeUpfrontImmediately: boolean;
};

type TSubscriptionResponse = {
  id: number;
  name: string;
  firstName: string;
  email: string;
  trainer: {
    id: number;
    name: string;
  };
  payment: {
    id: number;
    datakey: string;
  };
  queue: {
    id: number;
    payment: boolean;
    datakey: string;
    createdAt: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
  };
};

export default function useCreateSubscription() {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [getClientPaymentConfiguration] = useGetClientPaymentConfigurationLazyQuery();
  const [getClientGeneral] = useGetClientGeneralLazyQuery();

  const createNewSubscription = async (data: TNewSubscriptionFormValues) => {
    try {
      setLoading(true);
      const submitDataFormatted = {
        client: data.clientId,
        periods: data?.months,
        payment: true,
        currency: data.currency,
        ...(Boolean(data.upfrontFee) && {
          signUpFee: data.upfrontFee,
        }),
        ...(Boolean(data.recurringFee) && {
          monthlyAmount: data.recurringFee,
        }),
        chargeUpfrontImmediately: data.chargeUpfrontImmediately,
        ...(data.customStartPaymentDate &&
          data.startPaymentDate && {
            startPaymentDate: format(data.startPaymentDate, "yyyy-MM-dd"),
          }),
        ...(Boolean(data?.includeTerms) && {
          terms: data?.terms,
        }),
      };

      const formData = getFormDataFromObject(submitDataFormatted);

      const { data: subscriptionData } = await axios.post<TSubscriptionResponse>(
        SET_CLIENT_SETTINGS(data.clientId),
        formData,
      );

      await getClientPaymentConfiguration({
        fetchPolicy: "network-only",
        variables: {
          clientId: data.clientId,
        },
      });

      await enqueueSnackbar(
        t("modalSubscription.newSubscription", { ns: TranslatorNS.MODALS }),
        {
          variant: "success",
        },
      );

      await getClientGeneral({
        fetchPolicy: "network-only",
        variables: {
          id: data.clientId,
        },
      });

      reduxStore.dispatch(closeGlobalModalAction(ModalTypeEnum.ClientSubscription));
      reduxStore.dispatch(
        openGlobalModalAction({
          type: ModalTypeEnum.EmailInvitation,
          context: {
            messageType: DefaultMessageTypeEnum.TYPE_PAYMENT_MESSAGE_EMAIL,
            clientId: data.clientId,
            queueId: subscriptionData.queue.id,
            dataKey: subscriptionData.payment.datakey,
          },
        }),
      );
    } catch (e: any) {
      enqueueSnackbar(extractTranslatedError(e, t), {
        variant: "error",
        autoHideDuration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    createNewSubscription,
    loading,
  };
}
