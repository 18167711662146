import { Dispatch, SetStateAction } from "react";
import { ReactSortable, Sortable, SortableEvent } from "react-sortablejs";
import BlockWrapper from "./Block";
import { sortableOptions } from "./sortableConfig";
import "./PlanEditor/style.css";
import { WorkoutItemType, WorkoutPlan } from "./types";

import { getWorkoutDefaultConfig } from "./utils";
import { ExerciseTypeEnum } from "../../generated/graphql";

interface Props {
  block: WorkoutPlan;
  blockIndex: number[];
  setBlocks: Dispatch<SetStateAction<WorkoutPlan[]>>;
  handleSuperSet: (blockId: number) => void;
  handleDiscardSuperset: (blockId: number) => void;
  updateComment: (blockId: number, text: string) => void;
  updateReps: (blockId: number, reps: string) => void;
  updateTime: (blockId: number, time: string) => void;
  updateSets: (blockId: number, sets: string) => void;
  updateRest: (blockId: number, rest: string) => void;
  deleteItem: (blockId: number) => void;
  disableSectionDrop: boolean;
  disableSuperSuperDrop: boolean;
  setDisableSuperSuperDrop: Dispatch<boolean>;
  setEditingComment: Dispatch<boolean>;

  reorderNestedList: ({
    blockId,
    order,
    parentId,
  }: {
    blockId: number;
    order: number;
    parentId: number;
  }) => void;
  onCloneNestedWorkout: (item: any, evt: SortableEvent, parentId: number) => void;
}

// function that recursively finds the block and returns the index
function recursivelyFindIndexes(blockId: number, block: WorkoutPlan[]): number[] {
  const index = block.findIndex(b => b.id === blockId);
  if (index !== -1) {
    return [index];
  }

  const found = block.find(b => b.type === WorkoutItemType.SUPER_SET);
  if (found) {
    return [
      block.indexOf(found),
      ...recursivelyFindIndexes(blockId, found?.children || []),
    ];
  }
  return [];
}

function getIsDisabled({
  blockType,
  disableSectionDrop,
  disableSuperSuperDrop,
}: {
  blockType: WorkoutItemType;
  disableSectionDrop: boolean;
  disableSuperSuperDrop: boolean;
}) {
  if (disableSectionDrop && blockType === WorkoutItemType.SUPER_SET) {
    return true;
  }
  if (disableSuperSuperDrop && blockType === WorkoutItemType.SUPER_SET) {
    return true;
  }
  return false;
}

function Container({
  block,
  blockIndex,
  setBlocks,
  handleSuperSet,
  handleDiscardSuperset,
  updateComment,
  updateReps,
  updateTime,
  updateSets,
  updateRest,
  deleteItem,
  disableSectionDrop,
  disableSuperSuperDrop,
  setDisableSuperSuperDrop,
  reorderNestedList,
  onCloneNestedWorkout,
  setEditingComment,
}: Props) {
  const disabled = getIsDisabled({
    blockType: block.type,
    disableSectionDrop,
    disableSuperSuperDrop,
  });

  return (
    <>
      <ReactSortable
        key={block.id}
        list={block.children as WorkoutPlan[]}
        // TODO: fix add if it comes from left list (add flag or some shit)
        clone={(item, evt) => {
          onCloneNestedWorkout(item, evt, block.id);
          // To keep optimistic updates,
          const payload = getWorkoutDefaultConfig(item?.workoutType?.id);

          return {
            ...item,
            ...payload,
            // to make sure the local state is in sync so we don't get glitchy updates
            exerciseType:
              item.workoutType?.id === 2 ? ExerciseTypeEnum.Time : ExerciseTypeEnum.Reps,
          } as WorkoutPlan;
        }}
        onChange={() => {}}
        onStart={(evt: Sortable.SortableEvent) => {
          /**
           * Checking if the dragged item is a super set
           * if it is set the flag used to disable the drop on the other super sets
           */
          const workout = block.children?.find(i => i.id === Number(evt.item.dataset.id));
          if (workout?.type === WorkoutItemType.SUPER_SET) {
            setDisableSuperSuperDrop(true);
          }
        }}
        onEnd={(evt: SortableEvent, sortable: Sortable | null, store) => {
          setDisableSuperSuperDrop(false);
          if (evt.oldIndex === undefined) {
            console.warn("onEnd.nested - evt.oldIndex is undefined");
            return;
          }
          if (evt.newIndex === undefined) {
            console.warn("onEnd.nested - evt.newIndex is undefined");
            return;
          }
          /**
           * Pull mode prevents multiple re-orders
           * when an item is dragged from one list to another
           * (usually when the item is drag out from a list)
           */
          if (evt.pullMode === true) {
            return;
          }
          const workout = block.children?.find(i => i.id === Number(evt.item.dataset.id));

          console.groupEnd();
          if (!workout) {
            console.error("workout is undefined");
            return;
          }
          /**
           * Skip reorder call if the index didn't change
           */
          if (evt.oldIndex !== evt.newIndex) {
            reorderNestedList({
              blockId: workout.id,
              order: evt.newIndex + 1,
              parentId: block.id,
            });
          }
        }}
        // TODO: there is maybe  some bug here 🥸 (but only when enabling sections again should be checked)
        setList={(currentList, sortable, store) => {
          setBlocks((sourceList: WorkoutPlan[]) => {
            const [first, second] = recursivelyFindIndexes(block.id, sourceList);
            const tempList = [...sourceList];

            if (first && second !== undefined) {
              // @ts-ignore
              tempList[first].children[second].children = currentList;
              return tempList;
            }

            if (first && !second) {
              // @ts-ignore
              tempList[first].children = currentList;
              return tempList;
            }
            /**
             * This might be deprecatable :D(not 100% sure yet)
             */
            const _blockIndex = [...blockIndex];
            const lastIndex = _blockIndex.pop();
            // @ts-ignore
            const lastArr = _blockIndex.reduce((arr, i) => arr[i]?.children, tempList);
            if (!lastArr) {
              return tempList;
            }

            //@ts-ignore
            const parentBlockMovedDown = lastArr[lastIndex]?.id !== block.id;
            //@ts-ignore
            const currentBlockIndex = parentBlockMovedDown
              ? (lastIndex as number) + 1
              : lastIndex;
            //@ts-ignore
            if (!lastArr[currentBlockIndex]) {
              return tempList;
            }
            //@ts-ignore
            lastArr[currentBlockIndex]["children"] = currentList;

            return tempList;
          });
        }}
        {...sortableOptions}
        disabled={disabled}>
        {block.children &&
          block.children?.length > 0 &&
          block.children.map((childBlock: WorkoutPlan, index: number) => {
            return (
              <BlockWrapper
                key={childBlock.id}
                block={childBlock}
                handleDiscardSuperset={handleDiscardSuperset}
                canBeSuperset={block.type !== WorkoutItemType.SUPER_SET}
                handleSuperSet={handleSuperSet}
                blockIndex={[...blockIndex, index]}
                setBlocks={setBlocks}
                updateComment={updateComment}
                updateReps={updateReps}
                updateTime={updateTime}
                updateSets={updateSets}
                updateRest={updateRest}
                deleteItem={deleteItem}
                disableSectionDrop={disableSectionDrop}
                disableSuperSuperDrop={disableSuperSuperDrop}
                setDisableSuperSuperDrop={setDisableSuperSuperDrop}
                reorderNestedList={reorderNestedList}
                onCloneNestedWorkout={onCloneNestedWorkout}
                setEditingComment={setEditingComment}
              />
            );
          })}
      </ReactSortable>
    </>
  );
}

export default Container;
