import useTracking from "./hooks/useTracking";
import { connect } from "react-redux";
import { AppState } from "./store";
import { useSearchParams } from "react-router-dom";

const Tracking = ({
  userId,
  userEmail,
  userName,
}: {
  userId?: number;
  userEmail?: string;
  userName?: string;
}) => {
  const [searchParams] = useSearchParams();
  useTracking(userId, userEmail, userName, searchParams.get("anonymousId"));
  return null;
};

const mapStatesToProps = (state: AppState) => {
  return {
    userId: state.user.id,
    userEmail: state.user.email,
    userName: state.user.name,
  };
};

export default connect(mapStatesToProps)(Tracking);
