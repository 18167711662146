import { ReactNode, useEffect } from "react";
import * as Sentry from "@sentry/react";
import CrashPage from "./CrashPage";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { hashString } from "./util";

/*
 * In development mode, React will rethrow errors caught within an error boundary.
 * This will result in errors being reported twice to Sentry with the above setup,
 * but this won’t occur in the production build.
 */

/*The current commit from git is fetched from index.html and the meta tag */
const currentVersion = (document.querySelector("[name=version]") as any)?.content;
const branchName = (document.querySelector("[name=branch]") as any)?.content;

Sentry.init({
  release: `${branchName}@${currentVersion}`,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
  ],
  tracesSampleRate: process.env.NODE_ENV !== "production" ? 1.0 : 0.2,
  enabled: process.env.NODE_ENV === "production",
  debug: false,
  beforeBreadcrumb(breadCrumb, hint) {
    if (hint?.level === "warn") {
      return null;
    }
    return breadCrumb;
  },
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
});

export default function ErrorProvider(props: { children: ReactNode }) {
  useEffect(() => {
    const date = String(new Date().getTime());
    const username = hashString(date);
    Sentry.setUser({ username });
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={CrashPage}>{props.children}</Sentry.ErrorBoundary>
  );
}
