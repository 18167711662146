import { m } from "framer-motion";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Stack } from "@mui/material";
import { SeverErrorIllustration } from "./Illustration/illustration_500";
import Page from "../../components/_MUI/Page";
import { MotionContainer, varBounce } from "../../components/_MUI/animate";
import { TranslatorNS } from "../../i18n/const";
import { PRIMARY } from "../../theme/palette";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import { hotjar } from "react-hotjar";

const ContentStyle = styled("div")(() => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  padding: 0,
}));

interface Props {
  componentStack: string | null;
  error: any;
  eventId: string | null;
  resetError?: () => void;
}

export default function CrashPage(props: Props) {
  const { resetError } = props;

  // Can't call useTranslation because of hooks order of render
  const t = (key: string) => i18next.t(key, { ns: TranslatorNS.APP });
  const errorCode = Sentry.getCurrentHub().getScope()?.getUser()?.username;
  hotjar?.initialized() && hotjar.event("web:pageCrash");

  return (
    <Page title="Internal frontend error">
      <Container component={MotionContainer}>
        <ContentStyle>
          <m.div variants={varBounce().in}>
            <Typography variant="h2" color={"black"}>
              {t("sentry.woops")}!
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("sentry.tryagain")}.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 5 } }} />
          </m.div>

          <Stack mb={3} mt={-2}>
            <m.div variants={varBounce().in}>
              <Typography sx={{ color: "text.secondary" }}>
                {t("sentry.report")}:{" "}
                <strong style={{ textDecoration: "underline" }}>{errorCode}</strong>
              </Typography>
            </m.div>
          </Stack>

          <Button
            onClick={() => resetError && resetError()}
            sx={{ backgroundColor: PRIMARY.main }}
            size="large"
            variant="contained"
            color={"primary"}>
            {t("sentry.reload")}
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
