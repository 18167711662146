import { TrackedWorkoutsModalContext } from "../../../../store/modals/reducerTypes";
import Dialog from "@mui/material/Dialog";
import { useGetClientTrackedWorkoutsQuery } from "../../../../generated/graphql";
import DialogTitle from "@mui/material/DialogTitle";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import { Skeleton, Stack } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import RHFGraphqlError from "../../../_MUI/hook-form/RHFGraphqlError";
import TrackedWorkouts from "./TrackedWorkouts";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { getHourDurationFromSeconds } from "../../../../helpers/timeHelper";

interface Props {
  readonly savedWorkoutDate: TrackedWorkoutsModalContext["savedWorkoutDate"];
  readonly savedWorkoutId: TrackedWorkoutsModalContext["savedWorkoutId"];
  onClose: () => void;
}

export default function TrackedWorkoutsContainer(props: Props) {
  const { savedWorkoutDate, savedWorkoutId, onClose } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);

  const { data, loading, error } = useGetClientTrackedWorkoutsQuery({
    variables: {
      savedWorkoutId,
    },
  });

  const savedWorkout = data?.savedWorkout;

  const duration = getHourDurationFromSeconds(Number(savedWorkout?.time || 0));

  const exercises = data?.savedWorkout.day.exercises || undefined;

  const renderContent = () => {
    if (error) {
      return <RHFGraphqlError error={error} />;
    }

    if (loading) {
      return (
        <Stack justifyContent={"flex-start"}>
          <Skeleton variant="rectangular" height={270} width={"100%"} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" height={270} width={"100%"} sx={{ mb: 2 }} />
        </Stack>
      );
    }

    if (!exercises) {
      return <Typography>{t("trackedWorkout.noTrackedWorkouts")}</Typography>;
    }

    return (
      <TrackedWorkouts trackedWorkouts={exercises} savedWorkoutDate={savedWorkoutDate} />
    );
  };

  return (
    <Dialog
      maxWidth={"xl"}
      sx={{
        ".MuiPaper-root": {
          width: { xs: "100vw", sm: "50vw" },
          maxWidth: "600px!important",
        },
      }}
      open={true}
      onClose={onClose}>
      <DialogTitle>
        <SkeletonContainer loading={loading} height={30} width={"100%"}>
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Stack>{savedWorkout?.day.name}</Stack>
            <Stack>
              <Typography color={"text.secondary"} fontSize={18}>
                {format(new Date(savedWorkoutDate), "EEEE")}
                &nbsp;
                {format(new Date(savedWorkoutDate), "(dd/MM/yyyy)")}
              </Typography>
            </Stack>
          </Stack>
        </SkeletonContainer>
      </DialogTitle>
      <DialogContent sx={{ mt: 3, minHeight: "100%" }}>
        <SkeletonContainer loading={loading} height={50} width={"100%"}>
          <Stack>
            <Typography>Duration: {duration}</Typography>
            <Typography color={"text.secondary"} sx={{ mt: 1 }}>
              {t("trackedWorkout.clientFeedback", {
                feedback: savedWorkout?.comment || t("trackedWorkout.none"),
              })}
            </Typography>
          </Stack>
        </SkeletonContainer>
        <Stack mt={3}>{renderContent()}</Stack>
      </DialogContent>
    </Dialog>
  );
}
