import { ClientFoodPreferencesType } from "../generated/graphql";

export const getAllergies = (
  clientFoodPreference?: ClientFoodPreferencesType | null | any,
): string[] => {
  return clientFoodPreference
    ? Object.entries(clientFoodPreference)
        .filter(
          ([k, v]) =>
            !DIET_PREFERENCES_ARR.includes(k) &&
            !EATING_DISORDERS.includes(k) &&
            v &&
            k !== "__typename",
        )
        .map(([k]) => k)
    : [];
};

export const getDietaryPreference = (
  clientFoodPreference: ClientFoodPreferencesType | null | any,
): string => {
  return clientFoodPreference
    ? Object.entries(clientFoodPreference)
        .filter(([k, v]) => DIET_PREFERENCES_ARR.includes(k) && v)
        .map(([k]) => k)?.[0] || "none"
    : "none";
};

export const getEatingDisorders = (
  clientFoodPreference: ClientFoodPreferencesType | null | any,
): Pick<
  ClientFoodPreferencesType,
  "foodDominates" | "lostControl" | "significantLoss" | "feelsFat" | "uncomfortablyFull"
> | null => {
  if (!clientFoodPreference) return null;
  const { foodDominates, significantLoss, feelsFat, lostControl, uncomfortablyFull } =
    clientFoodPreference;

  return {
    foodDominates,
    uncomfortablyFull,
    lostControl,
    feelsFat,
    significantLoss,
  };
};

export const DIET_PREFERENCES_ARR = ["isPescetarian", "isVegetarian", "isVegan", "none"];
export const EATING_DISORDERS = [
  "foodDominates",
  "lostControl",
  "significantLoss",
  "feelsFat",
  "uncomfortablyFull",
];
