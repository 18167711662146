import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Title = ({
  title,
  subTitle,
  bigSubtitle,
}: {
  title: string;
  subTitle: string;
  bigSubtitle: boolean;
}) => {
  return (
    <Stack mb={4} mt={2} justifyContent={"center"} alignItems={"center"}>
      <Typography fontSize={34} textAlign={"center"} variant={"h1"} fontWeight={"bold"}>
        {title}
      </Typography>
      <Typography
        marginY={1}
        textAlign={"center"}
        fontWeight={"500"}
        fontSize={{ xs: bigSubtitle ? 18 : 16, sm: bigSubtitle ? 24 : 16 }}
        variant={"subtitle1"}>
        {subTitle}
      </Typography>
    </Stack>
  );
};

export default Title;
