import { PaymentTypeEnum } from "../../../../generated/graphql";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { alpha } from "@mui/material/styles";
import Label from "../../../../components/_MUI/Label";
import { useGetPaymentTypeColorTitle } from "../hooks/useGetPaymentTypeColorTitle";
import { UpcomingPaymentType } from "../index";
import { formatDate } from "../../../../helpers/dates";
import { FLEX_AMOUNT_STATUS, FLEX_DATE } from "./index";

interface Props {
  upcomingPayment: UpcomingPaymentType;
}

export default function UpcomingPaymentItem(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { upcomingPayment } = props;

  const { color, title } = useGetPaymentTypeColorTitle(
    PaymentTypeEnum.SubscriptionPaymentWaiting,
  );

  return (
    <Stack flexDirection={"row"} borderRadius={1} p={1}>
      <Stack flex={FLEX_DATE}>
        {t("newClientOverview.nextPayment")} (
        {formatDate(upcomingPayment.date, "MMM dd, yyyy")})
      </Stack>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        flex={FLEX_AMOUNT_STATUS}
        px={3}>
        <Stack flexDirection={"row"}>
          <Typography sx={{ mx: 0.5 }}>
            {upcomingPayment.currency.toUpperCase()}
          </Typography>
          <Typography fontWeight={500}>{upcomingPayment.amount}</Typography>
        </Stack>
        <Label
          variant={"filled"}
          sx={{ width: 80, background: alpha(color, 0.16), color }}>
          {title}
        </Label>
      </Stack>
    </Stack>
  );
}
