import mappet from "mappet";
import { prepareRoute } from "../../../helpers/routing";
import { Routes } from "../../routeMap";
import { GENDER, LEVELS, LOCATION } from "../const";
import i18next from "i18next";
import { TranslatorNS } from "../../../i18n/const";

const t = (key: string) => i18next.t(key, { ns: TranslatorNS.LISTS });

const getUrl = (_: null, source: any) => {
  const templateId = source.id;
  return prepareRoute(Routes.WORKOUTS_TEMPLATES_EDITOR, { templateId });
};

const getLevelString = (_: null, { meta }: any) => t(LEVELS[meta.level] || "-");

const getLocationString = (_: null, { meta }: any) => t(LOCATION[meta.location] || "-");

const getGenderString = (_: null, { meta }: any) => t(GENDER[meta.gender] || "-");

const getSchema = {
  id: "id",
  url: { path: "", modifier: getUrl },
  name: "name",
  explanation: "explanation",
  workoutsPerWeek: {
    path: "meta.workoutsPerWeek",
    modifier: (string: any) => string || "-",
  },
  level: "meta.level",
  location: "meta.location",
  gender: "meta.gender",
  levelString: { path: "", modifier: getLevelString },
  locationString: { path: "", modifier: getLocationString },
  genderString: { path: "", modifier: getGenderString },
};

const mapper = mappet(getSchema);

export function workoutTemplateSchema(source: any) {
  return mapper(source);
}

const updateSchema = {
  id: "id",
  url: { path: "", modifier: getUrl },
  name: "name",
  explanation: "explanation",
  workoutsPerWeek: "workoutsPerWeek",
  level: "level",
  location: "location",
  gender: "gender",
  levelString: { path: "level", modifier: (id: number) => t(LEVELS[id]) },
  locationString: { path: "location", modifier: (id: number) => t(LOCATION[id]) },
  genderString: { path: "gender", modifier: (gender: number) => t(GENDER[gender]) },
};

const updateMapper = mappet(updateSchema);

export function updateWorkoutTemplateSchema(source: any) {
  return updateMapper(source);
}
