import { Button, Stack, Typography } from "@mui/material";
import Iconify from "../../../../components/_MUI/Iconify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { prepareRoute } from "../../../../helpers/routing";
import { Routes } from "../../../routeMap";
import { useParams } from "react-router-dom";
import { ClientType } from "../../../../generated/graphql";
import { TranslatorNS } from "../../../../i18n/const";

export default function PendingWorkoutPlan({ name }: { name?: ClientType["name"] }) {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const navigate = useNavigate();

  return (
    <Stack height={"100%"}>
      <Stack justifyContent={"center"} textAlign={"center"} height={"100%"} pb={5}>
        <Stack flexDirection={"row"} justifyContent={"center"}>
          <Iconify icon="uil:dumbbell" color={"info.main"} width={50} height={50} />
        </Stack>
        <Stack mt={5} maxWidth={300} mx={"auto"}>
          <Typography mb={1} variant={"h6"}>
            {t("createModal.noWorkouts", { ns: TranslatorNS.WORKOUT_PLAN })}
          </Typography>
          <Typography color={"text.secondary"}>
            {t("workouts.const.emptyState.subTitle", { name, ns: TranslatorNS.LISTS })}
          </Typography>
        </Stack>
        <Stack mt={5} alignItems={"center"} justifyContent={"center"}>
          <Button
            onClick={() =>
              navigate(prepareRoute(Routes.CLIENT_WORKOUT_PLAN, { clientId }))
            }
            variant={"contained"}>
            {t("plan.createWorkoutPlan", { ns: TranslatorNS.WORKOUT_PLAN })}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
