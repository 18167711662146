import React, { Fragment } from "react";
import { connect } from "react-redux";
import AddDay from "./workout/AddDay";
import { PlannerState } from "../../store/reducer";
import { Client, MealPlan, PlanId, RecipePlan, WorkoutPlan } from "../../types";
import CloneDay from "./workout/CloneDay";
import SaveAsPdf from "./shared/SaveAsPdf";
import EditWorkout from "./workout/EditWorkout";
import SaveAsTemplate from "./workout/SaveAsTemplate";
import ApplyTemplate from "./workout/ApplyTemplate";
import ExerciseModal from "./workout/ExerciseModal";
import ConfirmDelete from "./shared/ConfirmDelete";
import YoutubeExerciseModal from "./workout/YoutubeExerciseModal";
import EditMeal from "./meal/EditMeal";
import MealApplyTemplate from "./meal/MealApplyTemplate";
import EditMealTitle from "./meal/EditMealTitle";
import CloneMealPlan from "./meal/CloneMealPlan";
import MealSaveAsTemplate from "./meal/SaveAsTemplate";
import ApplyRecipes from "./meal/ApplyRecipes";
import RecipeModal from "./recipe/RecipeModal";
import { BootstrapStyled } from "./BootstrapStyled";

interface Props {
  workoutPlanState?: WorkoutPlan;
  mealPlanState?: MealPlan;
  idState: PlanId;
  clientState?: Client;
  recipeState?: RecipePlan;
  refetchFn: () => void;
}

function TwigModals(props: Props) {
  const {
    workoutPlanState,
    mealPlanState,
    idState,
    clientState,
    recipeState,
    refetchFn,
  } = props;

  return (
    <BootstrapStyled>
      {idState === PlanId.WORKOUT && workoutPlanState && (
        <Fragment>
          <AddDay
            workoutId={workoutPlanState.id}
            dayId={workoutPlanState.dayId}
            name={workoutPlanState.dayName}
          />
          <CloneDay workoutId={workoutPlanState.id} />
          <EditWorkout
            plan={workoutPlanState}
            client={clientState}
            refetchFn={refetchFn}
          />
          <SaveAsTemplate plan={workoutPlanState} client={clientState} />
          <ApplyTemplate
            plan={workoutPlanState}
            client={clientState}
            refetchFn={refetchFn}
          />
          <ExerciseModal />
          <ConfirmDelete />
          <YoutubeExerciseModal />
          <SaveAsPdf plan={workoutPlanState} id={idState} />
        </Fragment>
      )}

      {idState === PlanId.MEAL && mealPlanState && (
        <Fragment>
          <EditMeal plan={mealPlanState} client={clientState} />
          <MealApplyTemplate plan={mealPlanState} client={clientState} />
          <EditMealTitle plan={mealPlanState} />
          <CloneMealPlan plan={mealPlanState} />
          <MealSaveAsTemplate plan={mealPlanState} />
          <ApplyRecipes />
          <SaveAsPdf plan={mealPlanState} id={idState} />
        </Fragment>
      )}

      <RecipeModal plan={recipeState} refetchFn={refetchFn} />
    </BootstrapStyled>
  );
}

function mapStateToProps({ plan }: { plan: PlannerState }) {
  return {
    workoutPlanState: plan.workoutPlan,
    mealPlanState: plan.mealPlan,
    recipeState: plan.recipePlan,
    idState: plan.type,
    clientState: plan.client,
  };
}

export default connect(mapStateToProps, {})(TwigModals);
