export enum ModalActionType {
  EMPTY = "",
  CREATE = "create",
  DUPLICATE = "duplicate",
  EDIT = "edit",
}

export interface initialModal {
  active: boolean;
  type: ModalActionType;
  headTitle: string;
  content: string;
}
