import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import {
  GetSubscriptionPlansQuery,
  useGetSubscriptionPlansLazyQuery,
} from "../../../../generated/graphql";
import { Grid, Stack, Typography } from "@mui/material";
import { getSubscriptionPlanFeatures } from "./getSubscriptionPlanFeatures";
import Iconify from "../../../../components/_MUI/Iconify";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import StripeRedirect from "./StripeRedirect";
import RHFGraphqlError from "../../../../components/_MUI/hook-form/RHFGraphqlError";
import { formatDate } from "../../../../helpers/dates";
import { addDays, startOfDay } from "date-fns";
import NavigationSteps from "../NavigationSteps";

export default function LenusSubscriptionPlans() {
  const [subscriptionId, setSubscriptionId] = useState<number | undefined>();
  const [redirecting, setRedirecting] = useState(false);
  const { t } = useTranslation(TranslatorNS.AUTH);

  const { getStartedData, loading } = useLenusTransferContext();
  const [getSubscriptions, { data, loading: subLoading, error }] =
    useGetSubscriptionPlansLazyQuery({
      variables: {
        filters: {
          currency: getStartedData.currency,
        },
        pagination: {
          page: 1,
          limit: 30,
        },
      },
    });

  useEffect(() => {
    if (!loading) {
      getSubscriptions();
    }
  }, [loading]);

  const trial_end = addDays(startOfDay(getStartedData.startDate), 31);
  const commitmentPeriod = 6;
  const daysFree = 30;

  if (redirecting && subscriptionId) {
    return <StripeRedirect subscriptionId={subscriptionId} />;
  }

  if (error) {
    return <RHFGraphqlError error={error} />;
  }

  return (
    <>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <Stack
          sx={{
            textAlign: "center",
            mb: 1,
            color: "white",
            maxWidth: 560,
          }}>
          <Typography color={"white"} fontSize={{ sm: "32px", xs: "28px" }}>
            {t("transfer.whichPlan")}
          </Typography>
          <Typography color={"white"}>
            {t("transfer.subscriptionWillBegin", {
              date: formatDate(getStartedData.startDate, "do MMMM yyyy"),
            })}{" "}
            {getStartedData.commitment &&
              t("transfer.commitmentPeriod", { months: commitmentPeriod })}{" "}
            {t("transfer.weWillGiveDays", {
              days: daysFree,
              date: formatDate(trial_end, "do MMMM yyyy"),
            })}
          </Typography>
        </Stack>
        <Stack mt={2} pb={{ xs: 10, sm: 0 }}>
          <SkeletonContainer
            loading={loading || subLoading}
            sx={{
              width: { xs: 560 },
              height: { xs: "80vh", sm: 445 },
              bgcolor: "grey.800",
            }}>
            <SubscriptionPlansCards
              plans={data?.plans?.data ?? []}
              subscriptionId={subscriptionId}
              handleSelect={setSubscriptionId}
            />
          </SkeletonContainer>
        </Stack>
        <Stack
          width={"100%"}
          position={"absolute"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          bottom={20}
          px={4}>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}>
            <Typography color={"white"} mr={2}>
              {t("transfer.nextCheckout")}
            </Typography>
            <LoadingButton
              type={"submit"}
              size={"large"}
              loading={loading}
              disabled={!subscriptionId}
              onClick={() => setRedirecting(true)}
              variant={"contained"}>
              {t("transfer.next")}
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
      <NavigationSteps />
    </>
  );
}

const SubscriptionPlansCards = ({
  plans,
  subscriptionId,
  handleSelect,
}: {
  plans: GetSubscriptionPlansQuery["plans"]["data"];
  handleSelect: (subscriptionId: number) => void;
  subscriptionId: number | undefined;
}) => {
  const { t } = useTranslation(TranslatorNS.SETTINGS);
  return (
    <Grid container spacing={{ xs: 6, sm: 2 }} flexDirection={"row"}>
      {plans.map((plan, i: number) => {
        const { image, features } = getSubscriptionPlanFeatures(i);
        const width = 12 / plans?.length;
        return (
          <Grid
            item
            xs={12}
            sm={width}
            key={plan.id}
            width={320}
            onClick={() => handleSelect(plan.id)}>
            <Stack
              padding={0.75}
              bgcolor={"white"}
              borderRadius={1}
              sx={{
                transition: "all .2s ease-in-out",
                "&:hover": { transform: "scale(1.05)" },
                cursor: "pointer",
                position: "relative",
              }}>
              <Stack
                sx={{
                  background: `url("${image}")`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                width={"100%"}
                borderRadius={1}
                px={1.5}
                pt={2}
                pb={1}
                justifyContent={"space-between"}
                height={{ xs: "100%", sm: 160 }}>
                <Stack>
                  <Typography color={"white"} variant={"h6"}>
                    {plan.name}
                  </Typography>
                  <Typography color={"white"}>
                    {t("transfer.includeUpTo", {
                      ns: TranslatorNS.AUTH,
                      clients: plan?.clientCount || 0,
                    })}
                  </Typography>
                </Stack>
                <Stack flexDirection={"row"} alignItems={"center"}>
                  <Typography
                    color={"white"}
                    variant={"h3"}
                    textTransform={
                      "uppercase"
                    }>{`${plan.currency}${plan.priceMonth}`}</Typography>
                  <Typography color={"white"}>
                    /{t("transfer.month", { ns: TranslatorNS.AUTH })}
                  </Typography>
                </Stack>
              </Stack>
              <Stack px={1} py={2.5}>
                {Object.values(features).map(feature => {
                  return (
                    <Stack
                      key={feature}
                      flexDirection={"row"}
                      mb={2}
                      alignItems={"center"}
                      justifyContent={"flex-start"}>
                      <Stack width={16} height={16} mr={1}>
                        <Iconify
                          color={"primary.dark"}
                          icon={"lucide:check-circle"}
                          height={16}
                          width={16}
                        />
                      </Stack>
                      <Typography sx={{ whiteSpace: "nowrap" }}>{t(feature)}</Typography>
                    </Stack>
                  );
                })}
              </Stack>
              {subscriptionId === plan.id && (
                <Stack
                  bottom={-26}
                  left={0}
                  right={0}
                  position={"absolute"}
                  flexDirection={"row"}
                  justifyContent={"center"}>
                  <Stack padding={0.75} bgcolor={"#151515"} borderRadius={50}>
                    <Iconify
                      sx={{ bgcolor: "primary.main", borderRadius: 50 }}
                      color={"white"}
                      icon={"uil:check"}
                      width={38}
                      height={38}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Grid>
        );
      })}
    </Grid>
  );
};
