import { ModalActionType } from "./types";

export const GetActionType = (type: string) =>
  type === ModalActionType.CREATE
    ? "shared.helpers.create"
    : type === ModalActionType.DUPLICATE
    ? "shared.helpers.duplicate"
    : "shared.helpers.update";

export const InitialModalShow = {
  active: false,
  type: ModalActionType.EMPTY,
  headTitle: "",
  content: "",
};
export const OnlyAssignToAll = (
  val: { label: string; value: string }[],
  key: string,
  t: any,
) => {
  let error = {};
  if (val) {
    const notValid =
      Object.values(val).some(item => item.value.includes("all")) &&
      Object.values(val).some(item => !item.value.includes("all"));
    //@ts-ignore
    notValid && (error[key] = t("helpers.cantAssign"));
    if (Object.keys(error).length > 0) {
      throw error;
    }
  }
};

export const LIST_TITLE_LIMIT = 80;
export const LIST_BODY_LIMIT = 250;
