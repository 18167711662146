import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { AddClientStep } from "./types";
import { AddClientStepNumber } from "./help-const";
import { TranslatorNS } from "../../../i18n/const";

interface Props {
  title: string;
  body: JSX.Element;
  submitButton: JSX.Element;
  currentModalStep: AddClientStep;
  hiddenSteps?: AddClientStep[];
  onClose: () => void;
  description?: string;
  onStepClick?: Dispatch<SetStateAction<AddClientStep>>;
}

const steps = [
  {
    stepKey: AddClientStep.CLIENT,
    title: "addClient.breadcrumb.create",
  },
  {
    stepKey: AddClientStep.ADDITIONAL_OPTIONS,
    title: "addClient.breadcrumb.options",
  },
  {
    stepKey: AddClientStep.SUBSCRIPTION,
    title: "addClient.breadcrumb.subscription",
  },
  {
    stepKey: AddClientStep.QUESTIONNAIRE,
    title: "addClient.breadcrumb.questionnaire",
  },
];

const BreadcrumbItem = ({
  title,
  isActive,
  onClick,
}: {
  title: string;
  isActive: boolean;
  onClick: (() => void) | null;
}) => {
  return (
    <div onClick={!!onClick ? onClick : () => {}}>
      <Typography
        fontSize={12}
        color={isActive ? "primary" : "gray"}
        sx={{
          cursor: !!onClick ? "pointer" : "auto",
          "&:hover": {
            color: theme => (!!onClick || isActive ? theme.palette.primary.main : "gray"),
          },
        }}>
        {title}
      </Typography>
    </div>
  );
};

const AddClientModalBodyPlaceholder = ({
  title,
  description,
  body,
  submitButton,
  currentModalStep,
  hiddenSteps = [],
  onClose,
  onStepClick,
}: Props) => {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  return (
    <>
      <DialogTitle>{t(title)}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        {description && <DialogContentText>{t(description)}</DialogContentText>}
      </DialogContent>
      <DialogContent sx={{ pt: { xs: 1, sm: 1 }, pb: { xs: 1, sm: 5 } }}>
        {body}
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          justifyContent: "space-between",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}>
        <Breadcrumbs separator={<NavigateNextIcon style={{ fontSize: 16 }} />}>
          {steps
            .filter(e => !hiddenSteps.includes(e.stepKey))
            .map(({ stepKey, title }) => {
              const canClick =
                AddClientStepNumber[currentModalStep] > AddClientStepNumber[stepKey];
              return (
                <BreadcrumbItem
                  key={stepKey}
                  isActive={currentModalStep === stepKey}
                  title={t(title)}
                  onClick={canClick && !!onStepClick ? () => onStepClick(stepKey) : null}
                />
              );
            })}
        </Breadcrumbs>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
          sx={{ width: { xs: "100%", sm: "auto" }, mt: { xs: 2, sm: 0 } }}>
          <Button
            sx={{ width: { xs: "100%", sm: "auto" } }}
            variant="outlined"
            color="inherit"
            onClick={onClose}>
            {t("addClient.closeClient")}
          </Button>
          {submitButton}
        </Stack>
      </DialogActions>
    </>
  );
};

export default AddClientModalBodyPlaceholder;
