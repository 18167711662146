import React, { ElementType, Suspense } from "react";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../components/_MUI/LoadingScreen";

export const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}>
      <Component {...props} />
    </Suspense>
  );
};
