import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  label: string;
  answeredQuestionnaire: boolean;
  name: string;
  helperText: string;
  value: any;
  handleChange: (name: string, value: boolean) => void;
}

const EatingDisorder = (props: Props) => {
  const { t } = useTranslation(TranslatorNS.MESSAGES);
  const { label, name, value, answeredQuestionnaire, handleChange, helperText } = props;
  const formattedValue =
    value === undefined && !answeredQuestionnaire ? null : value ? "yes" : "no";

  return (
    <FormControl id={`zf_${name}`} sx={{ my: 3 }} error={Boolean(helperText)}>
      <FormLabel sx={{ fontWeight: "600" }}>{t(label)}</FormLabel>
      <RadioGroup
        name={name}
        value={formattedValue}
        onClick={({ target }: any) => {
          const value = target.value === "yes";
          handleChange(name, value);
        }}>
        <Stack flexDirection={"row"} justifyContent={"flex-start"} mb={1}>
          <FormControlLabel value={"no"} control={<Radio />} label={t("general.no")} />
          <FormControlLabel value={"yes"} control={<Radio />} label={t("general.yes")} />
        </Stack>
      </RadioGroup>
      <FormHelperText sx={{ m: 0 }}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default EatingDisorder;
