import { VersionEnum } from "../generated/graphql";

export const getVersionFormattedToSchema = (
  version: -1 | 0 | 1 | undefined,
): VersionEnum => {
  switch (version) {
    case -1:
      return VersionEnum.Legacy;
    case 1:
      return VersionEnum.Experimental;
    default:
      return VersionEnum.Stable;
  }
};
