import * as React from "react";

const LoadingIcon = () => (
  <svg height={20} width={21} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.597 13.016a1 1 0 0 0-1.366-.366l-1.733 1a1 1 0 0 0 1 1.732l1.733-1a1 1 0 0 0 .366-1.366zM2.498 6.382l1.733 1a1 1 0 1 0 1-1.732l-1.733-1a1 1 0 0 0-1 1.732zm2.295 3.634a1 1 0 0 0-1-1h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 1-1zm11.195-3a1 1 0 0 0 1.367.366l1.732-1a1 1 0 1 0-1-1.732l-1.732 1a1 1 0 0 0-.367 1.366zM13.793 4.82a1 1 0 0 0 1.366-.366l1-1.733a1 1 0 1 0-1.733-1l-1 1.733a1 1 0 0 0 .367 1.366zm5.294 8.83-1.732-1a1 1 0 1 0-1 1.732l1.732 1a1 1 0 0 0 1-1.732zm-3.928 1.928a1 1 0 1 0-1.733 1l1 1.732a1 1 0 1 0 1.733-1zm4.634-6.562h-2a1 1 0 1 0 0 2h2a1 1 0 0 0 0-2zm-9 7a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1zm-3-.804a1 1 0 0 0-1.367.366l-1 1.732a1 1 0 0 0 1.733 1l1-1.732a1 1 0 0 0-.366-1.366zm3-15.196a1 1 0 0 0-1 1v2a1 1 0 1 0 2 0v-2a1 1 0 0 0-1-1z"
      fill="#fff"
    />
  </svg>
)

export default LoadingIcon
