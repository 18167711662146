import { useState } from "react";
import { ClientPaymentType, PaymentTypeEnum } from "../../../../generated/graphql";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { REFUND_CLIENT } from "../../../../api/private/clients";

interface Props {
  clientId: number;
  chargeId: ClientPaymentType["chargeId"];
  payment: ClientPaymentType;
}

export default function useRefundAction(props: Props) {
  const { clientId, chargeId, payment } = props;
  const [loading, setLoading] = useState(false);
  const { cache } = useApolloClient();

  const ApolloUpdateClientConfigStatus = () => {
    if (payment) {
      cache.modify({
        id: cache.identify(payment),
        fields: {
          type() {
            return PaymentTypeEnum.ChargeRefunded;
          },
        },
      });
    }
  };

  const refundClient = async () => {
    try {
      setLoading(true);
      await ApolloUpdateClientConfigStatus();
      let bodyData = new FormData();
      bodyData.append("client", String(clientId));
      bodyData.append("chargeId", String(chargeId));
      await axios.post(REFUND_CLIENT(), bodyData);
    } catch (e) {
      console.error({ e });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const {} = props;

  return {
    loading,
    refundClient,
  };
}
