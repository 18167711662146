import axios from "axios";
import { ROOT } from "../api/config";
import {
  getToken,
  removeTokenLocalStorage,
  setRefreshToken,
  setToken,
  Tokens,
} from "../api/tokens";
import { CLIENT_LOGIN } from "../api/private/clients";
import { Routes } from "../routes/routeMap";
import { UserPrivateApi, ClientApi, DefaultApi } from "../sdk";
/**
 * keeps track of failed requests to retry when the token is refreshed
 */
let failedQueue: any[] = [];
let isRefreshing = false;

function getAuthHeaderSignature(token: string) {
  return token ? `BEARER ${token}` : "";
}

const processQueue = (error: any, token = null) => {
  failedQueue.forEach(prom => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });

  failedQueue = [];
};

enum ResponseCodeEnum {
  DEACTIVATED = 402,
  ACCESS_DENIED = 401,
}

// TODO: make this default export instead axios at bottom of file when all endpoints has been replaced
export const userPrivateApi = new UserPrivateApi(undefined, ROOT);
export const clientPrivateApi = new ClientApi(undefined, ROOT);
export const defaultApi = new DefaultApi(undefined, ROOT);

// TODO: consider handling 403 if those statuses are used in the app
export const initializeHttp = () => {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: getAuthHeaderSignature(getToken(Tokens.BEARER_TOKEN) as string) || "",
  };
  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error?.config;

      /** To handle when a request has been cancelled and there are no config **/
      if (originalRequest === undefined) {
        return;
      }

      /**
       * Redirect to sign out when getting 401 from refresh token is invalid
       */
      const isRefreshTokenUrl = originalRequest?.url?.includes("token/refresh");

      if (
        isRefreshTokenUrl &&
        error.response?.status === ResponseCodeEnum.ACCESS_DENIED
      ) {
        removeTokenLocalStorage(Tokens.BEARER_TOKEN);
        removeTokenLocalStorage(Tokens.REFRESH_TOKEN);
        window.location.replace(Routes.LOGIN_ROUTE);
      }
      if (
        error.response?.status === ResponseCodeEnum.ACCESS_DENIED &&
        !originalRequest._retry &&
        // skip comes from sign in
        !(
          isRefreshTokenUrl ||
          originalRequest.url.includes("/login_check") ||
          originalRequest.url.includes(CLIENT_LOGIN())
        )
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers["Authorization"] = getAuthHeaderSignature(
                token as string,
              );
              return axios(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        /**
         * Update refresh token and retry requests
         */
        return new Promise(function (resolve, reject) {
          axios
            .post(`${ROOT}/token/refresh`, {
              refresh_token: getToken(Tokens.REFRESH_TOKEN),
            })
            .then(({ data }) => {
              const { token, refresh_token } = data;
              // update tokens in localstorage
              if (token && refresh_token) setToken(token);
              setRefreshToken(refresh_token);
              const authHeader = getAuthHeaderSignature(token);
              axios.defaults.headers.common["Authorization"] = authHeader;
              originalRequest.headers["Authorization"] = authHeader;
              processQueue(null, token);
              resolve(axios(originalRequest));
            })
            .catch(err => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }

      if (error.response?.status === ResponseCodeEnum.DEACTIVATED) {
        if (
          !window.location.href.includes(Routes.INTRO_ROUTE) &&
          !window.location.href.includes(Routes.LENUS_TRANSFER)
        ) {
          window.location.replace(Routes.INTRO_ROUTE);
        }
      }

      return Promise.reject(error);
    },
  );
};

export default axios;
