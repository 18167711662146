export const Confetti = {
  v: "5.7.4",
  fr: 30,
  ip: 0,
  op: 60,
  w: 1920,
  h: 1080,
  nm: "Scene_1",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "cross",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 70,
                  s: [360],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 140,
                  s: [720],
                },
                { t: 210, s: [1080] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [23.855, 23.319, 0], ix: 1, l: 2 },
            s: { a: 0, k: [106, 106, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.928, 0],
                        [0, 0],
                        [0, 3.926],
                        [0, 0],
                        [3.928, 0],
                        [0, -3.928],
                        [0, 0],
                        [3.928, 0],
                        [0, 0],
                        [0, -3.927],
                        [-3.928, 0],
                        [0, 0],
                        [0, -3.928],
                        [0, 0],
                        [-3.928, 0],
                        [0, 3.928],
                        [0, 0],
                        [-3.928, 0],
                        [0, 0],
                        [0, 3.928],
                      ],
                      o: [
                        [0, 0],
                        [-3.928, 0],
                        [0, 0],
                        [0, -3.928],
                        [-3.928, 0],
                        [0, 0],
                        [0, 3.926],
                        [0, 0],
                        [-3.928, 0],
                        [0, 3.928],
                        [0, 0],
                        [3.928, 0],
                        [0, 0],
                        [0, 3.928],
                        [3.928, 0],
                        [0, 0],
                        [0, -3.928],
                        [0, 0],
                        [3.928, 0],
                        [0, -3.927],
                      ],
                      v: [
                        [15.873, -7.112],
                        [14.224, -7.112],
                        [7.112, -14.223],
                        [7.112, -15.872],
                        [0.001, -22.984],
                        [-7.112, -15.872],
                        [-7.112, -14.223],
                        [-14.223, -7.112],
                        [-15.871, -7.112],
                        [-22.984, -0.001],
                        [-15.871, 7.112],
                        [-14.223, 7.112],
                        [-7.112, 14.223],
                        [-7.112, 15.871],
                        [0.001, 22.984],
                        [7.112, 15.871],
                        [7.112, 14.223],
                        [14.224, 7.112],
                        [15.873, 7.112],
                        [22.984, -0.001],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    k: [
                      {
                        s: [1, 0.216, 0.776, 1],
                        t: 0,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                      {
                        s: [1, 0.216, 0.776, 1],
                        t: 54,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                    ],
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [23.855, 23.319], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 217,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "diamond",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 30,
                  s: [360],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 60,
                  s: [720],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 90,
                  s: [1080],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 120,
                  s: [1440],
                },
                { t: 150, s: [1800] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [21.954, 21.319, 0], ix: 1, l: 2 },
            s: { a: 0, k: [147, 147, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.335, 2.336],
                        [0, 0],
                        [-2.336, 2.335],
                        [0, 0],
                        [-2.336, -2.336],
                        [0, 0],
                        [2.335, -2.336],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.336, -2.336],
                        [0, 0],
                        [2.335, -2.336],
                        [0, 0],
                        [2.335, 2.335],
                        [0, 0],
                        [-2.336, 2.336],
                      ],
                      v: [
                        [-4.228, 18.524],
                        [-18.524, 4.229],
                        [-18.524, -4.229],
                        [-4.228, -18.524],
                        [4.229, -18.524],
                        [18.525, -4.229],
                        [18.525, 4.229],
                        [4.229, 18.524],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    k: [
                      {
                        s: [0.337, 0.686, 1, 1],
                        t: 0,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                      {
                        s: [0.337, 0.686, 1, 1],
                        t: 54,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                    ],
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [21.954, 21.319], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 155,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "star",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 30,
                  s: [360],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 60,
                  s: [720],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 90,
                  s: [1080],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 120,
                  s: [1440],
                },
                { t: 150, s: [1800] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [22.839, 21.821, 0], ix: 1, l: 2 },
            s: { a: 0, k: [111, 111, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.736, 1.511],
                        [0, 0],
                        [1.11, -0.555],
                        [0, 0],
                        [-0.592, 3.07],
                        [0, 0],
                        [0.871, 0.885],
                        [0, 0],
                        [-3.103, 0.386],
                        [0, 0],
                        [-0.572, 1.102],
                        [0, 0],
                        [-1.327, -2.831],
                        [0, 0],
                        [-1.224, -0.204],
                        [0, 0],
                        [2.282, -2.135],
                        [0, 0],
                        [-0.185, -1.227],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.087, -0.6],
                        [0, 0],
                        [-2.796, 1.398],
                        [0, 0],
                        [0.236, -1.219],
                        [0, 0],
                        [-2.193, -2.227],
                        [0, 0],
                        [1.232, -0.153],
                        [0, 0],
                        [1.44, -2.774],
                        [0, 0],
                        [0.526, 1.124],
                        [0, 0],
                        [3.083, 0.513],
                        [0, 0],
                        [-0.906, 0.848],
                        [0, 0],
                        [0.466, 3.091],
                      ],
                      v: [
                        [8.413, 19.633],
                        [1.358, 15.739],
                        [-2.151, 15.667],
                        [-9.359, 19.269],
                        [-14.748, 15.182],
                        [-13.224, 7.27],
                        [-14.239, 3.91],
                        [-19.893, -1.832],
                        [-17.67, -8.22],
                        [-9.675, -9.215],
                        [-6.793, -11.219],
                        [-3.08, -18.371],
                        [3.684, -18.231],
                        [7.101, -10.935],
                        [9.896, -8.813],
                        [17.845, -7.492],
                        [19.803, -1.018],
                        [13.918, 4.487],
                        [12.766, 7.803],
                        [13.964, 15.771],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    k: [
                      {
                        s: [1, 0.529, 0.286, 1],
                        t: 0,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                      {
                        s: [1, 0.529, 0.286, 1],
                        t: 54,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                    ],
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [22.829, 21.967], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 154,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_3",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "circle",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [966.94, 510.978, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -12.324],
                        [12.324, 0],
                        [0, 12.324],
                        [-12.325, 0],
                      ],
                      o: [
                        [0, 12.324],
                        [-12.325, 0],
                        [0, -12.324],
                        [12.324, 0],
                      ],
                      v: [
                        [22.315, 0],
                        [0, 22.314],
                        [-22.315, 0],
                        [0, -22.314],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    k: [
                      {
                        s: [1, 0.827, 0.047, 1],
                        t: 0,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                      {
                        s: [1, 0.827, 0.047, 1],
                        t: 54,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                    ],
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [966.94, 510.978], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 154,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_4",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "zig zag",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 0,
                  s: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 60,
                  s: [360],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 120,
                  s: [720],
                },
                { t: 180, s: [1080] },
              ],
              ix: 10,
            },
            p: { a: 0, k: [50, 50, 0], ix: 2, l: 2 },
            a: { a: 0, k: [32.054, 16.802, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-2.604, 2.463],
                        [0, 0],
                        [2.563, 2.709],
                        [2.71, -2.563],
                        [0, 0],
                        [2.604, 2.464],
                        [0, 0],
                        [2.604, -2.464],
                        [0, 0],
                        [-2.564, -2.71],
                        [-2.71, 2.563],
                        [0, 0],
                        [-2.604, -2.463],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [2.709, -2.564],
                        [-2.564, -2.71],
                        [0, 0],
                        [-2.604, 2.463],
                        [0, 0],
                        [-2.604, -2.464],
                        [0, 0],
                        [-2.709, 2.564],
                        [2.563, 2.71],
                        [0, 0],
                        [2.604, -2.463],
                        [0, 0],
                        [2.604, 2.464],
                      ],
                      v: [
                        [14.163, 13.912],
                        [28.294, 0.543],
                        [28.559, -9.005],
                        [19.01, -9.27],
                        [14.163, -4.683],
                        [4.88, -4.683],
                        [-4.877, -13.912],
                        [-14.16, -13.912],
                        [-28.294, -0.543],
                        [-28.558, 9.006],
                        [-19.01, 9.271],
                        [-14.16, 4.683],
                        [-4.877, 4.683],
                        [4.88, 13.912],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    k: [
                      {
                        s: [1, 0.973, 0.937, 1],
                        t: 0,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                      {
                        s: [1, 0.973, 0.937, 1],
                        t: 54,
                        i: { x: [1], y: [1] },
                        o: { x: [0], y: [0] },
                      },
                    ],
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [32.054, 16.803], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 185,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Ribbon 6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1584, 408.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [480, 572, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [90.301, 27.192],
                    [26.849, 37.555],
                    [-34.387, 30.802],
                    [-10.169, 0.568],
                    [-8.013, -10.348],
                    [24.318, -23.733],
                    [33.361, -6.451],
                    [85.524, 37.427],
                    [9.076, 44.391],
                    [-25.452, 31.147],
                    [-31.025, 17.406],
                    [-49.654, 52.747],
                    [2.765, 43.187],
                    [-9.718, 94.722],
                    [-60.767, 6.064],
                  ],
                  o: [
                    [-91.817, 21.521],
                    [-44.205, -13.312],
                    [-26.851, -37.555],
                    [7.587, -6.796],
                    [13.068, -0.729],
                    [20.803, 26.866],
                    [-24.316, 23.732],
                    [-91.657, 17.724],
                    [-41.508, -18.165],
                    [-8.058, -39.409],
                    [22.511, -27.548],
                    [63.179, -35.443],
                    [29.663, -31.511],
                    [-6.083, -95.024],
                    [6.233, -60.751],
                    [0, 0],
                  ],
                  v: [
                    [404.717, 474.894],
                    [125.144, 466.188],
                    [9.514, 394.679],
                    [14.615, 268.333],
                    [41.766, 255.635],
                    [75.446, 272.676],
                    [61.999, 364.816],
                    [-30.17, 404.75],
                    [-305.167, 383.696],
                    [-396.658, 290.373],
                    [-359.954, 179.607],
                    [-278.698, 111.378],
                    [-87.81, 10.713],
                    [-45.045, -108.051],
                    [-130.996, -376.917],
                    [-11.164, -496.416],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                k: [
                  {
                    s: [0.337, 0.686, 1, 1],
                    t: 8,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.337, 0.686, 1, 1],
                    t: 33,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.603], y: [1.004] },
                    o: { x: [0.693], y: [0.002] },
                    t: 8,
                    s: [55],
                  },
                  { t: 28, s: [20] },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [480.23, 572.002], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 12, s: [0] },
              { t: 32, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 8, s: [0] },
              { t: 28, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 8,
      op: 33,
      st: 8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Ribbon 5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1396.5, 583.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [309, 677.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.961, -54.589],
                    [25.255, -69.233],
                    [31.409, -9.892],
                    [5.32, 1.119],
                    [3.528, 9.54],
                    [-23.808, 17.523],
                    [-29.561, -0.067],
                    [-28.818, -13.593],
                    [-2.379, -91.281],
                    [15.812, -89.932],
                    [-46.145, -78.795],
                    [-57.73, 4.595],
                  ],
                  o: [
                    [27.775, 47.087],
                    [3.992, 73.587],
                    [-11.285, 30.936],
                    [-5.186, 1.632],
                    [-9.954, -2.092],
                    [-10.252, -27.727],
                    [23.807, -17.524],
                    [31.863, 0.071],
                    [82.587, 38.954],
                    [2.379, 91.283],
                    [-15.811, 89.934],
                    [29.267, 49.973],
                    [0, 0],
                  ],
                  v: [
                    [-141.342, -602.321],
                    [-106.95, -443.663],
                    [-124.551, -224.885],
                    [-186.95, -152.863],
                    [-203.009, -151.348],
                    [-223.602, -172.078],
                    [-195.49, -249.783],
                    [-111.281, -272.301],
                    [-18.425, -253.088],
                    [106.534, -24.978],
                    [65.858, 245.304],
                    [93.142, 512.083],
                    [233.854, 597.726],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                k: [
                  {
                    s: [1, 0.827, 0.047, 1],
                    t: 4,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1, 0.827, 0.047, 1],
                    t: 28,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.603], y: [1.004] },
                    o: { x: [0.693], y: [0.002] },
                    t: 4,
                    s: [55],
                  },
                  { t: 24, s: [20] },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [308.938, 677.539], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 8, s: [100] },
              { t: 28, s: [0] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 4, s: [100] },
              { t: 24, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 4,
      op: 29,
      st: 4,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Ribbon 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1360, 569, 0], ix: 2, l: 2 },
        a: { a: 0, k: [474, 670.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-10.592, -51.14],
                    [15.654, -49.824],
                    [12.825, -40.819],
                    [-13.362, -39.448],
                    [-46.702, -13.652],
                    [-45.644, -16.853],
                    [-5.76, -48.314],
                    [12.615, -34.58],
                    [-32.379, -41.511],
                    [-63.653, -0.017],
                    [-49.763, -25.605],
                    [4.462, -55.787],
                  ],
                  o: [
                    [51.563, -8.289],
                    [10.592, 51.139],
                    [-12.825, 40.82],
                    [-12.485, 39.735],
                    [15.612, 46.084],
                    [46.702, 13.652],
                    [45.644, 16.854],
                    [4.359, 36.551],
                    [-18.043, 49.457],
                    [39.15, 50.188],
                    [55.965, 0.015],
                    [49.765, 25.604],
                    [0, 0],
                  ],
                  v: [
                    [-398.294, -586.637],
                    [-289.087, -496.164],
                    [-309.97, -342.698],
                    [-348.444, -220.239],
                    [-360.063, -98.037],
                    [-250.698, -10.107],
                    [-108.815, 23.604],
                    [-13.764, 124.533],
                    [-39.875, 230.618],
                    [-31.815, 381.532],
                    [145.094, 431.794],
                    [308.697, 461.418],
                    [393.833, 594.926],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                k: [
                  {
                    s: [1, 0.529, 0.286, 1],
                    t: 0,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1, 0.529, 0.286, 1],
                    t: 24,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.603], y: [1.004] },
                    o: { x: [0.693], y: [0.002] },
                    t: 0,
                    s: [55],
                  },
                  { t: 20, s: [20] },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [474.128, 670.603], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 4, s: [100] },
              { t: 24, s: [0] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [100] },
              { t: 20, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 0,
      op: 25,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Ribbon 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [230, 396.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [430, 577, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-105.135, -9.589],
                    [-24.324, -15.114],
                    [14.109, -54.133],
                    [23.501, 8.56],
                    [-6.398, 21.235],
                    [-68.221, 12.384],
                    [-34.213, -5.496],
                    [-15.289, -18.681],
                    [20.1, -13.371],
                    [18.06, 16.864],
                    [-34.62, 77.311],
                    [1.829, 59.064],
                    [26.174, 34.52],
                    [36.785, 33.977],
                    [8.019, 44.079],
                    [-29.771, 26.983],
                    [-38.501, 11.494],
                  ],
                  o: [
                    [88.47, -57.605],
                    [28.519, 2.601],
                    [47.514, 29.526],
                    [-6.308, 24.202],
                    [-20.838, -7.591],
                    [20.003, -66.389],
                    [34.094, -6.189],
                    [23.835, 3.829],
                    [15.289, 18.683],
                    [-20.574, 13.684],
                    [-61.912, -57.812],
                    [24.15, -53.931],
                    [-1.342, -43.3],
                    [-30.254, -39.904],
                    [-32.911, -30.398],
                    [-7.193, -39.531],
                    [29.771, -26.984],
                    [0, 0],
                  ],
                  v: [
                    [-354.622, 354.799],
                    [-52.907, 279.969],
                    [28.992, 303.165],
                    [82.278, 450.589],
                    [31.066, 492.89],
                    [13.917, 435.186],
                    [171.366, 315.81],
                    [274.812, 314.325],
                    [338.786, 344.657],
                    [334.523, 408.778],
                    [267.723, 393.214],
                    [237.43, 157.124],
                    [300.24, -6.496],
                    [248.363, -123.326],
                    [147.543, -234.435],
                    [69.089, -340.407],
                    [111.407, -447.78],
                    [218.195, -501.45],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                k: [
                  {
                    s: [1, 0.827, 0.047, 1],
                    t: 8,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1, 0.827, 0.047, 1],
                    t: 33,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.603], y: [1.004] },
                    o: { x: [0.693], y: [0.002] },
                    t: 8,
                    s: [55],
                  },
                  { t: 28, s: [20] },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [430.314, 576.731], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 12, s: [0] },
              { t: 32, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 8, s: [0] },
              { t: 28, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 8,
      op: 33,
      st: 8,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Ribbon 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [408, 536, 0], ix: 2, l: 2 },
        a: { a: 0, k: [328, 661, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-22.66, 133.637],
                    [45.382, 127.721],
                    [-0.623, 46.233],
                    [-32.246, 42.431],
                    [-118.437, 16.195],
                  ],
                  o: [
                    [124.454, -53.698],
                    [22.66, -133.637],
                    [-15.481, -43.568],
                    [0.717, -53.288],
                    [72.329, -95.174],
                    [0, 0],
                  ],
                  v: [
                    [-252.21, 585.736],
                    [-24.183, 269.361],
                    [-75.285, -129.565],
                    [-107.854, -263.572],
                    [-49.602, -409.538],
                    [252.21, -585.736],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                k: [
                  {
                    s: [1, 0.529, 0.286, 1],
                    t: 5,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [1, 0.529, 0.286, 1],
                    t: 29,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.603], y: [1.004] },
                    o: { x: [0.693], y: [0.002] },
                    t: 5,
                    s: [55],
                  },
                  { t: 25, s: [20] },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [327.842, 660.823], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 9, s: [0] },
              { t: 29, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 5, s: [0] },
              { t: 25, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 5,
      op: 30,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Ribbon 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [419.5, 461.5, 0], ix: 2, l: 2 },
        a: { a: 0, k: [572, 613, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-25.182, 22.908],
                    [-35.474, 61.685],
                    [-5.223, 45.5],
                    [29.801, 34.776],
                    [6.635, -3.058],
                    [1.464, -2.266],
                    [-28.695, -4.861],
                    [-22.537, 18.416],
                    [-69.043, 105.713],
                    [-53.328, 18.681],
                    [-28.193, -48.969],
                    [5.284, 5.675],
                    [0.047, 7.754],
                    [-36.391, 35.732],
                    [-53.13, 27.345],
                  ],
                  o: [
                    [33.64, 5.223],
                    [52.638, -47.884],
                    [22.832, -39.701],
                    [5.223, -45.499],
                    [-4.754, -5.548],
                    [-2.451, 1.129],
                    [-15.795, 24.446],
                    [28.695, 4.86],
                    [97.77, -79.894],
                    [30.898, -47.308],
                    [53.327, -18.681],
                    [-4.182, 6.529],
                    [-5.283, -5.674],
                    [-0.31, -51],
                    [42.637, -41.865],
                    [0, 0],
                  ],
                  v: [
                    [-496.409, 532.475],
                    [-406.261, 492.468],
                    [-273.021, 326.779],
                    [-225.882, 198.751],
                    [-260.484, 69.777],
                    [-279.156, 61.682],
                    [-284.89, 67.24],
                    [-248.577, 131.397],
                    [-169.191, 102.107],
                    [34.705, -214.218],
                    [159.187, -324.697],
                    [308.761, -279.598],
                    [288.602, -279.705],
                    [282.169, -301.486],
                    [351.666, -433.093],
                    [496.409, -537.698],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                k: [
                  {
                    s: [0.337, 0.686, 1, 1],
                    t: 2,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                  {
                    s: [0.337, 0.686, 1, 1],
                    t: 26,
                    i: { x: [1], y: [1] },
                    o: { x: [0], y: [0] },
                  },
                ],
              },
              o: { a: 0, k: 100, ix: 4 },
              w: {
                a: 1,
                k: [
                  {
                    i: { x: [0.603], y: [1.004] },
                    o: { x: [0.693], y: [0.002] },
                    t: 2,
                    s: [55],
                  },
                  { t: 22, s: [20] },
                ],
                ix: 5,
              },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [572.111, 613.182], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 6, s: [0] },
              { t: 26, s: [100] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 2, s: [0] },
              { t: 22, s: [100] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: 2,
      op: 27,
      st: 2,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: "_cross",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.724] },
                o: { x: [0.356], y: [0.366] },
                t: 7,
                s: [-503.38],
              },
              {
                i: { x: [0.725], y: [2.203] },
                o: { x: [0.235], y: [2.226] },
                t: 18.75,
                s: [585.435],
              },
              { t: 60, s: [793.37] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.186] },
                t: 7,
                s: [1230.76],
              },
              {
                i: { x: [0.89], y: [0.788] },
                o: { x: [0.341], y: [0] },
                t: 18.75,
                s: [205.312],
              },
              { t: 60, s: [1059.68] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [273, 273, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [91, 91, 100],
            },
            { t: 60, s: [45.5, 45.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 215,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: "_cross",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: -15, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.572], y: [0.622] },
                o: { x: [0.356], y: [0.307] },
                t: 7,
                s: [-1383.965],
              },
              {
                i: { x: [0.725], y: [1.36] },
                o: { x: [0.235], y: [0.665] },
                t: 18.75,
                s: [-87.851],
              },
              { t: 60, s: [607.714] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.091] },
                t: 7,
                s: [1875.779],
              },
              {
                i: { x: [0.89], y: [0.857] },
                o: { x: [0.341], y: [0] },
                t: 18.75,
                s: [-221.44],
              },
              { t: 60, s: [1050.162] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [450, 450, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [150, 150, 100],
            },
            { t: 60, s: [75, 75, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 215,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: "_cross",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: -15, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.535], y: [0.579] },
                o: { x: [0.356], y: [0.452] },
                t: 7,
                s: [-622.376],
              },
              {
                i: { x: [0.725], y: [1.529] },
                o: { x: [0.235], y: [0.978] },
                t: 18.75,
                s: [258.981],
              },
              { t: 60, s: [731.966] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.134] },
                t: 7,
                s: [1877.01],
              },
              {
                i: { x: [0.89], y: [0.79] },
                o: { x: [0.341], y: [0] },
                t: 18.75,
                s: [450.901],
              },
              { t: 60, s: [1315.59] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [306, 306, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [102, 102, 100],
            },
            { t: 60, s: [51, 51, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 215,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: "_cross",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 22, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.559], y: [0.589] },
                o: { x: [0.582], y: [0.299] },
                t: 7,
                s: [2163.229],
              },
              {
                i: { x: [0.654], y: [0.922] },
                o: { x: [0.206], y: [0.39] },
                t: 18.75,
                s: [1627.179],
              },
              { t: 60, s: [1187.596] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.164] },
                t: 7,
                s: [1582.928],
              },
              {
                i: { x: [0.89], y: [0.704] },
                o: { x: [0.341], y: [0] },
                t: 18.75,
                s: [418.366],
              },
              { t: 60, s: [1030.59] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-234, 234, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-78, 78, 100],
            },
            { t: 60, s: [-39, 39, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 215,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: "_cross",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 22, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.682], y: [0.684] },
                o: { x: [0.582], y: [0.165] },
                t: 7,
                s: [2828.845],
              },
              {
                i: { x: [0.654], y: [0.957] },
                o: { x: [0.2], y: [0.338] },
                t: 18.75,
                s: [1858.593],
              },
              { t: 60, s: [1062.95] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.09] },
                t: 7,
                s: [2079.7],
              },
              {
                i: { x: [0.89], y: [0.836] },
                o: { x: [0.341], y: [0] },
                t: 18.75,
                s: [-28.158],
              },
              { t: 60, s: [1079.969] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-423.54, 423.54, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-141.18, 141.18, 100],
            },
            { t: 60, s: [-70.59, 70.59, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 215,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: "_diamond",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.748] },
                o: { x: [0.356], y: [0.333] },
                t: 7,
                s: [-770],
              },
              {
                i: { x: [0.706], y: [2.167] },
                o: { x: [0.126], y: [1.087] },
                t: 18.75,
                s: [426.5],
              },
              { t: 60, s: [655] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.169] },
                t: 7,
                s: [1516],
              },
              {
                i: { x: [0.894], y: [0.813] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [389.134],
              },
              { t: 60, s: [1328] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [300, 300, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [100, 100, 100],
            },
            { t: 60, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: "_diamond",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.54], y: [0.818] },
                o: { x: [0.356], y: [0.25] },
                t: 7,
                s: [-1480.94],
              },
              {
                i: { x: [0.706], y: [1.878] },
                o: { x: [0.126], y: [0.817] },
                t: 18.75,
                s: [110.405],
              },
              { t: 60, s: [414.31] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.127] },
                t: 7,
                s: [1323.88],
              },
              {
                i: { x: [0.894], y: [0.86] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [-174.852],
              },
              { t: 60, s: [1073.84] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [399, 399, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [133, 133, 100],
            },
            { t: 60, s: [66.5, 66.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 0,
      nm: "_diamond",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.885] },
                o: { x: [0.356], y: [0.152] },
                t: 7,
                s: [-3112.42],
              },
              {
                i: { x: [0.706], y: [1.533] },
                o: { x: [0.126], y: [0.496] },
                t: 18.75,
                s: [-492.085],
              },
              { t: 60, s: [8.33] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.077] },
                t: 7,
                s: [2537.84],
              },
              {
                i: { x: [0.894], y: [0.915] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [70.003],
              },
              { t: 60, s: [2126.12] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [657, 657, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [219, 219, 100],
            },
            { t: 60, s: [109.5, 109.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: "_diamond",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.496], y: [0.827] },
                o: { x: [0.221], y: [0.284] },
                t: 7,
                s: [3262.24],
              },
              {
                i: { x: [0.692], y: [1.044] },
                o: { x: [0.151], y: [0.5] },
                t: 18.75,
                s: [1850.37],
              },
              { t: 60, s: [1580.74] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.143] },
                t: 7,
                s: [1658.48],
              },
              {
                i: { x: [0.894], y: [0.842] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [328.778],
              },
              { t: 60, s: [1436.64] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-354, 354, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-118, 118, 100],
            },
            { t: 60, s: [-59, 59, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 0,
      nm: "_diamond",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.496], y: [0.686] },
                o: { x: [0.221], y: [0.517] },
                t: 7,
                s: [2090.232],
              },
              {
                i: { x: [0.692], y: [1.08] },
                o: { x: [0.151], y: [0.909] },
                t: 18.75,
                s: [1313.704],
              },
              { t: 60, s: [1165.407] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.261] },
                t: 7,
                s: [656.164],
              },
              {
                i: { x: [0.894], y: [0.712] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [-75.172],
              },
              { t: 60, s: [534.152] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-194.7, 194.7, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-64.9, 64.9, 100],
            },
            { t: 60, s: [-32.45, 32.45, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.748] },
                o: { x: [0.356], y: [0.333] },
                t: 7,
                s: [-543],
              },
              {
                i: { x: [0.706], y: [2.167] },
                o: { x: [0.126], y: [1.087] },
                t: 18.75,
                s: [653.5],
              },
              { t: 60, s: [882] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.169] },
                t: 7,
                s: [1376],
              },
              {
                i: { x: [0.894], y: [0.813] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [249.134],
              },
              { t: 60, s: [1188] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [300, 300, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [100, 100, 100],
            },
            { t: 60, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.79] },
                o: { x: [0.356], y: [0.277] },
                t: 7,
                s: [-1041.6],
              },
              {
                i: { x: [0.706], y: [1.973] },
                o: { x: [0.126], y: [0.906] },
                t: 18.75,
                s: [394.2],
              },
              { t: 60, s: [668.4] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.141] },
                t: 7,
                s: [1388.2],
              },
              {
                i: { x: [0.894], y: [0.844] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [35.961],
              },
              { t: 60, s: [1162.6] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [360, 360, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [120, 120, 100],
            },
            { t: 60, s: [60, 60, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.685] },
                o: { x: [0.356], y: [0.416] },
                t: 7,
                s: [-378.4],
              },
              {
                i: { x: [0.706], y: [2.459] },
                o: { x: [0.126], y: [1.359] },
                t: 18.75,
                s: [578.8],
              },
              { t: 60, s: [761.6] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.211] },
                t: 7,
                s: [1267.8],
              },
              {
                i: { x: [0.894], y: [0.767] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [366.307],
              },
              { t: 60, s: [1117.4] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [240, 240, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [80, 80, 100],
            },
            { t: 60, s: [40, 40, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.685] },
                o: { x: [0.356], y: [0.416] },
                t: 7,
                s: [-507.4],
              },
              {
                i: { x: [0.706], y: [2.459] },
                o: { x: [0.126], y: [1.359] },
                t: 18.75,
                s: [449.8],
              },
              { t: 60, s: [632.6] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.211] },
                t: 7,
                s: [1087.8],
              },
              {
                i: { x: [0.894], y: [0.767] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [186.307],
              },
              { t: 60, s: [937.4] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [240, 240, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [80, 80, 100],
            },
            { t: 60, s: [40, 40, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.45], y: [0.773] },
                o: { x: [0.294], y: [0.298] },
                t: 7,
                s: [2063.4],
              },
              {
                i: { x: [0.678], y: [1.162] },
                o: { x: [0.177], y: [0.844] },
                t: 18.75,
                s: [1106.2],
              },
              { t: 60, s: [923.4] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.211] },
                t: 7,
                s: [1098.8],
              },
              {
                i: { x: [0.894], y: [0.767] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [197.307],
              },
              { t: 60, s: [948.4] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-240, 240, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-80, 80, 100],
            },
            { t: 60, s: [-40, 40, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.45], y: [0.849] },
                o: { x: [0.294], y: [0.199] },
                t: 7,
                s: [2824.1],
              },
              {
                i: { x: [0.678], y: [1.108] },
                o: { x: [0.177], y: [0.563] },
                t: 18.75,
                s: [1388.3],
              },
              { t: 60, s: [1114.1] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.141] },
                t: 7,
                s: [1586.2],
              },
              {
                i: { x: [0.894], y: [0.844] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [233.961],
              },
              { t: 60, s: [1360.6] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-360, 360, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-120, 120, 100],
            },
            { t: 60, s: [-60, 60, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 0,
      nm: "_star",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.45], y: [0.886] },
                o: { x: [0.294], y: [0.149] },
                t: 7,
                s: [3287.8],
              },
              {
                i: { x: [0.678], y: [1.081] },
                o: { x: [0.177], y: [0.422] },
                t: 18.75,
                s: [1373.4],
              },
              { t: 60, s: [1007.8] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.106] },
                t: 7,
                s: [1838.6],
              },
              {
                i: { x: [0.894], y: [0.883] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [35.614],
              },
              { t: 60, s: [1537.8] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-480, 480, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-160, 160, 100],
            },
            { t: 60, s: [-80, 80, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.748] },
                o: { x: [0.356], y: [0.333] },
                t: 7,
                s: [-926],
              },
              {
                i: { x: [0.706], y: [2.167] },
                o: { x: [0.126], y: [1.087] },
                t: 18.75,
                s: [270.5],
              },
              { t: 60, s: [499] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.169] },
                t: 7,
                s: [1306],
              },
              {
                i: { x: [0.894], y: [0.813] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [179.134],
              },
              { t: 60, s: [1118] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [300, 300, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [100, 100, 100],
            },
            { t: 60, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.832] },
                o: { x: [0.356], y: [0.222] },
                t: 7,
                s: [-1714],
              },
              {
                i: { x: [0.706], y: [1.778] },
                o: { x: [0.126], y: [0.725] },
                t: 18.75,
                s: [80.75],
              },
              { t: 60, s: [423.5] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.113] },
                t: 7,
                s: [2005],
              },
              {
                i: { x: [0.894], y: [0.876] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [314.701],
              },
              { t: 60, s: [1723] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [450, 450, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [150, 150, 100],
            },
            { t: 60, s: [75, 75, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.832] },
                o: { x: [0.356], y: [0.222] },
                t: 7,
                s: [-1982],
              },
              {
                i: { x: [0.706], y: [1.778] },
                o: { x: [0.126], y: [0.725] },
                t: 18.75,
                s: [-187.25],
              },
              { t: 60, s: [155.5] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.113] },
                t: 7,
                s: [1367],
              },
              {
                i: { x: [0.894], y: [0.876] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [-323.299],
              },
              { t: 60, s: [1085] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [450, 450, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [150, 150, 100],
            },
            { t: 60, s: [75, 75, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.436], y: [0.685] },
                o: { x: [0.356], y: [0.416] },
                t: 7,
                s: [-392.4],
              },
              {
                i: { x: [0.706], y: [2.459] },
                o: { x: [0.126], y: [1.359] },
                t: 18.75,
                s: [564.8],
              },
              { t: 60, s: [747.6] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.211] },
                t: 7,
                s: [827.8],
              },
              {
                i: { x: [0.894], y: [0.767] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [-73.693],
              },
              { t: 60, s: [677.4] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [240, 240, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [80, 80, 100],
            },
            { t: 60, s: [40, 40, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.451], y: [0.765] },
                o: { x: [0.229], y: [0.287] },
                t: 7,
                s: [1930.8],
              },
              {
                i: { x: [0.698], y: [1.063] },
                o: { x: [0.138], y: [0.934] },
                t: 18.75,
                s: [1212.9],
              },
              { t: 60, s: [1075.8] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.282] },
                t: 7,
                s: [995.6],
              },
              {
                i: { x: [0.894], y: [0.689] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [319.48],
              },
              { t: 60, s: [882.8] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-180, 180, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-60, 60, 100],
            },
            { t: 60, s: [-30, 30, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.451], y: [0.882] },
                o: { x: [0.229], y: [0.144] },
                t: 7,
                s: [3254.6],
              },
              {
                i: { x: [0.698], y: [1.032] },
                o: { x: [0.138], y: [0.467] },
                t: 18.75,
                s: [1818.8],
              },
              { t: 60, s: [1544.6] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.141] },
                t: 7,
                s: [1464.2],
              },
              {
                i: { x: [0.894], y: [0.844] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [111.961],
              },
              { t: 60, s: [1238.6] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-360, 360, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-120, 120, 100],
            },
            { t: 60, s: [-60, 60, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 30,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.451], y: [0.804] },
                o: { x: [0.229], y: [0.239] },
                t: 7,
                s: [2477.96],
              },
              {
                i: { x: [0.698], y: [1.053] },
                o: { x: [0.138], y: [0.778] },
                t: 18.75,
                s: [1616.48],
              },
              { t: 60, s: [1451.96] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.235] },
                t: 7,
                s: [1494.92],
              },
              {
                i: { x: [0.894], y: [0.741] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [683.576],
              },
              { t: 60, s: [1359.56] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-216, 216, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-72, 72, 100],
            },
            { t: 60, s: [-36, 36, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 31,
      ty: 0,
      nm: "_circle",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.593], y: [0.86] },
                o: { x: [0.229], y: [0.096] },
                t: 7,
                s: [3582.4],
              },
              {
                i: { x: [0.698], y: [1.021] },
                o: { x: [0.138], y: [0.311] },
                t: 18.75,
                s: [1428.7],
              },
              { t: 60, s: [1017.4] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.094] },
                t: 7,
                s: [2534.8],
              },
              {
                i: { x: [0.894], y: [0.896] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [506.441],
              },
              { t: 60, s: [2196.4] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-540, 540, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-180, 180, 100],
            },
            { t: 60, s: [-90, 90, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 155,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 32,
      ty: 0,
      nm: "_zig zag",
      refId: "comp_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.52], y: [0.681] },
                o: { x: [0.356], y: [0.333] },
                t: 7,
                s: [-606],
              },
              {
                i: { x: [0.706], y: [2.167] },
                o: { x: [0.126], y: [1.087] },
                t: 18.75,
                s: [590.5],
              },
              { t: 60, s: [819] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.169] },
                t: 7,
                s: [1314],
              },
              {
                i: { x: [0.894], y: [0.813] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [187.134],
              },
              { t: 60, s: [1126] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [300, 300, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [100, 100, 100],
            },
            { t: 60, s: [50, 50, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 185,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 33,
      ty: 0,
      nm: "_zig zag",
      refId: "comp_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.588], y: [0.739] },
                o: { x: [0.356], y: [0.195] },
                t: 7,
                s: [-1777.78],
              },
              {
                i: { x: [0.706], y: [1.683] },
                o: { x: [0.126], y: [0.636] },
                t: 18.75,
                s: [268.235],
              },
              { t: 60, s: [658.97] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.099] },
                t: 7,
                s: [2529.56],
              },
              {
                i: { x: [0.894], y: [0.891] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [602.619],
              },
              { t: 60, s: [2208.08] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [513, 513, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [171, 171, 100],
            },
            { t: 60, s: [85.5, 85.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 185,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 34,
      ty: 0,
      nm: "_zig zag",
      refId: "comp_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.531], y: [0.726] },
                o: { x: [0.356], y: [0.247] },
                t: 7,
                s: [-1607.3],
              },
              {
                i: { x: [0.706], y: [1.865] },
                o: { x: [0.126], y: [0.805] },
                t: 18.75,
                s: [7.975],
              },
              { t: 60, s: [316.45] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.125] },
                t: 7,
                s: [1646.6],
              },
              {
                i: { x: [0.894], y: [0.862] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [125.331],
              },
              { t: 60, s: [1392.8] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [405, 405, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [135, 135, 100],
            },
            { t: 60, s: [67.5, 67.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 185,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 35,
      ty: 0,
      nm: "_zig zag",
      refId: "comp_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.487], y: [0.845] },
                o: { x: [0.326], y: [0.345] },
                t: 7,
                s: [3239.3],
              },
              {
                i: { x: [0.694], y: [1.046] },
                o: { x: [0.131], y: [0.546] },
                t: 18.75,
                s: [1624.025],
              },
              { t: 60, s: [1315.55] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.125] },
                t: 7,
                s: [1595.6],
              },
              {
                i: { x: [0.894], y: [0.862] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [74.331],
              },
              { t: 60, s: [1341.8] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-405, 405, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-135, 135, 100],
            },
            { t: 60, s: [-67.5, 67.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 185,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 36,
      ty: 0,
      nm: "_zig zag",
      refId: "comp_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.487], y: [0.808] },
                o: { x: [0.326], y: [0.426] },
                t: 7,
                s: [2785.223],
              },
              {
                i: { x: [0.694], y: [1.057] },
                o: { x: [0.131], y: [0.674] },
                t: 18.75,
                s: [1476.85],
              },
              { t: 60, s: [1226.986] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.155] },
                t: 7,
                s: [1067.666],
              },
              {
                i: { x: [0.894], y: [0.829] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [-164.562],
              },
              { t: 60, s: [862.088] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-328.05, 328.05, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-109.35, 109.35, 100],
            },
            { t: 60, s: [-54.675, 54.675, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 185,
      st: 5,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 37,
      ty: 0,
      nm: "_zig zag",
      refId: "comp_4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 46.25,
              s: [100],
            },
            { t: 60, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.487], y: [0.845] },
                o: { x: [0.326], y: [0.345] },
                t: 7,
                s: [2918.3],
              },
              {
                i: { x: [0.694], y: [1.046] },
                o: { x: [0.131], y: [0.546] },
                t: 18.75,
                s: [1303.025],
              },
              { t: 60, s: [994.55] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.353], y: [1] },
                o: { x: [0.133], y: [0.125] },
                t: 7,
                s: [1918.6],
              },
              {
                i: { x: [0.894], y: [0.862] },
                o: { x: [0.668], y: [0] },
                t: 18.75,
                s: [397.331],
              },
              { t: 60, s: [1664.8] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 7,
              s: [-405, 405, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 18.75,
              s: [-135, 135, 100],
            },
            { t: 60, s: [-67.5, 67.5, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      w: 100,
      h: 100,
      ip: 5,
      op: 185,
      st: 5,
      bm: 0,
    },
  ],
  markers: [],
};
