export const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: "ghost",
  handle: ".handle",
  group: {
    name: "cloning-group-name",
    pull: true,
  },
};
