import React from "react";
import { Box, Typography, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { UilAngleRight } from "@iconscout/react-unicons";
import Iconify from "../../../components/_MUI/Iconify";
import useUnreadClientMessages from "../useUnreadClientMessages";

function ChatHeader(props) {
  const {
    isOpen,
    clientId,
    clientName,
    onClick,
    markUnreadConversation,
    markConversationDone,
  } = props;

  const { t } = useTranslation("chatWidget");
  const { palette } = useTheme();

  const { resetUnreadCount } = useUnreadClientMessages(clientId);

  const handleToggleOpen = e => {
    e.stopPropagation();
    resetUnreadCount();
    onClick();
  };

  const handleMarkUnreadConversation = e => {
    e.stopPropagation();
    markUnreadConversation();
  };

  const handleMarkConversationDone = e => {
    e.stopPropagation();
    markConversationDone();
  };

  return (
    <RootStyle>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          sx={{
            backgroundColor: palette.grey[300],
            borderRadius: 1,
          }}
          onClick={handleToggleOpen}>
          <UilAngleRight width={24} height={24} />
        </IconButton>
        <Stack sx={{ ml: 2 }} direction="row" gap={1}>
          <Typography variant="h5">{clientName}</Typography>
        </Stack>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {/*   Will be re-added when a proper conversation id is provided
      {isOpen && (
        <>
          <Tooltip title={t("sendField.markAsUnread")} placement="top">
            <IconButton onClick={handleMarkUnreadConversation}>
              <Iconify icon="uil:eye-slash" width={20} height={20} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("sendField.markAsDone")} placement="top">
            <IconButton onClick={handleMarkConversationDone}>
              <Iconify icon="uil:check-circle" width={20} height={20} />
            </IconButton>
          </Tooltip>
        </>
      )}
      */}
    </RootStyle>
  );
}

const RootStyle = styled("div")(() => ({
  height: 75,
  display: "flex",
  alignItems: "center",
  zIndex: 1001,
  borderRadius: "8px 8px 0 0",
  borderBottom: "1px solid #eee",
  padding: "0 16px",
  position: "relative",
}));

export default ChatHeader;
