import * as React from "react";
import {
  DialogContext,
  ModalContextForType,
  WorkoutPlanModalType,
} from "../DialogProvider";
import { gql, useQuery } from "@apollo/client";
import { ModifyWorkoutPlanInfo } from "./Form";

const WORKOUT_PLAN_QUERY = gql`
  query GetWorkoutPlanTemplate($id: Int!) {
    workoutPlan(id: $id) {
      id
      name
      description
      comment
      meta {
        id
        level
        location
        workoutsPerWeek
        gender
      }
    }
  }
`;

export default function ModifyWorkoutPlanInfoModalContainer() {
  const { modal, setModal } = React.useContext(DialogContext);
  const isOpen = modal.type === WorkoutPlanModalType.MODIFY_WORKOUT_PLAN_INFO;
  const context =
    modal?.context as ModalContextForType<WorkoutPlanModalType.MODIFY_WORKOUT_PLAN_INFO>;

  const handleClose = () => {
    setModal({ type: null });
  };
  if (!isOpen) return null;
  return (
    <ModifyWorkoutPlanInfoContainer
      workoutPlanId={context.id}
      handleClose={handleClose}
    />
  );
}

function ModifyWorkoutPlanInfoContainer({
  workoutPlanId,
  handleClose,
}: {
  workoutPlanId: number;
  handleClose: () => void;
}) {
  const { data, loading, error } = useQuery(WORKOUT_PLAN_QUERY, {
    variables: { id: Number(workoutPlanId) },
  });

  if (loading || error) return null;
  const plan = data?.workoutPlan;
  console.log({ plan });
  return (
    <ModifyWorkoutPlanInfo
      name={plan?.name}
      comment={plan?.comment}
      level={plan?.meta?.level}
      location={plan?.meta?.location}
      workoutsPerWeek={plan.meta?.workoutsPerWeek}
      gender={plan.meta?.gender}
      handleClose={handleClose}
    />
  );
}
