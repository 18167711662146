import React, { useState } from "react";
import { ListItemButton } from "@mui/material";
import {
  GetWorkoutPlanTemplateDocument,
  useMoveWorkoutMutation,
} from "../../../generated/graphql";
import { Collapse, List, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { gql, useQuery } from "@apollo/client";
import { WorkoutDayType } from "../../../generated/graphql";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { TranslatorKeyPair } from "../../../i18n/const";

const WORKOUT_PLAN_QUERY = gql`
  query GetWorkoutPlanTemplate($id: Int!) {
    workoutPlan(id: $id) {
      id
      days {
        id
        name
      }
    }
  }
`;

export default function MoveDropdown({
  workoutId,
  dayId,
  templateId,
}: {
  workoutId: number;
  dayId: number;
  templateId: number | string;
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [subNavOpen, setsubNavOpen] = useState(false);
  const [moveWorkout] = useMoveWorkoutMutation();

  const { data } = useQuery(WORKOUT_PLAN_QUERY, {
    variables: { id: Number(templateId) },
  });
  /**
   * Filter out the current day from the list of days to move to
   */
  const moveableDays =
    data?.workoutPlan?.days.filter((day: Partial<WorkoutDayType>) => day.id !== dayId) ||
    [];

  const onMoveWorkout = async (newDayId: number) => {
    try {
      if (!newDayId || !workoutId) return;
      // some update mutation
      await moveWorkout({
        variables: {
          input: {
            workoutId: workoutId,
            destinationWorkoutDayId: newDayId,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      enqueueSnackbar(t("plan.workout.moved.success", { ns: TranslatorKeyPair.plans }), {
        variant: "success",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("plan.workout.moved.error", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };

  /**
   * If there are no days to move to, don't render the dropdown
   */
  if (moveableDays?.length < 1) return null;

  return (
    <>
      <ListItemButton onClick={() => setsubNavOpen(!subNavOpen)}>
        <ListItemText primary={t("plan.moveToDay", { ns: TranslatorKeyPair.plans })} />
        {subNavOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={subNavOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {moveableDays?.map((day: WorkoutDayType) => (
            <ListItemButton sx={{ pl: 4 }} onClick={() => onMoveWorkout(day.id)}>
              <ListItemText primary={day.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}
