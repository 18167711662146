import { ReactNode } from "react";
import { VersionEnum } from "../generated/graphql";
import { connect } from "react-redux";
import { AppState } from "../store";
import { getVersionFormattedToSchema } from "../helpers/version";

interface Props {
  showOn: VersionEnum[];
  version: VersionEnum;
  children: ReactNode;
}

function VersionContainer(props: Props) {
  const { showOn, version, children } = props;

  if (!showOn.includes(version)) return null;

  return <>{children}</>;
}

const mapStateToProps = (state: AppState) => {
  return {
    version: getVersionFormattedToSchema(state.user.version),
  };
};

export default connect(mapStateToProps)(VersionContainer);
