import { DEFAULT_MESSAGE_TYPES } from "../../../constants";
import { AlertTitle, Button, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import { LoadingButton } from "@mui/lab";
import ActivateClientWrapper from "../../../../../components/ActivateClientWrapper";
import React from "react";

interface Props {
  loading: boolean;
  activateClient: () => void;
  cancel: () => void;
}

export default function ReadyToActivate(props: Props) {
  const { activateClient, cancel, loading } = props;
  const { t } = useTranslation(TranslatorNS.CHAT_WIDGET);

  return (
    <Alert severity={"success"} icon={false}>
      <AlertTitle sx={{ color: "success.darker" }}>{t("readyToActivate")}</AlertTitle>
      <Typography sx={{ color: "success.dark" }}>
        {t(DEFAULT_MESSAGE_TYPES["13"].subtitle)}
      </Typography>
      <Stack flexDirection={"row"} mt={1}>
        <ActivateClientWrapper>
          {({ disabled }) => (
            <LoadingButton
              color={"success"}
              loading={loading}
              disabled={disabled}
              onClick={activateClient}
              variant={"contained"}>
              {t("messages.yes")}
            </LoadingButton>
          )}
        </ActivateClientWrapper>
        <Button color={"success"} onClick={cancel} variant={"text"} sx={{ ml: 2 }}>
          {t("messages.notYet")}
        </Button>
      </Stack>
    </Alert>
  );
}
