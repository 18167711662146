import { useState } from "react";
import { GetClientGeneralQuery } from "../../../../generated/graphql";
import { Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  note: GetClientGeneralQuery["client"]["note"];
}

export default function Note(props: Props) {
  const { note } = props;
  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [expand, setExpand] = useState(false);
  const LINES = 4;

  if (!note) return null;

  return (
    <Stack
      px={0}
      pt={0}
      pb={2}
      sx={{ cursor: "pointer" }}
      title={t("newClientOverview.expand")}
      onClick={() => setExpand(!expand)}>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          ...(!expand && {
            WebkitLineClamp: LINES,
          }),
          WebkitBoxOrient: "vertical",
        }}
        fontSize={14}
        color={palette.grey[500]}>
        {note}
      </Typography>
    </Stack>
  );
}
