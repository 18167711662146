import moment from "moment";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "moment/locale/da";
import "moment/locale/en-gb";
import "moment/locale/fi";
import "moment/locale/se";
import "moment/locale/nb";
import "moment/locale/nl";
import "moment/locale/de";
import "moment/locale/sv";

import { TranslatorKeyPair } from "../const";
import { DEFAULT_LOCALE, Locales } from "../locales";
import { buildYupLocale } from "./buildYupLocale";
import { setDefaultOptions } from "date-fns";
import { da, de, enGB, fi, sv, nl, nb, enUS } from "date-fns/locale";
import { Locale } from "../../interfaces/locale";

const splitPlurals = (object: { [x: string]: any }) => {
  let newObject = {};
  Object.keys(object).forEach(key => {
    let elem = object[key];
    if (typeof elem === "object") {
      // @ts-ignore
      newObject[key] = splitPlurals(elem);
      return;
    }
    // replace all symfony parameters %param% with {{param}} in all strings for js
    elem = String(elem).replace(/%([^%]+(?=%))%/gi, "{{$1}}");

    // splits all plurals like "one apple|many apples" into different keys apple and apple_plural
    if (typeof elem === "string" && elem.includes("|")) {
      const plural = elem.split("|");
      // @ts-ignore
      newObject[key] = plural.shift();
      // @ts-ignore
      newObject[`${key}_plural`] = plural.shift();

      return;
    }
    // @ts-ignore
    newObject[key] = elem;
  });
  return newObject;
};

/*Get dynamically all translations from ./translations/* */
const resources = Object.keys(Locales).reduce((prev, locale) => {
  /*convert locale to 'en' because of file name*/
  const getLocale = locale === "en_US" || locale === "en_GB" ? "en" : locale;
  /*Initial object with locale key*/
  let singleLocal = { [locale]: {} };
  /*loop through translator keys and fetch files based on key and lang : object as result*/
  // Translator key pair needs to be updated if new keys is added
  singleLocal[locale] = Object.entries(TranslatorKeyPair).reduce((prev, current) => {
    const [getKey, getFileName] = current;
    const newItem = {
      [getKey]: splitPlurals(
        require(`../../translations/${getFileName}.${getLocale}.json`),
      ),
    };
    return { ...prev, ...newItem };
  }, {});

  return { ...prev, ...singleLocal };
}, {});

export const initTranslator = () => {
  i18n.use(initReactI18next).init(
    {
      resources,
      lng: DEFAULT_LOCALE,
      fallbackLng: DEFAULT_LOCALE,
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
      },
    },
    buildYupLocale,
  );

  type TransWindow = Window &
    typeof globalThis & {
      jsTrans: (key: string, base: string, params: any) => typeof i18n.t;
    };

  (window as TransWindow).jsTrans = (key: string, base: string, params: any) =>
    i18n.t(key, { ns: base, ...params });
};

export default i18n;

export function updateUserLanguage(locale: Locale) {
  const momentLocale = locale?.split("_")[0];
  moment.locale(momentLocale);
  i18n.changeLanguage(locale);
  // Rebuilds the YupLocale so the error messages change the language
  buildYupLocale(null, i18n.t);
  updateDateFnsLocale(locale);
}

export const updateDateFnsLocale = (locale: Locale) => {
  const lang = () => {
    switch (locale) {
      case Locale.da_DK:
        return da;
      case Locale.de_DE:
        return de;
      case Locale.en_GB:
        return enGB;
      case Locale.fi_FI:
        return fi;
      case Locale.nb_NO:
        return nb;
      case Locale.sv_SE:
        return sv;
      case Locale.nl_NL:
        return nl;
      default:
        return enUS;
    }
  };
  setDefaultOptions({ locale: lang() });
};
