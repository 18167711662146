export const FETCH_MEAL_TEMPLATE = "FETCH_MEAL_TEMPLATE";
export const FETCH_MEAL_TEMPLATE_FAILED = "FETCH_MEAL_TEMPLATE_FAILED";
export const FETCH_MEAL_TEMPLATE_SUCCESS = "FETCH_MEAL_TEMPLATE_SUCCESS";

export const FETCH_WORKOUT_TEMPLATE = "FETCH_WORKOUT_TEMPLATE";
export const FETCH_WORKOUT_TEMPLATE_FAILED = "FETCH_WORKOUT_TEMPLATE_FAILED";
export const FETCH_WORKOUT_TEMPLATE_SUCCESS = "FETCH_WORKOUT_TEMPLATE_SUCCESS";

export const FETCH_RECIPE_TEMPLATE = "FETCH_RECIPE_TEMPLATE";
export const FETCH_RECIPE_TEMPLATE_FAILED = "FETCH_RECIPE_TEMPLATE_FAILED";
export const FETCH_RECIPE_TEMPLATE_SUCCESS = "FETCH_RECIPE_TEMPLATE_SUCCESS";

export const RESET_STORE = "editors.RESET_STORE";
