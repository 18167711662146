import { useEffect, useState } from "react";
import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import { addDays, startOfDay } from "date-fns";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import { TRAINER_CHECKOUT } from "../../../../api/private/settings";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";

interface Props {
  subscriptionId: number;
}

export default function StripeRedirect(props: Props) {
  const { subscriptionId } = props;
  const { getStartedData } = useLenusTransferContext();
  const [countDown, setCountDown] = useState(3);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.AUTH);

  const startDate = getStartedData.startDate;

  const getStripeCheckoutUrl = async () => {
    try {
      const baseUrl = window.location.origin;
      const after_success_redirect_url = `${baseUrl}/lenus/transfer/start/success`;
      const trial_end = addDays(startOfDay(startDate), 31).getTime() / 1000;

      const payload = {
        after_success_redirect_url,
        trial_end,
      };

      const { data } = await axios.post(TRAINER_CHECKOUT(subscriptionId), payload);

      window.location.assign(data.url);
    } catch (e: any) {
      console.error(e?.response?.data?.message);
      enqueueSnackbar("Something went wrong when redirecting to stripe", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountDown(countDown - 1);
    }, 1000);

    if (countDown <= 0) {
      timer && clearTimeout(timer);
      getStripeCheckoutUrl();
    }
  }, [countDown]);

  return (
    <Stack
      height={{ xs: "82vh", sm: "100%" }}
      width={"100%"}
      justifyContent={"center"}
      alignItems={"center"}>
      <Typography color={"white"} fontSize={"60px"}>
        {countDown || <CircularProgress size={60} sx={{ color: "white" }} />}
      </Typography>
      <Typography color={"white"} fontSize={32}>
        {t("transfer.redirectStripe")}
      </Typography>
    </Stack>
  );
}
