import { format } from "date-fns";
import { Tooltip, Typography } from "@mui/material";
import { TFlattenedWorkout } from "../index";

interface Props {
  savedWorkout: TFlattenedWorkout;
}

export default function SavedWorkoutDay(props: Props) {
  const { savedWorkout } = props;

  if (!savedWorkout?.date) {
    return <Typography>-</Typography>;
  }

  return (
    <Tooltip disableInteractive title={format(new Date(savedWorkout.date), "dd/MM/yyyy")}>
      <Typography sx={{ textTransform: "capitalize", cursor: "default" }}>
        {format(new Date(savedWorkout?.date), "EEEE")}
      </Typography>
    </Tooltip>
  );
}
