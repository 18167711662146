import React, { useEffect, useRef, useState } from "react";
import { getFlagEmojiFromCountryCode } from "./util";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { LangPlacement, ZFLangSwitchStyled } from "./ZFLangSwitchStyled";
import { DEFAULT_LOCALE, Locales } from "../../i18n/locales";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";
import { LocaleEnum } from "../../generated/graphql";

interface ZFLangSwitchProps {
  changeLang: (locale: LocaleEnum) => void;
  chosenLang: LocaleEnum;
  placement: LangPlacement;
}

export default function ZFLangSwitch(props: ZFLangSwitchProps) {
  const { t } = useTranslation(TranslatorNS.SHARED);
  const { changeLang, chosenLang, placement = LangPlacement.RIGHT } = props;

  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  const handleChosenLang = (newLang: LocaleEnum) => {
    changeLang(newLang);
    setOpen(!open);
  };

  // TODO: useEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickOutside = (event: { target: any }) => {
    // @ts-ignore
    if (wrapperRef && !wrapperRef.current.contains(event.target) && open) {
      setOpen(!open);
    }
  };

  const LangList = () => {
    return (
      <div className="langList">
        {Object.entries(Locales).map(([langCode, langName], index) => {
          return (
            <div
              key={index}
              onClick={() => handleChosenLang(langCode as LocaleEnum)}
              className={`langItem ${chosenLang === langCode ? "active" : ""}`}>
              <span className={"flag"}>
                {getFlagEmojiFromCountryCode(langCode as LocaleEnum)}
              </span>
              <span>{t(langName)}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ZFLangSwitchStyled
      placement={placement}
      className={`removeHighLight `}
      ref={wrapperRef}>
      <div className={`langBtn ${open && "active"}`} onClick={() => setOpen(!open)}>
        <span className={"flag"}>
          {Locales[chosenLang]
            ? getFlagEmojiFromCountryCode(chosenLang)
            : getFlagEmojiFromCountryCode(LocaleEnum.EnUs)}
        </span>
        <span className={`flagName ${open && "active"}`}>
          {t(Locales[chosenLang] ? Locales[chosenLang] : Locales[DEFAULT_LOCALE])}
        </span>
        {!open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </div>
      {open && <LangList />}
    </ZFLangSwitchStyled>
  );
}
