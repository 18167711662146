import { Stack, Typography } from "@mui/material";
import {
  MealPlanTemplateOrderByFieldEnum,
  SortingDirectionEnum,
  useGetMealTemplatesQuery,
} from "../../../../generated/graphql";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { columns } from "./config";
import Search from "./Search";
import { useEffect, useState } from "react";
import { TEMPLATES_LIMIT } from "../../const";
import useResponsive, {
  BreakPointEnum,
  QueryEnum,
} from "../../../../hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

export const defaultOrderByObject = {
  field: MealPlanTemplateOrderByFieldEnum.Id,
  sort: SortingDirectionEnum.Desc,
};

export default function TableContainer() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);
  const { t } = useTranslation(TranslatorNS.LISTS);

  const { data, loading, fetchMore } = useGetMealTemplatesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      limit: TEMPLATES_LIMIT,
      filters: {
        search: search || undefined,
      },
      orderBy: defaultOrderByObject,
    },
  });

  useEffect(() => {
    setPage(1);
  }, [search]);

  const mealPlanTemplates = data?.mealPlanTemplates;

  const loadMore = async () => {
    try {
      const hasMore =
        !!mealPlanTemplates?.data &&
        Number(mealPlanTemplates.data?.length) < Number(mealPlanTemplates?.total);

      if (!hasMore) return;

      const newPage = page + 1;

      await fetchMore({
        variables: {
          page: newPage,
        },
      });
      setPage(newPage);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack sx={{ height: "100%", mb: "-84px" }}>
      <Stack
        flexDirection={{ xs: "column-reverse", sm: "row" }}
        justifyContent={"space-between"}
        alignItems={{ xs: "flex-start", sm: "center" }}>
        <Search search={search} setSearch={setSearch} />
        <Typography variant={"subtitle2"} sx={{ mb: 1 }}>
          {t("meals.main.total", { total: data?.mealPlanTemplates?.total || 0 })}
        </Typography>
      </Stack>
      <Stack mt={2} height={"100%"}>
        <DataGridPro
          columns={columns()}
          rows={mealPlanTemplates?.data || []}
          rowCount={mealPlanTemplates?.total || 0}
          loading={loading}
          autoHeight={false}
          scrollEndThreshold={300}
          sortModel={[
            {
              field: "id",
              sort: "desc",
            },
          ]}
          rowHeight={56}
          onRowsScrollEnd={loadMore}
          columnVisibilityModel={{
            id: false,
            description: Boolean(isDesktop),
            numberOfMeals: Boolean(isDesktop),
          }}
        />
      </Stack>
    </Stack>
  );
}
