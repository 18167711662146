import { setLocale } from 'yup';
import { TranslatorNS } from '../const';
import { TOptions, TFunction } from 'i18next';

export const buildYupLocale = (_: unknown, i18nTranslator: TFunction): void => {
  const t = (key: string, options?: TOptions) => i18nTranslator(key, { ns: TranslatorNS.VALIDATIONS, ...options })

  // For more check: https://github.com/jquense/yup/blob/master/src/locale.ts
  setLocale({
    mixed: {
      required: t("mixed.required"),
      default: t("mixed.default"),
      notType: t('mixed.default')
    },
    string: {
      length: ({ length }) => t("string.length", { length }),
      min: ({ min }) => t("string.min", { min }),
      max: ({ max }) => t("string.max", { max }),
      email: t("string.email"),
      url: t("string.url"),
    },
    number: {
      min: ({ min }) => t("number.min", { min }),
      max: ({ max }) => t("number.max", { max }),
      lessThan: ({ less }) => t("number.less", { less }),
      moreThan: ({ more }) => t("number.moreThan", { more }),
      positive: t("number.positive"),
      negative: t("number.negative"),
      integer: t("number.integer"),
    },
    date: {
      min: ({ min }) => t("date.min", { min }),
      max: ({ max }) => t("date.max", { max }),
    },
    array: {
      min: ({ min }) => t("date.min", { min }),
      max: ({ max }) => t("date.max", { max }),
      length: ({ length }) => t("date.length", { length }),
    }
  });
}
