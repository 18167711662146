import {
  ClientPaymentConfigurationStatusEnum,
  GetClientModalSubscriptionQuery,
} from "../../../../generated/graphql";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  paymentConfiguration: GetClientModalSubscriptionQuery["client"]["paymentConfiguration"];
}

export default function SubscriptionStatus(props: Props) {
  const { paymentConfiguration } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);

  const isActive =
    paymentConfiguration?.status === ClientPaymentConfigurationStatusEnum.Active;

  return (
    <Stack sx={{ mb: 2 }}>
      <Typography>
        {isActive
          ? t("subscriptionStatus.youHaveAnExistingSub")
          : t("subscriptionStatus.setupAutomaticSub")}
      </Typography>
    </Stack>
  );
}
