import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import ExercisesList from "./Exercises/ExercisesList";
import PlanEditor from "./PlanEditor";
import VideoDialog from "./Dialog/VideoDialog";
import AddDayDialog from "./Dialog/AddDayDialog";
import TemplateDialog from "./Dialog/TemplateDialog";
import DialogProvider from "./Dialog/DialogProvider";
import ModifyDayDialog from "./Dialog/ModifyDayDialog";
import ModifyWorkoutPlanDescription from "./Dialog/ModifyWorkoutPlanInfo";
import { PlanId } from "../Editors/types";
import Editors from "../Editors";
import SaveAsTemplateDialog from "./Dialog/SaveAsTemplate";
import VersionContainer from "../../containers/VersionContainer";
import { VersionEnum } from "../../generated/graphql";
import WorkoutDays from "../WorkoutMobile/workout-days";
import { IS_MOBILE } from "../../helpers/devices";

export default function WorkoutEditor() {
  return (
    <>
      <VersionContainer showOn={[VersionEnum.Experimental, VersionEnum.Stable]}>
        {IS_MOBILE && (
          <Stack height={"100%"}>
            <WorkoutDays />
          </Stack>
        )}
        {!IS_MOBILE && (
          <DialogProvider>
            {/* HACK: style to reset page padding */}
            <Stack direction="row" sx={{ mr: -2, mt: -3 }}>
              {/* make sure width bounds to 300 */}
              <Box sx={{ minWidth: 300, maxWidth: 300, mt: 2 }}>
                <ExercisesList />
              </Box>
              <Box sx={{ mr: 2 }} />
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  overflowX: "scroll",
                }}>
                <PlanEditor />
              </Box>
              <VideoDialog />
              <AddDayDialog />
              <ModifyWorkoutPlanDescription />
              <ModifyDayDialog />
              <TemplateDialog />
              <SaveAsTemplateDialog />
            </Stack>
          </DialogProvider>
        )}
      </VersionContainer>
      <VersionContainer showOn={[VersionEnum.Legacy]}>
        <Editors type={PlanId.WORKOUT} />;
      </VersionContainer>
    </>
  );
}
