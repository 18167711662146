import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TranslatorNS } from "../../../i18n/const";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider } from "../../../components/_MUI/hook-form";
import RHFTextField from "../../../components/_MUI/hook-form/RHFTextField";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { DialogContext, WorkoutPlanModalType } from "./DialogProvider";
import { useSaveWorkoutPlanAsTemplateMutation } from "../../../generated/graphql";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const saveAsTemplate = () =>
  Yup.object().shape({
    title: Yup.string().required(),
    comment: Yup.string(),
  });

interface FormValuesProps {
  title: string;
  comment: string;
}

export default function SaveAsTemplateDialog() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { modal, setModal } = React.useContext(DialogContext);
  const { templateId } = useParams<{ templateId: string }>();
  const { t } = useTranslation(TranslatorNS.WORKOUT_PLAN);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [saveWorkoutPlanAsTemplate] = useSaveWorkoutPlanAsTemplateMutation();
  const defaultValues = {
    title: "",
    comment: "",
  };

  const isOpen = modal.type === WorkoutPlanModalType.SAVE_AS_TEMPLATE;

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(saveAsTemplate()),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleClose = () => {
    setModal({ type: null });
  };

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!templateId) return;
      await saveWorkoutPlanAsTemplate({
        variables: {
          id: Number(templateId),
          input: {
            name: data.title,
            comment: data.comment,
          },
        },
      });
      enqueueSnackbar(t("saveAsTemplate.workoutPlanWas"), { variant: "success" });
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("saveAsTemplate.cannotSaveWorkoutPlan"), { variant: "error" });
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
          <CloseOutlinedIcon />
        </IconButton>
        <DialogTitle>{t("saveAsTemplate.saveWorkoutPlan")}</DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, pb: 2 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <RHFTextField name="title" label={t("saveAsTemplate.nameOfWorkoutPlan")} />
              <RHFTextField name="comment" label={t("saveAsTemplate.enterYourComment")} />
              <Stack sx={{ pt: 2 }} justifyContent="center">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  size="large">
                  {t("saveAsTemplate.save")}
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
