import React, { useEffect, useState } from "react";
import ListSortable from "../../list-sortable";
import ZFLoader from "../../../../components/Loader";
import axios from "../../../../helpers/axios";
import { Routes } from "../../../routeMap";
import {
  ADD_DAY,
  GET_WORKOUT_PLAN,
  GET_WORKOUT_PLAN_DAYS,
  SAVE_WORKOUT_PLAN,
} from "../../../../api/private/workout-api";
import { useNavigate, useParams } from "react-router-dom";
import { prepareRoute } from "../../../../helpers/routing";

import WorkoutsHeader from "../../workout-day/components/new-workouts-header";
import { Stack } from "@mui/material";

const NewDaysList = () => {
  const commentLength = 120;
  const [planName, setPlanName] = useState("");
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { templateId, clientId } = useParams();
  const navigate = useNavigate();

  const handleClick = (dayId: string) => {
    if (dayId) {
      let url;

      if (clientId) {
        url = prepareRoute(Routes.CLIENT_WORKOUT_DAY, {
          clientId,
          templateId,
          dayId,
        });
      } else {
        url = prepareRoute(Routes.WORKOUTS_DAY, {
          templateId,
          dayId,
        });
      }

      return navigate(url);
    }
  };

  const handleUpdate = (evt: any, items: any) => {
    const data = {
      results: items.map((item: { id: any; comment: any }, i: number) => {
        return {
          day_id: item.id,
          workoutDayComment: item.comment,
          order: ++i,
        };
      }),
    };

    const url = SAVE_WORKOUT_PLAN(Number(templateId));
    axios.post(url, data);
  };

  useEffect(() => {
    fetchDays();
  }, []);

  const fetchDays = () => {
    axios.get(GET_WORKOUT_PLAN(Number(templateId))).then(({ data }) => {
      if (data) {
        setPlanName(data.name);
      }
    });

    const url = GET_WORKOUT_PLAN_DAYS(Number(templateId));
    axios.get(url).then(({ data }) => {
      setIsLoading(false);
      setItems(data);
    });
  };

  const handleAddDay = () => {
    setIsLoading(true);
    const dayLength = items2.length;
    const data = new FormData();
    data.append("name", `Day ${dayLength + 1}`);
    axios.post(ADD_DAY(Number(templateId)), data).then(() => {
      fetchDays();
    });
  };

  let items2 = items.map(
    (item: {
      comment: string;
      id: any;
      name:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    }) => {
      const comment =
        item.comment && item.comment.length > commentLength
          ? item.comment.substring(0, commentLength) + "..."
          : item.comment;

      return {
        id: item.id,
        comment: item.comment,
        elem: (
          <div className="workout-days-item" onClick={() => handleClick(item.id)}>
            <div className="workout-days-text">
              <h4>{item.name}</h4>
              {comment && <p>{comment}</p>}
            </div>
            <div>
              <a href="#" className="view-link">
                <i className="fa fa-angle-right" aria-hidden="true" />
              </a>
            </div>
          </div>
        ),
      };
    },
  );

  return (
    <Stack height={"100%"}>
      {isLoading ? (
        <div className="workout-days-loader-container">
          <ZFLoader />
        </div>
      ) : (
        <div className="workout-days-container-block">
          <WorkoutsHeader workoutPlanName={planName} />
          <ListSortable
            items={items2}
            id={templateId}
            onUpdate={handleUpdate.bind(this)}
          />
          <div className="add-day-btn">
            <button onClick={handleAddDay.bind(this)}>+ Add workout day</button>
          </div>
        </div>
      )}
    </Stack>
  );
};

export default NewDaysList;
