import React from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import { RHFTextField } from "../../../../_MUI/hook-form";
import { TClientSettingsForm } from "../ClientSettingsForm";
import { useFormContext } from "react-hook-form";

interface Props {
  customQuestions: TClientSettingsForm["customQuestions"];
}

export default function AdditionalFields(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { customQuestions } = props;
  const { watch } = useFormContext();
  const trainerCustomQuestions = watch("trainerCustomQuestions");

  return (
    <Stack mb={3}>
      <Typography variant={"subtitle2"} mb={2}>
        {t("info.clientFields.additionalInfo")}
      </Typography>
      <RHFTextField
        name="motivation"
        label={t("const.clientInfoFields.motivation")}
        multiline
        minRows={3}
      />
      <RHFTextField
        name="lifestyle"
        label={t("const.clientInfoFields.lifestyle")}
        multiline
        minRows={3}
      />
      <RHFTextField
        name="other"
        label={t("const.clientInfoFields.other")}
        multiline
        minRows={3}
      />
      {trainerCustomQuestions &&
        Object.keys(customQuestions).map(q => {
          const label = `${trainerCustomQuestions[q]?.question} (custom) `;
          return (
            <RHFTextField
              key={q}
              name={"customQuestions." + q}
              label={label}
              multiline
              minRows={3}
            />
          );
        })}
    </Stack>
  );
}
