import { CHANGE_SAVE_STATUS, GET_SETTINGS, SAVE_SETTING, VALIDATE_VAT } from "./types";
import produce from "immer";
import i18n from "i18next";

const INITIAL_STATE = {
  settings: {},
  saveStatus: "save",
  validateLoading: false,
  loading: true,
  vatError: "",
  vatSuccess: false,
  plans: [],
};

export default function Reducer(state = INITIAL_STATE, { type, payload }: any) {
  switch (type) {
    case SAVE_SETTING:
      return produce(state, draftState => {
        draftState.settings = payload;
        i18n.changeLanguage(payload.language);
      });
    case CHANGE_SAVE_STATUS:
      return { ...state, saveStatus: payload };
    case VALIDATE_VAT.REQUEST:
      return { ...state, validateLoading: true, vatError: "", vatSuccess: false };
    case VALIDATE_VAT.SUCCESS:
      return { ...state, validateLoading: false, vatSuccess: true, vatError: "" };
    case VALIDATE_VAT.FAILURE:
      return { ...state, validateLoading: false, vatError: payload };
    case GET_SETTINGS.REQUEST:
      return { ...state, loading: true };
    case GET_SETTINGS.SUCCESS:
      return {
        ...state,
        plans: payload.plans,
        settings: payload.settings,
        loading: false,
      };
    case GET_SETTINGS.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}
