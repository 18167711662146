import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { DialogContent, Typography } from "@mui/material";
import { SlideTransition } from "../SlideTransition";
import React from "react";
import { LoadingButton } from "@mui/lab";

interface Props {
  open: boolean;
  handleClose: VoidFunction;
  onConfirm: VoidFunction;
  title: string;
  description?: string;
  actionTxt?: string;
  isSubmitting: boolean;
}

export default function SimpleConfirmModal({
  open,
  handleClose,
  onConfirm,
  title,
  description,
  actionTxt,
  isSubmitting,
}: Props) {
  const { t } = useTranslation("clientOverview");

  return (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <Typography variant="body1">{description}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button color={"inherit"} disabled={isSubmitting} onClick={handleClose}>
          {t("confirmNeed.cancel")}
        </Button>
        <LoadingButton
          color={"error"}
          loading={isSubmitting}
          variant="contained"
          onClick={onConfirm}>
          {actionTxt || t("confirmNeed.yes")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
