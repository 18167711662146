import { Action, Middleware, MiddlewareAPI } from "redux";

const crashReporter: Middleware =
  () =>
  next =>
  <A extends Action>(action: A) => {
    try {
      return next(action);
    } catch (err) {
      console.error("Caught an exception!", err);
      throw err;
    }
  };

export default crashReporter;
