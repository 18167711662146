import { combineReducers } from "redux";
import { bottom } from "../bottom/store";
import { top } from "../top/store";

const clientProgress = combineReducers({
  bottom,
  top,
});

export default clientProgress;
