import moment from "moment";
import { intervalToDuration } from "date-fns";

// don't know all these types
// TODO: should be fixed (typed)
interface TimeArray {
  id: any;
  day: string;
  tooltip: any;
  date: Date;
  weight: any;
  kcal: any;
}
type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
/**
 * Very specific function to be in shared and only used once
 * @param timeArray
 * @param periodInMonths
 * @param timeKey
 * @returns
 */
export const filterDefaultPeriods = (
  timeArray: TimeArray[],
  periodInMonths: Month,
  timeKey: "date",
) => {
  const periodToSubstract = moment().subtract(
    // cast to string
    parseInt("" + periodInMonths),
    "months",
  );
  const returnedArr =
    periodInMonths === 12
      ? timeArray
      : timeArray.filter(item =>
          moment(item[timeKey]).isBetween(periodToSubstract, moment()),
        );
  return returnedArr;
};

export const getHourDurationFromSeconds = (time: number | undefined): string => {
  const seconds = Number(time || 0);
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const zeroPad = (num: number) => String(num).padStart(2, "0");

  const totalHours = (duration.hours || 0) + (duration.days || 0) * 24;

  return [totalHours, duration.minutes || 0, duration.seconds || 0]
    .map(zeroPad)
    .join(":");
};
