import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TranslatorKeyPair, TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider } from "../../../../components/_MUI/hook-form";
import RHFTextField from "../../../../components/_MUI/hook-form/RHFTextField";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import RHFSelect from "../../../../components/_MUI/hook-form/RHFSelect";
import MenuItem from "@mui/material/MenuItem";
import {
  GenderEnum,
  GetWorkoutPlanTemplateDocument,
  useUpdateWorkoutPlanMutation,
  WorkoutExperienceLevelEnum,
  WorkoutLocationEnum,
} from "../../../../generated/graphql";

import { WORKOUT_PER_WEEK } from "../../../ClientActivation/const";
import { isNil, omitBy } from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const addDaySchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    comment: Yup.string(),
  });

interface FormValuesProps {
  name: string;
  comment: string;
  level: WorkoutExperienceLevelEnum | null;
  location: WorkoutLocationEnum | null;
  workoutsPerWeek: number | null;
  gender: GenderEnum | null;
}

interface ModifyWorkoutPlanInfoProps {
  name: string;
  comment: string;
  level: WorkoutExperienceLevelEnum;
  location: WorkoutLocationEnum;
  workoutsPerWeek: number;
  gender: GenderEnum;
  handleClose: () => void;
}

export function ModifyWorkoutPlanInfo({
  name,
  comment,
  level,
  location,
  workoutsPerWeek,
  gender,
  handleClose,
}: ModifyWorkoutPlanInfoProps) {
  const theme = useTheme();

  const { templateId } = useParams<{ templateId: string }>();
  const { t } = useTranslation(TranslatorNS.PLANS);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [updateWorkoutPlan] = useUpdateWorkoutPlanMutation();

  const defaultValues = {
    name: name || "",
    comment: comment || "",
    level: level,
    location: location,
    workoutsPerWeek: workoutsPerWeek,
    gender: gender,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(addDaySchema()),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!templateId) return;
      const payload = omitBy(
        {
          name: data.name,
          comment: data.comment || "",
          level: data.level || null,
          location: data.location || null,
          workoutsPerWeek: Number(data.workoutsPerWeek) || null,
          gender: data.gender || null,
        },
        isNil,
      );
      await updateWorkoutPlan({
        variables: {
          id: Number(templateId),
          input: payload,
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={true}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
          <CloseOutlinedIcon />
        </IconButton>
        <DialogTitle>
          {t("index.editWorkoutPlan", {
            ns: TranslatorKeyPair.plans,
          })}
        </DialogTitle>
        <DialogContent>
          <Stack sx={{ pt: 2, pb: 2 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <RHFTextField
                name="name"
                label={t("index.workoutPlanTitle", {
                  ns: TranslatorKeyPair.plans,
                })}
              />
              <RHFTextField
                name="comment"
                multiline
                minRows={4}
                maxRows={20}
                label={t("index.workoutPlanDescription", {
                  ns: TranslatorKeyPair.plans,
                })}
              />
              <Accordion style={{ boxShadow: "none", padding: 0 }}>
                <AccordionSummary
                  style={{ padding: 0, marginLeft: 8, marginRight: 8 }}
                  expandIcon={<ExpandMoreIcon sx={{ color: "primary.main" }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Typography color="primary.main">
                    {t("index.advancedSettings")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails style={{ padding: 0 }}>
                  <>
                    <RHFSelect
                      fullWidth
                      label={t("modalWorkoutTemplates.workoutPerWeek", {
                        ns: TranslatorKeyPair.clientOverview,
                      })}
                      name={"workoutsPerWeek"}>
                      {Object.entries(WORKOUT_PER_WEEK).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      fullWidth
                      label={t("const.clientInfoFields.workoutExperienceLevel", {
                        ns: TranslatorKeyPair.clientOverview,
                      })}
                      name={"level"}>
                      {Object.values(WorkoutExperienceLevelEnum).map(value => (
                        <MenuItem key={value} value={value}>
                          {t(
                            `newClientOverview.generalCard.workoutExperienceLevel.${value.toLowerCase()}`,
                            {
                              ns: TranslatorKeyPair.clientOverview,
                            },
                          )}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      fullWidth
                      label={t("modalWorkoutTemplates.location", {
                        ns: TranslatorKeyPair.clientOverview,
                      })}
                      name={"location"}>
                      {Object.values(WorkoutLocationEnum).map(value => (
                        <MenuItem key={value} value={value}>
                          {t(`const.clientWorkoutMetas.location.${value.toLowerCase()}`, {
                            ns: TranslatorKeyPair.clientOverview,
                          })}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                    <RHFSelect
                      fullWidth
                      label={t("modalWorkoutTemplates.gender", {
                        ns: TranslatorKeyPair.clientOverview,
                      })}
                      name={"gender"}>
                      <MenuItem key={GenderEnum.Female} value={GenderEnum.None}>
                        {t("const.clientWorkoutMetas.gender.undefined", {
                          ns: TranslatorKeyPair.clientOverview,
                        })}
                      </MenuItem>
                      <MenuItem key={GenderEnum.Female} value={GenderEnum.Female}>
                        {t("const.gender.female", {
                          ns: TranslatorKeyPair.clientOverview,
                        })}
                      </MenuItem>
                      <MenuItem key={GenderEnum.Male} value={GenderEnum.Male}>
                        {t("const.gender.male", {
                          ns: TranslatorKeyPair.clientOverview,
                        })}
                      </MenuItem>
                    </RHFSelect>
                  </>
                </AccordionDetails>
              </Accordion>
              <Stack sx={{ pt: 2 }} justifyContent="center">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  size="large">
                  {t("index.workoutPlanSave")}
                </LoadingButton>
              </Stack>
            </FormProvider>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
