export const getSubscriptionPlanFeatures = (planIndex: number) => {
  switch (planIndex) {
    case 0:
      return {
        image: require("../../assets/1.png"),
        features: {
          2: "pricingTiers.starter.intelligentMeal",
          3: "pricingTiers.starter.workoutBuild",
          4: "pricingTiers.starter.textVoice",
          5: "pricingTiers.starter.autoCharge",
          6: "pricingTiers.starter.customBrand",
          7: "pricingTiers.starter.dripLesson",
          9: "pricingTiers.starter.onlineSupport",
        },
      };
    case 1:
      return {
        image: require("../../assets/2.png"),
        features: {
          2: "pricingTiers.growth.fastTrack",
          3: "pricingTiers.growth.contactForm",
          4: "pricingTiers.growth.salesCRM",
          5: "pricingTiers.growth.zenfitTeams",
        },
      };
    default:
      return {
        image: require("../../assets/3.png"),
        features: {
          2: "pricingTiers.growth.fastTrack",
          3: "pricingTiers.growth.contactForm",
          4: "pricingTiers.growth.salesCRM",
          5: "pricingTiers.growth.zenfitTeams",
        },
      };
  }
};
