import React, { useState } from "react";
import { TextField } from "@mui/material";
import {
  GetRecipeDocument,
  LocaleEnum,
  RecipeType,
  useUpdateRecipeMutation,
} from "../../../generated/graphql";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { TranslatorNS } from "../../../i18n/const";
import { useTranslation } from "react-i18next";

export default function InstructionsField(props: {
  instructions: RecipeType["instructions"];
  locale: LocaleEnum;
}) {
  const { templateId } = useParams<{ templateId: string }>();
  const { t } = useTranslation([TranslatorNS.PLANS]);
  const [instructions, setInstructions] = useState(props.instructions);
  const { enqueueSnackbar } = useSnackbar();
  const [updateRecipe] = useUpdateRecipeMutation();
  const handleSave = async () => {
    if (!templateId) return;
    await updateRecipe({
      variables: {
        id: Number(templateId),
        input: {
          instructions: instructions,
          locale: props.locale,
        },
      },
      refetchQueries: () => [
        {
          query: GetRecipeDocument,
          variables: { id: Number(templateId), locale: props.locale },
        },
      ],
    });
    enqueueSnackbar(t("plan.instructionsUpdated"), { variant: "success" });
  };
  return (
    <Stack>
      <TextField
        label={t("plan.instructions")}
        value={instructions}
        onChange={e => setInstructions(e.target.value)}
        onBlur={handleSave}
        multiline
        fullWidth
      />
    </Stack>
  );
}
