import React, { Fragment } from "react";
import Webcam from "react-webcam";
import { RecorderStatusEnum } from "../../type";
import { AUDIO_CONSTRAINT, VIDEO_STREAM_CONSTRAINT } from "../../config";
import VideoError from "../VideoError";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../../i18n/const";

interface Props {
  webcamRef: React.Ref<any>;
  status: RecorderStatusEnum;
  previewUrl: string;
  handlePermission: () => void;
}

const VideoPreview = (props: Props) => {
  const { t } = useTranslation(TranslatorNS.CHAT);
  const { webcamRef, status, previewUrl, handlePermission } = props;
  const ReadyToSend = status === RecorderStatusEnum.READY_TO_SEND;
  const isSafariBrowser = navigator.vendor && navigator.vendor.indexOf("Apple") > -1;

  return (
    <Fragment>
      {status !== RecorderStatusEnum.ERROR && <div className="darkOverlay" />}
      {isSafariBrowser && RecorderStatusEnum.READY_TO_SEND === status ? (
        <Stack
          bgcolor={"black"}
          height={300}
          width={400}
          justifyContent={"center"}
          alignItems={"center"}>
          <Typography
            sx={{ mb: "-100px" }}
            color={"white"}
            fontSize={"16"}
            fontWeight={"bold"}>
            {t("captureVideo.clickBelow")}
          </Typography>
        </Stack>
      ) : (
        <video
          style={{ display: ReadyToSend ? "flex" : "none" }}
          src={previewUrl}
          className={"videoPreview"}
          autoPlay
          loop={true}
        />
      )}
      {status === RecorderStatusEnum.ERROR ? (
        <VideoError />
      ) : (
        <Webcam
          style={{ display: !ReadyToSend ? "flex" : "none" }}
          videoConstraints={VIDEO_STREAM_CONSTRAINT}
          audioConstraints={AUDIO_CONSTRAINT}
          onUserMedia={() => handlePermission()}
          onUserMediaError={() => handlePermission()}
          mirrored={true}
          audio={true}
          muted={true}
          ref={webcamRef}
        />
      )}
    </Fragment>
  );
};

export default VideoPreview;
