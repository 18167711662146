import { ReactNode, useEffect, useState } from "react";
import { Button, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import PaidTwoToneIcon from "@mui/icons-material/PaidTwoTone";
import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { connect } from "react-redux";
import { ignoreClientTaskActionGraphqlCallback } from "../../../../store/currentClient/action";
import { TaskStatus } from "../../../../store/currentClient/types";
import { useGetClientStatusesQuery } from "../../../../generated/graphql";
import { useParams } from "react-router-dom";
import Iconify from "../../../../components/_MUI/Iconify";
import i18next from "i18next";

type pendingType = "payment" | "questionnaire" | "invite";

interface Props {
  type: pendingType;
  link: string;
  ignoreClientTaskActionGraphqlCallback: (
    id: TaskStatus["id"],
    refetch: () => void,
  ) => void;
  taskId?: number;
}

function PendingCard(props: Props) {
  const { clientId } = useParams();
  const { type, link, ignoreClientTaskActionGraphqlCallback, taskId } = props;
  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [copied, setCopied] = useState(false);
  const { refetch } = useGetClientStatusesQuery({
    variables: {
      id: Number(clientId),
    },
  });

  useEffect(() => {
    setTimeout(() => {
      copied && setCopied(false);
    }, 2000);
  }, [copied]);

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  const { title, body, icon, text, textCopied } = getContent(type);

  const updateTask = async () => {
    if (!taskId) return;
    ignoreClientTaskActionGraphqlCallback(taskId, () => refetch());
  };

  return (
    <Stack height={"100%"}>
      <Stack justifyContent={"center"} textAlign={"center"} height={"100%"} pb={5}>
        <Stack flexDirection={"row"} justifyContent={"center"}>
          {icon}
        </Stack>
        <Stack mt={5} maxWidth={300} mx={"auto"}>
          <Typography mb={1} variant={"h6"}>
            {title}
          </Typography>
          <Typography color={"text.secondary"}>{body}</Typography>
        </Stack>
        <Stack mt={5} alignItems={"center"} justifyContent={"center"}>
          <Tooltip title={link}>
            <Button sx={{ minWidth: "60%" }} onClick={copyLink} variant={"contained"}>
              {copied ? textCopied : text}
            </Button>
          </Tooltip>
          {taskId && (
            <Button
              onClick={() => updateTask()}
              sx={{ minWidth: "60%", mt: 1, color: palette.grey[500] }}
              startIcon={<Iconify icon={"uil:bell-slash"} />}
              variant={"text"}
              color={"inherit"}>
              {t("newClientOverview.ignoreTask")}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

const getContent = (
  type: pendingType,
): {
  title: string;
  body: string;
  icon: ReactNode;
  text: string;
  textCopied: string;
} => {
  const fontSize = "48px !important";
  const t = (key: string) => i18next.t(key, { ns: TranslatorNS.CLIENT_OVERVIEW });
  switch (type) {
    case "payment":
      return {
        title: t("newClientOverview.generalCard.pending.payment.title"),
        body: t("newClientOverview.generalCard.pending.payment.body"),
        icon: <PaidTwoToneIcon sx={{ fontSize, color: "success.dark" }} />,
        text: t("newClientOverview.copyPaymentLink"),
        textCopied: t("newClientOverview.copiedPaymentLink"),
      };
    case "invite":
      return {
        title: t("newClientOverview.generalCard.pending.invite.title"),
        body: t("newClientOverview.generalCard.pending.invite.body"),
        icon: <ArticleTwoToneIcon sx={{ fontSize, color: "secondary.main" }} />,
        text: t("newClientOverview.copyInviteLink"),
        textCopied: t("newClientOverview.copiedInviteLink"),
      };
    case "questionnaire":
      return {
        title: t("newClientOverview.generalCard.pending.questionnaire.title"),
        body: t("newClientOverview.generalCard.pending.questionnaire.body"),
        icon: <EmailTwoToneIcon sx={{ fontSize, color: "info.main" }} />,
        text: t("newClientOverview.copyQuestionnaireLink"),
        textCopied: t("newClientOverview.copiedQuestionnaireLink"),
      };
  }
};

export default connect(undefined, { ignoreClientTaskActionGraphqlCallback })(PendingCard);
