import { error, FETCH_DATA, SET_CLIENT_ID, success } from "./types";

const INITIAL_STATE = {
    data: {},
    mapping: {},
    clientId: null,
};

export default function ChartReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case SET_CLIENT_ID: {
            return {...state, clientId: payload.id || [] };
        }
        case FETCH_DATA: {
            return {...state, error: null };
        }
        case success(FETCH_DATA): {
            return {...state, data: { ...payload.data }};
        }
        case error(FETCH_DATA): {
            return {...state, error: payload && payload.error};
        }
        default:
            return state;
    }
}