import { useFormContext, Controller } from "react-hook-form";
import { TextFieldProps, TextField } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import Label, { LabelColor } from "../../Label";

type Props = {
  name: string;
  children: any;
  labelMap: { [value: string]: string };
  chipColor?: LabelColor;
};

export default function RHFChipSelect({
  name,
  children,
  chipColor,
  labelMap,
  sx,
  ...other
}: Props & TextFieldProps) {
  const { control } = useFormContext();
  const { t } = useTranslation(TranslatorNS.LISTS);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          sx={{ mb: 2, ...sx }}
          SelectProps={{
            multiple: true,
            renderValue: (selected: any) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Label key={value} color={chipColor}>
                    {t(labelMap[value])}
                  </Label>
                ))}
              </Box>
            ),
          }}
          error={!!error}
          helperText={error?.message}
          {...other}>
          {children}
        </TextField>
      )}
    />
  );
}
