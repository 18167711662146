import { Skeleton, Stack } from "@mui/material";

export default function EditorLoader() {
  return (
    <>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={40} />
      </Stack>
      <Stack direction="row" spacing={4}>
        <Skeleton width={60} height={60} />
        <Skeleton width={60} height={60} />
        <Skeleton width={60} height={60} />
        <Skeleton width={60} height={60} />
      </Stack>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={64} />
        <Skeleton variant="rectangular" height={128} />
        <Skeleton variant="rectangular" height={64} />
        <Skeleton variant="rectangular" height={128} />
        <Skeleton variant="rectangular" height={64} />
        <Skeleton variant="rectangular" height={128} />
      </Stack>
    </>
  );
}
