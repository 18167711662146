import { CLIENT_FOOD_PREFERENCES, MEALS_PER_DAY } from "./constants/Meal";
import { Locales } from "../../i18n/locales";

export const FILTERS = {
  pending: "const.filters.pending",
  all: "const.filters.all",
  "need-plans": "const.filters.need-plans",
  "missing-checkin": "const.filters.missing-checkin",
  progress: "const.filters.progress",
  unanswered: "const.filters.unanswered",
  "old-chats": "const.filters.old-chats",
  ending: "const.filters.ending",
  payments: "const.filters.payments",
  custom: "const.filters.custom",
};

export const S3_BEFORE_AFTER_IMAGES =
  "https://zenfit-images.s3.eu-central-1.amazonaws.com/before-after-images/client/photo/";

export const CLIENTS_LIST_FETCH_LIMIT = 35;

export const WEEK_DAYS = {
  0: "help.week.none",
  1: "help.week.Monday",
  2: "help.week.Tuesday",
  3: "help.week.Wednesday",
  4: "help.week.Thursday",
  5: "help.week.Friday",
  6: "help.week.Saturday",
  7: "help.week.Sunday",
};

export const CHECKIN_FREQUENCY = {
  1: "help.frequency.everyWeek",
  2: "help.frequency.everyTwoWeeks",
  3: "help.frequency.everyThreeWeeks",
  4: "help.frequency.everyFourWeeks",
}

export const UPDATE_PLANS_SCHEDULE = {
  4: "info.clientFields.updatePlans.every_4_weeks",
  6: "info.clientFields.updatePlans.every_6_weeks",
  8: "info.clientFields.updatePlans.every_8_weeks",
};

export const DURATION = {
  1: "const.duration.1",
  2: "const.duration.2",
  3: "const.duration.3",
  4: "const.duration.4",
  5: "const.duration.5",
  6: "const.duration.6",
  7: "const.duration.7",
  8: "const.duration.8",
  9: "const.duration.9",
  10: "const.duration.10",
  11: "const.duration.11",
  12: "const.duration.12",
};

export const LEVELS = {
  1: "const.levels.beginner",
  2: "const.levels.intermediate",
  3: "const.levels.advanced",
};

export const GENDER = {
  1: "const.gender.female",
  2: "const.gender.male",
};

/* Disclaimer
 * GoalType is only 1: lose weight, and 2 gain weight, but list this is necessary
 */
export const GOAL_TYPE = {
  1: "const.goalType.loseWeight",
  2: "const.goalType.maintainWeight",
  3: "const.goalType.gainWeight",
};

export const MEASURING_SYSTEM = {
  1: "const.measuringSystem.metric",
  2: "const.measuringSystem.imperial",
};

export const WORKOUTS_PER_WEEK = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
};

export const EXPERIENCE_LEVELS = {
  1: "const.experienceLevels.beginner",
  2: "const.experienceLevels.intermediate",
  3: "const.experienceLevels.advanced",
};

export const WORKOUT_LOCATIONS = {
  1: "const.workoutLocations.gym",
  2: "const.workoutLocations.atHome",
  3: "const.workoutLocations.outdoor",
};

export const ACTIVITY_LEVEL = {
  1: "const.activityLevel.lowActivity",
  2: "const.activityLevel.sedentaryJob",
  3: "const.activityLevel.moderateActiveJob",
  4: "const.activityLevel.quiteActiveJob",
  5: "const.activityLevel.hardPhysicalJob",
};

const MEASURE_SYSTEM_HEIGHT = {
  1: "const.measureSystemHeight.cm",
  2: "const.measureSystemHeight.feet",
};

const MEASURE_SYSTEM_WEIGHT = {
  1: "const.measureSystemWeight.kg",
  2: "const.measureSystemWeight.lbs",
};

export const LBS_TO_KG = 0.45359237;
export const FEET_TO_CM = 30.48;

export const CLIENT_INFO_FIELDS = {
  name: {
    type: "input",
    label: "const.clientInfoFields.name",
    key: "name",
  },
  measuringSystem: {
    type: "select",
    label: "const.clientInfoFields.measuringSystem",
    key: "measuringSystem",
    options: MEASURING_SYSTEM,
  },
  email: {
    type: "input",
    label: "const.clientInfoFields.email",
    key: "email",
  },
  phone: {
    type: "input",
    label: "const.clientInfoFields.phone",
    key: "info.phone",
  },
  age: {
    type: "input",
    label: "const.clientInfoFields.age",
    key: "info.age",
  },
  birthday: {
    type: "input",
    label: "const.clientInfoFields.birthday",
    key: "info.birthday",
  },
  gender: {
    type: "select",
    label: "const.clientInfoFields.gender",
    key: "info.gender",
    options: GENDER,
  },
  goalType: {
    type: "select",
    label: "const.clientInfoFields.goalType",
    key: "info.goalType",
    options: GOAL_TYPE,
  },
  height: {
    type: "number",
    label: "const.clientInfoFields.height",
    valueType: MEASURE_SYSTEM_HEIGHT,
    key: "info.height",
  },
  feet: {
    type: "number",
    label: "const.clientInfoFields.feet",
    key: "info.feet",
  },
  inches: {
    type: "number",
    label: "const.clientInfoFields.inches",
    key: "info.inches",
  },
  startWeight: {
    type: "number",
    label: "const.clientInfoFields.startWeight",
    valueType: MEASURE_SYSTEM_WEIGHT,
    key: "info.startWeight",
  },
  goalWeight: {
    type: "number",
    label: "const.clientInfoFields.goalWeight",
    valueType: MEASURE_SYSTEM_WEIGHT,
    key: "info.goalWeight",
  },
  locale: {
    type: "select",
    label: "const.clientInfoFields.language",
    key: "info.locale",
    options: Locales,
  },
  workoutLocation: {
    type: "select",
    label: "const.clientInfoFields.workoutLocation",
    key: "info.workoutLocation",
    options: WORKOUT_LOCATIONS,
  },
  experienceLevel: {
    type: "select",
    label: "const.clientInfoFields.workoutExperience",
    key: "info.experienceLevel",
    options: EXPERIENCE_LEVELS,
  },
  workoutsPerWeek: {
    type: "select",
    label: "const.clientInfoFields.workoutPerWeek",
    key: "info.workoutsPerWeek",
    options: WORKOUTS_PER_WEEK,
  },
  numberOfMeals: {
    type: "select",
    label: "const.clientInfoFields.mealsPerDay",
    key: "info.numberOfMeals",
    options: MEALS_PER_DAY,
  },
  clientFoodPreferences: {
    type: "multiSelect",
    label: "const.clientInfoFields.foodPreferences",
    key: "info.clientFoodPreferences",
    options: CLIENT_FOOD_PREFERENCES,
  },
  activityLevel: {
    type: "select",
    label: "const.clientInfoFields.activityLevel",
    key: "info.activityLevel",
    options: ACTIVITY_LEVEL,
  },
  lifestyle: {
    type: "textarea",
    label: "const.clientInfoFields.lifestyle",
    key: "info.lifestyle",
  },
  dietStyle: {
    type: "textarea",
    label: "const.clientInfoFields.dietStyle",
    key: "info.dietStyle",
  },
  motivation: {
    type: "textarea",
    label: "const.clientInfoFields.motivation",
    key: "info.motivation",
  },
  experience: {
    type: "textarea",
    label: "const.clientInfoFields.experience",
    key: "info.experience",
  },
  exercisePreferences: {
    type: "textarea",
    label: "const.clientInfoFields.exercisePreferences",
    key: "info.exercisePreferences",
  },
  injuries: {
    type: "textarea",
    label: "const.clientInfoFields.injuries",
    key: "info.injuries",
  },
  other: {
    type: "textarea",
    label: "const.clientInfoFields.other",
    key: "info.other",
  },
};

export const CLIENT_NOTES_FIELDS = {
  startDate: {
    type: "date",
    label: "const.clientNotesFields.startDate",
    key: "startDate.date",
  },
  duration: {
    type: "select",
    label: "const.clientNotesFields.duration",
    key: "duration",
    options: DURATION,
  },
  endDate: {
    type: "dateVal",
    label: "const.clientNotesFields.endDate",
    key: "endDate.date",
  },
  tags: {
    type: "multiSelect",
    label: "const.clientNotesFields.tags",
    key: "tags",
    options: [],
    creatable: true,
  },
  note: {
    type: "textarea",
    label: "const.clientNotesFields.privateNote",
    key: "info.notes.note",
  },
  dialogMessage: {
    type: "static",
    label: "const.clientNotesFields.leadInfo",
    key: "info.notes.dialogMessage",
  },
  salesNotes: {
    type: "static",
    label: "const.clientNotesFields.salesNote",
    key: "info.notes.salesNotes",
  },
};

export const CLIENT_WORKOUT_METAS = {
  level: [
    "const.clientWorkoutMetas.level.beginner",
    "const.clientWorkoutMetas.level.intermediate",
    "const.clientWorkoutMetas.level.advanced",
  ],
  location: [
    "const.clientWorkoutMetas.location.gym",
    "const.clientWorkoutMetas.location.atHome",
    "const.clientWorkoutMetas.location.outdoor",
  ],
  gender: [
    "const.clientWorkoutMetas.gender.female",
    "const.clientWorkoutMetas.gender.male",
  ],
  workoutsPerWeek: "const.clientWorkoutMetas.workoutsPerWeek",
  duration: "const.clientWorkoutMetas.duration",
  type: "",
};

export const PERIOD_GRAPH_TOGGLE = {
  12: "const.periodGraphToggle.ALL",
  6: "const.periodGraphToggle.6m",
  3: "const.periodGraphToggle.3m",
};
