import {
  ClientPaymentConfigurationStatusEnum,
  EventNameEnum,
  GetClientGeneralQuery,
} from "../../../generated/graphql";
import PendingCard from "./components/PendingCard";
import { Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { getWeightGoalDifferenceLabel } from "./helpers";
import Weeks from "./components/Weeks";
import CheckInDay from "./components/CheckInDay";
import Screening from "./components/Screening";
import Note from "./components/Note";
import { ReactComponent as MyFitnessPal } from "./assets/MyFitnessPal.svg";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import Age from "./components/Age";
import { getUnit } from "../../../helpers/progressHelper";
import Iconify from "../../../components/_MUI/Iconify";
import { ActivationQueryEnum } from "../../ClientActivation/Main";

interface Props {
  data: GetClientGeneralQuery;
}

export default function GeneralContent({ data }: Props) {
  const client = data?.client;
  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const {
    paymentConfiguration,
    gender,
    startWeight,
    unresolvedTasks,
    birthday,
    weightGoal,
    measuringSystem,
    clientFoodPreferences,
    checkInDay,
    checkInFrequency,
    checkInReminderLastSent,
    coach,
    startDate,
    note,
    age,
    isDemo,
    endDate,
    integrations,
    url,
  } = client;

  const taskPaymentPending =
    paymentConfiguration?.status === ClientPaymentConfigurationStatusEnum.Pending &&
    coach.isConnectedToStripeConnect &&
    (unresolvedTasks || []).find(
      status => status?.event.name === EventNameEnum.PaymentPending,
    );

  const taskInvite = (unresolvedTasks || []).find(
    status => status?.event?.name === EventNameEnum.InvitePending,
  );

  const questionnairePending = (unresolvedTasks || []).find(
    status => status?.event?.name === EventNameEnum.QuestionnairePending,
  );

  const { transString, difference } = getWeightGoalDifferenceLabel(
    startWeight,
    weightGoal,
  );
  const unit = getUnit(measuringSystem);

  if (taskPaymentPending && !taskPaymentPending?.resolved) {
    return (
      <PendingCard
        type={"payment"}
        link={paymentConfiguration?.url}
        taskId={taskPaymentPending.id}
      />
    );
  }

  if (!isDemo && questionnairePending && !questionnairePending?.resolved) {
    return (
      <PendingCard
        type={"questionnaire"}
        link={`${url}?${ActivationQueryEnum.FullQuestionnaire}=true`}
        taskId={questionnairePending?.id}
      />
    );
  }

  if (!isDemo && taskInvite && !taskInvite?.resolved) {
    return <PendingCard type={"invite"} link={url} taskId={taskInvite?.id} />;
  }

  return (
    <Stack sx={{ overflowY: "auto" }} pb={1}>
      <Note note={note} />
      <Stack sx={Styles.rowItem}>
        <Iconify icon="uil:user" fontSize={20} />
        <Typography>{gender ? t("newClientOverview.gender." + gender) : "-"}</Typography>
      </Stack>
      <Stack sx={Styles.rowItem}>
        {/* Where is the icon for birthdate??? */}
        <Age age={age} birthday={birthday} />
      </Stack>
      <Stack sx={Styles.rowItem}>
        <Iconify icon="uil:weight" fontSize={20} />
        <Typography>{t(transString, { difference, unit })}</Typography>
      </Stack>
      <Stack sx={Styles.rowItem}>
        <Weeks startDate={startDate} endDate={endDate} />
      </Stack>
      <Stack sx={Styles.rowItem}>
        <Iconify
          icon="uil:apple-alt"
          fontSize={20}
          color={integrations?.healthConnected ? "inherit" : palette.error.dark}
        />
        <Typography>
          {integrations?.healthConnected
            ? t("newClientOverview.generalCard.appleConnected")
            : t("newClientOverview.generalCard.notAppleConnected")}
        </Typography>
        {/*{!integrations?.healthConnected && (
          <Tooltip title={"Invite to connect with Apple Health"}>
            <IconButton size={"small"}>
              <Iconify icon="ic:round-arrow-forward-ios´" fontSize={20} />
            </IconButton>
          </Tooltip>
        )}*/}
      </Stack>
      <Stack sx={Styles.rowItem}>
        <SvgIcon
          sx={{
            mr: "-3px",
            fontSize: 20,
            g: {
              fill: integrations?.mfpConnected ? "inherit" : palette.error.dark,
            },
          }}>
          <MyFitnessPal />
        </SvgIcon>
        <Typography>
          {integrations?.mfpConnected
            ? t("newClientOverview.generalCard.mfpConnected")
            : t("newClientOverview.generalCard.notMfpConnected")}
        </Typography>
        {/*
        TODO: Re-add when there is a proper chatTemplate
        {!integrations?.mfpConnected && (
          <Tooltip title={"Invite to connect with MyFitnessPal"}>
            <IconButton size={"small"}>
              <ArrowForwardRoundedIcon fontSize={"small"} />
            </IconButton>
          </Tooltip>
        )}*/}
      </Stack>
      <Stack sx={Styles.rowItem}>
        <CheckInDay
          checkInDay={checkInDay}
          checkInFrequency={checkInFrequency}
          checkInReminderLastSent={checkInReminderLastSent}
        />
      </Stack>
      <Stack sx={Styles.rowItem} className={!!clientFoodPreferences ? "action" : ""}>
        <Screening screeningQuestions={clientFoodPreferences} />
      </Stack>
    </Stack>
  );
}

const Styles = {
  rowItem: {
    position: "relative",
    py: 1,
    borderRadius: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
    "&.action": {
      "&:hover": {
        bgcolor: "background.neutral",
      },
    },
    ".MuiButtonBase-root": {
      position: "absolute",
      right: 1,
    },
    ".MuiTypography-root": {
      ml: 1,
      cursor: "default",
    },
  },
};
