import { Client, MealPlan, PlanId, RecipePlan, WorkoutPlan } from "../types";
import {
  InitialClient,
  InitialMealPlan,
  InitialRecipePlan,
  InitialWorkoutPlan,
} from "../const";
import {
  FETCH_MEAL_TEMPLATE,
  FETCH_MEAL_TEMPLATE_SUCCESS,
  FETCH_RECIPE_TEMPLATE,
  FETCH_RECIPE_TEMPLATE_SUCCESS,
  FETCH_WORKOUT_TEMPLATE,
  FETCH_WORKOUT_TEMPLATE_SUCCESS,
  RESET_STORE,
} from "./types";
import { RequestState } from "../../../interfaces/requestState";

export interface PlannerState {
  type: PlanId;
  client?: Client;
  mealPlan?: MealPlan;
  workoutPlan: WorkoutPlan;
  meal: {};
  recipePlan: RecipePlan;
  mealPlanRequestState: RequestState;
  workoutPlanRequestState: RequestState;
  recipePlanRequestState: RequestState;
  isLoading: boolean;
}

export const INITIAL_STATE: PlannerState = {
  // TODO: remove ignore when adding api call instead of hardcoded values
  // @ts-ignore
  type: PlanId.NONE,
  // @ts-ignore
  client: InitialClient,
  // @ts-ignore
  mealPlan: InitialMealPlan,
  meal: {},
  // @ts-ignore
  workoutPlan: InitialWorkoutPlan,
  // @ts-ignore
  recipePlan: InitialRecipePlan,
  // @ts-ignore
  isLoading: true,
};

const planReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case FETCH_MEAL_TEMPLATE:
      return {
        ...state,
        mealPlan: InitialMealPlan,
        mealPlanRequestState: RequestState.PENDING,
        type: PlanId.MEAL,
        isLoading: true,
      };
    case FETCH_MEAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        mealPlan: action.payload,
        mealPlanRequestState: RequestState.RESOLVED,
        type: PlanId.MEAL,
        isLoading: false,
      };
    case FETCH_WORKOUT_TEMPLATE:
      return {
        ...state,
        workoutPlan: InitialWorkoutPlan,
        workoutPlanRequestState: RequestState.PENDING,
        type: PlanId.WORKOUT,
        isLoading: true,
      };
    case FETCH_WORKOUT_TEMPLATE_SUCCESS:
      return {
        ...state,
        workoutPlan: action.payload,
        workoutPlanRequestState: RequestState.RESOLVED,
        type: PlanId.WORKOUT,
        isLoading: false,
      };
    case FETCH_RECIPE_TEMPLATE:
      return {
        ...state,
        recipePlan: InitialRecipePlan,
        recipePlanRequestState: RequestState.PENDING,
        type: PlanId.RECIPE,
        isLoading: true,
      };
    case FETCH_RECIPE_TEMPLATE_SUCCESS:
      return {
        ...state,
        recipePlan: action.payload,
        recipePlanRequestState: RequestState.RESOLVED,
        type: PlanId.RECIPE,
        isLoading: false,
      };
    case RESET_STORE:
      return INITIAL_STATE
    default:
      return state;
  }
};
export default planReducer;
