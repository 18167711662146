import { useFormContext, Controller } from "react-hook-form";
import { TextFieldProps, TextField } from "@mui/material";
import { ReactNode } from "react";

type IProps = {
  name: string;
  children: any;
  multiple?: boolean;
  renderValue?: (value: any) => ReactNode;
};

type Props = IProps & TextFieldProps;

export default function RHFSelect({
  name,
  children,
  multiple,
  renderValue,
  sx,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...fieldRest }, fieldState: { error } }) => (
        <TextField
          {...fieldRest}
          select
          value={value ?? ""}
          sx={{ mb: 2, ...sx }}
          SelectProps={{ multiple, renderValue }}
          error={!!error}
          helperText={error?.message}
          {...other}>
          {children}
        </TextField>
      )}
    />
  );
}
