import { AppState } from "../index";
import {
  CLOSE_GLOBAL_MODAL,
  ModalPayload,
  ModalTypeEnum,
  OPEN_GLOBAL_MODAL,
} from "./reducerTypes";
import { Dispatch } from "redux";

export const openGlobalModalAction = (payload: ModalPayload<ModalTypeEnum>) => {
  return (dispatch: Dispatch<AppState>) => {
    dispatch({
      type: OPEN_GLOBAL_MODAL,
      payload,
    });
  };
};

export const closeGlobalModalAction = (payload: ModalTypeEnum) => {
  return (dispatch: Dispatch<AppState>) => {
    dispatch({
      type: CLOSE_GLOBAL_MODAL,
      payload,
    });
  };
};
