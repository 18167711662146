import Avatar, { Props as AvatarProps } from "./Avatar";
import { getClientInitials } from "../../helpers/utils";
import SkeletonContainer from "../../containers/SkeletonContainer";
import useResponsive, { BreakPointEnum, QueryEnum } from "../../hooks/useResponsive";

interface Props extends AvatarProps {
  photo?: string;
  name: string;
  initialLoading: boolean;
}

export default function MyAvatar(props: Props) {
  const { photo, name, sx, initialLoading, ...other } = props;
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);

  return (
    <SkeletonContainer
      width={isDesktop ? 88 : 80}
      height={isDesktop ? 88 : 80}
      sx={sx}
      variant={"circular"}
      loading={initialLoading}>
      <Avatar sx={sx} color={"default"} src={photo} {...other}>
        {!photo && getClientInitials(name)}
      </Avatar>
    </SkeletonContainer>
  );
}
