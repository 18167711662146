import React from "react";
import { KEY_TO_FIND_CLIENT_NAME } from "../../helpers/const";
import i18n from "i18next";
import { TranslatorNS } from "../../i18n/const";
import { Stack, Tooltip } from "@mui/material";
import Label from "../_MUI/Label";
import { sharedTooltipProps, TSharedTooltipProps } from "../../routes/ClientList/const";

interface Props {
  tags: (string | undefined)[];
  compareKey?: string;
  limit?: number;
  customTooltipProps?: TSharedTooltipProps;
}

const getTagTitle = (title?: string) => {
  switch (title?.toLowerCase()) {
    case "#all":
      return `${i18n.t("documents.main.all", { ns: TranslatorNS.LISTS })}`;
    default:
      return title?.includes(KEY_TO_FIND_CLIENT_NAME)
        ? `#${title.split("%")[1]}`
        : `${title?.replace("#", "")}`;
  }
};

export default function TagIcons({ tags, limit = 2, customTooltipProps }: Props) {
  return (
    <Stack flexDirection={"row"}>
      {tags?.map(
        (tag, i) =>
          i < limit && (
            <Label key={i} sx={{ ml: 0.5, mr: 0.5 }}>
              {getTagTitle(tag)?.toUpperCase()}
            </Label>
          ),
      )}
      {tags?.length > limit && (
        <>
          <Tooltip
            arrow
            placement={"top"}
            PopperProps={{ sx: { zIndex: 1000 } }}
            componentsProps={TooltipSx}
            {...customTooltipProps}
            title={
              <>
                {tags?.map(
                  (tag, i) =>
                    i >= limit && (
                      <Label key={i} sx={{ m: 0.5 }}>
                        {getTagTitle(tag)?.toUpperCase()}
                      </Label>
                    ),
                )}
              </>
            }>
            <div>
              <Label sx={{ ml: 0.5, mr: 0.5 }}>+{tags.length - limit}</Label>
            </div>
          </Tooltip>
        </>
      )}
    </Stack>
  );
}

const TooltipSx = {
  tooltip: {
    sx: {
      padding: "6px 2px!important",
      backgroundColor: "white!important",
      boxShadow: "2px 2px 6px 1px rgb(178 178 178 / 17%)!important",
      "& .MuiTooltip-arrow": {
        color: "white!important",
      },
    },
  },
};
