import _ from "lodash";
import {
  CONVERSATION_SELECTED_UPDATE,
  CONVERSATIONS_FETCH,
  CONVERSATIONS_MARK_AS_SEEN,
  CONVERSATIONS_ORDER,
  CONVERSATIONS_SEND_MESSAGE_TO_ALL_CLIENTS,
  CONVERSATIONS_SHOW_MODAL,
  MESSAGES_FETCH,
  MESSAGES_SHOW,
  MESSAGES_UPDATE,
  MOBILE_VIEW,
  SET_SCROLLED,
} from "../constants";
import { ChatActionTypes, ChatState } from "./types";

const INITIAL_STATE: ChatState = {
  conversations: [],
  selectedConversation: null,
  isShowNewMessageConversation: false,
  isShowSendMessageToAllClients: false,
  messages: [],
  startFrom: 0,
  hasMore: false,
  isShowMessages: false,
  selectedClient: null,
  isLoading: true,
  isMessageLoading: true,
  scrolled: false,
  isMobile: false,
};

export default function ChatReducer(
  state = INITIAL_STATE,
  action: ChatActionTypes,
): ChatState {
  const { type, payload } = action;
  switch (type) {
    case MESSAGES_FETCH.REQUEST:
      return {
        ...state,
        isMessageLoading: true,
        messages: [],
        startFrom: 0,
        scrolled: false,
        selectedConversation: null,
      };
    case MESSAGES_FETCH.SUCCESS:
      return {
        ...state,
        messages: [...payload.messages],
        hasMore: payload.hasMore,
        startFrom:
          payload.startFrom || payload.startFrom === 0
            ? payload.startFrom
            : state.startFrom + payload.messages.length,
        selectedConversation: payload.selectedConversation,
        selectedClient: payload.selectedClient,
        isMessageLoading: false,
      };
    case MESSAGES_UPDATE:
      /**
       * Check if selectClient has changed before this update
       */
      const messagesByClientId = _.keyBy(payload.messages, "clientId");
      const clientId = Object.keys(messagesByClientId)?.[0];
      /**
       * Short circuit chat update if you are no longer looking at the same chat
       * as updated messages comes from
       */
      if (Number(clientId) !== Number(state.selectedClient?.id)) {
        return state;
      }
      return {
        ...state,
        hasMore: payload.hasMore,
        startFrom: payload.startFrom,
        messages: [...payload.messages],
      };
    case MESSAGES_SHOW:
      return {
        ...state,
        isShowMessages: payload.isShowMessages,
      };
    case CONVERSATIONS_FETCH.REQUEST:
      return { ...state, isLoading: true, messages: [] };
    case CONVERSATIONS_FETCH.SUCCESS:
      return {
        ...state,
        conversations: [...payload.conversations],
        selectedConversation: payload.selectedConversation,
        selectedClient: payload.selectedClient,
        isLoading: false,
      };
    case CONVERSATIONS_SHOW_MODAL:
      return {
        ...state,
        isShowNewMessageConversation: payload.isShowNewMessageConversation,
      };
    case CONVERSATIONS_SEND_MESSAGE_TO_ALL_CLIENTS:
      return {
        ...state,
        isShowSendMessageToAllClients: payload.isShowSendMessageToAllClients,
      };
    case CONVERSATIONS_ORDER:
      return {
        ...state,
        conversations: [...payload.conversations],
      };
    case CONVERSATION_SELECTED_UPDATE:
      return {
        ...state,
        selectedConversation: payload.selectedConversation,
      };

    case CONVERSATIONS_MARK_AS_SEEN:
      const nextState = {
        ...state,
        conversations: state.conversations.map(conversation => {
          if (conversation.id === payload.id) {
            conversation.isNew = false;
          }
          return conversation;
        }),
      };

      if (payload.message) {
        nextState.messages = state.messages.map(message => {
          if (message.id === payload.message.id) {
            return {
              ...message,
              ...payload.message,
            };
          }
          return message;
        });
      }

      return nextState;
    case SET_SCROLLED:
      return {
        ...state,
        scrolled: payload.scrolled,
      };
    case MOBILE_VIEW:
      return {
        ...state,
        isMobile: payload.isMobile,
        isShowMessages: payload.isShowMessages,
      };
    default:
      return state;
  }
}
