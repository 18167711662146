import DialogContent from "@mui/material/DialogContent";
import SubscriptionStatus from "./SubscriptionStatus";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CreateSubscriptionSchema } from "../subscriptionSchema";
import {
  ClientType,
  CurrencyEnum,
  GetClientModalSubscriptionQuery,
  LocaleEnum,
} from "../../../../generated/graphql";
import {
  FormProvider,
  RHFDatePicker,
  RHFSwitch,
  RHFTextField,
} from "../../../_MUI/hook-form";
import { Button, DialogActions, Grid } from "@mui/material";
import RHFNativeSelect from "../../../_MUI/hook-form/RHFNativeSelect";
import InfoTooltip from "../../../Help/InfoTooltip";
import { addDays } from "date-fns";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { useEffect } from "react";
import axios from "axios";
import { GET_DEFAULT_MESSAGES_LOCAL } from "../../../../api/private/default-messages";
import useCreateSubscription, {
  TNewSubscriptionFormValues,
} from "../useCreateSubscription";

interface Props {
  defaultValues: TNewSubscriptionFormValues;
  clientInvitationInfo: {
    clientId: ClientType["id"];
    name: ClientType["name"];
    email: ClientType["email"];
    locale: LocaleEnum;
  };
  paymentConfiguration: GetClientModalSubscriptionQuery["client"]["paymentConfiguration"];
  onClose: () => void;
}

const CLIENT_PAYMENT_TERMS = 14;

export default function FormFields(props: Props) {
  const { paymentConfiguration, defaultValues, onClose, clientInvitationInfo } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);
  const { createNewSubscription, loading } = useCreateSubscription();

  const methods = useForm<TNewSubscriptionFormValues>({
    resolver: yupResolver(CreateSubscriptionSchema),
    defaultValues,
  });

  const { watch, setValue, handleSubmit } = methods;

  const values = watch();

  useEffect(() => {
    if (values.includeTerms && !values.terms) {
      axios
        .get(
          GET_DEFAULT_MESSAGES_LOCAL(
            CLIENT_PAYMENT_TERMS,
            clientInvitationInfo.clientId,
            clientInvitationInfo.locale,
          ),
        )
        .then(({ data }: any) => {
          if (data.defaultMessages.length !== 0) {
            setValue(
              "terms",
              data.defaultMessages[data.defaultMessages.length - 1].message,
            );
          }
        });
    }
  }, [clientInvitationInfo.clientId, clientInvitationInfo.locale, values.includeTerms]);

  useEffect(() => {
    if (values.months == 0) {
      setValue("recurringFee", 0);
    }
  }, [values.months]);

  return (
    <>
      <DialogContent>
        <SubscriptionStatus paymentConfiguration={paymentConfiguration} />
        <FormProvider methods={methods} onSubmit={handleSubmit(createNewSubscription)}>
          <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} md={6}>
              <RHFNativeSelect
                name="currency"
                size="small"
                label={t("currency.chooseCurrency")}>
                {Object.entries(CurrencyEnum).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value.toUpperCase()}
                  </option>
                ))}
              </RHFNativeSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFNativeSelect name="months" size="small" label={t("month.label")}>
                {Array.from(Array(14).keys()).map(month => (
                  <option key={month} value={month}>
                    {t(`month.options.${month}`)}
                  </option>
                ))}
              </RHFNativeSelect>
            </Grid>

            <Grid item xs={12} md={6}>
              <RHFTextField
                name="upfrontFee"
                size="small"
                label={t("amountInput.label")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField
                name="recurringFee"
                size="small"
                type={"number"}
                disabled={values.months == 0}
                label={t("amountInput.monthlyAmount.label")}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFSwitch name="customStartPaymentDate" label={t("terms.firstPayment")} />
              <InfoTooltip text={t("terms.firstPaymentExplainer")} />
            </Grid>
            {values.customStartPaymentDate && (
              <>
                <Grid item xs={12} md={12}>
                  <RHFDatePicker
                    name="startPaymentDate"
                    label={t("terms.firstPaymentDateLabel")}
                    disablePast
                    minDate={addDays(new Date(), 2)}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <RHFSwitch
                    name="chargeUpfrontImmediately"
                    label={t("terms.chargeUpfront")}
                  />
                  <InfoTooltip text={t("terms.chargeUpfrontExplainer")} />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={12}>
              <RHFSwitch name="includeTerms" label={t("terms.addTerms")} />
              <InfoTooltip text={t("terms.addTermsExplainer")} />
              {values.includeTerms && (
                <RHFTextField name="terms" label={t("terms.terms")} multiline rows={5} />
              )}
            </Grid>
            <Grid item xs={12} md={12}></Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t("modalSubscription.close")}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit(createNewSubscription)}
          loading={loading}>
          {t("modalSubscription.confirmSub")}
        </LoadingButton>
      </DialogActions>
    </>
  );
}
