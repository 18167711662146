import {
  CHANGE_LANGUAGE,
  CHANGE_STEP,
  GET_CLIENT_ACTIVATION_FAILURE,
  GET_CLIENT_ACTIVATION_REQUEST,
  GET_CLIENT_ACTIVATION_SUCCESS,
  IClientActivationState,
  POST_CLIENT_FAILURE,
  POST_CLIENT_REQUEST,
  POST_CLIENT_SUCCESS,
  SAVE_STEP,
  TStepContent,
} from "./types";
import { UPLOAD_CLIENT_IMAGES } from "../../../api/private/clients";
import {
  GET_CLIENT_ACTIVATION_API,
  SUBMIT_CLIENT_INFO_REACT_API_PUBLIC,
} from "../../../api/public/clients";
import axios from "../../../helpers/axios";
import { ERROR_MESSAGE } from "../../../config";
import { ThunkAction } from "redux-thunk";
import { getSchemaClientActivation, submitSchemaClientActivation } from "./schema";
import { ActivationStepsEnum } from "../type";
import { identify } from "../../../helpers/analytics";
import { LocaleEnum } from "../../../generated/graphql";

type ThunkActionType = ThunkAction<void, any, any>;

export const saveFieldsAction = (
  stepContent: TStepContent,
  nextStep: IClientActivationState["step"],
  stepName: ActivationStepsEnum,
): ThunkActionType => {
  return dispatch => {
    dispatch({ type: CHANGE_STEP, payload: nextStep });
    dispatch({
      type: SAVE_STEP,
      payload: { stepContent, stepName },
    });
  };
};

export const submitClientAction = (
  stepContent: TStepContent,
  stepName: ActivationStepsEnum,
): ThunkActionType => {
  return async (dispatch: any, getState: any) => {
    dispatch({ type: SAVE_STEP, payload: { stepContent, stepName } });
    dispatch({ type: POST_CLIENT_REQUEST });

    try {
      const state = getState().clientActivation;
      const mappedClientInfo = submitSchemaClientActivation(state);

      const shouldPostImg =
        Number(state.step) !== 1 &&
        ((typeof state.photos.front !== "string" && state.photos.front) ||
          (typeof state.photos.side !== "string" && state.photos.side) ||
          (typeof state.photos.back !== "string" && state.photos.back) ||
          (typeof state.general.photo !== "string" && state.general.photo));

      await axios.post(SUBMIT_CLIENT_INFO_REACT_API_PUBLIC(), mappedClientInfo);

      if (shouldPostImg) {
        let imgs = new FormData();
        state.photos.front && imgs.append("front-img", state.photos.front);
        state.photos.side && imgs.append("side-img", state.photos.side);
        state.photos.back && imgs.append("back-img", state.photos.back);
        state.general.photo && imgs.append("profile-img", state.general.photo);
        await axios.post(UPLOAD_CLIENT_IMAGES(state.clientId), imgs);
      }
      dispatch({ type: POST_CLIENT_SUCCESS, payload: stepName });
    } catch (e: any) {
      console.log({ e }, "activation Error");
      dispatch({
        type: POST_CLIENT_FAILURE,
        payload: e?.response?.data || ERROR_MESSAGE,
      });
    }
  };
};

export const changeLangAction = (newLang: LocaleEnum): ThunkActionType => {
  return dispatch => {
    dispatch({ type: CHANGE_LANGUAGE, payload: newLang });
  };
};

export const changeStepAction = (newStep: number): ThunkActionType => {
  return dispatch => {
    dispatch({ type: CHANGE_STEP, payload: newStep });
  };
};

export const getClientActivationAction = (datakey: string, onlySurvey: boolean) => {
  return async (dispatch: any) => {
    dispatch({ type: GET_CLIENT_ACTIVATION_REQUEST });

    let survey = 0;
    if (onlySurvey) {
      survey = 1;
    }
    try {
      const params = { datakey, onlySurvey: survey };

      const { data } = await axios.get(GET_CLIENT_ACTIVATION_API(), { params });
      const client = data.client;
      const mappedToState = getSchemaClientActivation(client);
      identify(client.id, {
        email: client.email,
        trainer: client.trainer.id,
        userType: "client",
      });
      dispatch({
        type: GET_CLIENT_ACTIVATION_SUCCESS,
        payload: {
          mappedToState,
          config: data.config,
          userToken: client.trainer.token,
          datakey,
          showEatingDisorderQuestions: client.queue.screeningQuestions,
          customQuestions: client.customQuestions,
        },
      });
    } catch (e: any) {
      console.log({ e }, "activation Error");
      dispatch({
        type: GET_CLIENT_ACTIVATION_FAILURE,
        payload: e?.response?.data?.error_list || ERROR_MESSAGE,
      });
    }
  };
};
