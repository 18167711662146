import { roundToDecimals } from "../../ClientProgress/bottom/helpers/utils";

export const getWeightGoalDifferenceLabel = (
  startWeight?: string | number | null,
  goalWeight?: string | number | null,
): { transString: string; difference: number } => {
  if (!Boolean(startWeight) && !Boolean(goalWeight)) {
    return {
      transString: "-",
      difference: 0,
    };
  }

  const startWeightNumber = Number(startWeight);
  const goalWeightNumber = Number(goalWeight);

  const transString =
    goalWeight === startWeightNumber
      ? "newClientOverview.generalCard.goalWeight.maintain"
      : goalWeightNumber > startWeightNumber
      ? "newClientOverview.generalCard.goalWeight.gain"
      : "newClientOverview.generalCard.goalWeight.lose";

  const difference = roundToDecimals(Math.abs(startWeightNumber - goalWeightNumber), 1);

  return {
    transString,
    difference,
  };
};
