// scroll bar
import "simplebar/src/simplebar.css";
import "./index.scss";
import "./assets/scss/FixBootstrapStyling.scss";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

import App from "./App";
import { createRoot } from "react-dom/client";
import NotistackProvider from "./components/Snackbar";
import ErrorProvider from "./providers/ErrorProvider";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_X));

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(
  <HelmetProvider>
    <ErrorProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <NotistackProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </NotistackProvider>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </ErrorProvider>
  </HelmetProvider>,
);
