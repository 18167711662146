export enum CypressTestId {
  // Sign up
  SIGN_UP_WITH_EMAIL_BUTTON = "SIGN_UP_WITH_EMAIL_BUTTON",
  SIGN_UP_NAME = "SIGN_UP_NAME",
  SIGN_UP_EMAIL = "SIGN_UP_EMAIL",
  SIGN_UP_PASSWORD = "SIGN_UP_PASSWORD",
  // SIGN_UP_NUMBER_CLIENTS = "SIGN_UP_NUMBER_CLIENTS",
  SIGN_UP_CHECK_TERMS = "SIGN_UP_CHECK_TERMS",
  SIGN_UP_SUBMIT = "SIGN_UP_SUBMIT",
  // Sign in
  SIGN_IN_EMAIL = "SIGN_IN_EMAIL",
  SIGN_IN_PASSWORD = "SIGN_IN_PASSWORD",
  SIGN_IN_REMEMBER_ME = "SIGN_IN_REMEMBER_ME",
  SIGN_IN_SUBMIT = "SIGN_IN_SUBMIT",
  // finish sign up
  SIGN_UP_BUSINESS_PHONE = "SIGN_UP_BUSINESS_PHONE",
  SIGN_UP_FINISH_SUBMIT = "SIGN_UP_FINISH_SUBMIT",
  SIGN_UP_TITLE = "SIGN_UP_TITLE",

  // Account dropdown name
  USER_HEADER_NAME = "USER_HEADER_NAME",

  // Client page
  CLIENT_PAGE_PROGRESS_BAR = "CLIENT_PAGE_PROGRESS_BAR",
  CLIENT_PAGE_PASSWORD = "CLIENT_PAGE_PASSWORD",
  CLIENT_PAGE_CONFIRM_PASSWORD = "CLIENT_PAGE_CONFIRM_PASSWORD",
  CLIENT_PAGE_BIRTHDAY = "CLIENT_PAGE_BIRTHDAY",
  CLIENT_PAGE_BUTTON_1 = "CLIENT_PAGE_BUTTON_1",
  CLIENT_PAGE_BUTTON_2 = "CLIENT_PAGE_BUTTON_2",
  CLIENT_PAGE_BUTTON_3 = "CLIENT_PAGE_BUTTON_3",
  CLIENT_PAGE_BUTTON_4 = "CLIENT_PAGE_BUTTON_4",
  CLIENT_PAGE_BUTTON_5 = "CLIENT_PAGE_BUTTON_5",
  CLIENT_PAGE_BUTTON_6 = "CLIENT_PAGE_BUTTON_6",
  CLIENT_PAGE_BUTTON_7 = "CLIENT_PAGE_BUTTON_7",

  //Add client flow
  ADD_CLIENT_BUTTON = "ADD_CLIENT_BUTTON",
  ADD_CLIENT_NAME = "ADD_CLIENT_NAME",
  ADD_CLIENT_SUBSCRIPTION_MONTHLY_AMOUNT = "ADD_CLIENT_SUBSCRIPTION_MONTHLY_AMOUNT",
  ADD_CLIENT_EMAIL = "ADD_CLIENT_EMAIL",
  ADD_CLIENT_DATAKEY = "ADD_CLIENT_DATAKEY",
  ADD_CLIENT_BUTTON_1 = "ADD_CLIENT_BUTTON_1",
  ADD_CLIENT_BUTTON_2 = "ADD_CLIENT_BUTTON_2",
  ADD_CLIENT_BUTTON_3 = "ADD_CLIENT_BUTTON_3",

  //Checkout page
  CHECKOUT_AMOUNT = "CHECKOUT_AMOUNT",
}
