import { LinearProgress, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { ClientHabitEnum, ClientType } from "../../../../generated/graphql";
import { getPreviousWeeksAverageSteps } from "../helpers/getPreviousWeeksAverageSteps";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";
import { roundToDecimals } from "../../../ClientProgress/bottom/helpers/utils";

interface Props {
  steps: ClientType["steps"] | undefined;
  habits: ClientType["habits"] | undefined;
}

export default function WeeklyAverages(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { steps, habits } = props;
  const { stepGoal, stepGoalString } = getStepHabitGoal(habits);
  const { stepsWeekNumber, stepsAverageWeek } = getPreviousWeeksAverageSteps(steps);
  const stepPercentageDiff = getPercentageDiff(stepGoal, stepsAverageWeek);
  const { palette } = useTheme();

  const stepTooltipString =
    stepsWeekNumber && stepsAverageWeek
      ? `${t("table.weekNumberTooltip")} - ${stepsAverageWeek} ${t("table.WeekTooltip")}`
      : "";

  return (
    <Stack mt={2}>
      <Stack sx={Styles.row}>
        <Typography sx={{ pr: 1 }}>{t("newClientOverview.steps")}</Typography>
        <Tooltip title={stepTooltipString} disableInteractive>
          <Stack width={"80%"} justifyContent={"center"} height={"100%"}>
            <LinearProgress variant="determinate" value={stepPercentageDiff} />
          </Stack>
        </Tooltip>
        <Tooltip title={t("newClientOverview.weeklyGoal")} disableInteractive>
          <Stack sx={{ cursor: "default" }}>
            <Typography sx={{ pl: 1, color: palette.grey[600] }}>
              {stepGoalString}
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

export const getStepHabitGoal = (habits?: ClientType["habits"]) => {
  const goalStep = habits?.find(h => h.type === ClientHabitEnum.Steps)?.goal;
  return {
    stepGoalString: goalStep ? `${roundToDecimals(goalStep / 1000, 1)}k` : "-",
    stepGoal: goalStep || undefined,
  };
};

const getPercentageDiff = (goal: number | undefined, average: number | undefined) => {
  if (!goal || !average) return 0;
  const percentage = (average / goal) * 100;
  return percentage <= 100 ? percentage : 100;
};

const Styles = {
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
