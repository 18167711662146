export enum Locale {
  da_DK = "da_DK",
  sv_SE = "sv_SE",
  en_US = "en_US",
  en_GB = "en_GB",
  fi_FI = "fi_FI",
  nb_NO = "nb_NO",
  de_DE = "de_DE",
  nl_NL = "nl_NL",
  es_ES = "es_ES",
}

export enum CountryEnum {
  DK = "DK",
  SE = "SE",
  NO = "NO",
  FI = "FI",
  GB = "GB",
  NL = "NL",
  DE = "DE",
  US = "US",
  ES = "ES",
  CH = "CH",
}
