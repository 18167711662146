import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MealPlan } from "../../../types";
import axios from "../../../../../helpers/axios";
import { SAVE_AS_TEMPLATE } from "../../../../../api/private/meals";
import { useSnackbar } from "notistack";

interface Props {
  plan: MealPlan;
}

export default function MealSaveAsTemplate({ plan }: Props) {
  const { t } = useTranslation("meal");
  const PATH = SAVE_AS_TEMPLATE(plan.id);
  const [planName, setPlanName] = useState(plan.name);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const form = e.target;
      const name = form?.elements?.name.value;

      const data = new FormData();
      data.append("name", name);

      await axios.post(PATH, data);
      //@ts-ignore
      $("#saveAsTemplate").modal("hide");
      enqueueSnackbar(t("notifications.saved"), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal inmodal fade sm reactModal"
      id="saveAsTemplate"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("saveAsTemplate.close")}</span>
            </button>
            <h4 className="modal-title">{t("saveAsTemplate.saveMealAsTemplate")}</h4>
          </div>
          <form onSubmit={onSubmit} autoComplete="off">
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="template_name" className="control-label">
                  {t("saveAsTemplate.templateName")}
                </label>
                <input
                  type="text"
                  id="template_name"
                  name="name"
                  className="form-control"
                  placeholder={t("saveAsTemplate.nameOfMealTemplate")}
                  value={planName}
                  onChange={e => setPlanName(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-block btn-success btn-upper"
                data-loading-text={t("saveAsTemplate.saving")}>
                {t("saveAsTemplate.save")} {t("saveAsTemplate.template")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
