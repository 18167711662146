import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";

export default function MissingHealth() {
  const { palette } = useTheme();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  return (
    <Tooltip title={t("newClientOverview.healthInfo")}>
      <Stack
        border={"1px solid"}
        borderColor={palette.grey[300]}
        my={1}
        p={1.5}
        borderRadius={2}
        flexDirection={"row"}
        sx={{ cursor: "help" }}
        bgcolor={palette.grey[200]}>
        <Stack
          width={48}
          height={48}
          border={"0.5px solid"}
          borderColor={palette.grey[300]}
          borderRadius={1}
          mr={2}
          alignItems={"self-end"}
          justifyContent={"flex-start"}
          bgcolor={"white"}>
          <FavoriteIcon sx={{ m: 0.5, color: palette.error.main }} />
        </Stack>
        <Stack flexDirection={"row"} width={"75%"} justifyContent={"space-between"}>
          <Stack flexDirection={"column"} justifyContent={"space-around"}>
            <Typography variant={"subtitle2"}>Apple Health</Typography>
            <Typography color={"text.secondary"} fontSize={12}>
              {t("newClientOverview.healthNotConnected")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Tooltip>
  );
}
