import { useEffect } from "react";
import {
  Badge,
  Box,
  Card,
  Container,
  IconButton,
  Tab,
  Tabs,
  useTheme,
  Button,
} from "@mui/material";
import Page from "../../components/_MUI/Page";
import { useLocation } from "react-router";
import { ACCOUNT_TABS } from "./const";
import { styled } from "@mui/material/styles";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { resetClientImages } from "../../routes/ClientProgress/top/store/clientImages/actions";
import ChatWidget from "../../routes/ChatWidget";
import {
  toggleChatWidgetOpen,
  initializeChatWidget,
} from "../../routes/ChatWidget/actions/chat-action";
import { ProfileCover } from "../../components/_MUI/sections/@dashboard/user/profile";
import { useTranslation } from "react-i18next";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import { prepareRoute } from "../../helpers/routing";
import { Locale } from "../../interfaces/locale";
import { setCurrentClient } from "../../store/currentClient/action";
import { AppState } from "../../store";
import { Routes } from "../../routes/routeMap";
import { LocaleEnum, useGetClientContainerQuery } from "../../generated/graphql";
import Iconify from "../../components/_MUI/Iconify";

interface Props {
  isChatWidgetOpen: boolean;
  setCurrentClientDispatch: ({ clientId }: { clientId: number }) => void;
  resetClientImagesDispatch: () => void;
  initializeChatWidgetDispatch: (
    userId: number,
    clientId: number,
    clientName: string,
    photo?: string,
    locale?: LocaleEnum,
  ) => void;
  locale: LocaleEnum;
  toggleChatWidgetOpenDispatch: (toOpen: boolean) => void;
  unreadMessagesCount: number;
}

function ClientPageWrapper(props: Props) {
  const {
    setCurrentClientDispatch,
    resetClientImagesDispatch,
    toggleChatWidgetOpenDispatch,
    initializeChatWidgetDispatch,
    locale,
    isChatWidgetOpen,
    unreadMessagesCount,
  } = props;

  const location = useLocation();
  const { t } = useTranslation("app");
  const { clientId } = useParams<{ clientId: string }>();
  const { onToggleCollapse, collapseClick } = useCollapseDrawer();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { data, loading } = useGetClientContainerQuery({
    variables: { id: Number(clientId) },
  });
  const clientTrainerId = data?.client?.coach?.id;
  const clientFullName = data?.client?.name || "";
  const clientPhoto = data?.client?.photo || "";
  const tags = data?.client?.tags || undefined;
  const url = data?.client?.url;
  const hasCreatedPassword = !!data?.client?.hasCreatedPassword;
  const answeredQuestionnaire = !!data?.client?.hasCreatedPassword;

  const getRouteName = location.pathname
    .replace(String(clientId), ":clientId")
    .replace(/[0-9]/g, "")
    .replace(/\/$/, "");

  useEffect(() => {
    if (clientId) {
      setCurrentClientDispatch({ clientId: Number(clientId) });
    }
    return () => {
      resetClientImagesDispatch();
    };
  }, [clientId]);

  useEffect(() => {
    if (clientTrainerId && clientId) {
      initializeChatWidgetDispatch(
        clientTrainerId,
        Number(clientId),
        clientFullName,
        clientPhoto,
        locale,
      );
    }
  }, [clientTrainerId, clientId, clientFullName]);

  useEffect(() => {
    if (!collapseClick) {
      onToggleCollapse();
    }
  }, []);

  return (
    <Page title={`${clientFullName}`}>
      <Button
        onClick={() => toggleChatWidgetOpenDispatch(!isChatWidgetOpen)}
        size="medium"
        variant="contained"
        id="chat-widget-button"
        sx={Styles.iconButton}
        startIcon={<Iconify icon="uil:comment" color={palette.grey[0]} fontSize={22} />}>
        {t("navigation.chat")}
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          color={"error"}
          sx={{ display: "contents" }}
          badgeContent={unreadMessagesCount}></Badge>
      </Button>
      <Container style={{ maxWidth: "100%" }} id={"mainWrapper"}>
        <Card sx={Styles.card}>
          <IconButton
            onClick={() => navigate(Routes.CLIENT_LIST)}
            sx={Styles.iconButton2}>
            <Iconify icon="uil:arrow-left" color={palette.grey["500"]} fontSize={30} />
          </IconButton>
          <ProfileCover
            name={clientFullName}
            tags={tags}
            photo={clientPhoto}
            clientInviteUrl={url}
            hasCreatedPassword={hasCreatedPassword}
            answeredQuestionnaire={answeredQuestionnaire}
            initialLoading={loading}
          />
          <TabsWrapperStyle>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              id="client-navigation-tabs"
              value={getRouteName}>
              {ACCOUNT_TABS.map(tab => (
                <Tab
                  to={prepareRoute(tab.value, { clientId })}
                  component={Link}
                  disableRipple
                  key={tab.value}
                  label={t(tab.title)}
                  icon={tab.icon}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>
        <Box sx={{ mb: 3 }} />
        <Box sx={{ mb: 3 }} />
        <Box style={{ position: "relative", minHeight: "90vh" }}>
          <Outlet />
        </Box>
      </Container>
      <ChatWidget />
    </Page>
  );
}

const Styles = {
  iconButton: {
    mt: 4,
    boxShadow: 1,
    zIndex: 999,
    position: "fixed",
    right: { xs: 12, sm: 20 },
    py: 1,
    px: 3,
  },
  iconButton2: {
    zIndex: 10,
    position: "absolute",
    top: { xs: 25, sm: 28 },
    left: { xs: 36, sm: 8 },
  },
  card: {
    boxShadow: "none",
    mt: -5,
    mb: 3,
    mr: -5,
    ml: -5,
    height: { xs: 210, sm: 220, md: 140 },
    position: "relative",
    background: "transparent",
    borderRadius: 0,
  },
};

const mapStatesToProps = (state: AppState) => {
  return {
    locale: state.user.locale,
    isChatWidgetOpen: state.chatWidgetMessages.isChatWidgetOpen,
    unreadMessagesCount: state.chatWidgetMessages.unreadMessagesCount,
  };
};

const mapDispatchToProps = {
  setCurrentClientDispatch: setCurrentClient,
  resetClientImagesDispatch: resetClientImages,
  toggleChatWidgetOpenDispatch: toggleChatWidgetOpen,
  initializeChatWidgetDispatch: initializeChatWidget,
};

export default connect(mapStatesToProps, mapDispatchToProps)(ClientPageWrapper);

const TabsWrapperStyle = styled("div")(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  borderTop: "1px solid #eee",
  borderBottom: "1px solid #eee",
  width: "100%",
  display: "flex",
  position: "absolute",
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up("sm")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(23),
  },
}));
