import { useTranslation } from "react-i18next";
import { MealPlan } from "../../../types";
import { ADD_PLAN } from "../../../../../api/private/meals";

interface Props {
  plan: MealPlan;
}

export default function EditMealTitle({ plan }: Props) {
  const { t } = useTranslation("meal");
  const PATH = ADD_PLAN(plan.id);

  return (
    <div
      className="modal inmodal fade sm reactModal"
      id="addPlanModal"
      tabIndex={-1}
      role="dialog">
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("plan.close")}</span>
            </button>
            <h4 className="modal-title">{t("mealPlan.addNewMealPlan")}</h4>
          </div>
          {/* I changed this not submit to the backend form  */}
          <form autoComplete="off" action={PATH}>
            <input type="hidden" name="id" />
            <input type="hidden" name="parent_id" />
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="mealTitle" className="control-label">
                  {t("mealPlan.titleOfMealPlan")}
                </label>
                <input
                  type="text"
                  id="mealTitle"
                  name="name"
                  className="form-control"
                  placeholder={t("mealPlan.titleOfMealPlan")}
                  required
                />
              </div>
              <div className="form-group" id="upload-meal-image-input">
                <label htmlFor="mealImage" className="control-label">
                  {t("mealPlan.uploadImage")}
                </label>
                <input
                  type="file"
                  id="mealImage"
                  accept="image/*"
                  name="image"
                  className="form-control"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-block btn-success btn-upper">
                {t("mealPlan.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
