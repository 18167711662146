import { ClientPaymentType, PaymentTypeEnum } from "../../../../generated/graphql";
import { Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import PaymentItem from "./PaymentItem";
import UpcomingPaymentItem from "./UpcomingPaymentItem";
import { UpcomingPaymentType } from "../index";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  payments?: ClientPaymentType[];
  upcomingPayment?: UpcomingPaymentType | null;
}

export const FLEX_DATE = 0.825;
export const FLEX_AMOUNT_STATUS = 0.175;

export default function Payments(props: Props) {
  const { payments, upcomingPayment } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  if (!payments?.length) {
    return (
      <Stack m={2} mt={3} flexDirection={"row"} justifyContent={"center"}>
        <Typography variant={"subtitle2"}>{t("clientPayment.noPayments")}</Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Divider sx={{ my: 2 }} />
      <Stack flexDirection={"row"} p={1}>
        <Typography flex={FLEX_DATE} fontWeight={600}>
          {t("clientPayment.dateTitle")}
        </Typography>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          flex={FLEX_AMOUNT_STATUS}
          px={3}>
          <Typography sx={{ mx: 1 }} textAlign={"center"} fontWeight={600}>
            {t("clientPayment.amountTitle")}
          </Typography>
          <Typography width={80} fontWeight={600} textAlign={"center"}>
            {t("clientPayment.status")}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        {upcomingPayment && (
          <Stack bgcolor="white">
            <UpcomingPaymentItem upcomingPayment={upcomingPayment} />
          </Stack>
        )}
        {(payments || []).map((payment, i) => {
          const { type, id } = payment;
          if (
            type === PaymentTypeEnum.SubscriptionPaymentCreated ||
            type === PaymentTypeEnum.SubscriptionCanceled
          )
            return;

          return (
            <Stack bgcolor={i % 2 == 0 ? "#f9fafb" : "white"} key={id}>
              <PaymentItem payment={payment} />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
