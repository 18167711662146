import { useState } from "react";
import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import {
  LenusDataTypeEnum,
  LenusPlatformTypeEnum,
  useInitiateLenusTransferMutation,
} from "../../../../generated/graphql";
import {
  getLocalStorage,
  LocalStorageKeysEnum,
  setLocalStorage,
} from "../../../../helpers/localStorage";
import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import RHFGraphqlError from "../../../../components/_MUI/hook-form/RHFGraphqlError";
import NavigationSteps from "../NavigationSteps";
import { transferContentArr } from "./transferContentArr";
import { circleSx, rectangleSx } from "../LenusLoginStep/LoginIntro";
import Iconify from "../../../../components/_MUI/Iconify";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

export default function TransferForm() {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { t } = useTranslation(TranslatorNS.AUTH);

  const { lenusToken, emailContent, emailSubject } = useLenusTransferContext();
  const transferFromLocalStorage = getLocalStorage(
    LocalStorageKeysEnum.LenusPlatformType,
  ) as LenusPlatformTypeEnum;

  const [initiateLenusTransfer, { loading, error }] = useInitiateLenusTransferMutation();

  const handleInitiateTransfer = async () => {
    try {
      if (!lenusToken || !emailContent || !emailSubject) {
        throw new Error(
          `Missing data to initiate transfer: ${JSON.stringify({
            lenusToken,
            emailContent,
            emailSubject,
          })}`,
        );
      }

      await initiateLenusTransfer({
        variables: {
          input: {
            content: emailContent,
            subject: emailSubject,
            token: lenusToken,
            platform: transferFromLocalStorage || LenusPlatformTypeEnum.Eu,
            data: [
              LenusDataTypeEnum.Clients,
              LenusDataTypeEnum.WorkoutTemplates,
              LenusDataTypeEnum.Recipes,
              LenusDataTypeEnum.Leads,
              LenusDataTypeEnum.Exercises,
            ],
          },
        },
      });
      setLocalStorage(
        LocalStorageKeysEnum.LenusTransferStarted,
        JSON.stringify(new Date()),
      );
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Stack maxWidth={{ xs: "100%", sm: "45%" }}>
        <Typography color={"white"} textAlign={"center"} fontSize={"32px"}>
          {t("transfer.nowTransfer")}
        </Typography>
        <Typography color={"white"} textAlign={"center"} maxWidth={480}>
          {t("transfer.toGetStarted")}
        </Typography>
        <Typography
          color={"white"}
          textAlign={"center"}
          sx={{
            mt: 2,
          }}>
          Platform: {`${transferFromLocalStorage.toLowerCase()}.lenus.io`}
        </Typography>
      </Stack>
      <ContentTransfered />
      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              color={"primary"}
              onChange={(_, checked) => setTermsAccepted(checked)}
              checked={termsAccepted}
            />
          }
          label={<Typography color={"white"}>{t("transfer.iUnderstand")}</Typography>}
        />
        <LoadingButton
          type={"submit"}
          size={"large"}
          color={"success"}
          loading={loading}
          onClick={handleInitiateTransfer}
          disabled={!termsAccepted}
          sx={{ px: 5, ml: "auto", mr: "auto", mt: 1 }}
          variant={"contained"}>
          {t("transfer.beginTransfer")}
        </LoadingButton>
        <RHFGraphqlError error={error} />
      </Stack>
      <NavigationSteps />
    </>
  );
}

function ContentTransfered() {
  const { t } = useTranslation(TranslatorNS.AUTH);
  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      my={4}
      px={{ xs: 3, sm: 0 }}
      width={{ xs: "100%", sm: "49.5%" }}
      flexWrap={"wrap"}>
      {transferContentArr.map((item, index) => (
        <Stack
          key={item.icon + index}
          my={0.5}
          sx={rectangleSx}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}>
            <Stack sx={circleSx}>
              <Iconify color={"white"} icon={item.icon} width={20} height={20} />
            </Stack>
            <Stack flexDirection={"row"}>
              <Typography color={"white"}>{t(item.title)}</Typography>
              {item?.info && (
                <Tooltip title={t(item?.info)}>
                  <Stack mb={0.5} ml={1} justifyContent={"center"}>
                    <Iconify
                      color={"grey.500"}
                      icon={"uil:info-circle"}
                      width={16}
                      height={16}
                      mt={0.5}
                    />
                  </Stack>
                </Tooltip>
              )}
            </Stack>
          </Stack>
          <Iconify
            width={20}
            height={20}
            color={item.enabled ? "success.dark" : "error.dark"}
            icon={item.enabled ? "uil:check-circle" : "uil:times-circle"}
          />
        </Stack>
      ))}
    </Stack>
  );
}
