// https://github.com/ashleyw/react-sane-contenteditable/blob/master/src/react-sane-contenteditable.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";
import { browser } from "../../utils";
import { IS_MOBILE } from "../../../../helpers/devices";
import { styled } from "@mui/material/styles";

const propTypes = {
  content: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  sendMsg: PropTypes.func,
};

const defaultProps = {
  name: "message",
  className: "",
  placeholder: "",
  content: "",
  onChange: () => null,
  sendMsg: () => {},
  canSubmit: true,
};

class ContentEditable extends Component {
  constructor(props) {
    super(props);
    this._element = React.createRef();
    this.state = {
      value: props.content,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.content !== this.props.content) {
      this.setState({
        value: nextProps.content,
      });
    }
  }

  _onChange = e => {
    let value = e.target.value;
    this.setValue(value);
  };

  _onPaste = ev => {
    const { maxLength } = this.props;

    ev.preventDefault();
    const text = ev.clipboardData.getData("text").substr(0, maxLength);
    if (browser.name === "Firefox") {
      this.setValue(this.state.value + text);
    } else {
      document.execCommand("insertText", false, text);
    }
  };

  /*Submit on enter*/
  _onKeyEvent(event, sendMsg) {
    if (!IS_MOBILE && event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      if (!this.props.canSubmit) {
        return;
      }
      sendMsg();
      this.setValue("");
    }
  }

  setValue(value, cb = () => null) {
    this.setState({ value: value }, () => {
      this.props.onChange(value);
      cb();
    });
  }

  paste(content) {
    if (!this._element.current) {
      return console.warn("Unknown ref element");
    }

    const { value } = this.state;
    //const sub1 = value.substring(0, selectionStart);
    //const sub2 = value.substring(selectionEnd, value.length);

    //const concatenated = [ sub1, content, sub2 ].join('');
    const val = value + " " + content;

    this.setValue(val, () => {
      this.textarea.focus();
      //element.selectionStart = sub1.length + content.length;
      //element.selectionEnd = sub1.length + content.length;
    });
  }

  replace(content) {
    this.setValue(content, () => {
      this.textarea.focus();
    });
  }

  flush() {
    this.setValue("");
  }

  render() {
    const { className, placeholder, name, sendMsg } = this.props;
    return (
      <StyledTextareaAutosize
        ref={this._element}
        inputRef={tag => (this.textarea = tag)}
        onKeyDown={e => this._onKeyEvent(e, sendMsg)}
        className={className}
        placeholder={placeholder}
        name={name}
        value={this.state.value}
        style={{ whiteSpace: "pre-line", maxHeight: 180, ...this.props.style }}
        onChange={this._onChange}
        onPaste={this._onPaste}
        maxRows={8}
        useCacheForDOMMeasurements
      />
    );
  }
}

ContentEditable.propTypes = propTypes;
ContentEditable.defaultProps = defaultProps;

const PlaceholderStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  flex: 1,
  minHeight: 40,
  position: "relative",
  padding: "9px 40px 9px 16px",
  backgroundColor: "#f4f6f8",
  borderRadius: "22px",
  border: "none",
  boxShadow: "none",
  outline: "none",
  fontSize: "14px",
  lineHeight: "22px",

  /* WebKit, Blink, Edge */
  "::-webkit-input-placeholder": PlaceholderStyle,
  /* Mozilla Firefox 4 to 18 */
  ":-moz-placeholder": PlaceholderStyle,
  /* Mozilla Firefox 19+ */
  "::-moz-placeholder": PlaceholderStyle,
  /* Internet Explorer 10-11 */
  ":-ms-input-placeholder": PlaceholderStyle,
  /* Microsoft Edge */
  "::-ms-input-placeholder": PlaceholderStyle,
  /* Most modern browsers support this now. */
  "::placeholder": PlaceholderStyle,
}));

export default ContentEditable;
