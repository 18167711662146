import { useReducer, useRef } from "react";
import { ReactSortable } from "react-sortablejs";
import { Item } from "../utils";
import { WorkoutPlan } from "../types";
import SearchExercises from "./SearchExercises";
import ListItem from "./ListItem";
import useExercisesList from "./useExercisesList";
import { uniqBy } from "lodash";
import { ExerciseQuery } from "./SearchExercises";
import InfiniteScroll from "react-infinite-scroller";

enum ActionType {
  UPDATE_QUERY_PARAMS = "UPDATE_QUERY_PARAMS",
  LOAD_MORE_EXERCISES = "LOAD_MORE_EXERCISES",
  UPDATE_EXERCISE_LIST = "UPDATE_EXERCISE_LIST",
}

export interface ExerciseListState {
  searchText: string;
  muscleGroupId?: number;
  equipmentId?: number;
  useOwnExercisesOnly?: boolean;
  page: number;
  limit: number;
  exercises: WorkoutPlan[];
  moreToLoad: boolean;
  loading: boolean;
}

function reducer(
  state: ExerciseListState,
  action: { type: ActionType; payload: Partial<ExerciseListState> },
) {
  switch (action.type) {
    case ActionType.UPDATE_QUERY_PARAMS:
      return { ...state, ...action.payload, page: 1 };
    case ActionType.LOAD_MORE_EXERCISES:
      return { ...state, ...action.payload, loading: true };
    case ActionType.UPDATE_EXERCISE_LIST:
      const shouldAppend = state.page > 1;
      const moreToLoad = action.payload.exercises?.length === state.limit;
      if (shouldAppend) {
        return {
          ...state,
          exercises: uniqBy(
            [...state.exercises, ...(action.payload.exercises || [])],
            "id",
          ),
          moreToLoad,
          loading: false,
        };
      }
      return {
        ...state,
        exercises: action.payload.exercises || [],
        moreToLoad,
        loading: false,
      };

    default:
      throw new Error("Unknown action");
  }
}

const initialState: ExerciseListState = {
  exercises: [],
  searchText: "",
  limit: 25,
  page: 1,
  moreToLoad: true,
  loading: true,
};

export default function ExercisesList() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const containerScrollRef = useRef<HTMLDivElement>(null);
  const updateExerciseList = (payload: any) => {
    dispatch({ type: ActionType.UPDATE_EXERCISE_LIST, payload: { exercises: payload } });
  };
  const {
    page,
    exercises,
    limit,
    searchText,
    muscleGroupId,
    equipmentId,
    useOwnExercisesOnly,
    moreToLoad,
  } = state;

  useExercisesList({
    page,
    limit,
    searchText,
    muscleGroupId,
    equipmentId,
    useOwnExercisesOnly,
    updateExerciseList,
  });
  const setQueryParams = (payload: Partial<ExerciseQuery>) => {
    dispatch({ type: ActionType.UPDATE_QUERY_PARAMS, payload });
  };

  const loadMore = () => {
    if (!state.loading && moreToLoad) {
      dispatch({ type: ActionType.LOAD_MORE_EXERCISES, payload: { page: page + 1 } });
    }
  };

  return (
    <>
      <SearchExercises setQueryParams={setQueryParams} />
      <div
        ref={containerScrollRef}
        style={{ height: "calc(100vh - 240px)", overflowY: "auto" }}>
        <InfiniteScroll
          loadMore={loadMore}
          useWindow={false}
          hasMore={moreToLoad}
          threshold={350}
          getScrollParent={() => containerScrollRef.current}>
          <ReactSortable
            list={exercises}
            setList={() => {}}
            animation={150}
            sort={false}
            multiDrag
            ghostClass="ghost"
            group={{
              name: "cloning-group-name",
              pull: "clone",
              put: false,
            }}
            filter=".disabled"
            clone={item => ({ ...item })}>
            {exercises.map(item => (
              <Item key={item.id}>
                <ListItem
                  exerciseImage={item.image}
                  exerciseVideo={item.video}
                  title={item.title}
                  subTitle={item.subTitle}
                />
              </Item>
            ))}
          </ReactSortable>
        </InfiniteScroll>
      </div>
    </>
  );
}
