import moment from "moment/moment";
import i18next from "i18next";
import { TranslatorNS } from "../../i18n/const";

export function getDurationInMonthsDays(startDate: string, endDate?: string) {
  if (!Boolean(startDate) || !Boolean(endDate)) {
    return "";
  }
  const monthString = i18next.t("info.months", { ns: TranslatorNS.CLIENT_OVERVIEW });
  const dayString = i18next.t("info.days", { ns: TranslatorNS.CLIENT_OVERVIEW });

  const duration = moment.duration(moment(endDate).diff(moment(startDate)));
  const months = Math.floor(duration.asMonths());
  duration.subtract(moment.duration(months, "months"));

  const days = Math.floor(duration.asDays());

  if (isNaN(months)) {
    return "";
  }

  return ` ${months ? months + " " + monthString : ""} ${
    days >= 1 ? `${days} ${dayString}` : ""
  }`;
}
