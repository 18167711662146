import { html, stripIndents } from "common-tags";
import { pictureFilter } from "../helpers";
import { TYPE_MEAL, TYPE_RECIPE, TYPE_WORKOUT } from "../constants";
import isNumber from "lodash/isNumber";
import { ROOT } from "../../../../api/config";
import EXERCISE_DEFAULT from "../../../../assets/images/lists/playIcon.png";
import { TranslatorNS } from "../../../../i18n/const";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function PlanDropZone(type) {
  const title =
    type === TYPE_WORKOUT
      ? window.jsTrans("plan.dropExerciseHere", "plans")
      : window.jsTrans("plan.dropFoodItemHere", "plans");

  return html` <div class="plan-box-drop-zone" data-title="${title}"></div> `;
}

export function PlanExerciseInput({ label, value, name }) {
  return html`
    <div class="form-group">
      <label>${capitalizeFirstLetter(label)}</label>
      <input
        class="form-control ${`plan-item-input-${name}`}"
        value="${value}"
        name="${name}"
        type="text"
      />
    </div>
  `;
}

export function PlanStateLink({ className, isActive, on, off }) {
  const linkClass = `plan-item-state-link${className ? ` ${className}` : ""}${
    isActive ? " is-active" : ""
  }`;

  return html`
    <a class="${linkClass}" tabindex="0" role="button" data-on="${on}" data-off="${off}">
      ${isActive ? on : off}
    </a>
  `;
}

export function PlanItemComment({ comment }) {
  const hasComment = Boolean(comment);

  return `
    <div class="${`plan-item-comment ${hasComment ? "" : "is-hidden"}`}">
      <textarea class="form-control js-plan-item-comment" placeholder="${window.jsTrans(
        "plan.comment",
        "plans",
      )}">${comment}</textarea>
    </div>
  `;
}

export function PlanItemEditName(plan, type, canCollapse) {
  let attributes = [
    `data-title="${plan.name}"`,
    `data-id="${plan.id}"`,
    `data-type="${type}"`,
  ];

  if (canCollapse) {
    attributes.push(`data-hide-upload-image=true`);
  }

  if (type === TYPE_RECIPE) {
    attributes.push(`data-locale="${plan.locale}"`);
    attributes.push(`data-macro-split="${plan.macro_split}"`);
    attributes.push(`data-cooking-time="${plan.cooking_time}"`);
    attributes.push(`data-recipe-type='${JSON.stringify(plan.types)}'`);
    attributes.push(`data-recipe-meta='${JSON.stringify(plan.recipeMeta)}'`);
  }

  return html`
    <a id="plan-link" class="grey-color js-add-plan" ${attributes.join(" ")}>
      <span class="material-icons">mode_edit</span>
    </a>
  `;
}

export function PlanTotalsList(totals) {
  return html`
    <ul class="plans-box-totals-list">
      <li>
        <span>${window.jsTrans("plan.energy", "plans")}</span>
        <var>${Math.round(totals.kcal)}</var>${window.jsTrans("plan.kcalAbbr", "plans")}
      </li>
      <li class="is-blue">
        <span>${window.jsTrans("plan.protein", "plans")}</span>
        <var>${Math.round(totals.protein)}</var>${window.jsTrans(
          "plan.gramsAbbr",
          "plans",
        )}
      </li>
      <li class="is-red">
        <span>${window.jsTrans("plan.carbs", "plans")}</span>
        <var>${Math.round(totals.carbohydrate)}</var>${window.jsTrans(
          "plan.gramsAbbr",
          "plans",
        )}
      </li>
      <li class="is-orange">
        <span>${window.jsTrans("plan.fat", "plans")}</span>
        <var>${Math.round(totals.fat)}</var>${window.jsTrans("plan.gramsAbbr", "plans")}
      </li>
    </ul>
  `;
}

export function PlanExercise(workout, options = {}) {
  const { settings } = options;
  const exercise = workout.exercise;
  const preview = `${ROOT}/api/exercises/exerciseInfo/${exercise.id}`;
  let thumbnail = EXERCISE_DEFAULT;

  if (exercise?.picture?.includes("http")) {
    thumbnail = pictureFilter(options.s3, exercise.picture, "exercises", 50);
  }

  const type = workout.type.name.toLowerCase();
  const hasDropSet = false;
  const isSuperSet = Array.isArray(workout.supers) && Boolean(workout.supers.length);

  const actions = [
    {
      className: "js-plan-item-set-comment",
      on: window.jsTrans("plan.removeComment", "plans"),
      off: window.jsTrans("plan.addComment", "plans"),
      isActive: Boolean(workout.comment),
    },
    {
      className: "js-plan-item-set-dropset",
      on: window.jsTrans("plan.removeDropSet", "plans"),
      off: window.jsTrans("plan.dropSet", "plans"),
      isActive: hasDropSet,
    },
    {
      className: "js-plan-item-set-superset",
      on: window.jsTrans("plan.cancelSuperSet", "plans"),
      off: window.jsTrans("plan.createSuperSet", "plans"),
      isActive: isSuperSet,
    },
  ];

  const inputs = [
    {
      label: window.jsTrans("plan.inputs.sets", "plans"),
      name: "sets",
      value: workout.sets,
    },
    {
      label: window.jsTrans("plan.inputs." + type, "plans"),
      name: type,
      value: workout[type],
    },
    {
      label: window.jsTrans("plan.inputs.RM", "plans"),
      name: "rm",
      value: workout.rm,
    },
    {
      label: window.jsTrans("plan.inputs.weight", "plans"),
      name: "weight",
      value: workout.startWeight,
    },
    {
      label: window.jsTrans("plan.inputs.restInSec", "plans"),
      name: "rest",
      value: workout.rest,
    },
    {
      label: window.jsTrans("plan.inputs.tempo", "plans"),
      name: "tempo",
      value: workout.tempo,
    },
  ].filter(input => settings && settings[input.name]);

  let comment = workout.comment == null ? "" : workout.comment;

  return `
    <div class="plan-item" data-id="${workout.id}" data-exercise-id="${exercise.id}">
      <div class="plans-handle"></div>
      <div class="plan-item-thumb">
        <a
            class="js-exercise-preview"
            href="${preview}"
            role="button"
            data-exercise-type="${workout.type.name}"
        >
          <img src="${thumbnail}" alt="">
          <span></span>
        </a>
      </div>
      <div class="plan-item-details">
        <a class="plan-item-title js-exercise-preview" href="${preview}" data-exercise-type="${
    workout.type.name
  }">${exercise.name}</a>
        <div class="plan-item-details">
          ${actions.map(action => PlanStateLink(action)).join("\n")}
        </div>
      </div>
      <div class="plan-item-inputs">
        ${inputs.map(input => PlanExerciseInput(input))}
        <button class="plan-item-delete js-plan-item-remove ${
          isSuperSet ? "delete-superset" : ""
        }" type="button">
          <i class="material-icons">clear</i>
        </button>
      </div>
      ${PlanItemComment({ comment })}
    </div>
  `;
}

export function PlanSuperSet({ id, children }, type) {
  return html`
    <div class="plan-superset" data-id="${id}">
      <div class="plans-handle plan-superset-handle"></div>
      <div class="plan-superset-divider">${window.jsTrans("plan.superSet", "plans")}</div>
      ${children}
      <div class="plans-box-item-list" data-children="0" data-count="0"></div>
      ${PlanDropZone(type)}
      <div class="plan-superset-divider">
        ${window.jsTrans("plan.endOfSuperSet", "plans")}
      </div>
    </div>
  `;
}

export const getMealHeaderImageHtmlString = plan => {
  return `
    <div class="plans-box-header-image">
        <img id="meal-details-header-image" src="${
          plan.image || "/bundles/app/images/recipe-placeholder.png"
        }" alt="${plan.name}"/>
    </div>`;
};

export function PlanHeader(
  plan,
  type,
  canCollapse = true,
  canClone = true,
  isDraggable = true,
  canDelete = true,
) {
  let className = "plans-box-header";
  let collapseAction = "";

  if (canCollapse) {
    className += " js-plan-toggle";
    collapseAction = html`
      <button class="plans-box-header-action js-plan-open" type="button">
        <span class="material-icons">expand_more</span>
      </button>
      <button
        class="plans-box-header-action js-plan-close"
        style="display: none"
        type="button"
      >
        <span class="material-icons">expand_less</span>
      </button>
    `;
  }

  let saveRecipeAction = "";
  const canSaveRecipe = !!plan.recipe;
  if (canSaveRecipe) {
    saveRecipeAction = `
      <button
        class="plans-box-header-action js-recipe-save"
        type="button"
        data-source-meal="${plan.id}"
        data-id="${plan.recipe}"
        data-toggle="modal"
        role="button"
      >
        <span class="material-icons">save_alt</span>
      </button>
    `;
  }

  let cloneAction = "";

  if (canClone) {
    cloneAction = `<button class="plans-box-header-action js-plan-clone" data-id="${plan.id}" data-title="${plan.name}" type="button">
      <span class="material-icons">content_copy</span>
    </button>`;
  }

  let deleteAction = "";

  if (canDelete) {
    deleteAction = `
      <button class="plans-box-header-action js-plan-delete" type="button">
        <span class="material-icons">delete</span>
      </button>
    `;
  }

  let dragHandle = "";

  if (isDraggable) {
    dragHandle = `<div class="plans-handle plans-box-handle ${type}_handler"></div>`;
  }

  let diffText = "";
  let diffElement = "";

  let serviceLabel = "";
  if (plan?.servings > 1) {
    serviceLabel = `<span data-servings="10" id="titleServing" class="servingsLabel">${plan.servings} servings</span>`;
  }

  if (type === TYPE_MEAL && plan.contains_alternatives && +plan.desired_kcals > 0) {
    const count = Math.abs(plan.ideal_kcals - plan.totals.kcal);
    const diffPercent = (count / plan.ideal_kcals) * 100;

    if (diffPercent >= 10) {
      diffText = window.jsTrans("plan.diffKcalsOff", "plans", { count });
      const bgColor = "#FF4842";
      const color = "white";
      diffElement = `<span style="background-color: ${bgColor}; color: ${color}" class="offLabel">${diffText}</span>`;
    }
  }

  let headerBody = `
    <h5>
      <span id="plan-name">${plan.name}</span>
      ${PlanItemEditName(plan, type, canCollapse)}
      ${serviceLabel}
      ${diffElement}
    </h5>
    <div class="plans-box-header-actions">
      ${saveRecipeAction}
      ${cloneAction}
      ${deleteAction}
      ${collapseAction}
    </div>
  `;

  const hasImage =
    type === TYPE_RECIPE ||
    (type === TYPE_MEAL &&
      plan.hasOwnProperty("image") &&
      plan.hasOwnProperty("products"));

  const thumbRE = /thumbnail/i;
  const headerImage =
    hasImage && plan.image && !thumbRE.test(plan.image)
      ? getMealHeaderImageHtmlString(plan)
      : "";

  if (hasImage) {
    headerBody = `
      ${headerImage}
      <div id="meal-details-header" class="plans-box-header-wrapper">
        <div class="plans-box-header-main">
          ${headerBody}
        </div>
        <!--Change image moved to /recipe-->
        ${PlanMealTotals(plan.totals)}
      </div>
    `;
  }

  return html` <header class="${className}">${dragHandle} ${headerBody}</header> `;
}

export function PlanComment(
  plan,
  type,
  withHandler = true,
  placeholder = null,
  handler = null,
  props = {},
) {
  if (!placeholder) {
    switch (type) {
      case TYPE_MEAL:
        placeholder = window.jsTrans("plan.commentToMealPlan", "plans");
        break;
      case TYPE_WORKOUT:
        placeholder = window.jsTrans("plan.commentToDay", "plans");
        break;
      case TYPE_RECIPE:
        placeholder = window.jsTrans("plan.writeTheRecipeToClient", "plans");
        break;
    }
  }

  const containerClass = props.containerClass
    ? props.containerClass
    : "plans-box-comment";
  const hasComment = Boolean(plan.comment);
  const commentValue = hasComment ? plan.comment : "";

  return `
    <div class="${containerClass}">
      ${
        withHandler
          ? `<a tabindex="0" role="button" class="${hasComment ? "hidden" : ""}">${
              handler || window.jsTrans("plan.commentForPlan", "plans")
            }</a>`
          : ""
      }
      <textarea class="form-control ${
        withHandler && !hasComment ? "hidden" : ""
      }" placeholder="${placeholder}">${commentValue}</textarea>
    </div>
  `;
}

export function PlanProduct(meal, locale, options = {}) {
  const product = meal.product;
  let name = product.name;

  if (product.brand) {
    name += `, ${product.brand}`;
  }

  if (product.recommended && options.admin) {
    name += ` <span class="glyphicon glyphicon-star text-success"></span>`;
  }

  let chooseAmountText = window.jsTrans("plan.chooseAmount", "plans");

  if (meal.weight) {
    chooseAmountText = `${meal.weightUnits} x ${meal.weight.name} (${Math.round(
      meal.weight.weight,
    )}${window.jsTrans("plan.gramsAbbr", "plans")})`;
  } else if (meal.totalWeight) {
    chooseAmountText = `${Math.round(meal.totalWeight)}${window.jsTrans(
      "plan.gramsAbbr",
      "plans",
    )}`;
  }

  const totalEnergy = Math.round((meal.product.kcal / 100) * meal.totalWeight);

  return html`
    <div
      class="plan-item"
      data-id="${meal.id}"
      data-product-id="${product.id}"
      data-kcal="${product.kcal}"
      data-protein="${product.protein}"
      data-carbohydrates="${product.carbohydrates}"
      data-fat="${product.fat}"
      data-total-weight="${meal.totalWeight}"
      data-weight-units="${meal.weightUnits}"
      data-weight-id="${meal.weight && meal.weight.id}"
    >
      <div class="plans-handle"></div>
      <div class="plan-item-details">
        <span class="plan-item-title">${name}</span>
      </div>
      <div class="plan-item-inputs">
        <div class="form-group">
          <a class="js-plans-choose-amount" role="button" tabindex="0">
            ${chooseAmountText}
          </a>
        </div>
        <div class="form-group">
          <span class="js-plans-product-kcal">${totalEnergy}</span>${window.jsTrans(
            "plan.kcalAbbr",
            "plans",
          )}
        </div>
        <button class="plan-item-delete js-plan-item-remove" type="button">
          <i class="material-icons">clear</i>
        </button>
      </div>
    </div>
  `;
}

export function PlanTotalsDrop(data, weight = 0, showChart = false) {
  const keys = ["kcal", "protein", "carbohydrate", "fat"];
  const totals = {};
  let minWeight = weight;

  if (isNaN(weight) || typeof weight !== "number") {
    minWeight = 50;
  }

  keys.forEach(key => {
    const value = parseFloat(data[key]) || 0;

    let max = Math.round(value);
    let min = Math.round(value / (100 / minWeight));

    if (!isFinite(max)) {
      max = 0;
    }

    if (!isFinite(min)) {
      min = 0;
    }

    totals[key] = { min, max };
  });

  return html`
    <h5>${data.title}</h5>
    <ul class="plans-box-totals-list">
      <li class="is-muted">
        <span>${window.jsTrans("plan.inThePortion", "plans")}</span>
        <var>${minWeight}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
        <var>100${window.jsTrans("plan.gramsAbbr", "plans")}</var>
      </li>
      <li>
        <span>${window.jsTrans("plan.energy", "plans")}</span>
        <var>${totals.kcal.min}${window.jsTrans("plan.kcalAbbr", "plans")}</var>
        <var>${totals.kcal.max}${window.jsTrans("plan.kcalAbbr", "plans")}</var>
      </li>
      <li class="is-blue">
        <span>${window.jsTrans("plan.protein", "plans")}</span>
        <var>${totals.protein.min}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
        <var>${totals.protein.max}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
      </li>
      <li class="is-red">
        <span>${window.jsTrans("plan.carbs", "plans")}</span>
        <var>${totals.carbohydrate.min}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
        <var>${totals.carbohydrate.max}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
      </li>
      <li class="is-orange">
        <span>${window.jsTrans("plan.fat", "plans")}</span>
        <var>${totals.fat.min}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
        <var>${totals.fat.max}${window.jsTrans("plan.gramsAbbr", "plans")}</var>
      </li>
    </ul>
  `;
}

export function PlanMealAmountDrop(mealProduct, locale) {
  const weightId = mealProduct.weight ? parseInt(mealProduct.weight.id, 10) : 0;
  const isLenusIngredient = mealProduct.isLenusIngredient;
  const weights = [
    {
      id: 0,
      name: window.jsTrans("plan.gram", "plans"),
    },
  ].concat(
    isLenusIngredient
      ? mealProduct.weights
      : mealProduct.weights.filter(x => {
          return !("locale" in x) || (x.locale && x.locale === locale);
        }),
  );

  return html`
    <form class="plan-item-amount-form" onsubmit="return false;">
      ${weights.map((row, index) => {
        const id = parseInt(row.id, 10);
        const isBase = index === 0;
        const isChecked = weightId === id;
        let value = 1;

        if (isBase) {
          value = parseInt(mealProduct.totalWeight, 10) || 100;
        } else {
          if (isChecked) {
            value = mealProduct.weightUnits || 1;
          }
        }

        const suffixContainer = `<div class="plan-item-amount-total${
          isChecked ? " is-visible" : ""
        }">`;

        return html`
          <div class="plan-item-amount-row">
            <div>
              <input class="plan-item-amount-radio" type="radio" name="weight_type" value="${id}" id="weight_type_${id}" ${
          isChecked ? " checked" : ""
        }/>
            </div>
            <label class="control-label" for="weight_type_${id}">
              ${row.name}
              ${
                isBase
                  ? ""
                  : ` (${Math.round(row.weight)}${window.jsTrans(
                      "plan.gramsAbbr",
                      "plans",
                    )})`
              }
            </label>
            ${isBase ? "" : suffixContainer}
                <input type="number" value="${parseFloat(
                  value,
                )}" step="0.01" class="form-control text-right plan-item-amount-value" ${
          isBase ? "" : `data-weight="${row.weight}"`
        }>
            ${isBase ? suffixContainer : ""}
                ${isBase ? "" : " " + window.jsTrans("plan.pcs", "plans")}
                (<var>${mealProduct.totalKcal}</var> ${window.jsTrans(
          "plan.kcalAbbr",
          "plans",
        )})
            </div>
          </div>
        `;
      })}
      <div class="plan-item-amount-actions">
        <button class="btn btn-success" type="submit">
          ${window.jsTrans("plan.saveAmount", "plans")}
        </button>
        <div>
          <a class="product-weights-cancel js-close-amount" href="#"
            >${window.jsTrans("plan.cancel", "plans")}</a
          >
        </div>
      </div>
    </form>
  `;
}

export function PlanMealTotals(totals) {
  return html`
    <div class="plans-meal-totals">
      <div class="plan-totals-col">
        <span>${window.jsTrans("plan.carbs", "plans")}</span>
        <var>${Math.round(totals.carbohydrate)}</var>${window.jsTrans(
          "plan.gramsAbbr",
          "plans",
        )}
      </div>
      <div class="plan-totals-col">
        <span>${window.jsTrans("plan.protein", "plans")}</span>
        <var>${Math.round(totals.protein)}</var>${window.jsTrans(
          "plan.gramsAbbr",
          "plans",
        )}
      </div>
      <div class="plan-totals-col">
        <span>${window.jsTrans("plan.fat", "plans")}</span>
        <var>${Math.round(totals.fat)}</var>${window.jsTrans("plan.gramsAbbr", "plans")}
      </div>
      <div class="plan-totals-col">
        <var>${Math.round(totals.weight)}</var>
        ${window.jsTrans("plan.gramsAbbr", "plans")}
      </div>
      <div class="plan-totals-col">
        <var>${Math.round(totals.kcal)}</var>
        ${window.jsTrans("plan.kcalAbbr", "plans")}
      </div>
    </div>
  `;
}

export function PlanTotalsChart(title, totals) {
  return html`
    <aside class="plans-box-side">
      <div class="plan-box-side-widget">
        <h2 class="plans-box-side-title">${title}</h2>
        ${PlanTotalsList(totals)}
        <div class="plans-box-side-chart"></div>
      </div>
    </aside>
  `;
}

export function PlanMeal(meal, type) {
  const totalProducts = meal.products.length;
  const hasImage = meal.hasOwnProperty("image") && meal.hasOwnProperty("products");

  const template = html`
    <div
      class="plans-meal-plan"
      data-id="${meal.id}"
      data-protein="${meal.totals.protein}"
      data-carbohydrate="${meal.totals.carbohydrate}"
      data-servings="${meal?.servings}"
      data-fat="${meal.totals.fat}"
      data-kcal="${meal.totals.kcal}"
      data-weight="${meal.totals.weight}"
      data-ideal-kcals="${meal.ideal_kcals}"
    >
      ${PlanHeader(meal, type, false)} ${hasImage ? "" : PlanMealTotals(meal.totals)}
      <div
        class="plans-box-item-list plans-box-item-list--products"
        data-count="${totalProducts}"
      ></div>
      ${PlanDropZone(type)}
      ${PlanComment(
        meal,
        type,
        true,
        window.jsTrans("plan.commentToThisMeal", "plans"),
        window.jsTrans("plan.addComment", "plans"),
      )}
    </div>
  `;

  return stripIndents`${template}`;
}

export function PlanCollapsedDescription(plan, type) {
  let children = "";

  switch (type) {
    case TYPE_WORKOUT:
      const exercises =
        plan.workouts.length + plan.workouts.filter(x => x.supers.length).length;
      const supersets = plan.workouts.filter(x => x.supers.length).length;

      children = html`
        <span
          >${window.jsTrans("plan.exercisesInTotal", "plans", { count: exercises })}</span
        >
        <span>${window.jsTrans("plan.superSets", "plans", { count: supersets })}</span>
      `;
      break;
    case TYPE_MEAL:
      const meals = plan.meals?.length;
      if (plan.contains_alternatives) {
        children = html`
          <span
            >${window.jsTrans("plan.alternativesWith", "plans", {
              meals: meals,
              kcal: Math.round(plan.avg_totals?.kcal),
            })}</span
          >
        `;
        break;
      } else {
        children = html`
          <span
            >${window.jsTrans("plan.mealsWith", "plans", {
              meals: meals,
              kcal: Math.round(plan.totals?.kcal),
            })}</span
          >
        `;
        break;
      }
  }

  return html` <div class="plans-box-collapsed-description">${children}</div> `;
}

export function PlanMealActions(plan, type, masterPlan, options) {
  const recipeParams = {
    plan: masterPlan.id,
    meal: plan.id,
    locale: masterPlan.locale || options.locale,
  };

  if (isNumber(plan.macroSplit)) {
    recipeParams.macroSplit = plan.macroSplit;
  }

  if (isNumber(plan.type)) {
    recipeParams.type = plan.type;
  }

  return html`
    <div class="plans-box-meal-actions">
      <button
        class="btn btn-success js-add-plan"
        data-parent="${plan.id}"
        data-type="${type}"
      >
        + ${window.jsTrans("plan.addNewMeal", "plans")}
      </button>
    </div>
  `;
}

export function Plan(plan, type, masterPlan, options) {
  let listAttr = [];
  if (type === TYPE_WORKOUT) {
    const totalWorkouts = plan.workouts.length;

    listAttr.push(`data-count="${totalWorkouts}"`);
  } else if (type === TYPE_RECIPE) {
    const totalProducts = plan.products.length;
    ["kcal", "protein", "carbohydrate", "fat", "weight"].forEach(key =>
      listAttr.push(`data-${key}="${plan.totals[key]}"`),
    );
    listAttr.push(`data-count="${totalProducts}"`);
  }

  let canCollapse = type !== TYPE_RECIPE;
  let canClone = canCollapse;
  let canDelete = canCollapse;

  let isCollapsed = canCollapse && !plan.last;
  let isDraggable = canCollapse;

  let expandFooterAction = "";

  if (canCollapse) {
    expandFooterAction = `
      <div>
        <div class="plans-box-plan expand-plan js-plan-open" style="display: block; padding: 6px;">
            <i class="material-icons expand-icon">
                expand_more
            </i>
        </div>
        <div class="plans-box-plan expand-plan js-plan-close" style="display: none; padding: 6px;">
            <i class="material-icons expand-icon">
                expand_less 
            </i>
        </div>
      </div>
    `;
  }

  let collapsedDescription = "";

  if (canCollapse) {
    collapsedDescription = PlanCollapsedDescription(plan, type);
  }

  window.jsTrans("plan.totalCalorieCounter", "plans");

  if (plan.contains_alternatives) {
    window.jsTrans("plan.targetCalorieCounter", "plans");
  }

  let totalCharts = ""; // type !== TYPE_WORKOUT ? PlanTotalsChart(chartTitle, chartTotals) : '';
  let description = "";

  if (type === TYPE_MEAL && plan.contains_alternatives) {
    const planTotals = plan.ideal_totals || plan.totals;

    if (plan.locale == "dk") {
      description = `<div class="plans-box-description">
         Til ${plan.name.toLowerCase()} skal du have <var>${Math.round(
        planTotals.kcal,
      )} kcal</var>
         bestående af <var>${Math.round(
           planTotals.carbohydrate,
         )}g kulhydrater, ${Math.round(planTotals.protein)}g protein</var>
         og  <var>${Math.round(planTotals.fat)}g fedt</var>.<br/>
         Dette er reflekteret i disse ${plan.meals.length} alternativer.
      </div>`;
    } else {
      description = `<div class="plans-box-description">
         ${window.jsTrans("plan.desc.forNameYouShouldConsume", "plans", {
           name: plan.name.toLowerCase(),
         })} <var>${Math.round(planTotals.kcal)} ${window.jsTrans(
        "plan.kcalAbbr",
        "plans",
      )}</var>
         ${window.jsTrans(
           "plan.desc.basedOnApproximately",
           "plans",
         )} <var>${window.jsTrans("plan.desc.totals1", "plans", {
        carbs: Math.round(planTotals.carbohydrate),
        protein: Math.round(planTotals.protein),
      })}</var>
         ${window.jsTrans("plan.desc.and", "plans")} <var>${window.jsTrans(
        "plan.desc.totals2",
        "plans",
        { fat: Math.round(planTotals.fat) },
      )}</var>.<br/>
         ${window.jsTrans("plan.desc.thisIsReflected", "plans", {
           count: plan.meals.length,
         })}
      </div>`;
    }
  }
  plan.desired_kcals = masterPlan.desired_kcals;
  return `
    <div class="plans-box${isCollapsed ? " is-collapsed" : " "} ${
    "plan_type_" + type
  }" data-id="${plan.id}" data-contains-alternatives="${
    plan.contains_alternatives
  }" data-meals="${plan.meals ? plan.meals.length : 0}">
      <div class="plans-box-plan">
        ${PlanHeader(plan, type, canCollapse, canClone, isDraggable, canDelete)}
        ${description}
        ${collapsedDescription}
        <div class="plans-box-item-list" ${listAttr.join(" ")}></div>
        ${type === TYPE_WORKOUT || TYPE_RECIPE ? PlanDropZone(type) : ""}
        ${
          type === TYPE_MEAL && plan.meals && plan.meals.length > 1
            ? PlanMealActions(plan, type, masterPlan, options)
            : ""
        }
        ${
          type === TYPE_RECIPE || type == TYPE_WORKOUT || type == TYPE_MEAL
            ? PlanComment(plan, type, false)
            : ""
        }
        ${expandFooterAction}
      </div>
      ${totalCharts}
    </div>
  `;
}
