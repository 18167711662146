import { useTranslation } from "react-i18next";

const TermsAccepted = () => {
  const { t } = useTranslation("auth");
  const termsLink = "https://zenfit.io/terms-conditions/";
  const privacyLink = "https://zenfit.io/privacy-policy-cookies/";
  return (
    <span className={"termsTxt"} style={{ fontWeight: "500", fontSize: 13 }}>
      {t("signup.inputs.iHaveReadAndAccept")}{" "}
      <a href={termsLink} target={"_blank"} style={{ fontWeight: "500", fontSize: 13 }}>
        {t("signup.inputs.theTermsOfService")}
      </a>
      {t("signup.inputs.and")}{" "}
      <a href={privacyLink} target={"_blank"} style={{ fontWeight: "500", fontSize: 13 }}>
        {t("signup.inputs.privacyPolicy")}
      </a>
    </span>
  );
};
export default TermsAccepted;
