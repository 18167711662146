import { ROOT } from "../config";

export const SET_CLIENT_SETTINGS = (clientId: number) => {
  return `${ROOT}/api/client/settings/set-client-settings/${clientId}`;
};

export const SEND_EMAIL = () => {
  return `${ROOT}/api/trainer/send-email-to-client`;
};

export const SAVE_TEMPLATE = () => {
  return `${ROOT}/api/trainer/set-default-message`;
};
