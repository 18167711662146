import {
  FETCH_MEAL_TEMPLATE,
  FETCH_MEAL_TEMPLATE_FAILED,
  FETCH_MEAL_TEMPLATE_SUCCESS,
  FETCH_RECIPE_TEMPLATE,
  FETCH_RECIPE_TEMPLATE_FAILED,
  FETCH_RECIPE_TEMPLATE_SUCCESS,
  FETCH_WORKOUT_TEMPLATE,
  FETCH_WORKOUT_TEMPLATE_FAILED,
  FETCH_WORKOUT_TEMPLATE_SUCCESS,
  RESET_STORE
} from "./types";
import axios from "axios";
import { GET_WORKOUT_PLAN } from "../../../api/private/workout-api";
import { GET_MEAL_PLAN } from "../../../api/private/meal-api";
import { GET_RECIPE } from "../../../api/private/recipes";

export const fetchMealPlanTemplate = (templateId: number) => {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_MEAL_TEMPLATE });
    try {
      const { data } = await axios.get(GET_MEAL_PLAN(templateId));
      dispatch({ type: FETCH_MEAL_TEMPLATE_SUCCESS, payload: data[0] });
    } catch (e) {
      dispatch({ type: FETCH_MEAL_TEMPLATE_FAILED, error: e });
    }
  };
};

export const fetchWorkoutPlanTemplate = (templateId: number) => {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_WORKOUT_TEMPLATE });
    try {
      const { data } = await axios.get(GET_WORKOUT_PLAN(templateId));
      dispatch({ type: FETCH_WORKOUT_TEMPLATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: FETCH_WORKOUT_TEMPLATE_FAILED, error: e });
    }
  };
};
export const fetchRecipeTemplate = (templateId: number) => {
  return async (dispatch: any) => {
    dispatch({ type: FETCH_RECIPE_TEMPLATE });
    try {
      const { data } = await axios.get(GET_RECIPE(templateId));
      dispatch({ type: FETCH_RECIPE_TEMPLATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: FETCH_RECIPE_TEMPLATE_FAILED, error: e });
    }
  };
};
export const resetStore = () => {
  return { type: RESET_STORE };
}
