import React from "react";

export default function ZenfitDark({ color = "#000000" }: { color?: string }) {
  return (
    <svg
      width="120px"
      height="28px"
      viewBox="0 0 90 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>Zenfit-Logo</title>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor={color} offset="0%" />
          <stop stopColor={color} offset="100%" />
        </linearGradient>
      </defs>
      <g
        id="Signup-(trainer)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g id="1-Signup" transform="translate(-30.000000, -22.000000)" fillRule="nonzero">
          <g id="Zenfit-Logo" transform="translate(30.000000, 22.000000)">
            <path
              d="M0.166175052,8.85937468 L8.15955247,8.85937468 L6.9775654,15.1073408 L18.5294118,15.1073408 C16.8302985,18.2708812 13.4790902,20.2513919 9.82755688,20.25 C4.40006523,20.25 0,15.9441923 0,10.6310163 C0,10.0258949 0.0571785127,9.43301577 0.166175052,8.85937468 Z"
              id="Path"
              fill="url(#linearGradient-1)"></path>
            <path
              d="M0.861184866,6.47157792 C2.53541543,3.2758239 5.90813248,1.26463351 9.5914399,1.26562459 C15.0027549,1.26562459 19.3897059,5.53884472 19.3897059,10.8098717 C19.3897059,11.4415273 19.3273533,12.0593003 19.2062111,12.6562495 L11.8031757,12.6562495 L13.1348492,6.47157792 L0.860294118,6.47157792 L0.861184866,6.47157792 Z"
              id="Path"
              fill="url(#linearGradient-1)"></path>
            <polygon
              id="Path"
              fill={color}
              points="39.2426471 18.1617181 39.2426471 14.9415424 33.3270559 14.9415424 39.1417201 9.02109655 39.1417201 5.94843728 28.8071661 5.94843728 28.8071661 9.14252776 34.2153948 9.14252776 28.6544118 14.8686837 28.6544118 18.1608186 39.2426471 18.1608186"></polygon>
            <path
              d="M57.6785938,10.8942478 C57.6785938,9.6969361 58.4951187,8.81078519 59.7701397,8.81078519 C61.1730281,8.81078519 61.8361121,9.69605959 61.8361121,10.8705821 L61.8361121,17.7187494 L65.7132353,17.7187494 L65.7132353,10.2473839 C65.7132353,7.63713921 64.2847734,5.50546855 61.0963076,5.50546855 C59.7957132,5.50546855 58.2402972,6.05766942 57.5516398,7.18223088 L57.5516398,5.81750586 L53.8014706,5.81750586 L53.8014706,17.7187494 L57.6776805,17.7187494 L57.6776805,10.8942478 L57.6785938,10.8942478 Z"
              id="Path"
              fill={color}></path>
            <path
              d="M77.2884821,8.78715995 L77.2884821,17.7187494 L81.1985294,17.7187494 L81.1985294,5.6894579 L72.5816879,5.6894579 L72.5816879,4.64743914 C72.5816879,3.437067 73.5847604,3.14643591 74.2534749,3.14643591 C74.819027,3.14643591 75.1791752,3.21909368 75.3587887,3.26782756 L75.3587887,0.265821113 C74.9995617,0.0974677413 74.2018937,0 73.5589694,0 C70.4456696,0 68.6716412,1.88732991 68.6716412,4.64743914 L68.6716412,5.68857183 L66.6397059,5.68857183 L66.6397059,8.78715995 L68.6716412,8.78715995 L68.6716412,17.7187494 L72.5816879,17.7187494 L72.5816879,8.78715995 L77.2884821,8.78715995 L77.2884821,8.78715995 Z"
              id="Path"
              fill={color}></path>
            <path
              d="M76.7655979,2.11948587 C76.7655979,3.28777508 77.7616921,4.2398436 78.9566486,4.2398436 C80.2024352,4.2398436 81.1985294,3.28690322 81.1985294,2.11948587 C81.1985294,0.928528361 80.2024352,0 78.9557565,0 C77.7608001,0 76.7647059,0.928528361 76.7647059,2.11948587 L76.7655979,2.11948587 Z"
              id="Path"
              fill={color}></path>
            <path
              d="M87.566269,2.53124991 L84.1541949,2.53124991 L84.1541949,4.06678541 C84.1541949,5.17163266 83.5271206,6.01191425 82.2226626,6.01191425 L81.5955882,6.01191425 L81.5955882,9.20516012 L83.8029256,9.20516012 L83.8029256,14.3206819 C83.8029256,16.697378 85.3840866,18.1617181 87.9678483,18.1617181 C89.171687,18.1617181 89.7996593,17.8980314 89.94969,17.825957 L89.94969,14.8005917 C89.724195,14.8480553 89.3477707,14.9201297 88.946192,14.9201297 C88.118777,14.9201297 87.566269,14.656443 87.566269,13.6720127 L87.566269,9.20516012 L90,9.20516012 L90,6.01279321 L87.566269,6.01279321 L87.566269,2.53124991 Z"
              id="Path"
              fill={color}></path>
            <path
              d="M46.0785682,5.50546855 C50.0365353,5.50546855 52.4779412,7.78654699 52.4779412,11.6296587 C52.4779412,11.6548869 52.4778123,11.6802336 52.4775644,11.7056332 L52.4769123,11.756483 L52.4769123,11.756483 L52.4759597,11.8073563 C52.4662674,12.2564953 52.4234014,12.6946601 52.4006407,12.7583311 L52.4006407,12.7583311 L43.5092485,12.7583311 C43.586549,14.1023654 44.8969757,15.0631433 46.4126166,15.0631433 C47.8261105,15.0631433 48.6230414,14.4390332 48.9828566,13.5019891 L48.9828566,13.5019891 L52.2469601,14.3669529 C51.6294767,16.4317904 49.5994197,18.1617181 46.3620032,18.1617181 C42.8411518,18.1617181 39.7058824,15.8076803 39.7058824,11.7975531 C39.7058824,7.95532042 42.7638513,5.50546855 46.0785682,5.50546855 Z M46.1301019,8.38692334 C44.4856268,8.38692334 43.6629292,9.51647469 43.586549,10.4042933 L43.586549,10.4042933 L48.7003418,10.4042933 C48.6488082,9.49186189 47.9797912,8.38692334 46.1301019,8.38692334 Z"
              id="Combined-Shape"
              fill={color}></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
