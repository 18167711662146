import { LocaleEnum } from "../../generated/graphql";

export function getFlagEmojiFromCountryCode(countryCode: LocaleEnum) {
  const getLastCountryCode = countryCode.split("_")[1];
  const codePoints = getLastCountryCode
    .toUpperCase()
    .split("")
    //@ts-ignore
    .map(char => 127397 + char.charCodeAt());

  return String.fromCodePoint(...codePoints);
}
