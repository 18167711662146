import { INIT_CHAT_WIDGET } from "../constants";

const INITIAL_STATE = {
  isChatWidgetInitialized: false,
  userId: null,
  clientId: null,
  clientName: null,
  clientPhoto: null,
};

export default function WidgetReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case INIT_CHAT_WIDGET:
      return { ...state, ...payload, isChatWidgetInitialized: true };

    default:
      return state;
  }
}
