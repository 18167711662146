import { ClientStatusType, EventNameEnum, EventType } from "../../../generated/graphql";

export enum EventNameEnumForUnsubscribed {
  RequestedUnsubscribe = "RequestedUnsubscribe",
}

export interface ITaskTooltip {
  eventId: EventType["id"];
  eventName: EventNameEnum | EventNameEnumForUnsubscribed;
  statusId: ClientStatusType["id"];
  createdAt: ClientStatusType["date"];
}

export default function useGetClientTaskTooltipContent() {
  const getClientTasksNameId = (
    tasks: ClientStatusType[],
    eventArray: EventNameEnum[],
  ): ITaskTooltip[] => {
    return tasks
      .filter((t: ClientStatusType) => eventArray.includes(t.event.name))
      .map(t => ({
        eventId: t.event.id,
        eventName: t.event.name,
        statusId: t.id,
        createdAt: t.date,
      }));
  };

  return {
    getClientTasksNameId,
  };
}
