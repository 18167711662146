import { GridColDef } from "@mui/x-data-grid";
import i18n from "i18next";
import { TranslatorNS } from "../../../../i18n/const";
import ClientVideosVisibility from "./RenderCells/ClientVideosVisibility";
import ClientVideosTitle from "./RenderCells/ClientVideosTitle";
import TagIcons from "../../../../components/TableTagIcons";
import ClientVideosComment from "./RenderCells/ClientVideosComment";
import { IS_MOBILE } from "../../../../helpers/devices";

export const videoColumns = (): GridColDef[] => {
  const t = (key: string) => i18n.t(key, { ns: TranslatorNS.LISTS });
  return [
    {
      headerName: t("videos.const.tableTitle.title"),
      field: "title",
      flex: 1,
      renderCell: ({ row }) => <ClientVideosTitle row={row} />,
    },
    {
      headerName: t("videos.const.tableTitle.comment"),
      field: "comment",
      hide: IS_MOBILE,
      flex: 1,
      renderCell: ({ row }) => <ClientVideosComment row={row} />,
    },
    {
      headerName: t("videos.const.tableTitle.assignTo"),
      field: "assignTo",
      hide: IS_MOBILE,
      flex: 1,
      renderCell: ({ row }) => <TagIcons limit={2} tags={row.assignTo} />,
    },
    {
      headerName: t("videos.const.tableTitle.visibility"),
      field: "visibility",
      width: IS_MOBILE ? 150 : 200,
      renderCell: ({ row }) => <ClientVideosVisibility row={row} />,
    },
    /*    {
      headerName: "",
      hideSortIcons: true,
      field: "",
      hide: IS_MOBILE,
      width: 100,
      renderCell: ({ row }) => <ClientVideosActions row={row} />,
    },*/
  ];
};
