import { useEffect, useState } from "react";
import { GET_MACRO_SPLITS } from "../api/private/meals";
import axios from "axios";

enum Macro {
  CARBS = "c",
  FAT = "f",
  PROTEIN = "p",
}

interface MacroSplitResponse {
  lenus: {
    [index: number]: {
      title: string;
      protein: number;
      carbohydrate: number;
      fat: number;
    };
  };
  zenfit: {
    [index: number]: {
      title: string;
      protein: number;
      carbohydrate: number;
      fat: number;
    };
  };
}

export type TMacroSplitEntry = {
  value: number;
  label: string;
  protein: number;
  carbohydrate: number;
  fat: number;
};

function appendMacroSplitSymbol(macroTitle: string) {
  const orderedDisplay = [Macro.PROTEIN, Macro.CARBS, Macro.FAT];
  return macroTitle
    .split("/")
    .map((macro, i) => `${macro}${orderedDisplay[i]}`)
    .join("/");
}

async function getMacroSplits(): Promise<MacroSplitResponse> {
  const { data } = await axios.get(GET_MACRO_SPLITS());
  return data;
}

function getPlanSpecifcMacroSplit({
  macroSplitMap,
  useLenusMacroSplits,
}: {
  macroSplitMap: MacroSplitResponse;
  useLenusMacroSplits: boolean;
}) {
  if (useLenusMacroSplits) {
    return macroSplitMap.lenus;
  }
  return macroSplitMap.zenfit;
}

function getFormattedMacroSplits(macroSplitMap: MacroSplitResponse) {
  // Formatted zenfit macrosplit
  const lenusMacroSplits = getPlanSpecifcMacroSplit({
    macroSplitMap,
    useLenusMacroSplits: true,
  });
  const lenusMacroSplitOptions = Object.entries(lenusMacroSplits).map(([key, value]) => ({
    value: Number(key),
    label: appendMacroSplitSymbol(value["title"]),
    protein: value?.protein,
    carbohydrate: value?.carbohydrate,
    fat: value?.fat,
  }));
  const lenusMacroSplitMap = lenusMacroSplitOptions.reduce(
    (acc, i) => ({ ...acc, [Number(i.value)]: i.label }),
    {},
  );
  // Formatted zenfit macrosplit
  const zenfitMacroSplits = getPlanSpecifcMacroSplit({
    macroSplitMap,
    useLenusMacroSplits: false,
  });
  const zenfitMacroSplitOptions = Object.entries(zenfitMacroSplits).map(
    ([key, value]) => ({
      value: Number(key),
      label: appendMacroSplitSymbol(value["title"]),
      protein: value?.protein,
      carbohydrate: value?.carbohydrate,
      fat: value?.fat,
    }),
  );
  const zenfitMacroSplitMap = zenfitMacroSplitOptions.reduce(
    (acc, i) => ({ ...acc, [Number(i.value)]: i.label }),
    {},
  );
  return {
    lenusMacroSplitOptions,
    lenusMacroSplitMap,
    zenfitMacroSplitOptions,
    zenfitMacroSplitMap,
  };
}

/**
 *
 * @param planId Optional (use if lenus recipe to get lenus macros)
 * @returns
 */
export default function useMacroSplit() {
  const [isLoading, setisLoading] = useState(true);

  const [lenusMacroSplitArray, setLenusMacroSplitArray] = useState<TMacroSplitEntry[]>(
    [],
  );
  const [lenusMacroSplitMap, setLenusMacroSplitMap] = useState<{
    [index: number]: string;
  }>({});

  const [zenfitMacroSplitArray, setZenfitMacroSplitArray] = useState<TMacroSplitEntry[]>(
    [],
  );
  const [zenfitMacroSplitMap, setZenfitMacroSplitMap] = useState<{
    [index: number]: string;
  }>({});

  useEffect(() => {
    setisLoading(true);

    const getMacroSplitOptions = async () => {
      try {
        const data = await getMacroSplits();
        const {
          lenusMacroSplitOptions,
          lenusMacroSplitMap,
          zenfitMacroSplitOptions,
          zenfitMacroSplitMap,
        } = getFormattedMacroSplits(data);
        // lenus
        setLenusMacroSplitArray(lenusMacroSplitOptions);
        setLenusMacroSplitMap(lenusMacroSplitMap);
        // zenfit
        setZenfitMacroSplitArray(zenfitMacroSplitOptions);
        setZenfitMacroSplitMap(zenfitMacroSplitMap);
      } catch (error) {
        console.error(error);
      } finally {
        setisLoading(false);
      }
    };

    getMacroSplitOptions();

    return () => {};
  }, []);
  return {
    isLoading,
    lenusMacroSplitArray,
    lenusMacroSplitMap,
    zenfitMacroSplitArray,
    zenfitMacroSplitMap,
  };
}
