import { UilLifeRing } from "@iconscout/react-unicons";
import styled from "styled-components";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../../../components/_MUI/Iconify";
import { IconButtonAnimate } from "../../../components/_MUI/animate";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    $sleek: any;
  }
}

export default function SleekPlanPopover() {
  const {t} = useTranslation("settings");
  const handleOpen = () => {
    window.$sleek.toggle("tiny.changelog");
  };

  return (
    <Tooltip title={t("topbar.updatesTooltip")}>
      <IconButtonAnimate onClick={handleOpen} data-badge-changelog data-sleek-changelog>
        <Iconify icon="uil:bell" sx={{ color: "grey.300" }}/>
      </IconButtonAnimate>
    </Tooltip>
  );
}