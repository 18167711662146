import { Alert, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { CSSProperties } from "react";
import { formatDate } from "../../../../helpers/dates";
import Iconify from "../../../../components/_MUI/Iconify";

interface Props {
  alertSx: CSSProperties;
  lenusTransferDate: Date;
}

export default function LenusTransferNotReady(props: Props) {
  const { lenusTransferDate, alertSx } = props;
  const { t } = useTranslation(TranslatorNS.APP);
  return (
    <Alert
      severity="info"
      variant="outlined"
      sx={alertSx}
      icon={<Iconify icon={"uil:info-circle"} color={"#ffc107"} />}>
      <Typography color={"white"} sx={{ display: "flex", flexDirection: "row" }}>
        {t("userAlert.dateTransfer", {
          date: formatDate(lenusTransferDate, "do, MMMM yyyy"),
        })}
      </Typography>
    </Alert>
  );
}
