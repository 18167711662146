import { MeasureSystem } from "../interfaces/measureSystem";

const OneFeetInInches = 12;

const cmToFeetFactor = 30.48;
export const lbsToKgFactor = 2.2046;

export const roundToTwo = (val?: number) => parseFloat(Number(val).toFixed(2));

export const roundToNone = (val?: number) => parseFloat(Number(val).toFixed(0));

// TODO: camelCase function names
export const GetFeetFromInches = (inches: number) => Number(inches) / OneFeetInInches;

// TODO: camelCase function names
export const GetKGFromLBS = (lbs: number) => parseFloat(String(lbs)) / lbsToKgFactor;
export const getLbsFromKg = (kg: number) => parseFloat(String(kg)) * lbsToKgFactor;

// TODO: rename to getKgOrPound, isKgOrPound sounds like it will return a boolean which it does not
export const isKgOrPound = (measureType?: MeasureSystem) =>
  Number(measureType) === MeasureSystem.IMPERIAL ? "lbs" : "kg";

// TODO: camelCase function names
export const GetCMFromFeetInches = (feet?: number, inches?: number) =>
  Math.round(
    (Math.floor(Number(feet)) + GetFeetFromInches(Number(inches))) * cmToFeetFactor,
  );

// TODO: camelCase function names
export const GetFeetInchesFromCM = (cm?: number) => {
  const feet = Math.floor(Number(cm)) / cmToFeetFactor;
  const inches = roundToTwo((feet - Math.floor(feet)) * OneFeetInInches);
  return {
    feet: Math.floor(feet),
    inches: inches,
  };
};

export function getCircumferenceUnitFromWeightUnit(
  weightUnit: "lbs" | "kg",
): "cm" | "in" {
  return weightUnit === "lbs" ? "in" : "cm";
}
