import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ApplyRecipes() {
  const { t } = useTranslation("meal");
  const [checkbox, toggle] = useState(false);

  return (
    <div
      className="modal modal-recipes inmodal in reactModal"
      id="applyMealRecipe"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("applyRecipes.close")}</span>
            </button>
            <div className="d-flex align-items-center">
              <h4 className="modal-title flex-grow-1">{t("applyRecipes.recipes")}</h4>
              <label className="help-block mt-0 mb-0" htmlFor="recipes_filter_mine">
                <input
                  type="checkbox"
                  name="recipes_filter_mine"
                  id="recipes_filter_mine"
                  value={Number(checkbox)}
                  onChange={() => toggle(!checkbox)}
                  checked={checkbox}
                />
                {t("applyRecipes.onlyShowOwnRecipes")}
              </label>
            </div>
          </div>
          <div className="modal-body">
            <div className="plans-search-text">
              <input
                className="form-control"
                type="text"
                name="q"
                defaultValue={""}
                placeholder={t("applyRecipes.searchRecipe")}
                autoComplete="off"
              />
            </div>
            <div className="modal-recipes-empty" hidden>
              <p>{t("applyRecipes.youHaventCreatedAny")}</p>
              <a className="btn btn-success" href="/recipes">
                {t("applyRecipes.createYourFirstRecipe")}
              </a>
            </div>
            <div className="recipe-list js-modal-recipe-list"></div>
            <div className="modal-recipes-spinner" hidden>
              <img
                id="spinnerApplyTemplate"
                style={{ display: "none" }}
                src={"/images/ajax-loader.gif"}
                alt={"loading"}
              />
            </div>
            <div className="js-modal-recipes-more text-center m-b" hidden>
              <button className="btn btn-success" type="button">
                {t("applyRecipes.loadMoreRecipes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
