import { ROOT } from "../config";

export const GET_RECIPE = (id: number) => {
  return `${ROOT}/api/recipes/get/${id}`;
};

export const GET_RECIPES = () => {
  return `${ROOT}/api/recipes`;
};

export const CREATE_RECIPES = () => {
  return `${ROOT}/api/recipes`;
};

export const DELETE_RECIPES = (id: number) => {
  return `${ROOT}/api/recipes/${id} `;
};

export const UPDATE_RECIPES = (id: number) => {
  return `${ROOT}/api/recipes/${id} `;
};

export const GET_ALL_RECIPE_INGREDIENTS = () => {
  return `${ROOT}/api/recipes/get-ingredients`;
};
export const GET_ALL_RECIPE = () => {
  return `${ROOT}/api/recipes/get-recipes`;
};
export const UPDATE_RECIPE_PREFERENCE = () => {
  return `${ROOT}/api/recipes/update-user-recipe-preferences`;
};
