import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { IconButton, Stack } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { TranslatorNS } from "../../../i18n/const";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DialogContentText from "@mui/material/DialogContentText";
import ChooseTemplate from "../../ClientWorkout/components/CreatePlanModal/ChooseTemplate";
import { SlideTransition } from "../../../components/Modal/SlideTransition";
import { DialogContext, WorkoutPlanModalType } from "./DialogProvider";
import axios from "axios";
import { UPDATE_WORKOUT } from "../../../api/private/workout-api";
import { useParams } from "react-router-dom";

export default function TemplateDialog() {
  const theme = useTheme();
  const { t } = useTranslation(TranslatorNS.WORKOUT_PLAN);
  const { templateId } = useParams<{ templateId: string }>();
  const { modal, setModal } = React.useContext(DialogContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTemplateIds, setSelectedTemplatesIds] = React.useState<number[]>([]);
  const modalBodyScrollRef = React.useRef<HTMLDivElement>();
  const disableSubmit = selectedTemplateIds.length < 1;
  const modalBodyRef = isMobile ? modalBodyScrollRef.current : undefined;
  const isOpen = modal.type === WorkoutPlanModalType.TEMPLATE;
  const [isSubmitting, setisSubmitting] = React.useState(false);

  const handleApplyTemplate = async () => {
    try {
      setisSubmitting(true);
      if (!templateId) throw new Error("Template id is not defined");
      const requestBody = new FormData();
      selectedTemplateIds.forEach(templateId => {
        // cast to string
        requestBody.append("templates[]", String(templateId));
      });
      await axios.post(UPDATE_WORKOUT(Number(templateId)), requestBody);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setisSubmitting(false);
    }
  };

  const handleClose = () => {
    setModal({ type: null });
  };
  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={isOpen}
        TransitionComponent={SlideTransition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 0 }}>
          <CloseOutlinedIcon />
        </IconButton>
        <DialogTitle>{t("editor.applyTemplateToWorkout")}</DialogTitle>
        <DialogContent sx={{ pb: 0 }} ref={modalBodyScrollRef}>
          <DialogContentText>{t("applyTemplate.youCanAdd")}</DialogContentText>
          <Stack sx={{ pb: 2 }}>
            <ChooseTemplate
              selectedTemplateIds={selectedTemplateIds}
              setSelectedTemplatesIds={setSelectedTemplatesIds}
              modalBodyRef={modalBodyRef}
            />
            <Stack sx={{ pt: 2 }} justifyContent="center">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={disableSubmit}
                onClick={handleApplyTemplate}
                loading={isSubmitting}
                size="large">
                {t("applyTemplate.addWorkoutTemplates")}
              </LoadingButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
