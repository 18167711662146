import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useLenusTransferContext } from "../index";
import Page from "../../../components/_MUI/Page";
import TransferContainer from "./TransferContainer";
import { getNextLenusRoute } from "../../../helpers/routing";

export default function LenusTransferOutLet() {
  let location = useLocation();
  const {
    loggedIn,
    getStartedData,
    loading,
    lenusToken,
    transferInProgress,
    emailSubject,
    emailContent,
  } = useLenusTransferContext();
  const activated = getStartedData?.isActive;

  const nextLenusRoute = getNextLenusRoute(
    activated,
    loggedIn,
    location.pathname,
    loading,
    lenusToken,
    transferInProgress,
    getStartedData.lastPaymentFailed,
    emailSubject,
    emailContent,
  );

  if (nextLenusRoute) {
    return <Navigate to={nextLenusRoute} state={{ from: location }} replace />;
  }

  return (
    <Page title={"Lenus x Zenfit"}>
      <TransferContainer>
        <Outlet />
      </TransferContainer>
    </Page>
  );
}
