import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Typography(theme: Theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontStretch: "normal",
          fontFamily: "inter",
          lineHeight: 1.57,
          letterSpacing: "normal",
          color: "#212b36",
          fontSize: "14px",
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
        subtitle1: {
          fontWeight: 700,
        },
        subtitle2: {
          fontWeight: 600,
        },
      },
    },
  };
}
