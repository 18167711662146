import { useEffect, useState } from "react";
import { IconButton, Slider } from "@mui/material";
import { styled } from "@mui/material/styles";

import Iconify from "../../../components/_MUI/Iconify";

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  const toggle = () => setPlaying(!playing);

  const toggleIsMuted = () => setIsMuted(!isMuted);

  const handleEnded = () => {
    setPlaying(false);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audio.currentTime);
  };

  const handleDurationChange = () => {
    setDuration(audio.duration);
  };

  const handleTrackDrag = newValue => {
    audio.pause();
    audio.currentTime = newValue;
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [audio, playing]);

  useEffect(() => {
    audio.muted = isMuted;
  }, [audio, isMuted]);

  useEffect(() => {
    audio.addEventListener("ended", handleEnded);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("durationchange", handleDurationChange);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("play", handlePlay);

    return () => {
      audio.removeEventListener("ended", handleEnded);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("durationchange", handleDurationChange);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("play", handlePlay);
    };
  }, []);

  return {
    playing,
    toggle,
    duration,
    currentTime,
    isMuted,
    toggleIsMuted,
    handleTrackDrag,
  };
};

const VoicePlayer = ({ url }) => {
  const {
    playing,
    toggle,
    duration,
    currentTime,
    isMuted,
    toggleIsMuted,
    handleTrackDrag,
  } = useAudio(url);

  const getFormattedTime = time => {
    const ceiledTime = Math.ceil(time || 0);
    const minutes = Math.floor(ceiledTime / 60);
    const seconds = ceiledTime - minutes * 60;

    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <VoiceContent>
      <StyledIconButton onClick={toggle}>
        {playing ? (
          <Iconify icon="uil:pause" width={18} height={18} />
        ) : (
          <Iconify icon="uil:play" width={18} height={18} />
        )}
      </StyledIconButton>

      <ProgressTrack
        min={0}
        max={duration * 1000}
        value={currentTime * 1000}
        color="secondary"
        onChange={(_, value) => handleTrackDrag(value / 1000)}
      />

      <DurationText>
        {getFormattedTime(currentTime)} / {getFormattedTime(duration)}
      </DurationText>

      <StyledIconButton onClick={toggleIsMuted}>
        {isMuted ? (
          <Iconify icon="uil:volume-mute" width={18} height={18} />
        ) : (
          <Iconify icon="uil:volume" width={18} height={18} />
        )}
      </StyledIconButton>
    </VoiceContent>
  );
};

const VoiceContent = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  margin: "-6px -8px",
  whiteSpace: "nowrap",
  height: '34px'
}));

const DurationText = styled("div")(() => ({
  fontSize: "12px",
  lineHeight: "18px",
}));

const StyledIconButton = styled(IconButton)(() => ({
  color: "inherit",
}));

const ProgressTrack = styled(Slider)(() => ({
  color: "inherit",
  width: "100%",
  marginRight: "12px",
}));

export default VoicePlayer;
