import {
  AuthActionTypes,
  CHANGE_ERROR,
  CHANGE_LANG,
  CHANGE_MESSAGE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  FINISH_SIGNING_UP_FAILURE,
  FINISH_SIGNING_UP_REQUEST,
  FINISH_SIGNING_UP_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_LENUS_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
} from "./types";
import produce from "immer";
import { Locales } from "../../i18n/locales";
import i18n from "i18next";
import { RequestState } from "../../interfaces/requestState";
import { LocaleEnum } from "../../generated/graphql";

export interface AuthState {
  error: string;
  emailError: string;
  message: string;
  userCreated: boolean;
  userSubmitting: boolean;
  onboardFinishLoading: boolean;
  onboardFinishError: string;
  onboardFinish: boolean;
  //  not sure if this should go here
  datakey: any;
  // not sure if this is a string or we can add a more specific enum?
  locales: Locales;
  currentLocale: LocaleEnum;
  requestState: RequestState;
}

export const INITIAL_STATE: AuthState = {
  error: "",
  message: "",
  requestState: RequestState.INITIAL,
  userCreated: false,
  userSubmitting: false,
  onboardFinishLoading: false,
  onboardFinishError: "",
  onboardFinish: false,
  emailError: "",
  datakey: null,
  locales: Locales.da_DK,
  currentLocale: LocaleEnum.EnUs,
};

const authReducer = (state = INITIAL_STATE, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, requestState: RequestState.PENDING, error: '' };
    case LOGIN_SUCCESS:
      return { ...state, requestState: RequestState.RESOLVED };
    case LOGIN_FAILURE:
      return { ...state, requestState: RequestState.REJECTED, error: action.payload };
    case LOGIN_LENUS_SUCCESS:
      return { ...state, requestState: RequestState.RESOLVED, error: action.payload };
    case CHANGE_MESSAGE:
      return { ...state, message: action.payload };
    case CHANGE_ERROR:
      return { ...state, error: action.payload };
    case CHANGE_LANG:
      return produce(state, draftState => {
        try {
          i18n.changeLanguage(action.payload);
          draftState.currentLocale = action.payload;
        } catch (e) {
          console.log("lang issue", e);
        }
      });
    case CREATE_USER_REQUEST: {
      return produce(state, draftState => {
        draftState.userSubmitting = true;
        draftState.error = "";
      });
    }
    case CREATE_USER_SUCCESS: {
      return produce(state, draftState => {
        draftState.userSubmitting = false;
        draftState.error = "";
      });
    }
    case CREATE_USER_FAILURE: {
      return produce(state, draftState => {
        draftState.userSubmitting = false;
        draftState.error = action.payload;
      });
    }
    case FINISH_SIGNING_UP_REQUEST: {
      return produce(state, draftState => {
        draftState.onboardFinishLoading = true;
        draftState.onboardFinishError = "";
      });
    }
    case FINISH_SIGNING_UP_SUCCESS: {
      return produce(state, draftState => {
        draftState.onboardFinishLoading = false;
        draftState.onboardFinishError = "";
        draftState.userCreated = true;
        window.location.replace("/dashboard");
      });
    }
    case FINISH_SIGNING_UP_FAILURE: {
      return produce(state, draftState => {
        draftState.onboardFinishLoading = false;
        draftState.onboardFinishError = action.payload;
      });
    }

    default:
      return state;
  }
};
export default authReducer;
