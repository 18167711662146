import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldValidators } from "../../../../helpers/validators";
import { CREATE_ACCOUNT_FIELDS } from "../../const";
import ZFError from "../../../../components/Error";
import ZFTermsBox from "../../../../components/TermsBox";
import { AppState } from "../../../../store";
import { Stack, TextField } from "@mui/material";
import {
  ActivationConfigEnum,
  ActivationStepsEnum,
  IAccount,
  TSubmitError,
} from "../../type";
import { submitClientAction } from "../../store/action";
import { IUser } from "../../../../store/user/reducerTypes";
import { INITIAL_ACCOUNT } from "../../store/initialStates";
import { LoadingButton } from "@mui/lab";
import { IClientActivationState } from "../../store/types";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { scrollToElementId } from "../../../../helpers/utils";
import { track } from "../../../../helpers/analytics";

interface Props {
  configState: ActivationConfigEnum;
  submitClientActionDispatch: (obj: IAccount, step: ActivationStepsEnum) => void;
  accountState: IAccount;
  submitError: TSubmitError;
  primaryColor: IUser["primaryColor"];
  clientSubmittingState: IClientActivationState["clientSubmitting"];
}

const CreateAccount = ({
  configState,
  submitClientActionDispatch,
  accountState,
  submitError,
  primaryColor,
  clientSubmittingState,
}: Props) => {
  const { t } = useTranslation("messages");
  const [validator, setValidator] = useState<any>({});
  const [error, setError] = useState<boolean>(false);

  const [account, setAccount] = useState<IAccount>({
    ...INITIAL_ACCOUNT,
    password: "",
    passwordConfirm: "",
    termsAccepted: false,
  });

  useEffect(() => {
    setAccount({
      ...account,
      name: accountState.name,
      email: accountState.email,
      phone: accountState.phone,
    });
  }, [accountState]);

  const handleChange = (name: keyof typeof account, value: string | number | boolean) => {
    validator && setValidator({ ...validator, [name]: "" });
    setAccount({ ...account, [name]: value });
  };

  const handleSubmit = () => {
    try {
      FieldValidators(account, t, ["password"]);
      track("Login Created", "Client Activation");
      submitClientActionDispatch(account, ActivationStepsEnum.ACCOUNT);
    } catch (e: any) {
      const id = e && Object.keys(e)[0];
      id && scrollToElementId(id);
      setValidator(e);
    }
  };

  return (
    <Stack width={{ xs: "100%", sm: 450 }}>
      {Object.values(CREATE_ACCOUNT_FIELDS).map(
        (field: { label: string; type: string; name: string; cyId?: string }, i) => {
          return (
            <TextField
              key={i}
              id={`zf_${field.name}`}
              type={field.type}
              label={t(field.label)}
              sx={{ mb: 2 }}
              data-cy={field.cyId}
              onChange={e =>
                handleChange(e.target.name as keyof typeof account, e.target.value)
              }
              name={field.name}
              value={account[field.name as keyof IAccount]}
              error={Boolean(validator[field.name])}
              helperText={validator[field.name]}
            />
          );
        },
      )}

      <ZFTermsBox
        primaryColor={primaryColor}
        checked={Boolean(account?.termsAccepted)}
        onChange={() => {
          handleChange("termsAccepted", !account.termsAccepted);
          error && setError(false);
        }}
      />

      <LoadingButton
        variant="contained"
        size="large"
        loading={clientSubmittingState}
        disabled={!account.termsAccepted || clientSubmittingState}
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_1}
        onClick={() => handleSubmit()}>
        {configState === "activation"
          ? t("client.activation.updateLogin")
          : t("client.activation.createLoginBtn")}
      </LoadingButton>
      {error && <span className="error">{t("client.checkout.acceptTermsError")}</span>}
      {submitError && (
        <ZFError
          msg={typeof submitError !== "string" ? submitError?.message : submitError}
          status={typeof submitError !== "string" ? submitError?.status : ""}
        />
      )}
    </Stack>
  );
};

function mapStateToProps({ clientActivation, user }: AppState) {
  return {
    step: clientActivation.step,
    accountState: clientActivation.account,
    configState: clientActivation.config,
    primaryColor: user.primaryColor,
    submitError: clientActivation.clientSubmitError,
    clientSubmittingState: clientActivation.clientSubmitting,
  };
}

const mapDispatchToProp = {
  submitClientActionDispatch: submitClientAction,
};

export default connect(mapStateToProps, mapDispatchToProp)(CreateAccount);
