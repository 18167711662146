import { Dispatch } from "redux";
import { WorkoutplanActionTypes } from "../types";
import * as api from "../../api";
import { extractResponseError } from "../../../../helpers/error";
import { SET_EDIT_WORKOUT_PLAN_MODAL } from "../../../MealPlan/store/modal/types";

export const UPDATE_WORKOUTPLANS_REQUEST = "workoutplan/UPDATE_REQUEST";
export const UPDATE_WORKOUTPLANS_SUCCESS = "workoutplan/UPDATE_SUCCESS";
export const UPDATE_WORKOUTPLANS_FAILURE = "workoutplan/UPDATE_FAILURE";

// UPDATE
export interface UpdateWorkoutplansRequest {
  type: typeof UPDATE_WORKOUTPLANS_REQUEST;
  payload: any;
}
export interface UpdateWorkoutplansSuccess {
  type: typeof UPDATE_WORKOUTPLANS_SUCCESS;
  payload: any;
}
export interface UpdateWorkoutplansFailure {
  type: typeof UPDATE_WORKOUTPLANS_FAILURE;
  payload: any;
}

export const updateWorkoutplans = (id: number, workoutplan: api.UpdateWorkoutPlan) => {
  return async (dispatch: Dispatch<WorkoutplanActionTypes>) => {
    dispatch({ type: UPDATE_WORKOUTPLANS_REQUEST, payload: workoutplan });
    try {
      await api.updateWorkoutPlan(id, workoutplan);
      dispatch({
        type: UPDATE_WORKOUTPLANS_SUCCESS,
        payload: { id, workoutplan },
      });
      dispatch({ type: SET_EDIT_WORKOUT_PLAN_MODAL, payload: null });
    } catch (error: any) {
      dispatch({
        type: UPDATE_WORKOUTPLANS_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};
