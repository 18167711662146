import { ModalActionType } from "../../helpers/types";

export const TABLE_TITLE = [
  {
    label: "meals.const.tableTitle.name",
    value: "name",
  },
  {
    label: "meals.const.tableTitle.comment",
    value: "comment",
  },
  /*{
        label: 'meals.const.tableTitle.kcal',
        value: "kcal",
    },
    {
        label: 'meals.const.tableTitle.macroSplit',
        value: "macroSplit",
    },*/
  {
    label: "meals.const.tableTitle.amountMeals",
    value: "amountMeals",
  },
];
export const NEW_MEAL = {
  active: true,
  headTitle: "meals.const.newMeal.headTitle",
  content: "meals.const.newMeal.content",
  type: ModalActionType.CREATE,
};
export const EDIT_MEAL = {
  active: true,
  headTitle: "meals.const.editMeal.headTitle",
  content: "meals.const.editMeal.content",
  type: ModalActionType.EDIT,
};
export const DUPLICATE_MEAL = {
  active: true,
  headTitle: "meals.const.duplicateMeal.headTitle",
  content: "meals.const.duplicateMeal.content",
  type: ModalActionType.DUPLICATE,
};

export const InitialMeal = {
  name: "",
  comment: "",
};

export const LIMIT = 15;
