import { Dispatch } from "redux";
import { WorkoutplanActionTypes } from "../types";
import * as api from "../../api";
import { extractResponseError } from "../../../../helpers/error";
import { SET_EDIT_WORKOUT_PLAN_MODAL } from "../../../MealPlan/store/modal/types";

export const DELETE_WORKOUTPLANS_REQUEST = "workoutplan/DELETE_REQUEST";
export const DELETE_WORKOUTPLANS_SUCCESS = "workoutplan/DELETE_SUCCESS";
export const DELETE_WORKOUTPLANS_FAILURE = "workoutplan/DELETE_FAILURE";

// DELETE
export interface DeleteWorkoutplansRequest {
  type: typeof DELETE_WORKOUTPLANS_REQUEST;
}
export interface DeleteWorkoutplansSuccess {
  type: typeof DELETE_WORKOUTPLANS_SUCCESS;
  payload: { id: number };
}
export interface DeleteWorkoutplansFailure {
  type: typeof DELETE_WORKOUTPLANS_FAILURE;
  payload: any;
}

export function deleteWorkoutplanRequest(): DeleteWorkoutplansRequest {
  return {
    type: DELETE_WORKOUTPLANS_REQUEST,
  };
}

export function deleteWorkoutplanSuccess(id: number): DeleteWorkoutplansSuccess {
  return {
    type: DELETE_WORKOUTPLANS_SUCCESS,
    payload: { id },
  };
}

export function deleteWorkoutplanFail(error: any): DeleteWorkoutplansFailure {
  return {
    type: DELETE_WORKOUTPLANS_FAILURE,
    payload: extractResponseError(error),
  };
}

export const deleteWorkoutplans = (id: number) => {
  return async (dispatch: Dispatch<WorkoutplanActionTypes>) => {
    dispatch(deleteWorkoutplanRequest());
    try {
      await api.deleteWorkoutPlan(id);
      dispatch(deleteWorkoutplanSuccess(id));
      dispatch({ type: SET_EDIT_WORKOUT_PLAN_MODAL, payload: null });
    } catch (error: any) {
      dispatch(deleteWorkoutplanFail(error));
    }
  };
};
