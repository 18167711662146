export enum Tokens {
  BEARER_TOKEN = "BEARER",
  REFRESH_TOKEN = "REFRESH_TOKEN",
}

function setTokenLocalStorage(key: Tokens, token: string) {
  try {
    localStorage.setItem(key, token);
  } catch (error) {
    console.error(`Failed to set token: key=${key} token=${token}`);
  }
}
export function removeTokenLocalStorage(key: Tokens) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Failed to set token: key=${key}`);
  }
}

export const getToken = (key: Tokens) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error(`Failed to get token: key=${key}`);
  }
};

export const setToken = (token: string) => {
  setTokenLocalStorage(Tokens.BEARER_TOKEN, token);
};

export const setRefreshToken = (token: string) => {
  setTokenLocalStorage(Tokens.REFRESH_TOKEN, `${token}`);
};

export const logout = () => {
  removeTokenLocalStorage(Tokens.BEARER_TOKEN);
  removeTokenLocalStorage(Tokens.REFRESH_TOKEN);
  window.localStorage.removeItem("token");
  // @ts-ignore
  if (window?.["Intercom"]) {
    // @ts-ignore
    window.Intercom("shutdown");
  }
  window.location.reload();
};
