import { memo } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FitnessCenterRounded from "@material-ui/icons/FitnessCenterRounded";
import { UilSignalAlt3, UilLocationPoint } from "@iconscout/react-unicons";

import { FiltersState } from "./ChooseTemplate";
import GenderIcon from "../../../../assets/svg/GenderIcon";
import TemplateFilterMenuItem from "./TemplateFilterMenuItem";
import { TemplateFilterMenuItemProps } from "./TemplateFilterMenuItem";
import { PERWEEK, LEVELS, LOCATION, GENDER } from "../../../WorkoutTemplates/const";

interface FilterMenuProps {
  filters: FiltersState;
  handleFilterChange: (
    filterName: keyof FiltersState,
    filterValue: string | number | number[] | null,
  ) => void;
}

const getFilterTabs = (
  filters: FiltersState,
  handleFilterChange: (
    filterName: keyof FiltersState,
    filterValue: string | number | number[] | null,
  ) => void,
): TemplateFilterMenuItemProps[] => {
  const handleCheckboxSelect = (itemKeyValue: number, filterKey: keyof FiltersState) => {
    const filterSelectedItems = filters ? (filters[filterKey] as number[]) : [];
    const isAlreadySelected = filterSelectedItems.indexOf(itemKeyValue) > -1;

    handleFilterChange(
      filterKey,
      isAlreadySelected
        ? filterSelectedItems.filter(el => el !== itemKeyValue)
        : [...filterSelectedItems, itemKeyValue],
    );
  };

  const handleRadioSelect = (itemKeyValue: number, filterKey: keyof FiltersState) => {
    const filterSelectedItem = filters ? (filters[filterKey] as number) : null;
    const isAlreadySelected = itemKeyValue === filterSelectedItem;

    handleFilterChange(filterKey, isAlreadySelected ? null : itemKeyValue);
  };

  return [
    {
      tabLabel: "workouts.const.tableTitle.workoutsPerWeek",
      tabIcon: <FitnessCenterRounded />,
      options: PERWEEK,
      checkboxSelectedItems: filters.workoutsPerWeek,
      onItemClick: itemKeyValue => handleCheckboxSelect(itemKeyValue, "workoutsPerWeek"),
    },
    {
      tabLabel: "workouts.const.tableTitle.experience",
      tabIcon: <UilSignalAlt3 size={20} />,
      options: LEVELS,
      checkboxSelectedItems: filters.levels,
      onItemClick: itemKeyValue => handleCheckboxSelect(itemKeyValue, "levels"),
    },
    {
      tabLabel: "workouts.const.tableTitle.sex",
      tabIcon: <GenderIcon size={20} />,
      options: GENDER,
      radioSelectedItem: filters.gender,
      onItemClick: itemKeyValue => handleRadioSelect(itemKeyValue, "gender"),
    },
    {
      tabLabel: "workouts.const.tableTitle.location",
      tabIcon: <UilLocationPoint size={20} />,
      options: LOCATION,
      checkboxSelectedItems: filters.locations,
      onItemClick: itemKeyValue => handleCheckboxSelect(itemKeyValue, "locations"),
    },
  ];
};

const TemplateFiltersMenu = ({ filters, handleFilterChange }: FilterMenuProps) => {
  const filterTabs = getFilterTabs(filters, handleFilterChange);

  return (
    <Box sx={{ my: 3 }}>
      <Grid
        direction="row"
        container
        columnSpacing={{ xs: 0, sm: 2 }}
        rowSpacing={{ xs: 1, sm: 2 }}>
        {filterTabs.map(tab => (
          <Grid item xs={6} sm="auto" key={tab.tabLabel}>
            <TemplateFilterMenuItem {...tab} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default memo(TemplateFiltersMenu);
