export enum AlertStorageKeys {
  STRIPE_CONNECT_ALERT = "STRIPE_CONNECT_ALERT",
}

export function getAlertStorage() {
  try {
    const stripeConnectAlertStorage = localStorage.getItem(
      AlertStorageKeys.STRIPE_CONNECT_ALERT,
    );
    return {
      stripeConnectAlertStorage,
    };
  } catch (error) {
    console.error(error);
    return {};
  }
}
