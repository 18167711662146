import { useInfiniteQuery } from "@tanstack/react-query";
import { ReactQueryKeys } from "../../../interfaces/ReactQueryKeys";
import { getClientVideos } from "./api";
import { IClientVideosQueryParams } from "./interfaces";

export default function useClientVideosQuery(params: IClientVideosQueryParams) {
  const { clientId } = params;
  const clientVideosQuery = useInfiniteQuery(
    [ReactQueryKeys.CLIENT_VIDEOS, clientId],
    ({ pageParam = 0, queryKey }) => getClientVideos({ clientId }),
    {
      getNextPageParam: lastPage => lastPage?.nextCursor,
      refetchOnWindowFocus: false,
    },
  );

  return {
    clientVideosQuery,
  };
}
