import { Stack, Tooltip, Typography } from "@mui/material";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "../../../../components/_MUI/hook-form";
import TermsAccepted from "../../../../components/TermsBox/TermsAccepted";
import Alert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import useLenusSignupSubmit, { LenusSignupSchema } from "./useLenusSignupSubmit";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { TLenusSignupFormProps } from "./index";
import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  defaultValues: TLenusSignupFormProps;
}

export default function SignupForm(props: Props) {
  const { defaultValues } = props;
  const { setLoggedIn } = useLenusTransferContext();
  const { t } = useTranslation(TranslatorNS.AUTH);

  const {
    loading: signupLoading,
    onLenusSignup,
    error,
  } = useLenusSignupSubmit({ setLoggedIn });

  const methods = useForm<TLenusSignupFormProps>({
    resolver: yupResolver(LenusSignupSchema),
    defaultValues,
  });

  const { handleSubmit, watch } = methods;
  const acceptedTerms = watch("acceptedTerms");

  const textFieldsx = {
    input: {
      color: "white",
    },
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onLenusSignup)}>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        height={{ xs: "82vh" }}
        width={{ xs: "100%", sm: "100vw" }}>
        <Stack sx={{ textAlign: "center", mb: 5, color: "white" }} width={{ xs: "90%" }}>
          <Typography color={"white"} fontSize={"32px"}>
            {t("transfer.niceToMeetYou")}
          </Typography>
          <Typography color={"white"}>{t("transfer.gotEmail")}</Typography>
        </Stack>
        <Stack
          width={{ xs: "90%", sm: 480 }}>
          <Tooltip title={t("signup.inputs.emailTooltip")} arrow>
            <Stack>
              <RHFTextField
                sx={textFieldsx}
                name={"email"}
                label={t("signup.inputs.email")}
                disabled
              />
            </Stack>
          </Tooltip>
          <RHFTextField
            sx={textFieldsx}
            name={"name"}
            label={t("signup.inputs.fullName")}
          />
          <RHFTextField
            sx={textFieldsx}
            name={"password"}
            type={"password"}
            label={t("signup.inputs.password")}
          />
          <RHFCheckbox
            sx={{ span: { color: "white" }, mx: "auto", pr: 2 }}
            name={"acceptedTerms"}
            label={<TermsAccepted />}
          />
          {error && (
            <Alert sx={{ width: "100%", my: 2 }} severity="error">
              {error}
            </Alert>
          )}
        </Stack>
      </Stack>
      <Stack
        width={"100%"}
        position={"absolute"}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        bottom={{ sm: 20, xs: 40}}
        px={4}>
        <Stack sx={{ opacity: 0.45 }} color={"white"} justifyContent={"center"} display={{ xs: "none", sm: "flex" }}>
          {t("transfer.zenfitLenus")}
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Typography color={"white"} mr={2}>
            {t("transfer.nextPlan")}
          </Typography>
          <LoadingButton
            type={"submit"}
            size={"large"}
            loading={signupLoading}
            disabled={!acceptedTerms}
            variant={"contained"}>
            {t("transfer.createAccount")}
          </LoadingButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
}
