export const MEALS_PER_DAY = {
  3: 3,
  4: 4,
  5: 5,
  6: 6,
};

export const PAL_SLIDER = [
  {
    value: 1.4,
    label: "meal.palSlider.inactive",
  },
  {
    value: 1.9,
    label: "meal.palSlider.moderatelyActive",
  },
  {
    value: 2.4,
    label: "meal.palSlider.active",
  },
];

export const CLIENT_FOOD_PREFERENCES = {
  lactose: "meal.clientFoodPreferences.avoidLactose",
  gluten: "meal.clientFoodPreferences.avoidGluten",
  nuts: "meal.clientFoodPreferences.avoidNuts",
  eggs: "meal.clientFoodPreferences.avoidEggs",
  pig: "meal.clientFoodPreferences.avoidPig",
  shellfish: "meal.clientFoodPreferences.avoidShellfish",
  fish: "meal.clientFoodPreferences.avoidFish",
  isVegetarian: "meal.clientFoodPreferences.isVegetarian",
  isVegan: "meal.clientFoodPreferences.isVegan",
  isPescetarian: "meal.clientFoodPreferences.isPescetarian",
};

export const ALTERNATIVES = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
};
