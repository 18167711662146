import RECIPE_IMG from "../../assets/images/lists/recipeIcon.png";
import i18next from "i18next";
import { TranslatorNS } from "../../i18n/const";
import { ModalActionType } from "../../helpers/types";
import { range } from "lodash";
import { CookingTimeEnum, MealTypeEnum } from "../../generated/graphql";

export const TABLE_TITLE = [
  {
    id: "id",
    label: "recipes.const.tableTitle.name",
    value: "name",
    image: "image",
    default: RECIPE_IMG,
  },
  {
    label: "ingredients.main.mainTitle",
    value: "metaTags",
  },
  {
    label: "ingredients.main.servings",
    value: "servings",
  },
  {
    label: "recipes.const.tableTitle.locale",
    value: "locale",
  },
  {
    label: "recipes.const.tableTitle.type",
    value: "type",
  },
  {
    label: "recipes.const.tableTitle.macroSplit",
    value: "macroSplit",
  },
  {
    label: "recipes.const.tableTitle.cookingTime",
    value: "cookingTime",
  },
];

export const NEW_RECIPE = {
  active: true,
  headTitle: "recipes.const.newRecipe.headTitle",
  content: "recipes.const.newRecipe.content",
  type: ModalActionType.CREATE,
};

export const EDIT_RECIPE = {
  active: true,
  headTitle: "recipes.const.editRecipe.headTitle",
  content: "recipes.const.editRecipe.content",
  type: ModalActionType.EDIT,
};

export const DUPLICATE_RECIPE = {
  active: true,
  headTitle: "recipes.const.duplicateRecipe.headTitle",
  content: "recipes.const.duplicateRecipe.content",
  type: ModalActionType.DUPLICATE,
};

export const RECIPE_ATTRIBUTES = {
  lactose: "recipes.const.recipeAttributes.lactose",
  gluten: "recipes.const.recipeAttributes.gluten",
  nuts: "recipes.const.recipeAttributes.nuts",
  eggs: "recipes.const.recipeAttributes.eggs",
  pig: "recipes.const.recipeAttributes.pig",
  fish: "recipes.const.recipeAttributes.fish",
  shellfish: "recipes.const.recipeAttributes.shellfish",
  isVegetarian: "recipes.const.recipeAttributes.isVegetarian",
  isVegan: "recipes.const.recipeAttributes.isVegan",
  isPescetarian: "recipes.const.recipeAttributes.isPescetarian",
};
export const RECIPE_ALLERGIES = {
  lactose: "recipes.const.recipeAttributes.lactose",
  gluten: "recipes.const.recipeAttributes.gluten",
  nuts: "recipes.const.recipeAttributes.nuts",
  eggs: "recipes.const.recipeAttributes.eggs",
  pig: "recipes.const.recipeAttributes.pig",
  fish: "recipes.const.recipeAttributes.fish",
  shellfish: "recipes.const.recipeAttributes.shellfish",
  // isVegetarian: "recipes.const.recipeAttributes.isVegetarian",
  // isVegan: "recipes.const.recipeAttributes.isVegan",
  // isPescetarian: "recipes.const.recipeAttributes.isPescetarian",
};

export const RECIPE_PREFERENES = {
  // lactose: "recipes.const.recipeAttributes.lactose",
  // gluten: "recipes.const.recipeAttributes.gluten",
  // nuts: "recipes.const.recipeAttributes.nuts",
  // eggs: "recipes.const.recipeAttributes.eggs",
  // pig: "recipes.const.recipeAttributes.pig",
  // fish: "recipes.const.recipeAttributes.fish",
  // shellfish: "recipes.const.recipeAttributes.shellfish",
  isVegetarian: "recipes.const.recipeAttributes.isVegetarian",
  isVegan: "recipes.const.recipeAttributes.isVegan",
  isPescetarian: "recipes.const.recipeAttributes.isPescetarian",
};

export const COOKING_TIME = () => {
  const t = (key: string) => i18next.t(key, { ns: TranslatorNS.LISTS });
  return {
    0: t("recipes.const.cookingTime.any"),
    1: t("recipes.const.cookingTime.0-15 min"),
    2: t("recipes.const.cookingTime.15-30 min"),
    3: t("recipes.const.cookingTime.+30 min"),
  };
};

export const COOKING_TIME_KEYS = {
  0: "recipes.cookingTimes.any",
  1: "recipes.cookingTimes.0-15 min",
  2: "recipes.cookingTimes.15-30 min",
  3: "recipes.cookingTimes.+30 min",
};
// the new way in graphql
export const COOKING_TIME_ENUM_KEYS = {
  [CookingTimeEnum.Fast]: "recipes.cookingTimes.0-15 min",
  [CookingTimeEnum.Medium]: "recipes.cookingTimes.15-30 min",
  [CookingTimeEnum.Slow]: "recipes.cookingTimes.+30 min",
};

export const MEAL_TYPE = {
  1: "recipes.const.mealType.breakfast",
  2: "recipes.const.mealType.lunch",
  3: "recipes.const.mealType.dinner",
  4: "recipes.const.mealType.morning_snack",
  5: "recipes.const.mealType.afternoon_snack",
  6: "recipes.const.mealType.evening_snack",
};
export const MEAL_TYPE_ENUM_KEYS = {
  [MealTypeEnum.Breakfast]: "recipes.const.mealType.breakfast",
  [MealTypeEnum.Lunch]: "recipes.const.mealType.lunch",
  [MealTypeEnum.Dinner]: "recipes.const.mealType.dinner",
  [MealTypeEnum.MorningSnack]: "recipes.const.mealType.morning_snack",
  [MealTypeEnum.AfternoonSnack]: "recipes.const.mealType.afternoon_snack",
  [MealTypeEnum.EveningSnack]: "recipes.const.mealType.evening_snack",
};

export const INITIAL_RECIPE = {
  id: 0,
  name: "",
  macroSplit: "",
  image: "",
  types: [],
  cookingTime: undefined,
  attributes: [],
  locale: "",
  servings: 1,
};

export const INITIAL_VALIDATOR = {
  name: "",
  image: "",
  locale: "",
  cookingTime: "",
  macroSplit: "",
  types: "",
  servings: "",
};

export const SERVINGS_SELECT_OPTIONS = 20;
export const SERVINGS_SELECT_OPTIONS_MAP = range(1, SERVINGS_SELECT_OPTIONS).reduce(
  (acc, curr) => ({ ...acc, [curr]: curr }),
  {},
) as { [key: number]: number };
