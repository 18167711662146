import React from "react";
import { Typography } from "@mui/material";
import { prepareRoute } from "../../../../helpers/routing";
import { Routes } from "../../../routeMap";
import { Link } from "react-router-dom";

export default function Delete({
  templateId,
  title,
}: {
  templateId: number;
  title: string;
}) {
  return (
    <Link to={prepareRoute(Routes.MEALS_TEMPLATES_EDITOR, { templateId })}>
      <Typography>{title}</Typography>
    </Link>
  );
}
