import WEIGHT_IMG from "../../assets/images/lists/weight.png";

export const TABLE_TITLE = [
  {
    id: "id",
    label: "exercises.const.tableTitle.name",
    value: "name",
    image: "picture_url",
    default: WEIGHT_IMG,
  },
  {
    label: "exercises.const.tableTitle.muscleGroup",
    value: "muscleGroup",
  },
  {
    label: "exercises.const.tableTitle.type",
    value: "type",
  },
  {
    label: "exercises.const.tableTitle.tracking",
    value: "tracking",
  },
  {
    label: "exercises.const.tableTitle.equipment",
    value: "equipment",
  },
];

export const NEW_EXERCISE = {
  active: true,
  headTitle: "exercises.const.newExercise.headTitle",
  content: "exercises.const.newExercise.content",
  type: "create",
};

export const EDIT_EXERCISE = {
  active: true,
  headTitle: "exercises.const.editExercise.headTitle",
  content: "exercises.const.editExercise.content",
  type: "edit",
};

export const DUPLICATE_EXERCISE = {
  active: true,
  headTitle: "exercises.const.duplicateExercise.headTitle",
  content: "exercises.const.duplicateExercise.content",
  type: "duplicate",
};

type KeyValPair = { [key: number]: string };

export const EXERCISE_TYPE: KeyValPair = {
  1: "exercises.const.exerciseType.strength",
  2: "exercises.const.exerciseType.stretch",
  4: "exercises.const.exerciseType.cardio",
};

export const WORKOUT_TYPE: KeyValPair = {
  1: "exercises.const.workoutType.reps",
  2: "exercises.const.workoutType.time",
};

export const MUSCLE_GROUPS: KeyValPair = {
  1: "exercises.const.muscleGroups.chest",
  2: "exercises.const.muscleGroups.shoulders",
  3: "exercises.const.muscleGroups.biceps",
  4: "exercises.const.muscleGroups.triceps",
  5: "exercises.const.muscleGroups.lats",
  6: "exercises.const.muscleGroups.calves",
  7: "exercises.const.muscleGroups.glutes",
  8: "exercises.const.muscleGroups.hamstrings",
  9: "exercises.const.muscleGroups.quadriceps",
  10: "exercises.const.muscleGroups.middleBack",
  11: "exercises.const.muscleGroups.traps",
  12: "exercises.const.muscleGroups.neck",
  13: "exercises.const.muscleGroups.lowerBack",
  14: "exercises.const.muscleGroups.abdominals",
  15: "exercises.const.muscleGroups.aboductors",
  16: "exercises.const.muscleGroups.other",
};

export const EQUIPMENT: KeyValPair = {
  1: "exercises.const.equipment.barbell",
  2: "exercises.const.equipment.dumbbell",
  3: "exercises.const.equipment.machine",
  4: "exercises.const.equipment.bands",
  5: "exercises.const.equipment.bodyWeight",
  6: "exercises.const.equipment.cable",
  7: "exercises.const.equipment.kettleBell",
  9: "exercises.const.equipment.exerciseBall",
  10: "exercises.const.equipment.other",
  12: "exercises.const.equipment.ezCurlBar",
  14: "exercises.const.equipment.foamRoll",
  15: "exercises.const.equipment.none",
  19: "exercises.const.equipment.medicineBall",
  20: "exercises.const.equipment.trx",
};

export const INITIAL_EXERCISE = {
  name: "",
  execution: "",
  picture_url: "",
  video_url: "",
  muscleGroup: 0,
  exerciseType: 0,
  equipment: 0,
  workoutType: 1,
};

export const INITIAL_VALIDATOR_EXERCISE = {
  name: "",
  execution: "",
  picture_url: "",
  video_url: "",
  muscleGroup: "",
  exerciseType: "",
  equipment: "",
  workoutType: "",
};

// Pagination
export const PAGE_INCREMENT = 1;
export const GET_LIMIT = 50;
