import axios from "axios";
import {
  DELETE_CLIENT_IMAGES,
  FETCH_CLIENT_IMAGES,
  FETCH_CLIENT_PROGRESS,
  UPDATE_CLIENT_PROGRESS,
  UPLOAD_CLIENT_IMAGES,
} from "../../../api/private/clients";
import { Checkin } from "./store/clientProgress/types";

/**
 * Images
 */

export async function getClientImages(id: number, page: number, limit: number) {
  const params = { page, limit };
  return await axios.get(FETCH_CLIENT_IMAGES(id), { params });
}

export async function uploadClientImages(id: number, formData: any) {
  return await axios.post(UPLOAD_CLIENT_IMAGES(id), formData);
}
export async function deleteClientImages(id: number, ids: any) {
  return await axios.delete(DELETE_CLIENT_IMAGES(id), {
    data: { ids },
  });
}

export async function getClientProgress(id: number) {
  return await axios.get(FETCH_CLIENT_PROGRESS(id));
}

export async function updateClientProgress(id: number, entry: Partial<Checkin>) {
  return await axios.post(UPDATE_CLIENT_PROGRESS(id), entry);
}
