import React, { Component } from "react";
import styled from "styled-components";

export default class DropOptions extends Component {
  static defaultProps = {
    items: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.isOpen &&
      !this.props.isVideoOpen
    ) {
      this.toggleDropdown();
    }
  }

  toggleDropdown = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !this.state.isOpen });
    if (!isOpen) {
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  };

  render() {
    const { isOpen } = this.state;
    const { items, label } = this.props;

    const renderItems = items.map((item, i) => <span key={i}>{item}</span>);

    return (
      <RootStyle ref={this.wrapperRef}>
        {label(this.toggleDropdown)}
        <DropOptionsList isOpen={isOpen}>{renderItems}</DropOptionsList>
      </RootStyle>
    );
  }
}

const RootStyle = styled("div")(() => ({
  position: "relative",
}));

const DropOptionsList = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  z-index: 1;
  background-color: #fff;
  padding: 8px 16px;
  bottom: 48px;
  left: 0;
  list-style: none;
  border-radius: 16px;
  width: 280px;
  box-shadow: 0 12px 24px -4px rgba(145, 158, 171, 0.12),
    0 0 2px 0 rgba(145, 158, 171, 0.2);
`;
