import { Routes } from "../../../routes/routeMap";
import { AssignmentRounded } from "@material-ui/icons";
import Label from "../../../components/_MUI/Label";
import Iconify from "../../../components/_MUI/Iconify";

export function prepareNavBar(
  unreadMessagesCount: number,
  newLeadsCount: number,
  isAssistant: boolean,
  showPlans: boolean,
) {
  if (isAssistant) {
    return assistantConfig(unreadMessagesCount);
  }

  const full = fullConfig(unreadMessagesCount, newLeadsCount);
  if (showPlans) {
    full[0].items.push({
      title: "Plans",
      path: Routes.PLANS,
      icon: <Iconify icon="material-symbols:person-add-outline-rounded" />,
    });
  }
  return full;
}

function assistantConfig(unreadMessagesCount: number) {
  return [
    {
      subheader: "",
      items: [
        {
          title: "navigation.home",
          path: Routes.DEFAULT_ROUTE,
          icon: <Iconify icon="uil:home-alt" />,
        },
        {
          title: "navigation.leads",
          path: Routes.LEADS,
          icon: <Iconify icon="material-symbols:person-add-outline-rounded" />,
        },
        {
          title: "navigation.clients",
          path: Routes.CLIENT_LIST,
          icon: <Iconify icon="uil:users-alt" />,
        },
        {
          title: "navigation.messages",
          path: Routes.MESSAGES,
          icon: <Iconify icon="uil:comment" />,
          info: unreadMessagesCount ? (
            <Label color="info">{unreadMessagesCount}</Label>
          ) : (
            <></>
          ),
        },
      ],
    },
  ];
}

function fullConfig(unreadMessagesCount: number, newLeadsCount: number) {
  return [
    {
      subheader: "",
      items: [
        {
          title: "navigation.home",
          path: Routes.DEFAULT_ROUTE,
          icon: <Iconify icon="uil:home-alt" />,
        },
        {
          title: "navigation.leads",
          path: Routes.LEADS,
          icon: <Iconify icon="uil:user-plus" />,
          info: newLeadsCount ? <Label color="info">{newLeadsCount}</Label> : <></>,
        },
        {
          title: "navigation.clients",
          path: Routes.CLIENT_LIST,
          icon: <Iconify icon="uil:users-alt" />,
        },
        {
          title: "navigation.messages",
          path: Routes.MESSAGES,
          icon: <Iconify icon="uil:comment" />,
          info: unreadMessagesCount ? (
            <Label color="info">{unreadMessagesCount}</Label>
          ) : (
            <></>
          ),
        },
        {
          title: "navigation.workouts",
          path: Routes.WORKOUTS_TEMPLATES,
          icon: <Iconify icon="uil:dumbbell" />,
          children: [
            {
              title: "navigation.templates",
              path: Routes.WORKOUTS_TEMPLATES,
              icon: <AssignmentRounded />,
            },
            {
              title: "navigation.exercises",
              path: Routes.WORKOUTS_EXERCISES,
              icon: <Iconify icon="uil:comment" />,
            },
          ],
        },
        {
          title: "navigation.meals",
          path: Routes.MEALS_TEMPLATES,
          icon: <Iconify icon="uil:utensils" />,
          children: [
            {
              title: "navigation.templates",
              path: Routes.MEALS_TEMPLATES,
              icon: <AssignmentRounded />,
            },
            {
              title: "navigation.recipes",
              path: Routes.MEALS_RECIPES,
              icon: <Iconify icon="uil:comment" />,
            },
            {
              title: "navigation.ingredients",
              path: Routes.MEALS_INGREDIENTS,
              icon: <Iconify icon="uil:comment" />,
            },
          ],
        },
        {
          title: "navigation.content",
          path: Routes.CONTENT_VIDEOS,
          icon: <Iconify icon="uis:paperclip" />,
          children: [
            {
              title: "navigation.videos",
              path: Routes.CONTENT_VIDEOS,
              icon: <Iconify icon="uil:comment" />,
            },
            {
              title: "navigation.documents",
              path: Routes.CONTENT_FILES,
              icon: <Iconify icon="uil:comment" />,
            },
          ],
        },
      ],
    },
  ];
}
