import {
  AccountBoxRounded,
  AttachFileRounded,
  BarChartRounded,
  FitnessCenterRounded,
  RestaurantRounded,
} from "@mui/icons-material";
import React from "react";
import { Routes } from "../../routes/routeMap";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import Iconify from "../../components/_MUI/Iconify";

export const ACCOUNT_TABS = [
  {
    title: "navigation.clientOverview",
    value: Routes.CLIENT_OVERVIEW,
    icon: <Iconify icon="uil:user-square" width={22} height={22} />,
  },
  {
    title: "navigation.workoutPlan",
    value: Routes.CLIENT_WORKOUT_PLAN,
    icon: <Iconify icon="uil:dumbbell" width={22} height={22} />,
  },
  {
    title: "navigation.mealPlan",
    value: Routes.CLIENT_MEAL_PLAN,
    icon: <Iconify icon="uil:utensils" width={22} height={22} />,
  },
  {
    title: "navigation.progress",
    value: Routes.CLIENT_PROGRESS,
    icon: <Iconify icon="ci:bar-chart-alt" width={22} height={22} />,
  },
  {
    title: "navigation.documents",
    value: Routes.CLIENT_DOCUMENTS,
    icon: <Iconify icon="uil:file-alt" width={22} height={22} />,
  },
  {
    title: "navigation.videos",
    value: Routes.CLIENT_VIDEOS,
    icon: <Iconify icon="uil:video" width={22} height={22} />,
  },
];
