import "./styles.scss";
import { Fragment } from "react";
import { Stack } from "@mui/material";
import SubscriptionStatus from "./SubscriptionStatus";
import Form from "./Form";
import { InvitationData } from "../../../components/Modal/AddClientModal/types";

type Props = {
  onClose: (goToNextStep: boolean, invitationModalData?: InvitationData) => void;
};

function ModalSubscription(props: Props) {
  return (
    <Fragment>
      <Stack spacing={3} sx={{ p: 3 }}>
        <SubscriptionStatus />
        <Form onClose={props.onClose} />
      </Stack>
    </Fragment>
  );
}

export default ModalSubscription;
