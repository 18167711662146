import { MeasureSystem } from "../../../interfaces/measureSystem";

export const INITIAL_ACCOUNT = {
  name: "",
  email: "",
  phone: "",
};

export const INITIAL_GENERAL = {
  gender: "",
  birthday: "",
  photo: "",
  height: undefined,
  feet: undefined,
  inches: undefined,
  startWeight: undefined,
  activityLevel: undefined,
  measuringSystem: MeasureSystem.METRIC,
};
export const INITIAL_PHOTOS = {
  front: "",
  back: "",
  side: "",
};

export const INITIAL_WORKOUT = {
  injuries: "",
  experienceLevel: undefined,
  experience: undefined,
  exercisePreferences: undefined,
  workoutLocation: undefined,
  workoutsPerWeek: undefined,
};
export const INITIAL_GOAL = {
  goalWeight: "",
};

export const INITIAL_DIET = {
  feelsFat: undefined,
  foodDominates: undefined,
  lostControl: undefined,
  significantLoss: undefined,
  uncomfortablyFull: undefined,
  dietStyle: "",
  numberOfMeals: undefined,
  allergies: [],
  dietPreference: "none",
};

export const INITIAL_OTHER = {
  lifestyle: "",
  motivation: "",
  customQuestions: undefined,
};
