import {
  ActivationConfigEnum,
  IAccount,
  ICustomQuestion,
  IDiet,
  IGeneral,
  IGoal,
  IOther,
  IPhotos,
  IWorkout,
} from "../type";
import { LocaleEnum } from "../../../generated/graphql";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_STEP = "CHANGE_STEP";
export const SAVE_STEP = "SAVE_STEP";
export const GET_CLIENT_ACTIVATION_REQUEST = "GET_CLIENT_ACTIVATION_REQUEST";
export const GET_CLIENT_ACTIVATION_SUCCESS = "GET_CLIENT_ACTIVATION_SUCCESS";
export const GET_CLIENT_ACTIVATION_FAILURE = "GET_CLIENT_ACTIVATION_FAILURE";
export const POST_CLIENT_REQUEST = "POST_CLIENT_REQUEST";
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS";
export const POST_CLIENT_FAILURE = "POST_CLIENT_FAILURE";

export interface IClientActivationState {
  clientId: number;
  answeredQuestionnaire: boolean;
  showEatingDisorderQuestions: boolean;
  step: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | number;
  clientSubmitting: boolean;
  clientSubmitError: string;
  account: IAccount;
  general: IGeneral;
  photos: IPhotos;
  workout: IWorkout;
  goal: IGoal;
  diet: IDiet;
  other: IOther;
  initialLoading: boolean;
  initialError: string;
  userToken: string;
  locale: LocaleEnum;
  config: ActivationConfigEnum;
  customQuestions?: ICustomQuestion[];
}

export type TStepContent =
  | IAccount
  | IGeneral
  | IPhotos
  | IWorkout
  | IGoal
  | IDiet
  | IOther;

export type clientActivationActionTypes = any;
