export const InitialMealPlan = {
  id: 0,
  name: "",
  template: false,
  contains_alternatives: false,
  desired_kcals: {},
  avg_totals: 0,
  locale: "",
  lastUpdated: "",
};

export const InitialClient = {
  id: 0,
  name: "",
  firstName: "",
};

export const InitialWorkoutPlan = {
  id: 0,
  name: "",
  comment: "",
  template: false,
  // TODO: don't use two
  lastUpdated: "",
  last_updated: "",
  dayId: "",
  dayName: "",
  meta: {
    workoutsPerWeek: 0,
    duration: 0,
    gender: 0,
    level: 0,
    location: 0,
  },
  extraInfo: {
    settings: "",
    updatedAt: "",
    s3: "",
    youtubeApiKey: "",
    tour: false,
  },
};

export const InitialRecipePlan = {
  id: 0,
  name: "",
  macro_split: "",
  locale: "",
  template: false,
  admin: false,
  description: "",
  cooking_time: 0,
  types: [],
  recipeMeta: {
    lactose: false,
    gluten: false,
    nuts: false,
    eggs: false,
    pig: false,
    shellfish: false,
    fish: false,
    isVegetarian: false,
    isVegan: false,
    isPescetarian: false,
  },
};
