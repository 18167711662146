import React, { useEffect, useState } from "react";
import LoadingScreen from "../../components/_MUI/LoadingScreen";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { setRefreshToken, setToken } from "../../api/tokens";
import axios from "axios";
import { INTERACTIVE_TOKEN } from "../../api/auth";
import { useNavigate } from "react-router";
import { Routes } from "../routeMap";
import ZFError from "../../components/Error";

export default function InteractiveToken() {
  const [error, setError] = useState("");
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const interactiveToken = async () => {
    try {
      const { data } = await axios.post(INTERACTIVE_TOKEN(token));
      setToken(data.token);
      setRefreshToken(data.refresh_token);
      window.location.replace(Routes.DEFAULT_ROUTE);
    } catch (e: any) {
      console.log(e.response.data.message, "response");
      e?.response && setError(e?.response?.data?.message);
    }
  };

  // TODO: useEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useEffect(() => {
    token ? interactiveToken() : navigate(Routes.LOGIN_ROUTE);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <StyledInteractive>
      <LoadingScreen />
      {error && (
        <div className="error">
          <ZFError msg={error} />
        </div>
      )}
    </StyledInteractive>
  );
}

const StyledInteractive = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .error {
    position: absolute;
    z-index: 9999999;
    bottom: 27%;
  }
`;
