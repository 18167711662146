export default function ZFLogo({ color = "#000000", width = 15, height = 15 }) {
  return (
    <svg width={width} height={height} fill={color} viewBox="0 0 1360 320" version="1.1">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
          <stop stopColor={color} offset="0%" />
          <stop stopColor={color} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-2">
          <stop stopColor={color} offset="0%" />
          <stop stopColor={color} offset="100%" />
        </linearGradient>
      </defs>
      <g id="Artboard" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
        <g id="zenfit-logo-white" fillRule="nonzero">
          <path
            d="M2.51108968,140 L123.299904,140 L105.438766,238.733296 L280,238.733296 C254.324511,288.725046 203.68403,320.022007 148.505304,320 C66.4898746,320 0,251.957616 0,167.996313 C0,158.4339 0.864030858,149.064946 2.51108968,140 Z"
            id="Path"
            fill={color}
          />
          <path
            d="M13.0134602,102.266914 C38.3129443,51.766108 89.2784463,19.9843328 144.937314,20 C226.708297,20 293,87.5274259 293,170.82267 C293,180.804388 292.057783,190.566728 290.22719,200 L178.3591,200 L198.482165,102.266914 L13,102.266914 L13.0134602,102.266914 Z"
            id="Path"
            fill="{color}"
          />
          <polygon
            id="Path"
            fill={color}
            points="593 287 593 236.113271 503.608845 236.113271 591.474882 142.555605 591.474882 94 435.308287 94 435.308287 144.474518 517.032632 144.474518 433 234.961924 433 286.985786 593 286.985786"
          />
          <path
            d="M871.58764,172.156021 C871.58764,153.235539 883.926238,139.232166 903.193222,139.232166 C924.392424,139.232166 934.41236,153.221688 934.41236,171.782044 L934.41236,280 L993,280 L993,161.933974 C993,120.685661 971.414354,87 923.233093,87 C903.579666,87 880.075602,95.7261375 869.669223,113.496986 L869.669223,91.9309602 L813,91.9309602 L813,280 L871.573838,280 L871.573838,172.156021 L871.58764,172.156021 Z"
            id="Path"
            fill={color}
          />
          <path
            d="M1167.91484,138.858829 L1167.91484,280 L1227,280 L1227,89.9074861 L1096.78995,89.9074861 L1096.78995,73.4410162 C1096.78995,54.3141471 1111.94749,49.7214582 1122.05251,49.7214582 C1130.59863,49.7214582 1136.04087,50.8696304 1138.75503,51.639746 L1138.75503,4.20063009 C1133.32671,1.54023103 1121.27306,0 1111.55776,0 C1064.51234,0 1037.7048,29.8244737 1037.7048,73.4410162 L1037.7048,89.893484 L1007,89.893484 L1007,138.858829 L1037.7048,138.858829 L1037.7048,280 L1096.78995,280 L1096.78995,138.858829 L1167.91484,138.858829 L1167.91484,138.858829 Z"
            id="Path"
            fill={color}
          />
          <path
            d="M1160.01348,33.4931112 C1160.01348,51.9549661 1175.06557,67 1193.12269,67 C1211.94791,67 1227,51.9411886 1227,33.4931112 C1227,14.6730413 1211.94791,0 1193.10921,0 C1175.05209,0 1160,14.6730413 1160,33.4931112 L1160.01348,33.4931112 Z"
            id="Path"
            fill={color}
          />
          <path
            d="M1323.22362,40 L1271.66339,40 L1271.66339,64.2652533 C1271.66339,81.7245684 1262.1876,95.0030928 1242.47579,95.0030928 L1233,95.0030928 L1233,145.464264 L1266.35532,145.464264 L1266.35532,226.302142 C1266.35532,263.85981 1290.24842,287 1329.29193,287 C1347.48327,287 1356.97263,282.833099 1359.23976,281.694146 L1359.23976,233.885902 C1355.83228,234.635944 1350.14409,235.774897 1344.07579,235.774897 C1331.57263,235.774897 1323.22362,231.607996 1323.22362,216.051566 L1323.22362,145.464264 L1360,145.464264 L1360,95.0169825 L1323.22362,95.0169825 L1323.22362,40 Z"
            id="Path"
            fill={color}
          />
          <path
            d="M696.298364,87 C756.107645,87 793,123.046673 793,183.77733 C793,184.175997 792.998053,184.576538 792.994306,184.977914 L792.984452,185.781467 L792.984452,185.781467 L792.970057,186.58539 C792.823596,193.682896 792.175844,200.606982 791.831904,201.613141 L791.831904,201.613141 L657.473089,201.613141 C658.641185,222.852202 678.443188,238.034866 701.346206,238.034866 C722.70567,238.034866 734.748181,228.172385 740.185388,213.364773 L740.185388,213.364773 L789.509619,227.033338 C780.178759,259.66287 749.502342,287 700.581382,287 C647.377405,287 600,249.800389 600,186.430476 C600,125.71371 646.209309,87 696.298364,87 Z M697.077095,132.534102 C672.22725,132.534102 659.795374,150.383803 658.641185,164.41353 L658.641185,164.41353 L735.916276,164.41353 C735.137546,149.99486 725.027956,132.534102 697.077095,132.534102 Z"
            id="Combined-Shape"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}
