import { Fragment } from "react";
import { GetClientGeneralQuery } from "../../../../generated/graphql";
import { WEEK_DAYS } from "../../../../helpers/week";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { Stack, Tooltip, Typography } from "@mui/material";
import { differenceInWeeks } from "date-fns";
import Iconify from "../../../../components/_MUI/Iconify";

interface Props {
  checkInDay: GetClientGeneralQuery["client"]["checkInDay"];
  checkInFrequency: GetClientGeneralQuery["client"]["checkInFrequency"];
  checkInReminderLastSent: GetClientGeneralQuery["client"]["checkInReminderLastSent"];
}

export default function CheckInDay(props: Props) {
  const { checkInDay, checkInFrequency, checkInReminderLastSent } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const weekdayString =
    checkInDay && WEEK_DAYS.find(v => String(checkInDay) === v.value)?.label;
  const dayTrackProgressLastSentObj =
    checkInReminderLastSent && new Date(checkInReminderLastSent);

  const weeksUntilNextCheckIn =
    dayTrackProgressLastSentObj &&
    differenceInWeeks(new Date(), dayTrackProgressLastSentObj);

  const tooltipString =
    weeksUntilNextCheckIn && checkInDay
      ? t("newClientOverview.generalCard.weeksUntilNextCheckIn", {
          weeksUntilNextCheckIn,
        })
      : "";

  const getWeekString =
    checkInFrequency && checkInFrequency !== 1
      ? `, ${t("newClientOverview.generalCard.everyWeek", {
          frequency: checkInFrequency,
        })}`
      : "";

  const rowString = checkInDay
    ? `${t("table.Check-In")} ${t(weekdayString || "-")} ${getWeekString}`
    : t("newClientOverview.noCheckin");

  return (
    <Fragment>
      <Iconify icon="uil:file-plus-alt" fontSize={20} />
      <Tooltip title={tooltipString}>
        <Stack>
          <Typography>{rowString}</Typography>
        </Stack>
      </Tooltip>
    </Fragment>
  );
}
