import React from "react";
import Main from "./Main";
import WorkoutDays from "../WorkoutMobile/workout-days";
import { PlanId } from "./types";
import { IS_MOBILE } from "../../helpers/devices";

interface Props {
  type: PlanId;
}

export default function Editors(props: Props) {
  const { type } = props;

  return (
    <>
      {type === PlanId.WORKOUT && IS_MOBILE ? (
        <WorkoutDays />
      ) : (
        <Main type={props.type} />
      )}
    </>
  );
}
