import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: "8px!important",
          color: "#fff!important",
          fontFamily: "inter,sans-serif!important",
          padding: "4px 8px!important",
          fontSize: "0.6875rem!important",
          minWidth: "fit-content!important",
          margin: 0,
          wordWrap: "break-word",
          fontWeight: 600,
          position: "relative",
          backgroundColor: "#212B36!important",
        },
        arrow: {
          color: "#212B36!important",
        },
      },
      defaultProps: {
        arrow: true,
        placement: "top",
      },
    },
  };
}
