import mappet from "mappet";
import { format } from "date-fns";

const formatClientFoodPreferences = (source: any) => {
  const allergies = source?.clientFoodPreferences.allergies;
  const disorders = source?.clientFoodPreferences.eatingDisorder;
  const birthdaySource = source?.birthday?.date;
  const cleaned = birthdaySource?.replace(" 00:00:00.000000", "");

  const clientFoodPreferences = allergies
    ? Object.entries(allergies)
        .filter(([_, v]) => v)
        .map(([k]) => k)
    : [];

  const eatingDisorder = disorders
    ? Object.entries(disorders)
        .filter(([_, v]) => v)
        .map(([k]) => k)
    : [];

  return {
    ...source,
    clientFoodPreferences,
    eatingDisorder,
    birthday: birthdaySource
      ? format(new Date(cleaned).getTime(), "dd/MM/yyyy")
      : undefined,
  };
};

const getSchema = {
  id: "id",
  name: "name",
  firstName: "firstName",
  info: { path: "info", modifier: formatClientFoodPreferences },
  email: "email",
  photo: "photo",
  active: "active",
  createdAt: "createdAt",
  startedAt: "startedAt",
  startDate: "startDate",
  endDate: "endDate",
  images: "images",
  tags: "tags",
  status: "status",
  reminders: "reminders",
  answeredQuestionnaire: "answeredQuestionnaire",
  customQuestions: "customQuestions",
  payments: "payments",
  measuringSystem: "measuringSystem",
  dayTrackProgress: "dayTrackProgress",
  goalWeight: "goalWeight",
  bodyProgressUpdated: "bodyProgressUpdated",
  queue: "queue",
  demoClient: "demoClient",
  hideKcals: "hideKcals",
  trainer: "trainer",
  messages: "messages",
  documents_count: "documents_count",
  videos_count: "videos_count",
  workout_plans_count: "workout_plans_count",
  master_meal_plans_count: "master_meal_plans_count",
  previous_kcals: "previous_kcals",
};

const mapper = mappet(getSchema);

export function getClientsSchema(source: any) {
  return mapper(source);
}

const mergeClientFoodPreferencesEatingDisorder = (source: any) => {
  const clientFoodPreferences = source.clientFoodPreferences || [];
  const eatingDisorder = source.eatingDisorder || [];
  return clientFoodPreferences?.concat(eatingDisorder);
};

const postSchema = {
  name: "name",
  measuringSystem: "measuringSystem",
  email: "email",
  phone: "info.phone",
  age: "info.age",
  gender: "info.gender",
  goalType: "info.goalType",
  startDate: "startDate",
  height: "info.height",
  feet: "info.feet",
  inches: "info.inches",
  startWeight: "info.startWeight",
  goalWeight: "info.goalWeight",
  locale: "info.locale",
  workoutLocation: "info.workoutLocation",
  experienceLevel: "info.experienceLevel",
  workoutsPerWeek: "info.workoutsPerWeek",
  numberOfMeals: "info.numberOfMeals",
  clientFoodPreferences: {
    path: "info",
    modifier: mergeClientFoodPreferencesEatingDisorder,
  },
  activityLevel: "info.activityLevel",
  lifestyle: "info.lifestyle",
  dietStyle: "info.dietStyle",
  motivation: "info.motivation",
  experience: "info.experience",
  exercisePreferences: "info.exercisePreferences",
  injuries: "info.injuries",
  other: "info.other",
  tags: "tags",
  note: "info.notes.note",
  dialogMessage: "info.notes.dialogMessage",
  salesNotes: "info.notes.salesNotes",
};

const postMapper = mappet(postSchema);

export function postClientsSchema(source: any) {
  return postMapper(source);
}
