import { useState } from "react";
import { Outlet } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
// hooks
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// config
import { HEADER, NAVBAR } from "../../config";
//
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";
//import NavbarHorizontal from "./navbar/NavbarHorizontal"
import { connect } from "react-redux";
import { AppState } from "../../store";
import CreateSubscriptionModal from "../../components/Modal/CreateSubscriptionModal";
import EmailInvitationModal from "../../components/Modal/EmailInvitationModal";
import TrackedWorkoutsModal from "../../components/Modal/TrackedWorkoutsModal";
import MealTemplateModal from "../../components/Modal/MealTemplateModal";
import ClientInfoModal from "../../components/Modal/ClientInfoModal";

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled("main", {
  shouldForwardProp: (prop: keyof MainStyleProps) =>
    prop !== "collapseClick" && prop !== "withoutBottomPadding",
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  height: "100%",
  [theme.breakpoints.up("lg")]: {
    position: "relative",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

type Props = {
  unreadMessagesCount: number;
  newLeadsCount: number;
  isAssistant: boolean;
  showPlans: boolean;
  activated: boolean;
};

function DashboardLayoutComponent(props: Props) {
  const { isAssistant, unreadMessagesCount, showPlans, activated, newLeadsCount } = props;
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        id={"layout"}
        sx={{
          overflowX: { xs: "hidden", sm: "hidden" },
          display: { lg: "flex" },
          height: "100%",
          minHeight: { lg: 1 },
        }}>
        {activated && (
          <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
        )}
        {activated && (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
            isAssistant={isAssistant}
            unreadMessagesCount={unreadMessagesCount}
            newLeadsCount={newLeadsCount}
            showPlans={showPlans}
          />
        )}
        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </Box>
      <TrackedWorkoutsModal />
      <CreateSubscriptionModal />
      <EmailInvitationModal />
      <MealTemplateModal />
      <ClientInfoModal />
    </>
  );
}

function mapStateToProps(state: AppState) {
  return {
    activated: state.user.activated,
    unreadMessagesCount: state.user.unreadMessagesCount,
    newLeadsCount: state.user.newLeadsCount,
    isAssistant: state.user.isAssistant,
    showPlans: [325, 2471, 3272].includes(state.user.id), //hardcoded because it is only for a few users
  };
}

export const DashboardLayout = connect(mapStateToProps)(DashboardLayoutComponent);
