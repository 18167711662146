import { styled } from "@mui/material";
import { PRIMARY } from "../../../theme/palette";

export const StyledImage = styled("div")(
  ({ exerciseimage }: { exerciseimage: string }) => ({
    marginRight: 12,
    borderRadius: 8,
    width: 48,
    height: 48,
    minWidth: 48,
    background: exerciseimage ? `url(${exerciseimage})` : PRIMARY.lighter,
    backgroundColor: "#f4f4f5",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    transition: "all 0.375s ",
    ".workout-editor-play-icon": {
      fontSize: "28px !important",
      transition: "all 0.375s ",
      opacity: exerciseimage ? 0 : 1,
      margin: 10,
    },
    "&:hover": {
      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${exerciseimage})`,
      cursor: "pointer",
      ".workout-editor-play-icon": {
        opacity: 1,
        color: "#fff"
      },
    },
  }),
);
