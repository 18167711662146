enum RequestTypes {
  REQUEST = "REQUEST",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

interface ReduxActionTypes {
  [RequestTypes.REQUEST]: string;
  [RequestTypes.SUCCESS]: string;
  [RequestTypes.FAILURE]: string;
}

export default function createRequestTypes(base: string): ReduxActionTypes {
  return [
    RequestTypes.REQUEST,
    RequestTypes.SUCCESS,
    RequestTypes.FAILURE,
  ].reduce((requestType, type: RequestTypes) => {
    requestType[type] = `${base}_${type}`;
    return requestType;
  }, {} as ReduxActionTypes);
}
