import { Action, Middleware, MiddlewareAPI } from "redux";
import {
  CLIENT_PROGRESS_REQUEST,
  CLIENT_PROGRESS_RESET,
} from "../../routes/ClientProgress/top/store/clientProgress/types";
import { RESET_CURRENT_CLIENT, SET_CURRENT_CLIENT_REQUEST } from "../currentClient/types";

const resetClientState: Middleware =
  store =>
  next =>
  <A extends Action>(action: A) => {
    resetStateTrigger(store, action);
    let result = next(action);

    return result;
  };
export default resetClientState;

// TODO: create store type when upgrading redux
function resetStateTrigger<S extends any>(store: MiddlewareAPI<S>, action: Action) {
  switch (action?.type) {
    case CLIENT_PROGRESS_REQUEST:
      store.dispatch({ type: CLIENT_PROGRESS_RESET });
      break;
    case SET_CURRENT_CLIENT_REQUEST:
      store.dispatch({ type: RESET_CURRENT_CLIENT });
      break;

    default:
      break;
  }
}
