import { combineReducers, Reducer } from "redux";
import auth, { AuthState, INITIAL_STATE as authInitState } from "./auth/reducer";
import plan, {
  INITIAL_STATE as planInitState,
  PlannerState,
} from "../routes/Editors/store/reducer";
/*TODO: BIG todo, dont have multible reducers from the same route*/
import user from "./user/reducer";
import currentClient from "./currentClient/reducer";
import { UserState } from "./user/reducerTypes";
import chat from "../routes/Chat/reducers/chat-reducer";
// TODO: merge to use clients directly
import chatClients from "../routes/Chat/reducers/clients-reducer";
import clients from "../routes/Clients/store/clients/reducer";
import progress from "../routes/Clients/store/progress/reducer";
import workouts from "../routes/Clients/store/workouts/reducer";
import clientActivation from "../routes/ClientActivation/store/reducer";
import leads from "../routes/Leads/store/leads/reducer";
import workoutTemplate, {
  WorkoutTemplateState,
} from "../routes/WorkoutTemplates/store/reducer";
import mealTemplate from "../routes/MealTemplatesOld/store/reducer";
import recipes from "../routes/Recipes/store/reducer";
import videos from "../routes/Videos/store/reducer";
import documents from "../routes/Documents/store/reducer";
import exercises from "../routes/Exercises/store/reducer";
import clientWorkout from "../routes/ClientWorkout/store/reducer";
import settings from "../routes/Settings/store/reducer";
import modal, {
  INITIAL_STATE as modalInitState,
} from "../routes/MealPlan/store/modal/reducer";
import { ModalState } from "../routes/MealPlan/store/modal/types";
import workoutDay from "../routes/WorkoutMobile/workout-day/reducers/workout-day";
import workoutDayGlobal from "../routes/WorkoutMobile/workout-day/reducers/workout-global";
import workoutDayTrack from "../routes/WorkoutMobile/workout-day/reducers/workout-track";
import clientProgress from "../routes/ClientProgress/sharedStore/indexReducer";
import chatWidget from "../routes/ChatWidget/reducers/widget-reducer";
import chatWidgetMessages from "../routes/ChatWidget/reducers/messages-reducer";
import { NotificationState } from "./notification/reducerTypes";
import notification from "./notification/reducer";
import { CurrentClientState } from "./currentClient/types";
import { ClientProgressState } from "../routes/ClientProgress/top/store";
import { IClientActivationState } from "../routes/ClientActivation/store/types";
import globalModal from "./modals/reducer";
import { GlobalModalState } from "./modals/reducerTypes";

export const initialState = {
  auth: authInitState,
  plan: planInitState,
  modal: modalInitState,
};

export type RootReducer = Reducer<{
  workoutTemplate: WorkoutTemplateState;
  auth: AuthState;
  plan: PlannerState;
  modal: ModalState;
  clients: any;
  user: UserState;
  notification: NotificationState;
  currentClient: CurrentClientState;
  clientWorkout: any;
  documents: any;
  chatWidgetMessages: any;
  clientProgress: ClientProgressState;
  clientActivation: IClientActivationState;
  settings: any;
  chatWidget: any;
  globalModal: GlobalModalState;
}>;

/**
 * some stores ignored as they are in javascript and doesn't have types
 * TODO: fix typing and convert to TypeScript
 */
export default combineReducers({
  auth,
  // @ts-ignore
  user,
  // @ts-ignore
  chat,
  // @ts-ignore
  clients,
  // @ts-ignore
  currentClient,
  // @ts-ignore
  chatClients,
  // @ts-ignore
  clientActivation,
  // @ts-ignore
  clientProgress,
  // @ts-ignore
  clientWorkout,
  // @ts-ignore
  progress,
  // @ts-ignore
  mealTemplate,
  // @ts-ignore
  videos,
  // @ts-ignore
  documents,
  // @ts-ignore
  recipes,
  // @ts-ignore
  settings,
  // @ts-ignore
  notification,
  // @ts-ignore
  modal,
  // @ts-ignore
  workoutDay,
  // @ts-ignore
  workoutDayGlobal,
  // @ts-ignore
  workoutDayTrack,
  // @ts-ignore
  workouts,
  // @ts-ignore
  workoutTemplate,
  // @ts-ignore
  exercises,
  // @ts-ignore
  plan,
  // @ts-ignore
  chatWidget,
  // @ts-ignore
  chatWidgetMessages,
  // @ts-ignore
  leads,
  // @ts-ignore
  globalModal,
}) as RootReducer;
