import mappet from "mappet";
import { S3_BEFORE_AFTER_IMAGES, S3_CLIENT_PHOTO } from "../../../config";
import { DIET_PREFERENCES_ARR } from "../../../helpers/clientFoodPreference";
import { ActivationConfigEnum, IAccount, IDiet, IGeneral } from "../type";
import { IClientActivationState } from "./types";
import moment from "moment";
import { getCurrentAge } from "../../../helpers/dates";

const getAccount = (source?: any): IAccount => ({
  name: source?.name || "",
  email: source?.email || "",
  phone: source?.phone || "",
});

const getGeneral = (source?: any): IGeneral => ({
  gender: source?.gender || "",
  birthday: source?.birthday?.date || "",
  photo: source?.photo ? S3_CLIENT_PHOTO + source?.photo : "",
  height: source?.height,
  feet: source?.feet,
  inches: source?.inches,
  startWeight: source?.startWeight,
  activityLevel: source?.activityLevel,
  measuringSystem: source?.measuringSystem,
});

const getPictures = (source?: any) => ({
  front: source?.[0] ? S3_BEFORE_AFTER_IMAGES + source[0].name : "",
  back: source?.[1] ? S3_BEFORE_AFTER_IMAGES + source[1].name : "",
  side: source?.[2] ? S3_BEFORE_AFTER_IMAGES + source[2].name : "",
});

const getWorkout = (source?: any) => ({
  injuries: source?.injuries,
  experienceLevel: source?.experienceLevel,
  experience: source?.experience,
  exercisePreferences: source?.exercisePreferences,
  workoutLocation: source?.workoutLocation,
  workoutsPerWeek: source?.workoutsPerWeek,
});

const getDiet = (source: {
  dietStyle: "";
  numberOfMeals: number;
  clientFoodPreferences: {
    allergies: any;
    eatingDisorder: any;
  };
}): IDiet => {
  const { clientFoodPreferences, dietStyle } = source;

  const dietPreference = Boolean(clientFoodPreferences?.allergies)
    ? Object.entries(clientFoodPreferences.allergies)
        .filter(([key, val]) => DIET_PREFERENCES_ARR.includes(key) && val)
        .reduce((p, [key, val]) => (val ? key : "none"), "none")
    : "none";

  const allergies = Boolean(clientFoodPreferences?.allergies)
    ? Object.entries(clientFoodPreferences.allergies)
        .filter(([key, val]) => !DIET_PREFERENCES_ARR.includes(key) && val)
        .map(([key]) => key)
    : [];

  return {
    feelsFat: clientFoodPreferences?.eatingDisorder?.feelsFat,
    foodDominates: clientFoodPreferences?.eatingDisorder?.foodDominates,
    lostControl: clientFoodPreferences?.eatingDisorder?.lostControl,
    significantLoss: clientFoodPreferences?.eatingDisorder?.significantLoss,
    uncomfortablyFull: clientFoodPreferences?.eatingDisorder?.uncomfortablyFull,
    dietStyle: dietStyle,
    numberOfMeals: source?.numberOfMeals,
    allergies,
    dietPreference: dietPreference || "none",
  };
};

const getOther = (source?: any) => ({
  lifestyle: source?.lifestyle || "",
  motivation: source?.motivation || "",
});

const getClientActivationSchema = {
  clientId: "id",
  answeredQuestionnaire: "answeredQuestionnaire",
  account: { path: "info", modifier: getAccount },
  general: { path: "info", modifier: getGeneral },
  photos: { path: "images", modifier: getPictures },
  workout: { path: "info", modifier: getWorkout },
  goal: { path: "info.goalWeight", modifier: (source: any) => ({ goalWeight: source }) },
  diet: { path: "info", modifier: getDiet },
  other: { path: "info", modifier: getOther },
};

const mapper = mappet(getClientActivationSchema);

export function getSchemaClientActivation(source?: any) {
  return mapper(source);
}

const isQuestionnaire = (
  config: ActivationConfigEnum,
  source: IClientActivationState,
) => {
  return (
    source.step !== 1 &&
    (config === ActivationConfigEnum.SURVEY || config === ActivationConfigEnum.FULL)
  );
};

const getClientFoodPreferences = (source: IDiet) => {
  const {
    allergies,
    dietPreference,
    significantLoss,
    foodDominates,
    lostControl,
    feelsFat,
    uncomfortablyFull,
  } = source;

  const eatingDisorderObj = {
    significantLoss,
    foodDominates,
    lostControl,
    feelsFat,
    uncomfortablyFull,
  };

  const eatingDisorderArr = Object.entries(eatingDisorderObj)
    .filter(([_, val]) => val)
    .map(([key]) => key);

  return dietPreference !== "none"
    ? [...allergies, ...eatingDisorderArr, dietPreference]
    : [...allergies, ...eatingDisorderArr];
};

const submitClientActionSchema = {
  datakey: "datakey",
  client: "clientId",
  isQuestionnaire: { path: "config", modifier: isQuestionnaire },
  name: "account.name",
  email: "account.email",
  phone: "account.phone",
  password: {
    path: "account.password",
    modifier: (source: string) => source || undefined,
  },
  passwordConfirm: {
    path: "account.passwordConfirm",
    modifier: (source: string) => source || undefined,
  },
  gender: "general.gender",
  age: {
    path: "general.birthday",
    modifier: (source: string) => {
      return moment(source).isValid() ? getCurrentAge(source) : undefined;
    },
  },
  birthday: {
    path: "general.birthday",
    modifier: (source: string) => {
      return moment(source).isValid() ? moment(source).format("yyyy-MM-DD") : undefined;
    },
  },
  height: "general.height",
  feet: "general.feet",
  inches: "general.inches",
  startWeight: "general.startWeight",
  activityLevel: "general.activityLevel",
  measuringSystem: "general.measuringSystem",
  injuries: "workout.injuries",
  experienceLevel: "workout.experienceLevel",
  experience: "workout.experience",
  exercisePreferences: "workout.exercisePreferences",
  workoutLocation: "workout.workoutLocation",
  workoutsPerWeek: "workout.workoutsPerWeek",
  goalWeight: "goal.goalWeight",
  numberOfMeals: "diet.numberOfMeals",
  clientFoodPreferences: { path: "diet", modifier: getClientFoodPreferences },
  dietStyle: "diet.dietStyle",
  lifestyle: "other.lifestyle",
  motivation: "other.motivation",
  questions: "other.customQuestions",
};

const submitMapper = mappet(submitClientActionSchema);

export function submitSchemaClientActivation(source?: any) {
  return submitMapper(source);
}
