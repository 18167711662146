export enum Routes {
  LOGIN_ROUTE = "/auth/login",
  SIGNUP_ROUTE = "/auth/sign-up",
  NEW_PASSWORD_ROUTE = "/auth/new-password",
  FINISH_SIGN_UP_ROUTE = "/auth/finish-sign-up",
  INTRO_ROUTE = "/dashboard/intro",
  LEADS = "/dashboard/leads",
  MESSAGES = "/dashboard/chat",
  PLANS = "/dashboard/plans",
  SETTINGS = "/dashboard/settings/general",
  SETTINGS_FORM = "/dashboard/settings/form",
  SETTINGS_PRICING = "/dashboard/settings/pricing",
  SETTINGS_INTEGRATIONS = "/dashboard/settings/integrations",

  CLIENT_LIST = "/dashboard/clients",
  DEFAULT_ROUTE = "/dashboard/app",
  CLIENT_OVERVIEW = "/dashboard/clients/:clientId",
  CLIENT_MEAL_PLAN = "/dashboard/clients/:clientId/meals",
  CLIENT_MEAL_PLAN_EDITOR = "/dashboard/clients/:clientId/meals/:templateId",
  CLIENT_WORKOUT_PLAN = "/dashboard/clients/:clientId/workouts",
  CLIENT_WORKOUT_DAY = "/dashboard/clients/:clientId/workouts/:templateId/day/:dayId",
  CLIENT_WORKOUT_PLAN_EDITOR = "/dashboard/clients/:clientId/workouts/:templateId",
  CLIENT_PROGRESS = "/dashboard/clients/:clientId/progress",
  CLIENT_DOCUMENTS = "/dashboard/clients/:clientId/documents",
  CLIENT_VIDEOS = "/dashboard/clients/:clientId/videos",

  WORKOUTS_EXERCISES = "/dashboard/workouts/exercises",
  WORKOUTS_DAY = "/dashboard/workouts/templates/:templateId/day/:dayId",
  WORKOUTS_TEMPLATES = "/dashboard/workouts/templates",
  WORKOUTS_TEMPLATES_EDITOR = "/dashboard/workouts/templates/:templateId",

  MEALS_TEMPLATES = "/dashboard/meals/templates",
  MEALS_TEMPLATES_EDITOR = "/dashboard/meals/templates/:templateId",
  MEALS_RECIPES = "/dashboard/meals/recipes",
  MEALS_INGREDIENTS = "/dashboard/meals/ingredients",
  MEALS_RECIPES_EDITOR = "/dashboard/meals/recipes/:templateId",

  CONTENT_VIDEOS = "/dashboard/content/videos",
  CONTENT_FILES = "/dashboard/content/files",

  CLIENT_ACTIVATION = "/client/clientActivation/:datakey",
  CLIENT_CHECKOUT = "/client/checkout/:datakey",
  CLIENT_SETTINGS = "/client/settings",

  LENUS_TRANSFER = "/lenus/transfer",
  LENUS_TRANSFER_EMAIL = "/lenus/transfer/email",
  LENUS_TRANSFER_PROGRESS = "/lenus/transfer/progress",
  LENUS_TRANSFER_SUCCESS = "/lenus/transfer/success",

  LENUS_SIGNUP = "/lenus/transfer/start/sign-up",
  LENUS_SUBSCRIPTION = "/lenus/transfer/start/subscription",
  LENUS_PAYMENT_FAILED = "/lenus/transfer/start/payment-failed",
  LENUS_SUCCESS = "/lenus/transfer/start/success",
}

export enum RoutesTitle {
  DEFAULT_ROUTE = "Dashboard",
  LOGIN_ROUTE = "Login",
  INTRO_ROUTE = "Intro",
  FINISH_SIGNUP = "Finish signup",
  LEADS = "Leads",
  MESSAGES = "Chat",
  SIGN_UP = "Sign up",

  CLIENT_LIST = "Clients",
  CLIENT_GENERAL = "General",
  CLIENT_MEAL_PLAN = "Meal plan",
  CLIENT_MEAL_PLAN_EDITOR = "Meal plan template",
  CLIENT_WORKOUT_PLAN = "Workout plan",
  CLIENT_WORKOUT_PLAN_EDITOR = "Workout plan template",
  CLIENT_PROGRESS = "Client progress",
  CLIENT_DOCUMENTS = "Client documents",
  CLIENT_VIDEOS = "Client videos",

  WORKOUTS_EXERCISES = "Exercises",
  WORKOUTS_DAY = "Workout day",
  WORKOUTS_TEMPLATES = "Workout template",
  WORKOUTS_TEMPLATES_EDITOR = "Workout template",

  MEALS_TEMPLATES = "Meal plan template",
  MEALS_TEMPLATES_EDITOR = "Meal plan template",
  MEALS_RECIPES = "Recipes",
  MEALS_INGREDIENTS = "Ingredients",
  MEALS_RECIPES_EDITOR = "Recipes template",

  CONTENT_VIDEOS = "Videos",
  CONTENT_FILES = "Documents",

  CLIENT_ACTIVATION = "Client activation",
  CLIENT_CHECKOUT = "Checkout",
  CLIENT_SETTINGS = "Checkout",
  PLANS = "Plans",
  TRAINER_SETTINGS = "Settings",
}
