import { useState } from "react";
import { MenuItem, Stack, Tooltip } from "@mui/material";
import MenuPopover from "../../../components/_MUI/MenuPopover";
import { IconButtonAnimate } from "../../../components/_MUI/animate";
import { LocaleObj } from "../../../i18n/locales";
import { Locale } from "../../../interfaces/locale";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

export default function LanguagePopover({
  locale,
  changeLang,
}: {
  locale: Locale;
  changeLang: (locale: Locale) => void;
}) {
  const { t } = useTranslation(TranslatorNS.SETTINGS);

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const update = (locale: Locale) => {
    changeLang(locale);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t("topbar.localeTooltip")}>
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            width: 40,
            height: 40,
            ...(open && { bgcolor: "action.selected" }),
          }}>
          {locale && (
            <img
              style={{ width: 25, height: 25 }}
              src={LocaleObj[locale].icon}
              alt={"flag"}
            />
          )}
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 0.5,
          ml: 0.75,
          "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
        }}>
        <Stack spacing={0.75}>
          {Object.values(LocaleObj).map(option => (
            <MenuItem
              key={option.value}
              selected={option.value === locale}
              onClick={() => update(option.value)}>
              <img
                alt={option.label}
                src={option.icon}
                style={{ width: 25, height: 25, marginRight: 8 }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
