import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import {
  ClientPaymentConfigurationStatusEnum,
  GetClientSubscriptionDocument,
  GetClientSubscriptionQuery,
  useDeleteLatestPendingPaymentMutation,
} from "../../../../generated/graphql";
import { Fragment, MouseEvent, useState } from "react";
import { MenuItem, Popover, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import PauseSubscriptionContainer from "./PauseSubscriptionModal/Container";
import AdjustPaymemtDateContainer from "./AdjustPaymentDateModal/Container";
import UnsubscribeClientContainer from "./UnsubscribeClientModal/Container";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { TranslatorNS } from "../../../../i18n/const";
import { ModalPayload, ModalTypeEnum } from "../../../../store/modals/reducerTypes";
import { openGlobalModalAction } from "../../../../store/modals/action";
import { useSnackbar } from "notistack";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";

interface Props {
  payment?: GetClientSubscriptionQuery["client"]["paymentConfiguration"];
  handlePaymentModal: () => void;
  openGlobalModalAction: (
    payload: ModalPayload<ModalTypeEnum.ClientSubscription>,
  ) => void;
}
function SubscriptionOptions(props: Props) {
  const { payment, openGlobalModalAction } = props;
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [unsubscribeClientModal, setUnsubscribeClientModal] = useState(false);
  const [adjustPaymentDateModal, setAdjustPaymentDateModal] = useState(false);
  const [pauseSubscriptionModal, setPauseSubscriptionModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deletePendingPayment] = useDeleteLatestPendingPaymentMutation();

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCopy = () => {
    if (!payment?.url) {
      enqueueSnackbar(t("newClientOverview.copySuccess"), {
        variant: "error",
      });
      return;
    }

    navigator.clipboard.writeText(payment?.url);
    enqueueSnackbar(t("newClientOverview.copiedPaymentLink"), {
      variant: "success",
    });
  };

  const handleCancelPendingSubscription = async () => {
    try {
      await deletePendingPayment({
        variables: {
          clientId: Number(clientId),
        },
        refetchQueries: () => [
          {
            query: GetClientSubscriptionDocument,
            variables: { id: Number(clientId) },
          },
        ],
      });
      enqueueSnackbar(t("clientPayment.deletedPendingSubscriptionSuccess"), {
        variant: "success",
      });
    } catch (error) {
      console.error({ error });
      enqueueSnackbar(t("clientPayment.deletedSubscriptionError"), {
        variant: "error",
      });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "payment-options" : undefined;

  return (
    <Fragment>
      <Stack>
        <IconButton component="span" aria-describedby={id} onClick={handleOpen}>
          <MoreHorizIcon />
        </IconButton>
        <Popover
          id={id}
          open={open}
          disableScrollLock
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}>
          <MenuItem
            onClick={() => {
              openGlobalModalAction({
                type: ModalTypeEnum.ClientSubscription,
                context: {
                  clientId: Number(clientId),
                },
              });
              setAnchorEl(null);
            }}
            style={{ fontSize: 13 }}>
            {t("clientPayment.createNewSub")}
          </MenuItem>
          {payment?.status === ClientPaymentConfigurationStatusEnum.Pending && (
            <>
              <ConfirmModal
                title={t("clientPayment.deletePendingSubscription")}
                body={t("clientPayment.deletePendingSubscriptionBody")}
                type={"danger"}
                action={() => {
                  handleCancelPendingSubscription();
                  setAnchorEl(null);
                }}
                actionTxt={t("clientPayment.deletePendingSubscription")}>
                <MenuItem style={{ fontSize: 13 }}>
                  {t("clientPayment.deletePendingSubscription")}
                </MenuItem>
              </ConfirmModal>
              <MenuItem
                onClick={() => {
                  handleCopy();
                  setAnchorEl(null);
                }}
                style={{ fontSize: 13 }}>
                {t("newClientOverview.copyPaymentLink")}
              </MenuItem>
            </>
          )}
          {payment?.status === ClientPaymentConfigurationStatusEnum.Active && (
            <>
              <MenuItem
                onClick={() => {
                  setAdjustPaymentDateModal(true);
                  setAnchorEl(null);
                }}
                style={{ fontSize: 13 }}>
                {t("clientPayment.adjustPaymentDate")}
              </MenuItem>
            </>
          )}
          {(payment?.status === ClientPaymentConfigurationStatusEnum.Active ||
            payment?.status === ClientPaymentConfigurationStatusEnum.Paused) && (
            <>
              <MenuItem
                onClick={() => {
                  setPauseSubscriptionModal(true);
                  setAnchorEl(null);
                }}
                style={{ fontSize: 13 }}>
                {payment?.status === ClientPaymentConfigurationStatusEnum.Paused
                  ? t("clientPayment.editSubDate")
                  : t("clientPayment.pauseSub")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setUnsubscribeClientModal(true);
                  setAnchorEl(null);
                }}
                style={{ fontSize: 13 }}>
                {t("clientPayment.unsubClient")}
              </MenuItem>
            </>
          )}
        </Popover>
      </Stack>
      <PauseSubscriptionContainer
        payment={payment}
        open={pauseSubscriptionModal}
        handleClose={() => setPauseSubscriptionModal(false)}
      />
      <AdjustPaymemtDateContainer
        open={adjustPaymentDateModal}
        handleClose={() => setAdjustPaymentDateModal(false)}
      />
      <UnsubscribeClientContainer
        open={unsubscribeClientModal}
        handleClose={() => setUnsubscribeClientModal(false)}
      />
    </Fragment>
  );
}

export default connect(undefined, { openGlobalModalAction })(SubscriptionOptions);
