import { Theme } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Popover(theme: Theme) {
  return {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "-1px 0px 16px -4px rgb(145 158 171 / 44%)",
          borderRadius: 8,
          padding: "4px",
        },
      },
    },
  };
}
