import styled from "styled-components";
import { Colors, Fonts } from "../../styleguide";

export const ZFErrorStyled = styled.div`
  display: flex;
  justify-content: center;
  svg {
    margin-right: 1rem;
  }
  span {
    width: 100%;
    padding: 12px 15px;
    border-radius: 6px;
    border: solid 1px rgba(222, 93, 93, 0.2);
    background-color: rgba(222, 93, 93, 0.1);
    font-size: 12px;
    color: ${Colors.ERROR_COLOR};
    display: flex;
    align-items: center;
    margin: 12px 0;
    ${Fonts.GLOBAL_FONT_STYLE};
  }
`;
