import { Button, Stack, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleChart from "../../../../components/Charts/CircleChart";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";

interface Props {
  openModal: () => void;
}

export default function MissingGoal({ openModal }: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  return (
    <Stack height={"100%"} justifyContent={"space-between"} py={1}>
      <Stack>
        <Typography fontSize={12} color={"text.secondary"}>
          {t("newClientOverview.noGoalYet")}
        </Typography>
        <Button
          variant={"text"}
          onClick={openModal}
          color={"inherit"}
          sx={{ justifyContent: "flex-start", px: 1, py: 0.5, ml: -1 }}>
          <Typography color={"text.secondary"} fontWeight={600}>
            {t("newClientOverview.setWeightGoal")}
          </Typography>
          <ArrowForwardIosIcon
            sx={{ ml: 0.5, fontSize: "14px !important", color: "text.secondary" }}
          />
        </Button>
      </Stack>
      <Stack flexDirection={"row"} justifyContent={"center"}>
        <CircleChart
          prefixText={t("clientWeight.stats.goal")}
          progressText={t("help.week.none")}
          suffixText={"-"}
          progress={0}
          width={200}
        />
      </Stack>
    </Stack>
  );
}
