import i18next from "i18next";
import { GridColDef } from "@mui/x-data-grid-pro";
import { TranslatorNS } from "../../i18n/const";
import MuiInternalUrl from "../../components/Table/MuiInternalUrl";
import { IS_MOBILE } from "../../helpers/devices";
import WorkoutTemplateActions from "./components/Actions";
import _ from "lodash";

export const TABLE_WORKOUT_PLAN = [
  {
    label: "workouts.const.tableTitle.name",
    value: "name",
  },
  {
    label: "workouts.const.tableTitle.explanation",
    value: "explanation",
  },
  {
    label: "workouts.const.tableTitle.workoutsPerWeek",
    value: "workoutsPerWeek",
  },
  {
    label: "workouts.const.tableTitle.lastUpdated",
    value: "lastUpdated",
  },
  {
    label: "workouts.const.tableTitle.created",
    value: "created",
  },
  {
    label: "workouts.const.tableTitle.status",
    value: "status",
    renderCell: ({ status }: { status: string }) => (
      <span>
        {i18next.t(`statusOptions.${status}`, { ns: TranslatorNS.LISTS }) as string}
      </span>
    ),
  },
];
export const workoutTemplateCols = (): GridColDef[] => {
  const t = (key: string) => i18next.t(key, { ns: TranslatorNS.LISTS });
  const tShared = (key: string) => i18next.t(key, { ns: TranslatorNS.SHARED });
  return [
    {
      headerName: t("workouts.const.tableTitle.name"),
      field: "name",
      flex: 0.2,
      renderCell: ({ row }) => <MuiInternalUrl url={row.url} name={row.name} />,
    },
    {
      headerName: t("workouts.const.tableTitle.explanation"),
      field: "explanation",
      hide: IS_MOBILE,
      flex: 0.25,
    },
    {
      headerName: t("workouts.const.tableTitle.level"),
      field: "levelString",
      hide: IS_MOBILE,
      flex: 0.1,
    },
    {
      headerName: t("workouts.const.tableTitle.location"),
      field: "locationString",
      hide: IS_MOBILE,
      flex: 0.1,
    },
    {
      headerName: t("workouts.const.tableTitle.gender"),
      field: "genderString",
      hide: IS_MOBILE,
      flex: 0.1,
    },
    {
      headerName: t("workouts.const.tableTitle.workoutsPerWeek"),
      field: "workoutsPerWeek",
      hide: IS_MOBILE,
      flex: 0.1,
    },
    {
      headerName: _.capitalize(tShared("table.actions")),
      field: "actions",
      flex: 0.15,
      renderCell: ({ row }) => <WorkoutTemplateActions row={row} />,
    },
  ];
};

type KeyValPair = { [key: number]: string };
type KeyValPairNumber = { [key: number]: number };

export const LEVELS: KeyValPair = {
  1: "workouts.const.level.beginner",
  2: "workouts.const.level.intermediate",
  3: "workouts.const.level.advanced",
};

export const LOCATION: KeyValPair = {
  1: "workouts.const.location.gym",
  2: "workouts.const.location.home",
  3: "workouts.const.location.outdoor",
};

export const GENDER: KeyValPair = {
  1: "workouts.const.gender.female",
  2: "workouts.const.gender.male",
};
export const PERWEEK: KeyValPairNumber = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
};

export const NEW_WORKOUT = {
  active: true,
  headTitle: "workouts.const.newWorkout.headTitle",
  content: "workouts.const.newWorkout.content",
  type: "create",
  item: undefined,
};
export const EDIT_WORKOUT = {
  active: true,
  headTitle: "workouts.const.editWorkout.headTitle",
  content: "workouts.const.editWorkout.content",
  type: "edit",
};
export const DUPLICATE_WORKOUT = {
  active: true,
  headTitle: "workouts.const.duplicateWorkout.headTitle",
  content: "workouts.const.duplicateWorkout.content",
  type: "duplicate",
};

export const INITIAL_WORKOUT_TEMPLATE = {
  name: "",
  workoutsPerWeek: undefined,
  levels: undefined,
  location: undefined,
  gender: undefined,
  explanation: "",
};

export const INITIAL_VALIDATOR_EXERCISE = {
  name: "",
  workoutsPerWeek: "",
  level: "",
  location: "",
  gender: "",
  explanation: "",
};

export const GET_WORKOUT_LIMIT = 100;
