import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { GetClientGoalQuery } from "../../../../generated/graphql";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { getGoalType, GoalTypeEnum } from "../helpers/getGoalType";
import { roundToDecimals } from "../../../ClientProgress/bottom/helpers/utils";

interface Props {
  weightGoal: GetClientGoalQuery["client"]["weightGoal"];
  startWeight: GetClientGoalQuery["client"]["startWeight"];
  currentWeight: GetClientGoalQuery["client"]["weightGoal"];
  unit: string;
}

export default function Stats(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { palette } = useTheme();
  const { weightGoal, startWeight, currentWeight, unit } = props;
  const status = roundToDecimals(
    Math.abs(weightGoal && currentWeight ? weightGoal - currentWeight : 0),
    1,
  );
  const goalType = getGoalType(currentWeight, weightGoal);
  const toolTipString = getTooltipStatusString(goalType);
  const statusString = getStatusString(goalType, status, unit);

  return (
    <Stack>
      <Stack
        flexDirection={"row"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        justifyContent={{ xs: "flex-start", sm: "space-between" }}
        p={1}
        flex={1}>
        <Stack>
          <Typography sx={{ ...Styles.typography, color: palette.grey[600] }}>
            {t("clientWeight.stats.start")}
          </Typography>
          <Typography sx={{ ...Styles.stat, color: palette.grey[600], fontWeight }}>
            {startWeight} {unit}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={{ ...Styles.typography, color: palette.grey[600] }}>
            {t("clientWeight.stats.current")}
          </Typography>
          <Typography sx={{ ...Styles.stat, color: palette.grey[600], fontWeight }}>
            {currentWeight} {unit}
          </Typography>
        </Stack>
        <Stack>
          <Typography sx={Styles.typography}>{t("clientWeight.stats.goal")}</Typography>
          <Typography sx={{ ...Styles.stat, fontWeight }}>
            {weightGoal} {unit}
          </Typography>
        </Stack>
        <Tooltip title={t(toolTipString, { status, unit })} disableInteractive>
          <Stack sx={{ cursor: "help" }}>
            <Typography sx={{ ...Styles.typography, color: palette.primary.dark }}>
              {t("clientWeight.stats.status")}
            </Typography>
            <Typography sx={{ ...Styles.stat, color: palette.primary.dark, fontWeight }}>
              {t(statusString)}
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  );
}

const getTooltipStatusString = (goalType: GoalTypeEnum | undefined): string => {
  switch (goalType) {
    case GoalTypeEnum.Gain:
      return "newClientOverview.goalGain";
    case GoalTypeEnum.Lose:
      return "newClientOverview.goalLose";
    case GoalTypeEnum.Maintain:
      return "newClientOverview.goalMaintain";
    default:
      return "";
  }
};

const getStatusString = (
  goalType: GoalTypeEnum | undefined,
  status: number,
  unit: string,
): string => {
  switch (goalType) {
    case GoalTypeEnum.Gain:
      return `+${status} ${unit}`;
    case GoalTypeEnum.Lose:
      return `-${status} ${unit}`;
    case GoalTypeEnum.Maintain:
      return `0 ${unit}`;
    default:
      return "-";
  }
};

const fontWeight = 700;

const Styles = {
  typography: {
    cursor: "default",
    fontSize: 12,
    marginBottom: "2px",
  },
  stat: {
    cursor: "default",
    fontSize: 14,
  },
};
