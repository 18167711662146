import React, { Component } from "react";
import { connect } from "react-redux";
import * as chat from "../actions/chat-action";
import Messages from "../components/messages/messages";
import ChatHeader from "../components/ChatHeader";
import _ from "lodash";
import Pusher from "pusher-js";
import { styled } from "@mui/material/styles";
import { createGlobalStyle } from "styled-components";
import { Drawer } from "@mui/material";

const HideIntercom = createGlobalStyle`
    body {
      .intercom-lightweight-app {
        display: none !important;
      }
    }
  `;

const RefElement = ({ setRef }) => <div className="ref-element" ref={setRef}></div>;

class ChatWidgetContainer extends Component {
  constructor(props) {
    super(props);

    this.pusher = new Pusher("dcc6e72a783dd2f4b5e2", {
      appId: "1007100",
      cluster: "eu",
      encrypted: true,
    });
    this.contentInput = React.createRef();
    this.markMessagesAsRead = _.debounce(this.markMessagesAsRead.bind(this), 300);
    this.markUnreadConversation = _.debounce(this.markUnreadConversation.bind(this), 300);
    this.markConversationDone = _.debounce(this.markConversationDone.bind(this), 300);
  }

  componentDidMount() {
    this.subscribe();
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.props.toggleChatWidgetOpen(false);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = prevProps;
    const prevClientId = prevProps.clientId;
    const { clientId, clearMessages, messages } = this.props;
    const currentIsOpen = this.props.isOpen;

    // scroll to bottom after fetching new chat
    if (messages !== prevProps.messages) {
      const firstMessage = messages?.[messages.length - 1];
      const prevFirstMessage = prevProps.messages?.[prevProps.messages.length - 1];

      if (
        prevProps.startFrom === 0 ||
        firstMessage?.id !== prevFirstMessage?.id ||
        JSON.stringify(firstMessage) !== JSON.stringify(prevFirstMessage)
      ) {
        this.scrollToBottom();
      }
    }

    if (prevClientId !== clientId && currentIsOpen) {
      this.fetchNewMessages();
      this.unsubscribe();
      this.subscribe();
    } else if (isOpen !== currentIsOpen) {
      if (!isOpen) {
        this.fetchNewMessages();
      } else {
        clearMessages();
      }
    }
  }

  render() {
    const {
      messages,
      userId,
      clientName,
      clientId,
      isMessageLoading,
      startFrom,
      hasMore,
      updateMessages,
      isChatWidgetInitialized,
      isOpen,
      locale,
      toggleChatWidgetOpen,
      updateUnAnsweredMessageCount,
      resolveMarkAsReadTask,
    } = this.props;
    const messagesEnd = <RefElement setRef={this.setRef} />;

    return (
      <>
        {/* hide Intercom widget when chat widget is open */}
        {Boolean(isOpen) && <HideIntercom />}

        <Drawer
          open={isOpen}
          sx={{
            [`& .MuiDrawer-paper`]: {
              top: 64,
              width: { xs: "100%", sm: 512 },
              boxShadow: "0px 0px 20px 0px #00000010",
            },
          }}
          hideBackdrop
          variant={"persistent"}
          anchor={"right"}>
          <ChatWidgetWrapper initialized={isChatWidgetInitialized}>
            <ChatHeader
              clientId={clientId}
              isOpen={isOpen}
              clientName={clientName}
              onClick={() => toggleChatWidgetOpen(!isOpen)}
              markUnreadConversation={this.markUnreadConversation}
              markConversationDone={this.markConversationDone}
            />
            <Messages
              messages={messages}
              messagesEnd={messagesEnd}
              scrollToBottom={this.scrollToBottom}
              userId={userId}
              clientId={clientId}
              hasMore={hasMore}
              isMessageLoading={isMessageLoading}
              startFrom={startFrom}
              updateMessages={updateMessages}
              isOpen={isOpen}
              markMessagesAsRead={this.markMessagesAsRead}
              locale={locale}
              contentInput={this.contentInput}
              updateUnAnsweredMessageCount={updateUnAnsweredMessageCount}
              resolveMarkAsReadTask={resolveMarkAsReadTask}
            />
          </ChatWidgetWrapper>
        </Drawer>
      </>
    );
  }

  markUnreadConversation() {
    const { conversationId, markUnreadConversation } = this.props;
    markUnreadConversation(conversationId);
  }
  markConversationDone() {
    const { conversationId, markConversationDone } = this.props;
    markConversationDone(conversationId);
  }
  markMessagesAsRead() {
    const { unreadMessagesCount, markMessagesAsRead, clientId } = this.props;
    if (unreadMessagesCount) {
      markMessagesAsRead(clientId);
    }
  }

  fetchNewMessages() {
    const { fetchMessages, clientId } = this.props;

    fetchMessages(clientId).then(() => {
      this.markMessagesAsRead();
    });
  }

  subscribe = () => {
    if (!this.chatChannel && this.props.userId) {
      this.chatChannel = this.pusher.subscribe(`messages.trainer.${this.props.userId}`);
      this.chatChannel.bind("pusher:subscription_succeeded", () => {
        this.chatChannel.bind("message", this.onMessageReceive);
      });
    }
  };

  unsubscribe = () => {
    if (this.chatChannel) {
      this.chatChannel.unbind_all();
      this.chatChannel.unsubscribe();
    }
    this.chatChannel = null;
  };

  onMessageReceive = async data => {
    if (data.clientId && parseInt(data.clientId) !== parseInt(this.props.clientId)) {
      return;
    }

    let messages = [...this.props.messages];
    let unreadMessagesCount = this.props.unreadMessagesCount;
    const index = _.findIndex(messages, item => item.id === data.id);
    const isUnseen = data.unseen || false;

    if (index !== -1) {
      messages.splice(index, 1, data);
    } else if (isUnseen) {
      messages.push(data);
    }

    if (isUnseen && data.client === true) {
      unreadMessagesCount++;
    }

    await this.props.updateMessages(
      messages,
      this.props.hasMore,
      messages.length,
      unreadMessagesCount,
    );
  };

  setRef = elem => {
    this.refEl = elem;
  };

  scrollToBottom = () => {
    this.refEl.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
  };
}

const ChatWidgetWrapper = styled(({ initialized, ...props }) => <div {...props} />)(
  ({ initialized }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "#fafafb",
    boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.1)",
    zIndex: 1199,
    transition: "bottom 0.3s ease-out",
    height: "100%",

    ...(initialized
      ? {
          bottom: 0,
        }
      : {}),
  }),
);

function mapStateToProps(state) {
  return {
    messages: state.chatWidgetMessages.messages,
    hasMore: state.chatWidgetMessages.hasMore,
    startFrom: state.chatWidgetMessages.startFrom,
    userId: state.user.id,
    locale: state.chatWidget.locale,
    scrolled: state.chatWidgetMessages.scrolled,
    isMessageLoading: state.chatWidgetMessages.isMessageLoading,
    unreadMessagesCount: state.chatWidgetMessages.unreadMessagesCount,
    clientName: state.chatWidget.clientName,
    clientId: state.chatWidget.clientId,
    conversationId: state.chatWidget.conversationId,
    isChatWidgetInitialized: state.chatWidget.isChatWidgetInitialized,
    isOpen: state.chatWidgetMessages.isChatWidgetOpen,
  };
}

export default connect(mapStateToProps, { ...chat })(ChatWidgetContainer);
