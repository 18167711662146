import { useParams } from "react-router-dom";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import Iconify from "../../../../components/_MUI/Iconify";
import { connect } from "react-redux";
import { openGlobalModalAction } from "../../../../store/modals/action";
import { ModalPayload, ModalTypeEnum } from "../../../../store/modals/reducerTypes";

interface Props {
  openGlobalModalAction: (payload: ModalPayload<ModalTypeEnum.ClientInfo>) => void;
}

function ClientSettingsButton(props: Props) {
  const { clientId } = useParams();
  const { openGlobalModalAction } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  return (
    <Tooltip title={t("newClientOverview.generalCard.settings")}>
      <Button
        variant="text"
        size={"small"}
        onClick={() =>
          openGlobalModalAction({
            type: ModalTypeEnum.ClientInfo,
            context: { clientId: Number(clientId) },
          })
        }>
        {t("newClientOverview.generalCard.view")}
        <Iconify
          icon="material-symbols:arrow-circle-right-rounded"
          color={"inherit"}
          fontSize={24}
          ml={0.5}
        />
      </Button>
    </Tooltip>
  );
}

export default connect(undefined, { openGlobalModalAction })(ClientSettingsButton);
