import { SettingsValueProps } from "../components/_MUI/settings/type";

export const S3_CLIENT_PHOTO =
  "https://zenfit-images.s3.eu-central-1.amazonaws.com/before-after-images/client/photo/";
export const S3_BEFORE_AFTER_IMAGES =
  "https://zenfit-images.s3.eu-central-1.amazonaws.com/before-after-images/";
export const ERROR_MESSAGE =
  "Something went wrong. Please try again later or contact support";

export const YOUTUBE_API_KEY = "AIzaSyA19b673u3VuGUGMQRf03OuvIOaqJWIbzQ";

export enum ClientTiers {
  NONE = 0,
  STARTER = 20,
  GROWTH = 50,
  PROFESSIONEL = 200,
}

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 64,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 64 - 32,
};

export const defaultSettings: SettingsValueProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: "horizontal",
  themeColorPresets: "blue",
  themeStretch: false,
};
