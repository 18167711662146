import CardContainer from "../../../containers/CardContainer/CardContainer";
import { useParams } from "react-router-dom";
import {
  ClientPaymentConfigurationStatusEnum,
  CurrencyEnum,
  useGetClientSubscriptionQuery,
} from "../../../generated/graphql";
import SubscriptionEmpty from "./SubscriptionEmpty";
import Subscription from "./Subscription";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useState } from "react";
import { Collapse, DialogTitle, Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Payments from "./Payments";
import SubscriptionOptions from "./SubscriptionOptions";
import { DialogAnimate } from "../../../components/_MUI/animate";
import ModalSubscription from "../../Modals/modal-subscription";
import Dialog from "@mui/material/Dialog";
import Questionnaire from "../../../components/Modal/AddClientModal/Questionnaire";
import {
  AddClientStep,
  InvitationData,
} from "../../../components/Modal/AddClientModal/types";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { AppState } from "../../../store";
import { connect } from "react-redux";

export type UpcomingPaymentType = {
  date: string;
  amount: number;
  currency: CurrencyEnum;
};

type Props = {
  stripeConnected: boolean;
};

function SubscriptionContainer(props: Props) {
  const { stripeConnected } = props;
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [open, setOpen] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [invitationModalData, setInvitationModalData] = useState<InvitationData | null>(
    null,
  );
  const { data, loading, error, refetch } = useGetClientSubscriptionQuery({
    variables: {
      id: Number(clientId),
    },
  });

  const paymentConfiguration = data?.client?.paymentConfiguration || undefined;
  const payments = data?.client?.payments || undefined;

  const upcomingDate = paymentConfiguration?.subscription?.upcomingInvoiceDueDate;
  const upcomingAmount = paymentConfiguration?.recurringFee;
  const upcomingCurrency = paymentConfiguration?.currency;
  const isStopped =
    paymentConfiguration?.status === ClientPaymentConfigurationStatusEnum.Canceled ||
    paymentConfiguration?.status === ClientPaymentConfigurationStatusEnum.Paused;

  const upcomingPayment: UpcomingPaymentType | null =
    upcomingDate && upcomingAmount && upcomingCurrency && !isStopped
      ? {
          date: upcomingDate,
          amount: upcomingAmount,
          currency: upcomingCurrency,
        }
      : null;

  const handleClosePaymentModal = (
    goToNextStep: boolean,
    invitationData?: InvitationData,
  ) => {
    setShowPaymentModal(false);
    if (goToNextStep) {
      refetch();
      invitationData && setInvitationModalData(invitationData);
    }
  };

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [clientId]);

  const renderContent = () => {
    if (!data?.client?.isDemo && !stripeConnected) {
      return (
        <SubscriptionEmpty
          type={"missingStripe"}
          stripeConnectUrl={data?.client?.coach?.stripeConnectUrl || undefined}
        />
      );
    }

    if (!paymentConfiguration) {
      return (
        <SubscriptionEmpty
          type={"missingSubscription"}
          handlePaymentModal={() => setShowPaymentModal(true)}
        />
      );
    }

    return (
      <Stack>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={{ xs: "baseline", sm: "center" }}
          sx={{ cursor: "default" }}>
          <Stack flexGrow={1} onClick={() => setOpen(!open)}>
            <Subscription payment={paymentConfiguration} />
          </Stack>
          <SubscriptionOptions
            handlePaymentModal={() => setShowPaymentModal(true)}
            payment={paymentConfiguration}
          />
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Stack>
        <Collapse in={open}>
          <Payments payments={payments} upcomingPayment={upcomingPayment} />
        </Collapse>
      </Stack>
    );
  };

  return (
    <CardContainer
      error={error}
      loading={loading}
      sx={{ minHeight: 80, pb: 2 }}
      skeletonHeight={68}>
      {renderContent()}
      <DialogAnimate
        open={showPaymentModal}
        onClose={() => handleClosePaymentModal(false)}>
        <DialogTitle>
          {t("info.paymentInfo.setupSubscriptionClient", {
            client: data?.client?.name,
          })}
        </DialogTitle>
        <ModalSubscription onClose={handleClosePaymentModal} />
      </DialogAnimate>
      {!!invitationModalData && (
        <Dialog
          open={true}
          onClose={() => setInvitationModalData(null)}
          PaperProps={{
            sx: {
              width: "100%",
              maxWidth: "720px!important",
            },
          }}>
          <Questionnaire
            hiddenSteps={Object.keys(AddClientStep) as AddClientStep[]}
            invitationData={invitationModalData}
            closeClientModal={() => setInvitationModalData(null)}
            // Override prop from redux
            changeFilterProperty={() => {}}
          />
        </Dialog>
      )}
    </CardContainer>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    stripeConnected: state.user.stripeConnected,
  };
};

export default connect(mapStateToProps)(SubscriptionContainer);
