import { Navigate, useLocation } from "react-router-dom";
import { getToken, Tokens } from "../../api/tokens";
import { Routes } from "../../routes/routeMap";
import React from "react";

export const GuestGuard = (props: { children: JSX.Element }) => {
  let location = useLocation();
  const token = getToken(Tokens.BEARER_TOKEN);
  if (token) {
    return <Navigate to={Routes.DEFAULT_ROUTE} state={{ from: location }} replace />;
  }

  return <>{props.children}</>;
};
