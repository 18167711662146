import createRequestTypes from "../../../helpers/createReduxActionTypes";

export const SAVE_SETTING = "SAVE_SETTING";
export const CHANGE_SAVE_STATUS = "CHANGE_SAVE_STATUS";
export const VALIDATE_VAT = createRequestTypes("VALIDATE_VAT");
export const GET_SETTINGS = createRequestTypes("GET_SETTINGS");

export type PasswordChange = {
  password: string;
  password1: string;
  password2: string;
};
