import React from "react";
import ZFRecorder from "./components/VideoRecorder";
import ActionButton from "./components/modules/ActionButton";
import "./Recorder_styles.scss";
import { connect } from "react-redux";
import { AppState } from "../../../../store";
import DraggableContainer from "../../../../components/Draggable/DraggableContainer";
import { Portal } from "@mui/material";

interface Props {
  renderActionButton: (toggleFunc: () => any, hiddenInput: any) => any;
  isMobile: boolean;
  selectVideo: (blob: Blob) => void;
  isWidget?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  isSending: boolean;
}

export default function ZFVideoRecorder(props: Props) {
  const {
    renderActionButton,
    isMobile,
    isWidget,
    selectVideo,
    isOpen,
    onClose,
    onOpen,
    isSending = false,
  } = props;

  return (
    <>
      <ActionButton
        isSending={isSending}
        isMobile={isMobile}
        handleModal={onOpen}
        handleVideo={selectVideo}
        isWidget={isWidget}
        renderActionButton={renderActionButton}
      />

      {isOpen && (
        <Portal>
          <DraggableContainer>
            <ZFRecorder
              onClose={onClose}
              isMobile={isMobile}
              handleVideoRecorded={selectVideo}
            />
          </DraggableContainer>
        </Portal>
      )}
    </>
  );
}
