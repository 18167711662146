import createRequestTypes from "../../helpers/createReduxActionTypes";
import { AppStatus } from "./reducer";
import { Subscription } from "../../interfaces/subscription";
import { Locale } from "../../interfaces/locale";
import { CurrencyEnum } from "../../interfaces/currency";
import { UserApp } from "../../interfaces/userApp";
import { LocaleEnum, TransferProgressEnum } from "../../generated/graphql";

export const CHANGE_USER_LANGUAGE = "CHANGE_USER_LANGUAGE";
export const GET_USER = createRequestTypes("GET_USER");
export const UPDATE_MESSAGE_COUNT = "UPDATE_MESSAGE_COUNT";
export const UPDATE_USER_TAGS = "UPDATE_USER_TAGS";

interface getBaseUserAction {
  type: typeof GET_USER.SUCCESS;
  payload: IUser;
}

interface getChangeUserLangAction {
  type: typeof CHANGE_USER_LANGUAGE;
  payload: string;
}

interface updateMessageCountAction {
  type: typeof UPDATE_MESSAGE_COUNT;
  payload: {
    unreadMessagesCount: number;
  };
}
interface updateUserTagsAction {
  type: typeof UPDATE_USER_TAGS;
  payload: {
    tags?: string[];
  };
}

export interface UserState extends IUser {
  loading: boolean;
  appStatus: AppStatus;
  loadedAt: Date | null;
  stripeConnected: boolean;
  stripeConnectUrl: string | null;
  userApplicationFee: number | null;
  useLenusRecipes: any;
}

export interface UserSettings {
  defaultCheckInDay: number | null;
  defaultCurrency: CurrencyEnum | null;
  defaultUpfront: number | null;
  defaultRecurring: number | null;
}

export enum PlatformOriginEnum {
  Lenus = "lenus",
  Zenfit = "zenfit",
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  company: string;
  logo: string;
  primaryColor: string;
  currency: CurrencyEnum;
  totalActiveClients: number;
  unreadMessagesCount: number;
  newLeadsCount: number;
  interactiveToken: string;
  tags: any;
  locale: LocaleEnum;
  isAssistant: boolean;
  activated: boolean;
  configured: boolean;
  version: 0 | 1 | -1; // VersionEnum
  vatNumber?: string;
  hasValidVat: boolean;
  subscription: Subscription;
  userApp?: UserApp;
  settings: UserSettings;
  platformOrigin: PlatformOriginEnum;
  payment: {
    nextPaymentAttempt: number | null;
    lastPaymentFailed: boolean;
  };
  lenusTransferDate: { date: string } | null;
  transferInProgress: TransferProgressEnum;
}

export type userActionTypes =
  | getBaseUserAction
  | getChangeUserLangAction
  | updateUserTagsAction
  | updateMessageCountAction;
