import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Tabs, Tab, styled, IconButton, Box, IconButtonProps } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { WorkoutPlanDay } from "../types";
import { TranslatorKeyPair } from "../../../i18n/const";
import { useTranslation } from "react-i18next";
import Iconify from "../../../components/_MUI/Iconify";
import { useHasHorizontalOverflow } from "../../../hooks/useHasOverflow";

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  tabs: WorkoutPlanDay[];
  initialTab: number;
  setActiveTab: (tabId: number) => void;
  activeAction: (tabId: number) => void;
  onDragEnd: ({ id, newIndex }: { id: number; newIndex: number }) => void;
  openAddDayDialog: () => void;
}

export default function DraggableTabs(props: Props) {
  const { t } = useTranslation();

  const [tabs, setTabs] = useState(props.tabs);
  const [tab, setTab] = useState(props.initialTab);
  const [target, setTarget] = React.useState<HTMLDivElement | null>(null);

  const { atStart, atEnd, isOverflowingHorizontal } = useHasHorizontalOverflow(target);
  useEffect(() => {
    setTabs(props.tabs);
    return () => {};
  }, [props.tabs]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newTabs = reorder(tabs, result.source.index, result.destination.index);
    setTabs(newTabs);
    props.onDragEnd?.({
      id: Number(result.draggableId),
      newIndex: result.destination.index,
    });
  };

  const handleChange = (tab: number) => {
    setTab(tab);
    props.setActiveTab(tab);
  };

  const handleScroll = (scrollOffset: number) => {
    if (target) {
      target.scrollLeft += scrollOffset;
    }
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  const tabIds = tabs.map((tab: any) => tab.id);
  const currentIndex = tabIds.indexOf(tab);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {provided => (
          <>
            <Stack direction="row" alignItems="center" sx={{ pr: 1 }}>
              {isOverflowingHorizontal && (
                <CustomIconButton
                  size="small"
                  color="primary"
                  disabled={atStart}
                  onClick={() => handleScroll(-80)}>
                  <Iconify icon="material-symbols:arrow-back-rounded" />
                </CustomIconButton>
              )}
            </Stack>
            <Wrapper
              ref={el => {
                provided.innerRef(el);
                setTarget(el);
              }}
              style={{
                display: "flex",
                background: "transparent",
                overflowX: "scroll",
                width: "100%",
                height: 48,
              }}
              {...provided.droppableProps}>
              <div style={{ display: "flex" }}>
                <CustomTabs value={currentIndex}>
                  {tabs.map((item, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={index}
                      disableInteractiveElementBlocking>
                      {provided => {
                        const id = item.id;
                        return (
                          <CustomTab
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            value={currentIndex}
                            key={id}
                            onClick={() => handleChange(id)}
                            label={
                              <Stack direction="row" alignItems="center" id={"workout-day-tab"}>
                                {item.name}
                                <Tooltip title={t("board.editDay", { ns: TranslatorKeyPair.plans })}>
                                  <IconButton
                                    sx={{
                                      visibility: tab === id ? "visible" : "hidden",
                                    }}
                                    size="small"
                                    onClick={() => props.activeAction(item.id)}>
                                    <Iconify
                                      icon="ri:edit-line"
                                      color="grey.600"
                                      sx={{ fontSize: 18 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            }
                            id={`tab-${currentIndex}`}
                          />
                        );
                      }}
                    </Draggable>
                  ))}
                  <span style={{ opacity: 0, zIndex: -1, position: "relative" }}>
                    {provided.placeholder}
                  </span>
                </CustomTabs>
              </div>
            </Wrapper>
            <Stack direction="row" alignItems="center" sx={{ pl: 1 }}>
              {isOverflowingHorizontal && (
                <CustomIconButton
                  size="small"
                  disabled={atEnd}
                  onClick={() => handleScroll(80)}>
                  <Iconify icon="material-symbols:arrow-forward-rounded" />
                </CustomIconButton>
              )}
              <Box sx={{ alignSelf: "center" }}>
                <Tooltip title={t("board.addDay", { ns: TranslatorKeyPair.plans })}>
                  <IconButton onClick={props.openAddDayDialog}>
                    <Iconify icon="material-symbols:add-circle-outline-rounded" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Stack>
          </>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const CustomTab = styled(Tab)(() => ({
  marginRight: 16,
  ":not(:last-of-type)": {
    marginRight: 16,
  },
}));

const CustomTabs = styled(Tabs)(() => ({
  overflow: "inherit !important",
  "& .MuiTabs-scroller": {
    overflow: "inherit !important",
  },
}));

const Wrapper = styled("div")(() => ({
  scrollBehavior: "smooth",
  "::-webkit-scrollbar": {
    display: "none",
  },
}));

const CustomIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => {
  return {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  };
});
