import Draggable, { DraggableData } from "react-draggable"; // The default
import { Card } from "@mui/material";
import Box from "@mui/material/Box";
import { useRef, useState, useMemo } from "react";

/**
 * Note that the recorder for the chat and the chatwidget will have different starting points
 * as to where it is rendered into the DOM (could consider modifying this later)
 *
 * TODO: if this dragcontianer will be used other places extract the default and consts else make this dedicated to the video recorder
 *
 */
const startingPointX = 500;
const startingPointY = -450;

const dragBoxStyle = {
  zIndex: 10000,
  margin: "1rem",
  position: "absolute",
  cursor: "move",
};

export default function DraggableContainer({
  children,
}: {
  children: React.ReactElement;
}) {
  const ref = useRef(null);

  const [initialBounds, setInitialsBounds] = useState<{
    left: number;
    right: number;
    top: number;
    bottom: number;
  } | null>(null);

  /**
   * Get boundaries onDrag (shortcuit to only get it on initial drag)
   */
  const handleStartDrag = (_: Event, data: DraggableData) => {
    if (!initialBounds) {
      const position = data.node.getBoundingClientRect();
      setInitialsBounds({
        left: position.left,
        right: position.right,
        top: position.top,
        bottom: position.bottom,
      });
    }
  };

  const defaultPosition = { x: startingPointX, y: startingPointY };
  return (
    <Draggable
      // @ts-ignore
      onStart={handleStartDrag}
      nodeRef={ref}
      defaultPosition={defaultPosition}
      bounds={"parent"}>
      <Box ref={ref} sx={dragBoxStyle}>
        <Card>{children}</Card>
      </Box>
    </Draggable>
  );
}
