import { styled, TextField } from "@mui/material";

export default styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& input": {
      fontWeight: 700,
    },
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
      fieldset: {
        borderColor: "transparent",
      },
    },
    "&.Mui-focused fieldset": {
      borderWidth: 1,
      borderColor: theme.palette.grey[400],
    },
  },
}));
