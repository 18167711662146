import { CREATE_VIDEOS, DELETE_VIDEOS, ERROR_STATE, GET_VIDEOS } from "./types";

const INITIAL_STATE = {
  listUpdate: false,
  error: false,
  empty: null,
  isLoading: true,
};

export default function Reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_VIDEOS.SUCCESS: {
      return {
        ...state,
        videos: payload,
        error: false,
        empty: payload?.length === 0,
        isLoading: false,
      };
    }
    case CREATE_VIDEOS.SUCCESS: {
      return { ...state, listUpdate: payload, error: false };
    }
    case DELETE_VIDEOS.SUCCESS: {
      return { ...state, listUpdate: payload, error: false };
    }
    case ERROR_STATE: {
      return { ...state, error: payload };
    }
    default:
      return state;
  }
}
