import { ClientType } from "../../../../generated/graphql";

export enum GoalTypeEnum {
  Lose = "lose",
  Maintain = "maintain",
  Gain = "gain",
}

export const getGoalType = (
  startWeight: ClientType["startWeight"],
  weightGoal: ClientType["weightGoal"],
): GoalTypeEnum | undefined => {
  if (!startWeight || !weightGoal) return undefined;

  if (Number(startWeight) > Number(weightGoal)) return GoalTypeEnum.Lose;

  if (Number(startWeight) < Number(weightGoal)) return GoalTypeEnum.Gain;

  return GoalTypeEnum.Maintain;
};
