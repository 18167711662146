import React from "react";
import IngredientsList from "./Ingredients/IngredientsList";
import DialogProvider from "./Dialog/DialogProvider";
import { Stack, Box } from "@mui/material";
import EditorContainer from "./Editor";
import EditRecipeDialog from "./Dialog/EditRecipeDialog";
import Editors from "../Editors";
import { PlanId } from "../Editors/types";
import VersionContainer from "../../containers/VersionContainer";
import { VersionEnum } from "../../generated/graphql";
import { RootState } from "../../store";
import { connect } from "react-redux";

function RecipeEditor({ isUsingLenusMacroSplit }: { isUsingLenusMacroSplit: boolean }) {
  /**
   * If the user does not use Lenus recipes, we use the old editor.
   * as the Zenfit Macrosplit is not supported by the graphQL API.
   */
  if (!isUsingLenusMacroSplit) {
    return <Editors type={PlanId.RECIPE} />;
  }
  return (
    <>
      <VersionContainer showOn={[VersionEnum.Experimental]}>
        <DialogProvider>
          <>
            {/* HACK: style to reset page padding */}
            <Stack direction="row" sx={{ mr: -2, mt: -3 }}>
              {/* make sure width bounds to 300 */}
              <Box sx={{ minWidth: 300, maxWidth: 300, mt: 2 }}>
                <IngredientsList />
              </Box>
              <Box sx={{ mr: 2 }} />
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  overflowX: "scroll",
                }}>
                <EditorContainer />
              </Box>
            </Stack>
            <EditRecipeDialog />
          </>
        </DialogProvider>
      </VersionContainer>
      <VersionContainer showOn={[VersionEnum.Stable, VersionEnum.Legacy]}>
        <Editors type={PlanId.RECIPE} />
      </VersionContainer>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    isUsingLenusMacroSplit: state.user.useLenusRecipes,
  };
}

export default connect(mapStateToProps)(RecipeEditor);
