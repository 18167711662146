import * as React from "react";
import { Stack, Typography } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

interface Props {
  title: string;
  subTitle: string;
  kcal: number;
}

export default function ListItem({ title, subTitle, kcal }: Props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack direction="row" width="100%">
      <Stack alignSelf={"center"} width="100%">
        <Tooltip title={title}> 
          <Typography 
            variant="subtitle2"
            fontSize={12}
            sx={{
              width: "100%",
              textOverflow: "ellipsis",
              whiteSpace: "noWrap",
              overflow: "hidden",
              display: "inline-block",
            }}>
              {title}
          </Typography>
        </Tooltip>
        {kcal ? (
          <Stack direction="row" justifyContent="space-between" width="100%">
            <Tooltip title={subTitle}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 10,
                  color: theme.palette.text.secondary,
                  width: "70%",
                  textOverflow: "ellipsis",
                  whiteSpace: "noWrap",
                  overflow: "hidden",
                  display: "inline-block",
                }}>
                {subTitle}
              </Typography>
            </Tooltip>
            <Typography
              variant="body2"
              sx={{
                flexGrow: 1,
                fontSize: 10,
                color: theme.palette.text.secondary,
                textAlign: "right",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
                overflow: "hidden",
                display: "inline-block",
              }}>
              {kcal} {t("meal.kcals", { ns: TranslatorNS.MEAL_PLAN })} / 100g
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
}
