import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { AppState } from "../../store";
import { connect } from "react-redux";
import {
  CLEAR_NOTIFICATION_MESSAGE,
  NotificationTypeEnum,
} from "../../store/notification/reducerTypes";
import { useSnackbar } from "notistack";

type Props = {
  type?: NotificationTypeEnum;
  message: string;
  clearNotificationDispatch: () => void;
  open: boolean;
};

const NotificationBar = (props: Props) => {
  const { type, message, open, clearNotificationDispatch } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const CLEAR_TIMER = 4000;

  const handleClose = (event?: React.SyntheticEvent<any> | null, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    clearNotificationDispatch();
  };

  useEffect(() => {
    if (open) {
      enqueueSnackbar(message, {
        variant: type,
        preventDuplicate: false,
        autoHideDuration: CLEAR_TIMER,
        onClose: handleClose,
      });
    }
    return () => {};
  }, [open]);

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    type: state.notification.type,
    message: state.notification.message,
    open: state.notification.open,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearNotificationDispatch: () => dispatch({ type: CLEAR_NOTIFICATION_MESSAGE }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
