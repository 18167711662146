import { combineReducers, Reducer } from "redux";
import clientProgress from "./clientProgress/reducer";
import clientImages from "./clientImages/reducer";
import { ClientTopProgressState } from "./clientProgress/types";
import { ClientTopImagesState } from "./clientImages/types";

export const top = combineReducers({
  // @ts-ignore
  clientProgress,
  // @ts-ignore
  clientImages,
}) as Reducer<{
  clientProgress: ClientTopProgressState;
  clientImages: ClientTopImagesState;
}>;

export type ClientProgressState = { top: ReturnType<typeof top> };
