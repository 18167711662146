import React, { Fragment } from "react";
import {
  ClientType,
  useUpdateClientGoalMutation,
} from "../../../../../generated/graphql";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, DialogContent, Stack, TextField } from "@mui/material";
import FormProvider from "../../../../../components/_MUI/hook-form/FormProvider";
import RHFGraphqlError from "../../../../../components/_MUI/hook-form/RHFGraphqlError";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import { RHFTextField } from "../../../../../components/_MUI/hook-form";
import EstimatedCalories from "./EstimatedCalories";
import { getGoalType, GoalTypeEnum } from "../../helpers/getGoalType";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import { useParams } from "react-router-dom";
import { setCurrentClient } from "../../../../../store/currentClient/action";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { yupClientGoal } from "./yupClientGoal";

type TClientGoalForm = {
  weightGoal: ClientType["weightGoal"];
  dailySteps: number | undefined | null;
};

type TComputeProps = {
  pal: ClientType["pal"];
  gender: ClientType["gender"];
  height: ClientType["height"];
  age: ClientType["age"];
  measuringSystem: ClientType["measuringSystem"];
  startWeight: ClientType["startWeight"];
};

interface Props {
  name: ClientType["name"];
  defaultValues: TClientGoalForm;
  computeCalsProperties: TComputeProps;
  onClose: () => void;
  /*TODO: Remove this dispatch when all pages are migrated to GRAPHQL*/
  setCurrentClientDispatch: ({ clientId }: { clientId: number }) => void;
}

function ClientGoalForm(props: Props) {
  const { clientId } = useParams<{ clientId: string }>();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { enqueueSnackbar } = useSnackbar();

  const {
    defaultValues,
    onClose,
    computeCalsProperties,
    name,
    setCurrentClientDispatch,
  } = props;

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yupClientGoal),
    mode: "onChange",
  });

  const [updateClientGoal, { loading, error }] = useUpdateClientGoalMutation();

  const {
    reset,
    handleSubmit,
    watch,
    formState: { isDirty },
  } = methods;

  const computedProperties = {
    pal: computeCalsProperties.pal,
    gender: computeCalsProperties.gender,
    height: computeCalsProperties.height,
    age: computeCalsProperties.age,
    measuringSystem: computeCalsProperties.measuringSystem,
    startWeight: computeCalsProperties.startWeight,
    weightGoal: Number(watch("weightGoal")),
  };

  const goalType = getGoalType(computedProperties.startWeight, watch("weightGoal"));
  const goalTypeString = goalType ? getGoalTypeString(goalType) : "-";

  const submitSettings = async (data: TClientGoalForm, event: any) => {
    event.preventDefault();
    try {
      await updateClientGoal({
        variables: {
          id: Number(clientId),
          input: {
            goalWeight: Number(data?.weightGoal),
            dailySteps: data?.dailySteps ? Number(data?.dailySteps) : 0,
          },
        },
      });

      reset(data);
      setCurrentClientDispatch({ clientId: Number(clientId) });
      onClose();
      enqueueSnackbar(_.capitalize(t("info.saveStatus.saved")), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(_.capitalize(t("info.saveStatus.error")), {
        variant: "error",
        persist: false,
        preventDuplicate: true,
      });
    }
  };

  return (
    <Fragment>
      <DialogTitle>{t("newClientOverview.settingsFor", { name })}</DialogTitle>
      <DialogContent>
        <Stack height={"90%"} mt={2}>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit((_, event) => event?.preventDefault())}>
            <Stack>
              <Typography variant={"subtitle2"} mb={2}>
                {t("newClientOverview.mainGoal")}
              </Typography>
              <RHFTextField type={"number"} name="weightGoal" label={t("newClientOverview.goalWeight")} />
              <TextField
                disabled
                value={t(goalTypeString)}
                label={t("newClientOverview.goalType")}
                sx={{ mb: 2 }}
              />
              <EstimatedCalories computeProperties={computedProperties} />
            </Stack>

            <Stack mt={4}>
              <Typography variant={"subtitle2"} mb={2}>
              {t("newClientOverview.dailyGoals")}
              </Typography>
              <RHFTextField
                name={"dailySteps"}
                type={"number"}
                label={t("newClientOverview.walk")}
                InputProps={{
                  endAdornment: <Typography sx={{ opacity: 0.5 }}>{t("newClientOverview.stepsAdornment")}</Typography>,
                }}
              />
            </Stack>
          </FormProvider>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: "1px solid rgba(145, 158, 171, 0.24)", p: "16px !important" }}>
        <Stack width={"100%"}>
          <RHFGraphqlError error={error} />
          <Stack flexDirection={"row"} justifyContent={"flex-end"}>
            <Button
              size={"medium"}
              sx={{ mr: 2 }}
              onClick={onClose}
              variant={"outlined"}
              color={"inherit"}>
              {t("addClient.closeClient")}
            </Button>
            <LoadingButton
              size={"medium"}
              variant={"contained"}
              onClick={handleSubmit(submitSettings)}
              disabled={!isDirty}
              loading={loading}>
              {t("info.saveStatus.save")}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Fragment>
  );
}

const mapDispatchToProps = {
  setCurrentClientDispatch: setCurrentClient,
};
export default connect(undefined, mapDispatchToProps)(ClientGoalForm);

const getGoalTypeString = (goalType: GoalTypeEnum) => {
  switch (goalType) {
    case GoalTypeEnum.Gain:
      return "const.goalType.gainWeight";
    case GoalTypeEnum.Lose:
      return "const.goalType.loseWeight";
    case GoalTypeEnum.Maintain:
      return "const.goalType.maintainWeight";
    default:
      return "";
  }
};
