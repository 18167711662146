import React from "react";
import { Colors } from "../../styleguide";
import { ZFLoaderStyled } from "./ZFLoaderStyled";
import { CircularProgress } from "@material-ui/core";

type ZFLoaderProps = {
  color?: string;
  size?: number;
  value?: number;
  thickness?: number;
  style?: object;
  centerAlign?: boolean;
};

const ZFLoader = (props: ZFLoaderProps) => {
  const {
    color = Colors.CTA_COLORS_BG,
    size = 100,
    value,
    style,
    thickness = 1,
    centerAlign = true,
  } = props;
  return (
    <ZFLoaderStyled
      style={style}
      color={color}
      className={`ZFLoader ${centerAlign ? "centerAlign" : ""}`}>
      <CircularProgress
        thickness={thickness}
        variant={value ? "determinate" : "indeterminate"}
        value={value}
        style={{ color: color, width: size, height: size }}
      />
    </ZFLoaderStyled>
  );
};

export default ZFLoader;
