import { ClientPaymentConfigurationStatusEnum } from "../../../../generated/graphql";
import { useTheme } from "@mui/material/styles";

export const useGetPaymentStatusColorTitle = (
  status: ClientPaymentConfigurationStatusEnum | undefined,
): { color: string; statusTitle: string; tooltipString?: string } => {
  const { palette } = useTheme();

  switch (status) {
    case ClientPaymentConfigurationStatusEnum.Active:
      return {
        color: palette.success.dark,
        statusTitle: "info.paymentInfo.status.active",
      };
    case ClientPaymentConfigurationStatusEnum.Paused:
      return {
        color: palette.common.black,
        statusTitle: "info.paymentInfo.status.paused",
        tooltipString: "info.paymentInfo.status.pausedUntil",
      };
    case ClientPaymentConfigurationStatusEnum.Canceled:
      return {
        color: palette.warning.main,
        statusTitle: "info.paymentInfo.status.canceled",
        tooltipString: "info.paymentInfo.status.canceledAt",
      };
    case ClientPaymentConfigurationStatusEnum.Pending:
      return {
        color: palette.grey[500],
        statusTitle: "info.paymentInfo.status.pending",
      };
    case ClientPaymentConfigurationStatusEnum.Failed:
      return {
        color: palette.error.main,
        statusTitle: "info.paymentInfo.status.failed",
      };
    case ClientPaymentConfigurationStatusEnum.WillStart:
      return {
        color: palette.success.main,
        statusTitle: "info.paymentInfo.status.willStart",
      };
    default:
      return {
        color: palette.grey[600],
        statusTitle: "-",
      };
  }
};
