import { Stack, Typography } from "@mui/material";
import NavigationButtons from "../../../../providers/LenusTransferProvider/components/NavigationButtons";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  handleNext: () => void;
}

export default function LoginSuccess(props: Props) {
  const { handleNext } = props;
  const { t } = useTranslation(TranslatorNS.AUTH);
  return (
    <>
      <Stack maxWidth={"50%"}>
        <Typography color={"white"} textAlign={"center"} fontSize={"32px"}>
          {t("transfer.loginSuccess")}
        </Typography>
        <Typography color={"white"} textAlign={"center"} fontSize={"32px"}>
          {t("transfer.clickNext")}
        </Typography>
      </Stack>
      <NavigationButtons
        buttonTxt={t("transfer.next")}
        goNextTxt={`${t("transfer.next")}: ${t("transfer.nextCreateEmail")}`}
        goNext={handleNext}
      />
    </>
  );
}
