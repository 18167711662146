/*
 * TODO: move to redux store handling modal
 */

import { IWorkoutTemplateModalState } from "./reducer";

export const MODAL_STATE_WORKOUT_TEMPLATE = "MODAL_STATE_WORKOUT_TEMPLATE";

export const handleWorkoutTemplateModalAction = (payload: IWorkoutTemplateModalState) => {
  return (dispatch: any) => {
    dispatch({ type: MODAL_STATE_WORKOUT_TEMPLATE, payload });
  };
};
