import "react-tippy/dist/tippy.css";
import React, { Component, createRef, Fragment } from "react";
import SendField from "./send-field";
import { GET_SIGNED_UPLOAD_URL, MESSAGE_SEND } from "../../../../api/private/chat";
import axios from "../../../../helpers/axios";
import MessageList from "./MessageList";
import ModalChatTemplates from "../../../Modals/modal-chat-templates";
import { DEFAULT_MESSAGE_TYPES } from "../../constants";
import { withTranslation } from "react-i18next";
import UploadVideoLoaderSnackbar from "../../../../components/Loader/UploadVideoLoaderSnackbar";
import RetryUploadSnackbar from "../../../../components/Error/RetryUploadSnackbar";
import { Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { compose } from "redux";
import { handleActivateClientChatAction } from "../../../Clients/store/clients/actions";
import { track } from "../../../../helpers/analytics";
import ActivateClientContainer from "./ActivateClientContainer";

class Messages extends Component {
  scrolled = false;
  scrolledToBottom = true;

  constructor(props) {
    super(props);

    this.chat = createRef();
    this.state = {
      hasMore: props.hasMore,
      isInfiniteLoading: false,
      isSending: false,
      messageType: -1,
      showAction: false,
      actionResponse: null,
      activeClient: null,
      fileProgress: 0,
      // Little hack to replay upload action if it fails
      replayUploadAction: null,
    };

    this.handleSend = this.handleSend.bind(this);
    this.openChatTemplate = this.openChatTemplate.bind(this);
  }

  componentDidMount() {
    this.props.scrollToBottom();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.scrolled = nextProps.scrolled;
    if (this.state.messages !== nextProps.messages) {
      this.setState({
        messages: nextProps.messages,
        hasMore: nextProps.hasMore,
      });
    }

    if (this.state.activeClient !== nextProps.clientId) {
      if (nextProps.messageType && nextProps.messageType.action) {
        this.setState({ showAction: true });
      } else {
        this.setState({ showAction: false });
      }
      this.state.actionResponse && this.setState({ actionResponse: null });
    }
  }
  openChatTemplate(value) {
    this.setState({
      messageType: value,
    });
  }
  render() {
    const { isInfiniteLoading, isSending, showAction, actionResponse } = this.state;
    const {
      messagesEnd,
      hasMore,
      startFrom,
      messages,
      clientId,
      updateMessages,
      isMessageLoading,
      markMessagesAsRead,
      locale,
      messageType,
      contentInput,
      subscription,
      filterCounts,
      resolveMarkAsReadTask,
    } = this.props;
    const currentActive = filterCounts.all;
    const disableActivateCondition =
      subscription.clients !== 0 && !(currentActive < subscription.clients);

    return (
      <Fragment>
        <UploadVideoLoaderSnackbar
          isOpen={isSending}
          progress={this.state.fileProgress}
        />
        <RetryUploadSnackbar
          isOpen={Boolean(this.state.replayUploadAction)}
          retryAction={() => {
            const replayUploadActionFn = this.state.replayUploadAction;
            this.setState({ replayUploadAction: null }, () => replayUploadActionFn());
          }}
        />
        <ViewWrapper>
          {clientId && <ActivateClientContainer clientId={clientId} />}
          <MessageList
            messages={messages}
            isInfiniteLoading={isInfiniteLoading}
            messagesEnd={messagesEnd}
            hasMore={hasMore}
            startFrom={startFrom}
            clientId={clientId}
            updateMessages={updateMessages}
            isMessageLoading={isMessageLoading}
            markMessagesAsRead={markMessagesAsRead}
          />
          <SendField
            isSending={isSending}
            sendMessage={this.handleSend}
            clientId={clientId}
            isOpen={this.props.isOpen}
            locale={locale}
            contentInput={contentInput}
            messageType={messageType}
            resolveMarkAsReadTask={resolveMarkAsReadTask}
          />
          <ModalChatTemplates
            clientId={clientId}
            inputRef={this.contentInput}
            defaultMessageType={this.state.messageType}
            handleMessageType={this.openChatTemplate}
          />
        </ViewWrapper>
      </Fragment>
    );
  }

  handleChatScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    this.scrolledToBottom = scrollHeight - (clientHeight + scrollTop) <= 0;
  };

  async handleSendSuccessUpdate(receivedMessages) {
    const { updateMessages, clientId, hasMore } = this.props;

    if (receivedMessages?.[0]?.clientId === clientId) {
      await updateMessages(receivedMessages, hasMore, receivedMessages.length);
    }
  }

  async handleSend(msg, video, voice) {
    const { userId, clientId, updateUnAnsweredMessageCount } = this.props;
    const { isSending } = this.state;

    try {
      if (isSending) {
        return;
      }
      const data = new FormData();

      this.setState({ isSending: true });

      if (video) {
        const videoUrl = await this.uploadVideo(video);
        if (!videoUrl) return false;
        data.append("media", videoUrl);
      }
      if (voice) {
        const voiceUrl = await this.uploadVideo(voice);
        if (!voiceUrl) return false;
        data.append("media", voiceUrl);
      }
      data.append("msg", msg);
      data.append("clientId", clientId);
      data.append("trainer", userId);

      const res = await axios.post(MESSAGE_SEND(), data);

      const receivedMessages = res.data.messages;
      await this.handleSendSuccessUpdate(receivedMessages);
      await updateUnAnsweredMessageCount(clientId);
    } catch (error) {
      console.error(error);
      this.setState({ replayUploadAction: () => this.handleSend(msg, video, voice) });
    } finally {
      this.setState({ isSending: false, fileProgress: 0 });
    }
  }

  async uploadVideo(video) {
    const [contentType] = video.type.split(";");
    let [, extension = "webm"] = contentType.split("/");
    const {
      data: { url, filename: key },
    } = await axios.get(GET_SIGNED_UPLOAD_URL(extension, contentType));
    await axios.put(url, video, {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
      onUploadProgress: progressEvent => {
        this.setState({
          fileProgress: (progressEvent.loaded / progressEvent.total) * 100,
        });
      },
    });

    return key;
  }
}

const ViewWrapper = styled("div")(() => ({
  height: "calc(100% - 141px)",
  display: "flex",
  flexFlow: "column",
  position: "relative",
}));

/*TODO: cleanup to mui*/
const ActionContainer = styled("div")(() => ({
  background: "#ebf3fb",
  padding: "5px 10px",
  width: "95%",
  margin: "5px auto",
}));

const ActionTitle = styled("p")(() => ({
  color: "#1ba1fb",
  fontSize: "14px",
  fontWeight: 900,
  margin: "0px",
}));

const ActionContent = styled("p")(() => ({
  color: "#1ba1fb",
  fontSize: "12px",
  marginTop: "4px",
  marginBottom: "10px",
}));

const mapStateToProps = state => {
  return {
    subscription: state.user.subscription,
    filterCounts: state.clients.filterCounts,
  };
};

export default compose(
  withTranslation("chatWidget", { withRef: true }),
  connect(mapStateToProps, { handleActivateClientChatAction }),
)(Messages);
