import { useFormContext, Controller } from "react-hook-form";
import { getUserTagsArrayToReactSelect } from "../../../helpers/utils";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";
import { MenuPlacement, MultiValue, StylesConfig } from "react-select";
import palette, { PRIMARY } from "../../../theme/palette";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { FormControl, FormHelperText } from "@mui/material";

type IProps = {
  name: string;
  label?: string;
  tagsList: string[];
  placement?: MenuPlacement;
  maxMenuHeight?: number;
};

type Props = IProps;

function RHFTags({
  name,
  label,
  tagsList,
  placement = "top",
  maxMenuHeight = 138,
  ...other
}: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const tagSelect = (newValues: MultiValue<any>) => {
          let searchValue = [];
          if (newValues !== null) {
            searchValue = newValues.reduce((accumulator, currentValue) => {
              accumulator.push(currentValue.value);
              return accumulator;
            }, []);
          }
          onChange(searchValue);
        };

        const options = getUserTagsArrayToReactSelect(
          tagsList.filter(e => !value?.includes(e)),
        );

        return (
          <FormControl fullWidth error={!!error}>
            <CreatableSelect
              isMulti
              value={getUserTagsArrayToReactSelect(value)}
              options={options}
              menuPlacement={placement}
              noOptionsMessage={() => t("tagFilter.noOptions")}
              placeholder={label || t("tagFilter.placeholder")}
              onChange={tagSelect}
              onInputChange={inputVal => inputVal.toUpperCase()}
              formatCreateLabel={inputValue =>
                t("tagFilter.createInput", { input: inputValue })
              }
              styles={customStyles}
              maxMenuHeight={maxMenuHeight}
              theme={theme => ({
                ...theme,
                borderRadius: 9,
              })}
            />
            {!!error && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}

function mapStateToProps(state: AppState) {
  return {
    tagsList: state.user.tags,
  };
}

export default connect(mapStateToProps)(RHFTags);

const customStyles: StylesConfig<any> = {
  control: provided => ({
    ...provided,
    color: palette.light.text.primary,
    fontSize: "1rem",
    padding: "9px 4px 9px 6px",
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: PRIMARY.main,
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: "#fff",
    paddingLeft: 8,
  }),
  multiValueRemove: provided => ({
    ...provided,
    cursor: "pointer",
    color: "#fff",
  }),
  // Remove indicator separator styles
  indicatorSeparator: () => ({}),
};
