import { useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";

import { TranslatorNS } from "../../../../i18n/const";

export interface TemplateFilterMenuItemProps {
  tabLabel: string;
  tabIcon: JSX.Element;
  checkboxSelectedItems?: number[];
  radioSelectedItem?: number | null;
  options: {
    [key: number]: string | number;
  };
  onItemClick: (itemKey: number) => void;
}

const TemplateFilterMenuItem = ({
  tabLabel,
  tabIcon,
  options,
  checkboxSelectedItems = [],
  radioSelectedItem,
  onItemClick,
}: TemplateFilterMenuItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { t } = useTranslation(TranslatorNS.LISTS);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = !!anchorEl;
  const numberOfSelectedItems =
    Number(!!radioSelectedItem) || (checkboxSelectedItems || []).length;

  return (
    <>
      <Button
        aria-controls={isMenuOpen ? "filter-workout-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        onClick={openMenu}
        sx={{ color: "black" }}
        startIcon={tabIcon}>
        {t(tabLabel)}
        <Box sx={{ width: 24 }}>
          {!!numberOfSelectedItems && `(${numberOfSelectedItems})`}
        </Box>
      </Button>
      <Menu
        id="filter-workout-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}>
        {Object.entries(options).map(([optionValue, optionLabel]) => (
          <MenuItem key={optionValue} sx={{ p: 0 }}>
            <FormControlLabel
              sx={{ m: 0, pr: 2, width: "100%" }}
              control={
                Object.keys(options).length === 2 ? (
                  <Radio
                    onClick={() => onItemClick(Number(optionValue))}
                    checked={radioSelectedItem === Number(optionValue)}
                  />
                ) : (
                  <Checkbox
                    onClick={() => onItemClick(Number(optionValue))}
                    checked={checkboxSelectedItems.includes(Number(optionValue))}
                  />
                )
              }
              label={t(`${optionLabel}`)}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TemplateFilterMenuItem;
