import { MeasuringSystemEnum } from "../generated/graphql";

export enum MeasuringSystemUnitEnum {
  KG = "kg",
  LBS = "lbs",
}

export const getUnit = (
  measureType?: MeasuringSystemEnum | null,
): MeasuringSystemUnitEnum =>
  measureType === MeasuringSystemEnum.Imperial
    ? MeasuringSystemUnitEnum.LBS
    : MeasuringSystemUnitEnum.KG;
