import "./vanilla/index.scss";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Client, MealPlan, PlanId, RecipePlan, WorkoutPlan } from "./types";
import styled from "styled-components";
import { connect } from "react-redux";
import TwigModals from "./components/editors";
import initMeal from "./init/initMeal";
import initWorkout from "./init/initWorkout";
import initRecipe from "./init/initRecipe";
import { AppState } from "../../store";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchMealPlanTemplate,
  fetchRecipeTemplate,
  fetchWorkoutPlanTemplate,
  resetStore,
} from "./store/actions";
import { RequestState } from "../../interfaces/requestState";
import { FixStyled } from "./FixStyled";
import HeaderBreadcrumbs from "../../components/_MUI/HeaderBreadcrumbs";
import { Routes, RoutesTitle } from "../routeMap";
import { prepareRoute } from "../../helpers/routing";
import Page from "../../components/_MUI/Page";
import { Typography } from "@mui/material";

interface Props {
  type: string;
  mealPlan?: MealPlan;
  planName: string;
  workoutPlan?: WorkoutPlan;
  meal?: {};
  recipePlan?: RecipePlan;
  fetchMealPlanTemplate: (templateId: number) => void;
  fetchWorkoutPlanTemplate: (templateId: number) => void;
  fetchRecipeTemplate: (templateId: number) => void;
  resetStore: () => void;
  mealPlanLoaded: boolean;
  workoutPlanLoaded: boolean;
  recipePlanLoaded: boolean;
  isLoading: boolean;
  clientId: number;
  clientName: string;
  clientFirstname: string;
}

function Main(props: Props) {
  const { templateId } = useParams();
  const { t } = useTranslation("app");
  const {
    type,
    clientId,
    clientName,
    clientFirstname,
    mealPlan,
    workoutPlan,
    meal,
    recipePlan,
    isLoading,
  } = props;
  const [editorType, setEditorType] = useState<string>("");
  const [backLinkText, setBackLinkText] = useState<string>("");
  const [prevRoute, setPrevRoute] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");

  // TODO: useCallback should be rewritten correctly, when refactoring code delete eslint-disable…
  const fetchPlanData = useCallback(() => {
    if (templateId && type === PlanId.MEAL) {
      props.fetchMealPlanTemplate(Number(templateId));
    }
    if (templateId && type === PlanId.WORKOUT) {
      props.fetchWorkoutPlanTemplate(Number(templateId));
    }
    if (templateId && type === PlanId.RECIPE) {
      props.fetchRecipeTemplate(Number(templateId));
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, type]);

  useEffect(() => {
    fetchPlanData();
  }, [fetchPlanData]);

  useEffect(() => {
    return () => props.resetStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetStore]);

  const client = { id: clientId, name: clientName, firstname: clientFirstname };
  // TODO: useEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useEffect(() => {
    switch (type) {
      case PlanId.MEAL:
        return props.mealPlanLoaded ? initMeal(mealPlan, meal, client) : undefined;
      case PlanId.WORKOUT:
        return props.workoutPlanLoaded
          ? workoutPlan && initWorkout(workoutPlan, client)
          : undefined;
      case PlanId.RECIPE:
        return props.recipePlanLoaded ? initRecipe(recipePlan) : undefined;
      default:
        return console.error("no valid id was provided");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // using name to update hook but lets split of the editor to make it a proper way of managing this
    props.planName,
    props.mealPlanLoaded,
    props.workoutPlanLoaded,
    props.recipePlanLoaded,
    type,
  ]);

  // TODO: useLayoutEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useLayoutEffect(() => {
    if (type === PlanId.MEAL) {
      setEditorType(t("navigation.mealPlan"));
      !client?.id && setPageTitle(RoutesTitle.MEALS_TEMPLATES);
    } else if (workoutPlan && type === PlanId.WORKOUT) {
      setEditorType(t("navigation.workoutPlan"));
      !client?.id && setPageTitle(RoutesTitle.WORKOUTS_TEMPLATES);
    } else if (recipePlan && type === PlanId.RECIPE) {
      setEditorType(t("navigation.recipes"));
      !client?.id && setPageTitle(RoutesTitle.MEALS_RECIPES_EDITOR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // TODO: useLayoutEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useLayoutEffect(() => {
    if (!isLoading) {
      /*TODO: MAKE SWITCH*/
      if (mealPlan && type === PlanId.MEAL) {
        setBackLinkText(t("navigation.mealPlan"));
        if (client?.id) {
          setPrevRoute(
            prepareRoute(Routes.CLIENT_MEAL_PLAN, { templateId, clientId: client.id }),
          );
        } else {
          setPrevRoute(Routes.MEALS_TEMPLATES);
        }
      } else if (workoutPlan && type === PlanId.WORKOUT) {
        setBackLinkText(t("navigation.workoutPlan"));
        if (client?.id) {
          setPrevRoute(
            prepareRoute(Routes.CLIENT_WORKOUT_PLAN, { templateId, clientId: client.id }),
          );
        } else {
          setPrevRoute(Routes.WORKOUTS_TEMPLATES);
        }
      } else if (recipePlan && type === PlanId.RECIPE) {
        setBackLinkText(t("navigation.recipes"));
        setPrevRoute(Routes.MEALS_RECIPES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /*The exercise wrapper will inner will be replaced with the planner*/
  return (
    <Page title={clientName || pageTitle}>
      <Typography variant="h4">
        {editorType}
      </Typography>
      {/* <HeaderBreadcrumbs
        heading={editorType}
        links={[{ name: backLinkText, href: prevRoute }, { name: props.planName }]}
      /> */}
      <FixStyled className="editWrapper">
        <Styled className={`exercise-wrapper ${client ? "" : "no-client-nav"}`} id={type}>
          <div className="spinner">
            <img src={"/ajax-loader.gif"} alt={"loading"} />
          </div>
        </Styled>
        <TwigModals refetchFn={fetchPlanData} />
      </FixStyled>
    </Page>
  );
}

function mapStateToProps(state: AppState, ownProps: { type: PlanId }) {
  return {
    type: ownProps.type,
    clientId: state.currentClient.id,
    clientName: state.currentClient.name,
    clientFirstname: state.currentClient.firstname,
    mealPlan: state.plan.mealPlan,
    planName: getPlanName(state, ownProps.type),
    meal: state.plan.meal,
    workoutPlan: state.plan.workoutPlan,
    recipePlan: state.plan.recipePlan,
    mealPlanLoaded: state.plan.mealPlanRequestState === RequestState.RESOLVED,
    workoutPlanLoaded: state.plan.workoutPlanRequestState === RequestState.RESOLVED,
    recipePlanLoaded: state.plan.recipePlanRequestState === RequestState.RESOLVED,
    isLoading: state.plan.isLoading,
  };
}

function getPlanName(state: AppState, type: PlanId): string {
  switch (type) {
    case PlanId.MEAL:
      // TODO: I think there is a wrong type for this when can this be undefined? (forcing string)
      return state.plan.mealPlan?.name as string;
    case PlanId.RECIPE:
      return state.plan.recipePlan?.name;
    case PlanId.WORKOUT:
      return state.plan.workoutPlan?.name;
    default:
      /**
       * Failing softly/silenty
       */
      console.warn(`No editor type set for = ${type}`);
      return "";
  }
}

const mapDispatchToProps = {
  fetchMealPlanTemplate,
  fetchWorkoutPlanTemplate,
  fetchRecipeTemplate,
  resetStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

const Styled = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  .spinner {
    align-self: center;
  }
  border: 1px solid #eee;
  margin-top: 16px;
`;
