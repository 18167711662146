import styled from "styled-components";
import { AddExerciseToWorkoutDayInputType } from "../../generated/graphql";
import { WorkoutItemType, WorkoutPlan, WorkoutPlanDay } from "./types";

export const Item = styled.div`
  display: flex;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #fff;
  padding: 8px;
  cursor: move;
  border-radius: 8px;
  border: 1px solid lightgray;
  &.sortable-ghost {
    background-color: #c8ebfb;
  }
`;
// transform to pass test
export function transformGraphqlToClientState(requestData: any): WorkoutPlanDay[] {
  const workoutPlan = requestData?.workoutPlan;
  const workoutDays = workoutPlan?.days?.map((day: any) => {
    // ignoring sections for now
    const { exercises } = day;

    const formattedExercises = exercises.map((superset: any) => {
      if (superset?.children?.length > 0) {
        return {
          ...superset,
          title: superset?.exercise?.title,
          video: superset?.exercise?.video,
          image: superset?.exercise?.image,
          isLenusExercise: superset?.exercise?.isLenusExercise,
          comment: superset.description,
          type: WorkoutItemType.SUPER_SET,
          exerciseType: superset?.exercise?.type,
          dayId: day.id,
          children: [
            ...superset.children.map((child: any) => ({
              ...child,
              title: child?.exercise?.title,
              video: child?.exercise?.video,
              image: child?.exercise?.image,
              comment: child.description,
              exerciseType: child?.exercise?.type,
            })),
          ],
        };
      }
      return {
        ...superset,
        title: superset?.exercise?.title,
        video: superset?.exercise?.video,
        image: superset?.exercise?.image,
        isLenusExercise: superset?.exercise?.isLenusExercise,
        comment: superset.description,
        exerciseType: superset?.exercise?.type,
        dayId: day.id,
      };
    });
    const combinedArray = formattedExercises;
    return {
      id: day.id,
      name: day.name,
      description: day.description || "",
      order: day.order,

      workouts: combinedArray.sort((a: any, b: any) => a?.order - b?.order),
    };
  });

  return workoutDays?.sort((a: WorkoutPlanDay, b: WorkoutPlanDay) => a.order - b.order);
}

export function transformWorkoutItemToSuperSet(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
) {
  return workoutPlan.map(item => {
    if (item.id === workoutId) {
      return {
        ...item,
        type: WorkoutItemType.SUPER_SET,
        children: [],
      };
    }
    if (item?.children) {
      return {
        ...item,
        children: item.children?.map(c => {
          if (c.id === workoutId) {
            return {
              ...c,
              type: WorkoutItemType.SUPER_SET,
              children: [],
            };
          }
          return c;
        }),
      };
    }
    return item;
  });
}

export function transformSuperSetToWorkoutItems(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
) {
  const items: WorkoutPlan[] = [];
  workoutPlan.forEach(item => {
    /**
     * This is for shallow checking of super set e.g.
     * if the super set is in root level to be transformed back
     */
    if (item.id === workoutId && item.type === WorkoutItemType.SUPER_SET) {
      item.children?.forEach(c =>
        items.push({ ...c, type: WorkoutItemType.EXERCISE, parentId: item.parentId }),
      );
      return;
    }
    /**
     * This is for deep checking of super set:
     * If the super set is inside a section
     */
    if (item?.children) {
      const childToMerge = item.children.find(c => c.id === workoutId);
      const childToMergeIndex = item.children.findIndex(c => c.id === workoutId);

      if (childToMerge) {
        const formattedChildren = childToMerge?.children?.map(c => ({
          ...c,
          type: WorkoutItemType.EXERCISE,
          parentId: item.id,
        }));
        // remove current index
        item.children.splice(childToMergeIndex, 1);
        // insert at current index
        insert(item.children, childToMergeIndex, formattedChildren);
      }
    }
    items.push(item);
  });

  return items;
}

/**
 * update comment in a workout item
 * @param workoutPlan
 * @param workoutId
 * @param text
 * @returns
 */
export function updateWorkoutItemComment(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
  text: string,
) {
  return mapWorkoutPlanRecursive(workoutPlan, workoutId, item => ({
    ...item,
    comment: text,
  }));
}

/**
 * update reps in a workout item
 * @param workoutPlan
 * @param workoutId
 * @param sets
 * @returns
 */
export function updateWorkoutItemSets(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
  sets: string,
) {
  return mapWorkoutPlanRecursive(workoutPlan, workoutId, item => ({
    ...item,
    sets,
  }));
}
/**
 * update reps in a workout item
 * @param workoutPlan
 * @param workoutId
 * @param reps
 * @returns
 */
export function updateWorkoutItemReps(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
  reps: string,
) {
  return mapWorkoutPlanRecursive(workoutPlan, workoutId, item => ({
    ...item,
    reps,
  }));
}
/**
 * update time in a workout item
 * @param workoutPlan
 * @param workoutId
 * @param time
 * @returns
 */
export function updateWorkoutItemTime(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
  time: string,
) {
  return mapWorkoutPlanRecursive(workoutPlan, workoutId, item => ({
    ...item,
    time,
  }));
}
/**
 * update reps in a workout item
 * @param workoutPlan
 * @param workoutId
 * @param reps
 * @returns
 */
export function updateWorkoutItemRest(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
  rest: string,
) {
  return mapWorkoutPlanRecursive(workoutPlan, workoutId, item => ({
    ...item,
    rest,
  }));
}

/**
 * Delete an item from the workout plan
 * @param workoutPlan
 * @param workoutId
 * @returns
 */
export function deleteWorkoutItem(workoutPlan: WorkoutPlan[], workoutId: number) {
  return filterWorkoutPlanRecursion(workoutPlan, workoutId);
}

// ==================== HELPERS ====================

/**
 * Inserts an item into an array at a given index
 * @param array
 * @param index
 * @param items
 * @returns
 */
function insert(array: any[], index: number, items: any) {
  return array.splice(index, 0, ...items);
}

/**
 * Recursion for removing an item in a tree
 * @param workoutPlan
 * @param workoutId
 * @returns
 */
export function filterWorkoutPlanRecursion(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
): WorkoutPlan[] {
  return workoutPlan
    .map(item => {
      if (item.id === workoutId) {
        return null;
      }
      if (item?.children) {
        return {
          ...item,
          children: filterWorkoutPlanRecursion(item.children, workoutId).filter(Boolean),
        };
      }
      return item;
    })
    .filter(Boolean) as WorkoutPlan[];
}

/**
 * Recursion for updating an item in a tree
 * @param workoutPlan
 * @param workoutId
 * @param callback
 * @returns WorkoutPlan[]
 */
function mapWorkoutPlanRecursive(
  workoutPlan: WorkoutPlan[],
  workoutId: number,
  callback: (item: WorkoutPlan) => WorkoutPlan,
): WorkoutPlan[] {
  return workoutPlan.map(item => {
    if (item.id === workoutId) {
      return callback(item);
    }
    if (item?.children) {
      return {
        ...item,
        children: mapWorkoutPlanRecursive(item.children, workoutId, callback),
      };
    }
    return item;
  });
}

export function getWorkoutDefaultConfig(workoutType: number) {
  let defaultConfig: Partial<AddExerciseToWorkoutDayInputType> = {
    sets: "3",
    rest: "60",
  };
  // REPS type
  if (workoutType === 1) {
    defaultConfig = {
      ...defaultConfig,
      reps: "12",
    };
  }
  if (workoutType === 2) {
    defaultConfig = {
      ...defaultConfig,
      time: "60",
    };
  }

  return defaultConfig;
}
