import { ClientType } from "../generated/graphql";

export const getCustomQuestions = (customQuestions: ClientType["customQuestions"]) =>
  (customQuestions || []).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...(typeof currentValue?.question === "string" && {
        [String(currentValue.id)]: currentValue?.answer,
      }),
    }),
    {},
  );

export const getCustomQuestionsById = (
  customQuestions?: ClientType["customQuestions"],
) => {
  if (!customQuestions) return null;

  return (customQuestions || []).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      ...(typeof currentValue?.question === "string" && {
        [String(currentValue.id)]: {
          answer: currentValue?.answer,
          question: currentValue?.question,
        },
      }),
    }),
    {},
  );
};
