import { Fragment } from "react";
import { Button, Dialog, Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";
import ClientGoalForm from "./ClientGoalForm";
import { ClientHabitEnum, GetClientGoalQuery } from "../../../../../generated/graphql";
import { getCurrentAge } from "../../../../../helpers/dates";
import Iconify from "../../../../../components/_MUI/Iconify";

interface Props {
  client?: GetClientGoalQuery["client"];
  openModal: boolean;
  handleModal: (open: boolean) => void;
}

export default function EditGoalModalContainer(props: Props) {
  const { openModal, handleModal, client } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  if (!client) return null;

  const defaultValues = {
    startWeight: client.startWeight,
    weightGoal: client?.weightGoal ? Number(client?.weightGoal) : null,
    dailySteps: client?.habits?.find(h => h.type === ClientHabitEnum.Steps)?.goal || null,
  };

  const computeCalsProperties = {
    measuringSystem: client.measuringSystem,
    startWeight: client.startWeight,
    age: client?.birthday ? getCurrentAge(client?.birthday) : client?.age,
    gender: client.gender,
    height: client.height,
    pal: client?.pal,
  };

  return (
    <Fragment>
      <Tooltip title={t("newClientOverview.editGoal")}>
        <Stack>
          <Button variant="text" size={"small"} onClick={() => handleModal(true)}>
            {t("newClientOverview.edit")}
            <Iconify icon="mdi:edit-circle" fontSize={24} ml={0.5} />
          </Button>
        </Stack>
      </Tooltip>
      <Dialog open={openModal} onClose={() => handleModal(false)}>
        <ClientGoalForm
          name={client?.name}
          defaultValues={defaultValues}
          computeCalsProperties={computeCalsProperties}
          onClose={() => handleModal(false)}
        />
      </Dialog>
    </Fragment>
  );
}
