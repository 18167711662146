import React from "react";
import { Stack, Button } from "@mui/material";
import { Tooltip } from "@material-ui/core";
import WorkoutTitle from "./WorkoutTitle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DialogContext, WorkoutPlanModalType } from "../Dialog/DialogProvider";
import { TranslatorNS } from "../../../i18n/const";
import { Box } from "@mui/system";
import axios from "axios";
import { EXPORT_WORKOUT_PLAN_AS_PDF } from "../../../api/private/workout-api";
import { gql, useQuery } from "@apollo/client";
import Iconify from "../../../components/_MUI/Iconify";

const WORKOUT_PLAN_QUERY = gql`
  query GetWorkoutPlanTemplate($id: Int!) {
    workoutPlan(id: $id) {
      id
      name
      description
    }
  }
`;

export default function HeaderContainer() {
  const { templateId } = useParams<{ templateId: string }>();
  const { data, loading, error } = useQuery(WORKOUT_PLAN_QUERY, {
    variables: { id: Number(templateId) },
  });

  if (loading || error) return null;
  const plan = {
    id: Number(data.workoutPlan.id),
    name: data.workoutPlan.name,
    description: data.workoutPlan.description,
  } as Props;
  return <Header plan={plan} />;
}

interface Props {
  id: number;
  name: string;
  description: string;
}

function Header({ plan }: { plan: Props }) {
  const location = useLocation();
  // using this to figure if we are not on the workout plan template page
  // as then we don't want to display save as template option
  const isClientWorkoutPlan = !location.pathname.includes("templates");
  const { t } = useTranslation([TranslatorNS.PLANS]);
  const { setModal } = React.useContext(DialogContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const openTemplateDialog = () => setModal({ type: WorkoutPlanModalType.TEMPLATE });

  const openSaveAsTemplateDialog = () =>
    setModal({ type: WorkoutPlanModalType.SAVE_AS_TEMPLATE, context: { id: plan.id } });

  const openModifyWorkoutPlanDescriptionDialog = () =>
    setModal({
      type: WorkoutPlanModalType.MODIFY_WORKOUT_PLAN_INFO,
      context: { id: plan.id, name: plan.name, description: plan.description || "" },
    });

  const handleWorkoutPdf = async () => {
    try {
      enqueueSnackbar("Loading...", { variant: "info" });
      await axios.post(EXPORT_WORKOUT_PLAN_AS_PDF(plan.id));
      enqueueSnackbar(
        `${t("index.yourPdfIsBeingGenerated")}
        ${t("index.onceFinishedItWillBeSent")}`,
        {
          variant: "success",
        },
      );
      handleClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("board.saveAsPDFError", { variant: "error" }));
    }
  };
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ p: 2 }}>
      <Stack id={"workout-plan-title"}>
        <Tooltip title={t("board.editPlan")}>
          <WorkoutTitle
            value={plan.name}
            endIcon={
              <Iconify icon="ri:edit-line" color="grey.600" sx={{ fontSize: 40 }} />
            }
            fullWidth
            size="small"
            placeholder={t("board.workoutPlanNamePlaceholder")}
            onClick={openModifyWorkoutPlanDescriptionDialog}>
            {plan.name || t("board.workoutPlanNamePlaceholder")}
          </WorkoutTitle>
        </Tooltip>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box>
          <Button
            variant="contained"
            fullWidth
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}>
            {t("board.more")}
          </Button>
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {isClientWorkoutPlan && (
          <MenuItem onClick={openSaveAsTemplateDialog} disableRipple>
            {t("board.saveAsTemplate")}
          </MenuItem>
        )}
        <MenuItem onClick={openTemplateDialog} disableRipple>
          {t("board.applyTemplate")}
        </MenuItem>
        <MenuItem onClick={handleWorkoutPdf} disableRipple>
          {t("board.saveAsPDF")}
        </MenuItem>
      </Menu>
    </Stack>
  );
}
