const GetbrowserNameVersion = () => {
  var ua = navigator.userAgent;
  var tem;
  var M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
};

const size = {
  mobileScreen: "1024px",
  laptopScreen: "1024px",
};

export const DEVICE = {
  mobile: `@media (max-width: ${size.mobileScreen})`,
  desktop: `@media (min-width: ${size.mobileScreen})`,
};

export const IS_MOBILE = /Mobi|Android/i.test(navigator.userAgent);
export const BROWSER_VERSION = GetbrowserNameVersion().split(" ")[1];
export const BROWSER_NAME = GetbrowserNameVersion().split(" ")[0];
export const SAFARI_MIN_SUPPORTED_VERSION = 13;
