import { Dictionary } from "lodash";
import { RequestState } from "../../../../../interfaces/requestState";

export const CLIENT_IMAGES_REQUEST = "CLIENT_IMAGES_REQUEST";
export const CLIENT_IMAGES_SUCCESS = "CLIENT_IMAGES_SUCCESS";
export const CLIENT_IMAGES_FAILURE = "CLIENT_IMAGES_FAILURE";

export const CLIENT_IMAGES_LOAD_MORE_SUCCESS = "CLIENT_IMAGES_LOAD_MORE_SUCCESS";

export const CLIENT_IMAGES_ADD_IMAGE_REQUEST = "CLIENT_IMAGES_ADD_IMAGE_REQUEST";
export const CLIENT_IMAGES_ADD_IMAGE_SUCCESS = "CLIENT_IMAGES_ADD_IMAGE_SUCCESS";
export const CLIENT_IMAGES_ADD_IMAGE_FAILURE = "CLIENT_IMAGES_ADD_IMAGE_FAILURE";

export const CLIENT_IMAGES_DELETE_IMAGE_REQUEST = "CLIENT_IMAGES_DELETE_IMAGE_REQUEST";
export const CLIENT_IMAGES_DELETE_IMAGE_SUCCESS = "CLIENT_IMAGES_DELETE_IMAGE_SUCCESS";
export const CLIENT_IMAGES_DELETE_IMAGE_FAILURE = "CLIENT_IMAGES_DELETE_IMAGE_FAILURE";

export const CLIENT_IMAGES_RESET = "CLIENT_IMAGES_RESET";

export interface Image {
  id: number;
  name: string;
  date: string;
  deleted: boolean;
  // maybe enum? :-O
  type: number;
  shortDate: string;
}

export interface ClientTopImagesState {
  clientId: number | null;
  list: Image[];
  map: Dictionary<Image>;
  error: any;
  fetchStatus: RequestState;
  addStatus: RequestState;
  deleteStatus: RequestState;
  page: number;
  allLoaded: boolean;
  initialLoading: boolean;
}

interface ClientImagesRequest {
  type: typeof CLIENT_IMAGES_REQUEST;
}
interface ClientImagesSuccess {
  type: typeof CLIENT_IMAGES_SUCCESS;
  payload: { clientId: number; imageList: Image[] };
}
interface ClientImagesFailure {
  type: typeof CLIENT_IMAGES_FAILURE;
  payload: any;
}

interface ClientImagesLoadMoreSuccess {
  type: typeof CLIENT_IMAGES_LOAD_MORE_SUCCESS;
  payload: { imageList: Image[] };
}

interface ClientImagesAddImageRequest {
  type: typeof CLIENT_IMAGES_ADD_IMAGE_REQUEST;
}
interface ClientImagesAddImageSuccess {
  type: typeof CLIENT_IMAGES_ADD_IMAGE_SUCCESS;
  payload: Image;
}
interface ClientImagesAddImageFailure {
  type: typeof CLIENT_IMAGES_ADD_IMAGE_FAILURE;
  payload: any;
}
interface ClientImagesDeleteImageRequest {
  type: typeof CLIENT_IMAGES_DELETE_IMAGE_REQUEST;
  payload: string[];
}
interface ClientImagesDeleteImageSuccess {
  type: typeof CLIENT_IMAGES_DELETE_IMAGE_SUCCESS;
  payload: { id: number };
}
interface ClientImagesDeleteImageFailure {
  type: typeof CLIENT_IMAGES_DELETE_IMAGE_FAILURE;
  payload: any;
}

interface ClientImagesReset {
  type: typeof CLIENT_IMAGES_RESET;
}

export type ClientImagesActionTypes =
  | ClientImagesRequest
  | ClientImagesSuccess
  | ClientImagesFailure
  | ClientImagesLoadMoreSuccess
  | ClientImagesAddImageRequest
  | ClientImagesAddImageSuccess
  | ClientImagesAddImageFailure
  | ClientImagesDeleteImageRequest
  | ClientImagesDeleteImageSuccess
  | ClientImagesDeleteImageFailure
  | ClientImagesReset;
