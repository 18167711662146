import {
  CREATE_DOCUMENT_PIC_URL,
  CREATE_DOCUMENTS,
  DELETE_DOCUMENTS,
  GET_DOCUMENTS,
  PUT_DOCUMENT_TO_CLIENT,
  RESET_DOCUMENTS,
  TAGS_BY_USER,
} from "./types";
import { InitialModalShow } from "../../../helpers/listHelper";
import { MODAL_STATE } from "../../../helpers/const";

const INITIAL_STATE = {
  tagsByUser: [],
  listUpdate: false,
  loading: true,
  image: undefined,
  error: false,
  empty: null,
  modal: InitialModalShow,
  isGeneral: true,
  createLoading: false,
};

export default function Reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case TAGS_BY_USER: {
      return { ...state, tagsByUser: payload };
    }
    case GET_DOCUMENTS.REQUEST: {
      return { ...state, isGeneral: payload };
    }
    case GET_DOCUMENTS.SUCCESS: {
      return {
        ...state,
        documents: payload,
        empty: payload?.length === 0,
        loading: false,
      };
    }
    case CREATE_DOCUMENTS.REQUEST: {
      return { ...state, error: "", createLoading: true };
    }
    case CREATE_DOCUMENTS.SUCCESS: {
      return { ...state, listUpdate: payload, createLoading: false };
    }
    case CREATE_DOCUMENTS.FAILURE: {
      return { ...state, loading: false, error: payload };
    }
    case CREATE_DOCUMENT_PIC_URL.REQUEST: {
      return { ...state, imageLoading: true, error: "" };
    }
    case CREATE_DOCUMENT_PIC_URL.SUCCESS: {
      return { ...state, image: payload, imageLoading: false };
    }
    case CREATE_DOCUMENT_PIC_URL.FAILURE: {
      return { ...state, imageLoading: false, error: payload };
    }
    case DELETE_DOCUMENTS.SUCCESS: {
      return { ...state, listUpdate: payload };
    }
    case PUT_DOCUMENT_TO_CLIENT.SUCCESS: {
      return { ...state, listUpdate: payload, loading: false, createLoading: false };
    }
    case PUT_DOCUMENT_TO_CLIENT.FAILURE: {
      return { ...state, error: payload, loading: false };
    }
    case MODAL_STATE: {
      return { ...state, modal: payload };
    }
    case RESET_DOCUMENTS: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
}
