import {
  ClientType,
  CurrencyEnum,
  LocaleEnum,
  useGetClientModalSubscriptionQuery,
} from "../../../../generated/graphql";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import FormFields from "./FormFields";
import { addDays } from "date-fns";
import Dialog from "@mui/material/Dialog";
import SkeletonContainer from "../../../../containers/SkeletonContainer";

interface Props {
  clientId: ClientType["id"];
  onClose: () => void;
}

export default function CreateSubscriptionContainer(props: Props) {
  const { clientId, onClose } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { data, loading } = useGetClientModalSubscriptionQuery({
    variables: {
      clientId,
    },
  });

  const client = data?.client;

  const clientInvitationInfo = {
    clientId,
    locale: client?.locale || LocaleEnum.EnUs,
    email: client?.email || "",
    name: client?.name || "",
  };

  const defaultValues = {
    clientId,
    locale: client?.locale || LocaleEnum.EnUs,
    currency: client?.paymentConfiguration?.currency || CurrencyEnum.Eur,
    months: client?.paymentConfiguration?.months || 0,
    upfrontFee: client?.paymentConfiguration?.upfrontFee || undefined,
    recurringFee: client?.paymentConfiguration?.recurringFee || undefined,
    startPaymentDate: addDays(new Date(), 2),
    terms: undefined,
    includeTerms: false,
    customStartPaymentDate: false,
    chargeUpfrontImmediately: false,
  };

  return (
    <Dialog maxWidth={"xs"} open={Boolean(clientId)} onClose={onClose}>
      <DialogTitle>
        <SkeletonContainer height={25} loading={loading} sx={{ mb: 0.5 }}>
          {t("info.paymentInfo.setupSubscriptionClient", {
            client: data?.client?.name,
          })}
        </SkeletonContainer>
      </DialogTitle>
      <SkeletonContainer height={390} width={444} loading={loading}>
        <FormFields
          clientInvitationInfo={clientInvitationInfo}
          defaultValues={defaultValues}
          paymentConfiguration={client?.paymentConfiguration}
          onClose={onClose}
        />
      </SkeletonContainer>
    </Dialog>
  );
}
