import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Client, MealPlan } from "../../../types";
import { UPDATE_MEAL } from "../../../../../api/private/meals";
import axios from "../../../../../helpers/axios";
import { TranslatorNS } from "../../../../../i18n/const";
import { useSnackbar } from "notistack";

interface Props {
  plan: MealPlan;
  client?: Client;
}

export default function EditMeal({ plan }: Props) {
  const { t } = useTranslation(TranslatorNS.MEAL);
  const PATH = UPDATE_MEAL(plan.id);
  const [planName, setPlanName] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    plan?.name && setPlanName(plan.name);
  }, [plan.name]);

  const onSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const form = e.target;
      const name = form?.elements?.name.value;

      const data = new FormData();
      data.append("name", name);
      data.append("plan", String(plan.id));

      await axios.post(PATH, data);
      //@ts-ignore
      document.querySelector(".js-plan-title").textContent = name;
      //@ts-ignore
      $("#mealPlanModal").modal("hide");
      enqueueSnackbar(
        t("index.renamed.mealPlan", { ns: "plans" }) +
          " " +
          t("index.updated.mealPlan", { ns: "plans" }),
        {
          variant: "success",
          persist: false,
          preventDuplicate: true,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="modal inmodal fade sm reactModal"
      id="mealPlanModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
      data-title={t("editor.editMealPlan")}
      data-description={plan?.description || ""}>
      <div className="modal-dialog">
        <div className="modal-content modal-content-light-grey">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("plan.close")}</span>
            </button>
            <h4 className="modal-title">{t("editor.editMealPlanTitle")}</h4>
            <p className="modal-description">{plan?.description || ""}</p>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="mealPlanTitle" className="control-label">
                  {t("plan.title")}
                </label>
                <input
                  type="text"
                  name="name"
                  id="mealPlanTitle"
                  className="form-control title"
                  placeholder={t("plan.titleOfMealPlan")}
                  value={planName}
                  onChange={e => setPlanName(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-block btn-success btn-upper">
                {t("plan.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
