// TODO: DEPRECATED WHEN REPLACED EVERYWHERE
import React from "react";
import { useTranslation } from "react-i18next";
import MONEY_IMG from "../../../assets/images/ClientOverview/money.png";

const SubscriptionStatus = props => {
  const { t } = useTranslation("modals");
  const { clientPayment } = props;

  return (
    <React.Fragment>
      {clientPayment && clientPayment.length !== 0 && clientPayment[0].active && (
        <div className="current-payment-plan">
          <div className="image pull-left">
            <img src={MONEY_IMG} alt={"money img"} />
          </div>
          <div className="pull-left">
            <span className="until">
              {clientPayment[0].canceled_at
                ? t("subscriptionStatus.subEnded", {
                    time: clientPayment[0].canceled_at,
                  })
                : t("subscriptionStatus.currentPaymentUntil", {
                    time: clientPayment[0].until,
                  })}
            </span>
            <p>
              <span className="recurring-fee-js">
                {clientPayment[0].recurring_fee + " " + clientPayment[0].currency}
              </span>
              <span className="months-js">
                {" "}
                {clientPayment[0].months === 13
                  ? t("subscriptionStatus.untilClientUnsubs")
                  : t("subscriptionStatus.forMonths", {
                      months: clientPayment[0].months,
                    })}{" "}
              </span>
              {t("subscriptionStatus.withA")}
              <span className="upfront-fee-js">
                {" "}
                {clientPayment[0].upfront_fee + " " + clientPayment[0].currency}{" "}
              </span>
              {t("subscriptionStatus.upfrontFee")}
            </p>
          </div>
          <div className="clearfix"></div>
        </div>
      )}
      <div className="info">
        {clientPayment && clientPayment.length !== 0 && clientPayment[0].active
          ? t("subscriptionStatus.youHaveAnExistingSub")
          : t("subscriptionStatus.setupAutomaticSub")}
      </div>
    </React.Fragment>
  );
};

export default SubscriptionStatus;
