import React, { useEffect } from "react";
import Lottie from "react-lottie";

type Props = {
  lottie: any;
  delay?: number;
  speed?: number;
  loop?: boolean;
  autoplay?: boolean;
  width?: number | string;
  height?: number | string;
};

const ZFLottie = (props: Props) => {
  const {
    lottie,
    height = 400,
    width = 400,
    loop = true,
    autoplay = true,
    delay = 0,
    speed = 1,
  } = props;

  const [play, setPlay] = React.useState<boolean>(false);

  // TODO: useEffect should be rewritten correctly, when refactoring code delete eslint-disable…
  useEffect(() => {
    !autoplay ? setTimeout(() => setPlay(true), delay) : setPlay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOptions = {
    loop: loop,
    delay: delay,
    autoplay: autoplay,
    animationData: lottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      speed={speed}
      isStopped={!play}
      options={defaultOptions}
      height={height}
      width={width}
    />
  );
};

export default ZFLottie;
