import { Fragment } from "react";
import { GetClientGeneralQuery } from "../../../../generated/graphql";
import { format, isSameDay, isSameMonth } from "date-fns";
import { Typography } from "@mui/material";
import { getCurrentAgeDateFns } from "../../../../helpers/dates";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import Iconify from "../../../../components/_MUI/Iconify";

interface Props {
  age: GetClientGeneralQuery["client"]["age"];
  birthday: GetClientGeneralQuery["client"]["birthday"];
}

export default function Age(props: Props) {
  const { age, birthday } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const today = new Date();
  const ageYears = getCurrentAgeDateFns(birthday);
  const currentAge = age || ageYears;
  const ageString = currentAge
    ? t("newClientOverview.generalCard.age", { currentAge })
    : "-";
  const birthdayString = birthday && `, (${format(new Date(birthday), "do MMM")})`;

  const hasBirthday =
    birthday &&
    isSameMonth(today, new Date(birthday)) &&
    isSameDay(today, new Date(birthday));

  return (
    <Fragment>
      <Iconify icon="uil:calender" fontSize={20} />
      <Typography>
        {ageString}
        {birthdayString}
        {hasBirthday && ` 🎉 `}
      </Typography>
    </Fragment>
  );
}
