import { GetClientSavedWorkoutsQuery } from "../../../../generated/graphql";
import { Divider, Stack, Tooltip, Typography } from "@mui/material";
import { TranslatorNS } from "../../../../i18n/const";
import { useTranslation } from "react-i18next";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import Box from "@mui/material/Box";
import { useLocation } from "react-router";
import SavedWorkoutDay from "./SavedWorkoutDay";
import { TFlattenedWorkout } from "../index";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { getHourDurationFromSeconds } from "../../../../helpers/timeHelper";
import { connect } from "react-redux";
import { openGlobalModalAction } from "../../../../store/modals/action";
import { ModalPayload, ModalTypeEnum } from "../../../../store/modals/reducerTypes";

interface Props {
  savedWorkouts?: TFlattenedWorkout[];
  workoutsPerWeek?: GetClientSavedWorkoutsQuery["client"]["workoutsPerWeek"];
  loading?: boolean;
  openGlobalModalAction: (payload: ModalPayload<ModalTypeEnum.TrackedWorkouts>) => void;
}

function SavedWorkoutTable(props: Props) {
  const { savedWorkouts, loading, workoutsPerWeek, openGlobalModalAction } = props;
  const { t } = useTranslation(TranslatorNS.WORKOUT_PLAN);
  const location = useLocation();
  const isProgress = location.pathname.includes("progress");

  const columns: GridColDef<TFlattenedWorkout>[] = [
    {
      field: "id",
      headerName: "id",
    },
    {
      sortable: false,
      field: "date",
      headerName: t("info.table.weekday"),
      flex: 0.15,
      renderCell: ({ row }) => <SavedWorkoutDay savedWorkout={row} />,
    },
    {
      sortable: false,
      field: "planTitle",
      headerName: t("info.table.planTitle"),
      flex: 0.25,
    },
    {
      sortable: false,
      field: "dayTitle",
      headerName: t("info.table.title"),
      flex: 0.2,
      renderCell: ({ row }) => {
        if (!row.dayTitle) return "-";
        const enableToolTip = row.dayTitle.length > 15;

        return (
          <Tooltip title={enableToolTip ? row?.dayTitle : ""}>
            <Typography noWrap sx={{ textTransform: "capitalize", cursor: "default" }}>
              {row?.dayTitle}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "time",
      headerName: t("info.table.time"),
      flex: 0.25,
      renderCell: ({ row }) => {
        const duration = getHourDurationFromSeconds(Number(row?.time || 0));
        return (
          <Stack sx={{ width: "100%" }}>
            <Typography noWrap sx={{ cursor: "default" }}>
              {duration}
            </Typography>
          </Stack>
        );
      },
    },
    {
      sortable: false,
      field: "comment",
      headerName: t("info.table.comment"),
      flex: 0.2,
      renderCell: ({ row }) => {
        if (!row.comment) return "-";
        const enableToolTip = row.comment.length > 20;

        return (
          <Tooltip title={enableToolTip ? row?.comment : ""}>
            <Stack sx={{ width: "100%" }}>
              <Typography noWrap sx={{ cursor: "default" }}>
                {row?.comment}
              </Typography>
            </Stack>
          </Tooltip>
        );
      },
    },
  ];

  const autoHeight = savedWorkouts && savedWorkouts?.length <= 3;
  const minHeight = isProgress ? 380 : 280;

  return (
    <Stack>
      <Box sx={{ minHeight, overflowX: "hidden" }}>
        <DataGridPro
          sx={{
            border: 0,
            padding: 0,
            minHeight,
            "& .MuiDataGrid-cell": {
              cursor: "pointer",

              "& p": {
                cursor: "pointer!important",
              },
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                planTitle: isProgress,
              },
            },
          }}
          autoHeight={autoHeight}
          loading={loading}
          rows={savedWorkouts || []}
          columns={columns}
          localeText={{ noRowsLabel: t("info.table.noWorkouts") }}
          onRowClick={({ row: savedWorkout }) => {
            openGlobalModalAction({
              type: ModalTypeEnum.TrackedWorkouts,
              context: {
                savedWorkoutId: savedWorkout.id,
                savedWorkoutDate: savedWorkout.date,
              },
            });
          }}
        />
      </Box>
      <Divider />
      <Stack pt={2} pb={2} justifyContent={"center"} alignItems={"center"}>
        <Tooltip title={t("info.clientGoal")}>
          <Typography sx={{ cursor: "default" }} fontSize={24} variant={"subtitle2"}>
            <SkeletonContainer
              sx={{ mt: -1, mb: -0.5 }}
              variant={"text"}
              height={50}
              width={100}
              loading={Boolean(loading)}>
              {`${savedWorkouts?.length}/${workoutsPerWeek}`}
            </SkeletonContainer>
          </Typography>
        </Tooltip>
        <Typography variant={"caption"} color={"text.secondary"}>
          {t("createModal.workoutsTracked")}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default connect(undefined, { openGlobalModalAction })(SavedWorkoutTable);
