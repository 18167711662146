import React from "react";
import PromptBox from "./PromptBox";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes/routeMap";
import { Stack, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../i18n/const";
import { getNextClientTier } from "../../helpers/utils";
import { CoachType } from "../../generated/graphql";
import useGetActivationInfo from "./useGetActivationInfo";

interface Props {
  children: (props: { disabled: boolean }) => JSX.Element;
  userId: CoachType["id"];
}

function ActivateClientWrapper(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { children, userId } = props;

  let navigate = useNavigate();

  const { coach } = useGetActivationInfo({
    userId,
  });

  const nextClientTier = getNextClientTier(coach?.subscription?.plan?.clientCount || 0);
  const subscriptionClientCount = coach?.subscription?.plan?.clientCount || 0;
  const activeClients = coach?.activeClientCount || 0;

  const disabled =
    subscriptionClientCount !== 0 && activeClients > subscriptionClientCount;

  const title = t("upgrade.activateTitle");
  const subTitle = t("upgrade.activateSubtitle", { nextClientTier });

  return (
    <Tooltip
      disableHoverListener={!disabled}
      placement={"top"}
      componentsProps={tooltipStyle}
      arrow
      title={
        <PromptBox
          title={title}
          subTitle={subTitle}
          action={() => navigate(Routes.SETTINGS_PRICING)}
        />
      }>
      <Stack sx={{ width: "fit-content", cursor: disabled ? "not-allowed" : "pointer" }}>
        {children({ disabled })}
      </Stack>
    </Tooltip>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    userId: state.user.id,
  };
};

export default connect(mapStateToProps)(ActivateClientWrapper);

const tooltipStyle = {
  tooltip: {
    sx: {
      padding: "6px 0!important",
      backgroundColor: "white!important",
      boxShadow: "2px 2px 6px 1px rgb(178 178 178 / 17%)!important",
      border: "1px solid #f3f3f3",
      "& .MuiTooltip-arrow": {
        color: "white!important",
      },
    },
  },
};
