import { useEffect, useState } from "react";
import { UNREAD_CLIENT_MESSAGES } from "../../api/private/chat";
import axios from "axios";
import useGetClientTaskTooltipContent from "../ClientList/hooks/useGetClientTaskTooltipContent";
import { gql, useLazyQuery } from "@apollo/client";
import { EventNameEnum } from "../../generated/graphql";
import useResolveTask from "../ClientList/hooks/useResolveTask";

const GET_UNREAD_TASKS = gql`
  query GetUnreadTasks($clientId: Int!) {
    client(id: $clientId) {
      id
      unresolvedTasks {
        id
        date
        event {
          id
          name
          title
        }
      }
    }
  }
`;

export default function useUnreadClientMessages(clientId?: number) {
  const [unreadCount, setUnreadCount] = useState(0);
  const { getClientTasksNameId } = useGetClientTaskTooltipContent();
  const [getUnReadMessages] = useLazyQuery(GET_UNREAD_TASKS);
  const { handleResolveTaskWithoutNotification } = useResolveTask();

  useEffect(() => {
    if (clientId) {
      getUnreadMessagesCount(clientId);
    }
  }, [clientId]);

  const markTaskRead = async (clientId: number) => {
    const { data } = await getUnReadMessages({
      fetchPolicy: "network-only",
      variables: { clientId },
    });

    const unresolvedTasks = data?.client?.unresolvedTasks || [];

    const unreadTasks = getClientTasksNameId(unresolvedTasks, [
      EventNameEnum.UnansweredMessage,
    ]);

    if (unreadTasks?.length) {
      unreadTasks.map(async ({ statusId }) => {
        await handleResolveTaskWithoutNotification(statusId, clientId);
      });
    }
  };

  const getUnreadMessagesCount = async (clientIdNumber: number) => {
    const response = await axios.get(UNREAD_CLIENT_MESSAGES(clientIdNumber));

    setUnreadCount(response?.data?.count || 0);
  };

  return {
    unreadCount,
    resetUnreadCount: () => setUnreadCount(0),
    markTaskRead,
  };
}
