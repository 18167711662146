import { MeasuringSystemEnum } from "../../../../../generated/graphql";
import {
  getEstimatedCalories,
  TComputeProperties,
} from "../../../../../helpers/getEstimatedCaloriesGraphql";
import { List, ListItem, Stack, Tooltip, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { UilExclamationCircle, UilExclamationTriangle } from "@iconscout/react-unicons";
import { getLbsFromKg } from "../../../../../helpers/measurementHelper";
import { Alert } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../i18n/const";

const gramsPerWeekArr = [-1000, -500, 0, 500, 1000];

interface Props {
  computeProperties: TComputeProperties;
}

export default function EstimatedCalories(props: Props) {
  const { computeProperties } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { palette } = useTheme();

  const { bmr, tdee } = getEstimatedCalories(computeProperties);

  const propertiesContainNull = Object.entries(computeProperties)
    .filter(([_, v]) => v === null || v === undefined)
    .map(([k]) => t("newClientOverview.calProps." + k));

  const renderList = () => {
    if (propertiesContainNull.length) {
      const properties = propertiesContainNull.join(", ");
      return t("newClientOverview.missingCalProperties", { properties });
    }

    return (
      <List dense>
        {gramsPerWeekArr.map(number => {
          const goalTypeOverwrite = number >= 0 ? 2 : 1;
          const { string, kcals } = getEstimatedCalories(
            computeProperties,
            number,
            goalTypeOverwrite,
          );
          const measureNumber =
            computeProperties.measuringSystem === MeasuringSystemEnum.Metric
              ? number
              : getLbsFromKg(number);

          return (
            <ListItem sx={{ pl: 0 }} key={measureNumber}>
              <Typography
                component={"span"}
                sx={{
                  fontSize: 12,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                {t(string, { kcal: kcals })}{" "}
                {measureNumber !== 0 &&
                  `${Math.abs(measureNumber / 1000).toFixed(1)}
                        ${
                          computeProperties.measuringSystem === MeasuringSystemEnum.Metric
                            ? "kg"
                            : "lbs"
                        } 
                        ${t("info.estimatedCalories.perWeek")}`}
                {kcals <= 1400 && (
                  <Tooltip
                    arrow
                    placement={"top"}
                    title={t("info.estimatedCalories.dontRecommend")}>
                    <Stack ml={1}>
                      <UilExclamationTriangle color={palette.warning.main} size={12} />
                    </Stack>
                  </Tooltip>
                )}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Alert
      icon={false}
      sx={{ paddingX: 2, ".MuiAlert-message": { width: "100%" } }}
      severity={"info"}
      variant={"standard"}>
      <Stack alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"}>
        <Typography variant={"subtitle1"}>
          {t("const.clientInfoFields.caloriesNeeded")}{" "}
          {!propertiesContainNull.length && `(BMR: ${bmr} | TDEE: ${tdee})`}
        </Typography>
        <Tooltip arrow placement={"top"} title={t("info.estimatedCalories.labelTitle")}>
          <Stack>
            <UilExclamationCircle size={17} />
          </Stack>
        </Tooltip>
      </Stack>
      {renderList()}
    </Alert>
  );
}
