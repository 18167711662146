import CreateAccount from "./components/steps/CreateAccount";
import General from "./components/steps/General";
import Progress from "./components/steps/Photos";
import GoalWeight from "./components/steps/GoalWeight";
import Workout from "./components/steps/Workout";
import Diet from "./components/steps/Diet";
import Other from "./components/steps/Other";
import Done from "./components/steps/Done";
import React from "react";

export const getAllPages = (t: any, weightString: string) => {
  return {
    1: {
      title: t("client.activation.createAccountTitle"),
      subTitle: t("client.activation.createAccountSubTitle"),
      component: <CreateAccount />,
    },
    2: {
      title: t("client.activation.generalTitle"),
      subTitle: t("client.activation.generalSubTitle"),
      component: <General />,
    },
    3: {
      title: t("client.survey.photos"),
      subTitle: t("client.survey.photosDescription"),
      component: <Progress />,
    },
    4: {
      title: t("client.survey.goalInfoTitle"),
      subTitle: weightString
        ? t("client.survey.yourCurrentWeight", { weightString })
        : "No weight provided",
      component: <GoalWeight />,
    },
    5: {
      title: t("client.survey.workoutPreferences"),
      subTitle: t("client.survey.workoutSubtitlePreferences"),
      component: <Workout />,
    },
    6: {
      title: t("client.survey.dietStyleTitle"),
      subTitle: t("client.survey.dietStyleSubTitle"),
      component: <Diet />,
    },
    7: {
      title: t("client.survey.otherFeedbackTitle"),
      subTitle: t("client.survey.otherFeedbackSubTitle"),
      component: <Other />,
    },
    8: {
      title: t("client.downloadApp.title") + " 🎉",
      subTitle: t("client.downloadApp.subTitle"),
      bigSubTitle: true,
      component: <Done />,
    },
  };
};

export const getActivationPages = (t: any, configState: any) => {
  return {
    1: {
      title:
        configState === "activation"
          ? t("client.activation.updateAccountTitle")
          : t("client.activation.createAccountTitle"),
      subTitle:
        configState === "activation"
          ? t("client.activation.updateAccountSubTitle")
          : t("client.activation.createAccountSubTitle"),
      component: <CreateAccount />,
    },
    2: {
      title:
        configState === "activation"
          ? t("client.downloadApp.updated")
          : t("client.downloadApp.title") + " 🎉",
      subTitle:
        configState === "activation"
          ? t("client.downloadApp.updatedSubtitle")
          : t("client.downloadApp.subTitle"),
      bigSubTitle: true,
      component: <Done />,
    },
  };
};

export const getSurveyPages = (t: any, weightString: string) => {
  return {
    1: {
      title: t("client.activation.generalTitle"),
      subTitle: t("client.activation.generalSubTitle"),
      component: <General />,
    },
    2: {
      title: t("client.survey.photos"),
      subTitle: t("client.survey.photosDescription"),
      component: <Progress />,
    },
    3: {
      title: t("client.survey.goalInfoTitle"),
      subTitle: weightString
        ? t("client.survey.yourCurrentWeight", { weightString })
        : "No weight provided",
      component: <GoalWeight />,
    },
    4: {
      title: t("client.survey.workoutPreferences"),
      subTitle: t("client.survey.workoutSubtitlePreferences"),
      component: <Workout />,
    },
    5: {
      title: t("client.survey.dietStyleTitle"),
      subTitle: t("client.survey.dietStyleSubTitle"),
      component: <Diet />,
    },
    6: {
      title: t("client.survey.otherFeedbackTitle"),
      subTitle: t("client.survey.otherFeedbackSubTitle"),
      component: <Other />,
    },
    7: {
      title: t("client.downloadApp.title") + " 🎉",
      subTitle: t("client.downloadApp.subTitle"),
      bigSubTitle: true,
      component: <Done />,
    },
  };
};
