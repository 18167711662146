import { handleWorkoutTemplateModalAction } from "../store/action";
import { connect } from "react-redux";
import { IWorkoutTemplateState } from "../types";
import { DUPLICATE_WORKOUT, EDIT_WORKOUT } from "../const";
import MuiActions from "../../../components/Table/MuiActions";
import useWorkoutTemplatesMutations from "../useWorkoutTemplates/useWorkoutTemplateMutations";
import { IWorkoutTemplateModalState } from "../store/reducer";

function Actions({
  row,
  handleWorkoutTemplateModalAction,
}: {
  row: IWorkoutTemplateState;
  handleWorkoutTemplateModalAction: (object: IWorkoutTemplateModalState) => void;
}) {
  const { deleteExerciseMutation } = useWorkoutTemplatesMutations();

  const handleEdit = (row: IWorkoutTemplateState) => {
    const item = {
      id: row?.id,
      name: row.name,
      workoutsPerWeek: row?.workoutsPerWeek,
      level: row?.level,
      location: row?.location,
      gender: row?.gender,
      explanation: row?.explanation || "",
    };
    handleWorkoutTemplateModalAction({ ...EDIT_WORKOUT, item });
  };

  const handleDuplicate = (row: IWorkoutTemplateState) => {
    const item = {
      plan: row.id,
      name: row.name,
      workoutsPerWeek: row?.workoutsPerWeek,
      level: row?.level,
      location: row?.location,
      gender: row?.gender,
      explanation: row?.explanation || "",
    };
    handleWorkoutTemplateModalAction({ ...DUPLICATE_WORKOUT, item });
  };

  return (
    <MuiActions
      itemName={row.name}
      onDelete={() => row?.id && deleteExerciseMutation.mutate(row.id)}
      onDuplicate={() => handleDuplicate(row)}
      onEdit={() => handleEdit(row)}
    />
  );
}

export default connect(undefined, { handleWorkoutTemplateModalAction })(Actions);
