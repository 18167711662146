import React from "react";
import { GetClientGeneralQuery } from "../../../../generated/graphql";
import { differenceInWeeks, format } from "date-fns";
import { Stack, Tooltip, Typography } from "@mui/material";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

interface Props {
  startDate: GetClientGeneralQuery["client"]["startDate"];
  endDate: GetClientGeneralQuery["client"]["endDate"];
}

export default function Weeks(props: Props) {
  const { startDate, endDate } = props;
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  if (!startDate) {
    return <Week percentage={0} text={"-"} />;
  }

  if (!endDate) {
    const weeks = differenceInWeeks(new Date(), new Date(startDate));
    return (
      <Week
        percentage={100}
        text={t("newClientOverview.weekNumber", { weekNumber: weeks })}
      />
    );
  }

  const endDateObj = new Date(endDate);
  const startDateObj = new Date(startDate);

  const totalWeeks = differenceInWeeks(endDateObj, startDateObj);
  const currentWeekCount = differenceInWeeks(new Date(), startDateObj, {
    roundingMethod: "ceil",
  });
  const percentage = (currentWeekCount / totalWeeks) * 100;
  const maxPercentage = percentage <= 100 ? percentage : 100;

  const text = t("newClientOverview.generalCard.week", {
    currentWeekCount,
    totalWeeks,
  });

  const tooltipString = `${format(startDateObj, "MMM dd, yyyy")} - ${format(
    endDateObj,
    "MMM dd, yyyy",
  )}`;

  return (
    <Tooltip title={tooltipString}>
      <Stack>
        <Week percentage={maxPercentage} text={text} />
      </Stack>
    </Tooltip>
  );
}

const Week = ({ percentage, text }: { percentage: number; text: string }) => {
  return (
    <Stack flexDirection={"row"}>
      <WeekChart percentage={percentage} />
      <Typography>{text}</Typography>
    </Stack>
  );
};

const WeekChart = ({ percentage }: { percentage: number }) => {
  const totalPercentage = 100;
  const difference = totalPercentage - percentage;

  const data = [
    { name: "progress", value: percentage },
    { name: "goal", value: difference },
  ];

  return (
    <Stack width={24} height={24}>
      <ResponsiveContainer width="90%" height="90%">
        <PieChart>
          <Pie
            blendStroke
            dataKey="value"
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={10}
            startAngle={90}
            endAngle={-270}>
            <Cell fill={"#000000"} />
            <Cell fill={"#dfe3e8"} />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Stack>
  );
};
