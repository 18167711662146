import { Fragment } from "react";
import {
  ClientFoodPreferencesType,
  LocaleEnum,
  GetClientSettingsQuery,
} from "../../../../generated/graphql";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useYupClientSettings from "./useYupClientSettings";
import FormProvider from "../../../_MUI/hook-form/FormProvider";
import { Button, DialogContent, Stack, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DietFields from "./FormFields/DietFields";
import Typography from "@mui/material/Typography";
import { RHFTextField } from "../../../_MUI/hook-form";
import PlanFields from "./FormFields/PlanFields";
import WorkoutFields from "./FormFields/WorkoutFields";
import BasicFields from "./FormFields/BasicFields";
import AdditionalFields from "./FormFields/AdditionalFields";
import { setCurrentClient } from "../../../../store/currentClient/action";
import RHFGraphqlError from "../../../_MUI/hook-form/RHFGraphqlError";
import useUpdateClientSettings from "../hooks/useUpdateClientSettings";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { LocaleFlagAndName } from "../../../../i18n/locales";
import RHFSelect from "../../../_MUI/hook-form/RHFSelect";
import RHFTags from "../../../_MUI/hook-form/RHFTags";

type TClientSettingsCleaned = Omit<
  GetClientSettingsQuery["client"],
  "customQuestions" | "clientFoodPreferences" | "checkInDay"
>;

export type TEatingDisorders = Pick<
  ClientFoodPreferencesType,
  "foodDominates" | "lostControl" | "significantLoss" | "feelsFat" | "uncomfortablyFull"
>;

type TCustomProps = {
  allergies: string[];
  foodPreferences: string;
  customQuestions: {
    [question: string]: string | null;
  };
  eatingDisorders: TEatingDisorders | null;
  language: LocaleEnum.EnUs;
  tags: string[];
  checkInDay: string;
  trainerCustomQuestions: {
    [key: string]: {
      answer: string | null;
      question: string;
    };
  } | null;
};
export type TClientSettingsForm = TClientSettingsCleaned & TCustomProps;

interface Props {
  clientId: number;
  defaultValues: TClientSettingsForm;
  onClose: () => void;
  /*TODO: Remove this dispatch when all pages are migrated to GRAPHQL*/
  setCurrentClientDispatch: ({ clientId }: { clientId: number }) => void;
}

function ClientSettingsForm(props: Props) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { defaultValues, onClose, setCurrentClientDispatch, clientId } = props;

  const YupClientSettings = useYupClientSettings();

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(YupClientSettings),
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = methods;
  const formErrors = Object.values(errors).filter(error => error)?.length > 0;

  const { submitSettings, loading, error } = useUpdateClientSettings({
    reset,
    setCurrentClientDispatch,
    onClose,
    clientId,
  });

  return (
    <Fragment>
      <DialogTitle sx={{ pb: 2 }}>
        {t("newClientOverview.settingsFor", { name: defaultValues?.name || "" })}
      </DialogTitle>
      <DialogContent sx={{ minWidth: { xs: "100%", sm: 600 }, height: "95vh" }}>
        <Stack height={"90%"}>
          <FormProvider methods={methods}>
            <Stack>
              <RHFTextField
                sx={{ mt: 1, pb: 2 }}
                name="note"
                label={t("info.noteYourSelf")}
                multiline
                minRows={3}
              />
              <Stack mb={5}>
                <Typography variant={"subtitle2"} mb={2}>
                  {t("info.clientFields.contactInfo")}
                </Typography>
                <RHFTextField name="name" label={t("const.clientInfoFields.name")} />
                <RHFTextField name="email" label={t("const.clientInfoFields.email")} />
                <RHFTextField name="phone" label={t("const.clientInfoFields.phone")} />
                <RHFSelect name="locale" label={t("const.clientInfoFields.language")}>
                  {Object.entries(LocaleFlagAndName).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFTags name={"tags"} />
              </Stack>
              <PlanFields />
              <BasicFields />
              <WorkoutFields />
              <DietFields />
              <AdditionalFields customQuestions={defaultValues.customQuestions} />
            </Stack>
          </FormProvider>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width={"100%"}>
          <RHFGraphqlError error={error} />
          <Stack flexDirection={"row"} justifyContent={"flex-end"}>
            <Button
              size={"medium"}
              sx={{ mr: 2 }}
              onClick={onClose}
              variant={"outlined"}
              color={"inherit"}>
              {t("addClient.closeClient")}
            </Button>
            <LoadingButton
              size={"medium"}
              variant={"contained"}
              onClick={handleSubmit(submitSettings)}
              disabled={!isDirty}
              color={error || formErrors ? "error" : "primary"}
              loading={loading}>
              {t("info.saveStatus.save")}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>
    </Fragment>
  );
}
const mapDispatchToProps = {
  setCurrentClientDispatch: setCurrentClient,
};
export default connect(undefined, mapDispatchToProps)(ClientSettingsForm);
