import { Button, Stack, useTheme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactSortable, SortableEvent, Store, Sortable } from "react-sortablejs";
import "./style.css";
import {
  transformWorkoutItemToSuperSet,
  transformSuperSetToWorkoutItems,
  updateWorkoutItemComment,
  deleteWorkoutItem,
  updateWorkoutItemReps,
  updateWorkoutItemSets,
  updateWorkoutItemRest,
  updateWorkoutItemTime,
  getWorkoutDefaultConfig,
} from "../utils";
import { WorkoutPlan, WorkoutItemType } from "../types";
import BlockWrapper from "../Block";
import { sortableOptions } from "../sortableConfig";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useReorderWorkoutMutation,
  WorkoutGroupEnum,
  useAddExerciseToWorkoutDayMutation,
  GetWorkoutPlanTemplateDocument,
  useDeleteWorkoutMutation,
  useUpdateWorkoutMutation,
  ExerciseTypeEnum,
} from "../../../generated/graphql";
import EmptyListImage from "./Emptystatevisual.png";
import { Box } from "@mui/system";
import Image from "../../../components/_MUI/Image";
import { TranslatorKeyPair } from "../../../i18n/const";

export default function Nested({
  dayId,
  workoutPlan,
}: {
  dayId: number;
  workoutPlan: WorkoutPlan[];
}) {
  const theme = useTheme();
  const { templateId } = useParams<{ templateId: string }>();
  const [blocks, setBlocks] = useState<WorkoutPlan[]>([]);
  const [disableSectionDrop, setDisableSectionDrop] = useState(false);
  const [disableSuperSuperDrop, setDisableSuperSuperDrop] = useState(false);
  const [editingComment, setEditingComment] = useState(false);
  const [updateWorkout] = useUpdateWorkoutMutation();
  const [addWorkout] = useAddExerciseToWorkoutDayMutation();
  const [deleteWorkout] = useDeleteWorkoutMutation();
  const [reorderWorkout] = useReorderWorkoutMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  /**
   * Consider not using state and rely fully on backend returned data
   * has to make the data with redux or so if we want to keep some state machine
   * Though the state machine gives a nicer user experience as it is more responsive
   * and gives optimistic updates
   */

  useEffect(() => {
    setBlocks(JSON.parse(JSON.stringify(workoutPlan)));

    return () => {};
  }, [workoutPlan]);

  const onAdd = (evt: SortableEvent, sortable: Sortable | null, store: Store) => {
    /**
     * Safeguard to prevent event that are not reordering
     */
    if (evt.newIndex === undefined) {
      console.warn("no new index");
      return;
    }
    if (evt.oldIndex === undefined) {
      console.warn("no oldIndex");
      return;
    }
    /**
     * Pull mode prevents multiple re-orders
     * when an item is dragged from one list to another
     * (usually when the item is drag out from a list)
     */
    if (evt.pullMode === true) {
      reorderWorkout({
        variables: {
          workoutDayId: Number(dayId),
          input: {
            workoutId: Number(evt.item.dataset.id),
            order: evt.newIndex + 1,
            // parentId: null,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
    }
    console.groupEnd();
  };

  const onEnd = (evt: SortableEvent, sortable: Sortable | null, store: Store) => {
    /**
     * Resetting the disable dropzone flags
     */
    setDisableSectionDrop(false);
    setDisableSuperSuperDrop(false);

    /**
     * Safe guards to warn developers
     */
    if (evt.newIndex === undefined) {
      console.warn("no new index");
      return;
    }
    if (evt.oldIndex === undefined) {
      console.warn("no oldIndex");
      return;
    }

    const block = workoutPlan[evt.oldIndex];
    if (evt.oldIndex === evt.newIndex) {
      console.warn("Skipping as it is a nested reorder");
      return;
    }
    /**
     * Pull mode prevents multiple re-orders
     * when an item is dragged from one list to another
     * (usually when the item is drag into a list from a list)
     */
    if (evt.pullMode === true) {
      return;
    }
    reorderWorkout({
      variables: {
        workoutDayId: Number(dayId),
        input: {
          workoutId: block.id,
          order: evt.newIndex + 1,
        },
      },
      refetchQueries: () => [
        {
          query: GetWorkoutPlanTemplateDocument,
          variables: { id: Number(templateId) },
        },
      ],
    });

    setDisableSectionDrop(false);
  };

  const onStart = (e: SortableEvent) => {
    const blockMoved = blocks.find(block => block.id === Number(e.item.dataset.id));
    /**
     * set disable flag for super sets being moved into other super sets
     */
    if (blockMoved?.type === WorkoutItemType.SUPER_SET) {
      setDisableSuperSuperDrop(true);
    }
  };

  const handleSuperSet = async (blockId: number) => {
    try {
      const updatedBlocks = transformWorkoutItemToSuperSet(blocks, blockId);
      await updateWorkout({
        variables: {
          id: blockId,
          input: {
            type: WorkoutGroupEnum.Superset,
            // @ts-ignore
            // order: currentBlock.order,
          },
        },
      });
      setBlocks(updatedBlocks);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDiscardSuperset = (blockId: number) => {
    const updatedBlocks = transformSuperSetToWorkoutItems(blocks, blockId);

    setBlocks(updatedBlocks);
  };

  const updateComment = async (blockId: number, text: string) => {
    const fBlocks = updateWorkoutItemComment(blocks, blockId, text);
    try {
      await updateWorkout({
        variables: {
          id: blockId,
          input: {
            description: text,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      setBlocks(fBlocks);
      enqueueSnackbar(t("plan.commentUpdated", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.commentUpdatedError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };
  const updateReps = async (blockId: number, reps: string) => {
    const fBlocks = updateWorkoutItemReps(blocks, blockId, reps);
    try {
      await updateWorkout({
        variables: {
          id: blockId,
          input: {
            reps: reps,
          },
        },
      });
      setBlocks(fBlocks);
      enqueueSnackbar(t("plan.repsUpdated", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.repsUpdatedError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };
  const updateTime = async (blockId: number, time: string) => {
    const fBlocks = updateWorkoutItemTime(blocks, blockId, time);
    try {
      await updateWorkout({
        variables: {
          id: blockId,
          input: {
            time: time,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      setBlocks(fBlocks);
      enqueueSnackbar(t("plan.timeUpdated", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.timeUpdatedError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };
  const updateSets = async (blockId: number, sets: string) => {
    const fBlocks = updateWorkoutItemSets(blocks, blockId, sets);
    try {
      await updateWorkout({
        variables: {
          id: blockId,
          input: {
            sets: sets,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      setBlocks(fBlocks);
      enqueueSnackbar(t("plan.setsUpdated", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.setsUpdatedError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };
  const updateRest = async (blockId: number, rest: string) => {
    const fBlocks = updateWorkoutItemRest(blocks, blockId, rest);
    try {
      await updateWorkout({
        variables: {
          id: blockId,
          input: {
            rest: rest,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      setBlocks(fBlocks);
      enqueueSnackbar(t("plan.restUpdated", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.restUpdatedError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };

  const deleteItem = async (blockId: number) => {
    const fBlocks = deleteWorkoutItem(blocks, blockId);
    try {
      await deleteWorkout({
        variables: {
          id: blockId,
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });
      setBlocks(fBlocks);
      enqueueSnackbar(t("plan.exerciseDelete", { ns: TranslatorKeyPair.plans }));
    } catch (e) {
      enqueueSnackbar(t("plan.exerciseDeleteError", { ns: TranslatorKeyPair.plans }), {
        variant: "error",
      });
    }
  };

  const onCloneWorkout = (item: any, evt: SortableEvent) => {
    if (evt.newIndex === undefined) {
      console.warn("clone:no newIndex");
      return;
    }
    if (!item?.workoutType?.id) return item;
    const payload = getWorkoutDefaultConfig(item.workoutType.id);
    addWorkout({
      variables: {
        id: Number(dayId),
        input: {
          exerciseId: Number(item.id),
          order: evt.newIndex + 1,
          ...payload,
        },
      },
      refetchQueries: () => [
        {
          query: GetWorkoutPlanTemplateDocument,
          variables: { id: Number(templateId) },
        },
      ],
    });

    return {
      ...item,
      ...payload,
      // to make sure the local state is in sync so we don't get glitchy updates
      exerciseType:
        item.workoutType.id === 2 ? ExerciseTypeEnum.Time : ExerciseTypeEnum.Reps,
    };
  };
  const onCloneNestedWorkout = (item: any, evt: SortableEvent, parentId: number) => {
    if (evt.newIndex === undefined) {
      console.warn("clone:no newIndex");
      return;
    }

    /**
     * Determine if it is a reorder or creation
     */
    // if this exists it is a creation
    if (evt.pullMode === "clone") {
      const payload = getWorkoutDefaultConfig(item.workoutType.id);

      addWorkout({
        variables: {
          id: Number(dayId),
          input: {
            exerciseId: Number(item.id),
            order: evt.newIndex + 1,
            parentId,
            ...payload,
          },
        },
        refetchQueries: () => [
          {
            query: GetWorkoutPlanTemplateDocument,
            variables: { id: Number(templateId) },
          },
        ],
      });

      return { ...item, ...payload };
    }

    reorderWorkout({
      variables: {
        workoutDayId: Number(dayId),
        input: {
          workoutId: item.id,
          order: evt.newIndex + 1,
          parentId,
        },
      },
      refetchQueries: () => [
        {
          query: GetWorkoutPlanTemplateDocument,
          variables: { id: Number(templateId) },
        },
      ],
    });
    return { ...item };
  };

  const reorderNestedList = ({
    blockId,
    order,
    parentId,
  }: {
    blockId: number;
    order: number;
    parentId: number;
  }) => {
    reorderWorkout({
      variables: {
        workoutDayId: Number(dayId),
        input: {
          workoutId: blockId,
          order,
          parentId,
        },
      },
      refetchQueries: () => [
        {
          query: GetWorkoutPlanTemplateDocument,
          variables: { id: Number(templateId) },
        },
      ],
    });
  };

  const isListEmpty = workoutPlan.length === 0;

  const emptyListStyle = {
    border: `2px dashed ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 4,
    height: "100%",
  };

  const EmptyStateText = () => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        width: "100%",
      }}>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ mb: -10 }}>
          <Typography variant="h5">
            {t("plan.empty.title", { ns: TranslatorKeyPair.plans })}
          </Typography>
          <Typography variant="body1">
            {t("plan.empty.description", { ns: TranslatorKeyPair.plans })}
          </Typography>
        </Box>
        <img
          style={{ margin: "40px auto 0" }}
          src={EmptyListImage}
          width="40%"
          alt="empty-workout-plan"
          max-width="200px"
        />
      </Box>
    </Stack>
  );

  return (
    <div
      style={{
        height: "calc(100vh - 240px)",
        width: "100%",
        position: "relative",
      }}>
      {isListEmpty && <EmptyStateText />}
      <ReactSortable
        onEnd={onEnd}
        onAdd={onAdd}
        onStart={onStart}
        list={blocks}
        style={
          isListEmpty
            ? emptyListStyle
            : { minHeight: "100%", paddingBottom: isListEmpty ? 0 : 40 }
        }
        setList={setBlocks}
        clone={onCloneWorkout}
        {...sortableOptions}>
        {blocks.map((block, blockIndex) => (
          <BlockWrapper
            key={block.id}
            block={block}
            canBeSuperset={!block.parentId}
            handleSuperSet={handleSuperSet}
            handleDiscardSuperset={handleDiscardSuperset}
            blockIndex={[blockIndex]}
            setBlocks={setBlocks}
            updateComment={updateComment}
            updateReps={updateReps}
            updateTime={updateTime}
            updateSets={updateSets}
            updateRest={updateRest}
            deleteItem={deleteItem}
            disableSectionDrop={disableSectionDrop}
            disableSuperSuperDrop={disableSuperSuperDrop}
            setDisableSuperSuperDrop={setDisableSuperSuperDrop}
            reorderNestedList={reorderNestedList}
            onCloneNestedWorkout={onCloneNestedWorkout}
            setEditingComment={setEditingComment}
            editingComment={editingComment}
          />
        ))}
      </ReactSortable>
    </div>
  );
}
