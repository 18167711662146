import { isEqual } from "date-fns";
import { Grid, Stack, Typography } from "@mui/material";
import { WorkoutTrackingType } from "../../../../generated/graphql";
import { TrackedWorkoutsModalContext } from "../../../../store/modals/reducerTypes";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

const setsProps = {
  item: true,
  xs: 4,
};

interface Props {
  trackedWorkouts: WorkoutTrackingType[];
  readonly savedWorkoutDate: TrackedWorkoutsModalContext["savedWorkoutDate"];
}

export default function TrackedSets(props: Props) {
  const { trackedWorkouts, savedWorkoutDate } = props;
  const { t } = useTranslation(TranslatorNS.MODALS);

  const trackedForSavedWorkoutDay = trackedWorkouts.filter(
    t => t?.date && isEqual(new Date(savedWorkoutDate), new Date(t?.date)),
  );

  if (trackedForSavedWorkoutDay.length === 0) {
    return <Stack p={2}>{t("trackedWorkout.noTrackedSets")}</Stack>;
  }

  return (
    <Stack p={2}>
      {trackedForSavedWorkoutDay.map((trackedWorkout: WorkoutTrackingType, index) => {
        return (
          <Grid
            container
            key={trackedWorkout?.id}
            sx={{
              py: 0.5,
              borderBottom: "1px solid",
              borderColor:
                index + 1 < trackedForSavedWorkoutDay.length ? "grey.300" : "transparent",
            }}>
            <Grid
              {...setsProps}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
              }}>
              <Typography>Set {index + 1} </Typography>
            </Grid>

            <Grid
              {...setsProps}
              sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
              <Typography>
                {trackedWorkout?.reps ? `${trackedWorkout?.reps} reps` : "-"}
              </Typography>
            </Grid>
            <Grid
              {...setsProps}
              sx={{ alignItems: "center", display: "flex", justifyContent: "flex-end" }}>
              <Typography>
                {trackedWorkout?.weight ? `${trackedWorkout?.weight} kg/lbs` : "-"}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Stack>
  );
}
