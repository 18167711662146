import { MeasureSystem } from "../../interfaces/measureSystem";
import { WorkoutLocation } from "../../interfaces/workout";
import { Gender } from "../../interfaces/gender";

export enum ActivationConfigEnum {
  ACTIVATION = "activation",
  SURVEY = "survey",
  FULL = "full",
  NONE = "",
}

export enum ActivationStepsEnum {
  GENERAL = "general",
  PHOTOS = "photos",
  WORKOUT = "workout",
  GOAL = "goal",
  DIET = "diet",
  ACCOUNT = "account",
  OTHER = "other",
}

export interface IGeneral {
  gender: Gender | string;
  birthday: string | undefined;
  photo: string;
  height: number | undefined;
  feet: number | undefined;
  inches: number | undefined;
  startWeight: number | undefined;
  activityLevel: number | undefined;
  measuringSystem: MeasureSystem;
}

export interface IGeneralStateValidator {
  gender: string;
  birthday: string;
  photo: string;
  height: string;
  feet: string;
  inches: string;
  startWeight: string;
  activityLevel: string;
  measuringSystem: string;
}

export interface IAccount {
  name: string;
  email: string;
  phone: string;
  password?: string;
  passwordConfirm?: string;
  termsAccepted?: boolean;
}

export interface IPhotos {
  front: string;
  back: string;
  side: string;
}

export interface IDiet {
  dietStyle: string;
  numberOfMeals: number | undefined;
  allergies: string[];
  dietPreference: string;
  uncomfortablyFull?: boolean | null;
  lostControl?: boolean | null;
  significantLoss?: boolean | null;
  feelsFat?: boolean | null;
  foodDominates?: boolean | null;
}

export interface IGoal {
  goalWeight: string;
}

export interface IWorkout {
  injuries: string | undefined;
  experienceLevel: number | undefined;
  experience: string | undefined;
  exercisePreferences: string | undefined;
  workoutLocation: WorkoutLocation | undefined;
  workoutsPerWeek: number | undefined;
}

export interface IOther {
  lifestyle: string;
  motivation: string;
  customQuestions?: { [id: string]: string }[];
}

export interface ICustomQuestion {
  id: number;
  text: string;
  answer?: string;
}

export type TSubmitError = { message: string; status: string } | string;

export interface IDietValidator {
  dietStyle: string;
  numberOfMeals: string;
  allergies: string;
  dietPreference: string;
  uncomfortablyFull: string;
  lostControl: string;
  significantLoss: string;
  feelsFat: string;
  foodDominates: string;
}
