import { CREATE_MEALS, DELETE_MEALS, EDIT_MEALS, ERROR_STATE, GET_MEALS } from "./types";
import { MODAL_STATE } from "../../../helpers/const";
import { InitialModalShow } from "../../../helpers/listHelper";
import { LIMIT } from "../const";
import produce from "immer";

const INITIAL_STATE = {
  limit: LIMIT,
  offset: 0,
  meals: [],
  error: false,
  empty: null,
  modal: InitialModalShow,
  fetchLoading: true,
  allLoaded: false,
  initialLoading: true,
};

export default function Reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_MEALS.REQUEST: {
      return {
        ...state,
        fetchLoading: true,
      };
    }
    case GET_MEALS.SUCCESS: {
      return {
        ...state,
        meals: payload?.newList,
        offset: payload.offset,
        error: false,
        empty: payload?.allLoaded,
        fetchLoading: false,
        initialLoading: false,
        allLoaded: payload?.allLoaded,
      };
    }
    case CREATE_MEALS.SUCCESS: {
      return produce(state, draftState => {
        draftState.meals = draftState.meals.concat(payload);
        draftState.error = false;
      });
    }
    case EDIT_MEALS.SUCCESS: {
      return produce(state, draftState => {
        const objIndex = draftState.meals.findIndex(obj => obj.id === payload.id);
        draftState.meals[objIndex] = payload;
        draftState.error = false;
      });
    }
    case DELETE_MEALS.SUCCESS: {
      return produce(state, draftState => {
        const objIndex = draftState.meals.findIndex(obj => obj.id === payload);
        /*if last item reset page*/
        draftState.offset = draftState.meals.length === 1 ? 0 : draftState.offset;
        draftState.meals.splice(objIndex, 1);
      });
    }
    case ERROR_STATE: {
      return { ...state, error: payload };
    }
    case MODAL_STATE: {
      return { ...state, modal: payload };
    }
    default:
      return state;
  }
}
