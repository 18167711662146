import React from "react";

export enum RecipeModalType {
  EDIT_RECIPE = "EDIT_RECIPE",
}

type If<T, Type, Then, Otherwise> = T extends Type ? Then : Otherwise;

export interface EditRecipeDialogContext {
  id: number;
}

const initialValue = {
  type: null,
};

export type ModalContextForType<Type extends RecipeModalType> = If<
  Type,
  RecipeModalType.EDIT_RECIPE,
  EditRecipeDialogContext,
  never
>;

interface ContextState {
  type: RecipeModalType | null;
  context?: ModalContextForType<RecipeModalType>;
}

export const DialogContext = React.createContext<{
  modal: ContextState;
  setModal: React.Dispatch<ContextState>;
}>({ modal: initialValue, setModal: () => {} });

export default function DialogProvider({ children }: { children: React.ReactElement }) {
  const [modal, setModal] = React.useState<ContextState>(initialValue);
  const value = { modal, setModal };

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
}
