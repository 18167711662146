import { Client, MealPlan, PlanId } from "../types";
import { InitPlans } from "../vanilla";

const initMeal = (plan?: MealPlan, meal?: {}, client?: Client) => {
  let template;
  let initClient;

  /*TODO CLEANUP*/
  if (client?.id) {
    initClient = client
      ? {
          id: client.id,
          name: client.name,
          firstName: client.firstname,
        }
      : null;
    template = null;
  } else {
    initClient = null;
    template = {
      id: plan?.id,
      name: plan?.name,
    };
  }

  const wrapper = document.getElementById(PlanId.MEAL);

  if (wrapper !== null) {
    // @ts-ignore
    // this is a class Call from index
    new InitPlans(wrapper, InitPlans.MEAL, {
      client: initClient,
      updatedAt: plan?.lastUpdated,
      locale: plan?.locale,
      plan: client?.id ? plan : { ...plan, template: true },
      template: template,
    });
  }
};

export default initMeal;
