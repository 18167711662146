import * as React from "react";

const SendIcon = () => (
  <svg height={21} width={21} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.769 2.475a2.514 2.514 0 0 0-3.18-1.59l-14.97 4.99a2.513 2.513 0 0 0-.104 4.731l5.538 2.121c.4.155.718.472.873.872l2.121 5.54a2.485 2.485 0 0 0 2.344 1.614l.058-.001c1.064-.016 2-.706 2.33-1.718l4.99-14.97a2.514 2.514 0 0 0 0-1.589zm-.95 1.273-4.99 14.97a1.482 1.482 0 0 1-1.402 1.035 1.534 1.534 0 0 1-1.446-.972l-2.122-5.54a2.527 2.527 0 0 0-1.447-1.447l-5.54-2.121a1.513 1.513 0 0 1 .064-2.849l14.97-4.99a1.54 1.54 0 0 1 .49-.081 1.52 1.52 0 0 1 1.423 1.995z"
      fill="#fff"
    />
  </svg>
);

export default SendIcon;
