import { useEffect, useState } from "react";
import NavigationSteps from "../NavigationSteps";
import Dialog from "@mui/material/Dialog";
import useGetDefaultMessage from "./useGetDefaultMessage";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import LenusEmailForm from "./LenusEmailForm";
import { DefaultMessageTypeEnum } from "../../../../api/private/default-messages";
import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import EmailSuccess from "./EmailSuccess";
import { useNavigate } from "react-router";
import { Routes } from "../../../routeMap";

export enum LenusEmailStepEnum {
  LenusEmailStep = 0,
  LenusSuccessStep = 1,
}

export default function LenusEmailStep() {
  const { subject, content, loading, getDefaultMessageById } = useGetDefaultMessage();
  const { getStartedData, loading: providerLoading } = useLenusTransferContext();
  const [step, setStep] = useState(LenusEmailStepEnum.LenusEmailStep);
  let navigate = useNavigate();

  useEffect(() => {
    if (!providerLoading) {
      getDefaultMessageById(
        DefaultMessageTypeEnum.LenusTransferTemplate,
        getStartedData.locale,
      );
    }
  }, [providerLoading]);

  const defaultValues = {
    subject: subject || "",
    content: content || "",
  };

  const renderContent = () => {
    if (step === LenusEmailStepEnum.LenusSuccessStep) {
      return <EmailSuccess handleNext={() => navigate(Routes.LENUS_TRANSFER_PROGRESS)} />;
    }

    return (
      <Dialog
        maxWidth={"xl"}
        sx={{
          ".MuiPaper-root": {
            width: { xs: "90vw", sm: "50vw" },
            maxWidth: "720px!important",
          },
        }}
        open={true}
        onClose={() => {}}>
        <SkeletonContainer
          loading={loading}
          variant={"rectangular"}
          sx={{
            height: { xs: "90vh", sm: "88.5vh" },
            width: "100%",
          }}>
          <LenusEmailForm
            defaultValues={defaultValues}
            handleNext={() => {
              setStep(LenusEmailStepEnum.LenusSuccessStep);
            }}
          />
        </SkeletonContainer>
      </Dialog>
    );
  };

  return (
    <>
      {renderContent()}
      <NavigationSteps />
    </>
  );
}
