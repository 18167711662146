import { Alert, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { Link } from "react-router-dom";
import { CSSProperties } from "react";
import { Routes } from "../../../../routes/routeMap";
import Iconify from "../../../../components/_MUI/Iconify";

interface Props {
  alertSx: CSSProperties;
}

export default function LenusTransferReady(props: Props) {
  const { alertSx } = props;
  const { t } = useTranslation(TranslatorNS.APP);
  return (
    <Alert
      severity="info"
      variant="outlined"
      sx={alertSx}
      icon={<Iconify icon={"uil:info-circle"} color={"#ffc107"} />}>
      <Stack flexDirection={"row"}>
        <Typography color={"white"} sx={{ display: { xs: "none", sm: "flex" } }}>
          {t("userAlert.reachedDate")}
        </Typography>
        <Link to={Routes.LENUS_TRANSFER}>
          <Typography color={"white"} sx={{ ml: 0.5, textDecoration: "underline" }}>
            {t("userAlert.transferDataNow")}
          </Typography>
        </Link>
      </Stack>
    </Alert>
  );
}
