import { AppState } from "../../../../store";
import { connect } from "react-redux";
import ClientVideosTable from "./ClientVideosTable";
import { RequestState } from "../../../../interfaces/requestState";

interface Props {
  loading: boolean;
}

function ClientVideosTableContainer(props: Props) {
  const { loading } = props;

  if (loading) return null;

  return <ClientVideosTable />;
}

const mapStateToProps = (appState: AppState) => {
  return {
    loading:
      appState.currentClient.loading === RequestState.PENDING ||
      appState.currentClient.loading === RequestState.INITIAL,
  };
};

export default connect(mapStateToProps)(ClientVideosTableContainer);
