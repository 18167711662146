import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveFieldsAction } from "../../store/action";
import { useTranslation } from "react-i18next";
import { ACTIVITY_LEVEL } from "../../const";
import { FieldValidators } from "../../../../helpers/validators";
import {
  GetCMFromFeetInches,
  GetFeetInchesFromCM,
  isKgOrPound,
  roundToTwo,
} from "../../../../helpers/measurementHelper";
import { TranslatorNS } from "../../../../i18n/const";
import { ActivationStepsEnum, IGeneral, IGeneralStateValidator } from "../../type";
import { InputType } from "../../../../components/InputField/enum";
import { AppState } from "../../../../store";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { MeasureSystem } from "../../../../interfaces/measureSystem";
import { Gender } from "../../../../interfaces/gender";
import Typography from "@mui/material/Typography";
import { INITIAL_GENERAL } from "../../store/initialStates";
import { IClientActivationState } from "../../store/types";
import { UploadAvatar } from "../../../../components/_MUI/upload";
import { DesktopDatePicker } from "@mui/lab";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { scrollToElementId } from "../../../../helpers/utils";
import { getCurrentAge, isValidAge } from "../../../../helpers/dates";
import { track } from "../../../../helpers/analytics";

interface IGeneralProps {
  step: IClientActivationState["step"];
  saveFieldsActionDispatch: (
    general: IGeneral,
    nextStep: IClientActivationState["step"],
    currentStepName: ActivationStepsEnum,
  ) => void;
  generalState: IGeneral;
}

const General = (props: IGeneralProps) => {
  const { step, saveFieldsActionDispatch, generalState } = props;
  const { t } = useTranslation(TranslatorNS.MESSAGES);
  const [validator, setValidator] = useState<IGeneralStateValidator>({
    activityLevel: "",
    birthday: "",
    feet: "",
    gender: "",
    height: "",
    inches: "",
    measuringSystem: "",
    photo: "",
    startWeight: "",
  });
  const [general, setGeneral] = useState<IGeneral>(INITIAL_GENERAL);

  useEffect(() => {
    setGeneral({
      ...general,
      gender: generalState.gender,
      birthday: generalState.birthday,
      photo: generalState.photo,
      height: generalState.height,
      feet: generalState.feet,
      inches: generalState.inches,
      startWeight: generalState.startWeight,
      activityLevel: generalState.activityLevel,
      measuringSystem: generalState.measuringSystem || MeasureSystem.METRIC,
    });
  }, [generalState]);

  const handleChange = (
    name: keyof typeof general,
    value?: string | number | Date | any,
  ) => {
    setValidator({ ...validator, [name]: "" });
    setGeneral({ ...general, [name]: value });
  };

  const handleDropAvatar = useCallback(
    (acceptedFiles: any[]) => {
      const file = acceptedFiles[0];
      if (file) {
        setGeneral({
          ...general,
          photo: file,
        });
      }
    },
    [general],
  );

  const handleMeasureSystem = (
    name: string,
    measureType: ChangeEvent<HTMLInputElement>,
  ) => {
    if (Number(measureType.target.value) === MeasureSystem.IMPERIAL) {
      setGeneral({
        ...general,
        measuringSystem: MeasureSystem.IMPERIAL,
        startWeight: general?.startWeight
          ? roundToTwo(general.startWeight * 2.205)
          : undefined,
        feet: GetFeetInchesFromCM(general.height).feet,
        inches: GetFeetInchesFromCM(general.height).inches,
      });
    } else {
      setGeneral({
        ...general,
        measuringSystem: MeasureSystem.METRIC,
        startWeight: general.startWeight
          ? roundToTwo(general.startWeight / 2.205)
          : undefined,
        height: GetCMFromFeetInches(general.feet, general.inches),
      });
    }
  };

  const handleSave = () => {
    try {
      if (Number(general.measuringSystem) === MeasureSystem.IMPERIAL) {
        general["height"] = GetCMFromFeetInches(general["feet"], general["inches"]);
      } else {
        general["feet"] = GetFeetInchesFromCM(general.height).feet;
        general["inches"] = GetFeetInchesFromCM(general.height).inches;
      }

      FieldValidators(general, t, ["photo"]);

      if (general?.birthday) {
        const age = getCurrentAge(general.birthday);
        if (!isValidAge(age)) {
          throw { birthday: t("client.activation.ageValidation") };
        }
      }
      track("General Information Added", "Client Activation");
      saveFieldsActionDispatch(general, Number(step) + 1, ActivationStepsEnum.GENERAL);
    } catch (e: any) {
      setValidator(e);
      const id = e && Object.keys(e)[0];
      id && scrollToElementId(id);
    }
  };

  return (
    <Stack width={{ xs: "100%", sm: 450 }}>
      <UploadAvatar
        photoText={t("client.activation.bodyPictures.uploadProfile")}
        updatePhotoText={t("client.activation.bodyPictures.updatePhoto")}
        accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
        file={general.photo}
        onDrop={handleDropAvatar}
        error={Boolean(validator["photo"])}
        helperText={validator["photo"]}
      />
      <FormControl sx={{ my: 2 }}>
        <RadioGroup
          id={"zf_gender"}
          name="gender"
          value={general.gender || ""}
          onClick={({ target }: any) => handleChange("gender", Number(target.value))}>
          <Stack flexDirection={"row"} justifyContent={"center"}>
            <FormControlLabel
              value={Gender.FEMALE}
              control={<Radio />}
              label={
                <Typography color={Boolean(validator["gender"]) ? "error" : "inherit"}>
                  {t("client.survey.female")}
                </Typography>
              }
            />
            <FormControlLabel
              value={Gender.MALE}
              control={<Radio />}
              label={
                <Typography color={Boolean(validator["gender"]) ? "error" : "inherit"}>
                  {t("client.survey.male")}
                </Typography>
              }
            />
          </Stack>
        </RadioGroup>
      </FormControl>
      <DesktopDatePicker
        label={t("client.survey.birthday")}
        inputFormat="dd/MM/yyyy"
        value={general.birthday}
        onChange={newValue => {
          handleChange("birthday", newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            error={Boolean(validator["birthday"])}
            id={"zf_birthday"}
            helperText={validator["birthday"]}
            value={general.birthday}
            data-cy={CypressTestId.CLIENT_PAGE_BIRTHDAY}
          />
        )}
      />

      <FormControl sx={{ my: 2 }}>
        <RadioGroup
          name="measuringSystem"
          value={general.measuringSystem}
          onChange={(val: ChangeEvent<HTMLInputElement>) =>
            handleMeasureSystem("measuringSystem", val)
          }>
          <Stack flexDirection={"row"} justifyContent={"center"} mb={1}>
            <FormControlLabel
              value={MeasureSystem.METRIC}
              control={<Radio />}
              label={t("client.survey.metric")}
            />
            <FormControlLabel
              value={MeasureSystem.IMPERIAL}
              control={<Radio />}
              label={t("client.survey.imperial")}
            />
          </Stack>
        </RadioGroup>
      </FormControl>

      {Number(general.measuringSystem) === MeasureSystem.IMPERIAL ? (
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <TextField
            sx={{ mb: 2, width: "49%" }}
            label={"Feet"}
            name={"feet"}
            type={InputType.NUMBER}
            onChange={e => handleChange("feet", Number(e.target.value))}
            value={general.feet || ""}
            error={Boolean(validator["inches"])}
            helperText={validator["feet"]}
          />
          <TextField
            sx={{ mb: 2, width: "49%" }}
            label={"Inches"}
            name={"inches"}
            type={InputType.NUMBER}
            onChange={e => handleChange("inches", Number(e.target.value))}
            value={general.inches || ""}
            error={Boolean(validator["inches"])}
            helperText={validator["inches"]}
          />
        </Stack>
      ) : (
        <TextField
          sx={{ mb: 2 }}
          label={t("client.survey.height")}
          name={"height"}
          id={"zf_height"}
          type={InputType.NUMBER}
          InputProps={{ endAdornment: "cm" }}
          onChange={e => handleChange("height", Number(e.target.value))}
          value={general.height || ""}
          error={Boolean(validator["height"])}
          helperText={validator["height"]}
        />
      )}

      <TextField
        label={t("client.survey.startWeight")}
        name={"startWeight"}
        id={"zf_startWeight"}
        sx={{ mb: 2 }}
        value={general.startWeight || ""}
        InputProps={{
          endAdornment: isKgOrPound(general.measuringSystem),
          inputProps: {
            min: general.measuringSystem === MeasureSystem.IMPERIAL ? 66 : 30,
          },
        }}
        type={InputType.NUMBER}
        onChange={e => handleChange("startWeight", e.target.value)}
        error={Boolean(validator["startWeight"])}
        helperText={validator["startWeight"]}
      />

      <TextField
        id={"zf_activityLevel"}
        name={"activityLevel"}
        value={general.activityLevel ?? ""}
        onChange={e => handleChange("activityLevel", e.target.value)}
        error={Boolean(validator["activityLevel"])}
        SelectProps={{
          native: true,
        }}
        select
        label={t("client.survey.activityLevel")}>
        <option disabled={Boolean(general.activityLevel)} value={undefined}>
          {t("chooseOption")}
        </option>
        {Object.entries(ACTIVITY_LEVEL).map(([key, val]) => {
          return (
            <option key={key} value={key}>
              {t(val)}
            </option>
          );
        })}
      </TextField>

      <Button
        sx={{ mt: 2 }}
        variant="contained"
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_2}
        size="large"
        onClick={() => handleSave()}>
        {t("client.activation.next")}
      </Button>
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    step: clientActivation.step,
    generalState: clientActivation.general,
  };
}

const mapDispatchToProps = {
  saveFieldsActionDispatch: saveFieldsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(General);
