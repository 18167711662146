import { Dispatch } from "redux";
import { NavigateFunction } from "react-router";

import * as api from "../../api";
import { Routes } from "../../../routeMap";
import { WorkoutplanActionTypes } from "../types";
import { WorkoutPlan } from "../../../../interfaces/meal";
import { formatWorkoutplan } from "../helpers/formatData";
import { prepareRoute } from "../../../../helpers/routing";
import { extractResponseError } from "../../../../helpers/error";
import { SET_MANUAL_PLAN_MODAL } from "../../../MealPlan/store/modal/types";
import { track } from "../../../../helpers/analytics";

export const CREATE_WORKOUTPLANS_REQUEST = "workoutplan/CREATE_REQUEST";
export const CREATE_WORKOUTPLANS_SUCCESS = "workoutplan/CREATE_SUCCESS";
export const CREATE_WORKOUTPLANS_FAILURE = "workoutplan/CREATE_FAILURE";

// CREATE
export interface CreateWorkoutplansRequest {
  type: typeof CREATE_WORKOUTPLANS_REQUEST;
  payload: Partial<WorkoutPlan>;
}
export interface CreateWorkoutplansSuccess {
  type: typeof CREATE_WORKOUTPLANS_SUCCESS;
  payload: WorkoutPlan;
}
export interface CreateWorkoutplansFailure {
  type: typeof CREATE_WORKOUTPLANS_FAILURE;
  payload: any;
}

export const createWorkoutPlan = (
  workoutplan: api.CreateWorkPlanBase,
  navigate: (url: string, templateId: number) => void,
) => {
  return async (dispatch: Dispatch<WorkoutplanActionTypes>) => {
    dispatch({ type: CREATE_WORKOUTPLANS_REQUEST, payload: workoutplan });
    try {
      const data = await api.createWorkoutPlan(workoutplan);
      dispatch({
        type: CREATE_WORKOUTPLANS_SUCCESS,
        payload: formatWorkoutplan(data, workoutplan.clientId),
      });
      const clientId = workoutplan.clientId;
      const templateId = data.id;

      dispatch({ type: SET_MANUAL_PLAN_MODAL, payload: null });

      if (clientId && templateId) {
        const url = prepareRoute(Routes.CLIENT_WORKOUT_PLAN_EDITOR, {
          clientId,
          templateId,
        });
        track("Create Workout Plan", "Workout Plan");
        return navigate(url, templateId);
      }
    } catch (error: any) {
      dispatch({
        type: CREATE_WORKOUTPLANS_FAILURE,
        payload: extractResponseError(error),
      });
    }
  };
};
