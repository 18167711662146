export const GLOBAL_FONT_FAMILY = "inter, san-serif";
export const GLOBAL_FONT_STYLE = `
  font-family: inter, san-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

/*TODO: Move or delete to appropriate folder*/
export const REMOVE_SELECTION = `
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
