import axios, { userPrivateApi } from "../../helpers/axios";
import {
  CHANGE_USER_LANGUAGE,
  GET_USER,
  UPDATE_MESSAGE_COUNT,
  UPDATE_USER_TAGS,
} from "./reducerTypes";
import { ERROR_MESSAGE } from "../../config";
import { GET_PUBLIC_USER } from "../../api/public/users";
import { GET_UNREAD_MESSAGES } from "../../api/private/users";
import { updateUserLanguage } from "../../i18n/helpers/translator";
import { UPDATE_USER_LANG } from "../../api/private/settings";
import * as Sentry from "@sentry/browser";
import { hashString } from "../../providers/ErrorProvider/util";
import { Locale } from "../../interfaces/locale";

function hasUserLoadedAtExpired(loadedAt: Date) {
  // 10 min expiration time
  const expirationInMinutes = 60 * 10;
  const loadedAtExpirationTime = loadedAt.getTime() + expirationInMinutes * 1000;

  return loadedAtExpirationTime < Date.now();
}

function shouldLoadUser(loadedAt: Date | null) {
  if (!loadedAt) return true;
  return hasUserLoadedAtExpired(loadedAt);
}

export const fetchUserPrivate = () => {
  return async (dispatch: any, getState: any) => {
    try {
      const shouldFetch = shouldLoadUser(getState().user.loadedAt);
      if (shouldFetch) {
        const { data } = await userPrivateApi.getUserApiPrivateGetUserInfo();
        if (data?.id && data?.name) {
          const username = hashString(String(data.id));
          Sentry.setUser({ id: String(data.id), name: data.name, username });
        }
        updateUserLanguage((data?.locale as Locale) || Locale.en_US);
        dispatch({ type: GET_USER.SUCCESS, payload: data });
      }
    } catch (e) {
      dispatch({ type: GET_USER.FAILURE, ERROR_MESSAGE });
    }
  };
};

export const fetchUserPublic = (userToken: string) => {
  return async (dispatch: any) => {
    dispatch({ type: GET_USER.REQUEST });
    try {
      const headers = { Authorization: userToken };
      const { data } = await axios.get(GET_PUBLIC_USER(), { headers });
      dispatch({ type: GET_USER.SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: GET_USER.FAILURE, ERROR_MESSAGE });
    }
  };
};

export const changeUserLangAction = (locale: Locale) => {
  return async (dispatch: any) => {
    dispatch({ type: CHANGE_USER_LANGUAGE, payload: locale });
    updateUserLanguage(locale);
    await axios.post(UPDATE_USER_LANG(), { locale });
  };
};

export const fetchUserUnreadMessages = () => {
  return async (dispatch: any) => {
    const res = await axios.get(GET_UNREAD_MESSAGES());

    dispatch({
      type: UPDATE_MESSAGE_COUNT,
      payload: { unreadMessagesCount: res?.data?.unreadMessagesCount },
    });
  };
};

export const updateUserTags = (tags?: string[]) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_USER_TAGS,
      payload: { tags },
    });
  };
};
