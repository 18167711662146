import { ClientType } from "../../generated/graphql";
import { DefaultMessageTypeEnum } from "../../api/private/default-messages";

export enum ModalTypeEnum {
  ClientSubscription = "ClientSubscription",
  EmailInvitation = "EmailInvitation",
  TrackedWorkouts = "TrackedWorkouts",
  MealTemplate = "MealTemplate",
  ClientInfo = "ClientInfo",
}

export const OPEN_GLOBAL_MODAL = "OPEN_GLOBAL_MODAL";
export const CLOSE_GLOBAL_MODAL = "CLOSE_GLOBAL_MODAL";

export enum ModalCrudTypeEnum {
  Create = "Create",
  Update = "Update",
  Duplicate = "Duplicate",
}

/*Global modals*/
export interface CreateSubscriptionContext {
  readonly clientId: ClientType["id"];
}

export interface EmailInvitationModalContext {
  readonly clientId: ClientType["id"];
  readonly messageType: DefaultMessageTypeEnum;
  readonly queueId: number;
  readonly dataKey: string;
}

export interface TrackedWorkoutsModalContext {
  readonly savedWorkoutId: number;
  readonly savedWorkoutDate: string;
}

export interface MealTemplateModalContext {
  readonly mealPlanId?: number;
  readonly type: ModalCrudTypeEnum;
}

export interface ClientInfoModalContext {
  readonly clientId: number;
}

export type GlobalModalState = {
  [type in ModalTypeEnum]?: ModalContextForType<type>;
};

export type If<T, Type, Then, Otherwise> = T extends Type ? Then : Otherwise;

// add more modals here with a context
// prettier-ignore
export type ModalContextForType<Type extends ModalTypeEnum> =
  If<Type, ModalTypeEnum.ClientSubscription, CreateSubscriptionContext,
  If<Type, ModalTypeEnum.EmailInvitation, EmailInvitationModalContext,
  If<Type, ModalTypeEnum.TrackedWorkouts, TrackedWorkoutsModalContext,
  If<Type, ModalTypeEnum.MealTemplate, MealTemplateModalContext,
  If<Type, ModalTypeEnum.ClientInfo, ClientInfoModalContext,
  never
  >>>>>;

export interface ModalPayload<Type extends ModalTypeEnum> {
  type: Type;
  context?: ModalContextForType<Type>;
}

// Opens modal of provided type
interface OpenModal<Type extends ModalTypeEnum> {
  type: typeof OPEN_GLOBAL_MODAL;
  payload: ModalPayload<Type>;
}

// Closes modal of provided type
interface CloseModal {
  type: typeof CLOSE_GLOBAL_MODAL;
  payload: ModalTypeEnum;
}

export function isModalOpen(type: ModalTypeEnum, state: GlobalModalState) {
  return type in state;
}

export type ModalActionTypes<Type extends ModalTypeEnum> = OpenModal<Type> | CloseModal;
