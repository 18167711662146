import React from "react";
import SendIcon from "../icons/Send";
import { GetFormattedMM2SEC } from "../../util/time";
import CameraIcon from "../icons/Camera";
import LoadingIcon from "../icons/Loading";
import ZFLoader from "../../../../../../components/Loader";
import { CircularProgress } from "@mui/material";
import { RecorderStatusEnum } from "../../type";
import { MAX_RECORDING_SEC } from "../../config";

interface Props {
  status?: RecorderStatusEnum;
  timeCounter: number;
  handleDownload: () => void;
  handleStopCaptureClick: () => void;
  handleStartCaptureClick: () => void;
}

const VideoFooter = (props: Props) => {
  const {
    status,
    timeCounter,
    handleDownload,
    handleStopCaptureClick,
    handleStartCaptureClick,
  } = props;

  const RenderButton = () => {
    switch (status) {
      case RecorderStatusEnum.NOT_READY:
        return (
          <CircularProgress
            color="success"
            variant={"indeterminate"}
            size={25}
            thickness={3}
          />
        );
      case RecorderStatusEnum.RECORDING:
        return (
          <span onClick={handleStopCaptureClick}>
            <span className="counter">{GetFormattedMM2SEC(timeCounter)}</span>
            <ZFLoader
              style={{ position: "absolute" }}
              color={"rgba(204,204,204,0.58)"}
              size={46}
              thickness={2}
              value={(timeCounter / MAX_RECORDING_SEC) * 100}
            />
          </span>
        );
      case RecorderStatusEnum.READY:
        return (
          <span onClick={handleStartCaptureClick}>
            <CameraIcon />
          </span>
        );
      case RecorderStatusEnum.READY_TO_SEND:
        return (
          <span onClick={handleDownload}>
            <SendIcon />
          </span>
        );
      default:
        return (
          <span>
            <LoadingIcon />
          </span>
        );
    }
  };

  if (
    status === RecorderStatusEnum.NO_PERMISSIONS ||
    status === RecorderStatusEnum.ERROR
  ) {
    return <></>;
  }

  return (
    <div className={"videoFooter"}>
      <div
        className={`actionButton ${
          status === RecorderStatusEnum.NOT_READY ? "ZFLoading" : ""
        } `}>
        {RenderButton()}
      </div>
    </div>
  );
};

export default VideoFooter;
