import { useState, useRef, useEffect } from "react";
import axios from "../../../helpers/axios";
import * as yup from "yup";
import { Dispatch } from "redux";
import { connect } from "react-redux";

import styled from "styled-components";
import { Formik, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { AppState } from "../../../store";
import ZFLoader from "../../Loader";
import { TranslatorNS } from "../../../i18n/const";
import { SAVE_TEMPLATE } from "../../../api/private";
import { GLOBAL_FONT_STYLE } from "../../../styleguide/Fonts";
import AddClientModalBodyPlaceholder from "./AddClientModalBodyPlaceholder";
import * as clientActions from "../../../routes/Clients/store/clients/actions";
import { prepareMessageUsingTags } from "../../SideContent/helper";
import { GET_DEFAULT_MESSAGES_LOCAL_QUEUE } from "../../../api/private/default-messages";
import {
  AddClientStep,
  QuestionnaireFormValues,
  InvitationData,
  EmailTemplate,
} from "./types";
import { quillModules, quillFormats } from "../../SideContent/reactQuillConfig";
import { track } from "../../../helpers/analytics";
import { CypressTestId } from "../../../helpers/cypressTestIds";

interface SendEmailData {
  queue?: number;
  type?: number;
  to?: string;
  subject?: string;
  "default-message-dropdown": number | null;
  message?: string;
}

export const questionnaireInitialValues: QuestionnaireFormValues = {
  to: "",
  subject: "",
  template: null,
  message: "",
};

const validationSchema = yup.object({
  to: yup.string().email().required(),
  subject: yup.string().required(),
  template: yup.number().nullable(),
  message: yup.string().required(),
});

function Questionnaire({
  invitationData,
  hiddenSteps,
  closeClientModal,
  // redux-props
  sendEmail,
  changeFilterProperty,
}: {
  invitationData: InvitationData;
  hiddenSteps: AddClientStep[];
  closeClientModal: (closeImmediately: boolean) => void;
  sendEmail: (data: SendEmailData) => Promise<void>;
  changeFilterProperty: (activeFilter: string) => void;
}) {
  const { messageType, clientId, locale, dataKey, queueId } = invitationData;

  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState<boolean>(false);
  const [templateList, setTemplateList] = useState<EmailTemplate[]>([]);
  const [initialValues, setInitialValues] = useState<QuestionnaireFormValues>(
    questionnaireInitialValues,
  );
  const formRef = useRef<FormikProps<QuestionnaireFormValues>>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);

  useEffect(() => {
    async function getTemplates() {
      try {
        setLoading(true);
        const { data } = await axios.get(
          GET_DEFAULT_MESSAGES_LOCAL_QUEUE(messageType!, clientId!, locale!, dataKey!),
        );

        const defaultTemplates = data?.defaultMessages || [];

        const {
          id: defaultTemplateId,
          subject,
          placeholders,
          message,
        } = defaultTemplates[defaultTemplates.length - 1] || {};

        setTemplateList(defaultTemplates);
        setInitialValues({
          to: invitationData.clientEmail || "",
          subject: prepareMessageUsingTags(subject, placeholders, false) || "",
          message: prepareMessageUsingTags(message, placeholders, false) || "",
          template: defaultTemplateId || null,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    getTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionnaireFormSubmit = async (values: QuestionnaireFormValues) => {
    try {
      setIsSubmitting(true);
      track("Questionnaire Step Completed", "Add Client Modal");

      await sendEmail({
        queue: queueId,
        type: messageType,
        to: values.to,
        subject: values.subject,
        "default-message-dropdown": values.template,
        message: values.message,
      });

      setTimeout(() => {
        enqueueSnackbar(t("addClient.emailSentToClient"), {
          variant: "success",
          persist: false,
          preventDuplicate: true,
        });
      }, 900);

      changeFilterProperty("pending");
      closeClientModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTemplateSave = async (values: QuestionnaireFormValues) => {
    try {
      setIsSavingTemplate(true);
      const placeholders =
        templateList[templateList.findIndex(t => t.id === values.template)].placeholders;

      await axios.post(SAVE_TEMPLATE(), {
        textarea: prepareMessageUsingTags(values.message, placeholders, true),
        subject: prepareMessageUsingTags(values.subject, placeholders, true),
        type: invitationData.messageType,
      });

      enqueueSnackbar(t("actions.saved"), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });
    } catch (error: any) {
      const message = error?.response?.data?.reason;
      message &&
        enqueueSnackbar(message, {
          variant: "error",
          persist: false,
          preventDuplicate: true,
        });
    } finally {
      setIsSavingTemplate(false);
    }
  };

  const onTemplateChange = (
    e: SelectChangeEvent<string | number>,
    setFieldValue: (field: string, value: string | number) => void,
  ) => {
    const selectedTemplateId = e.target.value;
    const selectedTemplate =
      templateList[templateList.findIndex(t => t.id === selectedTemplateId)];

    setFieldValue("template", selectedTemplateId);
    setFieldValue(
      "subject",
      prepareMessageUsingTags(
        selectedTemplate.subject,
        selectedTemplate.placeholders,
        false,
      ) || "",
    );
    setFieldValue(
      "message",
      prepareMessageUsingTags(
        selectedTemplate.message,
        selectedTemplate.placeholders,
        false,
      ) || "",
    );
  };

  return (
    <AddClientModalBodyPlaceholder
      title="addClient.invitation"
      currentModalStep={AddClientStep.QUESTIONNAIRE}
      hiddenSteps={hiddenSteps}
      onClose={() => closeClientModal(false)}
      body={
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleQuestionnaireFormSubmit}>
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  {loading && <ZFLoader size={60} thickness={2} style={{ zIndex: 10 }} />}
                  <div
                    style={{ display: "none" }}
                    data-datakey={dataKey}
                    data-cy={CypressTestId.ADD_CLIENT_DATAKEY}
                  />
                  <Alert severity="info">{t("invitation.alert")}</Alert>
                  <TextField
                    fullWidth
                    name="to"
                    label={t("invitation.email")}
                    value={values.to}
                    onChange={handleChange}
                    error={touched.to && Boolean(errors.to)}
                    helperText={touched.to && errors.to}
                  />
                  <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                    <TextField
                      fullWidth
                      name="subject"
                      label={t("invitation.subject")}
                      value={values.subject}
                      onChange={handleChange}
                      error={touched.subject && Boolean(errors.subject)}
                      helperText={touched.subject && errors.subject}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="template-label">
                        {t("invitation.template")}
                      </InputLabel>
                      <Select
                        value={values.template || ""}
                        name="template"
                        labelId="template-label"
                        label={t("invitation.template")}
                        onChange={e => onTemplateChange(e, setFieldValue)}>
                        {templateList.map(t => (
                          <MenuItem key={t.id} value={t.id}>
                            {t.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <div>
                    <Typography color="GrayText" fontWeight={600} fontSize={14}>
                      {t("invitation.email")}
                    </Typography>
                    <ReactQuillStyled>
                      {/* @ts-ignore */}
                      <ReactQuill
                        value={values.message}
                        placeholder={t("invitation.emailPlaceholder")}
                        tabIndex={10}
                        modules={quillModules}
                        formats={quillFormats}
                        theme="snow"
                        onKeyUp={({ target }) =>
                          setFieldValue("message", target.innerHTML)
                        }
                      />
                      {errors.message && (
                        <FormHelperText error>{errors.message}</FormHelperText>
                      )}
                    </ReactQuillStyled>
                    <Box display="flex" justifyContent="flex-end" marginTop={1}>
                      <LoadingButton
                        variant="text"
                        disabled={!isValid}
                        loading={isSavingTemplate}
                        onClick={() => handleTemplateSave(values)}
                        sx={{ textTransform: "none" }}>
                        {t("invitation.saveEmailAsTemplate")}
                      </LoadingButton>
                    </Box>
                  </div>
                </Stack>
              </form>
            );
          }}
        </Formik>
      }
      submitButton={
        <LoadingButton
          type="submit"
          sx={{ width: { xs: "100%", sm: "auto" } }}
          variant="contained"
          color="primary"
          loading={isSubmitting}
          onClick={() => formRef.current?.handleSubmit()}>
          {t("clientField.next")}
        </LoadingButton>
      }
    />
  );
}

const ReactQuillStyled = styled.div`
  .ql-editor {
    min-height: 150px;
    padding: 12px 0;
  }
  .ql-container {
    padding: 10px 21px !important;
    border: 1px solid #dadada !important;
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid #dadada;
    border-radius: 8px;
    border-bottom: 0;
    box-sizing: border-box;
    ${GLOBAL_FONT_STYLE};
    padding: 8px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const mapDispatchToProps = (
  dispatch: Dispatch<AppState>,
  ownProps: { changeFilterProperty?: (activeFilter: string) => void },
) => {
  return {
    sendEmail: (data: SendEmailData) => dispatch(clientActions.sendEmail(data)),
    changeFilterProperty: (activeFilter: string) =>
      ownProps?.changeFilterProperty ||
      dispatch(clientActions.changeFilterProperty(activeFilter)),
  };
};

export default connect(null, mapDispatchToProps)(Questionnaire);
