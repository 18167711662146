import { CSSProperties } from "react";

const GenderIcon = ({ size, style }: { size: number; style?: CSSProperties }) => {
  const iconStyles = {
    width: size,
    height: size,
    ...style,
  };
  return (
    <svg
      style={iconStyles}
      viewBox="0 0 14 20"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor">
      <path d="M12.883.833a.79.79 0 0 1 .721.494.83.83 0 0 1 .063.303v3.187a.79.79 0 0 1-.784.797.79.79 0 0 1-.785-.797V3.55l-1.88 1.911a5.608 5.608 0 0 1 1.08 2.938l.004.09c.007.105.01.212.01.32l-.004-.206.003.148v.057a5.749 5.749 0 0 1-.188 1.444 5.57 5.57 0 0 1-1.707 2.768 5.428 5.428 0 0 1-2.81 1.306v1.653h.785a.79.79 0 0 1 .784.797.79.79 0 0 1-.784.797h-.784v.797a.79.79 0 0 1-.785.797.79.79 0 0 1-.784-.797v-.797h-.784a.79.79 0 0 1-.784-.797.79.79 0 0 1 .784-.797h.784v-1.653a5.438 5.438 0 0 1-3.097-1.577A5.577 5.577 0 0 1 .534 10.3l-.022-.085a3.941 3.941 0 0 1-.033-.13l-.02-.093a4.265 4.265 0 0 1-.064-.352 5.008 5.008 0 0 1-.018-.131l-.006-.054a5.557 5.557 0 0 1-.034-.443v-.029a5.624 5.624 0 0 1 .12-1.36c.036-.172.08-.34.131-.505l.017-.05c.75-2.342 2.968-3.963 5.484-3.839a5.413 5.413 0 0 1 3.024 1.107l1.88-1.91H9.746a.79.79 0 0 1-.784-.797.79.79 0 0 1 .784-.797Zm-7.06 3.991c-2.166 0-3.921 1.784-3.921 3.984 0 1.992 1.439 3.643 3.318 3.937a.223.223 0 0 0 .025.005l-.025-.005.116.017-.091-.012a3.884 3.884 0 0 0 3.325-1.16 4.03 4.03 0 0 0 0-5.577l-.156-.153a3.89 3.89 0 0 0-2.21-1.018 4.172 4.172 0 0 0-.382-.018Z" />
    </svg>
  );
};

export default GenderIcon;
