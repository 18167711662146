import React from "react";
import { useNavigate } from "react-router-dom";

export default function WorkoutsHeader(props: any) {
  const navigate = useNavigate();

  return (
    <header className="ibox-header no-border">
      <div className="workouts-header-left">
        <button
          className={props.btnClass || "btn btn-sm btn-success"}
          onClick={() => navigate(-1)}>
          <i className="fa fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="workouts-header-left m-l">
        <h4 className="text-center">{props.workoutPlanName}</h4>
        <p className="text-center">{props.dayName}</p>
      </div>

      <div className="workouts-header-right">
        {props.renderRightAction?.()}
      </div>
    </header>
  );
}
