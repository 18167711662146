import { Alert, Button } from "@mui/material";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { Link } from "react-router-dom";
import useResponsive, {
  BreakPointEnum,
  QueryEnum,
} from "../../../../hooks/useResponsive";
import { CSSProperties } from "react";
import { AlertTitle } from "@mui/material";
import { Routes } from "../../../../routes/routeMap";

function TrialAlert({
  trialDays,
  alertSx,
}: {
  trialDays: number;
  alertSx: CSSProperties;
}) {
  const { t } = useTranslation(TranslatorNS.APP);
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);
  return (
    <Alert
      severity="info"
      variant="outlined"
      sx={alertSx}
      action={
        <Link to={Routes.SETTINGS_PRICING}>
          <Button sx={{ color: "white" }} color="inherit" size="small">
            {t("subscriptionBar.chooseLink")}
          </Button>
        </Link>
      }>
      {isDesktop ? (
        t("subscriptionBar.youAreOnTrial", { count: trialDays })
      ) : (
        <AlertTitle sx={{ pt: 0, pb: 0, mt: "3px", mb: 0, fontSize: 10, color: "white" }}>
          {t("subscriptionBar.mobileTrial", { count: trialDays })}
        </AlertTitle>
      )}
    </Alert>
  );
}

function mapStateToProps(state: any) {
  return {
    trialDays: state.user?.payment?.trialLeft,
  };
}

export default connect(mapStateToProps)(TrialAlert);
