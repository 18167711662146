import {
  CLOSE_GLOBAL_MODAL,
  ModalActionTypes,
  GlobalModalState,
  OPEN_GLOBAL_MODAL,
} from "./reducerTypes";

const INITIAL_GLOBAL_MODAL_STATE: GlobalModalState = {};

export default function globalModal(
  state = INITIAL_GLOBAL_MODAL_STATE,
  action: ModalActionTypes<any>,
) {
  switch (action.type) {
    case OPEN_GLOBAL_MODAL:
      return {
        ...state,
        [action.payload.type]: action.payload.context,
      };
    case CLOSE_GLOBAL_MODAL:
      const { [action.payload]: _, ...rest } = state;
      return rest;
    default:
      return state;
  }
}
