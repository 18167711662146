import React, { ChangeEvent, Fragment } from "react";
import Checkbox from "@mui/material/Checkbox";
import { ZFCheckboxStyled } from "./ZFCheckboxStyled";
// @ts-ignore TODO: fix declare module with x.d.ts
import { Colors } from "../../styleguide";

interface Props {
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  component?: () => React.ReactElement;
  checked: boolean;
  iconSize?: number;
}


const renderIcon = (checked: boolean) =>
  checked ? (
    <svg fill={Colors.ACTIVE_COLOR} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><rect fill="#0062ff" height="20" rx="4" width="20"/><path d="m8.40288939 12.4842942-2.29371448-2.22664c-.25558549-.2481114-.66190059-.2481114-.9174858 0-.25558548.2481112-.25558548.6425446 0 .890656l2.74590387 2.6656064c.25558549.2481112.66845409.2481112.92403958 0l6.94667854-6.73717701c.2555852-.2481112.2555852-.64254479 0-.89065599-.2555855-.24811147-.6619006-.24811147-.9174858 0z" fill="#fff" fillRule="nonzero"/></g></svg>
  ) : (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect fill="#fff" fillRule="evenodd" height="18" rx="4" stroke="#ddd" strokeWidth="2" width="18" x="1" y="1"/></svg>
  );

const ZFCheckbox = ({
  title,
  subtitle,
  disabled,
  name,
  onChange,
  component,
  checked,
  iconSize = 17
}: Props) => {
  return (
    <ZFCheckboxStyled iconSize={iconSize}>
      <Checkbox
        disabled={disabled}
        icon={renderIcon(checked)}
        checkedIcon={renderIcon(checked)}
        // this basically doesn't allow there to be 2 checkboxes at the same time
        // but with a dynamic it does
        id={name || "checkbox"}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
        name={name}
        color="primary"
        checked={checked}
      />

      <label htmlFor={name || "checkbox"} style={{ cursor: "pointer" }}>
        {component ? (
          component()
        ) : (
          <Fragment>
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
          </Fragment>
        )}
      </label>
    </ZFCheckboxStyled>
  );
};

export default ZFCheckbox;
