import React, { useState } from "react";
import ReactPlayer from "react-player";
import { CircularProgress, Stack } from "@mui/material";

interface IProps {
  url: string;
}

type TMediaBubbleType = IProps & {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};

export default function MediaBubble(props: IProps) {
  const { url } = props;
  const isVideo = url.endsWith(".mp4");
  const [loading, setLoading] = useState(!isVideo);

  if (!isVideo) {
    return <AudioBubble url={url} loading={loading} setLoading={setLoading} />;
  }

  return <VideoBubble url={url} loading={loading} setLoading={setLoading} />;
}

const VideoBubble = ({ url, loading, setLoading }: TMediaBubbleType) => {
  return (
    <Stack
      maxWidth={360}
      justifyContent={"flex-end"}
      pb={1}
      overflow={"hidden"}
      position={"relative"}>
      {loading && (
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            height: "100%",
            width: "100%",
          }}>
          <CircularProgress />
        </Stack>
      )}
      <Stack
        borderRadius={1}
        sx={{ "-webkit-mask-image": "-webkit-radial-gradient(white, black)" }}
        overflow={"hidden"}>
        <ReactPlayer
          height={"230px"}
          width={"auto"}
          style={{ objectFit: "fill", maxWidth: loading ? 150 : 360 }}
          url={url}
          controls
          onReady={() => {
            if (!loading) {
              setLoading(false);
            }
          }}
        />
      </Stack>
    </Stack>
  );
};

const AudioBubble = ({ url, loading, setLoading }: TMediaBubbleType) => {
  return (
    <Stack borderRadius={1} overflow={"hidden"}>
      <ReactPlayer
        height={"50px"}
        width={"500px"}
        style={{ objectFit: "fill" }}
        url={url}
        controls
        onReady={() => {
          if (!loading) {
            setLoading(false);
          }
        }}
      />
    </Stack>
  );
};
