import { ROOT } from "../config";

export const GET_WORKOUT_PLAN = (id: number) => {
  return `${ROOT}/api/workout/plan/${id}`;
};

export const GET_WORKOUT_TEMPLATES = () => {
  return `${ROOT}/api/workout/templates`;
};

export const CREATE_WORKOUT_TEMPLATES = () => {
  return `${ROOT}/api/workout/templates`;
};

export const EDIT_WORKOUT_TEMPLATES = (id: number) => {
  return `${ROOT}/api/workout/templates/${id}`;
};

export const DELETE_WORKOUT_TEMPLATES = (id: number) => {
  return `${ROOT}/api/workout/templates/${id}`;
};

export const GET_WORKOUT_PLANS = (clientId: number) => {
  return `${ROOT}/api/workout/client/plans/${clientId}`;
};

export const GET_WORKOUT_PLAN_DAYS = (plan: number) => {
  return `${ROOT}/api/workout/client/${plan}/days`;
};

export const GET_WORKOUT_PLAN_DAY = (dayId: number) => {
  return `${ROOT}/api/workout/client/plan/day/${dayId}`;
};

export const APPLY_WORKOUT_TEMPLATE_TO_CLIENTS = (template: number) => {
  return `${ROOT}/api/workout/client/assign-plan/${template}`;
};

export const SAVE_WORKOUT_PLAN = (plan: number) => {
  return `${ROOT}/api/workout/client/save-workout/${plan}`;
};

export const SAVE_WORKOUT_DAY_PLAN = (plan: number) => {
  return `${ROOT}/api/workout/client/save-workout-day/${plan}`;
};

export const GET_EXERCISES = (
  q: string,
  page: number,
  muscleId: number,
  equipmentId: number,
) => {
  return `${ROOT}/api/exercises?q=${q}&page=${page}&equipmentId=${equipmentId}&muscleId=${muscleId}`;
};
export const getExercisesUrl = ({
  searchText = "",
  page = 1,
  limit = 25,
  muscleGroupId,
  equipmentId,
  useOwnExercisesOnly,
}: {
  searchText: string;
  page: number;
  limit: number;
  muscleGroupId?: number;
  equipmentId?: number;
  useOwnExercisesOnly?: boolean;
}) => {
  const equipmentQuery = equipmentId ? `&equipmentId=${equipmentId}` : "";
  const muscleGroupQuery = muscleGroupId ? `&muscleGroupId=${muscleGroupId}` : "";
  const useOwnExercisesQuery = useOwnExercisesOnly
    ? `&showOnlyOwn=${useOwnExercisesOnly}`
    : "";
  return `${ROOT}/api/exercises?q=${searchText}&page=${page}&limit=${limit}${equipmentQuery}${muscleGroupQuery}${useOwnExercisesQuery}`;
};

export const GET_TRACK_WEIGHT_HISTORY = (workoutId: number) => {
  return `${ROOT}/api/workout/mobile/getWeightLiftedHistory?workout=${workoutId}`;
};

export const GET_NEW_WEIGHT_LIFTED = (workoutId: number) => {
  return `${ROOT}/api/workout/mobile/createWeightLifted/${workoutId}`;
};

export const POST_WEIGHT_LIFTED = () => {
  return `${ROOT}/api/workout/mobile/insertWeightLifted`;
};

export const CREATE_WORKOUT_PLAN = (dayId: number, urlParentId: number) => {
  return `${ROOT}/api/workout/mobile/createWorkouts/${dayId}${urlParentId}`;
};

export const DELETE_WORKOUT_PLAN = (workoutPlanId: number) => {
  return `${ROOT}/workout/${workoutPlanId}`;
};

export const CREATE_PLAN = () => {
  return `${ROOT}/workout`;
};

export const EDIT_WORKOUT_DAY = (plan: number) => {
  return `${ROOT}/workout/${plan}/update`;
};

export const UPDATE_WORKOUT = (plan: number) => {
  return `${ROOT}/workout/${plan}/update`;
};

export const UPDATE_WORKOUT_PLAN = (plan: number) => {
  return `${ROOT}/workout/${plan}`;
};

export const EXPORT_WORKOUT_PLAN_AS_PDF = (id: number) => {
  return `${ROOT}/pdf/exportPlansPdfWorkout/${id}`;
};

export const ADD_DAY = (id: number) => {
  return `${ROOT}/workout/day/${id}`;
};

export const CLONE_DAY = (id: number) => {
  return `${ROOT}/workout/day/${id}/clone`;
};

export const SAVE_WORKOUT_AS_TEMPLATE = (id: number) => {
  return `${ROOT}/workout/save-as-template/${id}`;
};
