import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MobileDatePicker } from "@mui/lab";
import { addMonths } from "date-fns";
import { SlideTransition } from "../SlideTransition";

interface Props {
  open: boolean;
  handleClose: VoidFunction;
  onConfirm: (date: Date) => void;
  title: string;
  description: string;
  isSubmitting: boolean;
}
export default function DateConfirmModal({
  open,
  handleClose,
  onConfirm,
  title,
  description,
  isSubmitting,
}: Props) {
  const { t } = useTranslation("clientOverview");
  const [date, setDate] = useState<Date | null>(addMonths(new Date(), 1));
  return (
    <Dialog
      open={open}
      TransitionComponent={SlideTransition}
      keepMounted
      onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack mt={1}>
          <Typography variant="body1">{description}</Typography>
        </Stack>
        <Stack mt={2}>
          <MobileDatePicker
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={setDate}
            renderInput={params => <TextField {...params} />}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose}>
          {t("confirmNeed.no")}
        </Button>
        <Button
          disabled={isSubmitting}
          variant="contained"
          onClick={() => {
            if (!date) {
              return alert("Please select a date");
            }
            onConfirm(date);
          }}>
          {isSubmitting ? (
            <CircularProgress size={24} sx={{ color: "#fff" }} />
          ) : (
            t("confirmNeed.yes")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
