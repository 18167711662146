import * as Yup from "yup";

export const CreateSubscriptionSchema = Yup.object().shape({
  currency: Yup.string().required(),
  months: Yup.number().required(),
  startPaymentDate: Yup.date().nullable(),
  includeTerms: Yup.bool(),
  chargeUpfrontImmediately: Yup.bool(),
  recurringFee: Yup.number().when("months", {
    is: (val: number) => val !== 0,
    then: Yup.number().min(10).required(),
    otherwise: Yup.number().nullable(),
  }),
  upfrontFee: Yup.number()
    .nullable()
    .when("recurringFee", {
      is: (recurringFee: number) => !recurringFee,
      then: Yup.number().required(),
    }),
  terms: Yup.string().when("includeTerms", {
    is: (val: boolean) => val,
    then: Yup.string().required(),
  }),
});
