import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "@mui/material/styles";

interface Props {
  title: string;
  subtitle: string;
  isChecked: boolean;
  onCheck: (isChecked: boolean) => void;
}

const TemplateCheckboxItem = ({ title, subtitle, isChecked, onCheck }: Props) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      sx={{ m: 0 }}
      control={<Checkbox checked={isChecked} onChange={e => onCheck(e.target.checked)} />}
      label={
        <Box sx={{ display: "flex", flexDirection: "column", ml: 1 }}>
          <Typography variant="body1" display="block">
            {title}
          </Typography>
          <Typography variant="caption" display="block" color={theme.palette.grey[600]}>
            {subtitle}
          </Typography>
        </Box>
      }
    />
  );
};

export default TemplateCheckboxItem;
