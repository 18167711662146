import { ApolloError } from "@apollo/client";
import { Alert } from "@mui/lab";
import { AlertTitle, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
  error: ApolloError | undefined;
}

export default function RHFGraphqlError(props: Props) {
  const { error } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!error);
  }, [error]);

  if (!error) return null;

  const { message } = error;
  const isArrErrors = error.graphQLErrors?.[0]?.extensions?.validation as {
    [key: string]: string[];
  };

  if (!open) return null;

  if (isArrErrors) {
    return (
      <Stack>
        {Object.entries(isArrErrors).map(([key, errors]) => {
          return (
            <Stack key={key}>
              {errors.map(anError => (
                <Alert key={anError} sx={{ my: 1 }} variant={"filled"} severity="error">
                  <Typography> {key} </Typography>
                  {anError}
                </Alert>
              ))}
            </Stack>
          );
        })}
      </Stack>
    );
  }

  return (
    <Stack sx={{ maxWidth: "100%", my: 1 }}>
      <Alert variant={"filled"} severity="error">
        <Stack maxWidth={"27rem"} overflow={"hidden"} whiteSpace={"normal"}>
          <AlertTitle sx={{ fontWeight: "bold" }} color={"white"}>
            Server error
          </AlertTitle>
          {message}
        </Stack>
      </Alert>
    </Stack>
  );
}
