import { ROOT } from "./config";

export const LOGIN = () => {
  return `${ROOT}/login_check`;
};
export const FORGOT_PASSWORD = () => {
  return `${ROOT}/auth/api/forgot-password`;
};
export const SAVE_PASSWORD = () => {
  return `${ROOT}/auth/api/save-password`;
};
export const SIGNUP = () => {
  return `${ROOT}/auth/api/sign-up`;
};
export const FINISH_SIGNUP = () => {
  return `${ROOT}/auth/api/finish-sign-up`;
};
export const GOOGLE_LOGIN_URL = () => {
  return `${ROOT}/social/connect/google`;
};
export const FACEBOOK_LOGIN_URL = () => {
  return `${ROOT}/social/connect/facebook`;
};
export const INTERACTIVE_TOKEN = (token: string | undefined) => {
  return `${ROOT}/api/public/trainer/login/interactive/${token}`;
};
