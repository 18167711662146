import { useLenusTransferContext } from "../../../../providers/LenusTransferProvider";
import { Stack } from "@mui/material";
import SkeletonContainer from "../../../../containers/SkeletonContainer";
import LoginForm from "./LoginForm";
import NavigationSteps from "../NavigationSteps";
import { useState } from "react";
import LoginIntro from "./LoginIntro";
import LoginSuccess from "./LoginSuccess";
import { LenusPlatformTypeEnum } from "../../../../generated/graphql";

export type LenusLoginFormProps = {
  email: string | undefined;
  password: string | undefined;
  platform: LenusPlatformTypeEnum | null;
};

export enum LenusLoginStepEnum {
  Intro = 0,
  Login = 1,
  Success = 2,
}

export default function LenusLoginStep() {
  const { loading } = useLenusTransferContext();
  const [step, setStep] = useState(LenusLoginStepEnum.Intro);
  const [tempLenusToken, setTempLenusToken] = useState<string | undefined>(undefined);
  const { setLenusToken } = useLenusTransferContext();

  const defaultValues = {
    email: "",
    password: "",
    platform: LenusPlatformTypeEnum.Eu,
  };

  const handleNext = () => setStep(step + 1);
  const handleBack = () => !!step && setStep(step - 1);

  const renderStep = () => {
    switch (step) {
      case LenusLoginStepEnum.Login:
        return (
          <LoginForm
            defaultValues={defaultValues}
            setTempLenusToken={token => {
              setTempLenusToken(token);
              handleNext();
            }}
            handleBack={handleBack}
          />
        );
      case LenusLoginStepEnum.Success:
        return <LoginSuccess handleNext={() => setLenusToken(tempLenusToken)} />;
      default:
        return <LoginIntro handleNext={handleNext} />;
    }
  };

  return (
    <>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        width={"100vw"}>
        <SkeletonContainer
          loading={loading}
          sx={{
            width: { xs: "60vw", sm: 400 },
            height: { xs: "65vh", sm: 475 },
            bgcolor: "grey.900",
          }}>
          {renderStep()}
        </SkeletonContainer>
      </Stack>
      <NavigationSteps />
    </>
  );
}
