import { ROOT } from "../config";

export const GET_PRIVATE_USER = () => {
  return `${ROOT}/api/private/user`;
};

export const GET_UNREAD_MESSAGES = () => {
  return `${ROOT}/api/private/user/message-count`;
};

export const LENUS_LOGIN = () => {
  return `https://graphql.zenfitapp.com/lenus/login`;
};
