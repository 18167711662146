import { ROOT } from "../config";
import { LocaleEnum } from "../../generated/graphql";

export enum DefaultMessageTypeEnum {
  TYPE_PAYMENT_MESSAGE_EMAIL = 1,
  TYPE_PDF_MEAL_PLANS_EMAIL = 2,
  TYPE_PDF_MEAL_PLANS_INTRO = 3,
  TYPE_WELCOME_EMAIL = 4,
  TYPE_PLANS_READY_EMAIL = 5,
  TYPE_BOUNCED_LEAD_EMAIL = 6,
  TYPE_BODY_PROGRESS_FEEDBACK = 7,
  TYPE_PAYMENT_FAILED_MESSAGE = 8,
  TYPE_CLIENT_ENDING_MESSAGE = 9,
  TYPE_UPDATED_WORKOUT = 10,
  TYPE_UPDATED_MEAL = 11,
  TYPE_MISSING_CHECKIN = 12,
  TYPE_WELCOME_MESSAGE = 13,
  TYPE_CHECKOUT_TERMS = 14,
  TYPE_CHAT_MESSAGE_PROGRESS = 15,
  LenusTransferTemplate = 16,
}

export const GET_DEFAULT_MESSAGES = (type: DefaultMessageTypeEnum) => {
  return `${ROOT}/api/trainer/get-default-messages/${type}`;
};

export const GET_DEFAULT_MESSAGES_LOCAL = (
  type: number,
  clientId: number,
  local: string,
) => {
  return `${ROOT}/api/trainer/get-default-messages/${type}/${clientId}/${local}`;
};

export const GET_DEFAULT_MESSAGES_LOCAL_QUEUE = (
  type: number,
  clientId: number,
  locale: string,
  datakey: string,
) => {
  return `${ROOT}/api/trainer/get-default-messages/${type}/${clientId}/${locale}/${datakey}`;
};

export const CREATE_DEFAULT_MESSAGE = () => {
  return `${ROOT}/api/trainer/set-default-message`;
};

export const UPDATE_DEFAULT_MESSAGE = (id: number) => {
  return `${ROOT}/api/trainer/update-default-message/${id}`;
};

export const DELETE_DEFAULT_MESSAGE = (id: number) => {
  return `${ROOT}/api/trainer/delete-default-message/${id}`;
};

export interface IDefaultMessage {
  id: number;
  title: string | null;
  subject: string | null;
  message: string | null;
  locale: LocaleEnum;
  type: DefaultMessageTypeEnum;
  placeholders: {
    client: string;
    trainer: string;
  };
}
