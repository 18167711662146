import React, { Component } from "react";
import {
  Button,
  IconButton,
  Stack,
  Divider,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import VoiceRecorder from "../voice-recorder";
import ContentEditable from "./content-editable";
import _ from "lodash";
import EmojiPicker from "emoji-picker-react";
import DropOptions from "./DropOptions";
import { browser, isMobileDevice } from "../../utils";
import ModalChatTemplates from "../../../Modals/modal-chat-templates";
import { withTranslation } from "react-i18next";
import ZFVideoRecorder from "../../../Chat/components/ZFVideoRecorder";
import Iconify from "../../../../components/_MUI/Iconify";
import { TranslatorNS } from "../../../../i18n/const";

class SendField extends Component {
  isSending = false;
  constructor(props) {
    super(props);
    this.contentInput = React.createRef();
    this.state = {
      html:
        localStorage.unSentMsg === undefined
          ? ""
          : parseInt(localStorage.unSentClientId) === props.clientId
          ? localStorage.unSentMsg
          : "",
      showEmojis: false,
      selectedVideo: null,
      selectVoice: null,
      voiceRecorderOpen: false,
      recodedVoice: null,
      videoRecoderOpen: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.openVoiceRecorder = this.openVoiceRecorder.bind(this);
    this.closeVoiceRecorder = this.closeVoiceRecorder.bind(this);
    this.selectVoice = this.selectVoice.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.clientId !== this.props.clientId ||
      this.state.html !== localStorage.unSentMsg
    ) {
      this.setState({
        html:
          localStorage.unSentMsg === undefined
            ? ""
            : parseInt(localStorage.unSentClientId) === nextProps.clientId
            ? localStorage.unSentMsg
            : "",
      });
    }
  }

  onClickEmoji = (code, emojiObject) => {
    const emoji = emojiObject.emoji;
    const contentInput = this.props.contentInput.current || {};
    if (contentInput.paste) {
      localStorage.unSentMsg === undefined
        ? localStorage.setItem("unSentMsg", emoji)
        : localStorage.setItem("unSentMsg", localStorage.unSentMsg + emoji);
      localStorage.setItem("unSentClientId", this.props.clientId);
      contentInput.paste(emoji);
    }
    this.setState({
      showEmojis: false,
    });
  };

  selectVideo = file => {
    this.setState({ selectedVideo: file, videoRecoderOpen: false }, () => {
      this.handleSend();
    });
  };

  openVoiceRecorder = () => {
    this.setState({ voiceRecorderOpen: true });
  };

  closeVoiceRecorder = () => {
    this.setState({ voiceRecorderOpen: false });
  };

  selectVoice = file => {
    this.setState({ selectVoice: file, voiceRecorderOpen: false }, () => {
      this.handleSend();
    });
  };

  render() {
    const { html, selectedVideo, voiceRecorderOpen } = this.state;
    const { isSending, clientId, locale } = this.props;

    const renderVideoRecorderWrapper = ({ isRecorderOpen, renderActionButton }) => (
      <ZFVideoRecorder
        isOpen={isRecorderOpen}
        onClose={() => this.setState({ videoRecoderOpen: false })}
        onOpen={() => this.setState({ videoRecoderOpen: true })}
        isMobile={isMobileDevice}
        isWidget={true}
        selectVideo={this.selectVideo}
        renderActionButton={renderActionButton}
        isSending={isSending}
      />
    );

    // Disable Video Capture for unsupported browsers
    const isMediaRecordSupport = !!window.MediaRecorder;

    const renderVideoDropItem = () => {
      if (this.props.video) {
        return renderVideoRecorderWrapper({
          isRecorderOpen: false,
          renderActionButton: (toggleOpen, hiddenInput, handleVideoDirect) => (
            <Stack>
              <Stack flexDirection={"row"}>
                <DropOptionsListItem
                  sx={{ ml: "-6px" }}
                  onClick={handleVideoDirect}
                  disabled={isSending}>
                  <Stack sx={{ mr: "-3px" }}>{hiddenInput}</Stack>
                  <span>
                    {this.props.t("captureVideo.uploadVideoWidget", {
                      ns: TranslatorNS.CHAT,
                    })}
                  </span>
                </DropOptionsListItem>
              </Stack>

              {!isMobileDevice && (
                <Stack flexDirection={"row"}>
                  <DropOptionsListItem onClick={toggleOpen} disabled={isSending}>
                    <Iconify icon="uil:video" />
                    <span>{this.props.t("captureVideo.video")}</span>
                  </DropOptionsListItem>
                </Stack>
              )}
            </Stack>
          ),
        });
      }

      return null;
    };

    const isVoiceDisabled = !isMobileDevice && !isMediaRecordSupport;
    const renderVoiceDropItem = () => {
      if (isVoiceDisabled) {
        const warningTitle =
          browser.name === "Safari"
            ? this.props.t("sendField.pleaseUseChrome")
            : this.props.t("sendField.thisBrowserDoesNotVoice");

        return (
          <Tooltip title={warningTitle} position="top" trigger="mouseenter" size="big">
            <Stack>
              <DropOptionsListItem disabled>
                <Iconify icon="uil:microphone" />
                <span>{this.props.t("sendField.voice")}</span>
              </DropOptionsListItem>
            </Stack>
          </Tooltip>
        );
      }

      return (
        <DropOptionsListItem onClick={this.openVoiceRecorder} disabled={isSending}>
          <Iconify icon="uil:microphone" />
          <span>{this.props.t("sendField.sendVoiceChat")}</span>
        </DropOptionsListItem>
      );
    };

    const DefaultMessagesWrapper = ({ label }) => (
      <ModalChatTemplates
        clientId={clientId}
        label={label}
        inputRef={this.props.contentInput}
        defaultMessageType={this.props.messageType ? this.props.messageType.id : 15}
      />
    );

    const renderDefaultMessagesDropItem = () => (
      <DefaultMessagesWrapper
        label={toggleOpen => (
          <DropOptionsListItem onClick={toggleOpen} disabled={isSending}>
            <Iconify icon="uil:file-check-alt" />
            <span>{this.props.t("sendField.templates")}</span>
          </DropOptionsListItem>
        )}
      />
    );

    const emoji = this.state.showEmojis ? (
      <ClickAwayListener
        onClickAway={this.state.showEmojis ? this.handleToggleEmoji : null}>
        <EmojiPickerContainer style={{ display: this.state.showEmojis ? "" : "none" }}>
          <EmojiPicker onEmojiClick={this.onClickEmoji} />
        </EmojiPickerContainer>
      </ClickAwayListener>
    ) : null;

    const isSendButtonDisabled = !((html || "").trim() || selectedVideo) || isSending;

    return (
      <>
        <Divider />

        <RootStyle>
          {/* Serves as top level recorder view, should be the only one openable */}
          {renderVideoRecorderWrapper({
            isRecorderOpen: this.state.videoRecoderOpen,
            renderActionButton: () => null,
          })}

          {voiceRecorderOpen ? (
            <VoiceRecorder
              onSelectVoice={this.selectVoice}
              onClose={this.closeVoiceRecorder}
            />
          ) : (
            <StyledForm
              method="POST"
              onSubmit={event => {
                event.preventDefault();
                this.handleSend();
              }}>
              <Stack
                gap="4px"
                direction="row"
                alignItems="flex-end"
                sx={{ width: "100%" }}>
                <Stack gap="4px" direction="row">
                  {Boolean(html) ? (
                    <DropOptions
                      isVideoOpen={this.state.videoRecoderOpen}
                      items={[
                        renderVoiceDropItem(),
                        renderVideoDropItem(),
                        renderDefaultMessagesDropItem(),
                      ]}
                      label={toggleOpen => (
                        <StyledIconButton onClick={toggleOpen}>
                          <Iconify icon="uil:plus" />
                        </StyledIconButton>
                      )}
                    />
                  ) : (
                    <>
                      {!isVoiceDisabled && (
                        <Tooltip title={this.props.t("sendField.voice")} placement="top">
                          <Stack>
                            <StyledIconButton
                              onClick={this.openVoiceRecorder}
                              disabled={isSending}>
                              <Iconify icon="uil:microphone" />
                            </StyledIconButton>
                          </Stack>
                        </Tooltip>
                      )}

                      {Boolean(this.props.video) &&
                        renderVideoRecorderWrapper({
                          isRecorderOpen: false,
                          renderActionButton: (toggleOpen, hiddenInput) => (
                            <>
                              {hiddenInput}
                              {!isMobileDevice && (
                                <Tooltip
                                  title={this.props.t("sendField.video")}
                                  placement="top">
                                  <Stack>
                                    <StyledIconButton
                                      onClick={toggleOpen}
                                      disabled={isSending}>
                                      <Iconify icon="uil:video" />
                                    </StyledIconButton>
                                  </Stack>
                                </Tooltip>
                              )}
                            </>
                          ),
                        })}

                      <DefaultMessagesWrapper
                        label={toggleOpen => (
                          <Tooltip
                            title={this.props.t("sendField.templates")}
                            placement="top">
                            <Stack>
                              <StyledIconButton onClick={toggleOpen} disabled={isSending}>
                                <Iconify icon="uil:file-check-alt" />
                              </StyledIconButton>
                            </Stack>
                          </Tooltip>
                        )}
                      />
                    </>
                  )}
                </Stack>

                <InputWrapper>
                  <ContentEditable
                    placeholder={this.props.t("sendField.typeAMessage")}
                    className="form-control"
                    content={html}
                    onChange={this.handleChange}
                    ref={this.props.contentInput}
                    sendMsg={this.handleSend}
                    isOpen={this.props.isOpen}
                    canSubmit={!isSending}
                  />

                  <EmojiIconButton onClick={this.handleToggleEmoji}>
                    <Iconify icon="eva:smiling-face-fill" />
                  </EmojiIconButton>
                  {emoji}
                </InputWrapper>

                <StyledIconButton disabled={isSendButtonDisabled} sx type="submit">
                  {Boolean(isSending) ? (
                    <i className="fa fa-spinner fa-spin" />
                  ) : (
                    <Iconify icon="uil:message" />
                  )}
                </StyledIconButton>
              </Stack>
            </StyledForm>
          )}
        </RootStyle>
      </>
    );
  }

  handleToggleEmoji = () => {
    this.setState({ showEmojis: !this.state.showEmojis });
  };
  handleSend() {
    const { html, selectedVideo, selectVoice } = this.state;
    if ((html || "").trim() || selectedVideo || selectVoice) {
      const { sendMessage, resolveMarkAsReadTask, clientId } = this.props;
      this.isSending = true;
      sendMessage &&
        sendMessage(html.trim().replace(/\n/g, "<br>"), selectedVideo, selectVoice);
      localStorage.removeItem("unSentMsg");
      resolveMarkAsReadTask(clientId);

      // reset message state
      this.setState({ html: "", selectedVideo: null, selectVoice: null });
      this.contentInput?.current?.flush();
    }
  }
  handleChange(value) {
    this.setState({ html: value });
    if (this.props.clientId) {
      localStorage.setItem("unSentClientId", this.props.clientId);
      localStorage.setItem("unSentMsg", value);
    }
    _.isFunction(this.props.onType) && this.props.onType();
  }
}

SendField.defaultProps = {
  video: true,
};

const RootStyle = styled("div")(() => ({
  display: "flex",
  position: "relative",
  padding: "12px 12px",
  boxShadow: "0 8px 16px 0 rgba(145, 158, 171, 0.16)",
  backgroundColor: "white",
}));

const StyledForm = styled("form")(() => ({
  width: "100%",
  display: "flex",
}));

const StyledIconButton = styled(IconButton)(() => ({
  fontSize: 24,
  color: "#0062ff",

  "&:disabled": {
    color: "rgba(145, 158, 171, 0.8)",
  },
}));

const InputWrapper = styled("div")(() => ({
  width: "100%",
  position: "relative",
}));

const EmojiIconButton = styled(StyledIconButton)(() => ({
  position: "absolute",
  bottom: 0,
  right: 2,
}));

const EmojiPickerContainer = styled("div")(() => ({
  position: "absolute",
  bottom: 40,
  right: 0,
}));

const DropOptionsListItem = styled(Button)(({ disabled }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  position: "relative",
  gap: "16px",
  cursor: "pointer",
  padding: "12px 16px",
  borderRadius: "8px",
  fontSize: "14px",
  color: "#637381",
  backgroundColor: "transparent",
  width: "100%",
  ...(disabled
    ? {
        opacity: 0.5,
        cursor: "not-allowed",
      }
    : {}),

  ">.iconify": {
    color: "#637381",
    fontSize: 24,
  },
  "& svg": {
    color: "#637381",
    ml: 1,
  },

  "&:hover": disabled
    ? {}
    : {
        fontWeight: 600,
        color: "#0062ff",
        backgroundColor: "rgb(0, 98, 255, 0.08)",

        ">.iconify": {
          color: "#0062ff",
        },
        "& svg": {
          color: "#0062ff",
        },
      },
}));

export default withTranslation("chatWidget", { withRef: true })(SendField);
