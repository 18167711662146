import mappet from "mappet";
import { IPaymentInfo } from "../../routes/ClientOverview/type";
import { DIET_PREFERENCES_ARR } from "../../helpers/clientFoodPreference";
import { format } from "date-fns";

const formatPaymentInfo = (payments: IPaymentInfo[]) => payments[0];

const formatTags = (tags: string[]) =>
  tags.map(tag => ({ label: tag.toUpperCase(), value: tag }));

const formatFoodPreferences = (
  clientFoodPreferences: string[],
): { label: string; value: string }[] | undefined => {
  return clientFoodPreferences
    ? Object.entries(clientFoodPreferences)
        .filter(([key, val]) => !DIET_PREFERENCES_ARR.includes(key) && val)
        .map(([label]) => ({
          value: label,
          label: label.toUpperCase(),
        }))
    : undefined;
};

const formatDietaryPreference = (clientFoodPreferences: string[]) => {
  return clientFoodPreferences
    ? Object.entries(clientFoodPreferences)
        .filter(([key, val]) => DIET_PREFERENCES_ARR.includes(key) && val)
        .reduce((p, [key]) => key, "")
    : undefined;
};

const formatEatingDisorder = (eatingDisorders: { [key: string]: boolean }) => {
  return eatingDisorders
    ? Object.entries(eatingDisorders)
        .filter(([_, val]) => val)
        .map(([key]) => key)
    : [];
};

/**
 * From request format to redux state
 */
const schema = {
  id: "id",
  name: "name",
  firstname: "firstName",
  email: "email",
  isActive: "active",
  isDemoClient: "demoClient",
  photo: "photo",
  tags: { path: "tags", modifier: formatTags },
  startDate: "startDate.date",
  endDate: "endDate.date",
  dayTrackProgress: "dayTrackProgress",
  age: "info.age",
  birthday: {
    path: "info.birthday.date",
    modifier: (source: string) => {
      return source?.replace(" 00:00:00.000000", "");
    },
  },
  locale: "info.locale",
  phone: "info.phone",
  updateMealSchedule: "info.updateMealSchedule",
  updateWorkoutSchedule: "info.updateWorkoutSchedule",
  notes: "info.notes",
  startWeight: "info.startWeight",
  goalWeight: "info.goalWeight",
  height: "info.height",
  inches: "info.inches",
  hideKcals: "hideKcals",
  gender: "info.gender",
  measuringSystem: "info.measuringSystem",
  feet: "info.feet",
  activityLevel: "info.activityLevel",
  numberOfMeals: "info.numberOfMeals",
  injuries: "info.injuries",
  experienceLevel: "info.experienceLevel",
  experience: "info.experience",
  exercisePreferences: "info.exercisePreferences",
  workoutLocation: "info.workoutLocation",
  workoutsPerWeek: "info.workoutsPerWeek",
  motivation: "info.motivation",
  clientFoodPreferences: "info.clientFoodPreferences",
  dietaryPreference: {
    path: "info.clientFoodPreferences.allergies",
    modifier: formatDietaryPreference,
  },
  excludedIngredients: {
    path: "info.clientFoodPreferences.allergies",
    modifier: formatFoodPreferences,
  },
  eatingDisorder: {
    path: "info.clientFoodPreferences.eatingDisorder",
    formatEatingDisorder,
  },
  lifestyle: "info.lifestyle",
  pal: "info.pal",
  goalType: "info.goalType",
  trainerId: "trainer.id",
  isMfpActivated: "info.mfpActivated",
  paymentInfo: { path: "payments", modifier: formatPaymentInfo },
  paymentLogs: "paymentLogs",
  customQuestions: "customQuestions",
  trackProgressFrequency: {
    path: "trackProgressFrequency",
    modifier: (source: string) => Number(source),
  },
  status: "status",
  reminders: "reminders",
  queue: "queue",
};

const mapper = mappet(schema);

// TODO: make client payload type
export default function clientSchema(source: any) {
  return mapper(source);
}

const formatQuestions = (questions: any) =>
  // @ts-ignore
  questions.reduce((acc, i) => ({ ...acc, [i.id]: i.answer || "" }), {});

const formatTrainer = (trainerId: string) => ({ id: trainerId });

const formatTagsStrings = (tags: any[]) => tags.map(tag => tag.label);

const formatClientFoodPreferences = (dietaryPreference: string, source: any) => {
  const disorder = source?.eatingDisorder
    ? Object.entries(source.eatingDisorder)
        .filter(([_, v]) => v)
        .map(([k]) => k)
    : [];
  const excluded = (source?.excludedIngredients || [])
    .map((ingredient: { label: string; value: string }) => ingredient.value)
    .filter(Boolean);

  if (dietaryPreference) {
    return [dietaryPreference, ...disorder, ...excluded];
  }

  return [...disorder, ...excluded];
};

/**
 * From redux state to request format
 */
const clientUpdateSchema = {
  activityLevel: "activityLevel",
  birthday: {
    path: "birthday",
    modifier: (source: string) => {
      return Boolean(source) ? format(new Date(source), "yyyy-MM-dd") : undefined;
    },
  },
  allergies: "allergies",
  clientFoodPreferences: {
    path: "dietaryPreference",
    modifier: formatClientFoodPreferences,
  },
  dayTrackProgress: "dayTrackProgress",
  endDate: "endDate",
  email: "email",
  exercisePreferences: "exercisePreferences",
  experience: "experience",
  experienceLevel: "experienceLevel",
  feet: "feet",
  gender: "gender",
  goalWeight: "goalWeight",
  height: "height",
  id: "id",
  inches: "inches",
  injuries: "injuries",
  lifestyle: "lifestyle",
  measuringSystem: "measuringSystem",
  mfpActivated: "mfpActivated",
  motivation: "motivation",
  name: "name",
  note: "note",
  numberOfMeals: "numberOfMeals",
  phone: "phone",
  questions: { path: "customQuestions", modifier: formatQuestions },
  startDate: "startDate",
  startWeight: "startWeight",
  tags: { path: "tags", modifier: formatTagsStrings },
  trainer: { path: "trainerId", modifier: formatTrainer },
  updateMealSchedule: "updateMealSchedule",
  updateWorkoutSchedule: "updateWorkoutSchedule",
  workoutLocation: "workoutLocation",
  workoutsPerWeek: "workoutsPerWeek",
  hideKcals: "hideKcals",
  trackProgressFrequency: "trackProgressFrequency",
};

const mapperUpdate = mappet(clientUpdateSchema);

// TODO: make client payload type
export function updateClientSchema(source: any) {
  return mapperUpdate(source);
}
