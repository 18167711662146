import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer, { initialState } from "./rootReducer";
import resetClientState from "./middleware/resetClientStateMiddleware";
import crashReporter from "./middleware/crashReporter";

const enhancers = [];

const middleware = [thunk, resetClientState, crashReporter];

if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

// TODO: for now ignore initstate type, fix when upgrading react-redux
// @ts-ignore
const store = createStore(rootReducer, initialState, composedEnhancers);

export type AppState = ReturnType<typeof rootReducer>;

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
