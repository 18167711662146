import * as rudderanalytics from "rudder-sdk-js";

type CoachTrackingEventNames =
  | "Mealplan Updated"
  | "Add New Client Modal Started"
  | "Task Resolved"
  | "Task Ignored"
  | "Create Step Completed"
  | "Options Step Completed"
  | "Invitation Step Completed"
  | "Questionnaire Step Completed"
  | "Subscription Step Clicked"
  | "Add Client Modal Closed"
  | "PowerTool Opened"
  | "Start Meal Plan Guide"
  | "Visit Demo Client"
  | "Create Meal Plan"
  | "Create Workout Plan"
  | "Clicked Download App"
  | "Cicked View Progress"
  | "Client Activated";

type ClientTrackingEventNames =
  | "Login Created"
  | "Photo Added"
  | "Photo Removed"
  | "Mobile App link Clicked"
  | "Diet Preferences Added"
  | "Workout Preferences Added"
  | "General Information Added"
  | "Questionnaire Submitted"
  | "Weight Goal Added";

type TrackingContexts =
  | "Add Client Modal"
  | "Client List"
  | "Client Page Task List"
  | "Chat Widget"
  | "Demo Client"
  | "Meal Plan"
  | "Workout Plan"
  | "Onboarding Wizard"
  | "Client Activation";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const isProduction =
  process.env.NODE_ENV === "production" &&
  window.location.hostname === "app.zenfitapp.com";

export const track = (
  eventName: ClientTrackingEventNames | CoachTrackingEventNames,
  context: TrackingContexts,
  properties?: Record<string, any>,
) => {
  if (isProduction) {
    rudderanalytics.track(eventName, { context, platform: "web", ...properties });
  } else {
    console.log(eventName, { context, platform: "web", ...properties });
  }
};

export const identify = rudderanalytics.identify;

export const page = () => {
  if (isProduction) {
    rudderanalytics.page();
  } else {
    console.log("page view event");
  }
};

export const setAnonymousId = rudderanalytics.setAnonymousId;
