export const getFormDataFromObject = (obj: {
  [key: string]: string | number | Date | boolean | null | undefined;
}) => {
  const formData = new FormData();

  Object.entries(obj)
    .filter(([_, value]) => value || value === 0)
    .map(([key]) => formData.append(key, String(obj[key])));

  return formData;
};

export const getErrorMessage = (error: any) => {
  if (error?.response?.data?.error_list) {
    return Object.entries(error.response.data.error_list).map(([key, value]) => {
      return `${key}: ${value}`;
    });
  } else if (error.request) {
    return "Server is not responding";
  } else {
    return "Something went wrong";
  }
};
