import styled from "styled-components";
import { Colors } from "../../../../styleguide";
import { DEVICE } from "../../../../helpers/devices";
import { PRIMARY } from "../../../../theme/palette";

export const ZFProgressStyled = styled.div<{ primaryColor: string }>`
  padding: 1.5rem 2rem;
  background: #fcfcfc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
  .zf-progress {
    width: 93%;
    align-items: center;
    display: flex;
    span {
      padding: 10px 1px;
      &:first-child {
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-radius: 0 2px 2px 0;
      }
      &.span_active {
        cursor: pointer;
        &:hover > .zf-progressItem.active {
          background-color: ${({ primaryColor }) => primaryColor || PRIMARY.main};
          opacity: 0.5;
        }
      }
    }
    .zf-progressItem {
      background: ${Colors.INACTIVE_COLOR};
      height: 8px;
      display: flex;
      flex-direction: row;
      &.active {
        background-color: ${({ primaryColor }) => primaryColor || PRIMARY.main};
        &:hover {
          background-color: ${({ primaryColor }) => primaryColor || PRIMARY.main};
        }
      }
    }
  }

  .counts {
    font-size: 16px;
    color: #92929d;
  }

  ${DEVICE.mobile} {
    padding: 1rem;
    .zf-progress {
      width: 80%;
    }
    .counts {
      min-width: 28px;
    }
  }
`;
