import { GetKGFromLBS } from "./measurementHelper";
import { Gender } from "../interfaces/gender";
import { MeasureSystem } from "../interfaces/measureSystem";
import { GoalType } from "../interfaces/goalType";

export const DEFAULT_GRAM_LABEL = "g";

export const computeKcals = (
  gender: Gender,
  weight: number,
  height: number,
  age: number,
  pal: number,
  goalType: GoalType,
  gramsPerWeek: number,
  measureSystem: MeasureSystem,
) => {
  let bmr;
  let kcals;
  const kgWeight =
    measureSystem === MeasureSystem.IMPERIAL ? GetKGFromLBS(weight) : weight;

  if (gender === Gender.MALE) {
    bmr = Math.round(88.362 + 13.397 * kgWeight + 4.799 * height - 5.677 * age);
  } else {
    bmr = Math.round(447.593 + 9.247 * kgWeight + 3.098 * height - 4.33 * age);
  }

  const tdee = Math.round(bmr * pal);
  if (goalType === GoalType.LOSE_WEIGHT) {
    kcals = Math.round(tdee - gramsPerWeek * 1.11);
  } else {
    kcals = Math.round(tdee + gramsPerWeek * 1.11);
  }

  return {
    bmr,
    tdee,
    kcals,
  };
};

export const getMacroSplitInGrams = (
  kcal: number | undefined,
  macroSplitEntry: {
    proteinPercentage: number | undefined;
    carbohydratePercentage: number | undefined;
    fatPercentage: number | undefined;
  },
): { carbohydrateGrams: number; proteinGrams: number; fatGrams: number } => {
  const { carbohydratePercentage, proteinPercentage, fatPercentage } = macroSplitEntry;
  const noCalculate = !(
    kcal &&
    proteinPercentage &&
    carbohydratePercentage &&
    fatPercentage
  );

  if (noCalculate) {
    return {
      carbohydrateGrams: 0,
      proteinGrams: 0,
      fatGrams: 0,
    };
  }

  const total = carbohydratePercentage + proteinPercentage + fatPercentage;
  const carbohydrateGrams = Math.round(
    (carbohydratePercentage / CARBOHYDRATE_CALORIES_PER_GRAM / total) * kcal,
  );
  const proteinGrams = Math.round(
    (proteinPercentage / PROTEIN_CALORIES_PER_GRAM / total) * kcal,
  );
  const fatGrams = Math.round((fatPercentage / FAT_CALORIES_PER_GRAM / total) * kcal);
  return {
    carbohydrateGrams,
    proteinGrams,
    fatGrams,
  };
};

const FAT_CALORIES_PER_GRAM = 9;
const PROTEIN_CALORIES_PER_GRAM = 4;
const CARBOHYDRATE_CALORIES_PER_GRAM = 4;

export const getKcalFromFatCarbsProtein = (
  fatGrams: number,
  proteinGrams: number,
  carbohydrateGrams: number,
) => {
  return (
    fatGrams * FAT_CALORIES_PER_GRAM +
    proteinGrams * PROTEIN_CALORIES_PER_GRAM +
    carbohydrateGrams * CARBOHYDRATE_CALORIES_PER_GRAM
  );
};

export const getMacroSplitInPercentage = (
  kcal: number | undefined,
  fatGrams: number | undefined,
  proteinGrams: number | undefined,
  carbohydrateGrams: number | undefined,
) => {
  const noCalculate = !kcal || !fatGrams || !proteinGrams || !carbohydrateGrams;

  if (noCalculate) {
    return {
      carbohydratePercentage: 0,
      proteinPercentage: 0,
      fatPercentage: 0,
    };
  }

  const total =
    fatGrams * FAT_CALORIES_PER_GRAM +
    proteinGrams * PROTEIN_CALORIES_PER_GRAM +
    carbohydrateGrams * CARBOHYDRATE_CALORIES_PER_GRAM;

  const carbohydratePercentage = Math.round(
    ((carbohydrateGrams * CARBOHYDRATE_CALORIES_PER_GRAM) / total) * 100,
  );
  const proteinPercentage = Math.round(
    ((proteinGrams * PROTEIN_CALORIES_PER_GRAM) / total) * 100,
  );
  const fatPercentage = Math.round(((fatGrams * FAT_CALORIES_PER_GRAM) / total) * 100);

  return {
    carbohydratePercentage,
    proteinPercentage,
    fatPercentage,
  };
};
