// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../i18n/const";

// ----------------------------------------------------------------------

type IProps = {
  name: string;
};

type Props = IProps & TextFieldProps;

export default function RHFTextField({ name, sx, ...other }: Props) {
  const { control } = useFormContext();
  const { t } = useTranslation(TranslatorNS.MESSAGES);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ...rest }, fieldState: { error } }) => {
        return (
          <TextField
            {...rest}
            value={value || ""}
            sx={{ mb: 2, ...sx }}
            fullWidth
            error={!!error}
            helperText={error?.message ? t(error?.message) : null}
            {...other}
          />
        );
      }}
    />
  );
}
