import {
  ClientPlansScheduleEnum,
  ClientType,
  useGetClientSettingsQuery,
} from "../../../../generated/graphql";
import {
  getAllergies,
  getDietaryPreference,
  getEatingDisorders,
} from "../../../../helpers/clientFoodPreference";
import {
  getCustomQuestions,
  getCustomQuestionsById,
} from "../../../../helpers/customQuestions";
import ClientSettingsForm, { TClientSettingsForm } from "./ClientSettingsForm";
import Dialog from "@mui/material/Dialog";
import LoadingContainer from "../../../../containers/LoadingContainer";

interface Props {
  clientId: ClientType["id"];
  onClose: () => void;
}

export default function ClientInfoContainer(props: Props) {
  const { clientId, onClose } = props;

  const { data, loading, error } = useGetClientSettingsQuery({
    variables: { id: Number(clientId) },
    fetchPolicy: "network-only",
  });

  const client = data?.client;

  const allergies = getAllergies(client?.clientFoodPreferences);
  const foodPreferences = getDietaryPreference(client?.clientFoodPreferences);
  const customQuestions = getCustomQuestions(client?.customQuestions);
  const eatingDisorders = getEatingDisorders(client?.clientFoodPreferences);
  const trainerCustomQuestions = getCustomQuestionsById(client?.customQuestions);

  const {
    clientFoodPreferences,
    checkInDay,
    mealPlanSchedule,
    workoutPlanSchedule,
    ...rest
  } = client || {};

  const defaultValue = {
    ...rest,
    foodPreferences,
    allergies,
    eatingDisorders,
    customQuestions,
    trainerCustomQuestions,
    mealPlanSchedule: mealPlanSchedule || ClientPlansScheduleEnum.NoUpdate,
    workoutPlanSchedule: workoutPlanSchedule || ClientPlansScheduleEnum.NoUpdate,
    checkInDay: String(checkInDay || 0),
  } as TClientSettingsForm;

  return (
    <Dialog open={true} onClose={onClose}>
      <LoadingContainer
        loading={loading}
        error={error}
        sx={{ minWidth: { xs: "90vw", sm: 600 }, height: "95vh" }}>
        <ClientSettingsForm
          clientId={clientId}
          defaultValues={defaultValue}
          onClose={onClose}
        />
      </LoadingContainer>
    </Dialog>
  );
}
