// @mui
import { Breakpoint } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

type Key = Breakpoint | number;
type Start = Breakpoint | number;
type End = Breakpoint | number;
export enum QueryEnum {
  UP = "up",
  DOWN = "down",
  BETWEEN = "between",
  ONLY = "only",
}

export enum BreakPointEnum {
  XL = "xl",
  LG = "lg",
  MD = "md",
  SM = "sm",
}

export default function useResponsive(
  query: QueryEnum,
  key?: BreakPointEnum,
  start?: Start,
  end?: End,
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key as Key));

  const mediaDown = useMediaQuery(theme.breakpoints.down(key as Key));

  const mediaBetween = useMediaQuery(
    theme.breakpoints.between(start as Start, end as End),
  );

  const mediaOnly = useMediaQuery(theme.breakpoints.only(key as Breakpoint));

  if (query === QueryEnum.UP) {
    return mediaUp;
  }

  if (query === QueryEnum.DOWN) {
    return mediaDown;
  }

  if (query === QueryEnum.BETWEEN) {
    return mediaBetween;
  }

  if (query === QueryEnum.ONLY) {
    return mediaOnly;
  }
}
