import { Checkin, Checkins, CircumferenceKey, ClientTopProgressState } from "./types";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import { CTA_COLORS_BG } from "../../../../../styleguide/Colors";
import { GLOBAL_FONT_STYLE } from "../../../../../styleguide/Fonts";
import { ChartSeries } from "../../components/LineChartCard";

const circumferences = [
  CircumferenceKey.CHEST,
  CircumferenceKey.GLUTES,
  CircumferenceKey.HIPS,
  CircumferenceKey.LEFT_ARM,
  CircumferenceKey.LEFT_CALF,
  CircumferenceKey.LEFT_THIGH,
  CircumferenceKey.RIGHT_ARM,
  CircumferenceKey.RIGHT_CALF,
  CircumferenceKey.RIGHT_THIGH,
  CircumferenceKey.WAIST,
];

/**
 *
 * @param value it is always index 0 of the array from backend
 * @returns total number
 */
export function getTotalCircumference(value: Checkin) {
  return circumferences.reduce((acc, key) => acc + (value[key] || 0), 0);
}

const MomentObj = ({ compareKey }: { compareKey: number }) => {
  return <>{moment.unix(compareKey).format("DD/MM/YYYY")}</>;
};

export function getProgressTimeSeries({
  clientProgress,
}: {
  clientProgress: ClientTopProgressState;
}): ChartSeries[] {
  const clientKcalsObj = clientProgress.kcals;

  //Create lookup, so kcalEntries can compare if value is missing
  const onlyKcalsMatchingCheckings = Object.entries(clientProgress.checkins)?.reduce(
    (previousValue, [_, value]) => {
      return {
        ...previousValue,
        [value.date]: {
          date: value.date,
          weight: value?.weight,
          "fat%": value?.fat,
          circumference: getTotalCircumference(value) || null,
          unit: clientProgress.metrics?.unit,
          kcal: clientKcalsObj[value?.date],
        },
      };
    },
    {},
  );

  //Construct matching object, of missing kcal values
  const withKcalEntries = Object.entries(clientKcalsObj).reduce(
    (previousValue, [date, value]) => {
      const hasMatchingDate: ChartSeries | undefined =
        onlyKcalsMatchingCheckings[date as keyof typeof onlyKcalsMatchingCheckings];

      //If entry is already in don't add kcal entry
      if (Boolean(hasMatchingDate)) {
        return { ...previousValue };
      }

      return {
        ...previousValue,
        [date]: {
          date: date,
          weight: undefined,
          "fat%": undefined,
          circumferences: undefined,
          unit: undefined,
          kcal: value,
        },
      };
    },
    {},
  );

  const merged: { [date: string]: ChartSeries } = {
    ...withKcalEntries,
    ...onlyKcalsMatchingCheckings,
  };

  // Use lastKcal as a fallback value if there is no chart kcal value
  let lastKcal: number | undefined = undefined;

  return Object.values(merged).map(chartEntry => {
    if (Boolean(chartEntry?.kcal)) {
      lastKcal = chartEntry.kcal;
      return chartEntry;
    }

    return {
      ...chartEntry,
      kcal: lastKcal,
    };
  }) as ChartSeries[];
}

export function getCheckinEntry(
  { clientProgress }: { clientProgress: ClientTopProgressState },
  id: number,
) {
  return getEntry(clientProgress.checkins, id);
}

export function getEntry(entries: Checkins, id: number) {
  const entry = Object.entries(entries)?.find(([_, value]) => Number(value?.id) === id);
  if (entry) {
    // found entry value will be an index 1
    const [_, value] = entry;
    // data will always only first index according to backend
    return value;
  }
  return null;
}

export function getWeightTableEntries({
  clientProgress,
}: {
  clientProgress: ClientTopProgressState;
}) {
  return Object.entries(clientProgress.checkins)
    ?.reduce((prev, [_, value]) => {
      const { id, date, dateLabel, ...rest } = {
        id: value?.id,
        date: value?.date,
        dateLabel: <MomentObj compareKey={moment(value?.date).unix()} />,
        weight: value?.weight,
        fat: value?.fat,
      };
      /*Is rest values not null or 0 */
      return !Object.values(rest).some(Boolean)
        ? prev
        : prev.concat({ id, date, dateLabel, ...rest });
    }, [] as Partial<Checkin>[])
    ?.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
}

const TotalLabel = ({ compareKey }: { compareKey: number }) => (
  <TagIcon>{compareKey.toFixed(2)}</TagIcon>
);

export function getCircumferenceTableEntries(checkins: Checkins) {
  return Object.entries(checkins)
    ?.reduce((prev, [_, value]) => {
      const totalVal = Object.entries(value).reduce(
        /*@ts-ignore*/
        (prev, [key, val]) => (value && circumferences.includes(key) ? prev + val : prev),
        0,
      );

      const { id, date, total, dateLabel, ...rest } = {
        id: value?.id,
        date: value?.date,
        dateLabel: <MomentObj compareKey={moment(value?.date).unix()} />,
        chest: value?.chest,
        glutes: value?.glutes,
        hips: value?.hips,
        leftArm: value?.leftArm,
        leftCalf: value?.leftCalf,
        leftThigh: value?.leftThigh,
        rightArm: value?.rightArm,
        rightCalf: value?.rightCalf,
        rightThigh: value?.rightThigh,
        waist: value?.waist,
        total: <TotalLabel compareKey={totalVal} />,
      };

      /*Is rest values not null or 0 */
      return !Object.values(rest).some(Boolean)
        ? prev
        : prev.concat({ id, date, total, dateLabel, ...rest });
    }, [] as Partial<Checkin>[])
    ?.sort((a: any, b: any) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
}

const TagIcon = styled.div`
  font-size: 11px;
  font-weight: 500;
  padding: 4px 9px;
  border-radius: 4px;
  width: fit-content;
  display: flex;
  justify-content: center;
  margin: 0 2px;
  background: ${CTA_COLORS_BG}1a;
  color: ${CTA_COLORS_BG};
  ${GLOBAL_FONT_STYLE};
  max-height: 26px;
`;
