import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { UilPen } from "@iconscout/react-unicons";
import { UilCopy } from "@iconscout/react-unicons";
import { UilTrash } from "@iconscout/react-unicons";
import ConfirmModal from "../Modal/ConfirmModal";
import { TranslatorNS } from "../../i18n/const";
import { useTranslation } from "react-i18next";

interface Props {
  itemName: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
}

const size = 20;
const color = "black";

export default function MuiActions(props: Props) {
  const { itemName, onEdit, onDuplicate, onDelete } = props;
  /*TODO: create a new translation file to be only Table*/
  const { t } = useTranslation(TranslatorNS.SHARED);
  return (
    <Stack flexDirection="row">
      <IconButton onClick={onEdit}>
        <UilPen size={size} color={color} />
      </IconButton>
      <IconButton onClick={onDuplicate}>
        <UilCopy size={size} color={color} />
      </IconButton>
      <ConfirmModal
        type={"delete"}
        title={t("table.delete", { name: itemName })}
        body={t("table.youreAboutToDelete", { name: itemName })}
        action={onDelete}>
        <IconButton>
          <UilTrash size={size} color={color} />
        </IconButton>
      </ConfirmModal>
    </Stack>
  );
}
