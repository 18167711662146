export default function LenusIcon() {
  return (
    <svg height="80" viewBox="0 0 71 80" width="71" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path
          d="m0 79.8080921c.15493721.0767227.30987443.1151852.46481164.1919079 6.15875807-6.1804274 12.31752126-12.3608547 18.55375546-18.5028195.9683386-.9213808 1.3169486-1.8810217 1.3169486-3.1862183-.0387457-18.6564673-.0387457-37.351296-.0387457-56.00770262 0-.65258819-.0774914-1.34356607-.1162371-2.30325958-.7358643.69097889-1.2782029 1.15162979-1.7429486 1.61227968-4.45454 4.45298268-8.94767359 8.86755342-13.4021223 13.28214442-3.33114757 3.3397648-5.035462 7.3320774-5.035462 12.0537615v51.0172462zm71-39.3090404c-.0774914-.1535466-.1936271-.3070932-.2711186-.4606397-9.6835885 0-19.3671771-.0383614-29.01202.0383613-.6584743 0-1.39444.5374637-1.8980328 1.0364648-5.8488786 5.7197876-11.6202657 11.4395752-17.4303986 17.1977241-.4648471.4606398-.8908471.9981035-1.5493214 1.6890125.5422371.0768239.77461.1151852 1.0070843.1151852 7.6693185 0 15.3774842.1151853 23.0469042-.0383613 4.3382015-.0768239 8.1341658-1.8041977 11.23291-4.8752307 3.7184729-3.7235808 7.4756915-7.4088001 11.2330115-11.1324821 1.1619657-1.2283727 2.4401685-2.3800227 3.6409814-3.5700341z"
          fill="#222"
        />
        <g fill="#1a1a1a">
          <path d="m1 79.8080921c0-.6141864 0-1.2283728 0-1.8426603 0-17.005715 0-34.0115312 0-51.0172462 0-4.7216841 1.67619494-8.7139967 4.9523819-12.0537615 4.3809548-4.414591 8.7999561-8.82916174 13.1809009-13.28214442.4952841-.46064989.9905681-.92130079 1.7142914-1.61227968.0381065.95969351.1143194 1.65067139.1143194 2.30325958 0 18.65640662 0 37.31287392.0381064 56.00770262 0 1.3051966-.3428583 2.2264762-1.2952202 3.1862183-6.1333427 6.1419648-12.19049238 12.3223921-18.24763709 18.5028195-.1523809-.0767227-.30476181-.1535466-.45714271-.1919079z" />
          <path d="m71 40.4672441c-1.2346265 1.1824548-2.469152 2.3267918-3.665187 3.5473643-3.742269 3.6618181-7.4459466 7.3618545-11.1882156 11.0617904-3.0864148 3.0896496-6.8672754 4.7680373-11.1883167 4.8442728-7.6388033.1525716-15.3162992.0381178-22.9552035.0381178-.2314483 0-.4628965-.0381178-1.0030772-.1143533.6558543-.6866223 1.0802603-1.220673 1.5432579-1.6783877 5.787015-5.6834668 11.5740301-11.4050513 17.3610452-17.088518.5015891-.4958325 1.273117-1.0298832 1.8904808-1.0298832 9.6449577-.0762355 19.2900165-.0381178 28.8964837-.0381178.1157747.1525716.2315493.3051432.3087324.4577147z" />
        </g>
      </g>
    </svg>
  );
}
