import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as clientActivation from "./store/action";
import SignUpWrapper from "./components/SignUpWrapper";
import { useTranslation } from "react-i18next";
import ZFLoader from "../../components/Loader";
import * as userActions from "../../store/user/action";
import { useParams } from "react-router-dom";
import { getActivationPages, getAllPages, getSurveyPages } from "./pages";
import { Alert, Stack } from "@mui/material";
import { ActivationConfigEnum, IGeneral } from "./type";
import { useQuery } from "../../hooks/useQuery";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { createTheme } from "@material-ui/core";
import { PRIMARY } from "../../theme/palette";
import { MeasureSystem } from "../../interfaces/measureSystem";
import { AppState } from "../../store";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { IClientActivationState } from "./store/types";

const FULL_STEPS_TEMPLATE = (t: any, weight: number, measureType: MeasureSystem) => {
  const weightString = `${weight} ${
    Number(measureType) === MeasureSystem.IMPERIAL ? "lbs" : "kg"
  }`; // eslint-disable-line
  return getAllPages(t, weightString);
};

const ACTIVATION_STEPS_TEMPLATE = (t: any, configState: string) => {
  return getActivationPages(t, configState);
};
const SURVEY_STEPS_TEMPLATE = (t: any, weight: number, measureType: MeasureSystem) => {
  const weightString = `${weight} ${
    Number(measureType) === MeasureSystem.IMPERIAL ? "lbs" : "kg"
  }`; // eslint-disable-line
  return getSurveyPages(t, weightString);
};

interface Props {
  step: IClientActivationState["step"];
  weight: IGeneral["startWeight"];
  measuringSystem: IGeneral["measuringSystem"];
  configState: ActivationConfigEnum;
  logo?: string;
  initialLoadingState: IClientActivationState["initialLoading"];
  userTokenState?: string;
  getClientActivationAction: (datakey: string, survey: boolean) => void;
  fetchUserPublic: (token: string) => void;
  primaryColorState?: string;
  userLoadingState: boolean;
  initialError?: string;
}

export enum ActivationQueryEnum {
  OnlySurvey = "only_survey",
  FullQuestionnaire = "fullQuestionnaire",
}

const Main = (props: Props) => {
  const {
    step,
    weight,
    measuringSystem,
    configState,
    logo,
    initialLoadingState,
    userTokenState,
    getClientActivationAction,
    fetchUserPublic,
    primaryColorState,
    userLoadingState,
    initialError,
  } = props;

  const { t } = useTranslation("messages");
  let query = useQuery();
  const [loading, setLoading] = useState(true);
  const { datakey } = useParams<{ datakey: string }>();
  const fullOverwrite = query.get(ActivationQueryEnum.FullQuestionnaire) === "true";

  const muiTheme = createTheme({
    palette: {
      primary: {
        main: primaryColorState || PRIMARY.main,
      },
    },
  });

  useEffect(() => {
    if (datakey) {
      let survey = false;
      if (Number(query.get(ActivationQueryEnum.OnlySurvey)) == 1) {
        survey = true;
      }
      getClientActivationAction(datakey, survey);
    }
  }, [query]);

  useEffect(() => {
    if (userTokenState) {
      fetchUserPublic(userTokenState);
    }
  }, [userTokenState]);

  useEffect(() => {
    if (!initialLoadingState && !userLoadingState) {
      setLoading(false);
    }
  }, [userLoadingState, initialLoadingState]);

  const STEPS_TEMPLATE = (): { [step: string]: any } => {
    if (fullOverwrite) return FULL_STEPS_TEMPLATE(t, Number(weight), measuringSystem);

    switch (configState) {
      case ActivationConfigEnum.ACTIVATION:
        return ACTIVATION_STEPS_TEMPLATE(t, configState);
      case ActivationConfigEnum.SURVEY:
        return SURVEY_STEPS_TEMPLATE(t, Number(weight), measuringSystem);
      default:
        return FULL_STEPS_TEMPLATE(t, Number(weight), measuringSystem);
    }
  };

  if (loading) {
    return (
      <>
        <ZFLoader />
        {initialError && (
          <Stack
            height={"100vh"}
            marginTop={"10rem"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Alert severity="error">{initialError}</Alert>
          </Stack>
        )}
      </>
    );
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SignUpWrapper
          logo={logo}
          stepAttr={STEPS_TEMPLATE()[step]}
          stepsCount={Object.keys(STEPS_TEMPLATE())}>
          {STEPS_TEMPLATE()[step] && STEPS_TEMPLATE()[step].component}
        </SignUpWrapper>
      </LocalizationProvider>
    </MuiThemeProvider>
  );
};

function mapStateToProps(state: AppState) {
  return {
    step: state.clientActivation.step,
    userTokenState: state.clientActivation.userToken,
    weight: state.clientActivation.general.startWeight,
    measuringSystem: state.clientActivation.general.measuringSystem,
    configState: state.clientActivation.config,
    logo: state.user.logo,
    primaryColorState: state.user.primaryColor,
    localesState: state.clientActivation.locale,
    initialLoadingState: state.clientActivation.initialLoading,
    userLoadingState: state.user.loading,
    initialError: state.clientActivation.initialError,
  };
}

export default connect(mapStateToProps, { ...clientActivation, ...userActions })(Main);
