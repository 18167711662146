import { useState } from "react";
import { useSnackbar } from "notistack";
import { TNewEmailFields } from "../components/FormFields";
import { TDefaultEmailTemplates } from "./useGetEmailTemplates";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";
import { getErrorMessage, getFormDataFromObject } from "../../../../helpers/postHelper";
import axios from "../../../../helpers/axios";
import { SAVE_TEMPLATE, SEND_EMAIL as SEND_EMAIL_URL } from "../../../../api/private";
import { prepareMessageUsingTags } from "../../../SideContent/helper";
import reduxStore from "../../../../store";
import { closeGlobalModalAction } from "../../../../store/modals/action";
import { ModalTypeEnum } from "../../../../store/modals/reducerTypes";

export default function usePostEmail() {
  const [savingEmailTemplate, setSavingEmailTemplate] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveEmailTemplate = async (
    values: TNewEmailFields,
    defaultTemplates: TDefaultEmailTemplates[],
  ) => {
    try {
      setSavingEmailTemplate(true);
      const placeholders =
        defaultTemplates[defaultTemplates.findIndex(t => t.id === values.template)]
          .placeholders;

      await axios.post(SAVE_TEMPLATE(), {
        textarea: prepareMessageUsingTags(values.message, placeholders, true),
        subject: prepareMessageUsingTags(values.subject, placeholders, true),
        type: values.type,
      });

      enqueueSnackbar(t("actions.saved"), {
        variant: "success",
        persist: false,
        preventDuplicate: true,
      });
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
      console.log({ e }, "devDebug");
    } finally {
      setSavingEmailTemplate(false);
    }
  };

  const handleSubmitEmail = async (values: TNewEmailFields) => {
    try {
      setSendingEmail(true);

      const submitDataFormatted = {
        queue: values.queueId,
        type: values.type,
        to: values.to,
        subject: values.subject,
        "default-message-dropdown": values.template,
        message: values.message,
      };

      const formData = getFormDataFromObject(submitDataFormatted);
      await axios.post(SEND_EMAIL_URL(), formData);

      setTimeout(() => {
        enqueueSnackbar(t("addClient.emailSentToClient"), {
          variant: "success",
          persist: false,
          preventDuplicate: true,
        });
      }, 900);

      reduxStore.dispatch(closeGlobalModalAction(ModalTypeEnum.EmailInvitation));
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
      console.log({ e }, "devDebug");
    } finally {
      setSendingEmail(false);
    }
  };

  return {
    handleSaveEmailTemplate,
    handleSubmitEmail,
    savingEmailTemplate,
    sendingEmail,
  };
}
