import { CSSProperties, ReactNode, useEffect } from "react";
// form
import { FieldValues, FormProvider as Form, UseFormReturn } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/material";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  style?: CSSProperties;
};

export default function FormProvider({ children, onSubmit, methods, style }: Props) {
  const { errors } = methods.formState;

  useEffect(() => {
    scrollToRHFFirstError(errors);
  }, [errors]);

  return (
    <Form {...methods}>
      <form style={{ height: "100%", ...style }} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
}

function scrollToRHFFirstError(errors: FieldErrors<FieldValues>) {
  if (errors && Object.keys(errors).length !== 0) {
    const firstError = (Object.keys(errors) as Array<keyof typeof errors>)?.reduce<
      keyof typeof errors | null
    >((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return !!errors[fieldKey] ? fieldKey : a;
    }, null);

    if (firstError) {
      let firstErrorElement = document.getElementsByName(String(firstError))?.[0];
      firstErrorElement?.scrollIntoView({ behavior: `smooth`, block: "center" });
    }
  }
}
