import { ExerciseType, ExerciseTypeEnum } from "../../generated/graphql";
interface ServerExercise {
  equipment: { id: number; name: string };
  id: number;
  muscle: { id: number; name: string };
  name: string;
  picture: string;
  type: { id: number; name: string };
  video: string;
}
interface ServerSuperSet {
  comment: string;
  exercise: ServerExercise;
  id: number;
  order: number;
  reps: string;
  rest: string;
  rm: null;
  sets: string;
  startWeight: null;
  tempo: null;
  time: null;
  type: { id: number; name: string };
}

export interface ServerWorkouts {
  comment: string;
  exercise: ServerExercise;
  id: number;
  order: number;
  reps: string;
  rest: string;
  rm: null;
  sets: string;
  startWeight: null;
  supers: ServerSuperSet[];
  tempo: null;
  time: null;
  type: { id: number; name: string };
}

export enum WorkoutItemType {
  SUPER_SET = "Superset",
  EXERCISE = "Exercise",
}

export interface WorkoutPlan {
  id: number;
  title: string;
  dayId: number;
  subTitle: string;
  image?: string;
  video?: string;
  comment?: string;
  parentId: number | null;
  type: WorkoutItemType;
  sets?: string;
  reps?: string;
  rest?: string;
  time?: string;
  workoutType: { id: number };
  children?: WorkoutPlan[];
  isLenusExercise?: boolean;
  exerciseType: ExerciseTypeEnum;
}

export interface WorkoutPlanDay {
  id: number;
  last: boolean;
  name: string;
  order: number;
  description: string;
  // might needs revision
  workouts: WorkoutPlan[];
}
