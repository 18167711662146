import React from "react";
import { ZFErrorStyled } from "./ZFErrorStyled";
import { useTranslation } from "react-i18next";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

interface MapProps {
  msg: string;
  status?: string;
  style?: object;
}

const ZFError = (props: MapProps) => {
  const { t } = useTranslation("shared");
  const { msg, status, style = {} } = props;
  return (
    <ZFErrorStyled style={style}>
      <span>
        <ReportProblemOutlinedIcon />
        {msg && typeof msg === "string"
          ? status
            ? `${t("error.status")}: ${status ? status : t("error.error")} | ${msg}`
            : msg
          : t("error.anErrorOccurred")}
      </span>
    </ZFErrorStyled>
  );
};

export default ZFError;
