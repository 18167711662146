import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import Iconify from "../_MUI/Iconify";
import { useTheme } from "@mui/material/styles";

export default function InfoTooltip({ text }: { text: string }) {
  const theme = useTheme();
  return (
    <Tooltip sx={{ zIndex: 1000 }} title={text}>
      <IconButton sx={{ mb: 0.5 }}>
        <Iconify
          color={theme.palette.grey[700]}
          icon="charm:info"
          height={18}
          width={18}
        />
      </IconButton>
    </Tooltip>
  );
}
