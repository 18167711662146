import { styled } from "@mui/material/styles";
import { Box, Menu, Stack, Tooltip, Typography } from "@mui/material";
import cssStyles from "../../../../../../utils/cssStyles";
import MyAvatar from "../../../../MyAvatar";
import SkeletonContainer from "../../../../../../containers/SkeletonContainer";
import Label from "../../../../Label";
import { LoadingButton } from "@mui/lab";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../../../i18n/const";
import { useSnackbar } from "notistack";
import Iconify from "../../../../Iconify";
import { ActivationQueryEnum } from "../../../../../../routes/ClientActivation/Main";

type Props = {
  name: string;
  photo?: string;
  initialLoading: boolean;
  tags?: string[];
  clientInviteUrl: string | undefined;
  answeredQuestionnaire: boolean;
  hasCreatedPassword: boolean;
};

export default function ProfileCover(props: Props) {
  const {
    name,
    photo,
    initialLoading,
    tags,
    clientInviteUrl,
    hasCreatedPassword,
    answeredQuestionnaire,
  } = props;

  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCopyLink = async (copyText: string | undefined) => {
    if (!copyText) {
      await enqueueSnackbar("Missing link to copy", { variant: "error" });
      return;
    }

    try {
      await navigator.clipboard.writeText(copyText);
      await enqueueSnackbar("Copied link", { variant: "success" });
    } catch (e) {
      await enqueueSnackbar("Could not copy link", { variant: "error" });
    }

    setAnchorEl(null);
  };

  const handleOpenLink = (url: string | undefined) => {
    if (!url) {
      enqueueSnackbar("Missing link to open", { variant: "error" });
      return;
    }

    window.open(url);
  };

  return (
    <RootStyle>
      <InfoStyle>
        <MyAvatar
          photo={photo}
          name={name}
          initialLoading={initialLoading}
          sx={{
            mx: "auto",
            borderStyle: "none",
            width: { md: 88, xs: 80 },
            height: { md: 88, xs: 80 },
          }}
        />
        <Box
          sx={{
            ml: { md: 3 },
            display: "flex",
            mt: -2.5,
            justifyContent: { xs: "center", md: "left" },
          }}>
          <SkeletonContainer
            variant={"text"}
            height={40}
            sx={{ mt: { xs: 1, md: -2 } }}
            loading={initialLoading}>
            <Typography mt={{ xs: 2, md: -2 }} variant="h4">
              <Stack
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                pt={{ xs: 1, sm: 0 }}>
                <Stack>{name}</Stack>
                <LoadingButton
                  sx={{
                    mx: 1,
                    display: { xs: "none", sm: "inline-flex" },
                    pointerEvents: "auto",
                  }}
                  size={"small"}
                  color={"inherit"}
                  variant={"outlined"}
                  onClick={handleClick}
                  loading={initialLoading}
                  endIcon={
                    <Iconify icon={"material-symbols:keyboard-arrow-down-rounded"} />
                  }>
                  {t("newClientOverview.links")}
                </LoadingButton>
                <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                  <Tooltip
                    title={t("newClientOverview.copyInviteLinkTooltip")}
                    placement="right">
                    <MenuItem onClick={() => handleCopyLink(clientInviteUrl)}>
                      {t("newClientOverview.copyInviteLink")}
                    </MenuItem>
                  </Tooltip>
                  <Tooltip
                    title={t("newClientOverview.copyQuestionnaireLinkTooltip")}
                    placement="right">
                    <MenuItem
                      disabled={!answeredQuestionnaire}
                      onClick={() =>
                        handleCopyLink(
                          `${clientInviteUrl}?${ActivationQueryEnum.FullQuestionnaire}=true`,
                        )
                      }>
                      {t("newClientOverview.copyQuestionnaireLink")}
                    </MenuItem>
                  </Tooltip>
                  <Tooltip
                    title={t("newClientOverview.openResetPasswordTooltip")}
                    placement="right">
                    <MenuItem
                      disabled={!hasCreatedPassword}
                      onClick={() => handleOpenLink(clientInviteUrl)}>
                      {t("newClientOverview.openResetPassword")}
                    </MenuItem>
                  </Tooltip>
                </Menu>
                {(tags || []).map(t => (
                  <Label sx={{ mr: 1 }} key={t} color={"default"}>
                    {t.toUpperCase()}
                  </Label>
                ))}
              </Stack>
            </Typography>
          </SkeletonContainer>
        </Box>
      </InfoStyle>
    </RootStyle>
  );
}

const RootStyle = styled("div")(({ theme }) => ({
  "&:before": {
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.grey["200"] }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const InfoStyle = styled("div")(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  pointerEvents: "none",
  position: "absolute",
  marginTop: theme.spacing(5),
  [theme.breakpoints.up("md")]: {
    right: "auto",
    display: "flex",
    alignItems: "center",
    left: theme.spacing(9),
    bottom: theme.spacing(1.5),
  },
}));
