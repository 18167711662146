import { Stack, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useTranslation } from "react-i18next";
import { TranslatorNS } from "../../../../i18n/const";

export default function AllDone() {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  return (
    <Stack height={"100%"}>
      <Stack justifyContent={"center"} textAlign={"center"} height={"100%"} pb={5}>
        <Stack flexDirection={"row"} justifyContent={"center"}>
          <CheckCircleRoundedIcon
            sx={{ fontSize: "58px !important" }}
            color={"success"}
          />
        </Stack>
        <Stack mt={4} maxWidth={300} mx={"auto"}>
          <Typography mb={1} variant={"h6"}>
            {t("newClientOverview.allDone")}
          </Typography>
          <Typography color={"text.secondary"}>
            {t("newClientOverview.goodJob")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
