import useClientVideosQuery from "../../useVideosQuery/useClientVideosQuery";
import { videoColumns } from "./VideoColumns";
import { DataGrid } from "@mui/x-data-grid";
import { AppState } from "../../../../store";
import { connect } from "react-redux";
import { IClientVideosPageShape } from "../../useVideosQuery/useClientVideosMutations";

function getFlattenedClientVideosArray(
  clientVideosPages: IClientVideosPageShape[] | undefined,
) {
  if (!clientVideosPages)  return [];
  
  return clientVideosPages.map(page => page.data).flat();
}

interface Props {
  clientId: number;
}

function ClientVideosTable(props: Props) {
  const { clientId } = props;
  const params = { clientId };

  const { clientVideosQuery } = useClientVideosQuery(params);
  const data = getFlattenedClientVideosArray(clientVideosQuery.data?.pages);
  const loading = clientVideosQuery.isLoading;

  return <DataGrid rows={data} loading={loading} columns={videoColumns()} />;
}
const mapStateToProps = (appState: AppState) => {
  return {
    clientId: appState.currentClient.id,
  };
};

export default connect(mapStateToProps)(ClientVideosTable);
