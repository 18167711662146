import { useState } from "react";
import * as Yup from "yup";
import { TLenusSignupFormProps } from "./index";
import axios from "../../../../helpers/axios";
import { FINISH_SIGNUP, SIGNUP } from "../../../../api/auth";
import { setRefreshToken, setToken } from "../../../../api/tokens";
import { ERROR_MESSAGE } from "../../../../helpers/const";
import { useNavigate } from "react-router";
import { Routes } from "../../../routeMap";
import { addMonthsDate, formatDate } from "../../../../helpers/dates";

export const LenusSignupSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(6),
  acceptedTerms: Yup.boolean()
    .required("The terms and conditions must be accepted.")
    .oneOf([true], "The terms and conditions must be accepted."),
});

export default function useLenusSignupSubmit({
  setLoggedIn,
}: {
  setLoggedIn: (b: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  let navigate = useNavigate();

  const onLenusSignup = async (form: TLenusSignupFormProps) => {
    try {
      setLoading(true);
      const payload = {
        name: form.name,
        email: form.email,
        password: form.password,
        termsAccepted: form.acceptedTerms,
        country: form.country,
        salesforce_id: form.salesforceId,
        start_date: formatDate(form.startDate, "yyyy-MM-dd"),
        platform_origin: form.platformOrigin,
        committed_until: getCommitmentDate(form.commitment, form.startDate),
      };

      const { data } = (await axios.post(SIGNUP(), payload)) as {
        data: { access_token: string; refresh_token: string };
      };

      const finishSignupPayload = {
        locale: form.locale,
        amountOfClients: form.amountOfClients,
        country: form.country,
      };

      await axios.post(FINISH_SIGNUP(), finishSignupPayload, {
        headers: {
          Authorization: `Bearer ${data?.access_token}`,
        },
      });

      setToken(data?.access_token);
      setRefreshToken(data?.refresh_token);
      setLoggedIn(true);
      navigate(Routes.LENUS_SUBSCRIPTION);
    } catch (e: any) {
      console.error({ e });
      setError(e?.response?.data?.message || ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  return {
    onLenusSignup,
    loading,
    error,
  };
}

const getCommitmentDate = (commitment: boolean, startDate: Date) => {
  if (!commitment) return undefined;

  return formatDate(addMonthsDate(startDate, 6), "yyyy-MM-dd");
};
