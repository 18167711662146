import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../../components/_MUI/Iconify";

interface Props {
  loading?: boolean;
  goBackTxt?: string;
  goBack?: () => void;
  goNextTxt?: string;
  goNext?: () => void;
  buttonTxt?: string;
}

export default function NavigationButtons(props: Props) {
  const { loading = false, goNext, buttonTxt, goNextTxt, goBackTxt, goBack } = props;
  return (
    <Stack
      width={"100%"}
      position={"absolute"}
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent={"space-between"}
      bottom={{ sm: 20, xs: 40 }}
      px={4}>
      <Stack>
        {goBackTxt && (
          <Stack
            onClick={goBack}
            sx={{ opacity: 0.45, cursor: "pointer" }}
            color={"white"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"row"}
            display={{ xs: "none", sm: "flex" }}>
            <Iconify
              icon={"material-symbols:arrow-back-rounded"}
              width={15}
              height={15}
              color={"white"}
            />
            {goBackTxt}
          </Stack>
        )}
      </Stack>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography color={"white"} mr={2}>
          {goNextTxt}
        </Typography>
        {goNext && (
          <LoadingButton
            type={"submit"}
            size={"large"}
            loading={loading}
            onClick={goNext}
            variant={"contained"}>
            {buttonTxt}
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
}
