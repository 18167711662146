export const MAX_MINUTS = 5;
export const MAX_RECORDING_SEC = 60 * MAX_MINUTS;

export const VIDEO_STREAM_CONSTRAINT = {
  width: 480,
  height: 360,
  facingMode: "user",
  frameRate: 20,
};

export const MEDIA_RECORDER_OPTIONS = {
  mimeType: "",
  videoBitsPerSecond: 1000000,
};

export const AUDIO_CONSTRAINT = {
  suppressLocalAudioPlayback: true,
  echoCancellation: true,
};

export const RECORDER_ANIMATION_SPEED = 1200;
