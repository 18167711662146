import { AppState } from "../../../../store";
import { connect } from "react-redux";
import FailedPaymentAlert from "./FailedPaymentAlert";
import TrialAlert from "./TrialAlert";
import StripeConnectAlert from "./StripeConnectAlert";
import { getAlertStorage } from "./helper";
import useResponsive, {
  BreakPointEnum,
  QueryEnum,
} from "../../../../hooks/useResponsive";
import {
  IUser,
  PlatformOriginEnum,
  UserState,
} from "../../../../store/user/reducerTypes";
import LenusTransferNotReady from "./LenusTransferNotReady";
import { isAfter, isBefore, isToday, parseISO } from "date-fns";
import LenusTransferReady from "./LenusTransferReady";
import { TransferProgressEnum } from "../../../../generated/graphql";

interface Props {
  displayFailedPaymentAlert: boolean;
  displayTrialAlert: boolean;
  displayStripeConnectAlert: boolean;
  stripeConnectUrl: UserState["stripeConnectUrl"];
  platformOrigin: IUser["platformOrigin"];
  transferInProgress: IUser["transferInProgress"];
  lenusTransferDate: Date | null;
}

function UserAlerts(props: Props) {
  const {
    displayFailedPaymentAlert,
    displayTrialAlert,
    displayStripeConnectAlert,
    stripeConnectUrl,
    platformOrigin,
    transferInProgress,
    lenusTransferDate,
  } = props;

  const { stripeConnectAlertStorage } = getAlertStorage();
  const isDesktop = useResponsive(QueryEnum.UP, BreakPointEnum.LG);
  const alertSx = {
    width: isDesktop ? "75%" : "95%",
    fontSize: isDesktop ? "inherit" : 10,
    padding: "0px 16px",
    color: "common.white",
    backgroundColor: "#ffffff20",
  };

  const showLenusTransferNotReadyAlert =
    platformOrigin === PlatformOriginEnum.Lenus &&
    transferInProgress === TransferProgressEnum.NotStarted &&
    lenusTransferDate &&
    isBefore(new Date(), new Date(lenusTransferDate));

  const showLenusTransferInProgressAlert =
    platformOrigin === PlatformOriginEnum.Lenus &&
    transferInProgress !== TransferProgressEnum.Completed &&
    lenusTransferDate &&
    (isToday(new Date(lenusTransferDate)) ||
      isAfter(new Date(), new Date(lenusTransferDate)));

  if (showLenusTransferInProgressAlert) {
    return <LenusTransferReady alertSx={alertSx} />;
  }

  if (showLenusTransferNotReadyAlert) {
    return (
      <LenusTransferNotReady alertSx={alertSx} lenusTransferDate={lenusTransferDate} />
    );
  }

  if (displayFailedPaymentAlert) {
    return <FailedPaymentAlert alertSx={alertSx} />;
  }
  if (displayTrialAlert) {
    return <TrialAlert alertSx={alertSx} />;
  }
  if (displayStripeConnectAlert && !stripeConnectAlertStorage) {
    return <StripeConnectAlert alertSx={alertSx} stripeConnectUrl={stripeConnectUrl} />;
  }
  return null;
}

function mapStateToProps(state: AppState) {
  return {
    displayFailedPaymentAlert: !!state.user.payment?.lastPaymentFailed,
    displayTrialAlert: state.user.subscription.isTrial,
    displayStripeConnectAlert: !state.user.stripeConnected,
    stripeConnectUrl: state.user.stripeConnectUrl,
    platformOrigin: state.user.platformOrigin,
    transferInProgress: state.user.transferInProgress,
    lenusTransferDate: getLenusTransferDate(state.user?.lenusTransferDate?.date),
  };
}

const getLenusTransferDate = (lenusTransferDate: string | null | undefined) => {
  if (!lenusTransferDate) {
    return null;
  }
  return parseISO(lenusTransferDate);
};

export default connect(mapStateToProps)(UserAlerts);
