export const SET_NOTIFICATION_MESSAGE = "SET_NOTIFICATION_MESSAGE";
export const CLEAR_NOTIFICATION_MESSAGE = "CLEAR_NOTIFICATION_MESSAGE";

export enum NotificationTypeEnum {
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
}

interface setNotification {
  type: typeof SET_NOTIFICATION_MESSAGE;
  payload: NotificationState;
}
interface clearNotification {
  type: typeof CLEAR_NOTIFICATION_MESSAGE;
}

export interface NotificationState {
  open: boolean;
  message: string;
  type: NotificationTypeEnum;
}

export type notificationActionTypes = setNotification | clearNotification;
