import { InitialModalShow } from "../../../helpers/listHelper";
import { MODAL_STATE_WORKOUT_TEMPLATE } from "./action";
import { IWorkoutTemplateState } from "../types";

export interface IWorkoutTemplateModalState {
  active: boolean;
  type: string;
  headTitle: string;
  content: string;
  item?: {
    gender: IWorkoutTemplateState["gender"] | undefined;
    level: IWorkoutTemplateState["level"] | undefined;
    name: IWorkoutTemplateState["name"];
    workoutsPerWeek: IWorkoutTemplateState["workoutsPerWeek"] | undefined;
    location: IWorkoutTemplateState["location"] | undefined;
    explanation: string;
  };
}

export interface WorkoutTemplateState {
  modal: IWorkoutTemplateModalState;
}

export const INITIAL_STATE = {
  modal: InitialModalShow,
};

export default function Reducer(state = INITIAL_STATE, { type, payload }: any) {
  switch (type) {
    case MODAL_STATE_WORKOUT_TEMPLATE: {
      return { ...state, modal: payload };
    }
    default:
      return state;
  }
}
