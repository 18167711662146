import axios from "axios";
import {
  MESSAGES_FETCH,
  MESSAGES_READ,
  MESSAGES_UPDATE,
  TOGGLE_CHAT_WIDGET_OPEN,
  INIT_CHAT_WIDGET,
} from "../constants";
import {
  CONVERSATIONS_MARK_DONE,
  CONVERSATIONS_MARK_UNREAD,
  MARK_MESSAGES_READ,
  MESSAGES_RECEIVE,
} from "../../../api/private/chat";
import {
  NotificationTypeEnum,
  SET_NOTIFICATION_MESSAGE,
} from "../../../store/notification/reducerTypes";
import translator from "../../../i18n/helpers/translator";
import { TranslatorNS } from "../../../i18n/const";

const t = str => translator.t(`${TranslatorNS.CHAT_WIDGET}:${str}`);

export const initializeChatWidget = (
  userId,
  clientId,
  clientName,
  clientPhoto,
  locale,
  messageType,
  conversationId,
) => {
  return {
    type: INIT_CHAT_WIDGET,
    payload: {
      userId,
      clientId,
      clientName,
      clientPhoto,
      locale,
      messageType,
      conversationId,
    },
  };
};

export const openChatWidget = (
  userId,
  clientId,
  clientName,
  clientPhoto,
  locale,
  messageType,
  conversationId,
) => {
  return dispatch => {
    dispatch(
      initializeChatWidget(
        userId,
        clientId,
        clientName,
        clientPhoto,
        locale,
        messageType,
        conversationId,
      ),
    );
    dispatch({ type: TOGGLE_CHAT_WIDGET_OPEN, payload: false });
    dispatch({ type: TOGGLE_CHAT_WIDGET_OPEN, payload: true });
  };
};

export const fetchMessages = (clientId, isViewed = false) => {
  return dispatch => {
    dispatch({ type: MESSAGES_FETCH.REQUEST });

    const obj = {
      startFrom: 0,
      isViewed: isViewed,
    };
    return axios.post(MESSAGES_RECEIVE(clientId), obj).then(({ data }) => {
      dispatch({
        type: MESSAGES_FETCH.SUCCESS,
        payload: {
          messages: data.messages,
          hasMore: data.hasMore,
        },
      });
    });
  };
};

export const clearMessages = () => {
  return {
    type: MESSAGES_FETCH.SUCCESS,
    payload: {
      messages: [],
      hasMore: false,
      startFrom: 0,
    },
  };
};

export const updateMessages = (messages, hasMore, startFrom, unreadMessagesCount = 0) => {
  return {
    type: MESSAGES_UPDATE,
    payload: { messages, hasMore, startFrom, unreadMessagesCount },
  };
};

export const markMessagesAsRead = clientId => {
  return dispatch => {
    dispatch({ type: MESSAGES_READ, payload: { unreadMessagesCount: 0 } });
    return axios.post(MARK_MESSAGES_READ(clientId), {});
  };
};

export const toggleChatWidgetOpen = isOpen => {
  return { type: TOGGLE_CHAT_WIDGET_OPEN, payload: isOpen };
};

export const markUnreadConversation = id => {
  return dispatch => {
    return axios.post(CONVERSATIONS_MARK_UNREAD(id), {}).then(() => {
      dispatch({
        type: SET_NOTIFICATION_MESSAGE,
        payload: {
          type: NotificationTypeEnum.SUCCESS,
          message: t("notifications.success"),
        },
      });
    });
  };
};

export const markConversationDone = id => {
  if (!id) {
    return dispatch => {
      return dispatch({
        type: SET_NOTIFICATION_MESSAGE,
        payload: {
          type: NotificationTypeEnum.ERROR,
          message: t("notifications.error"),
        },
      });
    };
  }

  return dispatch => {
    return axios.post(CONVERSATIONS_MARK_DONE(id), {}).then(() => {
      dispatch({
        type: SET_NOTIFICATION_MESSAGE,
        payload: {
          type: NotificationTypeEnum.SUCCESS,
          message: t("notifications.success"),
        },
      });
    });
  };
};
