import { ClientType, CoachType } from "../../generated/graphql";
import { gql, useApolloClient } from "@apollo/client";

interface Props {
  userId: ClientType["id"];
}

export default function useGetActivationInfo(props: Props) {
  const { userId } = props;
  const client = useApolloClient();

  const coach = client.readFragment({
    id: `CoachType:${userId}`,
    fragment: gql`
      fragment CoachType on CoachType {
        activeClientCount
        subscription {
          plan {
            clientCount
          }
        }
      }
    `,
  }) as CoachType;

  return {
    coach,
  };
}
