import {
  CREATE_RECIPES,
  DELETE_RECIPES,
  DUPLICATE_RECIPES,
  ERROR_STATE,
  GET_RECIPES,
  UPDATE_RECIPE,
} from "./types";
import { InitialModalShow } from "../../../helpers/listHelper";
import { MODAL_STATE } from "../../../helpers/const";

const INITIAL_STATE = {
  listUpdate: false,
  error: false,
  empty: null,
  modal: InitialModalShow,
  isLoading: true,
};

export default function Reducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GET_RECIPES.SUCCESS: {
      return {
        ...state,
        recipes: payload,
        error: false,
        empty: payload?.length === 0,
        isLoading: false,
      };
    }
    case CREATE_RECIPES.SUCCESS: {
      return { ...state, listUpdate: payload, error: false };
    }
    case DUPLICATE_RECIPES.SUCCESS: {
      return { ...state, listUpdate: payload, error: false };
    }
    case UPDATE_RECIPE.SUCCESS: {
      return { ...state, listUpdate: payload, error: false };
    }
    case DELETE_RECIPES.SUCCESS: {
      return { ...state, listUpdate: payload, error: false };
    }
    case ERROR_STATE: {
      return { ...state, error: payload };
    }
    case MODAL_STATE: {
      return { ...state, modal: payload };
    }
    default:
      return state;
  }
}
