import React from "react";
import { useTranslation } from "react-i18next";
import { MealPlan } from "../../../types";
import { CLONE_MEAL } from "../../../../../api/private/meals";

interface Props {
  plan: MealPlan;
}

export default function CloneMealPlan({ plan }: Props) {
  const { t } = useTranslation("meal");
  const PATH = CLONE_MEAL(plan.id);

  return (
    <div
      className="modal inmodal fade sm reactModal"
      id="clonePlanModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">×</span>
              <span className="sr-only">{t("cloneMealPlan.close")}</span>
            </button>
            <h4 className="modal-title">{t("cloneMealPlan.cloneMealPlan")}</h4>
          </div>

          <form action={PATH} method="POST" autoComplete="off">
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="inputPassword" className="control-label">
                  {t("cloneMealPlan.title")}
                </label>
                <input
                  type="text"
                  id="title"
                  name="name"
                  className="form-control"
                  placeholder={t("cloneMealPlan.titleOfMealPlan")}
                />
                <input type="hidden" name="id" />
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-block btn-success btn-upper">
                {t("cloneMealPlan.save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
