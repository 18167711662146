import { Theme } from "@mui/material/styles";
import i18n from "i18next";
import { TranslatorNS } from "../../i18n/const";

// ----------------------------------------------------------------------

export default function DataGrid(theme: Theme) {
  const t = (string: string) => i18n.t(string, { ns: TranslatorNS.LISTS });
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          padding: 8,
          ".MuiDataGrid-cell": {
            "&:focus": {
              outline: "none",
            },
            "&:focus-within": {
              outline: "none",
            },
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            "&:focus": {
              outline: "none",
            },
          },
          ".MuiDataGrid-virtualScroller": {
            overflowX: { xs: "auto", sm: "hidden" },
          },
          ".MuiDataGrid-columnHeaders ": {
            borderRadius: 8,
            border: 0,
            backgroundColor: theme.palette.grey[200],
            padding: "0 16px",
          },
          ".MuiDataGrid-row": {
            borderRadius: 8,
            padding: "0 16px",
            "&:hover": {
              backgroundColor: theme.palette.grey[200],
            },
          },
          ".MuiDataGrid-columnHeader": {
            color: theme.palette.grey[600],
            userSelect: "none",
            "&:focus": {
              outline: "none",
            },
            "&:focus-within": {
              outline: "none",
            },
          },
        },
      },
      defaultProps: {
        localeText: {
          noRowsLabel: t("common.noRows"),
        },
        headerHeight: 54,
        rowHeight: 54,
        autoHeight: true,
        disableColumnMenu: true,
        hideFooter: true,
        hideFooterSelectedRowCount: true,
        hideFooterPagination: true,
        disableSelectionOnClick: true,
      },
    },
  };
}
