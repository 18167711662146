export enum TranslatorKeyPair {
  /*i18Key: fileName in translations*/
  main = "surveyPage",
  surveyMessages = "surveyMessages",
  messages = "messages",
  auth = "auth",
  dashboard = "dashboard",
  leads = "leads",
  clientOverview = "clientOverview",
  mealPlan = "mealPlan",
  modals = "modals",
  lists = "lists",
  shared = "shared",
  plans = "plans",
  workoutPlan = "workoutPlan",
  app = "app",
  meal = "meal",
  chat = "chat",
  chatWidget = "chatWidget",
  settings = "settings",
  clientProgress = "clientProgress",
  clientSettings = "clientSettings",
  validations = "validations",
}

export enum TranslatorNS {
  MAIN = "main",
  SURVEY_MESSAGES = "surveyMessages",
  MESSAGES = "messages",
  AUTH = "auth",
  DASHBOARD = "dashboard",
  LEADS = "leads",
  CLIENT_OVERVIEW = "clientOverview",
  MEAL_PLAN = "mealPlan",
  MODALS = "modals",
  LISTS = "lists",
  SHARED = "shared",
  PLANS = "plans",
  WORKOUT_PLAN = "workoutPlan",
  APP = "app",
  MEAL = "meal",
  CHAT = "chat",
  CHAT_WIDGET = "chatWidget",
  SETTINGS = "settings",
  CLIENT_PROGRESS = "clientProgress",
  CLIENT_SETTINGS = "clientSettings",
  VALIDATIONS = "validations",
}
