import { useEffect, useMemo } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  RHFTextField,
  RHFSwitch,
  RHFDatePicker,
} from "../../../components/_MUI/hook-form";
import FormProvider from "../../../components/_MUI/hook-form/FormProvider";
import { Grid, DialogActions, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  DefaultMessageTypeEnum,
  GET_DEFAULT_MESSAGES_LOCAL,
} from "../../../api/private/default-messages";
import axios from "axios";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import InfoTooltip from "../../../components/Help/InfoTooltip";
import { clientUpdate } from "../../Leads/store/leads/actions";
import { addDays, format } from "date-fns";
import { IPaymentInfo } from "../../ClientOverview/type";
import { InvitationData } from "../../../components/Modal/AddClientModal/types";
import { Locale } from "../../../interfaces/locale";
import RHFNativeSelect from "../../../components/_MUI/hook-form/RHFNativeSelect";
import { CurrencyEnum } from "../../../interfaces/currency";
import { LocaleEnum } from "../../../generated/graphql";

interface FormValuesProps {
  currency: string;
  monthlyAmount?: number;
  signUpFee?: number;
  periods?: string;
  firstPayment: boolean;
  firstPaymentDate: Date;
  chargeUpFront: boolean;
  includeTerms: boolean;
  terms: string;
}

/**
 * Rules for validation the form
 * Called as function to make sure yup localization has been initialized
 */
const SubscriptionSchema = () =>
  Yup.object().shape({
    currency: Yup.string().required("currency is required"),
    monthlyAmount: Yup.number()
      .nullable()
      .when("periods", {
        is: (val: string) => val !== "0",
        then: Yup.number().min(10).required(),
      }),
    signUpFee: Yup.number().required("setup fee is required"),
    periods: Yup.string().required("periods is required"),
    firstPayment: Yup.bool(),
    firstPaymentDate: Yup.date(),
    chargeUpFront: Yup.bool(),
    includeTerms: Yup.bool(),
    terms: Yup.string(),
  });

const CLIENT_PAYMENT_TERMS = 14;

function Form({
  clientId,
  locale,
  onClose,
  dispatchClientUpdate,
  paymentInfo,
  currency,
}: {
  clientId: number;
  locale: LocaleEnum;
  onClose: (goToNextStep: boolean, invitationModalData?: InvitationData) => void;
  // not sure what the types should be here
  dispatchClientUpdate: (data: any) => Promise<any>;
  paymentInfo: IPaymentInfo;
  currency: CurrencyEnum;
}) {
  const { t } = useTranslation("modals");

  const defaultValues = useMemo(() => {
    return {
      currency: currency || CurrencyEnum.USD,
      monthlyAmount: paymentInfo?.recurring_fee,
      signUpFee: paymentInfo?.upfront_fee,
      periods: String(paymentInfo?.months || 3),
      includeTerms: false,
      firstPayment: false,
      firstPaymentDate: addDays(new Date(), 2),
      chargeUpFront: true,
      terms: "",
    };
  }, [paymentInfo]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(SubscriptionSchema()),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  /**
   * Fills terms if user hasn't changed the value for terms
   */
  useEffect(() => {
    if (values.includeTerms && values.terms === "") {
      axios
        .get(GET_DEFAULT_MESSAGES_LOCAL(CLIENT_PAYMENT_TERMS, clientId, locale))
        .then(({ data }: any) => {
          if (data.defaultMessages.length !== 0) {
            setValue(
              "terms",
              data.defaultMessages[data.defaultMessages.length - 1].message,
            );
          }
        });
    }
  }, [clientId, locale, values.includeTerms, values.terms]);

  /**
   *
   * Here the fields will have been validated according the schema rules
   */
  const onSubmit = async (data: FormValuesProps) => {
    try {
      // TODO: figure out what data to submit here
      dispatchClientUpdate({
        client: clientId,
        periods: values.periods,
        currency: values.currency,
        ...(Boolean(values.signUpFee) && {
          signUpFee: values.signUpFee,
        }),
        ...(Boolean(values.monthlyAmount && Number(values.periods) !== 0) && {
          monthlyAmount: values.monthlyAmount,
        }),
        ...(Boolean(values?.includeTerms) && {
          terms: values?.terms,
        }),
        payment: true,
        chargeUpfrontImmediately: values.chargeUpFront,
        ...(Boolean(values.firstPaymentDate) &&
          values.firstPayment && {
            startPaymentDate: format(values.firstPaymentDate, "yyyy-MM-dd"),
          }),
      }).then((res: any) => {
        if (res.status === "success") {
          const { id, name, email, queue, payment } = res.resData;
          const invitationModalData = {
            messageType: DefaultMessageTypeEnum.TYPE_PAYMENT_MESSAGE_EMAIL,
            locale: locale,
            clientName: name,
            clientEmail: email,
            clientId: id,
            dataKey: payment.datakey,
            queueId: queue.id,
          };
          onClose(true, invitationModalData);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <RHFNativeSelect
            name="currency"
            size="small"
            label={t("currency.chooseCurrency")}>
            <option value="usd">USD</option>
            <option value="dkk">DKK</option>
            <option value="nok">NOK</option>
            <option value="eur">EURO</option>
            <option value="gbp">GBP</option>
            <option value="sek">SEK</option>
            <option value="chf">CHF</option>
          </RHFNativeSelect>
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFNativeSelect name="periods" size="small" label={t("month.label")}>
            <option value="0">{t("month.options.0")}</option>
            <option value="1">{t("month.options.1")}</option>
            <option value="2">{t("month.options.2")}</option>
            <option value="3">{t("month.options.3")}</option>
            <option value="4">{t("month.options.4")}</option>
            <option value="5">{t("month.options.5")}</option>
            <option value="6">{t("month.options.6")}</option>
            <option value="7">{t("month.options.7")}</option>
            <option value="8">{t("month.options.8")}</option>
            <option value="9">{t("month.options.9")}</option>
            <option value="10">{t("month.options.10")}</option>
            <option value="11">{t("month.options.11")}</option>
            <option value="12">{t("month.options.12")}</option>
            <option value="13">{t("month.options.13")}</option>
          </RHFNativeSelect>
        </Grid>

        <Grid item xs={12} md={6}>
          <RHFTextField name="signUpFee" size="small" label={t("amountInput.label")} />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField
            name="monthlyAmount"
            size="small"
            disabled={values.periods === "0"}
            label={t("amountInput.monthlyAmount.label")}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <RHFSwitch name="firstPayment" label={t("terms.firstPayment")} />
          <InfoTooltip text={t("terms.firstPaymentExplainer")} />
        </Grid>
        {values.firstPayment && (
          <>
            <Grid item xs={12} md={12}>
              <RHFDatePicker
                name="firstPaymentDate"
                label={t("terms.firstPaymentDateLabel")}
                disablePast
                minDate={addDays(new Date(), 2)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFSwitch name="chargeUpFront" label={t("terms.chargeUpfront")} />
              <InfoTooltip text={t("terms.chargeUpfrontExplainer")} />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          <RHFSwitch name="includeTerms" label={t("terms.addTerms")} />
          <InfoTooltip text={t("terms.addTermsExplainer")} />
          {values.includeTerms && (
            <RHFTextField name="terms" label={t("terms.terms")} multiline rows={3} />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={() => onClose(false)}>
              {t("modalSubscription.close")}
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              {t("modalSubscription.confirmSub")}
            </LoadingButton>
          </DialogActions>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

function mapStateToProps(state: AppState) {
  return {
    clientId: state.currentClient.id,
    locale: state.user.locale,
    paymentInfo: state.currentClient.paymentInfo,
    currency: state.user.currency,
  };
}

const mapActionsToProps = {
  dispatchClientUpdate: clientUpdate,
};

export default connect(mapStateToProps, mapActionsToProps)(Form);
