export function prepareMessageUsingTags(message: string, placeholders: any, toActualValues: boolean) {
  if (message == null) {
    return "";
  }

  Object.keys(placeholders).forEach(placeholder => {
    for (let i = 0; i < 2; i++) {
      message = toActualValues
        ? message.replace(placeholders[placeholder], `[${placeholder}]`)
        : message.replace(`[${placeholder}]`, placeholders[placeholder]);
    }
  });

  return message;
}
