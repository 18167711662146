import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveFieldsAction } from "../../store/action";
import { useTranslation } from "react-i18next";
import { FieldValidators } from "../../../../helpers/validators";
import { isKgOrPound } from "../../../../helpers/measurementHelper";
import { MeasureSystem } from "../../../../interfaces/measureSystem";
import { AppState } from "../../../../store";
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { InputType } from "../../../../components/InputField/enum";
import { ActivationStepsEnum, IGeneral, IGoal } from "../../type";
import { INITIAL_GOAL } from "../../store/initialStates";
import { IClientActivationState } from "../../store/types";
import ErrorIcon from "@mui/icons-material/Error";
import { CypressTestId } from "../../../../helpers/cypressTestIds";
import { TranslatorNS } from "../../../../i18n/const";
import { scrollToElementId } from "../../../../helpers/utils";
import { track } from "../../../../helpers/analytics";

interface Props {
  saveFieldsActionDispatch: (
    goal: IGoal,
    nextStep: IClientActivationState["step"],
    currentStepName: ActivationStepsEnum,
  ) => void;
  goalState: IGoal;
  measuringSystem: MeasureSystem;
  currentStep: IClientActivationState["step"];
  startWeight: IGeneral["startWeight"];
}

const GoalWeight = ({
  saveFieldsActionDispatch,
  goalState,
  measuringSystem = MeasureSystem.METRIC,
  currentStep,
  startWeight,
}: Props) => {
  const { t } = useTranslation(TranslatorNS.MESSAGES);
  const [validator, setValidator] = useState({
    goalWeight: "",
  });
  const [goal, setGoal] = useState(INITIAL_GOAL);

  useEffect(() => {
    setGoal({
      ...goal,
      goalWeight: goalState.goalWeight,
    });
  }, [goalState]);

  const handleChange = (name: keyof typeof goal, value: string) => {
    setValidator({ ...validator, [name]: "" });
    setGoal({ ...goal, [name]: value });
  };

  const differenceLabel = () => {
    const goalWeight = Number(goal.goalWeight);
    if (!startWeight) return;

    const diffLabel =
      goalWeight === startWeight
        ? "client.survey.goalMaintain"
        : goalWeight > startWeight
        ? "client.survey.goalGain"
        : "client.survey.goalLose";

    const diffValue = Math.abs(goalWeight - startWeight);

    return t(diffLabel, {
      weight: `${diffValue} ${measuringSystem === MeasureSystem.IMPERIAL ? "lbs" : "kg"}`,
    });
  };

  const handleSave = () => {
    try {
      FieldValidators(goal, t);
      track("Weight Goal Added", "Client Activation");
      saveFieldsActionDispatch(goal, currentStep + 1, ActivationStepsEnum.GOAL);
    } catch (e: any) {
      setValidator(e);
      const id = e && Object.keys(e)[0];
      id && scrollToElementId(id);
    }
  };

  return (
    <Stack sx={{ width: { xs: "100%", sm: 450 } }}>
      <FormControl error={Boolean(validator["goalWeight"])} sx={{ mb: 3 }}>
        <TextField
          label={t("client.survey.goalWeight")}
          id={"zf_goalWeight"}
          name={"goalWeight"}
          error={Boolean(validator["goalWeight"])}
          type={InputType.NUMBER}
          onChange={e => handleChange("goalWeight", e.target.value)}
          value={goal.goalWeight || ""}
          InputProps={{
            endAdornment: isKgOrPound(measuringSystem),
            inputProps: {
              min: measuringSystem === MeasureSystem.IMPERIAL ? 66 : 30,
            },
          }}
        />
        <FormHelperText>{validator["goalWeight"]}</FormHelperText>
        {Boolean(goal.goalWeight) && (
          <Typography
            sx={{
              display: "flex",
              mx: 2,
              alignItems: "center",
              color: "text.secondary",
              fontSize: 12,
            }}>
            <ErrorIcon sx={{ fontSize: 6, mr: 1 }} />
            {differenceLabel()}
          </Typography>
        )}
      </FormControl>

      <Button
        data-cy={CypressTestId.CLIENT_PAGE_BUTTON_4}
        sx={{ width: { xs: "100%", sm: 450 } }}
        variant="contained"
        size="large"
        onClick={() => handleSave()}>
        {t("client.activation.next")}
      </Button>
    </Stack>
  );
};

function mapStateToProps({ clientActivation }: AppState) {
  return {
    startWeight: clientActivation.general.startWeight,
    goalState: clientActivation.goal,
    measuringSystem: clientActivation.general.measuringSystem,
    currentStep: clientActivation.step,
  };
}

const mapDispatchToProps = {
  saveFieldsActionDispatch: saveFieldsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(GoalWeight);
