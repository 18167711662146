import { NavigateFunction } from "react-router";

import reduxStore from "../../../store";
import { Routes } from "../../routeMap";
import axios from "../../../helpers/axios";
import { Locale } from "../../../interfaces/locale";
import { prepareRoute } from "../../../helpers/routing";
import { RESEND_QUESTIONNAIRE } from "../../../api/private/clients";
import { ClientTaskType } from "../../../interfaces/clientTaskType";
import { openChatWidget } from "../../ChatWidget/actions/chat-action";
import { CurrentClientState } from "../../../store/currentClient/types";
import { DefaultMessageTypeEnum } from "../../../api/private/default-messages";
import { handleResendInvitationModal } from "../../Clients/store/clients/actions";

export const handleTaskResolve = async (
  client: CurrentClientState,
  userId: number,
  eventName: string,
  locale: Locale,
  action: string,
  navigate: NavigateFunction,
) => {
  let returnValue = null;
  const clientId = client.id;

  switch (eventName) {
    case "client.updated_bodyprogress":
    case "client.uploaded_image": {
      navigate(prepareRoute(Routes.CLIENT_PROGRESS, { clientId }));
      break;
    }
    case "trainer.create_workout_plan":
    case "trainer.update_workout_plan": {
      navigate(prepareRoute(Routes.CLIENT_WORKOUT_PLAN, { clientId }));
      break;
    }
    case "trainer.create_meal_plan":
    case "trainer.update_meal_plan": {
      navigate(prepareRoute(Routes.CLIENT_MEAL_PLAN, { clientId }));
      break;
    }
    case "client.missing_checkin": {
      reduxStore.dispatch(
        openChatWidget(userId, clientId, client.name, client.photo, locale, {
          id: 12,
          action: false,
        }),
      );
      break;
    }
    case "client.payment_failed": {
      // window.openChatTemplate(8);
      // This is done through the ref before this action
      // TODO RE-WRITE ALL THIS LOGIC, is blocked by crap chat template
      // TOOD: USE PROPER TYPE FOR THE CASES
      break;
    }
    case "client.need_welcome": {
      reduxStore.dispatch(
        openChatWidget(userId, clientId, client.name, client.photo, locale, {
          id: 13,
          action: true,
        }),
      );
      break;
    }
    case "client.missing_communication":
    case "client.sent_message": {
      reduxStore.dispatch(
        openChatWidget(userId, clientId, client.name, client.photo, locale),
      );
      break;
    }
    case "client.ending_soon": {
      if (action === "statusList.updateText.extend") {
        returnValue = "extend";
      } else {
        reduxStore.dispatch(
          openChatWidget(userId, clientId, client.name, client.photo, locale, {
            id: 9,
            action: true,
          }),
        );
      }
      break;
    }
    case "client.completed": {
      if (action === "statusList.updateText.extend") {
        returnValue = "extend";
      } else {
        reduxStore.dispatch(
          openChatWidget(userId, clientId, client.name, client.photo, locale, {
            id: 9,
            action: true,
          }),
        );
      }
      break;
    }
    case "client.subscription_canceled": {
      if (action === "statusList.updateText.resubscribe") {
        returnValue = "resubscribe";
      } else {
        reduxStore.dispatch(
          openChatWidget(userId, clientId, client.name, client.photo, locale, {
            id: 9,
            action: true,
          }),
        );
      }
      break;
    }
    case "client.payment_pending": {
      reduxStore.dispatch(
        handleResendInvitationModal({
          client,
          messageType: DefaultMessageTypeEnum.TYPE_PAYMENT_MESSAGE_EMAIL,
        }),
      );
      break;
    }
    case "client.invite_pending": {
      reduxStore.dispatch(
        handleResendInvitationModal({
          client,
          messageType: DefaultMessageTypeEnum.TYPE_WELCOME_EMAIL,
        }),
      );
      break;
    }
    case "client.wrong_email": {
      if (client.queue.payment) {
        reduxStore.dispatch(
          handleResendInvitationModal({
            client,
            messageType: DefaultMessageTypeEnum.TYPE_PAYMENT_MESSAGE_EMAIL,
          }),
        );
      } else {
        reduxStore.dispatch(
          handleResendInvitationModal({
            client,
            messageType: DefaultMessageTypeEnum.TYPE_WELCOME_EMAIL,
          }),
        );
      }
      break;
    }
    case "client.questionnaire_pending": {
      try {
        await axios.post(RESEND_QUESTIONNAIRE(), { client: clientId });
        returnValue = ClientTaskType.RESEND_QUESTIONAIRE_SUCCESS;
      } catch (error) {
        returnValue = ClientTaskType.RESEND_QUESTIONAIRE_FAILED;
      }
      break;
    }
    default: {
      returnValue = "reminder";
      break;
    }
  }
  return returnValue;
};

export const getTaskEvents = (eventName: string) => {
  let actionText = [""];

  switch (eventName) {
    case "client.updated_bodyprogress":
      actionText = ["statusList.updateText.view"];
      break;
    case "client.uploaded_image":
      actionText = ["statusList.updateText.reply"];
      break;

    case "trainer.create_workout_plan":
      actionText = ["statusList.updateText.create"];
      break;

    case "trainer.update_workout_plan":
      actionText = ["statusList.updateText.update"];
      break;

    case "trainer.create_meal_plan":
      actionText = ["statusList.updateText.create"];
      break;

    case "trainer.update_meal_plan":
      actionText = ["statusList.updateText.update"];
      break;

    case "client.missing_communication":
      actionText = ["statusList.updateText.write"];
      break;

    case "client.sent_message":
      actionText = ["statusList.updateText.write"];
      break;

    case "client.need_welcome":
      actionText = ["statusList.updateText.activate"];
      break;

    case "client.missing_checkin":
      actionText = ["statusList.updateText.remind"];
      break;

    case "client.payment_failed":
      actionText = ["statusList.updateText.prompt"];
      break;

    case "client.ending_soon":
      actionText = ["statusList.updateText.extend", "statusList.updateText.sayGoodbye"];
      break;

    case "client.completed":
      actionText = ["statusList.updateText.extend", "statusList.updateText.sayGoodbye"];
      break;

    case "client.subscription_canceled":
      actionText = [
        "statusList.updateText.resubscribe",
        "statusList.updateText.sayGoodbye",
      ];
      break;

    case "client.payment_pending":
      actionText = ["statusList.updateText.resend"];
      break;

    case "client.invite_pending":
      actionText = ["statusList.updateText.resend"];
      break;

    case "client.wrong_email":
      actionText = ["statusList.updateText.change"];
      break;

    case "client.questionnaire_pending":
      actionText = ["statusList.updateText.resend"];
      break;

    default:
      actionText = ["statusList.updateText.resolve"];
  }

  return actionText;
};
