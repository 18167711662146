import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Stack,
  TextField,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { TranslatorNS } from "../../../../i18n/const";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

interface IProps {
  show: boolean;
  endDate?: string;
  onSubmit: (endDate: string) => void;
  onClose: () => void;
}
export default function ModalExtend(props: IProps) {
  const { t } = useTranslation(TranslatorNS.CLIENT_OVERVIEW);
  const { show, endDate, onSubmit, onClose } = props;
  const formattedDate = endDate?.replace("00:00:00.000000", "");
  const [endDateState, setEndDateState] = useState<Date | null>(
    formattedDate ? new Date(formattedDate) : null,
  );

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle>{t("modalDurationExtend.extendClient")}</DialogTitle>
      <DialogContent sx={{ width: 400 }}>
        <DialogContentText>
          {t("modalDurationExtend.clientIsExpiring")}:{" "}
          {endDate
            ? moment(endDate).format("MMM DD, YYYY")
            : t("modalDurationExtend.noEndDate")}
        </DialogContentText>
        <Stack py={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={t("const.clientNotesFields.endDate")}
              inputFormat="dd/MM/yyyy"
              minDate={new Date()}
              value={endDateState}
              onChange={newValue => {
                if (newValue !== null) {
                  setEndDateState(newValue);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  error={false}
                  sx={{
                    width: "100%",
                    background: "white",
                  }}
                  value={endDateState}
                />
              )}
            />
          </LocalizationProvider>
          <Stack mt={4}>
            <Button
              variant={"contained"}
              onClick={() =>
                endDateState && onSubmit(format(endDateState, "yyyy-MM-dd"))
              }>
              {t("modalDurationExtend.update")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
