import { useState } from "react";
import axios from "axios";
import {
  DefaultMessageTypeEnum,
  GET_DEFAULT_MESSAGES,
  IDefaultMessage,
} from "../../../../api/private/default-messages";
import { LocaleEnum } from "../../../../generated/graphql";

export default function useGetDefaultMessage() {
  const [subject, setSubject] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [content, setContent] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const getDefaultMessageById = async (
    id: DefaultMessageTypeEnum,
    locale: LocaleEnum,
  ) => {
    try {
      setLoading(true);
      const { data }: { data: { defaultMessages: IDefaultMessage[] } } = await axios.get(
        GET_DEFAULT_MESSAGES(id),
      );
      const messageObj = data?.defaultMessages?.find(
        m => m.locale === locale || locale.includes("en"),
      );
      setSubject(messageObj?.subject || undefined);
      setTitle(messageObj?.title || undefined);
      setContent(replaceNewLine(messageObj?.message) || undefined);
    } catch (e) {
      console.error(JSON.stringify(e));
    } finally {
      setLoading(false);
    }
  };

  return {
    getDefaultMessageById,
    subject,
    content,
    title,
    loading,
  };
}

const replaceNewLine = (str: string | undefined | null) => {
  return str?.replaceAll("\n", "<br>");
};
