import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

type IProps = {
  name: string;
  label?: string;
  disablePast?: boolean;
  minDate?: any;
};

type Props = IProps;

export default function RHFMobileDatePicker({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <MobileDatePicker
          label={other?.label}
          disablePast={other?.disablePast}
          minDate={other?.minDate}
          inputFormat="dd/MM/yyyy"
          {...field}
          renderInput={params => (
            <TextField
              helperText={error?.message}
              margin="dense"
              fullWidth
              color="primary"
              {...params}
              error={!!error}
            />
          )}
        />
      )}
    />
  );
}
