/**
 * Used to have access on Snackbar outside React components
 * Usage example: SnackbarUtils.success('Success')
 */

import React from "react";
import { useSnackbar, WithSnackbarProps, OptionsObject } from "notistack";

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

// Allow support for description using `\n` on message
const defaultOptions: OptionsObject = {
  preventDuplicate: true,
  style: { whiteSpace: "pre-line" },
};

export default {
  success(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: "success" });
  },
  warning(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: "warning" });
  },
  info(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: "info" });
  },
  error(msg: string, options: OptionsObject = defaultOptions) {
    this.toast(msg, { ...options, variant: "error" });
  },
  toast(msg: string, options: OptionsObject = defaultOptions) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};
